import type { CellValueChangedEvent, ColDef, GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef, useState } from 'react';

import type { dice } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useConfirmModal, useDeleteRowConfirmModal } from '@org/ui';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {
  type: 'pre' | 'post';
  getLinkProps: (masterConfigurationId: string) => Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LinkComponent: React.ComponentType<any>;
}

const defaultColDef: ColDef = {
  cellRendererParams: {
    suppressCount: true,
  },
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
};

export const useController = ({
  type,
  publicFacilityId,
  LinkComponent,
  getLinkProps,
  yearId,
}: UseControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);
  const [filterType, setFilterType] = useState('');

  const { year: selectedYear, calculationYear } = aggregated.useYear({
    publicFacilityId,
    type,
    yearId,
  });

  const {
    masterConfigurationEntities,
    createBackup,
    restoreBackup,
    createVariant,
    promoteConfig,
    deleteMasterConfig,
    createNewMasterConfig,
    createBackupMutation,
    createVariantMutation,
    updateMasterConfigEntity,
  } = aggregated.useMasterConfigurationByYearCalculation({
    yearCalculationId: yearId,
  });

  const { t } = useTranslation();

  const setFilter = (selectedFilter: string) => {
    tableRef.current?.api?.setFilterModel({
      type: {
        filter: selectedFilter,
        type: 'equals',
      },
    });

    setFilterType(selectedFilter);
  };

  const setBackups = () => setFilter('BACKUP');

  const setVariants = () => setFilter('VARIANT');

  const { onGridReady } = useAgGridData({
    agGridRef: tableRef,
    data: masterConfigurationEntities,
    onGridReady: setVariants,
  });

  const { modal: promoteVariantModal, openModal: handleOnPromoteVariant } = useConfirmModal({
    onConfirm: async (entity: dice.MasterConfigurationEntityDTO) => {
      await promoteConfig(entity);
      showNotification('success', t('main:manageMenu.configurationPage.promoteVariantSuccess'));
    },
    textKeys: {
      confirmButtonText: t('main:manageMenu.configurationPage.confirmPromoteButton'),
      contentText: t('main:manageMenu.configurationPage.confirmPromoteText'),
      titleText: t('main:manageMenu.configurationPage.confirmPromote'),
    },
  });

  const { modal: restoreBackupModal, openModal: handleOnRestoreBackup } = useConfirmModal({
    onConfirm: async (entity: dice.MasterConfigurationEntityDTO) => {
      await restoreBackup(entity);
      showNotification('success', t('main:manageMenu.configurationPage.restoreBackupSuccess'));
    },
    textKeys: {
      confirmButtonText: t('main:manageMenu.configurationPage.restore'),
      contentText: t('main:manageMenu.configurationPage.confirmRestoreBackupText'),
      titleText: t('main:manageMenu.configurationPage.confirmRestoreBackup'),
    },
  });

  const { modal: deleteModal, openModal: handleOnDeleteMasterConfig } = useDeleteRowConfirmModal({
    onConfirm: async (entity: dice.MasterConfigurationEntityDTO) => {
      await deleteMasterConfig(entity);
      showNotification('success', t('main:manageMenu.alerts.success.deletedRow'));
    },
  });

  const columnDefs = useColumns({
    LinkComponent,
    filterType,
    getLinkProps,
    handleOnDeleteMasterConfig,
    handleOnPromoteVariant,
    handleOnRestoreBackup,
  });

  const primaryConfigurationEntity = useMemo(
    () => masterConfigurationEntities?.find((mc) => mc.type === 'PRIMARY'),
    [masterConfigurationEntities],
  );

  const onCellValueChange = useCallback(
    async (event: CellValueChangedEvent) => {
      const colName = event.colDef.field;

      if (!colName) {
        return;
      }

      if (colName === 'description' || colName === 'name') {
        await updateMasterConfigEntity(event.node.data);
        return;
      }
    },

    [updateMasterConfigEntity],
  );

  const getRowId = useCallback(({ data }: GetRowIdParams) => data?.id || '', []);

  return {
    LinkComponent,
    calculationYear,
    columnDefs,
    createBackup,
    createNewMasterConfig,
    createVariant,
    defaultColDef,
    deleteModal,
    filterType,
    getLinkProps,
    getRowId,
    onCellValueChange,
    onGridReady,
    primaryConfigurationEntity,
    promoteVariantModal,
    publicFacilityId,
    restoreBackupModal,
    selectedYear,
    setBackups,
    setVariants,
    tableRef,
    type,
    yearId,
    createBackupMutation,
    createVariantMutation,
  };
};

export type ControllerType = ReturnType<typeof useController>;
