import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';

import { CostTypeAccountTableCheckbox, SelectAllCheckbox } from './components';

interface UseColumnsProps {
  costTypeNames: string[];
  onSelectionChanged: (selection: string[]) => Promise<void>;
  rowData: dice.AllocationConfigCostTypeAccountDTO[];
}

export const useColumns = ({ costTypeNames, rowData, onSelectionChanged }: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellRenderer: ({ data, value }: ICellRendererParams) => (
            <CostTypeAccountTableCheckbox
              costTypeNames={costTypeNames}
              data={data}
              onSelectionChanged={onSelectionChanged}
              value={value}
            />
          ),
          colId: 'name',
          field: 'name',
          flex: 2,
          headerComponent: () => (
            <div className="ml-2 flex space-x-2">
              <SelectAllCheckbox
                costTypeNames={costTypeNames}
                onSelectionChanged={onSelectionChanged}
                rowData={rowData}
              />
              <div>{t('main:manageMenu.allocationKeysConfiguration.costTypeAccounts')}</div>
            </div>
          ),
        },
      ] satisfies ColDef<dice.AllocationConfigCostTypeAccountDTO>[],
    [costTypeNames, onSelectionChanged, rowData, t],
  );
};
