import type { ColDef } from 'ag-grid-community';

import type { dice } from '@org/query';
import { t } from '@org/locales';

export const autoGroupColumnDef = {
  field: 'assetNumber',
  filterValueGetter: ({ data: { assetCategory, assetNumber, assetDescription } = {} }) =>
    `${assetCategory} ${assetNumber} ${assetDescription}`,
  flex: 3,
  headerName: t('main:manageMenu.manageAssetCostCenters.groupedAssetClasses'),
  valueGetter: ({ data: { assetNumber, assetDescription } = {} }) =>
    `${assetNumber} ${assetDescription}`,
} satisfies ColDef<dice.Asset>;

export const defaultColDef = {
  cellRendererParams: {
    suppressCount: true,
  },
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
  suppressHeaderMenuButton: true,
} satisfies ColDef;
