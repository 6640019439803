import { Fragment, useState } from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import { useTranslation } from '@org/locales';
import { NOT_AVAILABLE } from '@org/utils';

import { isEmptyOrNullObj } from '../../../../utils/formatUtils';
import { objSet } from '../../../../utils/object';
import ErrorIcon from '../../../common/ErrorIcon';
import { DefaultAllocationRow } from './DefaultAllocationRow';

const StyledCostCenterRow = styled.div`
  font-size: var(--font-size-primary);
`;
export const CostCenterRow = ({
  costCenter,
  allocationMap,
  updateCostCenterMap,
  selectedConfig,
}) => {
  const { t } = useTranslation();
  const [expandContent, setExpandContent] = useState(false);
  const onToggleExpand = () => {
    setExpandContent(!expandContent);
  };

  const updatePercentage = (babCostCenter, value) => {
    const newAllocationMap = structuredClone(allocationMap);
    objSet(newAllocationMap, babCostCenter, value);
    updateCostCenterMap(costCenter, newAllocationMap);
  };

  const isNotAssignedCostCenter = isEmptyOrNullObj(allocationMap) && costCenter !== NOT_AVAILABLE;
  return (
    <StyledCostCenterRow>
      <div className="d-flex">
        <span
          className="item-name d-flex"
          style={{ alignItems: 'center' }}
        >
          {costCenter}
          {isNotAssignedCostCenter && (
            <ErrorIcon
              tooltipText={t(
                'main:manageMenu.manageWithdrawCapital.alerts.warning.notAssignedCCWarning',
              )}
              type="warning"
            />
          )}
        </span>
        {!isNotAssignedCostCenter && (
          <span
            className={`appkiticon a-text-grey a-cursor-pointer ml-auto icon-${
              expandContent ? 'up' : 'down'
            }-chevron-fill `}
            data-test="upload-info-chevron"
            onClick={onToggleExpand}
          />
        )}
      </div>
      {expandContent &&
        Object.entries(allocationMap).map(([babCostCenter, allocationPercentage], index) => (
          <Fragment key={index}>
            <DefaultAllocationRow
              allocationPercentage={allocationPercentage}
              babCostCenter={babCostCenter}
              selectedConfig={selectedConfig}
              updatePercentage={updatePercentage}
            />
          </Fragment>
        ))}
    </StyledCostCenterRow>
  );
};
