import type { DropOptions } from '@react-aria/dnd';
import type { AriaLabelingProps, HoverEvents } from '@react-types/shared';
import type { ElementType, ForwardedRef } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { forwardRef, useMemo, useRef } from 'react';

import { useClipboard, useDrop } from '@react-aria/dnd';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { filterDOMProps, mergeProps, useLabels, useSlotId } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { tv } from 'tailwind-variants';

import type { OverridableComponent, OverrideProps } from '../../types';

const dropZoneVariants = tv({
  base: ['flex h-full w-full cursor-pointer flex-col items-center justify-center p-8 text-center'],
  compoundVariants: [
    {
      class: 'border-orange-default',
      isHovered: true,
      variant: 'bordered',
    },
    {
      class: 'border-2 border-orange-default',
      isDropTarget: true,
      variant: 'bordered',
    },
  ],
  variants: {
    isDropTarget: {
      true: 'bg-gray-50',
    },
    isFocusVisible: {
      true: {},
    },
    isFocused: {
      true: {},
    },
    isHovered: {
      true: 'bg-gray-50',
    },
    variant: {
      bordered: ['rounded-sm border-1 border-dashed border-gray-500'],
      ghost: '',
    },
  },
});

export interface DropZoneTypeMap<
  AdditionalProps = {},
  DefaultComponent extends ElementType = 'div',
> {
  props: AdditionalProps &
    Omit<DropOptions, 'getDropOperationForPoint' | 'ref' | 'hasDropButton'> &
    HoverEvents &
    AriaLabelingProps &
    VariantProps<typeof dropZoneVariants>;
  defaultComponent: DefaultComponent;
}
export type DropZoneProps<
  RootComponent extends ElementType = DropZoneTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<DropZoneTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const DropZone = forwardRef(
  <BaseComponentType extends ElementType = DropZoneTypeMap['defaultComponent']>(
    inProps: DropZoneProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const { as: Component = 'div', onDrop, variant, children } = inProps;

    const buttonRef = useRef<HTMLButtonElement>(null);
    const { dropProps, dropButtonProps, isDropTarget } = useDrop({
      ...inProps,
      hasDropButton: true,
      ref: buttonRef,
    });
    const { hoverProps, isHovered } = useHover(inProps);
    const { focusProps, isFocused, isFocusVisible } = useFocusRing();

    const dropzoneId = useSlotId();
    const ariaLabel = inProps['aria-label'] ?? 'drop zone';
    const messageId = inProps['aria-labelledby'];
    // Chrome + VO will not announce the drop zone's accessible name if useLabels combines an aria-label and aria-labelledby
    const ariaLabelledby = [dropzoneId, messageId].filter(Boolean).join(' ');
    const labelProps = useLabels({ 'aria-labelledby': ariaLabelledby });

    const { clipboardProps } = useClipboard({
      onPaste: (items) =>
        onDrop?.({
          dropOperation: 'copy',
          items,
          type: 'drop',
          x: 0,
          y: 0,
        }),
    });

    const DOMProps = filterDOMProps(inProps);
    delete DOMProps.id;

    const styles = useMemo(
      () =>
        dropZoneVariants({
          isDropTarget,
          isFocusVisible,
          isFocused,
          isHovered,
          variant,
        }),
      [isDropTarget, isFocusVisible, isFocused, isHovered, variant],
    );

    return (
      <Component
        {...mergeProps(dropProps, hoverProps, DOMProps)}
        className={styles}
        data-drop-target={isDropTarget || undefined}
        data-focus-visible={isFocusVisible || undefined}
        data-focused={isFocused || undefined}
        data-hovered={isHovered || undefined}
        onClick={() => buttonRef.current?.focus()}
        ref={ref}
      >
        <VisuallyHidden>
          {/* Added as a workaround for a Chrome + VO bug where it will not announce the aria label */}
          <div
            aria-hidden="true"
            id={dropzoneId}
          >
            {ariaLabel}
          </div>
          <button
            {...mergeProps(dropButtonProps, focusProps, clipboardProps, labelProps)}
            ref={buttonRef}
          />
        </VisuallyHidden>
        {children}
      </Component>
    );
  },
) as OverridableComponent<DropZoneTypeMap>;
