import type { ColDef, GetRowIdParams } from 'ag-grid-community';
import type { FC } from 'react';

import type { griddy } from '@org/query';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const getRowId = ({ data }: GetRowIdParams<griddy.ManualCorrectionInterest>) => data.rowId!;

const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
};

const View: FC<ViewProps> = (props) => {
  const { modal, columnDefs, agGridProps, tableRef, onChange } = props;
  return (
    <div className="h-full flex-1">
      <BaseEditTable
        {...agGridProps}
        animateRows
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        onCellValueChanged={onChange}
        ref={tableRef}
      />
      {modal}
    </div>
  );
};

export const ManualCorrectionsInterest = bindControllers(View, [useController]);
ManualCorrectionsInterest.displayName = 'ManualCorrectionsInterest';
