import type { ICellRendererParams } from 'ag-grid-community';

import classNames from 'classnames';

import { getConfigStatus, getStatusColor } from '@org/utils';

export const AllocationStatusIcon = ({ value }: ICellRendererParams) => {
  const status = getConfigStatus(value);

  return (
    <div className="justify-right flex h-full items-center space-x-2">
      <div
        className={classNames(
          'inline-flex h-[18px] min-h-[18px] w-[18px] min-w-[18px] rounded-full border border-[#7d7d7d] p-0.5',
          getStatusColor(status),
        )}
      />
    </div>
  );
};
