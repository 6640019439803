import { getAccountIdDescription } from '@org/utils';

import TableItemsWithDescriptionList from '../common/TableItemsWithDescriptionList';
import { textCutStyle } from '../StyledTable';
import EditAccountAllocation from './WithdrawalAllocation/EditAccountAllocation';

export const generateWithdrawCapitalColumns = (
  selectedConfig,
  accountsPlanMap,
  defaultAllocationMap,
  t,
) => [
  {
    cellStyle: {
      maxWidth: 50,
      paddingRight: '10px',
      verticalAlign: 'top',
    },
    defaultSort: 'asc',
    editable: 'onAdd',
    field: 'shortName',
    title: t('main:manageMenu.manageWithdrawCapital.tableColumns.shortName'),
  },
  {
    cellStyle: {
      maxWidth: 100,
      paddingRight: '10px',
      verticalAlign: 'top',
      ...textCutStyle,
    },
    field: 'longName',
    title: t('main:manageMenu.manageWithdrawCapital.tableColumns.longName'),
  },
  {
    cellStyle: { verticalAlign: 'top', width: 2500 },
    editComponent: ({ value, onChange, rowData }) => (
      <EditAccountAllocation
        accountsPlanMap={accountsPlanMap}
        costCenterMapping={selectedConfig?.costCenterConfig?.costCenterMapping}
        defaultAllocationMap={defaultAllocationMap}
        onChange={onChange}
        rowData={rowData}
        selectedConfig={selectedConfig}
        value={value || {}}
      />
    ),
    field: 'accountsAllocation',
    render: (rowData) => (
      <TableItemsWithDescriptionList
        descWidth="40vw"
        getDescription={(accId) => getAccountIdDescription(accId, accountsPlanMap)}
        items={Object.keys(rowData?.accountsAllocation || {})}
      />
    ),
    title: t('main:manageMenu.manageWithdrawCapital.tableColumns.accountsList'),
  },
];
