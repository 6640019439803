import type { griddy } from '@org/query';

/**
 * Filter the cost centers by type.
 * @param {array type} costCenters - the array of cost centers
 * @param {string} type - string - the type of cost center to filter by
 * @returns The filtered cost centers.
 */
export const filterCostCenters = (
  costCenters: griddy.CostCenter[],
  type: griddy.CostCenter['costCenterType'],
) => costCenters?.filter((tmpCC) => tmpCC.costCenterType === type);
