import type { CellClickedEvent } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useReducer, useRef } from 'react';

import { useDisclosure } from '@nextui-org/react';

import type { erase } from '@org/query';

interface TableDataItem extends erase.Asset {
  _originalData?: erase.Asset;
  _updatedData?: Partial<erase.Asset>;
}

type TableData = Record<string, TableDataItem[]>;

const columnsNotClickable = new Set(['rowDescription']);
const columnsNotCostCenters = new Set(['reportingDate', 'imputedInterestAmount']);

const getModalData = (
  columnName: string,
  assetsByCell: {
    operatingAssetsTransactionsByCC: TableData;
    nonOperatingAssetsTransactionsByCC: TableData;
  },
) => {
  const { operatingAssetsTransactionsByCC, nonOperatingAssetsTransactionsByCC } = assetsByCell;

  if (columnsNotCostCenters.has(columnName)) {
    return [
      ...Object.values(operatingAssetsTransactionsByCC).flat(),
      ...Object.values(nonOperatingAssetsTransactionsByCC).flat(),
    ];
  }

  return [
    ...(operatingAssetsTransactionsByCC[columnName] ?? []),
    ...(nonOperatingAssetsTransactionsByCC[columnName] ?? []),
  ];
};

interface State {
  costCenterName: string;
  description: string;
  selectedAssets: erase.Asset[];
}

interface UseInterestReportModalProps {
  assetsByCell: {
    operatingAssetsTransactionsByCC: TableData;
    nonOperatingAssetsTransactionsByCC: TableData;
  };
  transactionsPresentToFlush: boolean;
  setTransactionsPresentToFlush: (value: boolean) => void;
}

export const useInterestReportModal = ({
  assetsByCell,
  transactionsPresentToFlush,
  setTransactionsPresentToFlush,
}: UseInterestReportModalProps) => {
  const modalGridRef = useRef<AgGridReact>(null);

  const { onClose: onCloseModal, onOpenChange, isOpen, onOpen } = useDisclosure();

  const [state, setState] = useReducer(
    (oldState: State, action: Partial<State>) => ({ ...oldState, ...action }),
    {
      costCenterName: '',
      description: '',
      selectedAssets: [],
    },
  );

  const executeCloseModal = useCallback(() => {
    setState({
      costCenterName: '',
      description: '',
      selectedAssets: [],
    });
    onCloseModal();
  }, [onCloseModal]);

  const onClose = useCallback(() => {
    if (transactionsPresentToFlush && modalGridRef.current) {
      // There were some manual made changes within the interest modal
      setTransactionsPresentToFlush(false);
      modalGridRef.current.api.flushAsyncTransactions();
    } else {
      executeCloseModal();
    }
  }, [executeCloseModal, modalGridRef, setTransactionsPresentToFlush, transactionsPresentToFlush]);

  const handleOpen = useCallback(
    (event: CellClickedEvent, columnName: string) => {
      const { data, node } = event;

      const { rowDescription } = data ?? node.allLeafChildren?.at(0)?.data;

      if (columnsNotClickable.has(columnName)) {
        return;
      }
      const selectedModalAssets = getModalData(columnName, assetsByCell);
      setState({
        costCenterName: columnName,
        description: rowDescription,
        selectedAssets: selectedModalAssets,
      });
      onOpen();
    },
    [assetsByCell, onOpen],
  );

  return {
    executeCloseModal,
    handleOpen,
    isOpen,
    modalGridRef,
    onClose,
    onOpenChange,
    ...state,
  } as const;
};
