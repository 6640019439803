// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SortByOrder = <T extends Record<string, any>, Key extends keyof T>(
  rows: T[],
  key: Key,
  orderList: string[],
) => T[];

export const sortByOrder: SortByOrder = (rows, key, orderList) =>
  rows.toSorted(
    (firstItem, secondItem) =>
      orderList.indexOf(firstItem[key]) - orderList.indexOf(secondItem[key]),
  );
