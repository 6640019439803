import type { CellClassParams, RowClassParams } from 'ag-grid-community';

export const boldClasses = (params: CellClassParams | RowClassParams) => {
  if (
    !params.data?.disableBold &&
    (params.node.level === 0 || (params.node.group && params.node.level < 2))
  ) {
    return 'font-bold';
  }
  return '';
};
