import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { MasterConfigurationsSelect, PlannedCostRevenueMasterDetail } from '@org/features';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Label, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface PlannedCostRevenueProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const View: FC<PlannedCostRevenueProps> = (props) => {
  const {
    agGridProps,
    tableRef,
    autoGroupColumnDef,
    columnDefs,
    defaultColDef,
    detailCellRendererParams,
    getRowId,
    plannedCostRevenueEnabled,
    priceIncreaseActivated,
    priceIncreaseMasterConfigurationId,
    publicFacilityId,
    updateDetail,
    isPending,
    handleOnCellChange,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="flex justify-start space-x-4 py-2">
        <div>
          <Label className="flex cursor-pointer space-x-2 text-xs">
            <ToggleInput
              checked={plannedCostRevenueEnabled}
              disabled={isPending}
              name={t('main:manageMenu.managePlannedCostRevenue.activatePlannedCostRevenue')}
              onChange={({ currentTarget: { checked } }) =>
                updateDetail('plannedCostRevenueEnabled', checked)
              }
            />
            <div>{t('main:manageMenu.managePlannedCostRevenue.activatePlannedCostRevenue')}</div>
          </Label>

          <Label className="flex cursor-pointer space-x-2 text-xs">
            <ToggleInput
              checked={priceIncreaseActivated}
              disabled={isPending}
              name={t('main:manageMenu.managePlannedCostRevenue.activatePriceIncrease')}
              onChange={({ currentTarget: { checked } }) =>
                updateDetail('priceIncreaseActivated', checked)
              }
            />
            <div>{t('main:manageMenu.managePlannedCostRevenue.activatePriceIncrease')}</div>
          </Label>
        </div>
        <div className="px-2">
          <MasterConfigurationsSelect
            className="w-96"
            isDisabled={isPending}
            onChange={(value) => {
              updateDetail('priceIncreaseMasterConfigurationId', value?.toString());
            }}
            publicFacilityId={publicFacilityId}
            value={priceIncreaseMasterConfigurationId}
          />
        </div>
      </div>
      <div className="flex-1 pb-2">
        <BaseEditTable
          {...agGridProps}
          animateRows
          autoGroupColumnDef={autoGroupColumnDef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          detailCellRenderer={PlannedCostRevenueMasterDetail}
          detailCellRendererParams={detailCellRendererParams}
          detailRowHeight={415}
          enableGroupEdit
          getRowId={getRowId}
          groupDisplayType="singleColumn"
          isGroupOpenByDefault={() => true}
          masterDetail
          onCellValueChanged={handleOnCellChange}
          ref={tableRef}
          rowDragMultiRow
          rowSelection="multiple"
        />
      </div>
    </div>
  );
};

export const PlannedCostRevenue = bindControllers(View, [useController]);

PlannedCostRevenue.displayName = 'PlannedCostRevenue';
