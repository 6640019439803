import { InputNumber } from 'appkit-react';
import styled from 'styled-components';

import { getCostCenterLongNameWithShortName } from '../../../../utils/costCenter';

const StyledDefaultAllocationRow = styled.div`
  font-size: var(--font-size-primary);
  padding: 5px;
  .cost-center-name {
    font-size: var(--font-size-primary);
    flex: 1 1 auto;
    line-height: 18px;
    align-items: center;
    margin-right: auto;
  }
  .input-number {
    min-width: 85px;
    max-width: 85px;
    margin-left: 0.25rem;
  }
`;
export const DefaultAllocationRow = ({
  babCostCenter,
  allocationPercentage,
  updatePercentage,
  selectedConfig,
}) => (
  <StyledDefaultAllocationRow className="d-flex a-bg-white border-bottom">
    <span className="a-apply-theme-font-default-white d-flex cost-center-name">
      {getCostCenterLongNameWithShortName(selectedConfig, babCostCenter)}
    </span>
    <InputNumber
      className="input-number"
      inputBoxSize="sm"
      kind="arrow"
      max={100}
      min={0}
      onChange={(value) => updatePercentage(babCostCenter, value)}
      value={allocationPercentage}
    />
  </StyledDefaultAllocationRow>
);
