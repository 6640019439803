import type { ButtonProps as NextButtonProps } from '@nextui-org/react';
import { forwardRef } from 'react';

import {
  ArrowDownTrayIcon,
  CloudArrowDownIcon,
  PencilIcon,
  ShareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { extendVariants, Button as NextButton } from '@nextui-org/react';

import { useTranslation } from '@org/locales';

// can be removed after we get rid of Bootstrap CSS and its utility important classes
import styles from './Button.module.css';

interface ButtonProps extends Omit<NextButtonProps, 'variant'> {
  variant?: 'primary' | 'secondary' | 'transparent' | 'cellRenderer';
}

export const Button = extendVariants(NextButton, {
  compoundVariants: [
    {
      class: styles.DefaultStyles,
      variant: 'primary',
    },
    {
      class: styles.DefaultStyles,
      variant: 'secondary',
    },
    {
      class: styles.DefaultStyles,
      variant: 'transparent',
    },
    {
      class: styles.DefaultStyles,
      variant: 'cellRenderer',
    },
    {
      class: '!border-gray-300 !bg-gray-300 !text-gray-400 ',
      isDisabled: true,
      variant: 'primary',
    },
    {
      class: '!border-gray-300 !bg-gray-300 !text-gray-400 ',
      isDisabled: true,
      variant: 'secondary',
    },
  ],
  defaultVariants: {
    size: 'sm',
    variant: 'primary',
  },
  variants: {
    isDisabled: {
      true: 'opacity-85 pointer-events-none',
    },
    size: {
      lg: 'h-9 px-5 text-sm',
      md: 'h-[34px] text-xs',
      sm: 'h-6 text-xs',
      xl: 'h-12 px-5 text-lg normal-case',
      iconButton: 'h-8 p-1 text-sm font-medium mx-[2px]',
    },
    variant: {
      cellRenderer:
        'text-orange-default hover:text-white active:text-white bg-transparent hover:!bg-orange-default active:!bg-orange-default h-[2.125rem] !inline-flex !border-none py-[10px] !px-0 min-w-0',
      primary:
        'text-white bg-orange-default !border-orange-default hover:bg-orange-default/90 active:bg-orange-default/80',
      secondary:
        'text-orange-default bg-transparent !border-orange-default hover:bg-orange-default/20 active:bg-orange-default/10',
      transparent: 'text-orange-default bg-transparent !border-none min-w-0',
    },
  },
});

export const CancelButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { t } = useTranslation();
  const { children = t('common:cancel'), ...rest } = props;
  return (
    <Button
      ref={ref}
      startContent={<XMarkIcon className="h-4" />}
      variant="secondary"
      {...rest}
    >
      {children}
    </Button>
  );
});

export const DeleteButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { t } = useTranslation();
  const { children = t('common:contextMenu.delete'), ...rest } = props;
  return (
    <Button
      ref={ref}
      startContent={<TrashIcon className="h-4" />}
      variant="primary"
      {...rest}
    >
      {children}
    </Button>
  );
});

export const SaveButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { t } = useTranslation();
  const { children = t('common:save'), ...rest } = props;
  return (
    <Button
      ref={ref}
      startContent={<ArrowDownTrayIcon className="h-4" />}
      variant="primary"
      {...rest}
    >
      {children}
    </Button>
  );
});

export const EditButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { t } = useTranslation();
  const { children = t('common:contextMenu.edit'), ...rest } = props;
  return (
    <Button
      ref={ref}
      startContent={<PencilIcon className="h-4" />}
      variant="primary"
      {...rest}
    >
      {children}
    </Button>
  );
});

export const ShareButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { t } = useTranslation();
  const { children = t('common:contextMenu.share'), ...rest } = props;
  return (
    <Button
      ref={ref}
      startContent={<ShareIcon className="h-4" />}
      variant="primary"
      {...rest}
    >
      {children}
    </Button>
  );
});

export const ImportButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { t } = useTranslation();
  const { children = t('main:clients.menu.fileMenu.btnImport'), ...rest } = props;
  return (
    <Button
      ref={ref}
      startContent={<CloudArrowDownIcon className="h-4" />}
      variant="primary"
      {...rest}
    >
      {children}
    </Button>
  );
});
