import { Component } from 'react';

import PropTypes from 'prop-types';

import ErrorPage from '../../pages/ErrorPage';
import Logger from '../Logger';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    // update state to show a fallback UI on render
    return { error, hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Logger.error(errorInfo);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      // render custom fallback UI
      return <ErrorPage error={error} />;
    }

    // if there are no errors, render usual UI
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
