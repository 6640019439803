import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface SelectAllCheckboxProps {
  accountIds: string[];
  onSelectionChanged: (selection: string[]) => Promise<void>;
  rowData: dice.AccountDTO[];
}

export const SelectAllCheckbox = ({
  rowData,
  accountIds,
  onSelectionChanged,
}: SelectAllCheckboxProps) => {
  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      const allSelected = rowData?.map((item) => item?.accountId ?? '');

      await onSelectionChanged(allSelected);
    } else {
      await onSelectionChanged([]);
    }
  };

  const indeterminate = accountIds.length > 0 && accountIds.length < rowData.length;

  return (
    <CheckboxCellRenderer
      indeterminate={indeterminate}
      onEdit={handleOnEdit}
      value={rowData.length === accountIds.length}
    />
  );
};
