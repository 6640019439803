import { useCallback } from 'react';

import { pick } from 'lodash';

import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

import type { CostTypeAccountRowDataAccount } from '../types';

interface SelectedAllCheckboxProps {
  selectedAccounts: Record<string, boolean>;
  costTypeAccountConfig?: dice.CostTypeAccountConfigDTO;
  rowId: string;
  detailAccounts: CostTypeAccountRowDataAccount[];
  handleEditConfig: (config: dice.CostTypeAccountConfigDTO) => Promise<void>;
}

export const SelectAllCheckbox = ({
  selectedAccounts,
  costTypeAccountConfig,
  rowId,
  detailAccounts,
  handleEditConfig,
}: SelectedAllCheckboxProps) => {
  const detailAccountIds = detailAccounts.map((acc) => acc.accountId);

  const selectedAccountForType = pick(selectedAccounts, detailAccountIds);

  const selectedAccountForTypeMap = Object.entries(selectedAccountForType);

  const canSelectAccounts = selectedAccountForTypeMap
    .map(([accountId, checked]) => {
      if (!checked) {
        return Number.parseInt(accountId);
      }
    })
    .filter(Boolean);

  const alreadySelected = detailAccounts
    .filter((acc) => acc.accountChecked)
    .map((acc) => acc.accountId);

  const handleOnEdit = useCallback(
    async (isChecked: boolean) => {
      const costTypeAccounts = costTypeAccountConfig?.costTypeAccounts ?? [];

      let updatedAccountIds: string[] = [];

      if (isChecked) {
        updatedAccountIds = [...alreadySelected, ...canSelectAccounts].filter(Boolean) as string[];
      }

      const updated = costTypeAccounts.map((currentEntry) => {
        if (currentEntry.id === rowId) {
          currentEntry.accountIds = updatedAccountIds;
        }

        return currentEntry;
      });

      const config = {
        ...costTypeAccountConfig,
        costTypeAccounts: updated,
      };

      await handleEditConfig(config);
    },
    [alreadySelected, canSelectAccounts, costTypeAccountConfig, handleEditConfig, rowId],
  );

  const fullySelected = canSelectAccounts.length === 0;

  return (
    <div className="ml-8">
      <CheckboxCellRenderer
        indeterminate={!fullySelected}
        onEdit={handleOnEdit}
        source="headerSelectAll"
        value={fullySelected}
      />
    </div>
  );
};
