import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface SelectAllCheckboxProps {
  costTypeNames: string[];
  onSelectionChanged: (selection: string[]) => Promise<void>;
  rowData: dice.AllocationConfigCostTypeAccountDTO[];
}

export const SelectAllCheckbox = ({
  rowData,
  costTypeNames,
  onSelectionChanged,
}: SelectAllCheckboxProps) => {
  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      const allSelected = rowData.map((item) => item.name!);

      await onSelectionChanged(allSelected);
    } else {
      await onSelectionChanged([]);
    }
  };

  const indeterminate = costTypeNames.length > 0 && costTypeNames.length < rowData.length;

  return (
    <CheckboxCellRenderer
      indeterminate={indeterminate}
      onEdit={handleOnEdit}
      value={rowData.length === costTypeNames.length}
    />
  );
};
