import { useCallback } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { getFileUploadErrorMessage, showNotification, Upload } from '@org/ui';

interface UploadSingleFileProps {
  apiFileType: aggregated.ApiFileType;
  yearCalculationId: string;
  baseFileType: aggregated.BaseFileType;
  masterConfigurationId?: string;
}

export const UploadSingleFile = ({
  apiFileType,
  yearCalculationId,
  baseFileType,
  masterConfigurationId,
}: UploadSingleFileProps) => {
  const { t } = useTranslation();

  const { uploadFile, isLoading } = aggregated.useFiles({});

  const handleFileUpload = useCallback(
    async (files: File[]) => {
      if (!!masterConfigurationId) {
        return showNotification(
          'error',
          t('main:clients.menu.fileMenu.notPossibleToUploadAndDeleteTitle'),
        );
      }
      if (files.length === 0) {
        return;
      }

      const file = files.at(0)!;

      const formData = new FormData();
      formData.append('uploadFile', file);

      try {
        await uploadFile({
          body: formData as unknown as dice.UploadFileRequestBody,
          fileName: file.name,
          type: apiFileType,
          yearCalculationId,
        });
        showNotification(
          'success',
          t('main:clients.menu.fileMenu.alerts.success.fileUploaded', {
            fileType: apiFileType,
          }),
        );
      } catch (error_) {
        const error = error_ as dice.UploadResponse;
        const { errorMessage, detailErrorMessages } = getFileUploadErrorMessage(
          error,
          baseFileType,
        );

        if (errorMessage) {
          showNotification('error', errorMessage, 5000, detailErrorMessages);
        }
      }
    },
    [masterConfigurationId, t, uploadFile, apiFileType, yearCalculationId, baseFileType],
  );

  return (
    <div className="w-full">
      <Upload
        acceptedFileTypes={['text/csv']}
        isBtnDisabled={!!masterConfigurationId || isLoading}
        isIconVisible={false}
        isUploading={isLoading}
        onUpload={handleFileUpload}
      >
        <div className="flex flex-col">
          <span className="pt-4 text-gray-500">{t('main:clients.menu.fileMenu.uploadHint')}</span>
        </div>
      </Upload>
    </div>
  );
};
