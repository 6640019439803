import type { FC, PropsWithChildren } from 'react';
import React, { useState } from 'react';

export const ConfigItemGroup: FC<
  PropsWithChildren<{
    groupLabel: string;
    defaultExpanded: boolean;
  }>
> = ({ groupLabel, defaultExpanded, children }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className="menu-item config-item-group">
      <div className="d-flex">
        <span className="item-name">{groupLabel}</span>
        <span
          className={`appkiticon a-text-grey a-cursor-pointer ml-auto icon-${
            expanded ? 'up' : 'down'
          }-chevron-fill `}
          data-test="upload-info-chevron"
          onClick={() => setExpanded((prev) => !prev)}
        />
      </div>
      <div className={expanded ? 'd-block a-mt-5' : 'd-none'}>
        <div>{children}</div>
      </div>
    </div>
  );
};
