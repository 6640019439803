import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerProps, ControllerType } from './useController';
import { useController } from './useController';

export type AccountListTableProps = ControllerProps;

const View: FC<ControllerType> = (props) => {
  const { columnDefs, defaultColDef, agGridProps, getRowId, tableRef } = props;

  return (
    <BaseEditTable
      {...agGridProps}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      ref={tableRef}
    />
  );
};

export const AccountListTable = bindControllers(View, [useController]);
AccountListTable.displayName = 'AccountListTable';
