import type { ColDef, ColGroupDef } from 'ag-grid-community';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import { useMemo } from 'react';

import type { aggregated, dice } from '@org/query';
import { useTranslation } from '@org/locales';
import {
  getCellClassesForGroupsWithActions,
  isAllEqualInArray,
  valueFormatterZero,
} from '@org/utils';

import { AccountPriceIncreaseCheckboxRenderer } from './components/AccountPriceIncreaseCheckboxRenderer';

const MIXED_PRICE_INCREASE = 'Mixed';

interface UseColumnProps {
  plannedCostTypeAccountEntries?: dice.PlannedCostTypeAccountEntryDTO[];
  updatePlannedCostRevenueAccountEntry: (
    updatedPlannedAccountEntry: dice.PlannedAccountEntryDTO,
  ) => Promise<dice.PlannedAccountEntryDTO>;
  updatePlannedCostRevenueCostTypeAccountEntry: (
    updatedPlannedCostTypeAccountEntry: dice.PlannedCostTypeAccountEntryDTO,
  ) => Promise<dice.PlannedCostTypeAccountEntryDTO>;
  priceIncreaseActivated: boolean;
}

export const useColumns = ({
  plannedCostTypeAccountEntries,
  updatePlannedCostRevenueAccountEntry,
  updatePlannedCostRevenueCostTypeAccountEntry,
  priceIncreaseActivated,
}: UseColumnProps) => {
  const { t } = useTranslation();

  return useMemo(
    (): (
      | ColDef<aggregated.FlattenPlannedAccountEntries>
      | ColGroupDef<aggregated.FlattenPlannedAccountEntries>
    )[] => [
      {
        colId: 'costTypeAccountName',
        field: 'costTypeAccountName',
        flex: 1,
        hide: true,
        rowGroup: true,
      },
      {
        colId: 'accountPriceIncreaseActivated',
        field: 'accountPriceIncreaseActivated',
        headerName: '',
        flex: 1,
        maxWidth: 30,
        suppressMenu: true,
        filter: false,
        hide: !priceIncreaseActivated,
        cellClass: getCellClassesForGroupsWithActions,
        cellRenderer: (props: ICellRendererParams) => (
          <AccountPriceIncreaseCheckboxRenderer
            {...props}
            plannedCostTypeAccountEntries={plannedCostTypeAccountEntries}
            updatePlannedCostRevenueAccountEntry={updatePlannedCostRevenueAccountEntry}
            updatePlannedCostRevenueCostTypeAccountEntry={
              updatePlannedCostRevenueCostTypeAccountEntry
            }
          />
        ),
      },
      {
        hide: !priceIncreaseActivated,
        colId: 'accountPriceIncreasePercentage',
        field: 'accountPriceIncreasePercentage',
        headerName: t('main:manageMenu.managePlannedCostRevenue.lblPriceIncrease'),
        flex: 1,
        headerClass: 'ag-right-aligned-header',
        valueFormatter: ({ value, node }) => {
          if (node?.group) {
            const { originalId } = node.allLeafChildren?.at(0)?.data ?? {};

            const selectedCostTypeAccountEntry = plannedCostTypeAccountEntries?.find(
              ({ id }) => id === originalId,
            );

            const percentages = selectedCostTypeAccountEntry?.plannedAccountEntries?.map(
              (item) => item.accountPriceIncreasePercentage,
            );

            if (!isAllEqualInArray(percentages ?? [])) {
              return MIXED_PRICE_INCREASE;
            }

            return valueFormatterZero({
              value: selectedCostTypeAccountEntry?.ctaPriceIncreasePercentage,
            });
          }

          return valueFormatterZero({ value });
        },
        maxWidth: 100,
        cellClass: getCellClassesForGroupsWithActions,
        editable: (params) => {
          if (params.node.group) {
            const { originalId } = params.node.allLeafChildren?.at(0)?.data ?? {};

            const selectedCostTypeAccountEntry = plannedCostTypeAccountEntries?.find(
              ({ id }) => id === originalId,
            );

            return Boolean(selectedCostTypeAccountEntry?.ctaPriceIncreaseActivated);
          }

          return Boolean(params?.data?.accountPriceIncreaseActivated);
        },
      },

      {
        colId: 'directEntriesAmount',
        field: 'directEntriesAmount',
        headerName: t('main:manageMenu.managePlannedCostRevenue.tableColumns.totalDirectEntries'),
        editable: false,
        flex: 1,
        cellRenderer: ({ value, node: { group, allLeafChildren } }: ICellRendererParams) => {
          let totalAmount = value;

          if (group) {
            const { originalId } = allLeafChildren?.at(0)?.data ?? {};

            const entry = plannedCostTypeAccountEntries?.find(({ id }) => id === originalId);

            totalAmount = entry?.directEntriesAmount ?? '';
          }

          return <div className="text-xs">{valueFormatterZero({ value: totalAmount })}</div>;
        },
        headerClass: 'ag-right-aligned-header',
        maxWidth: 150,
        cellClass: getCellClassesForGroupsWithActions,
      },
    ],
    [
      t,
      plannedCostTypeAccountEntries,
      updatePlannedCostRevenueCostTypeAccountEntry,
      updatePlannedCostRevenueAccountEntry,
      priceIncreaseActivated,
    ],
  );
};
