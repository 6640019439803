import styled from 'styled-components';

import Spinner from '../assets/spinner.svg';

const PageWrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: transparent;

  > div {
    min-width: 600px;
    min-height: 400px;
    height: 65vh;
    width: 70vw;
  }
`;

export const displayAsyncContent = (jsxContent, isLoading) => {
  if (isLoading) {
    return (
      <PageWrapper>
        <img
          alt="spinner"
          data-test="spinner"
          src={Spinner}
        />
      </PageWrapper>
    );
  }
  return jsxContent;
};

export const displaySpinner = (isLoading, height = 32, width = 32) => {
  if (isLoading) {
    return (
      <img
        alt="spinner"
        data-test="spinner"
        height={height}
        src={Spinner}
        width={width}
      />
    );
  }

  return null;
};
