import { useCallback } from 'react';

import type { APIParams } from '../..';
import type {
  CostUnitBasicFeeDTO,
  CostUnitBasicFeeEntryDTO,
  CostUnitBasicFeeGroupDTO,
} from '../dice';
import {
  useCreateBasicFeeGroup,
  useCreateBasicFeeGroupEntry,
  useDeleteBasicFeeGroup,
  useDeleteBasicFeeGroupEntry,
  useGetBasicFeeConfig,
  useUpdateBasicFeeEntries,
  useUpdateBasicFeeGroups,
} from '../dice';

export interface UseBasicFeeParams extends APIParams<'masterConfigurationId' | 'yearId'> {
  costUnitShortName: string;
}

const flattenBasicFeeConfig = (data: CostUnitBasicFeeDTO) => {
  if (!Array.isArray(data?.basicFeeGroups)) {
    return [];
  }

  return data.basicFeeGroups?.flatMap((basicFeeGroup) => {
    const { groupName, entries, id: groupId } = basicFeeGroup;
    return (
      entries?.flatMap((items: CostUnitBasicFeeGroupDTO) => [
        {
          ...items,
          groupId,
          groupName,
        },
      ]) ?? []
    );
  });
};

export const useBasicFee = (params: UseBasicFeeParams) => {
  const { masterConfigurationId: masterConfigId, yearId, costUnitShortName } = params;
  const pathParams = {
    costUnitShortName,
    masterConfigId,
    yearId,
  };

  const { data: basicFeeConfig, isPending: isBasicFeeConfigLoading } = useGetBasicFeeConfig(
    {
      pathParams,
    },
    {
      select: flattenBasicFeeConfig,
    },
  );

  const { mutateAsync: addBasicFeeGroupAsync } = useCreateBasicFeeGroup({});

  const { mutateAsync: addBasicFeeGroupEntryAsync } = useCreateBasicFeeGroupEntry({});

  const { mutateAsync: updateBasicFeeEntriesAsync } = useUpdateBasicFeeEntries({});

  const { mutateAsync: updateBasicFeeGroupsAsync } = useUpdateBasicFeeGroups({});

  const { mutateAsync: deleteBasicFeeGroupAsync } = useDeleteBasicFeeGroup({});

  const { mutateAsync: deleteBasicFeeGroupEntryAsync } = useDeleteBasicFeeGroupEntry({});

  const addBasicFeeGroupEntry = useCallback(
    async (entry: CostUnitBasicFeeEntryDTO, groupId: string) =>
      await addBasicFeeGroupEntryAsync({
        body: entry,
        pathParams: {
          groupId,
        },
      }),
    [addBasicFeeGroupEntryAsync],
  );

  const addBasicFeeGroup = useCallback(
    async (group: CostUnitBasicFeeGroupDTO) =>
      await addBasicFeeGroupAsync({
        body: group,
      }),
    [addBasicFeeGroupAsync],
  );

  const updateBasicFeeGroups = useCallback(
    async (group: CostUnitBasicFeeGroupDTO) =>
      await updateBasicFeeGroupsAsync({
        body: [group],
      }),
    [updateBasicFeeGroupsAsync],
  );

  const updateBasicFeeEntries = useCallback(
    async (entry: CostUnitBasicFeeEntryDTO) =>
      await updateBasicFeeEntriesAsync({
        body: [entry],
      }),
    [updateBasicFeeEntriesAsync],
  );

  const deleteBasicFeeGroup = useCallback(
    async (groupId: string) =>
      await deleteBasicFeeGroupAsync({
        pathParams: {
          groupId,
        },
      }),
    [deleteBasicFeeGroupAsync],
  );

  const deleteBasicFeeGroupEntry = useCallback(
    async (groupEntryId: string) =>
      await deleteBasicFeeGroupEntryAsync({
        pathParams: {
          groupEntryId,
        },
      }),
    [deleteBasicFeeGroupEntryAsync],
  );

  return {
    addBasicFeeGroup,
    addBasicFeeGroupEntry,
    basicFeeConfig,
    deleteBasicFeeGroup,
    deleteBasicFeeGroupEntry,
    isLoading: isBasicFeeConfigLoading,
    updateBasicFeeEntries,
    updateBasicFeeGroups,
  };
};
