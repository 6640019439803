import type { TFunction } from '@org/locales';
import { showNotification } from '@org/ui';
import { isStringEmptyOrSpaces } from '@org/utils';

import type { CostCenterMappings } from '../types';

export const isValidCCMappingsOrShowNotif = <T extends CostCenterMappings>(
  rowData: T,
  t: TFunction,
) => {
  if (rowData?.assignCostCenterMappings && !rowData?.mappingKeys?.length) {
    showNotification(
      'error',
      t('main:manageMenu.manageCostCenters.alerts.error.incompleteMappings'),
    );

    return false;
  }

  return true;
};

export const isValidCCLongNameOrShowNotif = <T extends CostCenterMappings>(
  rowData: T,
  t: TFunction,
) => {
  if (isStringEmptyOrSpaces(rowData.longName)) {
    showNotification('error', t('common:alerts.error.noLongName'));

    return false;
  }

  return true;
};

export const isValidCCShortNameOrShowNotif = <T extends CostCenterMappings>(
  rowData: T,
  t: TFunction,
) => {
  if (isStringEmptyOrSpaces(rowData.shortName)) {
    showNotification('error', t('common:alerts.error.notAllFieldsFilled'));

    return false;
  }

  return true;
};

export const isValidCCcostCenterTypeOrShowNotif = <T extends CostCenterMappings>(
  rowData: T,
  t: TFunction,
) => {
  if (isStringEmptyOrSpaces(rowData.costCenterType)) {
    showNotification('error', t('common:alerts.error.notAllFieldsFilled'));

    return false;
  }

  return true;
};

export class ValidationError extends Error {
  constructor(message?: string) {
    super(message ?? 'Validation error');
    this.name = 'ValidationError';
  }
}
