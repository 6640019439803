import type { griddy } from '../../../../../query';

export type CostUnitAllocationsByCostCenterShortNameResult =
  griddy.CostCenterToCostUnitDistribution[];

export type CostUnitAllocationsByCostCenterShortName = (
  shortName: string,
) => CostUnitAllocationsByCostCenterShortNameResult;

export const costUnitAllocationsByCostCenterShortName: CostUnitAllocationsByCostCenterShortName = (
  shortName,
) => [
  {
    allocationBasisType: 'TOTAL',
    primaryCostCenterShortName: shortName,
    rowType: 'REVENUE',
  },
  {
    allocationBasisType: 'TOTAL',
    primaryCostCenterShortName: shortName,
    rowType: 'EXPENSE',
  },
];
