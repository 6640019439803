import type { CellStyle, CellStyleFunc, IRowNode } from 'ag-grid-community';

/**
 * Function create styles for row in BAB Report table.
 * Return function what accepts as first argument column props
 * @returns styles
 */
export const getCostCenterCellStyle =
  (
    costCenterType: string,
    costCenterShortName: string,
    showModifications: boolean,
    defaultStyles: CellStyle,
  ): CellStyleFunc =>
  ({ node, data }): CellStyle => {
    let nextStyle: CellStyle = {
      ...defaultStyles,
    };
    nextStyle = applyStylesToFirstLevelRow(node, nextStyle);
    nextStyle = applyStylesToManuallyCorrectedCell(
      data,
      costCenterShortName,
      showModifications,
      nextStyle,
    );
    return nextStyle;
  };

const applyStylesToFirstLevelRow = (node: IRowNode, nextStyle: CellStyle) => {
  let modifiedStyle = nextStyle;
  if (node.level === 0 && node.allChildrenCount !== null) {
    modifiedStyle = {
      ...nextStyle,
      fontWeight: 'bold',
    };
  }
  return modifiedStyle;
};

const applyStylesToManuallyCorrectedCell = (
  data: Record<'manuallyCorrectedCostCenters', string[]> | undefined,
  costCenterShortName: string,
  showModifications: boolean,
  nextStyle: CellStyle,
) => {
  let modifiedStyle = nextStyle;
  if (showModifications && data?.manuallyCorrectedCostCenters?.includes(costCenterShortName)) {
    modifiedStyle = {
      ...nextStyle,
      background:
        'linear-gradient(to top left,#0000 85%,#0089EB80 85%) top left/40px 40px no-repeat',
    };
  }
  return modifiedStyle;
};
