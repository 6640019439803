import { griddy } from '@org/query';

export const useLabels = (yearId: string) => {
  const { data: profitCenterLabels, isLoading: profitCenterLabelsLoading } =
    griddy.useGetAllLabelsByType(
      {
        queryParams: {
          fileType: 'LABELS',
          labelType: 'PROFITCENTER',
          yearId,
        },
      },
      {
        enabled: !!yearId,
        networkMode: 'offlineFirst',
        refetchOnWindowFocus: false,
        select(data) {
          return new Map(data.map(({ number, description }) => [number, description]));
        },
        staleTime: Number.POSITIVE_INFINITY,
      },
    );

  const { data: costCenterLabels, isLoading: costCentersLabelsLoading } =
    griddy.useGetAllLabelsByType(
      {
        queryParams: {
          fileType: 'LABELS',
          labelType: 'KST',
          yearId,
        },
      },
      {
        enabled: !!yearId,
        networkMode: 'offlineFirst',
        refetchOnWindowFocus: false,
        select(data) {
          return new Map(data.map(({ number, description }) => [number, description]));
        },
        staleTime: Number.POSITIVE_INFINITY,
      },
    );

  const { data: jobIdLabels, isLoading: jobIdLabelsLoading } = griddy.useGetAllLabelsByType(
    {
      queryParams: {
        fileType: 'LABELS',
        labelType: 'AUFTRAG',
        yearId,
      },
    },
    {
      enabled: !!yearId,
      networkMode: 'offlineFirst',
      refetchOnWindowFocus: false,
      select(data) {
        return new Map(data.map(({ number, description }) => [number, description]));
      },
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  return {
    costCenterLabels,
    isLoading: profitCenterLabelsLoading || costCentersLabelsLoading || jobIdLabelsLoading,
    jobIdLabels,
    profitCenterLabels,
  };
};
