import { Controller, useForm } from 'react-hook-form';

import { Combobox, ComboboxItem, ComboboxSection } from '@org/design';
import { useTranslation } from '@org/locales';
import { dice } from '@org/query';

interface WiberaYearSelectProps {
  updateSelectedWiberaYear: (yearId: string) => Promise<void>;
  selectedYearId?: string;
  disabled: boolean;
}

export const WiberaYearSelect = ({
  updateSelectedWiberaYear,
  selectedYearId,
  disabled = false,
}: WiberaYearSelectProps) => {
  const { t } = useTranslation();
  const { data: indexFactorYears } = dice.useGetIndexFactorsAllYears(
    {},
    {
      select: (data) => {
        const sortedData = data.toSorted(
          ({ year = 0 }, { year: bYear = 0 }) => Number(bYear) - Number(year),
        );

        return sortedData.reduce((acc, item) => {
          const currentItem = acc.get(item.year ?? '') ?? [];
          currentItem.push(item);
          acc.set(item.year ?? '', currentItem);

          return acc;
        }, new Map<string, dice.IndexFactorYear[]>());
      },
    },
  );

  const { control } = useForm({
    values: {
      selectedYearId,
    },
  });

  return indexFactorYears?.size ? (
    <Controller
      control={control}
      name="selectedYearId"
      render={({ field: { onChange, value = null } }) => (
        <Combobox
          aria-label={t('main:manageMenu.manageWiberaMapping.lblSelectWiberaYear')}
          isDisabled={disabled}
          onSelectionChange={async (newValue) => {
            onChange(newValue);
            await updateSelectedWiberaYear(newValue?.toString() ?? '');
          }}
          placeholder={t('main:manageMenu.manageWiberaMapping.lblSelectWiberaYear')}
          selectedKey={value}
          size="sm"
        >
          {[...indexFactorYears.entries()].map(([currentYear, years]) => (
            <ComboboxSection
              key={currentYear}
              title={currentYear}
            >
              {years.map(({ id = '', dataSource, year }) => (
                <ComboboxItem
                  key={id}
                  textValue={`${dataSource}(${year})`}
                >
                  {dataSource}
                </ComboboxItem>
              ))}
            </ComboboxSection>
          ))}
        </Combobox>
      )}
    />
  ) : null;
};
