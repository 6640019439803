import type { CellClassParams, RowClassParams } from 'ag-grid-community';

export const getAlignmentIndentClass = (params: CellClassParams | RowClassParams): string => {
  let { node } = params;
  let indent = -1;
  while (node?.parent) {
    indent += 1;
    node = node.parent;
  }
  return `alignment-indent-${indent}`;
};
