import type { FC } from 'react';

import classnames from 'classnames';

import { Svg } from '../Svg';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type LoadingScreenProps = {} & OuterProps;

export const LoadingScreen: FC<LoadingScreenProps> = (props) => {
  const { className, ...otherProps } = props;
  return (
    <div
      className={classnames(className, 'relative mr-8 h-full w-full flex-1', 'overflow-hidden')}
      data-testid="LoadingScreen"
      {...otherProps}
    >
      <div className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 text-9xl">
        <Svg
          data-testid="spinner"
          name="Spinner"
        />
      </div>
      <div className="absolute left-0 top-0 z-10 h-full w-full bg-white opacity-90" />
    </div>
  );
};
