import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Label, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface ImputedConcessionFeeProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const ImputedConcessionFeeView: FC<ImputedConcessionFeeProps> = (
  props: ImputedConcessionFeeProps,
) => {
  const { t } = useTranslation();
  const {
    autoGroupColumnDef,
    columnDefs,
    tableRef,
    rowData,
    onCellValueChange,
    modal,
    defaultColDef,
    imputedConcessionFeeEnabled,
    handleEnableImputedConcessionFeeChange,
  } = props;

  return (
    <div className="h-[calc(100vh-20rem)]">
      <div className="pb-2">
        <h6 className="my-3">{t('main:manageMenu.manageImputedConcessionFee.tableTitle')}</h6>
        <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
          <ToggleInput
            checked={imputedConcessionFeeEnabled}
            name={t('main:manageMenu.manageImputedWorkingCapital.activateImputedConcessionFee')}
            onChange={handleEnableImputedConcessionFeeChange}
          />
          {t('main:manageMenu.manageImputedWorkingCapital.activateImputedConcessionFee')}
        </Label>
      </div>
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        isDisabled={!imputedConcessionFeeEnabled}
        onCellValueChanged={onCellValueChange}
        ref={tableRef}
        rowData={rowData}
        singleClickEdit
      />
      {modal}
    </div>
  );
};

export const ImputedConcessionFeePage = bindControllers(ImputedConcessionFeeView, [useController]);

ImputedConcessionFeePage.displayName = 'ImputedConcessionFeePage';
