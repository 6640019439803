import { isObject } from '../isObject';
import { isUndefined } from '../isUndefined';

/**
 * @deprecated - use zod or valibot instead
 */
export const isObjectShape = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  RequiredField extends Partial<Record<keyof T, true>> = Partial<Record<keyof T, true>>,
>(
  test: unknown,
  requiredFields: RequiredField,
): test is T => {
  if (!isObject(test)) {
    return false;
  }

  return Object.entries(requiredFields).every(([key]) => !isUndefined(test[key]));
};
