import type { ChangeEvent } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useDebounce } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { TextInput } from '@org/ui';
import { INDEX_FACTORS_AVAILABLE_FROM_YEAR } from '@org/utils';

interface WiberaYearSelectProps {
  updateNbrOfYearsForFactorsCalculation: (value: number) => Promise<void>;
  currentYear: number;
  wiberaYearFactorNum: number | undefined;
  disabled: boolean;
}

export const WiberaFactorYearInput = ({
  updateNbrOfYearsForFactorsCalculation,
  wiberaYearFactorNum,
  currentYear,
  disabled = false,
}: WiberaYearSelectProps) => {
  const { t } = useTranslation();

  const { control } = useForm({
    defaultValues: {
      nbrOfYearsForFactorsCalculation: wiberaYearFactorNum,
    },
  });

  const updateYearFactorDb = useDebounce(async (e: ChangeEvent<HTMLInputElement>) => {
    await updateNbrOfYearsForFactorsCalculation(Number(e.target.value));
  }, 500);

  return (
    <Controller
      control={control}
      name="nbrOfYearsForFactorsCalculation"
      render={({ field }) => (
        <TextInput
          {...field}
          className="h-auto w-[10rem] text-xs hover:w-[10rem] hover:text-xs focus:border-1 focus:outline focus:outline-1 focus:outline-gray-500"
          disabled={disabled}
          max={currentYear - INDEX_FACTORS_AVAILABLE_FROM_YEAR}
          min={0}
          onChange={async (e) => {
            field.onChange(e);
            await updateYearFactorDb(e);
          }}
          pattern="[0-9]*"
          placeholder={t('main:manageMenu.manageAssetCostCenters.wiberaCalculationFactorInYears')}
          type="number"
        />
      )}
    />
  );
};
