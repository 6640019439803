export type Move = <T>(arr: T[], from: number, to: number) => T[];

// https://github.com/brownieboy/array.prototype.move/blob/master/src/array-prototype-move.js
export const move: Move = (target, from, to) => {
  const arr = [...target];

  if (from === to) {
    return arr;
  }

  if (arr.length === 0) {
    return arr;
  }

  while (from < 0) {
    from += arr.length;
  }

  while (to < 0) {
    to += arr.length;
  }

  if (to >= arr.length) {
    to = arr.length;
  }

  arr.splice(to, 0, arr.splice(from, 1)[0]!);

  return arr;
};
