import { useMemo } from 'react';

import type { dice } from '@org/query';
import { groupBy } from '@org/utils';

import type { ACCOUNT_TYPES, BabCtaEntryRecord } from '../../ReportTable/types';
import { addModificationCountsToRows, flattenAPIResponseToRows } from '../helpers';

export const usePrepareAccountData = (
  selectedConfiguration: dice.MasterConfiguration | undefined,
  ctaEntries: BabCtaEntryRecord,
  accountType: ACCOUNT_TYPES,
) => {
  const { manualCorrections, transactionModifications } = selectedConfiguration ?? {};

  // Cost Type Account -wise table data from RAVE
  return useMemo(() => {
    const transactionAccounts = groupBy(
      transactionModifications ?? [],
      'originalTransaction.accountId',
    );
    const manualCorrectionsForAccounts = groupBy(manualCorrections ?? [], 'accountId');
    const rowsData = ctaEntries ? flattenAPIResponseToRows(ctaEntries, accountType) : [];

    return addModificationCountsToRows(rowsData, transactionAccounts, manualCorrectionsForAccounts);
  }, [ctaEntries, accountType, manualCorrections, transactionModifications]);
};
