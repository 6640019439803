/**
 * focus classNames when the element is focused by keyboard.
 */
export const focusVisibleClasses = [
  'focus-visible:z-10',
  'focus-visible:outline-2',
  'focus-visible:outline-focus',
  'focus-visible:outline-offset-2',
] as const;

export const dataFocusVisibleClasses = [
  'outline-none',
  'data-[focus-visible=true]:z-10',
  'data-[focus-visible=true]:outline-2',
  'data-[focus-visible=true]:outline-focus',
  'data-[focus-visible=true]:outline-offset-2',
  'group-data-[focus-visible=true]:z-10',
  'group-data-[focus-visible=true]:outline-2',
  'group-data-[focus-visible=true]:outline-focus',
  'group-data-[focus-visible=true]:outline-offset-2',
] as const;

export const groupDataFocusVisibleClasses = [
  'outline-none',
  'group-data-[focus-visible=true]:z-10',
  'group-data-[focus-visible=true]:ring-2',
  'group-data-[focus-visible=true]:ring-focus',
  'group-data-[focus-visible=true]:ring-offset-2',
  'group-data-[focus-visible=true]:ring-offset-background',
] as const;

export const ringClasses = [
  'outline-none',
  'ring-2',
  'ring-focus',
  'ring-offset-2',
  'ring-offset-background',
] as const;

/**
 * This classes centers the element by using absolute positioning.
 */
export const translateCenterClasses = [
  'absolute',
  'top-1/2',
  'left-1/2',
  '-translate-x-1/2',
  '-translate-y-1/2',
] as const;

export const absoluteFullClasses = ['absolute', 'inset-0'] as const;

/**
 * This object defines CSS classes for collapsing adjacent variant borders.
 * It includes classes for different variants like default, primary, secondary, etc.
 */
export const collapseAdjacentVariantBorders = {
  danger: ['[&+.border-medium.border-danger]:ms-[calc(theme(borderWidth.medium)*-1)]'],
  default: ['[&+.border-medium.border-default]:ms-[calc(theme(borderWidth.medium)*-1)]'],
  primary: ['[&+.border-medium.border-primary]:ms-[calc(theme(borderWidth.medium)*-1)]'],
  secondary: ['[&+.border-medium.border-secondary]:ms-[calc(theme(borderWidth.medium)*-1)]'],
  success: ['[&+.border-medium.border-success]:ms-[calc(theme(borderWidth.medium)*-1)]'],
  warning: ['[&+.border-medium.border-warning]:ms-[calc(theme(borderWidth.medium)*-1)]'],
} as const;
