import type { CellValueChangedEvent, ICellRendererParams } from 'ag-grid-community';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';

import type { griddy } from '@org/query';
import { useEvent } from '@org/hooks';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';
import { getPrimaryCostCenters } from '@org/utils';

import type { BaseControllerType } from '../types';
import { useColumns } from './useColumns';

export type UseControllerProps = BaseControllerType;

const DEFAULT_SURCHARGE_RATE = 2;
const DEFAULT_CONSIDER_REVENUES = false;

export const useController = (props: UseControllerProps) => {
  const { masterConfiguration: selectedConfig, updateMasterConfiguration: updateConfiguration } =
    aggregated.useMasterConfiguration(props);

  const saveConfiguration = useCallback(
    async (configuration?: Partial<griddy.CorporateRiskConfig>) => {
      await updateConfiguration({
        ...selectedConfig,
        corporateRiskConfig: configuration,
      });
    },
    [selectedConfig, updateConfiguration],
  );

  const defaultRowData = (): griddy.CorporateRiskConfig => ({
    considerRevenues: DEFAULT_CONSIDER_REVENUES,
    costCenterShortNames: costCentersConfig.map((cc) => cc.shortName).filter(Boolean) as string[],
    surchargeRate: DEFAULT_SURCHARGE_RATE,
  });

  const handleActivateCorporateRisk = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      saveConfiguration(defaultRowData());
    } else {
      saveConfiguration(undefined);
    }
  });

  const handleClearRow = useEvent(() => {
    saveConfiguration({
      considerRevenues: false,
      costCenterShortNames: [],
      surchargeRate: undefined,
    });
  });

  const onCellValueChange = useEvent(async (event: CellValueChangedEvent) => {
    const colName = event.colDef.field;

    if (!colName) {
      return;
    }

    await saveConfiguration(event.node.data);
  });

  const costCentersConfig = getPrimaryCostCenters(
    selectedConfig?.costCenterConfig.costCenterMapping,
  );

  const activeCorporateRisk = !!selectedConfig?.corporateRiskConfig;

  const { modal, openModal: openDeleteModal } = useDeleteRowConfirmModal<ICellRendererParams>({
    onConfirm: handleClearRow,
  });

  const columnDefs = useColumns({
    activeCorporateRisk,
    costCentersConfig,
    openDeleteModal,
  });

  return {
    activeCorporateRisk,
    columnDefs,
    costCentersConfig,
    handleActivateCorporateRisk,
    handleClearRow,
    modal,
    onCellValueChange,
    rowData: [selectedConfig?.corporateRiskConfig ?? {}],
    saveConfiguration,
  };
};

export type ControllerType = ReturnType<typeof useController>;
