import type { ChangeEvent } from 'react';
import { useState } from 'react';

import type { APIParams, griddy } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { Button, CheckboxInput, showNotification } from '@org/ui';
import { roundNumberToTwoDigits } from '@org/utils';

import { ValueLabelRow } from './ValueLabelRow';

const DEFAULT_REVENUE_DEFINITION: griddy.RevenueDefinition = {
  applyPrecalculatedBasicCharge: false,
  applyPrecalculatedFeeRate: false,
  basicCharge: 0,
  feeRate: 0,
  feeRelevantQuantity: 0,
  precalculatedBasicCharge: 0,
  precalculatedFeeRate: 0,
  precalculatedRevenuesFromFeeRates: 0,
  precalculatedTotalFeeRevenues: 0,
  revenuesFromFeeRates: 0,
  totalFeeRevenues: 0,
};

const isRevenueDefinitionInvalid = (revenueDefinition: griddy.RevenueDefinition) => {
  const {
    basicCharge,
    feeRelevantQuantity,
    feeRate,
    applyPrecalculatedBasicCharge,
    precalculatedBasicCharge,
    applyPrecalculatedFeeRate,
    precalculatedFeeRate,
  } = revenueDefinition;

  return (
    !basicCharge ||
    !feeRelevantQuantity ||
    !feeRate ||
    Boolean(applyPrecalculatedBasicCharge && precalculatedBasicCharge === null) ||
    (applyPrecalculatedFeeRate && !precalculatedFeeRate)
  );
};

const isUnitsInvalid = (revenueDefinition: griddy.RevenueDefinition) => {
  const {
    feeRelevantQuantityUnit,
    feeRateUnit,
    applyPrecalculatedFeeRate,
    precalculatedFeeRateUnit,
  } = revenueDefinition;

  return (
    !feeRelevantQuantityUnit ||
    !feeRateUnit ||
    (applyPrecalculatedFeeRate && !precalculatedFeeRateUnit)
  );
};

interface RevenueDefinitionProps {
  apiParams: APIParams<'masterConfigurationId'>;
  data: griddy.CostUnit;
}

export const RevenueDefinition = ({ data, apiParams }: RevenueDefinitionProps) => {
  const { t } = useTranslation();

  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const defaultValue = data.revenueDefinition ?? DEFAULT_REVENUE_DEFINITION;

  const [revenueDefinition, setRevenueDefinition] = useState(defaultValue);

  const updateValue = (
    field: keyof griddy.RevenueDefinition,
    value: number | string | boolean | null,
  ) => {
    const newRevenueDefinition = {
      ...revenueDefinition,
      [field]: value,
    };

    // Special treatment for flag changes
    if (field === 'applyPrecalculatedBasicCharge' && value === false) {
      newRevenueDefinition.applyPrecalculatedBasicCharge = false;
    }
    if (field === 'applyPrecalculatedFeeRate' && value === false) {
      newRevenueDefinition.precalculatedFeeRate = 0;
    }

    // Calculations
    const {
      feeRelevantQuantity,
      feeRate,
      precalculatedFeeRate,
      basicCharge,
      precalculatedBasicCharge,
      precalculatedRevenuesFromFeeRates,
      revenuesFromFeeRates,
      applyPrecalculatedFeeRate,
      applyPrecalculatedBasicCharge,
    } = newRevenueDefinition;

    newRevenueDefinition.revenuesFromFeeRates = roundNumberToTwoDigits(
      Number(feeRelevantQuantity) * Number(feeRate),
    );

    newRevenueDefinition.totalFeeRevenues = roundNumberToTwoDigits(
      Number(basicCharge) + Number(revenuesFromFeeRates),
    );

    if (applyPrecalculatedFeeRate) {
      newRevenueDefinition.precalculatedRevenuesFromFeeRates = roundNumberToTwoDigits(
        Number(feeRelevantQuantity) * Number(precalculatedFeeRate),
      );
    }

    newRevenueDefinition.totalFeeRevenues = roundNumberToTwoDigits(
      Number(basicCharge) + Number(revenuesFromFeeRates),
    );

    if (applyPrecalculatedBasicCharge ?? applyPrecalculatedFeeRate) {
      const calculatedResult =
        (applyPrecalculatedBasicCharge ? Number(precalculatedBasicCharge) : Number(basicCharge)) +
        (applyPrecalculatedFeeRate
          ? Number(precalculatedRevenuesFromFeeRates)
          : Number(revenuesFromFeeRates));

      newRevenueDefinition.precalculatedTotalFeeRevenues = roundNumberToTwoDigits(calculatedResult);
    }

    setRevenueDefinition(newRevenueDefinition);
  };

  const updateRevenueDefinitionConfig = () => {
    // Validation of mandatory fields
    if (isUnitsInvalid(revenueDefinition)) {
      showNotification('warning', t('main:manageMenu.manageCostUnits.alerts.warning.unitMissing'));
      return;
    }

    if (isRevenueDefinitionInvalid(revenueDefinition)) {
      showNotification(
        'warning',
        t('main:manageMenu.manageCostUnits.alerts.warning.revenueDefinitionMissingFields'),
      );
      return;
    }

    const updatedMasterConfiguration = {
      ...masterConfiguration,
      costUnitConfig: masterConfiguration?.costUnitConfig.map((costUnitConfig) =>
        costUnitConfig.shortName === data.shortName
          ? {
              ...costUnitConfig,
              revenueDefinition,
            }
          : costUnitConfig,
      ),
    };

    updateMasterConfiguration(updatedMasterConfiguration);

    showNotification(
      'success',
      t('main:manageMenu.manageCostUnits.alerts.success.revenueDefinitionUpdated'),
    );
  };

  return (
    <div className="h-full overflow-scroll bg-white p-8">
      <span className="font-weight-bold mb-2 text-red-default">
        {t('main:manageMenu.manageCostUnits.revenueDefinition.clickOnTwoCheckboxes')}
      </span>
      <div className="left">
        <span className="font-weight-bold">
          {t('main:manageMenu.manageCostUnits.revenueDefinition.headerLeft')}
        </span>
        <ValueLabelRow
          field="basicCharge"
          revenueDefinition={revenueDefinition}
          updateValue={updateValue}
        />

        <span className="d-flex a-mt-12">
          <CheckboxInput
            checked={!!revenueDefinition.applyPrecalculatedBasicCharge}
            className="pr-2"
            onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
              updateValue('applyPrecalculatedBasicCharge', checked);
            }}
          />
          {t('main:manageMenu.manageCostUnits.revenueDefinition.applyPrecalculatedBasicCharge')}
        </span>

        {revenueDefinition.applyPrecalculatedBasicCharge && (
          <ValueLabelRow
            field="precalculatedBasicCharge"
            revenueDefinition={revenueDefinition}
            updateValue={updateValue}
          />
        )}
      </div>

      <div className="right">
        <span className="font-weight-bold">
          {t('main:manageMenu.manageCostUnits.revenueDefinition.headerRight')}
        </span>

        <ValueLabelRow
          field="feeRelevantQuantity"
          revenueDefinition={revenueDefinition}
          unitField="feeRelevantQuantityUnit"
          updateValue={updateValue}
        />

        <ValueLabelRow
          field="feeRate"
          revenueDefinition={revenueDefinition}
          unitField="feeRateUnit"
          updateValue={updateValue}
        />

        <span className="d-flex a-mt-12">
          <CheckboxInput
            checked={!!revenueDefinition.applyPrecalculatedFeeRate}
            className="pr-2"
            onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
              updateValue('applyPrecalculatedFeeRate', checked);
            }}
          />
          {t('main:manageMenu.manageCostUnits.revenueDefinition.applyPrecalculatedFeeRate')}
        </span>

        {revenueDefinition.applyPrecalculatedFeeRate && (
          <ValueLabelRow
            field="precalculatedFeeRate"
            revenueDefinition={revenueDefinition}
            unitField="precalculatedFeeRateUnit"
            updateValue={updateValue}
          />
        )}

        <ValueLabelRow
          field="revenuesFromFeeRates"
          readOnly
          revenueDefinition={revenueDefinition}
          updateValue={updateValue}
        />

        {revenueDefinition.applyPrecalculatedFeeRate && (
          <ValueLabelRow
            field="precalculatedRevenuesFromFeeRates"
            readOnly
            revenueDefinition={revenueDefinition}
            updateValue={updateValue}
          />
        )}
      </div>

      <div className="bottom-left">
        <ValueLabelRow
          field="totalFeeRevenues"
          readOnly
          revenueDefinition={revenueDefinition}
          updateValue={updateValue}
        />

        {(revenueDefinition.applyPrecalculatedBasicCharge ??
          revenueDefinition.applyPrecalculatedFeeRate) && (
          <div className="border-b">
            <ValueLabelRow
              field="precalculatedTotalFeeRevenues"
              readOnly
              revenueDefinition={revenueDefinition}
              updateValue={updateValue}
              warningText={`(${t(
                'main:manageMenu.manageCostUnits.revenueDefinition.precalculatedTotalFeeRevenuesWarning',
              )})`}
            />
          </div>
        )}
      </div>
      <div className="mt-8 flex flex-row-reverse border-t border-gray-500">
        <Button
          className="value w-50"
          disabled={data.revenueDefinition === revenueDefinition}
          onClick={updateRevenueDefinitionConfig}
          size="md"
          variant="primary"
        >
          {t('common:btnUpdate')}
        </Button>
      </div>
      <div />
    </div>
  );
};
