import { useTranslation } from '@org/locales';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { PublicFacilitiesModalContentProps } from '..';

export const DeletePublicFacilityModalContent = ({
  disclosureState,
  selectedPublicFacility,
  deletePublicFacility,
}: PublicFacilitiesModalContentProps) => {
  const { t } = useTranslation();
  const { name = '', id } = selectedPublicFacility;
  const { onClose } = disclosureState;

  return (
    <>
      <ModalHeader>
        {t('common:contextMenu.modalDelete.title', {
          name,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>{t('common:contextMenu.modalDelete.label')}</ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common:btnCancel')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!name}
          onClick={async () => {
            try {
              await deletePublicFacility(id!);
              onClose?.();
            } catch {
              showNotification(
                'warning',
                t('main:clients.clientsOverviewPanel.alerts.warning.general'),
              );
            }
          }}
          size="md"
          variant="primary"
        >
          {t('common:contextMenu.modalDelete.btnDelete')}
        </Button>
      </ModalFooter>
    </>
  );
};
