import type {
  CellClassParams,
  ColDef,
  ColGroupDef,
  IAggFunc,
  RowClassParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import { useCustomLabels } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { getCellClasses as baseGetCellClasses, getAlignmentIndentClass } from '@org/utils';

import type { BaseControllerType } from '../types';
import { boldClasses } from './classes';

const fakeGroupClasses = (cellClassParams: CellClassParams | RowClassParams) => {
  if (cellClassParams.data?.fakeGroup) {
    return 'fake-group ag-row-group-indent-1';
  }
  return '';
};

const getCellClasses =
  (...extraCellClassFunctions: ((cellClassParams: CellClassParams | RowClassParams) => string)[]) =>
  (params: CellClassParams) => {
    if (params.data?.fakeGroup) {
      return extraCellClassFunctions.map((cellClassFunction) => cellClassFunction(params));
    }

    return baseGetCellClasses(...extraCellClassFunctions)(params);
  };

const aggFunction: IAggFunc<aggregated.CostUnitReportData[number]> = ({ values, rowNode }) => {
  if (rowNode.group && rowNode.level === 1 && rowNode.key === 'subTotal') {
    return values.at(0) - values.at(1);
  }

  return values.reduce((acc, value = 0) => acc + value, 0);
};

export const useColumns = (apiParams: BaseControllerType) => {
  const { t } = useTranslation();

  const { masterConfiguration } = aggregated.useMasterConfiguration(apiParams);
  const costUnits = useMemo(
    () =>
      masterConfiguration?.costUnitConfig.toSorted((a, b) => (a.rank ?? 0) - (b.rank ?? 0)) ?? [],
    [masterConfiguration?.costUnitConfig],
  );

  const getCustomLabel = useCustomLabels(apiParams);

  return useMemo<
    (
      | ColDef<aggregated.CostUnitReportData[number]>
      | ColGroupDef<aggregated.CostUnitReportData[number]>
    )[]
  >(
    () => [
      {
        children: [
          {
            field: 'rowName',
            pinned: 'left',
            showRowGroup: true,
            cellRenderer: 'agGroupCellRenderer',
            valueGetter: ({ data }) =>
              getCustomLabel(
                `costUnit.${data?.rowName}`,
                t(`main:reportPanels.costUnits.tableRows.${data?.rowName}`, {
                  defaultValue: data?.rowName,
                }),
              ),
            minWidth: 300,
            headerName: '',
            cellClass: getCellClasses(boldClasses, getAlignmentIndentClass, fakeGroupClasses),
          },
          {
            field: 'group',
            hide: true,
            rowGroup: true,
            valueGetter: ({ data }) =>
              data?.group &&
              getCustomLabel(
                `costUnit.${data?.group}`,
                t(`main:reportPanels.costUnits.tableRows.${data?.group}`),
              ),
          },
          {
            field: 'subGroup',
            hide: true,
            rowGroup: true,
            valueGetter: ({ data }) =>
              data?.subGroup &&
              getCustomLabel(
                `costUnit.${data?.subGroup}`,
                t(`main:reportPanels.costUnits.tableRows.${data?.subGroup}`),
              ),
          },
          {
            field: 'subSubGroup',
            hide: true,
            rowGroup: true,
            valueGetter: ({ data }) =>
              data?.subSubGroup &&
              getCustomLabel(
                `costUnit.${data?.subSubGroup}`,
                t(`main:reportPanels.costUnits.tableRows.${data?.subSubGroup}`),
              ),
          },
          {
            aggFunc: aggFunction,
            field: 'total',
            pinned: 'left',
            width: 120,
            type: 'report-price',
            headerName: t('main:reportPanels.costUnits.totalColumn'),
            cellClass: getCellClasses(boldClasses, () => 'ag-cell-type-report-price'),
          },
        ],
        headerName: '',
        headerClass: 'bg-white',
      },
      {
        children: costUnits.map(({ shortName, longName }) => ({
          aggFunc: aggFunction,
          field: shortName,
          headerName: longName,
          headerTooltip: longName,
          type: 'report-price',
          cellClass: getCellClasses(boldClasses, () => 'ag-cell-type-report-price'),
        })),
        headerName: t('common:costUnit'),
        headerClass: 'bg-white',
      },
    ],
    [costUnits, getCustomLabel, t],
  );
};
