import { CalendarDaysIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { Card } from '@nextui-org/react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { formatToSimpleDate } from '@org/utils';

interface YearCardProps {
  LinkComponent: React.ComponentType;
  type: string;
  yearCalculation: dice.YearCalculationDTO;
  getLinkProps: ({
    type,
    yearCalculationId,
  }: {
    type: string;
    yearCalculationId: string;
  }) => Record<string, unknown>;
}

export const YearCard = ({ yearCalculation, type, LinkComponent, getLinkProps }: YearCardProps) => {
  const { t } = useTranslation();

  return (
    <Card
      as={LinkComponent}
      className="h-[120px] w-full rounded-md p-4 hover:bg-actions-hover hover:text-black hover:no-underline"
      {...getLinkProps({
        type,
        yearCalculationId: yearCalculation?.id ?? '',
      })}
    >
      <div className="space-y-4">
        <div className="text-lg font-medium">
          {t(`main:reportGeneration.selectionSection.${type}Calculation`)}
        </div>
        <div className="flex items-center justify-between space-x-4 text-sm text-gray-500">
          <div className="flex space-x-2">
            <CalendarDaysIcon className="h-5 w-5" />
            <div>
              {yearCalculation.lastUpdateIsoDateTime &&
                formatToSimpleDate(
                  new Date(yearCalculation.lastUpdateIsoDateTime ?? ''),
                  'MMM dd, yyyy',
                )}
            </div>
          </div>
          {yearCalculation.configActivityStatus === 'FINALIZED' && (
            <div className="flex space-x-2">
              <CheckCircleIcon className="h-5 w-5 text-green-500" />
              <span className="capitalize">{t('main:final')}</span>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
