import type { ICellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';
import { memo } from 'react';

import {
  AdjustmentsVerticalIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
  SparklesIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import classnames from 'classnames';

import type { erase } from '@org/query';
import { useTranslation } from '@org/locales';
import { sortByOrder } from '@org/utils';

import { Tooltip } from '../Tooltip';

type OuterProps = React.JSX.IntrinsicElements['div'];

export interface StatusCellRendererProps extends OuterProps, Omit<ICellRendererParams, 'value'> {
  value: erase.Asset | erase.Asset[];
  onRefresh?: (value: erase.Asset | erase.Asset[]) => void;
}

const iconParams = {
  height: 20,
  width: 20,
} as const;

const allIconsByStatus = {
  AUTOMATIC: (
    <SparklesIcon
      {...iconParams}
      className="text-green-default"
    />
  ),
  MANUAL: (
    <AdjustmentsVerticalIcon
      {...iconParams}
      className="text-green-light"
    />
  ),
  NONE: <XCircleIcon {...iconParams} />,
  WARNING: (
    <ExclamationTriangleIcon
      {...iconParams}
      className="text-orange-default"
    />
  ),
} satisfies Record<Required<erase.Asset>['currentCcMappingStatus'], React.JSX.Element>;

const ICON_ARRAY_ORDER = ['AUTOMATIC', 'MANUAL', 'REDO', 'NONE', 'WARNING'];

export const StatusCellRenderer: FC<StatusCellRendererProps> = memo((props) => {
  const { className, value, onRefresh } = props;
  const { t } = useTranslation();

  const valueAsArray = Array.isArray(value) ? value : [value];

  const iconsByStatus = [
    ...new Set(valueAsArray.map(({ currentCcMappingStatus }) => currentCcMappingStatus)),
  ].flatMap((item) => (item ? [{ icon: allIconsByStatus[item], status: item }] : []));

  const sortedStatuses = sortByOrder(iconsByStatus, 'status', ICON_ARRAY_ORDER);

  return (
    <div
      className={classnames('flex items-center justify-items-start space-x-2', className)}
      data-testid="StatusCellRenderer"
    >
      {sortedStatuses.map(({ status, icon }, idx) => (
        <Tooltip
          content={t(`main:enums.currentCcMappingStatus.${status}`)}
          key={idx}
        >
          {icon}
        </Tooltip>
      ))}
      {valueAsArray
        ?.map(({ currentCcMappingStatus }) => currentCcMappingStatus)
        ?.includes('MANUAL') && (
        <Tooltip
          className="justify-self-end"
          content={t('main:manageMenu.manageAssetCostCenters.reset')}
        >
          <div
            aria-hidden
            className="cursor-pointer"
            onClick={() => onRefresh?.(value)}
          >
            <ArrowPathIcon
              {...iconParams}
              className="text-orange-default"
              cursor="pointer"
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
});

StatusCellRenderer.displayName = 'StatusCellRenderer';
