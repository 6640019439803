import type {
  CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GetRowIdParams,
  IDetailCellRendererParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useDisclosure } from '@nextui-org/react';
import { useForm, useWatch } from 'react-hook-form';

import type { APIParams, dice } from '@org/query';
import { useAgGridData, useDebounce, useReinitializeForm } from '@org/hooks';
import { t } from '@org/locales';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';

import { useColumns } from './useColumns';

export interface ControllerProps extends IDetailCellRendererParams {
  apiParams: APIParams<'masterConfigurationId'>;
}

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
} satisfies ColDef;

const amountModifier = (colName: string, data: dice.PlannedDirectEntryDTO) => {
  if (colName === 'amount') {
    return {
      ...data,
      detail: undefined,
    };
  }

  return data;
};

export const useController = ({ data, apiParams }: ControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);

  const manualTableRef = useRef<AgGridReact>(null);
  const distributionTableRef = useRef<AgGridReact>(null);
  const technicalTableRef = useRef<AgGridReact>(null);

  const [technicalModalEntryId, setTechnicalModalEntryId] = useState<string>();

  const {
    plannedAccountEntries,
    updatePlannedCostRevenueAccountEntry,
    createPlannedCostRevenueDirectEntry,
  } = aggregated.usePlannedCostRevenue(apiParams);

  const selectedPlannedAccountEntry = useMemo(
    () => plannedAccountEntries.find((entry) => entry?.id === data.id),
    [plannedAccountEntries, data.id],
  );

  const { getPlannedDirectEntry } = aggregated.usePlannedCostRevenue(apiParams);

  const [selectedPlannedDirectEntryId, setSelectedDirectEntryId] = useState<
    dice.PlannedDirectEntryDTO['id']
  >(selectedPlannedAccountEntry?.plannedDirectEntries?.at(0)?.id ?? '');

  const selectedPlannedDirectEntry = getPlannedDirectEntry({
    plannedAccountEntryId: selectedPlannedAccountEntry?.id ?? '',
    plannedCostTypeAccountEntryId: selectedPlannedDirectEntryId ?? '',
  });

  const handleAddNew = useCallback(async () => {
    await createPlannedCostRevenueDirectEntry({
      directEntryName: t('main:manageMenu.managePlannedCostRevenue.tableColumns.newEntry'),
      plannedAccountEntryId: selectedPlannedAccountEntry?.id ?? '',
      plannedCostTypeAccountEntryId: selectedPlannedAccountEntry?.originalId ?? '',
    });
  }, [
    createPlannedCostRevenueDirectEntry,
    selectedPlannedAccountEntry?.id,
    selectedPlannedAccountEntry?.originalId,
  ]);

  const getRowId = useCallback(
    ({ data: getRowParamsData }: GetRowIdParams) => getRowParamsData?.id,
    [],
  );

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: selectedPlannedAccountEntry?.plannedDirectEntries ?? [],
  });

  const methodOptions = useMemo(
    () => [
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.MANUAL`),
        value: 'MANUAL',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.TECHNICALDATA`),
        value: 'TECHNICAL_DATA',
      },
      {
        label: t(`main:manageMenu.managePlannedCostRevenue.enums.DistributionMode.DISTRIBUTION`),
        value: 'DISTRIBUTION',
      },
    ],
    [],
  );

  const defaultValues = useMemo(
    () => ({
      methodType: selectedPlannedDirectEntry?.distributionConfig?.allocationMethod ?? '',
      comment: selectedPlannedAccountEntry?.comment ?? '',
    }),
    [
      selectedPlannedAccountEntry?.comment,
      selectedPlannedDirectEntry?.distributionConfig?.allocationMethod,
    ],
  );

  const { control, reset } = useForm({
    defaultValues,
  });

  useReinitializeForm({ defaultValues, reset });

  const [methodType] = useWatch({
    control,
    defaultValue: defaultValues,
    name: ['methodType'],
  });

  const onRowSelectionChanged = useCallback((value: SelectionChangedEvent) => {
    const { api: eventApi } = value;
    const selectedRow = eventApi.getSelectedRows()?.[0];

    setSelectedDirectEntryId(selectedRow?.id);
  }, []);

  const onFirstSelectFirst = useCallback(
    (params: FirstDataRenderedEvent) => params.api.getDisplayedRowAtIndex(0)!.setSelected(true),
    [],
  );

  const updateAndReplacePlannedCostRevenueDirectEntry = useCallback(
    async (updatedDirectEntry: dice.PlannedDirectEntryDTO) => {
      await updatePlannedCostRevenueAccountEntry({
        ...selectedPlannedAccountEntry,
        plannedDirectEntries: selectedPlannedAccountEntry?.plannedDirectEntries?.map((entry) =>
          entry.id === selectedPlannedDirectEntry?.id ? updatedDirectEntry : entry,
        ),
      });
    },
    [selectedPlannedAccountEntry, selectedPlannedDirectEntry, updatePlannedCostRevenueAccountEntry],
  );

  const handleOnChange = useCallback(
    async (event: CellValueChangedEvent) => {
      const {
        colDef: { field: colName },
        data: updatedDirectEntry,
      } = event;

      if (!colName) {
        return;
      }

      if (updatedDirectEntry.id) {
        await updateAndReplacePlannedCostRevenueDirectEntry(
          amountModifier(colName, updatedDirectEntry),
        );
      }
    },
    [updateAndReplacePlannedCostRevenueDirectEntry],
  );

  const handleOnChangeMethod = useCallback(
    async (selectedMethod: dice.PlannedDirectEntryDistributionConfigDTO['allocationMethod']) => {
      const updatedDirectEntry = {
        ...selectedPlannedDirectEntry,
        distributionConfig: {
          ...selectedPlannedDirectEntry?.distributionConfig,
          allocationMethod: selectedMethod,
          allSelected:
            selectedPlannedDirectEntry?.distributionConfig === null ||
            selectedPlannedDirectEntry?.distributionConfig?.allSelected,
        },
      };

      await updateAndReplacePlannedCostRevenueDirectEntry(updatedDirectEntry);
    },
    [selectedPlannedDirectEntry, updateAndReplacePlannedCostRevenueDirectEntry],
  );

  const handleRemoveCost = useCallback(
    async (id: string) => {
      await updatePlannedCostRevenueAccountEntry({
        ...selectedPlannedAccountEntry,
        plannedDirectEntries: selectedPlannedAccountEntry?.plannedDirectEntries?.filter(
          (currentEntry) => currentEntry.id !== id,
        ),
      });
    },
    [selectedPlannedAccountEntry, updatePlannedCostRevenueAccountEntry],
  );

  const { modal, openModal } = useDeleteRowConfirmModal<string>({
    onConfirm: handleRemoveCost,
  });

  const {
    onOpenChange: onOpenChangeTechnicalModal,
    isOpen: isOpenTechnicalModal,
    onOpen: openTechnicalModal,
  } = useDisclosure();

  const handleOpenTechnicalModal = useCallback(
    (id: string) => {
      setTechnicalModalEntryId(id);
      openTechnicalModal();
    },
    [openTechnicalModal],
  );

  const handleOnCommentChange = useDebounce(
    useCallback(
      async (newComment: string) => {
        await updatePlannedCostRevenueAccountEntry({
          ...selectedPlannedAccountEntry,
          comment: newComment,
        });
      },
      [selectedPlannedAccountEntry, updatePlannedCostRevenueAccountEntry],
    ),
    500,
  );

  const columnDefs = useColumns({
    handleAddNew,
    openDeleteModal: openModal,
    openTechnicalModal: handleOpenTechnicalModal,
  });

  const allSelected = useMemo(
    () => Boolean(selectedPlannedDirectEntry?.distributionConfig?.allSelected),
    [selectedPlannedDirectEntry?.distributionConfig?.allSelected],
  );

  const handleOnSelectAllDistribution = useCallback(
    async (isSelected: boolean) => {
      const updatedDirectEntry = {
        ...selectedPlannedDirectEntry,
        distributionConfig: {
          ...selectedPlannedDirectEntry?.distributionConfig,
          allSelected: isSelected,
        },
      };

      await updateAndReplacePlannedCostRevenueDirectEntry(updatedDirectEntry);
    },
    [selectedPlannedDirectEntry, updateAndReplacePlannedCostRevenueDirectEntry],
  );

  return {
    control,
    agGridProps,
    onFirstSelectFirst,
    columnDefs,
    defaultColDef,
    getRowId,
    tableRef,
    methodOptions,
    currentMethodType: methodType,
    technicalTableRef,
    selectedPlannedDirectEntryId,
    apiParams,
    selectedPlannedAccountEntry,
    onRowSelectionChanged,
    handleOnChangeMethod,
    handleOnChange,
    handleRemoveCost,
    distributionTableRef,
    manualTableRef,
    modal,
    handleOnCommentChange,
    updateAndReplacePlannedCostRevenueDirectEntry,
    isOpenTechnicalModal,
    onOpenChangeTechnicalModal,
    technicalModalEntryId,
    handleOnSelectAllDistribution,
    allSelected,
  };
};

export type ControllerType = ReturnType<typeof useController>;
