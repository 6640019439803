// @ts-check

import { createElement, useEffect, useState } from 'react';

import { Button } from 'appkit-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification } from '@org/ui';

import { objGet, objSet } from '../../utils';
import { handleShowAlert } from '../../utils/Alerts';
import { downloadJSON } from '../../utils/fileDownload';
import { displayAsyncContent } from '../../utils/spinner';
import { openReport } from '../Clients/ConfigurationsOverviewPanel/openReport';
import { CONFIG_ITEMS } from '../Clients/Menu/Utils/configMenuItems';
import { ConfigPagePanelWrapper } from '../Clients/StyledComponents';

function ConfigurationPanel({ selectedYear, configItem }) {
  const { t } = useTranslation();
  const {
    configId = '',
    yearId = '',
    type = '',
    clientId = '',
    publicFacilityId = '',
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { masterConfiguration: configuration, updateMasterConfiguration: updateConfiguration } =
    aggregated.useMasterConfiguration({
      masterConfigurationId: configId,
    });

  const { recalculateReport } = aggregated.useReport({
    clientId,
    masterConfigurationId: configId,
    publicFacilityId,
    type,
    yearId,
  });

  const { selectedEntity } = aggregated.useMasterConfigurationByYearCalculation({
    masterConfigurationId: configId,
    yearCalculationId: yearId ?? '',
  });

  const isFinalized = selectedEntity?.configActivityStatus === 'FINALIZED';

  const [isCalculating, setIsCalculating] = useState(false);

  const provideRowsWithCorrectRanks = (rows) =>
    rows.sort((a, b) => a.rank - b.rank).map((row, idx) => ({ ...row, rank: idx + 1 }));

  const updateConfigWithCorrectRowRanks = async (config) => {
    await updateConfiguration(provideRowsWithCorrectRanks(config));
  };

  const moveRowUp = async (currentRow, configName, identifyingField) => {
    const rowsToUpdate = provideRowsWithCorrectRanks([...objGet(configuration, configName)]);

    const currentRowIndex = rowsToUpdate.findIndex(
      (row) => objGet(row, identifyingField) === objGet(currentRow, identifyingField),
    );

    if (currentRowIndex <= 0) {
      return;
    }

    const { rank } = objGet(rowsToUpdate, currentRowIndex);

    objGet(rowsToUpdate, currentRowIndex).rank = objGet(rowsToUpdate, currentRowIndex - 1).rank;
    objGet(rowsToUpdate, currentRowIndex - 1).rank = rank;
    rowsToUpdate.sort((a, b) => a.rank - b.rank);
    await updateConfiguration({ ...configuration, [configName]: rowsToUpdate });
    handleShowAlert('success', t('main:manageMenu.alerts.success.changedRank'));
  };

  const moveRowDown = async (currentRow, configName, identifyingField) => {
    const rowsToUpdate = provideRowsWithCorrectRanks([...objGet(configuration, configName)]);

    const currentRowIndex = rowsToUpdate.findIndex(
      (row) => objGet(row, identifyingField) === objGet(currentRow, identifyingField),
    );

    if (currentRowIndex < 0 || currentRowIndex >= rowsToUpdate.length - 1) {
      return;
    }

    const { rank } = objGet(rowsToUpdate, currentRowIndex);

    objGet(rowsToUpdate, currentRowIndex).rank = objGet(rowsToUpdate, currentRowIndex + 1).rank;
    objGet(rowsToUpdate, currentRowIndex + 1).rank = rank;

    rowsToUpdate.sort((a, b) => a.rank - b.rank);
    await updateConfiguration({ ...configuration, [configName]: rowsToUpdate });
    handleShowAlert('success', t('main:manageMenu.alerts.success.changedRank'));
  };

  const addRow = async (newRow, configName) => {
    if (objGet(configuration, configName) === null) {
      objSet(configuration, configName, []);
    }
    const updatedRows = [...objGet(configuration, configName), newRow];
    await updateConfiguration({ ...configuration, [configName]: updatedRows });
    handleShowAlert('success', t('main:manageMenu.alerts.success.addedRow'));
  };

  const updateRankRows = (rowToDelete, updatedRows) => {
    if (updatedRows && rowToDelete && rowToDelete.rank) {
      updatedRows.forEach((row) => {
        if (row.rank > rowToDelete.rank) {
          row.rank -= 1;
        }
      });
    }
  };

  const deleteRow = async (configName) => {
    const updatedRows = objGet(configuration, configName).filter((row) => !row.tableData.editing);
    const rowToDelete = objGet(configuration, configName).find((row) => row.tableData.editing);
    updateRankRows(rowToDelete, updatedRows);
    await updateConfiguration({ ...configuration, [configName]: updatedRows });
    handleShowAlert('success', t('main:manageMenu.alerts.success.deletedRow'));
  };

  const deleteRowWithKeyAndValue = async (key, value, configName) => {
    const updatedRows = objGet(configuration, configName).filter(
      (row) => objGet(row, key) !== value,
    );
    const rowToDelete = objGet(configuration, configName).find((row) => objGet(row, key) === value);
    updateRankRows(rowToDelete, updatedRows);
    await updateConfiguration({ ...configuration, [configName]: updatedRows });
    handleShowAlert('success', t('main:manageMenu.alerts.success.deletedRow'));
  };

  const deleteRowsWithKeyAndValues = async (key, values, configName) => {
    const updatedRows = objGet(configuration, configName).filter(
      (row) => !values.includes(objGet(row, key)),
    );
    await updateConfiguration({ ...configuration, [configName]: updatedRows });
    handleShowAlert('success', t('main:manageMenu.alerts.success.deletedRows'));
  };

  const updateRow = async (newRow, oldRow, configName) => {
    const updatedRows = [
      ...objGet(configuration, configName).filter((row) => !row.tableData.editing),
      newRow,
    ];
    await updateConfiguration({ ...configuration, [configName]: updatedRows });
    handleShowAlert('success', t('main:manageMenu.alerts.success.updatedRow'));
  };

  const updateRowExtended = async (newRow, oldRow, configName, additionalUpdatedConfigObject) => {
    const updatedRows = [
      ...objGet(configuration, configName).filter((row) => !row.tableData.editing),
      newRow,
    ];
    await updateConfiguration({
      ...configuration,
      [configName]: updatedRows,
      ...additionalUpdatedConfigObject,
    });
    handleShowAlert('success', t('main:manageMenu.alerts.success.updatedRow'));
  };

  const renderHeaderAndActions = () => (
    <div className="a-mt-20">
      <h4 className="d-inline-block align-items-center">
        {selectedEntity ? selectedEntity?.name : t('common:configuration')}
      </h4>

      <div className="float-right">
        <Button
          onClick={() =>
            downloadJSON(
              configuration,
              `${selectedYear?.year ?? ''} - ${configuration?.name ?? ''}`,
            )
          }
          style={{ marginRight: '15px' }}
        >
          {t('common:downloadJSON')}
        </Button>

        {selectedEntity?.reportId && (
          <Button
            onClick={() =>
              openReport({
                configuration,
                location,
                navigate,
                recalculateReports: recalculateReport,
                selectedYear,
                setIsCalculating,
                t,
              })
            }
            style={{ marginRight: '15px' }}
          >
            {t('common:goToReport')}
          </Button>
        )}

        <Button
          disabled={isFinalized}
          onClick={() =>
            openReport({
              configuration,
              location,
              navigate,
              recalculate: true,
              recalculateReports: recalculateReport,
              selectedYear,
              setIsCalculating,
              t,
            })
          }
          style={{ marginRight: '15px' }}
        >
          {t('common:recalculate')}
        </Button>
      </div>
      <hr className="a-m-5" />
    </div>
  );

  const configItemToRender = CONFIG_ITEMS.find((option) => option.value === configItem);

  useEffect(() => {
    if (selectedEntity?.configActivityStatus === 'FINALIZED') {
      showNotification(
        'warning',
        <span className="font-semibold">{t('common:finalizedConfigurationWarningTitle')}</span>,
        false,
        t('common:finalizedConfigurationWarningDescription'),
      );
    }
  }, [selectedEntity?.configActivityStatus, t]);

  const createConfigPage = () => (
    <ConfigPagePanelWrapper>
      <div
        className="flex h-full w-full flex-col bg-white px-4 shadow-sm"
        id="config-content"
      >
        {!configItemToRender?.hideCommonHeader && renderHeaderAndActions()}
        {!_.isEmpty(configuration) &&
          configItemToRender?.view &&
          createElement(configItemToRender.view, {
            addRow,
            deleteRow,
            deleteRowWithKeyAndValue,
            deleteRowsWithKeyAndValues,
            disableEditing: isFinalized,
            moveRowDown,
            moveRowUp,
            selectedConfig: configuration,
            selectedYear,
            setIsCalculating,
            updateConfigWithCorrectRowRanks,
            updateConfiguration,
            updateRankRows,
            updateRow,
            updateRowExtended,
          })}
      </div>
    </ConfigPagePanelWrapper>
  );

  return displayAsyncContent(<>{createConfigPage()}</>, isCalculating);
}

ConfigurationPanel.propTypes = {
  configItem: PropTypes.string,
  selectedYear: PropTypes.object,
};
export default ConfigurationPanel;
