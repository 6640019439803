import type {
  CellEditingStoppedEvent,
  EditableCallbackParams,
  GetRowIdParams,
} from 'ag-grid-community';
import type { AgGridReact as AgGridReactRef } from 'ag-grid-react';
import type { RefObject } from 'react';
import { forwardRef, useMemo } from 'react';

import classnames from 'classnames';

import {
  getCopyOfTableData,
  GROUP_COLUMN_ID,
  isNil,
  updateAgGridIdsByAutoGeneratedValues,
} from '@org/utils';

import type { BaseTableProps } from '../BaseTable';
import { BaseTable } from '../BaseTable';
import { ButtonCellRenderer } from '../ButtonCellRenderer';
import { CheckboxCellRenderer } from '../CheckboxCellRenderer';
import { DatePickerCellEditor } from '../DatePickerCellEditor';
import { MultipleButtonsRenderer } from '../MultipleButtonsRenderer';
import { NumericCellField } from '../NumericCellField';
import { SelectCellEditor } from '../SelectCellEditor';

export const isTopLevelRow = (params: EditableCallbackParams) => params?.node?.level === 0;

const getAgGridRowId = (params: GetRowIdParams) => {
  if (isNil(params.data.agGridRowId)) {
    params.data.agGridRowId = crypto.randomUUID();
  }
  return params.data.agGridRowId;
};

const isGroupCell = (event: CellEditingStoppedEvent) =>
  Boolean(event.node.group ?? event.colDef.colId === GROUP_COLUMN_ID);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseEditTableProps<Data = any> = {
  isDisabled?: boolean;
  setIsEditing?: (value: boolean) => void;
  onChange?: (data: Data[], event?: CellEditingStoppedEvent) => void;
  onGroupCellChange?: (event: CellEditingStoppedEvent) => void;
  ref?: RefObject<AgGridReactRef>;
  useAutoGeneratedRowId?: boolean;
} & BaseTableProps<Data>;

type BaseEditTableComponent = <Data>(
  props: BaseEditTableProps<Data>,
  ref: RefObject<AgGridReactRef>,
) => React.JSX.Element | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OriginalBaseTable = forwardRef<AgGridReactRef, BaseEditTableProps<any>>((props, ref) => {
  const {
    isDisabled,
    setIsEditing,
    defaultColDef,
    onChange,
    onGroupCellChange,
    rowData,
    onCellEditingStarted,
    useAutoGeneratedRowId,
    components,
    getRowId,
    ...otherProps
  } = props;

  const updatedRowData = useMemo(
    () => (useAutoGeneratedRowId ? updateAgGridIdsByAutoGeneratedValues(rowData!) : rowData),
    [useAutoGeneratedRowId, rowData],
  );

  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    const { api } = event;

    if (isGroupCell(event)) {
      onGroupCellChange?.(event);
    } else {
      onChange?.(getCopyOfTableData(api), event);
    }
  };

  return (
    <BaseTable
      className={classnames({ 'ag-disabled': isDisabled })}
      components={useMemo(
        () => ({
          ...components,
          button: ButtonCellRenderer,
          checkbox: CheckboxCellRenderer,
          datePicker: DatePickerCellEditor,
          multipleButtons: MultipleButtonsRenderer,
          numeric: NumericCellField,
          select: SelectCellEditor,
        }),
        [components],
      )}
      data-testid="BaseEditTable"
      defaultColDef={{
        singleClickEdit: true,
        suppressHeaderMenuButton: true,
        ...defaultColDef,
      }}
      getRowId={useAutoGeneratedRowId ? getAgGridRowId : getRowId}
      onCellEditingStarted={(event) => {
        onCellEditingStarted?.(event);
        setIsEditing?.(true);
      }}
      onCellEditingStopped={onCellEditingStopped}
      ref={ref}
      rowData={updatedRowData}
      suppressRowClickSelection
      {...otherProps}
    />
  );
});

OriginalBaseTable.displayName = 'BaseEditTable';

export const BaseEditTable = OriginalBaseTable as BaseEditTableComponent;
