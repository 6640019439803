import _ from 'lodash';

export const isEmptyOrSpaces = (str) =>
  str === null || str === undefined || String(str).match(/^ *$/) !== null;

export const isEmptyOrNullArray = (arr) => arr === null || arr === undefined || arr.length === 0;

export const isEmptyOrNullObj = (obj) => _.isEmpty(obj);

export const roundNumberWithTwoDigits = (number) =>
  Math.round((number + Number.EPSILON) * 100) / 100;

export const roundNumber = (number) => Math.round(number * 1e2) / 1e2;
