import type { GetRowIdFunc, IDetailCellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';
import { useRef } from 'react';

import classNames from 'classnames';

import type { APIParams, dice } from '@org/query';
import { AccountListTable } from '@org/features';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, Label, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const getRowId: GetRowIdFunc<dice.RangeDTO> = ({ data }) => data.id!;

interface DetailCellRendererProps extends IDetailCellRendererParams {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  data: dice.RangeDTO;
}

const DetailCellRenderer = (params: DetailCellRendererProps) => {
  const { data, apiParams } = params;
  // FIXME: Workaround for ag-grid bug
  const apiParamsRef = useRef(apiParams);
  return (
    <AccountListTable
      apiParams={apiParamsRef.current}
      data={data.accounts ?? []}
    />
  );
};

const View: FC<ControllerType> = (props) => {
  const {
    columnDefs,
    defaultColDef,
    autoGroupColumnDef,
    handleOnChange,
    modal,
    onGridReady,
    tableRef,
    isRevenueAccountRangeGroupMissing,
    isExpenseAccountRangeGroupMissing,
    isIgnoreAccountRangeGroupMissing,
    handleAddGroup,
    detailCellRendererParams,
    missingAccounts,
    onCellEditingStopped,
    useAccountPlanAccounts,
    handleOnAccountPlanAccountsChange,
    isToggleDisabled,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <Label
        className={classNames(
          'mb-3 flex cursor-pointer gap-2 text-xs',
          isToggleDisabled && 'pointer-events-none cursor-default',
        )}
      >
        <ToggleInput
          checked={useAccountPlanAccounts}
          name={t('main:manageMenu.accountRange.useAccountPlanAccounts')}
          onChange={handleOnAccountPlanAccountsChange}
        />
        {t('main:manageMenu.accountRange.useAccountPlanAccounts')}
      </Label>
      {(isRevenueAccountRangeGroupMissing ||
        isExpenseAccountRangeGroupMissing ||
        isIgnoreAccountRangeGroupMissing) && (
        <div className="flex justify-end gap-2">
          {isExpenseAccountRangeGroupMissing && (
            <Button
              onClick={handleAddGroup('EXPENSE')}
              size="sm"
              variant="primary"
            >
              {t('main:manageMenu.accountRange.createExpenseRange')}
            </Button>
          )}
          {isRevenueAccountRangeGroupMissing && (
            <Button
              onClick={handleAddGroup('REVENUE')}
              size="sm"
              variant="primary"
            >
              {t('main:manageMenu.accountRange.createRevenueRange')}
            </Button>
          )}
          {isIgnoreAccountRangeGroupMissing && (
            <Button
              onClick={handleAddGroup('IGNORED')}
              size="sm"
              variant="primary"
            >
              {t('main:manageMenu.accountRange.createIgnoredRange')}
            </Button>
          )}
        </div>
      )}
      <div className="h-[calc(100vh-18rem)]">
        <div className="h-2/3">
          <BaseEditTable
            animateRows
            autoGroupColumnDef={autoGroupColumnDef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            detailCellRenderer={DetailCellRenderer}
            detailCellRendererParams={detailCellRendererParams}
            detailRowHeight={415}
            getRowId={getRowId}
            groupDisplayType="singleColumn"
            isGroupOpenByDefault={() => true}
            masterDetail
            onCellEditingStopped={onCellEditingStopped}
            onCellValueChanged={handleOnChange}
            onGridReady={onGridReady}
            ref={tableRef}
          />
        </div>

        <div className="h-1/3">
          <h4 className="py-2">{t('main:manageMenu.accountRange.missingAccounts')}</h4>
          <AccountListTable
            apiParams={detailCellRendererParams.apiParams}
            data={missingAccounts}
          />
        </div>
      </div>
      {modal}
    </>
  );
};

export const ExpenseRevenueAccountsPage = bindControllers(View, [useController]);
ExpenseRevenueAccountsPage.displayName = 'ExpenseRevenueAccountsPage';
