import { useMemo } from 'react';

import { useTranslation } from '@org/locales';

enum InterestRatesBearingCapitalBased {
  AVERAGE = 'AVERAGE',
  START_OF_YEAR = 'START_OF_YEAR',
  END_OF_YEAR = 'END_OF_YEAR',
}

export const useSelectOptions = () => {
  const { t } = useTranslation();
  const bearingCapitalBased = useMemo(
    () => [
      {
        label: t('common:enums.InterestRatesBearingCapitalBased.AVERAGE'),
        value: InterestRatesBearingCapitalBased.AVERAGE,
      },
      {
        label: t('common:enums.InterestRatesBearingCapitalBased.START_OF_YEAR'),
        value: InterestRatesBearingCapitalBased.START_OF_YEAR,
      },
      {
        label: t('common:enums.InterestRatesBearingCapitalBased.END_OF_YEAR'),
        value: InterestRatesBearingCapitalBased.END_OF_YEAR,
      },
    ],
    [t],
  );

  return {
    bearingCapitalBased,
  };
};
