// if includes 'NA' and other keys, then it is mixedCostCenterSelection
// if includes only 'NA', then it is manualCostCenterSelection
// if includes only other keys than 'NA', then it is automaticCostCenterSelection

export const getSelectedAccountSelectionStatus = (keys: string[]) =>
  keys.reduce(
    (acc, key) => {
      if (key === ACCOUNT_STATUSES.NA) {
        acc.isManual = true;
      } else if (key?.length > 0) {
        acc.isAutomatic = true;
      }
      return acc;
    },
    { isManual: false, isAutomatic: false },
  );

export const ACCOUNT_STATUSES = {
  NA: 'NA',
  AUTOMATIC: 'AUTOMATIC',
  MIXED: 'MIXED',
};

export const getMixedAllocationStatus = ({
  automaticValue,
  manualValue,
}: {
  automaticValue: string;
  manualValue: string;
}) => {
  if (automaticValue === 'FULLY_ALLOCATED' && manualValue === 'FULLY_ALLOCATED') {
    return 'FULLY_ALLOCATED';
  }

  if ([automaticValue, manualValue].includes('PARTIALLY_ALLOCATED')) {
    return 'PARTIALLY_ALLOCATED';
  }

  if ([automaticValue, manualValue].includes('NOTHING_ALLOCATED')) {
    return 'NOTHING_ALLOCATED';
  }
};
