import type { InitialGroupOrderComparatorParams } from 'ag-grid-community';
import { useCallback } from 'react';

import { useTranslation } from '@org/locales';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View = ({
  columnsDefs,
  rowData,
  defaultColDef,
  gridRef,
  autoGroupColumnDef,
}: ControllerType) => {
  const { t } = useTranslation();

  const initialGroupOrderComparator = useCallback(
    (params: InitialGroupOrderComparatorParams) => {
      const a = params.nodeA.key ?? t('main:manageMenu.manageAllocationKeys.accounts');
      const b = params.nodeB.key ?? t('main:manageMenu.manageAllocationKeys.accounts');

      const ALLOCATION_ENTRIES_ORDER = new Map([
        [t('main:manageMenu.manageAllocationKeys.accounts'), 0], // Accounts
        [t('main:manageMenu.manageAllocationKeys.costTypeAccounts'), 1], // Cost type accounts
        [t('main:manageMenu.manageAllocationKeys.lblImputedCosts'), 2], // Imputed costs
        [t('main:manageMenu.allocationKeysConfiguration.allocationTableHeader'), 3], // Allocations
      ]);

      const aIndex = ALLOCATION_ENTRIES_ORDER.get(a) ?? 0;
      const bIndex = ALLOCATION_ENTRIES_ORDER.get(b) ?? 0;

      return aIndex - bIndex;
    },
    [t],
  );

  return (
    <BaseEditTable
      animateRows
      autoGroupColumnDef={autoGroupColumnDef}
      columnDefs={columnsDefs}
      defaultColDef={defaultColDef}
      getRowId={({ data }) => `${data.group}-${data.subGroup}-${data.label}`}
      groupDisplayType="singleColumn"
      initialGroupOrderComparator={initialGroupOrderComparator}
      isGroupOpenByDefault={() => true}
      ref={gridRef}
      rowData={rowData}
      rowSelection="multiple"
    />
  );
};

export const AccountTable = bindControllers(View, [useController]);

AccountTable.displayName = 'AccountTable';
