import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';

import { ImputedCostTableCheckbox, SelectAllCheckbox } from './components';

interface UseColumnsProps {
  allocationCalculatedCosts: dice.AllocationBasisConfigTypeEntryDTO['allocationBasisCalculatedCost'];
  onSelectionChanged: (
    entry: dice.AllocationBasisConfigTypeEntryDTO['allocationBasisCalculatedCost'],
  ) => Promise<void>;
  rowData: dice.AllocationConfigPreselectedValuesDTO['calculatedCosts'];
}
export const useColumns = ({
  allocationCalculatedCosts,
  rowData,
  onSelectionChanged,
}: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellRenderer: ({ data, value }: ICellRendererParams) => (
            <ImputedCostTableCheckbox
              allocationCalculatedCosts={allocationCalculatedCosts}
              data={data}
              onSelectionChanged={onSelectionChanged}
              value={value}
            />
          ),
          colId: 'imputedCostTable',
          flex: 2,
          headerComponent: () => (
            <div className="ml-2 flex space-x-2">
              <SelectAllCheckbox
                allocationCalculatedCosts={allocationCalculatedCosts}
                onSelectionChanged={onSelectionChanged}
                rowData={rowData}
              />
              <div>
                {t('main:manageMenu.allocationKeysConfiguration.methodEnum.CALCULATEDCOST')}
              </div>
            </div>
          ),
          valueGetter: ({ data }) => t(`main:reportPanels.babReport.tableRows.${data}`),
        },
      ] satisfies ColDef[],
    [allocationCalculatedCosts, onSelectionChanged, rowData, t],
  );
};
