import type { ColDef, IRowNode } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';
import { ExcelReportType, getCellClasses, getDefaultExcelExportParams } from '@org/utils';

import type { BaseControllerType } from '../types';
import { boldClasses } from './classes';
import { useColumns } from './useColumns';

export type ControllerProps = BaseControllerType;

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 105,
  sortable: false,
  suppressHeaderMenuButton: true,
  cellRendererParams: {
    suppressCount: true,
  },
  useValueFormatterForExport: true,
  cellClass: getCellClasses(boldClasses),
  headerClass: 'bg-white',
};

const doesExternalFilterPass = (node: IRowNode<aggregated.CostUnitReportData[number]>) =>
  Boolean(node.data?.visible);

export const useController = (apiParams: ControllerProps) => {
  const gridRef = useRef<AgGridReact>(null);

  const handleExport = useCallback(() => {
    gridRef.current?.api?.exportDataAsExcel();
  }, []);

  const { clientId } = apiParams;
  const { report } = aggregated.useReport(apiParams);
  const { getClient } = aggregated.useClients({});
  const { year } = aggregated.useYear(apiParams);
  const { publicFacility } = aggregated.usePublicFacilities(apiParams);

  const defaultExcelExportParams = useMemo(
    () =>
      getDefaultExcelExportParams(
        `${getClient(clientId)?.clientName}_${publicFacility?.name ?? ''}_${year?.year ?? ''}_KTR`,
        ExcelReportType.COST_UNIT,
      ),
    [clientId, getClient, publicFacility?.name, year?.year],
  );

  const agGridProps = useAgGridData({
    agGridRef: gridRef,
    data: report?.costUnitReportData,
  });

  const columnDefs = useColumns(apiParams);

  return {
    defaultColDef,
    gridRef,
    handleExport,
    defaultExcelExportParams,
    agGridProps,
    columnDefs,
    doesExternalFilterPass,
  };
};

export type ViewProps = ReturnType<typeof useController>;
