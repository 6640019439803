import type { GetRowIdParams, RowDragEvent } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { dice, griddy } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import type { costCenterTypes } from '@org/utils';
import { aggregated } from '@org/query';
import {
  addToArrayAtIndexMut,
  getBasicVarsForMovingSubgroups,
  getCopyOfTableData,
  getFirstEntryOf,
  getIndexOfEntry,
  getLastEntryOf,
  insertToArr,
  removeFromArr,
  removeFromArrayAtIndexMut,
} from '@org/utils';

import type { CostCenterMappings } from '../types';
import { MOVE_DIRECTION } from '../constants';

interface UseDnDProps {
  masterConfiguration: dice.MasterConfiguration | undefined;
  setCostCenterTypesExist: (data: {
    [costCenterTypes.PRIMARY_COST_CENTER.key]: boolean;
    [costCenterTypes.INDIRECT_COST_CENTER.key]: boolean;
  }) => void;
  updateAssetCCMapping: () => Promise<void>;
}
export function useDnD({
  masterConfiguration,
  setCostCenterTypesExist,
  updateAssetCCMapping,
}: UseDnDProps) {
  const { updateMasterConfiguration } = aggregated.useMasterConfiguration();
  const [rowData, setRowData] = useState<CostCenterMappings[]>([]);

  const ccMappings: CostCenterMappings[] = useMemo(
    () =>
      (masterConfiguration?.costCenterConfig?.costCenterMapping ?? [])
        .sort((mapping1, mapping2) => {
          if (mapping1.rank && mapping2.rank) {
            return mapping1.rank - mapping2.rank;
          }
          return -1;
        })
        .map((mapping, index) => ({
          ...mapping,
          ccMappingsToProfitCenters: [],
          id: index,
          mappingObject: {
            costCenterIgnoreMappings: mapping.costCenterIgnoreMappings ?? [],
            mappingKeys: mapping.mappingKeys ?? [],
          },
        })),
    [masterConfiguration],
  );

  useEffect(() => {
    setRowData(ccMappings);
  }, [ccMappings]);

  const setCostCenterTypesExisting = useCallback(
    (items: CostCenterMappings[]) => {
      setCostCenterTypesExist({
        ['INDIRECT_COST_CENTER']: items.some((cc) => cc.costCenterType === 'INDIRECT_COST_CENTER'),
        ['PRIMARY_COST_CENTER']: items.some((cc) => cc.costCenterType === 'PRIMARY_COST_CENTER'),
      });
    },
    [setCostCenterTypesExist],
  );

  const updateRankAndSetRows = useCallback((ccItems: CostCenterMappings[]) => {
    const updatedRankCCs = ccItems.map((node, index) => {
      node.rank = index + 1;
      return node;
    });

    setRowData(updatedRankCCs);
    return updatedRankCCs;
  }, []);

  const onGridReady = useCallback(() => {
    setRowData(ccMappings);
    setCostCenterTypesExisting(ccMappings);
  }, [ccMappings, setCostCenterTypesExisting]);

  const moveGroup = useCallback(
    ({ gridProps: { api: gridApi, node } }: ButtonCellRendererProps, direction: string) => {
      const tableData: CostCenterMappings[] = getCopyOfTableData(gridApi);
      const { groupFirstItem, groupFirstItemIndex, nodeIsFirstRow, nodeIsLastRow, rowsToMove } =
        getBasicVarsForMovingSubgroups(node, tableData, 'id');

      const isMoveUpForFirstRow =
        nodeIsFirstRow &&
        node?.parent?.key === 'INDIRECT_COST_CENTER' &&
        direction === MOVE_DIRECTION.MOVE_UP;

      const isMoveDownForLastRow =
        nodeIsLastRow &&
        node?.parent?.key === 'PRIMARY_COST_CENTER' &&
        direction === MOVE_DIRECTION.MOVE_DOWN;

      if (isMoveUpForFirstRow || isMoveDownForLastRow) {
        return;
      }

      const newTableData = removeFromArr(tableData, groupFirstItemIndex, rowsToMove.length);
      let movingOverGroup: CostCenterMappings;

      if (direction === MOVE_DIRECTION.MOVE_UP) {
        movingOverGroup = newTableData[groupFirstItemIndex - 1];
      } else {
        movingOverGroup = newTableData[groupFirstItemIndex];
      }
      if (!movingOverGroup) {
        return;
      }

      let toIndex;

      const groupNameNode = groupFirstItem?.data?.costCenterType;

      if (groupNameNode !== movingOverGroup.costCenterType) {
        if (direction === MOVE_DIRECTION.MOVE_UP) {
          const lastSubGroupEntry = getLastEntryOf('group', newTableData, movingOverGroup);
          toIndex = getIndexOfEntry('id', newTableData, lastSubGroupEntry);
          toIndex++;
        } else {
          const firstSubGroupEntry = getFirstEntryOf('group', newTableData, movingOverGroup);
          toIndex = getIndexOfEntry('id', newTableData, firstSubGroupEntry);
        }
      } else {
        if (direction === MOVE_DIRECTION.MOVE_UP) {
          const firstSubGroupEntry = getFirstEntryOf('group', newTableData, movingOverGroup);
          toIndex = getIndexOfEntry('id', newTableData, firstSubGroupEntry);
        } else {
          const lastSubGroupEntry = getLastEntryOf('group', newTableData, movingOverGroup);
          toIndex = getIndexOfEntry('id', newTableData, lastSubGroupEntry);
          toIndex++;
        }
      }

      rowsToMove.forEach((row) => {
        row.costCenterType = movingOverGroup.costCenterType;
      });
      const sortedTable = insertToArr(newTableData, rowsToMove, toIndex);

      return updateRankAndSetRows(sortedTable);
    },
    [updateRankAndSetRows],
  );

  const onRowDragMove = useCallback(
    (event: RowDragEvent) => {
      const movingNodes = event.nodes;
      const { overNode, api } = event;

      if (!overNode) {
        return;
      }

      const INVALID_INDEX = -1;

      const overRowIsGroupOrMainCategory = overNode?.group ?? overNode?.level === 0;

      // DO NOTHING - when insert directly in the main category (primary/indirect group) or moving over group
      if (overRowIsGroupOrMainCategory) {
        return;
      }

      const overData: CostCenterMappings = overNode?.data;
      const movingDataRows: CostCenterMappings[] = movingNodes.map((node) => node.data);
      const rowsHaveSameShortName = movingDataRows.some(
        (cc) => cc.shortName === overData?.shortName,
      );

      // DO NOTHING - when dragging over same node
      if (rowsHaveSameShortName) {
        return;
      }

      const updatedMovingDataRows = movingDataRows.map((node) => ({
        ...node,
        costCenterType: overData.costCenterType,
        group: overData.group,
      }));

      const allNodesData: CostCenterMappings[] = getCopyOfTableData(api);

      let fromIndex = INVALID_INDEX;

      updatedMovingDataRows.forEach((movingDataRow) => {
        fromIndex = allNodesData.findIndex(
          (nodeData: CostCenterMappings) => nodeData.shortName === movingDataRow.shortName,
        );
        removeFromArrayAtIndexMut(allNodesData, fromIndex);
      });

      const toIndex = allNodesData.findIndex(
        (nodeData: CostCenterMappings) => nodeData.shortName === overData.shortName,
      );

      const areAddAndRemoveIndexesValid = toIndex > INVALID_INDEX && fromIndex > INVALID_INDEX;

      // DO NOTHING - when invalid indexes
      if (!areAddAndRemoveIndexesValid) {
        return;
      }

      if (fromIndex > toIndex) {
        addToArrayAtIndexMut(allNodesData, updatedMovingDataRows, toIndex);
      } else {
        /*
        orig rows: [0 -> to 2, 1, 2]
        from: 0, [1, 2] (indexes: [0, 1])
        to: 1 (using modified array) -> should be 2 ( -> toIndex + 1)
       */
        addToArrayAtIndexMut(allNodesData, updatedMovingDataRows, toIndex + 1);
      }

      updateRankAndSetRows(allNodesData);

      api?.clearFocusedCell();
    },
    [updateRankAndSetRows],
  );

  const onRowDragEnd = useCallback(
    async (event: RowDragEvent) => {
      const { api } = event;
      const tableData: CostCenterMappings[] = getCopyOfTableData<CostCenterMappings>(api);
      const tableWithoutRowId = tableData.map(({ id: _, ...other }) => other);

      api.deselectAll();

      const updatedConfiguration = {
        ...masterConfiguration,
        costCenterConfig: { costCenterMapping: tableWithoutRowId },
      };
      setCostCenterTypesExisting(tableWithoutRowId as CostCenterMappings[]);
      await updateMasterConfiguration(updatedConfiguration as griddy.MasterConfiguration);
      await updateAssetCCMapping();
    },
    [
      masterConfiguration,
      setCostCenterTypesExisting,
      updateAssetCCMapping,
      updateMasterConfiguration,
    ],
  );
  const getRowId = useCallback((params: GetRowIdParams) => params.data?.id, []);

  return {
    getRowId,
    moveGroup,
    onGridReady,
    onRowDragEnd,
    onRowDragMove,
    rowData,
  };
}
