import { useParams } from 'react-router-dom';

import { aggregated, dice } from '@org/query';

export const useAssetCategories = () => {
  const { yearId = '', configId: masterConfigurationId = '' } = useParams<{
    yearId: string;
    configId: string;
  }>();
  const { masterConfiguration } = aggregated.useMasterConfiguration({ masterConfigurationId });
  const includeForecastAssets = !!masterConfiguration?.assetForecastConfig?.isEnabled;
  const { data: assetCategories } = dice.useGetAllDistinctAssetCategories({
    queryParams: {
      includeForecastAssets,
      masterConfigurationId,
      yearId,
    },
  });

  return {
    assetCategories,
  };
};
