import type { GridApi } from 'ag-grid-community';

import { getFilteredRowNodes } from '../getFilteredRowNodes';
import { setRowAttributeValue } from './setRowAttributeValue';

export const setRowAttributeValueForFilteredNodes = <T>(
  gridApi: GridApi | undefined,
  colKey: keyof T,
  value: unknown,
) => {
  const nodes = getFilteredRowNodes(gridApi);
  nodes.forEach((node) => setRowAttributeValue(node, colKey, value));
  return nodes;
};
