import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface SelectedAllCheckboxProps {
  costCenterDistributionMap: dice.PlannedDirectEntryDistributionConfigDTO['allocationMapping'];
  onSelectionChanged: (
    selection: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'],
  ) => Promise<void>;
  rowData: dice.AllocationConfigCostCenterDTO[];
}

export const SelectAllCheckbox = ({
  costCenterDistributionMap,
  rowData,
  onSelectionChanged,
}: SelectedAllCheckboxProps) => {
  const ccShortNames = rowData.map((acc) => acc.shortName!);

  const ccDistributionArray = Object.values(costCenterDistributionMap ?? {});

  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      const result: Record<string, number> = {};

      ccShortNames.forEach((item) => {
        result[item] = 0;
      });

      await onSelectionChanged(result);
    } else {
      await onSelectionChanged({});
    }
  };

  const indeterminate =
    ccDistributionArray.length > 0 && ccDistributionArray.length < rowData.length;

  return (
    <CheckboxCellRenderer
      indeterminate={indeterminate}
      onEdit={handleOnEdit}
      source="headerSelectAll"
      value={rowData.length === ccDistributionArray.length}
    />
  );
};
