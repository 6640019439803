import { useCallback, useMemo } from 'react';

import type { APIParams } from '../../types';
import { useMasterConfiguration } from './useMasterConfiguration';

export interface UseCostCentersParams extends APIParams<'masterConfigurationId'> {}

export const useCostCenters = (params: UseCostCentersParams) => {
  const { masterConfiguration } = useMasterConfiguration(params);
  const { costCenterConfig: { costCenterMapping: costCenters = [] } = {} } =
    masterConfiguration ?? {};

  const costCentersNameMap = useMemo(
    () => new Map(costCenters?.map(({ shortName, longName }) => [shortName, longName])),
    [costCenters],
  );

  const getCostCenterLongName = useCallback(
    (shortName: string) => costCentersNameMap?.get(shortName) ?? '',
    [costCentersNameMap],
  );

  const indirectCostCenters = useMemo(
    () => costCenters.filter((cc) => cc.costCenterType === 'INDIRECT_COST_CENTER'),
    [costCenters],
  );
  const indirectCostCenterShortNames = useMemo(
    () => indirectCostCenters.map((cc) => String(cc.shortName)),
    [indirectCostCenters],
  );

  const costCenterOptions = useMemo(
    () =>
      costCenters.map(({ shortName, longName }) => ({
        label: longName ?? shortName,
        value: shortName,
      })),
    [costCenters],
  );

  return {
    costCenterOptions,
    costCenters,
    costCentersNameMap,
    getCostCenterLongName,
    indirectCostCenterShortNames,
    indirectCostCenters,
  };
};
