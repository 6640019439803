import type { PriceSheetRow } from '../types';

interface CreateRowProps {
  groupName: string;
  subGroupName: string;
}

export const createRow = ({ groupName, subGroupName }: CreateRowProps): PriceSheetRow => ({
  costUnit: '',
  currency: '€',
  entryName: '',
  factor1: '1',
  factor2: '1',
  factor3: '1',
  factor4: '1',
  groupName,
  source: 'PRICE_SHEET',
  subGroupName,
});
