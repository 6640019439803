import type { Ref } from 'react';

import classnames from 'classnames';
import { withSlots } from 'react-slot-component';

import type { LabelProps } from '../Label';
import { Label } from '../Label';
import styles from './ToggleInput.module.css';

type OuterProps = React.JSX.IntrinsicElements['input'];

export type ToggleInputProps = {
  inputSize?: 'sm' | 'md' | 'xl';
  forwardRef?: Ref<HTMLInputElement>;
} & OuterProps;

export interface ToggleInputSlots {
  LabelBefore: LabelProps;
  LabelAfter: LabelProps;
}

export const ToggleInput = withSlots<ToggleInputSlots, ToggleInputProps>((props) => {
  const { className, inputSize = 'sm', slotProps, forwardRef, ...otherProps } = props;
  const { LabelBefore, LabelAfter } = slotProps;

  return (
    <div
      className={classnames(styles.ToggleInput, 'select-none', className)}
      data-testid="ToggleInput"
    >
      <label
        className={classnames('flex cursor-pointer items-center gap-4', {
          'gap-2': inputSize === 'sm',
          'gap-3': inputSize === 'md',
          'gap-4': inputSize === 'xl',
        })}
      >
        {LabelBefore && (
          <Label
            {...LabelBefore}
            as="span"
          />
        )}
        <div className="relative">
          <input
            className="sr-only"
            type="checkbox"
            {...otherProps}
            ref={forwardRef}
          />
          <div
            className={classnames('indicator block rounded-full bg-grey-light', {
              'h-5 w-8': inputSize === 'sm',
              'h-6 w-10': inputSize === 'md',
              'h-8 w-14': inputSize === 'xl',
            })}
          />
          <div
            className={classnames('dot absolute top-0 m-1 rounded-full bg-white transition', {
              'h-3 w-3': inputSize === 'sm',
              'h-4 w-4': inputSize === 'md',
              'h-6 w-6': inputSize === 'xl',
            })}
          />
        </div>
        {LabelAfter && (
          <Label
            {...LabelAfter}
            as="span"
          />
        )}
      </label>
    </div>
  );
});
