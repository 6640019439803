import { useMemo } from 'react';

import type { SingleComboboxProps } from '@org/design';
import type { APIParams } from '@org/query';
import { aggregated } from '@org/query';

export interface UseControllerProps
  extends APIParams<'publicFacilityId'>,
    Omit<SingleComboboxProps, 'onChange' | 'selectedKey' | 'onSelectionChange' | 'children'> {
  value?: SingleComboboxProps['selectedKey'];
  onChange?: SingleComboboxProps['onSelectionChange'];
}

export const useController = (props: UseControllerProps) => {
  const { publicFacilityId } = props;
  const { configurations } = aggregated.useGetAllConfigurations({
    publicFacilityId,
  });

  const sortedConfigurationsByType = useMemo(
    () =>
      configurations.map((yearlyConfig) => ({
        ...yearlyConfig,
        configs: yearlyConfig.configs.sort(
          (aConfig, bConfig) => getRank(aConfig.type) - getRank(bConfig.type),
        ),
      })),
    [configurations],
  );

  return {
    ...props,
    configurations: sortedConfigurationsByType,
  };
};

export type ViewProps = ReturnType<typeof useController>;

const UNDEFINED_RANK = 3;

const rankOrder: Record<'PRIMARY' | 'VARIANT' | 'BACKUP', number> = {
  PRIMARY: 0,
  VARIANT: 1,
  BACKUP: 2,
};

const getRank = (type?: string) => rankOrder[type as keyof typeof rankOrder] ?? UNDEFINED_RANK;
