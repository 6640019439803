import type { dice } from '../../../../../query/src';

const getUpdatedCostCenterInterestRatesByRemovedCostCenters = (
  costCentersFromCostCenterConfig: string[],
  costCentersFromCostCenterInterestRates: string[],
  interestRates: dice.InterestRates,
) => {
  const uniqueCostCentersFromCostCenterConfig = new Set(costCentersFromCostCenterConfig);
  const objectsToRemove = new Set(
    costCentersFromCostCenterInterestRates.filter(
      (cc) => !uniqueCostCentersFromCostCenterConfig.has(cc),
    ),
  );
  return (interestRates.costCenterInterestRates ?? []).filter(
    (ccir) => !objectsToRemove.has(ccir?.ccShortName ?? ''),
  );
};

const getNewCostCenterInterestRates = (
  costCentersFromCostCenterConfig: string[],
  costCentersFromCostCenterInterestRates: string[],
  interestRates: dice.InterestRates,
) => {
  const uniqueCostCentersFromCostCenterInterestRates = new Set(
    costCentersFromCostCenterInterestRates,
  );
  const costCentersToAdd = costCentersFromCostCenterConfig.filter(
    (cc) => !uniqueCostCentersFromCostCenterInterestRates.has(cc),
  );
  return costCentersToAdd.map<dice.CostCenterInterestRates>((ccShortName) => ({
    ccShortName,
    interestRate: interestRates.uniformInterestRate ?? 0,
  }));
};

export const updateInterestRatesByCostCenters = (
  selectedConfig: dice.MasterConfiguration,
): dice.MasterConfiguration => {
  const { costCenterConfig, interestRates } = selectedConfig;
  const costCentersFromCostCenterConfig = (costCenterConfig?.costCenterMapping?.map(
    (ccm) => ccm.shortName,
  ) ?? []) as string[];
  const costCentersFromInterestRates = (interestRates.costCenterInterestRates?.map(
    (ccir) => ccir.ccShortName,
  ) ?? []) as string[];

  const updatedCostCenterInterestRates = [
    ...getUpdatedCostCenterInterestRatesByRemovedCostCenters(
      costCentersFromCostCenterConfig,
      costCentersFromInterestRates,
      interestRates,
    ),
    ...getNewCostCenterInterestRates(
      costCentersFromCostCenterConfig,
      costCentersFromInterestRates,
      interestRates,
    ),
  ];
  return {
    ...selectedConfig,
    interestRates: {
      ...interestRates,
      costCenterInterestRates: updatedCostCenterInterestRates,
    },
  };
};
