import { formatNumberToLocaleString } from './formatNumberToLocaleString';

export type FormatNonZeroNumberToLocaleStringOptions = {
  defaultValue?: string;
} & Intl.NumberFormatOptions;

export const formatNonZeroNumberToLocaleString = (
  value?: number | string | null,
  options?: FormatNonZeroNumberToLocaleStringOptions,
) => {
  const { defaultValue } = options ?? {};
  return value ? formatNumberToLocaleString(value, options) : (defaultValue ?? '');
};
