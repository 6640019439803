import type { FieldValues, UseFormReset } from 'react-hook-form';
import { useEffect } from 'react';

interface UseReinitializeFormProps<T extends FieldValues> {
  defaultValues: T;
  reset: UseFormReset<T>;
}

export const useReinitializeForm = <T extends FieldValues>({
  defaultValues,
  reset,
}: UseReinitializeFormProps<T>) => {
  // Reseting form default values, this is needed to reinitialize controllers default values
  // https://github.com/react-hook-form/react-hook-form/issues/181#issuecomment-517871934
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
};
