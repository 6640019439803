import { useCallback } from 'react';

import type { APIParams } from '../..';
import type {
  CostUnitAllocationConfigDTO,
  CreateCostUnitAllocationBasisConfigPathParams,
} from '../dice';
import {
  useCreateCostUnitAllocationBasisConfig,
  useGetCostUnitAllocationConfig,
  useGetCostUnitAllocationPreselectedValues,
  useSaveCostUnitAllocationConfiguration,
} from '../dice';

interface UseCostUnitAllocationsParams extends APIParams<'masterConfigurationId'> {
  enabled?: boolean;
}

export const useCostUnitAllocation = ({
  masterConfigurationId,
  enabled = true,
}: UseCostUnitAllocationsParams) => {
  const pathParams = {
    masterConfigurationId,
  };

  const { data: allocationKeyConfig, isLoading: isAllocationKeyConfigLoading } =
    useGetCostUnitAllocationConfig(
      {
        pathParams,
      },
      {
        enabled,
      },
    );

  const { data: preselectedAllocations } = useGetCostUnitAllocationPreselectedValues(
    {
      pathParams: {
        masterConfigurationId: masterConfigurationId!,
      },
    },
    {
      enabled: Boolean(masterConfigurationId) && enabled,
    },
  );

  const { mutateAsync: updateSingleAllocationKeyConfigAsync } =
    useSaveCostUnitAllocationConfiguration({});

  const { mutateAsync: createAllocationBasisConfigAsync } = useCreateCostUnitAllocationBasisConfig(
    {},
  );

  const updateSingleAllocationKeyConfig = useCallback(
    async ({ id, item }: { id: string; item: CostUnitAllocationConfigDTO }) =>
      await updateSingleAllocationKeyConfigAsync({
        body: item,
        pathParams: {
          masterConfigurationId,
          costUnitAllocationConfigId: id,
        },
      }),
    [masterConfigurationId, updateSingleAllocationKeyConfigAsync],
  );

  const createAllocationBasisConfig = useCallback(
    async (
      createPathParams: Omit<
        CreateCostUnitAllocationBasisConfigPathParams,
        'masterConfigurationId'
      >,
    ) =>
      await createAllocationBasisConfigAsync({
        pathParams: {
          ...createPathParams,
          masterConfigurationId,
        },
      }),
    [createAllocationBasisConfigAsync, masterConfigurationId],
  );

  return {
    allocationKeyConfig,
    createAllocationBasisConfig,
    isLoading: isAllocationKeyConfigLoading,
    updateSingleAllocationKeyConfig,
    preselectedAllocations,
  };
};
