import type { FC } from 'react';

import { Controller } from 'react-hook-form';

import type { dice } from '@org/query';
import { Combobox, ComboboxItem } from '@org/design';
import { useTranslation } from '@org/locales';
import { CheckboxInput, Label } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { AccountIdsMethodTable } from './components/AccountIdsMethodTable';
import { AccountTable } from './components/AccountTable';
import { AllocationTable } from './components/AllocationTable';
import { AutomaticSelectAllCheckbox } from './components/AutomaticSelectAllCheckbox';
import { CommentTextArea } from './components/CommentTextArea';
import { CostCenterTable } from './components/CostCenterTable';
import { CostTypeAccountMethodTable } from './components/CostTypeAccountMethodTable';
import { ImputedCostCenterTable } from './components/ImputedCostCenterTable';
import { ManualTable } from './components/ManualTable';
import { TechnicalData } from './components/TechnicalData';
import { REMAINDER_ALLOC_NAME, totalMethodType, useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const { t } = useTranslation();

  const {
    apiParams,
    control,
    handleOnChangeBasis,
    basisOptions,
    methodOptions,
    handleSelectAllocation,
    handleOnChangeMethod,
    allocationTableRef,
    accountTableRef,
    selectedAllocation,
    basisType,
    handleOnChangeAllocation,
    allocationBasisConfigEntriesWithRemainder,
    handleRemoveAllocation,
    rankOfCC,
    currentMethodType,
    handleUpdateEntry,
    otherMergedAllocations,
    costCenterTableRef,
    costTypeAccountMethodTableRef,
    handleAddNewAllocation,
    handleOnChangeAutomaticAll,
    allSelected,
    handleEnableRemainder,
    allSelectedReceivingCostCenters,
    handleOnChangeAutomaticAllCostCenters,
    handleOnChangeComment,
    allocationKeyConfigById,
  } = props;

  return (
    <div className="h-full">
      <div className="grid grid-cols-2 p-2">
        <div className="flex flex-col border-r-2 border-solid border-r-gray-300 pr-2">
          <Label className="mb-0 inline-flex cursor-pointer items-center gap-2 font-bold">
            <span className="text-[0.75rem] after:content-[':']">
              {t('main:manageMenu.allocationKeysConfiguration.tableColumns.basis')}
            </span>
            <Controller
              control={control}
              name="basisType"
              render={({ field }) => (
                <Combobox
                  {...field}
                  aria-label={t('main:manageMenu.allocationKeysConfiguration.tableColumns.basis')}
                  isDisabled={false}
                  isFullWidth
                  onSelectionChange={async (data) => {
                    field.onChange(data);
                    await handleOnChangeBasis(data as dice.AllocationKeyConfigDTO['basisType']);
                  }}
                  placeholder={t(
                    'main:manageMenu.allocationKeysConfiguration.placeholderSelectAllocationBasis',
                  )}
                  selectedKey={field.value}
                >
                  {basisOptions.map(({ label, value }) => (
                    <ComboboxItem key={value}>{label}</ComboboxItem>
                  ))}
                </Combobox>
              )}
            />
          </Label>

          {basisType && basisType !== 'COMPLETE_COST_CENTER' && (
            <div className="pt-2">
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <div className="min-h-[350px]">
                  <AllocationTable
                    gridRef={allocationTableRef}
                    handleAddNewAllocation={handleAddNewAllocation}
                    handleOnChange={handleOnChangeAllocation}
                    handleRemoveAllocation={handleRemoveAllocation}
                    handleSelectAllocation={handleSelectAllocation}
                    rows={allocationBasisConfigEntriesWithRemainder}
                  />
                </div>
                {selectedAllocation &&
                selectedAllocation.allocationBasisName !== REMAINDER_ALLOC_NAME ? (
                  <div>
                    <AccountTable
                      apiParams={apiParams}
                      basisType={basisType}
                      gridRef={accountTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      otherMergedAllocations={otherMergedAllocations}
                      rankOfCC={rankOfCC}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  </div>
                ) : (
                  <div className="inline-flex h-6 flex-col justify-center">
                    <Controller
                      control={control}
                      name="remainderActive"
                      render={({ field }) => (
                        <CheckboxInput
                          {...field}
                          checked={field.value}
                          className="h-full"
                          onChange={async (event) => {
                            const {
                              target: { checked },
                            } = event;
                            field.onChange(event);
                            await handleEnableRemainder(checked);
                          }}
                        >
                          {t(
                            'main:manageMenu.allocationKeysConfiguration.allocationRemainderSelectAll',
                          )}
                        </CheckboxInput>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col pl-2">
          <Label className="mb-0 inline-flex cursor-pointer items-center gap-2 font-bold">
            <span className="text-[0.75rem] after:content-[':']">
              {t('main:manageMenu.allocationKeysConfiguration.tableColumns.method')}
            </span>
            <Controller
              control={control}
              name="methodType"
              render={({ field }) => (
                <Combobox
                  {...field}
                  aria-label={t('main:manageMenu.allocationKeysConfiguration.tableColumns.method')}
                  isFullWidth
                  onSelectionChange={async (data) => {
                    field.onChange(data);
                    await handleOnChangeMethod(
                      data as dice.AllocationDistributionConfigDTO['allocationMethod'],
                    );
                  }}
                  selectedKey={field.value}
                >
                  {methodOptions.map(({ label, value }) => (
                    <ComboboxItem key={value}>{label}</ComboboxItem>
                  ))}
                </Combobox>
              )}
            />
          </Label>

          <div className="pt-2">
            {totalMethodType.has(currentMethodType ?? '') && (
              <div className="grid">
                <div className="min-h-[320px]">
                  <AutomaticSelectAllCheckbox
                    allSelected={allSelected}
                    handleOnChangeAutomaticAll={handleOnChangeAutomaticAll}
                  />
                  {!allSelected && (
                    <CostCenterTable
                      apiParams={apiParams}
                      gridRef={costCenterTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      rankOfCC={rankOfCC}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  )}
                </div>
              </div>
            )}
            {currentMethodType === 'COSTTYPEACCOUNT' && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <div className="min-h-[320px]">
                  <AutomaticSelectAllCheckbox
                    allSelected={allSelected}
                    handleOnChangeAutomaticAll={handleOnChangeAutomaticAll}
                  />
                  {!allSelected && (
                    <CostTypeAccountMethodTable
                      apiParams={apiParams}
                      gridRef={costTypeAccountMethodTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  )}
                </div>
                <div>
                  <AutomaticSelectAllCheckbox
                    allSelected={allSelectedReceivingCostCenters}
                    handleOnChangeAutomaticAll={handleOnChangeAutomaticAllCostCenters}
                  />
                  {!allSelectedReceivingCostCenters && (
                    <CostCenterTable
                      apiParams={apiParams}
                      gridRef={costCenterTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      rankOfCC={rankOfCC}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  )}
                </div>
              </div>
            )}
            {currentMethodType === 'CALCULATEDCOST' && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <div>
                  <AutomaticSelectAllCheckbox
                    allSelected={allSelected}
                    handleOnChangeAutomaticAll={handleOnChangeAutomaticAll}
                  />
                  {!allSelected && (
                    <ImputedCostCenterTable
                      apiParams={apiParams}
                      gridRef={costCenterTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  )}
                </div>
                <div className="min-h-[320px]">
                  <AutomaticSelectAllCheckbox
                    allSelected={allSelectedReceivingCostCenters}
                    handleOnChangeAutomaticAll={handleOnChangeAutomaticAllCostCenters}
                  />
                  {!allSelectedReceivingCostCenters && (
                    <CostCenterTable
                      apiParams={apiParams}
                      gridRef={costTypeAccountMethodTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      rankOfCC={rankOfCC}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  )}
                </div>
              </div>
            )}
            {currentMethodType === 'MANUAL' && (
              <div className="grid">
                <div className="min-h-[320px]">
                  <ManualTable
                    apiParams={apiParams}
                    handleUpdateEntry={handleUpdateEntry}
                    rankOfCC={rankOfCC}
                    selectedAllocationBasis={selectedAllocation}
                  />
                </div>
              </div>
            )}
            {currentMethodType === 'TECHNICALDATA' && (
              <TechnicalData
                apiParams={apiParams}
                handleUpdateEntry={handleUpdateEntry}
                selectedAllocationBasis={selectedAllocation}
              />
            )}
            {currentMethodType === 'ACCOUNTID' && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <div>
                  <AutomaticSelectAllCheckbox
                    allSelected={allSelected}
                    handleOnChangeAutomaticAll={handleOnChangeAutomaticAll}
                  />
                  {!allSelected && (
                    <AccountIdsMethodTable
                      apiParams={apiParams}
                      gridRef={costCenterTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  )}
                </div>
                <div className="min-h-[320px]">
                  <AutomaticSelectAllCheckbox
                    allSelected={allSelectedReceivingCostCenters}
                    handleOnChangeAutomaticAll={handleOnChangeAutomaticAllCostCenters}
                  />
                  {!allSelectedReceivingCostCenters && (
                    <CostCenterTable
                      apiParams={apiParams}
                      gridRef={costTypeAccountMethodTableRef}
                      handleUpdateEntry={handleUpdateEntry}
                      rankOfCC={rankOfCC}
                      selectedAllocationBasis={selectedAllocation}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CommentTextArea
        comment={allocationKeyConfigById.comment ?? ''}
        handleOnChangeComment={handleOnChangeComment}
      />
    </div>
  );
};

export const AllocationKeysMasterDetail = bindControllers(View, [useController]);

AllocationKeysMasterDetail.displayName = 'AllocationKeysMasterDetail';
