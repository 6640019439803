import type { ExcelExportParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import { ExcelReportType } from '@org/models';
import { aggregated } from '@org/query';
import { getDefaultExcelExportParams } from '@org/utils';

import type { BaseControllerType } from '../types';
import { useColumns } from './useColumns';

export type UseControllerProps = BaseControllerType;
export type ViewProps = ReturnType<typeof useController>;

export const useController = (apiParams: UseControllerProps) => {
  const { clientId, publicFacilityId } = apiParams;
  const tableGridRef = useRef<AgGridReact>(null);
  const { report } = aggregated.useReport(apiParams);

  const { year: { year = '' } = {} } = aggregated.useYear(apiParams);

  const { publicFacility: { name = '' } = {} } = aggregated.usePublicFacilities({
    clientId,
    publicFacilityId,
  });

  const { tradeTaxTableReportData } = report ?? {};

  const defaultExcelExportParams = useMemo<ExcelExportParams>(
    () =>
      getDefaultExcelExportParams(
        `${clientId}_${name}_${year}_${ExcelReportType.TRADE_TAX}`,
        ExcelReportType.INTEREST,
      ),
    [clientId, name, year],
  );

  const columnDefs = useColumns({ apiParams, report });

  const handleExport = useCallback(() => {
    tableGridRef.current?.api?.exportDataAsExcel();
  }, []);

  return {
    columnDefs,
    defaultExcelExportParams,
    handleExport,
    rowData: tradeTaxTableReportData,
    tableGridRef,
  };
};
