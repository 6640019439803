import type { InitialGroupOrderComparatorParams } from 'ag-grid-community';
import type { FC } from 'react';
import { useCallback } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { CostTypeAccountMasterDetail } from '@org/features';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, Label } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface CostTypeAccountsProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const View: FC<CostTypeAccountsProps> = (props) => {
  const {
    tableRef,
    autoGroupColumnDef,
    columnDefs,
    defaultColDef,
    detailCellRendererParams,
    handleOnChange,
    getRowId,
    modal,
    agGridProps,
    onRowDragMove,
    onRowDragEnd,
    hasExpenseRows,
    hasRevenueRows,
    addNewRow,
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className="h-full flex-1"
      data-testid="CostTypeAccount"
    >
      {(!hasExpenseRows || !hasRevenueRows) && (
        <div className="flex justify-end">
          <div className="inline-flex">
            <Label className="flex gap-2 text-xs">
              {t('main:manageMenu.costTypeAccountConfiguration.createCostTypeAccountEntry')}
            </Label>
            {!hasExpenseRows && (
              <Button
                className="mb-2 ml-3"
                onClick={() =>
                  addNewRow({
                    node: {
                      key: 'EXPENSE',
                    },
                  })
                }
                size="sm"
                variant="primary"
              >
                <span className="appkiticon icon-news-outline" />
                {t('main:manageMenu.costTypeAccountConfiguration.tableColumns.EXPENSE')}
              </Button>
            )}
            {!hasRevenueRows && (
              <Button
                className="mb-2 ml-3"
                onClick={() =>
                  addNewRow({
                    node: {
                      key: 'REVENUE',
                    },
                  })
                }
                size="sm"
                variant="primary"
              >
                <span className="appkiticon icon-news-outline" />
                {t('main:manageMenu.costTypeAccountConfiguration.tableColumns.REVENUE')}
              </Button>
            )}
          </div>
        </div>
      )}

      <BaseEditTable
        {...agGridProps}
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        detailCellRenderer={CostTypeAccountMasterDetail}
        detailCellRendererParams={detailCellRendererParams}
        detailRowHeight={415}
        enableCellTextSelection={false}
        getRowId={getRowId}
        groupDisplayType="singleColumn"
        initialGroupOrderComparator={useCallback((params: InitialGroupOrderComparatorParams) => {
          const { nodeA, nodeB } = params;

          if (nodeA.field === 'type' && nodeB.field === 'type') {
            return nodeA.key === 'REVENUE' ? 1 : -1;
          }

          return 0;
        }, [])}
        isGroupOpenByDefault={useCallback(() => true, [])}
        masterDetail
        onCellValueChanged={handleOnChange}
        onRowDragEnd={onRowDragEnd}
        onRowDragMove={onRowDragMove}
        ref={tableRef}
        rowDragMultiRow
        rowSelection="multiple"
      />
      {modal}
    </div>
  );
};

export const CostTypeAccountsPage = bindControllers(View, [useController]);

CostTypeAccountsPage.displayName = 'CostTypeAccountsPage';
