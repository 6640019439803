import unionBy from 'lodash/unionBy';

import type { dice } from '@org/query';
import { getPrimaryCostCenters, sortByKey } from '@org/utils';

export const prepareDefaultRowData = (
  selectedConfig?: dice.MasterConfiguration,
): dice.CostCenterConcessionFee[] => {
  const primaryCC = getPrimaryCostCenters(selectedConfig?.costCenterConfig.costCenterMapping);
  return primaryCC?.map((cc) => ({
    concessionFeeRate: 0,
    primaryCostCenterShortName: cc.shortName,
  }));
};

export const prepareData = (
  defaultData: dice.CostCenterConcessionFee[] = [],
  currentDataState: (dice.CostCenterConcessionFee | dice.WorkingCapital)[] = [],
): (dice.CostCenterConcessionFee | dice.WorkingCapital)[] => {
  // filter made so when client deleted BAB CC we delete related row in this stop
  const filteredConcessionFee = currentDataState?.filter((cc) =>
    defaultData.find(
      (defaultCC) => defaultCC.primaryCostCenterShortName === cc.primaryCostCenterShortName,
    ),
  );
  const mergedData = unionBy(filteredConcessionFee, defaultData, 'primaryCostCenterShortName');
  return sortByKey(mergedData, 'primaryCostCenterShortName');
};
