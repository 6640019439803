import type { CostCenterMappings } from '../types';

export const convertMappingObjectToArrays = (newRow: CostCenterMappings) => {
  const {
    mappingObject: { mappingKeys },
    mappingIds: _,
    costCenterMappings: __,
    ccMappingsToProfitCenters: ___,
    ...newRowData
  } = newRow;

  return {
    ...newRowData,
    mappingKeys: mappingKeys || [],
  };
};
