import { useTranslation } from '@org/locales';

import type { APIParams } from '../../..';
import type { ReportsResponse } from '../../rave';
import { useGetByFileIdsFromGridFS } from '../../griddy';
import { useMasterConfigurationByYearCalculation } from '../useMasterConfigurationByYearCalculation';
import { groupCostCenters } from './costCenters';
import { generateCostUnitReport } from './costUnitReport';
import { generateNonOperatingInterestRateReport } from './nonOperatingInterestRateReport';
import { generateOperatingInterestRateReport } from './operatingInterestRateReport';
import { generateTradeTaxTableReport } from './tradeTaxTableReport';
import { useRecalculateReport } from './useRecalculateReport';

export type TradeTaxReportData = ReturnType<typeof generateTradeTaxTableReport>;
export type NonOperatingInterestTradeReportData = ReturnType<
  typeof generateNonOperatingInterestRateReport
>;
export type OperatingInterestTradeReportData = ReturnType<
  typeof generateOperatingInterestRateReport
>;

export type CostUnitReportData = ReturnType<typeof generateCostUnitReport>;

export interface ExtendedReportObjectsResponse
  extends ReportsResponse,
    ReturnType<typeof groupCostCenters> {
  tradeTaxTableReportData: ReturnType<typeof generateTradeTaxTableReport>;
  nonOperatingInterestTradeReport: ReturnType<typeof generateNonOperatingInterestRateReport>;
  operatingInterestTradeReport: ReturnType<typeof generateOperatingInterestRateReport>;
  costUnitReportData: ReturnType<typeof generateCostUnitReport>;
}

export interface UseReportParams
  extends APIParams<
    'publicFacilityId' | 'yearId' | 'clientId' | 'type' | 'masterConfigurationId'
  > {}

export const useReport = <TData = ExtendedReportObjectsResponse>(params: UseReportParams) => {
  const { t } = useTranslation();
  const { selectedEntity } = useMasterConfigurationByYearCalculation({
    masterConfigurationId: params.masterConfigurationId,
    yearCalculationId: params.yearId,
  });
  const reportId = selectedEntity?.reportId;

  const { recalculateReport, isRecalculating } = useRecalculateReport(params);

  const { data: report, ...responseObject } = useGetByFileIdsFromGridFS<TData>(
    {
      pathParams: {
        ids: [reportId!],
      },
    },
    {
      enabled: Boolean(reportId),
      select(data) {
        const reportObject = data?.[0] as ReportsResponse;
        const groupedCostCenters = groupCostCenters(reportObject.costCenters);
        const returnObject: ExtendedReportObjectsResponse = {
          ...reportObject,
          ...groupedCostCenters,
          nonOperatingInterestTradeReport: generateNonOperatingInterestRateReport({
            reportObject,
            ...groupedCostCenters,
          }),
          operatingInterestTradeReport: generateOperatingInterestRateReport({
            reportObject,
            t,
            ...groupedCostCenters,
          }),
          tradeTaxTableReportData: generateTradeTaxTableReport({
            ...groupedCostCenters,
            reportObject,
          }),
          costUnitReportData: generateCostUnitReport({ reportObject }),
        };
        return returnObject as TData;
      },
    },
  );

  return { ...responseObject, isRecalculating, recalculateReport, report };
};
