interface CheckboxIconProps {
  isSelected: boolean;
  isIndeterminate?: boolean;
  className?: string;
}

function CheckIcon(props: CheckboxIconProps) {
  const { isSelected, ...otherProps } = props;

  return (
    <svg
      aria-hidden="true"
      role="presentation"
      viewBox="0 0 17 18"
      {...otherProps}
    >
      <polyline
        fill="none"
        points="1 9 7 14 17 2"
        stroke="currentColor"
        strokeDasharray={22}
        strokeDashoffset={isSelected ? 44 : 63}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        style={
          isSelected
            ? {
                transition: 'stroke-dashoffset 100ms linear 0.1s',
              }
            : {}
        }
      />
    </svg>
  );
}

function IndeterminateIcon(props: CheckboxIconProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSelected, ...otherProps } = props;

  return (
    <svg
      role="presentation"
      stroke="currentColor"
      strokeWidth={3}
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <line
        x1="21"
        x2="3"
        y1="12"
        y2="12"
      />
    </svg>
  );
}

/**
 * CheckboxIcon is used to visually indicate the checked or indeterminate
 * state of a checkbox.
 */
export function CheckboxIcon(props: CheckboxIconProps) {
  const { isIndeterminate, ...otherProps } = props;
  const BaseIcon = isIndeterminate ? IndeterminateIcon : CheckIcon;

  return <BaseIcon {...otherProps} />;
}
