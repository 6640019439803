/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Column } from 'ag-grid-community';

const getCostCenterNamesOfGroup = (column: Column) =>
  column
    ?.getParent()
    ?.getChildren()
    ?.map((childCol) => childCol?.getUniqueId())
    ?.filter(Boolean);

export const calcGroupedColumnSum = <T extends Record<string, any>>(
  column: Column,
  rowData?: T,
) => {
  const costCenterNamesOfGroup = getCostCenterNamesOfGroup(column);

  return costCenterNamesOfGroup?.reduce(
    (acc: number, currCCName: string) => acc + ((rowData?.[currCCName] as number) ?? 0),
    0,
  );
};

export const joinGroupedColumnString = <T extends Record<string, any>>(
  column: Column,
  rowData?: T,
) => {
  const costCenterNamesOfGroup = getCostCenterNamesOfGroup(column);

  const uniqueIds =
    costCenterNamesOfGroup?.filter((item) => rowData?.[item]).map((item) => rowData?.[item]) ?? [];

  return uniqueIds.join(', ');
};
