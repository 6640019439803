import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface SelectAllCheckboxProps {
  costCenterDistributionMap?: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'];
  onSelectionChanged: (
    selection: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'],
  ) => Promise<void>;
  rowData: dice.AllocationConfigCostCenterDTO[];
}

export const SelectAllCheckbox = ({
  rowData,
  costCenterDistributionMap,
  onSelectionChanged,
}: SelectAllCheckboxProps) => {
  const ccDistributionArray = costCenterDistributionMap
    ? Object.keys(costCenterDistributionMap)
    : [];

  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      const allSelected = Object.fromEntries(rowData.map((cur) => [cur?.shortName, 0]));

      await onSelectionChanged(allSelected);
    } else {
      await onSelectionChanged({});
    }
  };

  const indeterminate =
    ccDistributionArray.length > 0 && ccDistributionArray.length < rowData.length;

  return (
    <CheckboxCellRenderer
      indeterminate={indeterminate}
      onEdit={handleOnEdit}
      value={rowData.length === ccDistributionArray.length}
    />
  );
};
