import type {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { renderSingleSelectCellValue } from '@org/utils';

interface UseColumnsProps {
  openDeleteModal: (data: Record<string, string>) => void;
  handleAddNewRow: () => Promise<void> | void;
  ccOptions: { label: string; value: string }[];
  disabledOptions: string[];
}

export const useColumns = ({
  openDeleteModal,
  handleAddNewRow,
  ccOptions,
  disabledOptions,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellClass: (cellClassParams: CellClassParams) => {
            if (!cellClassParams.data.shortName) {
              return '!border !border-[#d04a01]';
            }
          },
          cellEditor: 'select',
          cellEditorParams: {
            disabledOptions,
            options: ccOptions,
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          cellEditorPopup: true,
          colId: 'shortName',
          editable: true,
          field: 'shortName',
          flex: 3,
          headerName: t('common:costCenter'),
          minWidth: 250,
          valueFormatter: renderSingleSelectCellValue,
        },
        {
          cellClass: 'align-to-center-and-right',
          cellEditor: 'numeric',
          cellEditorParams: {
            decimalScale: 2,
            fixedDecimalScale: 2,
          },
          colId: 'percent',
          editable: (params: EditableCallbackParams) => params.data.shortName !== null,
          // @ts-expect-error -- wrong type
          field: 'percent',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageCostUnitsAllocation.tableColumns.percent'),
          maxWidth: 65,
          type: 'percent',
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRenderer: 'button',
          cellRendererParams: {
            disabled: false,
            icon: 'icon-delete-outline',
            onClick: (props: ButtonCellRendererProps) => openDeleteModal(props.data),
            size: 'iconButton',
          },
          colId: 'actions',
          editable: false,
          flex: 1,
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            disabled: false,
            icon: 'icon-plus-fill',
            onClick: () => handleAddNewRow(),
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          maxWidth: 65,
        },
      ] satisfies (ColDef<dice.CostCenter> | ColGroupDef<dice.CostCenter>)[],
    [ccOptions, disabledOptions, handleAddNewRow, openDeleteModal, t],
  );
};
