import type { IRowNode } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef, useState } from 'react';

import type { dice } from '@org/query';
import { useBabReportModal } from '@org/features';
import { aggregated } from '@org/query';

import type { BaseControllerType } from '..';

export type UseControllerProps = BaseControllerType;

const options = [
  {
    label: 'main:manageMenu.manageCostCenters.tableColumns.profitCenterId',
    value: 'PROFIT_CENTER',
  },
  { label: 'main:manageMenu.manageCostCenters.tableColumns.jobId', value: 'JOB_ID' },
  { label: 'main:manageMenu.manageCostCenters.tableColumns.costCenterId', value: 'CC_ID' },
];

const DEFAULT_CC_TYPE = 'CC_ID';

export const useController = (apiParams: UseControllerProps) => {
  const { yearId, clientId } = apiParams;
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);
  const tableGridRef = useRef<AgGridReact>(null);
  const modalGridRef = useRef<AgGridReact>(null);

  const { publicFacility } = aggregated.usePublicFacilities(apiParams);

  const { getClient } = aggregated.useClients({});
  const client = getClient(clientId);

  const { year } = aggregated.useYear(apiParams);
  const { report: selectedReport, recalculateReport } = aggregated.useReport(apiParams);
  const [showManualCorrections, setShowManualCorrections] = useState(false);

  const recalculate = useCallback(
    async (newMasterConfiguration?: dice.MasterConfiguration) => {
      await recalculateReport({
        masterConfiguration: newMasterConfiguration,
        year,
      });
    },
    [recalculateReport, year],
  );

  const getReportExcelFileName = useCallback(
    () => `${client?.clientName}_${publicFacility?.name ?? ''}_${year?.year ?? ''}_BAB`,
    [client, publicFacility?.name, year?.year],
  );

  const { modal, handleOpenModal } = useBabReportModal({
    accounts: selectedReport?.accounts,
    apiParams,
    modalGridRef,
    recalculate,
    yearId,
  });
  const handleDataChange = useCallback(
    async (idsVisible: boolean, type: string) => {
      tableGridRef?.current?.api.onFilterChanged();
      await updateMasterConfiguration({
        ...masterConfiguration,
        babConfig: {
          idsVisible,
          type: idsVisible ? type : undefined,
        },
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const isExternalFilterPresent = useCallback(
    () => masterConfiguration?.babConfig?.idsVisible !== true,
    [masterConfiguration?.babConfig?.idsVisible],
  );

  const doesExternalFilterPass = useCallback(
    ({ data }: IRowNode) => {
      if (!data) {
        return true;
      }

      return Boolean(masterConfiguration?.babConfig?.idsVisible) || !!data.accountId;
    },
    [masterConfiguration?.babConfig],
  );

  return {
    ...apiParams,
    DEFAULT_CC_TYPE,
    apiParams,
    doesExternalFilterPass,
    getReportExcelFileName,
    handleDataChange,
    handleExport: () => tableGridRef.current?.api?.exportDataAsExcel(),
    handleOpenModal,
    isCCIdsVisible: Boolean(masterConfiguration?.babConfig?.idsVisible),
    isExternalFilterPresent,
    masterConfiguration,
    modal,
    options,
    recalculateReport,
    selectedReport,
    setShowManualCorrections,
    showManualCorrections,
    tableGridRef,
  };
};

export type ViewProps = ReturnType<typeof useController>;
