import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from '@org/locales';

import packageJson from '../../../package.json';

const StyledFooter = styled.div`
  flex: 0 0 40px;
  display: flex;
  flex-wrap: nowrap;
  background-color: #2d2d2d;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  white-space: nowrap;
  padding: 0 5px;
  font-size: var(--font-size-secondary);

  span {
    font-size: var(--font-size-tertiary);
    color: #fff;
    padding: 0 5px;
  }

  a,
  a:visited,
  a:active,
  a:hover {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    color: #ffb600;
  }
`;

const Footer = ({ legalNotice = false }) => {
  const { t } = useTranslation();

  return (
    <StyledFooter id="footer">
      <div className="left">
        <span>{t('main:footer.copyright')}</span>
      </div>

      {legalNotice && (
        <div className="right">
          <span>
            <a
              href="https://www.pwc.de/de/disclaimer.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('main:footer.disclaimer')}
            </a>
          </span>
          <span>
            <a
              href="https://www.pwc.de/de/impressum.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('main:footer.legalNotice')}
            </a>
          </span>
          <span>
            <a
              href="https://www.pwc.de/de/nutzungsbedingungen.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('main:footer.termsOfUse')}
            </a>
          </span>
          <span>
            <a
              href="https://www.pwc.de/de/datenschutzerklaerung.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('main:footer.privacyPolicy')}
            </a>
          </span>
          <span>Version: {packageJson.version}</span>
        </div>
      )}
    </StyledFooter>
  );
};

Footer.propTypes = {
  legalNotice: PropTypes.bool,
};

export default Footer;
