import type {
  ColDef,
  FirstDataRenderedEvent,
  GetRowIdParams,
  IDetailCellRendererParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { APIParams } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { aggregated, dice, griddy } from '@org/query';
import { createAllocationStructureFromMapping } from '@org/utils';

import { useColumns } from './useColumns';

export interface ControllerProps extends IDetailCellRendererParams {
  apiParams: APIParams<'masterConfigurationId'>;
}

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
} satisfies ColDef;

export const useController = ({ data, apiParams }: ControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);
  const manualTableRef = useRef<AgGridReact>(null);

  const [selectedAccountLedger, setSelectedAccountLedger] = useState('');

  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);
  const { mutateAsync: updateWithdrawalCapitalConfig } = dice.useCreateWithdrawCapitalConfig({});

  const { costCenterConfig = {} } = masterConfiguration ?? {};

  const { data: accountCostCentersMap = {} } = griddy.useGetAllAcctIdToCostCenterNameMap({
    queryParams: {
      fileType: 'LEDGER_ACCOUNT',
      yearId: apiParams.yearId ?? '',
    },
  });

  const { data: profitCenterTrees = {} } = griddy.useGetTransactionsByFileId({
    queryParams: {
      fileType: 'JOURNAL',
      yearId: apiParams.yearId ?? '',
    },
  });

  const { data: accountsPlanMap } = griddy.useGetAllAccountIdToAccountDescriptionMap({
    queryParams: {
      fileType: 'ACCOUNT_PLAN',
      yearId: apiParams.yearId ?? '',
    },
  });

  useEffect(() => {
    updateWithdrawalCapitalConfig({
      queryParams: {
        masterConfigId: apiParams.masterConfigurationId,
        yearId: apiParams.yearId ?? '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ugly workaround for bug fix
  }, []);

  const defaultAllocationMap = createAllocationStructureFromMapping(
    accountCostCentersMap,
    costCenterConfig,
    profitCenterTrees,
  );

  const selectedWithdrawCapitalConfig = useMemo(
    () =>
      masterConfiguration?.withdrawCapitalConfig.find(
        (entry) => entry?.shortName === data.shortName,
      ),
    [masterConfiguration?.withdrawCapitalConfig, data.shortName],
  );

  const selectedAccountsAllocation = selectedWithdrawCapitalConfig?.accountsAllocation;

  const mappedAllocationMap = useMemo(
    () => Object.keys(defaultAllocationMap || {}).map((key) => ({ account: key })),
    [defaultAllocationMap],
  );

  const getRowId = useCallback(
    ({ data: getRowParamsData }: GetRowIdParams) => getRowParamsData?.account,
    [],
  );

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: mappedAllocationMap ?? [],
  });

  const onRowSelectionChanged = useCallback((value: SelectionChangedEvent) => {
    const { api: eventApi } = value;
    const selectedRow = eventApi.getSelectedRows()?.[0];

    setSelectedAccountLedger(selectedRow?.account);
  }, []);

  const onFirstSelectFirst = useCallback(
    (params: FirstDataRenderedEvent) => params.api.getDisplayedRowAtIndex(0)!.setSelected(true),
    [],
  );

  const updateAccountsAllocation = useCallback(
    async (accountsAllocation: dice.WithdrawCapital['accountsAllocation']) => {
      const updatedMasterConfig = masterConfiguration?.withdrawCapitalConfig.map((entry) => {
        if (entry?.shortName === selectedWithdrawCapitalConfig?.shortName) {
          return {
            ...entry,
            accountsAllocation,
          };
        }

        return entry;
      });

      await updateMasterConfiguration({
        ...masterConfiguration,
        withdrawCapitalConfig: updatedMasterConfig,
      });

      await updateWithdrawalCapitalConfig({
        queryParams: {
          masterConfigId: apiParams.masterConfigurationId,
          yearId: apiParams.yearId ?? '',
        },
      });
    },
    [
      apiParams.masterConfigurationId,
      apiParams.yearId,
      masterConfiguration,
      selectedWithdrawCapitalConfig?.shortName,
      updateMasterConfiguration,
      updateWithdrawalCapitalConfig,
    ],
  );

  const columnDefs = useColumns({
    selectedAccountsAllocation,
    updateAccountsAllocation,
    defaultAllocationMap,
    accountsPlanMap,
  });

  return {
    agGridProps,
    onFirstSelectFirst,
    columnDefs,
    defaultColDef,
    getRowId,
    tableRef,
    selectedAccountLedger,
    apiParams,
    onRowSelectionChanged,
    manualTableRef,
    updateAccountsAllocation,
    selectedAccountsAllocation,
    defaultAllocationMap,
  };
};

export type ControllerType = ReturnType<typeof useController>;
