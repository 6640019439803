import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface SelectAllCheckboxProps {
  allocationCalculatedCosts?: dice.AllocationConfigPreselectedValuesDTO['calculatedCosts'];
  onSelectionChanged: (
    entry: dice.AllocationConfigPreselectedValuesDTO['calculatedCosts'],
  ) => Promise<void>;
  rowData: dice.AllocationConfigPreselectedValuesDTO['calculatedCosts'];
}

export const SelectAllCheckbox = ({
  rowData = [],
  allocationCalculatedCosts = [],
  onSelectionChanged,
}: SelectAllCheckboxProps) => {
  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      const allSelected = rowData.map((item) => item);

      await onSelectionChanged(allSelected);
    } else {
      await onSelectionChanged([]);
    }
  };

  const indeterminate =
    allocationCalculatedCosts.length > 0 && allocationCalculatedCosts.length < rowData.length;

  return (
    <CheckboxCellRenderer
      indeterminate={indeterminate}
      onEdit={handleOnEdit}
      value={rowData.length === allocationCalculatedCosts.length}
    />
  );
};
