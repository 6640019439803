import type { ICellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';
import { memo } from 'react';

import classNames from 'classnames';

import type { RowType } from '@org/utils';

import { Button } from '../Button';

export type ButtonCellRendererProps = {
  icon?: string;
  text?: string;
  kind?: 'primary' | 'secondary' | 'transparent' | undefined;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  onClick: (event: unknown) => void;
  disabled: boolean;
  className?: string;
  gridProps: ICellRendererParams;
} & ICellRendererParams;

export interface ButtonHandlerProps extends ButtonCellRendererProps {
  type: RowType;
}

export const ButtonCellRendererMemo: FC<ButtonCellRendererProps> = (props) => {
  const {
    kind = 'cellRenderer',
    size = 'iconButton',
    onClick,
    disabled = false,
    icon,
    text,
    className,
  } = props;

  const handleOnClick = () => {
    onClick(props);
  };
  return (
    <div className={classNames('flex text-right', className)}>
      <Button
        className={`comments-add bt-control appkiticon ${icon} !px-1.5`}
        data-testid="ButtonRenderer"
        disabled={disabled}
        onClick={handleOnClick}
        size={size}
        variant={kind}
      >
        {text}
      </Button>
    </div>
  );
};

export const ButtonCellRenderer = memo(ButtonCellRendererMemo);
