import type { ICellRendererParams } from 'ag-grid-community';
import { useCallback } from 'react';

import type { dice, griddy } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface AccountTableCheckboxProps extends ICellRendererParams {
  accountsAllocation: dice.WithdrawCapital['accountsAllocation'];
  handleEditConfig: (accounts: dice.WithdrawCapital['accountsAllocation']) => Promise<void>;
  defaultAllocationMap: dice.WithdrawCapital['accountsAllocation'];
  label: string;
}

export const AccountTableCheckbox = ({
  data,
  handleEditConfig,
  accountsAllocation,
  defaultAllocationMap,
  label,
}: AccountTableCheckboxProps) => {
  const handleOnEdit = useCallback(
    async (isChecked: boolean) => {
      if (isChecked) {
        const updatedAccountsAllocation: griddy.WithdrawCapital['accountsAllocation'] = {
          ...accountsAllocation,
          [data.account]: defaultAllocationMap?.[data.account],
        };

        await handleEditConfig(updatedAccountsAllocation);
      } else if (!isChecked) {
        const clonedAccountsAllocation = { ...accountsAllocation };
        delete clonedAccountsAllocation?.[data.account];

        await handleEditConfig(clonedAccountsAllocation);
      }
    },
    [accountsAllocation, data.account, defaultAllocationMap, handleEditConfig],
  );

  const isSelected = Boolean(accountsAllocation?.[data.account]) ?? false;

  return (
    <CheckboxCellRenderer
      label={label}
      onEdit={handleOnEdit}
      value={isSelected}
    />
  );
};
