/* eslint-disable unicorn/no-unreadable-array-destructuring */
import { useMemo } from 'react';

import { Breadcrumb as AppkitBreadcrumb } from 'appkit-react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

const StyledBreadCrumb = styled(AppkitBreadcrumb)`
  &.a-breadcrumbs {
    margin-top: 15px;
    margin-left: 5.5rem;
    border-bottom: unset;
  }
`;

const BreadCrumb = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  let [, , clientId, publicFacilityId, yearId, type, masterConfigurationId, report] =
    location.pathname.split('/');

  // FIXME: Burn it with fire
  // Only needed until we migrate to next
  if (masterConfigurationId === 'files') {
    masterConfigurationId = undefined;
  }

  const { getClient } = aggregated.useClients({});
  const client = useMemo(() => getClient(clientId), [clientId, getClient]);

  const { publicFacility } = aggregated.usePublicFacilities({
    clientId,
    publicFacilityId,
  });

  const { selectedEntity } = aggregated.useMasterConfigurationByYearCalculation({
    masterConfigurationId,
    yearCalculationId: yearId ?? '',
  });

  const { year } = aggregated.useYear({
    publicFacilityId,
    type,
    yearId,
  });

  const breadcrumbs = [
    {
      link: '',
      value: (
        <span
          className="a-font-16"
          onClick={() => navigate('/')}
        >
          {t('common:clients')}
        </span>
      ),
    },
    client?.clientName && {
      link: '',
      value: (
        <span
          className="a-font-16"
          onClick={() => navigate(`/client/${clientId}`)}
        >
          {decodeURIComponent(client?.clientName)}
        </span>
      ),
    },
    publicFacilityId && {
      link: '',
      value: (
        <span
          className="a-font-16"
          onClick={() => navigate(`/client/${clientId}/${publicFacilityId}`)}
        >
          {publicFacility?.name}
        </span>
      ),
    },
    yearId && {
      link: '',
      value: (
        <span
          className="a-font-16"
          onClick={() => navigate(`/client/${clientId}/${publicFacilityId}/${yearId}/${type}`)}
        >
          {year?.year}
        </span>
      ),
    },
    masterConfigurationId && {
      link: '',
      value: (
        <span
          className="a-font-16"
          onClick={() =>
            navigate(
              `/client/${clientId}/${publicFacilityId}/${yearId}/${type}/${masterConfigurationId}/configuration`,
            )
          }
        >
          {selectedEntity?.name}
        </span>
      ),
    },
    report === 'report' && {
      link: '',
      value: <span className="a-font-16">{t('common:report')}</span>,
    },
  ].filter(Boolean);

  return (
    <StyledBreadCrumb
      className="breadcrumb-with-array breadCrumb"
      items={breadcrumbs}
      separator={<span className="appkiticon icon-right-chevron-fill a-font-12" />}
    />
  );
};

export default BreadCrumb;
