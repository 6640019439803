import type { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';

import type { aggregated, dice } from '@org/query';
import { formatNumberToLocaleString, formatToSimpleDate } from '@org/utils';

import { FILE_TYPE } from '../CostCenterConfiguration';

export const FILE_CONFIGURATION = {
  assets: {
    inputFields: [
      {
        headerName: 'anlagennummer',
        field: 'assetNumberOriginal',
        type: 'text',
      },
      {
        headerName: 'anlagenbezeichnung',
        field: 'assetDescription',
        type: 'text',
      },
      {
        headerName: 'anlagen_klasse_nr',
        field: 'assetCategory',
        type: 'text',
      },
      {
        headerName: 'anlagen_unr',
        field: 'assetAccount',
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'restbuchwert_beginn',
        field: 'rbvCapitalAssetsBegin',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'restbuchwert_ende',
        field: 'rbvCapitalAssetsEnd',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'anschaffungsdatum',
        field: 'purchasingDate',
        type: 'date',
        valueFormatter: (params: ValueFormatterParams) =>
          params?.data?.purchasingDate
            ? formatToSimpleDate(new Date(params.data.purchasingDate))
            : '',
      },
      {
        headerName: 'nutzungsdauer_in_monaten',
        field: 'lifeTime',
        type: 'number',
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'anschaffungswert_ende',
        field: 'acquisitionValueEnd',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        headerName: 'anlage_kostenstelle_nr',
        field: 'clientsCostCenterName',
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'anlage_afa',
        field: 'assetDepreciation',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'afa_zugang_HGB',
        field: 'depreciationHGB',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        field: 'dataSource',
        headerName: 'Data source',
        type: 'text',
      },
    ],
    name: 'assets.txt',
  },
  journal: {
    inputFields: [
      {
        headerName: 'index',
        field: 'index',
        type: 'text',
      },
      {
        headerName: 'buchungstext',
        field: 'description',
        type: 'text',
      },
      {
        headerName: 'konto_nr',
        field: 'accountId',
        type: 'text',
      },

      {
        headerName: 'soll_haben',
        field: 'transactionType',
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'betrag',
        field: 'transactionAmount',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        headerName: 'kostenstelle_nr',
        field: 'clientsCostCenterName',
        type: 'text',
      },
      {
        headerName: 'auftrag_nr',
        field: 'jobId',
        type: 'text',
      },
      {
        headerName: 'profitcenter_nr',
        field: 'profitCenterId',
        type: 'text',
      },
      {
        field: 'dataSource',
        headerName: 'Data source',
        type: 'text',
      },
    ],
    name: 'journal.txt',
  },
  kontenplan: {
    inputFields: [
      {
        headerName: 'konto_nr',
        field: 'accountId',
        type: 'text',
      },
      {
        headerName: 'konto_bez',
        field: 'accountDescription',
        type: 'text',
      },
      {
        field: 'dataSource',
        headerName: 'Data source',
        type: 'text',
      },
    ],
    name: 'kontenplan.txt',
  },
  labels: {
    inputFields: [
      {
        headerName: 'nr',
        field: 'number',
        type: 'text',
      },
      {
        headerName: 'typ',
        field: 'type',
        type: 'text',
      },
      {
        headerName: 'bez',
        field: 'description',
        type: 'text',
      },
      {
        field: 'dataSource',
        headerName: 'Data source',
        type: 'text',
      },
    ],
    name: 'labels.txt',
  },
  sachkonten: {
    inputFields: [
      {
        headerName: 'anlagen_nr',
        field: 'assetNumber',
        type: 'text',
      },
      {
        headerName: 'anlagen_unr',
        field: 'assetAccount',
        type: 'number',
      },
      {
        headerName: 'anlagen_bez',
        field: 'assetDescription',
        type: 'text',
      },
      {
        headerName: 'klasse_nr',
        field: 'assetCategory',
        type: 'text',
      },
      {
        headerName: 'konto_nr',
        field: 'accountId',
        type: 'text',
      },
      {
        headerName: 'kst_nr',
        field: 'clientsCostCenterName',
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        headerName: 'bw_anfang',
        field: 'rbvCapitalAssetsBegin',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },

        headerName: 'bw_ende',
        field: 'rbvCapitalAssetsEnd',
        type: 'number',
        valueFormatter: ({ value }: ValueFormatterParams) => formatNumberToLocaleString(value),
      },
      {
        field: 'dataSource',
        headerName: 'Data source',
        type: 'text',
      },
    ],
    name: 'sachkonten.txt',
  },
} satisfies Record<string, { inputFields: (ColDef | ColGroupDef)[]; name: string }>;

export type ConfigurationTypes = keyof typeof FILE_CONFIGURATION;

export const getColumnsForInputFile = (type: aggregated.BaseFileType) =>
  FILE_CONFIGURATION?.[type].inputFields ?? [];

export const FILE_TYPES_COUNT = Object.keys(FILE_TYPE).length;

export const isFilterModal = (apiFileType: aggregated.ApiFileType) =>
  [FILE_TYPE.ASSETS.apiType, FILE_TYPE.JOURNAL.apiType, FILE_TYPE.LEDGER_ACCOUNT.apiType].includes(
    apiFileType,
  );

export const showFilteringTable = (
  apiFileType: aggregated.ApiFileType,
  erpType: dice.FileErpTypeDTO['erpType'],
) => {
  if (apiFileType === 'JOURNAL' && erpType === 'NAVISION_365_BC') {
    return false;
  }

  return isFilterModal(apiFileType);
};
