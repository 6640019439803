import type {
  ColDef,
  ColGroupDef,
  GridApi,
  IRowNode,
  IsServerSideGroupOpenByDefaultParams,
  RowSelectedEvent,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { Dispatch, RefObject, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';

import type { CorrectAny } from '@org/models';
import type { aggregated, dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { Switch } from '@org/ui';

import type { InitialFilterMap } from './utils';
import { createServerSideDatasource } from './createServerSideDatasource';

export interface ControllerProps {
  agGridRef: RefObject<AgGridReact>;
  apiFileType: Extract<aggregated.ApiFileType, 'ASSETS' | 'JOURNAL' | 'LEDGER_ACCOUNT'>;
  type: string;
  tab: string;
  clientId: string;
  yearCalculationId: string;
  year: dice.YearDTOV2;
  erpType: string;
  updateToggle: ({
    toggleName,
    toggleValue,
  }: {
    toggleName: string;
    toggleValue: boolean;
  }) => Promise<dice.ImportFileResponse>;
  setFiltersWithChildren: Dispatch<SetStateAction<Set<unknown>>>;
  onFilterRowCheckboxSelected: (event: RowSelectedEvent) => Promise<void>;
}

const defaultColDef = {
  cellClass: ({ node: { level } }) => (level === 0 ? 'bg-highlighted font-bold' : 'text-right'),
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
  flex: 1,
} satisfies ColDef;

export const useController = ({
  agGridRef,
  apiFileType,
  type,
  clientId,
  year,
  yearCalculationId,
  erpType,
  updateToggle,
  setFiltersWithChildren,
  onFilterRowCheckboxSelected,
}: ControllerProps) => {
  const { t } = useTranslation();

  const columnDefs = useMemo(
    () => [{ field: 'nodeKey', hide: true }] satisfies (ColDef | ColGroupDef)[],
    [],
  );

  const changeToggleValueServerSide = useCallback(
    (api: GridApi, name: string, toggleValue: boolean) => {
      api?.forEachNode((node) => {
        if (node.key === name) {
          node.setData({ ...node.data, toggle: toggleValue });
        }
      });
    },
    [],
  );

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: t('main:cams.filterTable.filterKey'),
      headerCheckboxSelection: true,
      cellClass: ({ data, node }) => {
        if (data.toggle) {
          return 'ag-row-checkbox-disable';
        }

        if (data?.children?.length === 0 && node.level === 0) {
          return 'bg-gray-200 pointer-events-none';
        }

        return '';
      },
      cellRendererSelector: ({ value, data, node }) => {
        if (data?.children?.length === 0 && node.level === 0) {
          return {
            component: () => (
              <div className="ml-8 text-slate-600">
                {`${value} (${t('common:modals.camsImport.errors.NO_DATA_FOUND_FOR_ESRA_CLIENT_ID_AND_CALCULATION_YEAR')})`}
              </div>
            ),
          };
        }

        return {
          component: 'agGroupCellRenderer',
        };
      },
      cellRendererParams: {
        checkbox: ({ node, data }: { node: IRowNode; data: CorrectAny }) =>
          !(data?.children?.length === 0 && node.level === 0),
        innerRenderer: (params: {
          value?: string;
          api: GridApi;
          node: IRowNode;
          data: CorrectAny;
        }) => {
          const { value, node, data } = params;

          return (
            <div className="flex items-center">
              {node.parent?.key === null ? (
                <Switch
                  classNames={{
                    base: 'm-0',
                    wrapper: 'h-5 bg-zinc-400 group-data-[selected=true]:bg-orange-default',
                  }}
                  defaultSelected={data.toggle}
                  name={value}
                  onValueChange={async (toggleValue) => {
                    await updateToggle({
                      toggleName: `${value}Toggle`,
                      toggleValue,
                    });
                    changeToggleValueServerSide(params.api, value!, toggleValue);
                  }}
                  size="sm"
                />
              ) : (
                ''
              )}
              {value}
            </div>
          );
        },
      },
      field: 'nodeKey',
      filter: false,
    }),
    [changeToggleValueServerSide, t, updateToggle],
  );

  const isServerSideGroupOpenByDefault = useCallback(
    (params: IsServerSideGroupOpenByDefaultParams) => params.rowNode.level < 0,
    [],
  );
  const isServerSideGroup = useCallback(
    (dataItem: InitialFilterMap) => Boolean(dataItem.isGroup),
    [],
  );
  const getServerSideGroupKey = useCallback((dataItem: InitialFilterMap) => dataItem.nodeKey!, []);

  const apiParams = useMemo(
    () => ({
      apiFileType,
      esraClientId: clientId ?? '',
      isPostCalculation: type === 'post',
      calculationYear: Number(year?.year),
      yearCalculationId: yearCalculationId ?? '',
      erpType,
    }),
    [apiFileType, clientId, erpType, type, year?.year, yearCalculationId],
  );

  const dataSource = useMemo(
    () => createServerSideDatasource(apiParams, t, setFiltersWithChildren),
    [apiParams, t, setFiltersWithChildren],
  );

  return {
    autoGroupColumnDef,
    defaultColDef,
    agGridRef,
    isServerSideGroupOpenByDefault,
    isServerSideGroup,
    getServerSideGroupKey,
    dataSource,
    columnDefs,
    onFilterRowCheckboxSelected,
  };
};

export type ControllerType = ReturnType<typeof useController>;
