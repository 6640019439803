import type { ColDef } from 'ag-grid-community';

export const FILE_TYPE = {
  ACCOUNTS_PLAN: {
    apiType: 'ACCOUNT_PLAN',
    nameKey: 'common:files.accountPlan',
    type: 'kontenplan',
  },
  ASSETS: {
    apiType: 'ASSETS',
    nameKey: 'common:files.assets',
    type: 'assets',
    uniqueIds: ['anlagennummer', 'anlagen_unr'],
  },
  JOURNAL: {
    apiType: 'JOURNAL',
    nameKey: 'common:files.journal',
    type: 'journal',
    uniqueIds: ['index'],
  },
  LABELS: { apiType: 'LABELS', nameKey: 'common:files.labels', type: 'labels' },
  LEDGER_ACCOUNT: {
    apiType: 'LEDGER_ACCOUNT',
    nameKey: 'common:files.generalAccounts',
    type: 'sachkonten',
  },
};

export const MOVE_DIRECTION = {
  MOVE_DOWN: 'MOVE_DOWN',
  MOVE_UP: 'MOVE_UP',
};

export const LABEL_FILE_ROW_TYPES = {
  ANLAGEN_KLASSE: {
    description: 'Anlagenklasse',
    key: 'ANLAGEN_KLASSE',
  },
  AUFTRAG: {
    description: 'Auftrag',
    key: 'AUFTRAG',
  },
  BEWERTUNGSBEREICH: {
    description: 'Bewertungsbereich',
    key: 'BEWERTUNGSBEREICH',
  },
  KST: {
    description: 'Kostenstelle',
    key: 'KST',
  },
  KST_BEREICH: {
    description: 'Kostenstellen Bereich',
    key: 'KST_BEREICH',
  },
  PROFITCENTER: {
    description: 'Profit Center',
    key: 'PROFITCENTER',
  },
  PROFITCENTER_BEREICH: {
    description: 'Profit Center Bereich',
    key: 'PROFITCENTER_BEREICH',
  },
  PROJEKT: {
    description: 'Projekt',
    key: 'PROJEKT',
  },
};

export const DEFAULT_GROUP_NAME = 'default';

export const defaultColDef: ColDef = {
  sortable: false,
};
