import { useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { useTranslation } from '@org/locales';

import { objDelete, objGet, objSet } from '../../../../utils';
import SelectComponent from '../../SelectComponent';
import { ListItemWrapper } from '../../style/ListItemWrapper';
import { AccountRow } from './AccountRow';

const EditAccountAllocation = ({
  value,
  onChange,
  costCenterMapping,
  defaultAllocationMap,
  selectedConfig = {},
  accountsPlanMap = {},
}) => {
  const [selectedAccountIds, setSelectedAccountIds] = useState((value && Object.keys(value)) || []);
  const { t } = useTranslation();

  const updateAccountMap = (accountId, allocation) => {
    const newValue = structuredClone(value);
    objSet(newValue, accountId, allocation);
    onChange(newValue);
  };
  const deselectAccount = (accountId) => {
    objDelete(value, accountId);
    onChange(value);
    setSelectedAccountIds(Object.keys(value));
  };
  return (
    <>
      <div style={{ width: '40vw' }}>
        <SelectComponent
          items={Object.keys(defaultAllocationMap)}
          multiple
          onSelect={(newAccountIds) => {
            setSelectedAccountIds(newAccountIds);
            const newValue = {};
            newAccountIds.forEach((accountId) => {
              objSet(newValue, accountId, objGet(defaultAllocationMap, accountId));
            });
            onChange(newValue);
          }}
          placeholder={t('main:manageMenu.placeholderSelectAccounts')}
          t={t}
          value={selectedAccountIds}
        />
      </div>
      {value && Object.keys(value)?.length
        ? Object.keys(value).map((accountId, index) => (
            <ListItemWrapper key={index}>
              <AccountRow
                accountId={accountId}
                accountsPlanMap={accountsPlanMap}
                costCenterMapping={costCenterMapping}
                costCenterToAllocationMap={objGet(value, accountId) || {}}
                deselectAccount={deselectAccount}
                selectedConfig={selectedConfig}
                updateAccountMap={updateAccountMap}
              />
            </ListItemWrapper>
          ))
        : null}
    </>
  );
};

EditAccountAllocation.propTypes = {
  accountsPlanMap: PropTypes.object,
  costCenterMapping: PropTypes.array,
  defaultAllocationMap: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  selectedConfig: PropTypes.object,
  value: PropTypes.object,
};

export default EditAccountAllocation;
