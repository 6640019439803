import type { ChangeEvent } from 'react';
import { useCallback } from 'react';

import classnames from 'classnames';

import { CheckboxInput, VirtualizedList } from '@org/ui';

export interface CheckboxListProps<T> {
  handleSelect?: (item: T) => void;
  onChange?: (item: T, event: ChangeEvent<HTMLInputElement>) => void;
  items: T[];
  getCheckboxProps: (item: T) => {
    disabled?: boolean;
    checked?: boolean;
    indeterminate?: boolean;
    notAvailable?: boolean;
    children?: string;
    selected?: boolean;
    key?: string;
  };
  'data-testid'?: string;
}

export function CheckboxList<T>(props: CheckboxListProps<T>) {
  const { items, getCheckboxProps, handleSelect, onChange, ...rest } = props;

  const CheckboxLine = useCallback(
    ({ index = 0 }) => {
      const item = items[index];
      const checkboxProps = getCheckboxProps(item);
      const {
        notAvailable = false,
        selected,
        disabled = false,
        ...htmlCheckboxProps
      } = checkboxProps;
      return (
        <CheckboxInput
          {...htmlCheckboxProps}
          className={classnames('flex p-1 pl-2', {
            'bg-orange-lighter': selected === true,
            'bg-stone-300': notAvailable,
            'pointer-events-none': disabled,
          })}
          disableStopPropagation
          disabled={notAvailable || disabled}
          key={htmlCheckboxProps.key}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange?.(item, event)}
          onClick={() => handleSelect?.(item)}
        />
      );
    },
    [getCheckboxProps, onChange, handleSelect, items],
  );

  return (
    <div>
      <VirtualizedList
        Component={CheckboxLine}
        itemCount={items.length}
        {...rest}
      />
    </div>
  );
}
