import type { ControllerRenderProps } from 'react-hook-form';
import { useCallback } from 'react';

import { useForm } from 'react-hook-form';

import type { APIParams } from '@org/query';
import { dice } from '@org/query';

export interface ControllerProps {
  handleUpdateEntry: (entry: dice.CostUnitAllocationBasisConfigEntryDTO) => Promise<void>;
  selectedAllocationBasis?: dice.CostUnitAllocationBasisConfigEntryDTO;
  apiParams: APIParams<'masterConfigurationId'>;
}

export type ViewProps = ReturnType<typeof useController>;

export const useController = (props: ControllerProps) => {
  const { apiParams, selectedAllocationBasis, handleUpdateEntry } = props;
  const { allocationDistributionConfig: { selectedTechnicalData: technicalData = '' } = {} } =
    selectedAllocationBasis ?? {};

  const { control } = useForm({
    defaultValues: {
      technicalData,
    },
    values: {
      technicalData,
    },
  });

  const { data: options = [] } = dice.useGetCostUnitAllocationPreselectedValues(
    {
      pathParams: apiParams,
    },
    {
      gcTime: 3600,
      select: ({ technicalDataGroupCharacteristics }) =>
        technicalDataGroupCharacteristics?.map(({ id, name }) => ({ label: name, value: id })) ??
        [],
      staleTime: 600_000,
    },
  );

  const handleTechnicalDataSelection = useCallback(
    (
      field: ControllerRenderProps<
        {
          technicalData: string;
        },
        'technicalData'
      >,
    ) =>
      async (selectedTechnicalData: string | number | null) => {
        field.onChange(selectedTechnicalData);

        await handleUpdateEntry({
          ...selectedAllocationBasis,
          allocationDistributionConfig: {
            ...selectedAllocationBasis?.allocationDistributionConfig,
            selectedTechnicalData: selectedTechnicalData as string,
          },
        });
      },
    [handleUpdateEntry, selectedAllocationBasis],
  );

  return {
    control,
    handleTechnicalDataSelection,
    options,
  };
};
