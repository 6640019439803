import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const { onGridReady, tableRef, columnDefs, defaultColDef, modal, handleOnChange } = props;

  return (
    <>
      <BaseEditTable
        animateRows
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onCellValueChanged={handleOnChange}
        onGridReady={onGridReady}
        ref={tableRef}
        rowSelection="single"
        suppressRowClickSelection={false}
        useAutoGeneratedRowId
      />
      {modal}
    </>
  );
};

export const ManualTable = bindControllers(View, [useController]);
ManualTable.displayName = 'ManualTable';
