import type { CellValueChangedEvent, GridApi, SelectionChangedEvent } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';

import type { APIParams, dice } from '@org/query';
import { useDebounce, useEvent, useReinitializeForm } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

import { mergeOtherAllocations } from './components/utils';

export interface ControllerProps {
  data: dice.AllocationKeyConfigDTO;
  apiParams: APIParams<'masterConfigurationId'>;
  api: GridApi;
}

const createDefaultDistributionConfig = (): dice.AllocationDistributionConfigDTO => ({
  accountIds: [],
  allSelected: false,
  allocationCalculatedCost: [],
  allocationMethod: undefined,
  costCenterDistributionMap: {},
  costTypeNames: [],
  selectedTechnicalData: undefined,
});

const EMPTY_SELECT_VALUE = '';

export const totalMethodType = new Set([
  'TOTALCOST',
  'TOTAL_COST_INCLUDING_ALLOCATIONS',
  'TOTALREVENUE',
  'TOTAL_REVENUE_INCLUDING_ALLOCATIONS',
]);

export const methodContainingCostCenters = new Set([
  'COSTTYPEACCOUNT',
  'CALCULATEDCOST',
  'ACCOUNTID',
]);

export const REMAINDER_ALLOC_NAME = 'Remainder config';

const getRowMethodType = ({
  entries,
  methodType,
}: {
  entries: dice.AllocationBasisConfigEntryDTO[];
  methodType: dice.AllocationKeyConfigDTO['methodType'];
}) => {
  if (entries.length > 1) {
    const uniqueMethods = [
      ...new Set(entries.map((entry) => entry.allocationDistributionConfig?.allocationMethod)),
    ];

    return uniqueMethods.length > 1 ? 'MULTIPLE' : methodType;
  }
  return methodType;
};

export const useController = ({ data, apiParams, api }: ControllerProps) => {
  const { t } = useTranslation();

  const { id: rowId = '', rankOfCC } = data;

  const {
    createAllocationBasisConfig,
    updateSingleAllocationKeyConfig,
    allocationKeyConfig: allocationKeyConfigItems,
  } = aggregated.useAllocationKeys({
    ...apiParams,
  });

  const currentAllocationKeyConfigRowIndex = useMemo(
    () => allocationKeyConfigItems?.findIndex((config) => config.id === rowId) ?? -1,
    [allocationKeyConfigItems, rowId],
  );

  const allocationKeyConfigById = useMemo(
    () => allocationKeyConfigItems?.at(currentAllocationKeyConfigRowIndex) ?? {},
    [allocationKeyConfigItems, currentAllocationKeyConfigRowIndex],
  );

  const { basisType: initialBasisType } = allocationKeyConfigById;

  const allocationBasisConfig = useMemo(
    () => allocationKeyConfigById?.allocationBasisConfig ?? {},
    [allocationKeyConfigById],
  );

  const completeCostCenterDistribution = useMemo(
    () =>
      allocationKeyConfigById?.allocationBasisConfig
        ?.completeCostCenterAllocationDistributionConfig ?? {},
    [
      allocationKeyConfigById?.allocationBasisConfig
        ?.completeCostCenterAllocationDistributionConfig,
    ],
  );

  const allocationBasisRemainder = useMemo(() => {
    const defaultValue: Partial<dice.AllocationBasisConfigEntryDTO> = {
      id: 'remainder-id',
    };

    if (
      allocationKeyConfigById.allocationBasisConfig?.allocationBasisRemainderConfig
        ?.allocationDistributionConfig === null
    ) {
      defaultValue.allocationDistributionConfig = createDefaultDistributionConfig();
    }

    return {
      ...allocationKeyConfigById.allocationBasisConfig?.allocationBasisRemainderConfig,
      ...defaultValue,
    };
  }, [allocationKeyConfigById.allocationBasisConfig?.allocationBasisRemainderConfig]);

  const allocationBasisConfigEntriesWithRemainder = useMemo(
    () => [
      ...(allocationBasisConfig?.allocationBasisConfigEntries ?? []),
      allocationBasisRemainder,
    ],
    [allocationBasisConfig?.allocationBasisConfigEntries, allocationBasisRemainder],
  );

  const allocationBasisConfigEntries = useMemo(
    () => allocationBasisConfig?.allocationBasisConfigEntries ?? [],
    [allocationBasisConfig?.allocationBasisConfigEntries],
  );

  // NOTE: for complete cost center basis - take any first entry and replace its allocationDistributionConfig
  // selectedAllocation is used in method tables
  const initialSelectedAllocation = useMemo(() => {
    const firstEntry = allocationBasisConfigEntries?.at(0) ?? {};

    if (initialBasisType === 'COMPLETE_COST_CENTER') {
      return {
        ...firstEntry,
        allocationDistributionConfig: completeCostCenterDistribution,
      };
    }

    return firstEntry;
  }, [allocationBasisConfigEntries, completeCostCenterDistribution, initialBasisType]);

  const [selectedAllocation, setSelectedAllocation] = useState<
    dice.AllocationBasisConfigEntryDTO | undefined
  >(initialSelectedAllocation);

  const allocationTableRef = useRef<AgGridReact>(null);
  const accountTableRef = useRef<AgGridReact>(null);
  const costCenterTableRef = useRef<AgGridReact>(null);
  const costTypeAccountMethodTableRef = useRef<AgGridReact>(null);

  const currentMethodType = useMemo(
    () =>
      initialBasisType === 'COMPLETE_COST_CENTER'
        ? completeCostCenterDistribution.allocationMethod
        : selectedAllocation?.allocationDistributionConfig?.allocationMethod,
    [
      completeCostCenterDistribution.allocationMethod,
      initialBasisType,
      selectedAllocation?.allocationDistributionConfig?.allocationMethod,
    ],
  );

  const defaultValues = useMemo(
    () => ({
      basisType: initialBasisType,
      methodType: currentMethodType ?? EMPTY_SELECT_VALUE,
      remainderActive: allocationBasisRemainder.enabled,
    }),
    [initialBasisType, currentMethodType, allocationBasisRemainder.enabled],
  );

  const { control, reset } = useForm({
    defaultValues,
  });

  const [basisType, methodType] = useWatch({
    control,
    defaultValue: defaultValues,
    name: ['basisType', 'methodType'],
  });

  useReinitializeForm({ defaultValues, reset });

  const otherAllocations = allocationBasisConfigEntries.filter(
    (item) => item.id !== selectedAllocation?.id,
  );

  const otherMergedAllocations = mergeOtherAllocations(otherAllocations);

  const handleReplaceEntries = useCallback(
    (entries: dice.AllocationBasisConfigEntryDTO[]) => {
      const items = Array.from(allocationKeyConfigItems ?? []);

      items[currentAllocationKeyConfigRowIndex] = {
        ...allocationKeyConfigById,
        allocationBasisConfig: {
          ...allocationBasisConfig,
          allocationBasisConfigEntries: entries,
        },
      };

      return items;
    },
    [
      allocationBasisConfig,
      allocationKeyConfigById,
      allocationKeyConfigItems,
      currentAllocationKeyConfigRowIndex,
    ],
  );

  const handleReplaceEntry = useCallback(
    (item: dice.AllocationBasisConfigEntryDTO, entries?: dice.AllocationBasisConfigEntryDTO[]) => {
      const updatedEntries = (entries ?? allocationBasisConfigEntries)?.map((currentEntry) => {
        if (item?.id && currentEntry.id === item.id) {
          currentEntry = item;
        }

        return currentEntry;
      });

      return updatedEntries ?? [];
    },
    [allocationBasisConfigEntries],
  );

  const handleSelectAllocation = useCallback((value: SelectionChangedEvent) => {
    const { api: eventApi } = value;
    const selectedAllocationRow = eventApi.getSelectedRows()?.[0];

    setSelectedAllocation(selectedAllocationRow);
  }, []);

  const updateAllocationKeyConfigAndRefreshGrid = useCallback(
    async (items: dice.AllocationKeyConfigDTO[]) => {
      await updateSingleAllocationKeyConfig({
        id: rowId,
        item: items[currentAllocationKeyConfigRowIndex],
      });

      api.updateGridOptions({
        rowData: items,
      });
      api.refreshCells();
    },
    [api, currentAllocationKeyConfigRowIndex, rowId, updateSingleAllocationKeyConfig],
  );

  const handleOnChangeBasis = useCallback(
    async (selectedBasis: dice.AllocationKeyConfigDTO['basisType']) => {
      const items = Array.from(allocationKeyConfigItems ?? []);

      const updatedMethodType =
        selectedBasis === 'COMPLETE_COST_CENTER'
          ? selectedAllocation?.allocationDistributionConfig?.allocationMethod
          : getRowMethodType({
              entries: allocationBasisConfigEntries,
              methodType:
                allocationBasisConfigEntries?.at(0)?.allocationDistributionConfig
                  ?.allocationMethod ?? undefined,
            });

      items[currentAllocationKeyConfigRowIndex] = {
        ...allocationKeyConfigById,
        allocationBasisConfig: {
          ...allocationBasisConfig,
          completeCostCenterAllocationDistributionConfig:
            selectedAllocation?.allocationDistributionConfig,
        },
        basisType: selectedBasis,
        methodType: updatedMethodType,
      };

      await updateAllocationKeyConfigAndRefreshGrid(items);
    },
    [
      allocationBasisConfig,
      allocationBasisConfigEntries,
      allocationKeyConfigById,
      allocationKeyConfigItems,
      currentAllocationKeyConfigRowIndex,
      selectedAllocation?.allocationDistributionConfig,
      updateAllocationKeyConfigAndRefreshGrid,
    ],
  );

  const handleOnChangeComment = useDebounce(
    useCallback(
      async (comment: string) => {
        const items = Array.from(allocationKeyConfigItems ?? []);

        items[currentAllocationKeyConfigRowIndex] = {
          ...allocationKeyConfigById,
          comment,
        };

        await updateAllocationKeyConfigAndRefreshGrid(items);
      },
      [
        allocationKeyConfigById,
        allocationKeyConfigItems,
        currentAllocationKeyConfigRowIndex,
        updateAllocationKeyConfigAndRefreshGrid,
      ],
    ),
    500,
  );

  const handleOnChangeMethod = useCallback(
    async (selectedMethod: dice.AllocationDistributionConfigDTO['allocationMethod']) => {
      let method: dice.AllocationKeyConfigDTO['methodType'] = selectedMethod;
      let items = Array.from(allocationKeyConfigItems ?? []);

      const noCostCentersSelected =
        Object.keys(
          selectedAllocation?.allocationDistributionConfig?.costCenterDistributionMap ?? {},
        )?.length === 0;

      const isTotalMethod = totalMethodType.has(selectedMethod ?? '');
      const allSelectedDefaultValue = noCostCentersSelected && isTotalMethod;

      const isMethodContainingCostCenters = methodContainingCostCenters.has(selectedMethod ?? '');
      const allSelectedReceivingCostCentersDefaultValue =
        noCostCentersSelected && isMethodContainingCostCenters;

      const allSelectedConfig: Partial<dice.AllocationDistributionConfigDTO> = {
        allSelected:
          selectedAllocation?.allocationDistributionConfig?.allSelected ?? allSelectedDefaultValue,
        allSelectedReceivingCostCenters:
          selectedAllocation?.allocationDistributionConfig?.allSelectedReceivingCostCenters ??
          allSelectedReceivingCostCentersDefaultValue,
      };

      const updatedEntry: dice.AllocationBasisConfigEntryDTO = {
        ...selectedAllocation,
        allocationDistributionConfig: {
          ...selectedAllocation?.allocationDistributionConfig,
          allocationMethod: selectedMethod,
          ...allSelectedConfig,
        },
      };

      if (basisType === 'COMPLETE_COST_CENTER') {
        items[currentAllocationKeyConfigRowIndex] = {
          ...allocationKeyConfigById,
          allocationBasisConfig: {
            ...allocationBasisConfig,
            completeCostCenterAllocationDistributionConfig: {
              ...allocationBasisConfig.completeCostCenterAllocationDistributionConfig,
              ...allSelectedConfig,
              allocationMethod: selectedMethod,
            },
          },
        };
      } else if (selectedAllocation?.allocationBasisName === REMAINDER_ALLOC_NAME) {
        items[currentAllocationKeyConfigRowIndex] = {
          ...allocationKeyConfigById,
          allocationBasisConfig: {
            ...allocationBasisConfig,
            allocationBasisRemainderConfig: {
              ...allocationBasisConfig.allocationBasisRemainderConfig,
              allocationDistributionConfig: {
                ...allocationBasisConfig.allocationBasisRemainderConfig
                  ?.allocationDistributionConfig,
                ...allSelectedConfig,
                allocationMethod: selectedMethod,
              },
            },
          },
        };
      } else {
        const updatedEntries = handleReplaceEntry(updatedEntry);
        items = handleReplaceEntries(updatedEntries);
      }

      if (basisType !== 'COMPLETE_COST_CENTER') {
        method = getRowMethodType({
          entries: handleReplaceEntry(
            updatedEntry,
            allocationBasisRemainder.enabled
              ? allocationBasisConfigEntriesWithRemainder
              : undefined,
          ),
          methodType: selectedMethod!,
        });
      }

      items[currentAllocationKeyConfigRowIndex].methodType = method;

      setSelectedAllocation(updatedEntry);
      await updateAllocationKeyConfigAndRefreshGrid(items);
    },
    [
      allocationBasisConfig,
      allocationBasisConfigEntriesWithRemainder,
      allocationBasisRemainder.enabled,
      allocationKeyConfigById,
      allocationKeyConfigItems,
      basisType,
      currentAllocationKeyConfigRowIndex,
      handleReplaceEntries,
      handleReplaceEntry,
      selectedAllocation,
      updateAllocationKeyConfigAndRefreshGrid,
    ],
  );

  const handleOnChangeAllocation = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      data: eventData,
    } = event;

    if (!colName) {
      return;
    }

    if (eventData.id) {
      const updatedEntries = handleReplaceEntry(eventData);
      const items = handleReplaceEntries(updatedEntries);

      await updateSingleAllocationKeyConfig({
        id: rowId,
        item: items[currentAllocationKeyConfigRowIndex],
      });

      setSelectedAllocation(updatedEntries.find((item) => item.id === eventData?.id));
    }
  });

  const handleAddNewAllocation = async () => {
    await createAllocationBasisConfig({
      allocationBasisConfigName: t('main:manageMenu.manageAllocationKeys.newAllocation'),
      allocationKeyConfigId: rowId!,
    });
  };

  const handleRemoveAllocation = useCallback(
    async (id: string) => {
      const updatedEntries = allocationBasisConfigEntries?.filter(
        (currentEntry) => currentEntry.id !== id,
      );

      const removedItem = allocationBasisConfigEntries?.find(
        (currentEntry) => currentEntry.id === id,
      );

      const items = handleReplaceEntries(updatedEntries);

      await updateSingleAllocationKeyConfig({
        id: rowId,
        item: items[currentAllocationKeyConfigRowIndex],
      });

      allocationTableRef.current?.api.applyTransaction({ remove: [removedItem] });
    },
    [
      allocationBasisConfigEntries,
      currentAllocationKeyConfigRowIndex,
      handleReplaceEntries,
      rowId,
      updateSingleAllocationKeyConfig,
    ],
  );

  const handleUpdateEntry = useCallback(
    async (entry: dice.AllocationBasisConfigEntryDTO) => {
      const copy = Array.from(allocationKeyConfigItems ?? []);
      const distribution = entry.allocationDistributionConfig;

      if (basisType === 'COMPLETE_COST_CENTER') {
        copy[currentAllocationKeyConfigRowIndex] = {
          ...allocationKeyConfigById,
          allocationBasisConfig: {
            ...allocationBasisConfig,
            completeCostCenterAllocationDistributionConfig: distribution,
          },
        };
        await updateSingleAllocationKeyConfig({
          id: rowId,
          item: copy[currentAllocationKeyConfigRowIndex],
        });
      } else if (selectedAllocation?.allocationBasisName === REMAINDER_ALLOC_NAME) {
        copy[currentAllocationKeyConfigRowIndex] = {
          ...allocationKeyConfigById,
          allocationBasisConfig: {
            ...allocationBasisConfig,
            allocationBasisRemainderConfig: {
              ...allocationBasisConfig.allocationBasisRemainderConfig,
              allocationDistributionConfig: distribution,
            },
          },
        };

        await updateSingleAllocationKeyConfig({
          id: rowId,
          item: copy[currentAllocationKeyConfigRowIndex],
        });
      } else {
        const updatedEntries = handleReplaceEntry(entry);
        const items = handleReplaceEntries(updatedEntries);

        await updateSingleAllocationKeyConfig({
          id: rowId,
          item: items[currentAllocationKeyConfigRowIndex],
        });
      }
      setSelectedAllocation(entry);
      allocationTableRef.current?.api?.applyTransaction({ update: [entry] });
    },
    [
      allocationBasisConfig,
      allocationKeyConfigById,
      allocationKeyConfigItems,
      basisType,
      currentAllocationKeyConfigRowIndex,
      handleReplaceEntries,
      handleReplaceEntry,
      rowId,
      selectedAllocation?.allocationBasisName,
      updateSingleAllocationKeyConfig,
    ],
  );

  const handleEnableRemainder = useCallback(
    async (enabled: boolean) => {
      const items = allocationKeyConfigItems ?? [];

      items[currentAllocationKeyConfigRowIndex] = {
        ...allocationKeyConfigById,
        allocationBasisConfig: {
          ...allocationBasisConfig,
          allocationBasisRemainderConfig: {
            ...allocationBasisConfig.allocationBasisRemainderConfig,
            enabled,
          },
        },
        methodType: getRowMethodType({
          entries: enabled
            ? allocationBasisConfigEntriesWithRemainder
            : allocationBasisConfigEntries,
          methodType:
            allocationBasisConfigEntries?.at(0)?.allocationDistributionConfig?.allocationMethod,
        }),
      };

      await updateSingleAllocationKeyConfig({
        id: rowId,
        item: items[currentAllocationKeyConfigRowIndex],
      });
    },
    [
      allocationBasisConfig,
      allocationBasisConfigEntries,
      allocationBasisConfigEntriesWithRemainder,
      allocationKeyConfigById,
      allocationKeyConfigItems,
      currentAllocationKeyConfigRowIndex,
      rowId,
      updateSingleAllocationKeyConfig,
    ],
  );

  const basisOptions = useMemo(
    () => [
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.basisEnum.ACCOUNT`),
        value: 'ACCOUNT',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.basisEnum.COST_TYPE_ACCOUNT`),
        value: 'COST_TYPE_ACCOUNT',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.basisEnum.COMPLETE_COST_CENTER`),
        value: 'COMPLETE_COST_CENTER',
      },
    ],
    [t],
  );

  const methodOptions = useMemo(
    () => [
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.TOTALCOST`),
        value: 'TOTALCOST',
      },
      {
        label: t(
          `main:manageMenu.allocationKeysConfiguration.methodEnum.TOTAL_COST_INCLUDING_ALLOCATIONS`,
        ),
        value: 'TOTAL_COST_INCLUDING_ALLOCATIONS',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.TOTALREVENUE`),
        value: 'TOTALREVENUE',
      },
      {
        label: t(
          `main:manageMenu.allocationKeysConfiguration.methodEnum.TOTAL_REVENUE_INCLUDING_ALLOCATIONS`,
        ),
        value: 'TOTAL_REVENUE_INCLUDING_ALLOCATIONS',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.MANUAL`),
        value: 'MANUAL',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.COSTTYPEACCOUNT`),
        value: 'COSTTYPEACCOUNT',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.ACCOUNTID`),
        value: 'ACCOUNTID',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.TECHNICALDATA`),
        value: 'TECHNICALDATA',
      },
      {
        label: t(`main:manageMenu.allocationKeysConfiguration.methodEnum.CALCULATEDCOST`),
        value: 'CALCULATEDCOST',
      },
    ],
    [t],
  );

  const handleOnChangeAutomatic = useCallback(
    async (isSelected: boolean, isSelectedKey: string) => {
      let method: dice.AllocationKeyConfigDTO['methodType'] = currentMethodType;
      let items = Array.from(allocationKeyConfigItems ?? []);

      const updatedEntry: dice.AllocationBasisConfigEntryDTO = {
        ...selectedAllocation,
        allocationDistributionConfig: {
          ...selectedAllocation?.allocationDistributionConfig,
          [isSelectedKey]: isSelected,
        },
      };

      if (basisType === 'COMPLETE_COST_CENTER') {
        items[currentAllocationKeyConfigRowIndex] = {
          ...allocationKeyConfigById,
          allocationBasisConfig: {
            ...allocationBasisConfig,
            completeCostCenterAllocationDistributionConfig: {
              ...allocationBasisConfig.completeCostCenterAllocationDistributionConfig,
              [isSelectedKey]: isSelected,
            },
          },
        };
      } else if (selectedAllocation?.allocationBasisName === REMAINDER_ALLOC_NAME) {
        items[currentAllocationKeyConfigRowIndex] = {
          ...allocationKeyConfigById,
          allocationBasisConfig: {
            ...allocationBasisConfig,
            allocationBasisRemainderConfig: {
              ...allocationBasisConfig.allocationBasisRemainderConfig,
              allocationDistributionConfig: {
                ...allocationBasisConfig.allocationBasisRemainderConfig
                  ?.allocationDistributionConfig,
                [isSelectedKey]: isSelected,
              },
            },
          },
        };
      } else {
        const updatedEntries = handleReplaceEntry(updatedEntry);

        method = getRowMethodType({
          entries: updatedEntries,
          methodType: currentMethodType!,
        });

        items = handleReplaceEntries(updatedEntries);
      }

      items[currentAllocationKeyConfigRowIndex].methodType = method;

      setSelectedAllocation(updatedEntry);
      await updateAllocationKeyConfigAndRefreshGrid(items);
    },
    [
      allocationBasisConfig,
      allocationKeyConfigById,
      allocationKeyConfigItems,
      basisType,
      currentAllocationKeyConfigRowIndex,
      currentMethodType,
      handleReplaceEntries,
      handleReplaceEntry,
      selectedAllocation,
      updateAllocationKeyConfigAndRefreshGrid,
    ],
  );

  const handleOnChangeAutomaticAllCostCenters = (isSelected: boolean) =>
    handleOnChangeAutomatic(isSelected, 'allSelectedReceivingCostCenters');

  const handleOnChangeAutomaticAll = (isSelected: boolean) =>
    handleOnChangeAutomatic(isSelected, 'allSelected');

  const allSelected = !!selectedAllocation?.allocationDistributionConfig?.allSelected;

  const allSelectedReceivingCostCenters =
    !!selectedAllocation?.allocationDistributionConfig?.allSelectedReceivingCostCenters;

  return {
    accountTableRef,
    allSelected,
    allSelectedReceivingCostCenters,
    allocationBasisConfigEntries,
    allocationBasisConfigEntriesWithRemainder,
    allocationKeyConfigById,
    allocationTableRef,
    apiParams,
    basisOptions,
    basisType,
    control,
    costCenterTableRef,
    costTypeAccountMethodTableRef,
    currentMethodType: methodType,
    handleAddNewAllocation,
    handleEnableRemainder,
    handleOnChangeAllocation,
    handleOnChangeAutomaticAll,
    handleOnChangeAutomaticAllCostCenters,
    handleOnChangeBasis,
    handleOnChangeComment,
    handleOnChangeMethod,
    handleRemoveAllocation,
    handleSelectAllocation,
    handleUpdateEntry,
    methodOptions,
    otherMergedAllocations,
    rankOfCC: rankOfCC ?? 0,
    selectedAllocation,
  };
};

export type ControllerType = ReturnType<typeof useController>;
