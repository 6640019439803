import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { getCellClassesForGroupsWithActions } from '@org/utils';

interface UseColumnsProps {
  addNewRow: (props: { node: { key: dice.CostTypeAccountDTO['type'] } }) => Promise<void> | void;
  handleDeleteModal: (props: ButtonCellRendererProps) => Promise<void> | void;
}

export const useColumns = ({ addNewRow, handleDeleteModal }: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    (): (ColDef<dice.CostTypeAccountDTO> | ColGroupDef<dice.CostTypeAccountDTO>)[] => [
      {
        colId: 'type',
        field: 'type',
        flex: 1,
        hide: true,
        rowGroup: true,
        valueFormatter: ({ value }) =>
          t(`main:manageMenu.costTypeAccountConfiguration.tableColumns.${value}`),
      },
      {
        cellClass: getCellClassesForGroupsWithActions,
        cellRendererSelector: (params) => {
          const { node } = params;

          if (node.field === 'type') {
            const icons = [
              {
                icon: 'icon-plus-outline',
                onClick: addNewRow,
                size: 'iconButton',
              },
            ];

            return {
              component: 'multipleButtons',
              params: {
                buttonParams: icons,
                className: 'flex',
              },
            };
          } else if (node.data !== undefined) {
            const icons = [
              {
                icon: 'icon-delete-outline',
                onClick: handleDeleteModal,
                size: 'iconButton',
              },
            ];

            return {
              component: 'multipleButtons',
              params: {
                buttonParams: icons,
                className: 'flex',
              },
            };
          }
        },
        colId: 'actions',
        editable: false,
        flex: 1,
        floatingFilterComponent: 'button',
        floatingFilterComponentParams: {
          className: '!hidden',
          suppressFloatingFilterButton: true,
        },
        headerClass: 'ag-right-aligned-header',
        headerName: t('common:actions'),
        maxWidth: 150,
      },
    ],
    [addNewRow, handleDeleteModal, t],
  );
};
