import type { SWRConfiguration } from 'swr';

import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { FILE_TYPE, getApiFileType } from '../../utils/data_configuration';
import { GRIDDY_ACCOUNT_IDS } from './endpoints';

export const useAccountIds = (config?: SWRConfiguration) => {
  const { yearId } = useParams<{ yearId: string }>();
  const fileType = getApiFileType(FILE_TYPE.JOURNAL);

  return useSWR<number[]>(yearId ? GRIDDY_ACCOUNT_IDS({}, { fileType, yearId }) : null, {
    ...config,
    fallbackData: [],
  });
};
