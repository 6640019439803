import { bindControllers } from '@org/utils';

import { useCostUnitProfitCenterTree } from './hooks';
import { buildController } from './useController';
import { View } from './View';

const useController = buildController(useCostUnitProfitCenterTree);
export const CostUnitCostCenterMapping = bindControllers(View, [useController]);

CostUnitCostCenterMapping.displayName = 'CostUnitCostCenterMapping';
