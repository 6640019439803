import type { GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback, useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import { aggregated } from '@org/query';

import { useColumns } from './useColumns';

export interface ControllerProps {
  selectedAllocationBasis?: dice.AllocationBasisConfigEntryDTO;
  rankOfCC: number;
  handleUpdateEntry: (entry: dice.AllocationBasisConfigEntryDTO) => Promise<void>;
  gridRef: RefObject<AgGridReact>;
  apiParams: APIParams<'masterConfigurationId'>;
}

export type ControllerType = ReturnType<typeof useController>;

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
};

export function useController({
  selectedAllocationBasis,
  apiParams,
  rankOfCC,
  handleUpdateEntry,
  gridRef,
}: ControllerProps) {
  const { preselectedAllocations } = aggregated.useAllocationKeys(apiParams);

  const rowData =
    useMemo(
      () => preselectedAllocations?.costCenters?.filter((cc) => (cc.rank ?? 0) > (rankOfCC ?? 0)),
      [preselectedAllocations?.costCenters, rankOfCC],
    ) ?? [];

  const onSelectionChanged = useCallback(
    async (
      costCenterDistributionMap: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'],
    ) => {
      const copy: dice.AllocationBasisConfigEntryDTO = {
        ...selectedAllocationBasis,
        allocationDistributionConfig: {
          ...selectedAllocationBasis?.allocationDistributionConfig,
          costCenterDistributionMap,
        },
      };

      await handleUpdateEntry(copy);
    },
    [handleUpdateEntry, selectedAllocationBasis],
  );

  const getRowId = useCallback(({ data }: GetRowIdParams) => data?.shortName, []);

  const columnDefs = useColumns({
    costCenterDistributionMap:
      selectedAllocationBasis?.allocationDistributionConfig?.costCenterDistributionMap ?? {},
    onSelectionChanged,
    rowData,
  });

  return {
    columnDefs,
    defaultColDef,
    getRowId,
    gridRef,
    rowData,
  };
}
