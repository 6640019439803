import { useEffect, useState } from 'react';

import { Banner as AppkitBanner } from 'appkit-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sendGet } from '../utils/apiCalls';

const StyledBanner = styled.div`
  .a-banner-wrap-div {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

function Banner({ status = 'negative', content = 'Something went wrong' }) {
  const [backendAlive, setBackendAlive] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await sendGet('/api/healthcheck');
        if (response.status > 400) {
          setBackendAlive(false);
        }
      } catch {
        setBackendAlive(false);
      }
    })();

    const timeout = setTimeout(() => {
      setBackendAlive(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return !backendAlive ? (
    <StyledBanner>
      <AppkitBanner
        closeable
        content={content}
        status={status}
      />
    </StyledBanner>
  ) : (
    <></>
  );
}

Banner.propTypes = {
  content: PropTypes.string,
  status: PropTypes.string,
};

export default Banner;
