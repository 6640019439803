import type { ColDef, ColGroupDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useMemo } from 'react';

import type { dice, griddy } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

import type { BaseControllerType } from '..';

interface UseColumnsProps extends BaseControllerType {
  openModal: (index: number | number[]) => unknown;
  agGridRef: RefObject<AgGridReact<griddy.TransactionModification>>;
}

export const useColumns = (props: UseColumnsProps) => {
  const { openModal, agGridRef, ...apiParams } = props;
  const { getCostCenterLongName } = aggregated.useCostCenters(apiParams);
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          checkboxSelection: true,
          colId: 'index',
          field: 'index',
          flex: 4,
          headerCheckboxSelection: true,
          headerName: t('main:manageMenu.manageTransactionModifications.tableColumns.index'),
        },
        {
          colId: 'description',
          field: 'originalTransaction.description',
          flex: 2,
          headerName: t('main:manageMenu.manageTransactionModifications.tableColumns.description'),
        },
        {
          colId: 'accountId',
          field: 'originalTransaction.accountId',
          headerName: t('main:manageMenu.manageTransactionModifications.tableColumns.accountId'),
        },
        {
          colId: 'transactionAmount',
          field: 'originalTransaction.transactionAmount',
          headerName: t(
            'main:manageMenu.manageTransactionModifications.tableColumns.transactionAmount',
          ),
          minWidth: 30,
        },
        {
          colId: 'transactionType',
          field: 'originalTransaction.transactionType',
          headerName: t(
            'main:manageMenu.manageTransactionModifications.tableColumns.transactionType',
          ),
        },
        {
          colId: 'mappedCostCenterShortname',
          field: 'originalTransaction.mappedCostCenterShortname',
          flex: 3,
          headerName: t(
            'main:manageMenu.manageTransactionModifications.tableColumns.costCenterType',
          ),
          valueFormatter: ({ value, data }) => {
            if (value) {
              return `${getCostCenterLongName(value)} (${t('common:was')}: ${
                data?.clientsCostCenterName ?? t('common:undefined')
              })`;
            }
            return data?.clientsCostCenterName ?? t('common:undefined');
          },
        },
        {
          cellDataType: 'text',
          colId: 'enabled',
          field: 'enabled',
          headerName: t('main:manageMenu.manageTransactionModifications.tableColumns.enabled'),
          valueFormatter: ({ value }) => {
            if (typeof value === 'boolean') {
              return value ? t('common:yes') : t('common:no');
            }
            return '';
          },
        },
        {
          cellRenderer: 'multipleButtons',
          cellRendererParams: {
            buttonParams: [
              {
                className: 'align-to-center-and-right',
                icon: 'icon-delete-outline',
                onClick: ({ node }: ButtonCellRendererProps) => {
                  openModal(node.rowIndex!);
                },
                size: 'iconButton',
              },
            ],
          },
          colId: 'actions',
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            icon: 'icon-delete-outline',
            onClick: () => {
              const indexes = agGridRef.current?.api
                .getSelectedNodes()
                .map(({ rowIndex }) => rowIndex!);
              if (indexes?.length) {
                openModal(indexes);
              }
            },
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerName: t('common:actions'),
          maxWidth: 120,
        },
      ] satisfies (
        | ColDef<dice.TransactionModification>
        | ColGroupDef<dice.TransactionModification>
      )[],
    [agGridRef, getCostCenterLongName, openModal, t],
  );
};
