import { Fragment, useState } from 'react';

import { Table } from 'appkit-react';

import { useTranslation } from '@org/locales';

import { FILE_CONFIGURATION } from '../../utils/data_configuration';
import ModalWrapper from '../Modal/ModalWrapper';

function HelpMenu() {
  const [showHelp, setShowHelp] = useState(false);
  const { t } = useTranslation();

  const COLUMNS = [
    {
      Header: t('main:helpMenu.tableColumns.inputName'),
      accessor: 'inputName',
      maxWidth: 200,
    },
    {
      Header: t('main:helpMenu.tableColumns.type'),
      accessor: 'type',
      maxWidth: 150,
    },
    {
      Header: t('main:helpMenu.tableColumns.description'),
      accessor: 'description',
      style: { whiteSpace: 'normal' },
    },
    {
      Cell: ({ row }) => (row.optional ? t('common:yes') : ''),
      Header: t('main:helpMenu.tableColumns.optional'),
      accessor: 'optional',
      maxWidth: 150,
    },
    {
      Header: t('main:helpMenu.tableColumns.default'),
      accessor: 'default',
      maxWidth: 150,
    },
  ];

  const getInformation = (file) => (
    <>
      <h4 className="a-my-40">{file.name}</h4>
      <Table
        columns={COLUMNS}
        data={file.inputFields}
        showPagination={false}
        size="lg"
      />
    </>
  );

  const fileInfo = Object.entries(FILE_CONFIGURATION).map(([, value], index) => (
    <Fragment key={index}>{getInformation(value)}</Fragment>
  ));

  return (
    <>
      <div onClick={() => setShowHelp(!showHelp)}>
        <span
          className="appkiticon icon-help-question-outline"
          style={{ fontSize: '2rem' }}
        />
      </div>
      <ModalWrapper
        handleClose={() => setShowHelp(false)}
        visible={showHelp}
      >
        <h3
          className="a-my-10"
          style={{ textAlign: 'center' }}
        >
          {t('main:helpMenu.header')}
        </h3>
        {fileInfo}
      </ModalWrapper>
    </>
  );
}

export default HelpMenu;
