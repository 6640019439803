import type { TFunction } from '@org/locales';

export const replaceSpecificRowLabels =
  (t: TFunction) =>
  (value: string): string => {
    switch (value) {
      case 'EXPENSE': {
        return t('main:reportPanels.babReport.headerExpenseAccounts');
      }
      case 'REVENUE': {
        return t('main:reportPanels.babReport.headerRevenueAccounts');
      }

      default: {
        return value;
      }
    }
  };
