import type { FetcherOptions } from '../../common';
import { buildFetcher } from '../../common';

const baseUrl = '/api/piam';

export type ProductIamFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = FetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>;

export const productIamFetch = buildFetcher(baseUrl);

export { type ErrorWrapper } from '../../common';
