import { useCallback, useMemo } from 'react';

import type { APIParams } from '../..';
import type { AllocationKeyConfigDTO, CreateAllocationBasisConfigPathParams } from '../dice';
import {
  useCreateAllocationBasisConfig,
  useDiceContext,
  useGetAllocationKeyConfig,
  useGetAllocationKeyConfigPreselectedValues,
  useGetAllocationKeyConfigStatus,
  useSaveAllocationKeyConfig,
  useSaveAllocationKeyConfiguration,
} from '../dice';

interface UseAllocationKeysParams extends APIParams {
  enabled?: boolean;
}

export const useAllocationKeys = ({
  masterConfigurationId,
  enabled = true,
}: UseAllocationKeysParams = {}) => {
  const { queryKeyFn } = useDiceContext();

  const { data: allocationKeyConfigStatus } = useGetAllocationKeyConfigStatus(
    {
      pathParams: { masterConfigurationId: masterConfigurationId! },
    },
    {
      enabled: !!masterConfigurationId,
    },
  );

  const getAllocationKeyConfigQueryKey = useCallback(
    (configId = masterConfigurationId) =>
      queryKeyFn({
        operationId: 'getAllocationKeyConfig',
        path: '/master-configs/{masterConfigurationId}/allocation-key-configs',
        variables: {
          pathParams: {
            masterConfigurationId: configId!,
          },
        },
      }),
    [masterConfigurationId, queryKeyFn],
  );

  const getAllocationKeyConfigPreselectedValuesQueryKey = useCallback(
    (configId = masterConfigurationId) =>
      queryKeyFn({
        operationId: 'getAllocationKeyConfigPreselectedValues',
        path: '/allocation-key-config/preselected-values/master-configs/{masterConfigurationId}',
        variables: {
          pathParams: {
            masterConfigurationId: configId!,
          },
        },
      }),
    [masterConfigurationId, queryKeyFn],
  );

  const { data: allocationKeyConfig, isLoading: isAllocationKeyConfigLoading } =
    useGetAllocationKeyConfig(
      {
        pathParams: {
          masterConfigurationId: masterConfigurationId!,
        },
      },
      {
        enabled: !!masterConfigurationId && enabled,
      },
    );

  const { data: preselectedAllocations } = useGetAllocationKeyConfigPreselectedValues(
    {
      pathParams: {
        masterConfigurationId: masterConfigurationId!,
      },
    },
    {
      enabled: !!masterConfigurationId && enabled,
    },
  );

  const { mutateAsync: updateAllocationKeyConfigAsync } = useSaveAllocationKeyConfig({});

  const { mutateAsync: updateSingleAllocationKeyConfigAsync } = useSaveAllocationKeyConfiguration(
    {},
  );

  const { mutateAsync: createAllocationBasisConfigAsync } = useCreateAllocationBasisConfig({});

  const updateAllocationKeyConfig = useCallback(
    async (items: AllocationKeyConfigDTO[]) =>
      await updateAllocationKeyConfigAsync({
        body: items,
      }),
    [updateAllocationKeyConfigAsync],
  );

  const updateSingleAllocationKeyConfig = useCallback(
    async ({ id, item }: { id: string; item: AllocationKeyConfigDTO }) =>
      await updateSingleAllocationKeyConfigAsync({
        body: item,
        pathParams: {
          masterConfigurationId: masterConfigurationId!,
          allocationKeyConfigId: id,
        },
      }),
    [masterConfigurationId, updateSingleAllocationKeyConfigAsync],
  );

  const createAllocationBasisConfig = useCallback(
    async (createPathParams: CreateAllocationBasisConfigPathParams) =>
      await createAllocationBasisConfigAsync({
        pathParams: createPathParams,
      }),
    [createAllocationBasisConfigAsync],
  );

  const preselectedAllocationsCostCenterOptions = useMemo(
    () =>
      preselectedAllocations?.costCenters?.map((ccItem) => ({
        label: ccItem.longName ?? '',
        value: ccItem.shortName ?? '',
      })) ?? [],
    [preselectedAllocations],
  );

  return {
    preselectedAllocationsCostCenterOptions,
    allocationKeyConfig,
    allocationKeyConfigStatus,
    createAllocationBasisConfig,
    isLoading: isAllocationKeyConfigLoading,
    preselectedAllocations,
    updateAllocationKeyConfig,
    updateSingleAllocationKeyConfig,
    getAllocationKeyConfigQueryKey,
    getAllocationKeyConfigPreselectedValuesQueryKey,
  };
};
