import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { griddy } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { getCellClassesForGroupsWithActions } from '@org/utils';

interface UseColumnsProps {
  addNewRow: (props?: ButtonCellRendererProps) => Promise<void> | void;
  handleDeleteModal: (props: ButtonCellRendererProps) => void;
  costCenterOptions?: { label?: string; value?: string }[];
  masterConfigurationId: string;
}

export const useColumns = ({
  addNewRow,
  handleDeleteModal,
  costCenterOptions,
  masterConfigurationId,
}: UseColumnsProps) => {
  const { t } = useTranslation();
  const { accounts, getAccountWithDescription } = aggregated.useAccountIds({
    masterConfigurationId,
  });

  const groupedOptions = useMemo(
    () => [
      {
        label: t('main:manageMenu.accountsTypes.expense.groupName'),
        options: accounts.expense?.map(({ accountId, accountDescription }) => ({
          label: (
            <div className="space-x-2">
              <span className="text-red-500">
                {t('main:manageMenu.accountsTypes.expense.shortcut')}
              </span>
              <span>{`${accountId} ${accountDescription ?? ''}`.trim()}</span>
            </div>
          ),
          value: accountId,
        })),
      },
      {
        label: t('main:manageMenu.accountsTypes.revenue.groupName'),
        options: accounts.revenue.map(({ accountId, accountDescription }) => ({
          label: (
            <div className="space-x-2">
              <span className="text-green-500">
                {t('main:manageMenu.accountsTypes.revenue.shortcut')}
              </span>
              <span>{`${accountId} ${accountDescription ?? ''}`.trim()}</span>
            </div>
          ),
          value: accountId,
        })),
      },
    ],
    [t, accounts.expense, accounts.revenue],
  );

  return useMemo(
    () =>
      [
        {
          cellEditor: 'select',
          cellEditorParams: {
            isCreatable: true,
            options: groupedOptions,
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          colId: 'accountId',
          editable: true,
          field: 'accountId',
          flex: 3,
          headerName: t('main:manageMenu.manageManualCorrections.tableColumns.accountId'),
          valueFormatter: ({ value }) => getAccountWithDescription(value),
        },
        {
          cellEditor: 'select',
          cellEditorParams: {
            isCreatable: true,
            options: costCenterOptions,
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          cellEditorPopup: true,
          colId: 'costCenterShortName',
          editable: true,
          field: 'costCenterShortName',
          flex: 1,
          headerName: t('main:manageMenu.manageManualCorrections.tableColumns.costCenter'),
        },
        {
          cellEditor: 'numeric',
          colId: 'correction',
          editable: true,
          field: 'correction',
          flex: 1,
          headerName: t('main:manageMenu.manageManualCorrections.tableColumns.correction'),
          type: 'price',
        },
        {
          colId: 'correctionType',
          field: 'correctionType',
          flex: 1,
          headerName: t('main:manageMenu.manageManualCorrections.tableColumns.correctionType'),
        },
        {
          cellClass: getCellClassesForGroupsWithActions,
          cellRendererSelector: () => ({
            component: 'multipleButtons',
            params: {
              buttonParams: [
                {
                  icon: 'icon-delete-outline',
                  onClick: (props: ButtonCellRendererProps) => handleDeleteModal(props),
                  size: 'iconButton',
                },
              ],
              className: 'flex mr-[-4px]',
            },
          }),
          colId: 'actions',
          flex: 1,
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            icon: 'icon-plus-fill',
            onClick: addNewRow,
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          maxWidth: 60,
        },
      ] satisfies (ColDef<griddy.ManualCorrection> | ColGroupDef<griddy.ManualCorrection>)[],
    [addNewRow, costCenterOptions, getAccountWithDescription, groupedOptions, handleDeleteModal, t],
  );
};
