import { type FC } from 'react';

import { Combobox, ComboboxItem } from '@org/design';
import { CostUnitCostCenterMapping } from '@org/features';
import { useTranslation } from '@org/locales';
import { LoadingScreen } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

export const View: FC<ViewProps> = (props) => {
  const {
    isLoading,
    accountIds,
    apiParams,
    mappingObject,
    handleSelectChange,
    options,
    shortName,
    handleMappingChange,
  } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div className="m-2 w-80">
        <Combobox
          aria-label="Select accounts"
          enableSelectAll
          formatSelectedText={({ selectedItems, selectionManager }) => {
            if (selectionManager.isSelectAll) {
              return t('common:select.allSelected');
            }
            return t('common:select.selected', {
              count: selectedItems.length,
              of: options.length,
            });
          }}
          isFullWidth
          isMultiple
          onSelectionChange={(selectedKeys) => {
            if (selectedKeys === 'all') {
              handleSelectChange(options.map(({ value }) => value));
              return;
            }
            handleSelectChange([...selectedKeys].map(String));
          }}
          selectAllContent={t('common:selectAll')}
          selectedKeys={accountIds.map((id) => id?.toString())}
          size="sm"
        >
          {options.map(({ label, value }) => (
            <ComboboxItem key={value}>{label}</ComboboxItem>
          ))}
        </Combobox>
      </div>
      <CostUnitCostCenterMapping
        apiParams={apiParams}
        mappingObject={mappingObject}
        onChange={handleMappingChange}
        selectedAccounts={accountIds}
        shortName={shortName}
      />
    </div>
  );
};

export const SelectAccounts = bindControllers(View, [useController]);

SelectAccounts.displayName = 'SelectAccount';
