import type {
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';

export const REMAINDER_ALLOC_NAME = 'Remainder config';

interface UseColumnsProps {
  openDeleteModal: (id: string) => void;
  handleAddNewAllocation: () => void;
}

export const useColumns = ({ openDeleteModal, handleAddNewAllocation }: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          colId: 'allocationBasisName',
          editable: ({ data }: EditableCallbackParams) =>
            data.allocationBasisName !== REMAINDER_ALLOC_NAME,
          field: 'allocationBasisName',
          flex: 6,
          headerName: t('main:manageMenu.allocationKeysConfiguration.allocationTableHeader'),
          valueFormatter: ({ data, value }: ValueFormatterParams) =>
            data.allocationBasisName !== REMAINDER_ALLOC_NAME
              ? value
              : t('main:manageMenu.allocationKeysConfiguration.allocationRemainder'),
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRendererSelector: ({ data }: ICellRendererParams) => {
            const { allocationBasisName } = data;
            if (allocationBasisName !== REMAINDER_ALLOC_NAME) {
              return {
                component: 'button',
                params: {
                  icon: 'icon-delete-outline',
                  onClick: (props: ButtonCellRendererProps) => openDeleteModal(props.data.id),
                  size: 'iconButton',
                },
              };
            }
          },
          colId: 'actions',
          editable: false,
          flex: 1,
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            disabled: false,
            icon: 'icon-plus-fill',
            onClick: () => handleAddNewAllocation(),
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          minWidth: 55,
        },
      ] satisfies ColDef<dice.AllocationBasisConfigEntryDTO>[],
    [handleAddNewAllocation, openDeleteModal, t],
  );
};
