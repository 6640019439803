export const createBasicFeeEntry = (groupName: string) => ({
  basis: 0,
  description: 'New entry',
  factor: 0,
  factorDependent: 0,
  groupId: '',
  groupName,
  id: '',
  number: 0,
  totalBasisAndFactorDependent: 0,
  totalNumberAndBasis: 0,
  totalNumberAndBasisAndFactorDependent: 0,
  totalNumberAndFactorDependent: 0,
});
