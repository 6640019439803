import { useCallback, useMemo } from 'react';

import type { APIParams } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

type CustomLabelKey = 'tradeTax' | 'bab' | 'interest' | 'costUnit';

type CustomLabel = `${CustomLabelKey}.${string}`;

export const useCustomLabels = (apiParams: APIParams) => {
  const { t } = useTranslation();
  const { masterConfiguration } = aggregated.useMasterConfiguration(apiParams);
  const customLabelMap = useMemo(
    () => new Map(masterConfiguration?.customLabels?.map(({ key = '', name }) => [key, name])),
    [masterConfiguration],
  );

  const getCustomLabel = useCallback(
    (key: CustomLabel, defaultValue = '') =>
      customLabelMap.get(key) ??
      t(`main:customLabels.${key}`, {
        defaultValue,
      }),
    [customLabelMap, t],
  );

  return getCustomLabel;
};
