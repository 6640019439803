import type { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef } from 'react';

import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import { useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';
import { ENTRY, generateGroupRowName, GROUP, prepareGroupRowNamesFromGridAPI } from '@org/utils';

import { createBasicFeeEntry } from './helpers/createBasicFeeEntry';
import { columns, useColumns } from './useColumns';

export interface ControllerProps extends Pick<aggregated.UseBasicFeeParams, 'costUnitShortName'> {
  apiParams: Omit<aggregated.UseBasicFeeParams, 'costUnitShortName'>;
}

export type ControllerType = ReturnType<typeof useController>;

const defaultColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
} satisfies ColDef;

export function useController(baseProps: ControllerProps) {
  const { costUnitShortName, apiParams } = baseProps;
  const { masterConfigurationId: masterConfigId, yearId } = apiParams;
  const {
    basicFeeConfig,
    isLoading,
    updateBasicFeeEntries,
    updateBasicFeeGroups,
    addBasicFeeGroup,
    addBasicFeeGroupEntry,
    deleteBasicFeeGroupEntry,
    deleteBasicFeeGroup,
  } = aggregated.useBasicFee({
    ...apiParams,
    costUnitShortName,
  });

  const tableRef = useRef<AgGridReact>(null);

  const { t } = useTranslation();

  const autoGroupColumnDef = {
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true,
    },
    editable: (params) => !params.node.footer,
    field: columns.description.$path,
    flex: 3,
    headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.description'),
  } satisfies ColDef;

  const generateGroupName = useCallback(
    (colName: string, level: number) =>
      generateGroupRowName(
        t('main:manageMenu.manageImputedWithdrawalCapital.newGroup'),
        prepareGroupRowNamesFromGridAPI(tableRef.current?.api, colName, level),
      ),
    [t],
  );

  const addNewRow = useCallback(
    async (type: string, props?: ButtonCellRendererProps) => {
      const groupName = props?.gridProps?.node.key ?? generateGroupName('groupName', 0);

      const newRow = createBasicFeeEntry(groupName);

      const newlyAddedRow = tableRef.current?.api?.applyTransaction({
        add: [newRow],
      });

      if (newlyAddedRow?.add?.length) {
        const {
          add: [{ rowIndex }],
        } = newlyAddedRow;

        tableRef.current?.api?.ensureIndexVisible(rowIndex!, 'middle');
      }

      if (type === GROUP) {
        const newGroup = {
          costUnitShortName,
          entries: [],
          groupName,
          masterConfigId,
          yearId,
        };

        const group = await addBasicFeeGroup(newGroup);

        if (group.id) {
          await addBasicFeeGroupEntry(newRow, group.id);
        }
      }

      if (type === ENTRY && props?.gridProps?.node?.key) {
        const item = basicFeeConfig?.find(
          (basicFee) => basicFee?.groupName === props?.gridProps?.node?.key,
        );

        if (item?.groupId) {
          await addBasicFeeGroupEntry(newRow, item.groupId);
        }
      }
    },
    [
      generateGroupName,
      costUnitShortName,
      masterConfigId,
      yearId,
      addBasicFeeGroup,
      addBasicFeeGroupEntry,
      basicFeeConfig,
    ],
  );

  const duplicateEntry = useCallback(
    async (type: string, props?: ButtonCellRendererProps) => {
      if (props && type === ENTRY) {
        const { data } = props;
        const { id: _, rowIndex: __, ...other } = data;

        const newlyAddedRow = tableRef.current?.api?.applyTransaction({
          add: [other],
        });

        if (newlyAddedRow?.add?.length) {
          const {
            add: [{ rowIndex }],
          } = newlyAddedRow;

          tableRef.current?.api?.ensureIndexVisible(rowIndex!, 'middle');

          await addBasicFeeGroupEntry(other, data.groupId);
        }
      }
    },
    [addBasicFeeGroupEntry],
  );

  const onCellValueChanged = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      node,
      data,
    } = event;

    if (!colName) {
      return;
    }

    if (node.level === 1) {
      const { groupId: _, groupName: __, ...other } = data;

      await updateBasicFeeEntries(other);
    }

    if (node.level === 0 && node.group) {
      const hasDuplicatedGroupName = basicFeeConfig?.find(
        (basicFee) => basicFee?.groupName === data.description.trim(),
      );

      if (hasDuplicatedGroupName) {
        return showNotification(
          'error',
          t('main:manageMenu.priceSheet.alerts.error.duplicateGroupName'),
        );
      }

      const item = basicFeeConfig?.find((basicFee) => basicFee?.groupName === node.key);
      if (item?.groupId) {
        await updateBasicFeeGroups({
          costUnitShortName,
          entries: node.allLeafChildren?.map((entry) => entry.data),
          groupName: data.description,
          id: item.groupId,
          masterConfigId,
          yearId,
        });
      }
    }
  });

  const handleDelete = useCallback(
    async (data: ButtonHandlerProps) => {
      const { type, ...props } = data;

      if (type === GROUP) {
        const item = basicFeeConfig?.find(
          (basicFee) => basicFee?.groupName === props?.gridProps?.node.key,
        );

        if (item?.groupId) {
          await deleteBasicFeeGroup(item.groupId);
        }
      }

      if (type === ENTRY) {
        await deleteBasicFeeGroupEntry(props.data.id);

        const items = basicFeeConfig?.filter(
          (basicFee) => basicFee?.groupName === props?.data.groupName,
        );

        // Delete also a group if there is only one last entry
        if (items?.length === 1 && props.data.groupId) {
          await deleteBasicFeeGroup(props.data.groupId);
        }
      }
    },
    [basicFeeConfig, deleteBasicFeeGroup, deleteBasicFeeGroupEntry],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonHandlerProps>({
    onConfirm: handleDelete,
  });

  const columnsDefs = useColumns({ addNewRow, duplicateEntry, handleDeleteModal });

  return {
    autoGroupColumnDef,
    columnsDefs,
    defaultColDef,
    handleDelete,
    isLoading,
    modal,
    onCellValueChanged,
    rowData: basicFeeConfig,
    tableRef,
  };
}
