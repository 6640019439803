import { useParams } from 'react-router-dom';

import { ExpenseRevenueAccountsPage as Page } from '@org/pages';

export const ExpenseRevenueAccounts = () => {
  const {
    configId: masterConfigurationId = '',
    year: yearId = '',
    pe: publicFacilityId = '',
    clientName = '',
  } = useParams<{
    clientName: string;
    year: string;
    configId: string;
    pe: string;
  }>();
  return (
    <Page
      clientId={clientName}
      masterConfigurationId={masterConfigurationId}
      publicFacilityId={publicFacilityId}
      yearId={yearId}
    />
  );
};
