import type { GetRowIdParams } from 'ag-grid-community';
import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { AllocationKeysMasterDetail } from '@org/features';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const getRowId = ({ data }: GetRowIdParams) => data?.id;

interface AllocationKeysConfigurationProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const AllocationKeysConfigurationView: FC<AllocationKeysConfigurationProps> = (
  props: AllocationKeysConfigurationProps,
) => {
  const {
    autoGroupColumnDef,
    columnDefs,
    tableRef,
    defaultColDef,
    detailCellRendererParams,
    onGridReady,
  } = props;

  return (
    <div className="flex h-full flex-col">
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        detailCellRenderer={AllocationKeysMasterDetail}
        detailCellRendererParams={detailCellRendererParams}
        detailRowHeight={525}
        getRowId={getRowId}
        masterDetail
        onGridReady={onGridReady}
        ref={tableRef}
        singleClickEdit
      />
    </div>
  );
};

export const AllocationKeysConfigurationPage = bindControllers(AllocationKeysConfigurationView, [
  useController,
]);

AllocationKeysConfigurationPage.displayName = 'AllocationKeysConfigurationPage';
