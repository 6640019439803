import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';

import type { useCostUnitProfitCenterTree } from './hooks';
import type { CostCenter, IgnoreMappings, Job, MappingObjectType, ProfitCenter } from './types';
import {
  areProfitCentersIgnored,
  buildSelector,
  cleanupEmptyIgnoreMappings,
  deserializeMappingObject,
  isCostCenter,
  isJob,
  isValidCostCenterMapping,
  iterableToArray,
  mappingKeyMapToArray,
  serializeMappingObject,
  updateCheckedJobs,
  updateIgnoreMapping,
} from './helpers';
import { useCostCenterMappingState, useProfitCenterTree } from './hooks';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  shortName?: string;
  mappingObject?: MappingObjectType;
  onChange?: (mappingObject: MappingObjectType & { isValid: boolean }) => void;
  selectedAccounts?: (string | number)[];
}

export type ControllerReturnType = ReturnType<ReturnType<typeof buildController>>;

export const buildController = (
  useTreeHook:
    | typeof useProfitCenterTree
    | typeof useCostUnitProfitCenterTree = useProfitCenterTree,
) =>
  function useController(props: ControllerProps) {
    const { mappingObject, onChange } = props;
    const { costCenterIgnoreMappings, mappingKeys } = mappingObject ?? {};

    const checkedJobs = useMemo(() => {
      const map = new Map<string, griddy.MappingKey>();
      mappingKeys?.forEach((mappingKey) => map.set(mappingKey?.id ?? '', mappingKey));

      return map;
    }, [mappingKeys]);

    const isJobChecked = useMemo(() => buildSelector(checkedJobs), [checkedJobs]);

    const ignoreMappings = useMemo(
      () => serializeMappingObject(costCenterIgnoreMappings),
      [costCenterIgnoreMappings],
    );

    const modifiedData = useTreeHook(props);

    const costCenterMappingState = useCostCenterMappingState({
      ...modifiedData,
      checkedJobs,
      ignoreMappings,
      isJobChecked,
    });

    const { selectedProfitCenterName, updateState } = costCenterMappingState;

    const { availableProfitCenters, unavailableProfitCenters, costCenterMap } = modifiedData;

    const handleChange = useCallback(
      (newIgnoreMappings: IgnoreMappings, newJobs: Map<string, griddy.MappingKey>) => {
        cleanupEmptyIgnoreMappings(newIgnoreMappings);

        onChange?.({
          costCenterIgnoreMappings: deserializeMappingObject(newIgnoreMappings),
          isValid: isValidCostCenterMapping(newIgnoreMappings, newJobs),
          mappingKeys: mappingKeyMapToArray(newJobs),
        });
      },
      [onChange],
    );

    const handleCheck = useCallback(
      (
        item: ProfitCenter | CostCenter | Job,
        { currentTarget: { checked } }: ChangeEvent<HTMLInputElement>,
        currentIgnoreMappings = ignoreMappings,
        currentCheckedJobs = checkedJobs,
        doneCallback = handleChange,
      ) => {
        const newIgnoreMappings: IgnoreMappings = new Map(currentIgnoreMappings);
        let jobsToCheck: Job[] = [];

        const profitCenterKey = areProfitCentersIgnored({ ignoreMappings }) ? '' : null;

        if (isJob(item)) {
          jobsToCheck = [item];
        } else if (isCostCenter(item)) {
          jobsToCheck = iterableToArray(item.availableJobs);

          if (item.unavailableJobs.size === 0) {
            updateIgnoreMapping(
              newIgnoreMappings,
              profitCenterKey ?? item.profitCenterName,
              item.name,
              {
                ignoreJobIds: checked,
              },
            );
          }
        } else {
          jobsToCheck = iterableToArray(item.availableJobs);

          if (item.unavailableJobs.size === 0) {
            // Cleanup profit center
            newIgnoreMappings.delete(profitCenterKey ?? item.name);
            updateIgnoreMapping(newIgnoreMappings, profitCenterKey ?? item.name, '', {
              ignoreCostCenter: checked,
              ignoreJobIds: checked,
            });
          } else {
            item.availableCostCenters.forEach(({ unavailableJobs, name, profitCenterName }) => {
              if (unavailableJobs.size === 0) {
                updateIgnoreMapping(newIgnoreMappings, profitCenterKey ?? profitCenterName, name, {
                  ignoreJobIds: checked,
                });
              }
            });
          }
        }

        updateCheckedJobs(currentCheckedJobs, checked, ...jobsToCheck);
        doneCallback(newIgnoreMappings, currentCheckedJobs);
      },
      [checkedJobs, handleChange, ignoreMappings],
    );

    const handleProfitCenterIgnore = useCallback(
      ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
        const newIgnoreMappings: IgnoreMappings = new Map();

        // All jobs are available in the tree
        const ignoreJobIds =
          checked &&
          unavailableProfitCenters.size === 0 &&
          iterableToArray(availableProfitCenters.values()).every(
            ({ unavailableJobs }) => unavailableJobs.size === 0,
          );

        newIgnoreMappings.set(
          '',
          new Map([
            [
              '',
              {
                ignoreCostCenter: checked,
                ignoreJobIds,
                ignoreProfitCenter: checked,
              },
            ],
          ]),
        );

        const allJobs = iterableToArray(availableProfitCenters?.values()).flatMap(
          ({ availableJobs: profitCenterAvailableJobs }) => [...profitCenterAvailableJobs],
        );

        updateState({
          selectedCostCenterName: '',
          selectedProfitCenterName: '',
        });

        updateCheckedJobs(checkedJobs, checked, ...allJobs);
        handleChange(newIgnoreMappings, checkedJobs);
      },
      [
        availableProfitCenters,
        checkedJobs,
        handleChange,
        unavailableProfitCenters.size,
        updateState,
      ],
    );

    const handleCostCenterIgnore = useCallback(
      (
        { currentTarget: { checked } }: ChangeEvent<HTMLInputElement>,
        ...profitCenters: string[]
      ) => {
        const newIgnoreMappings = new Map(ignoreMappings);
        const allJobs = profitCenters.flatMap((profitCenter) => {
          if (profitCenter === '') {
            return iterableToArray(availableProfitCenters.values()).flatMap(
              ({ availableJobs: jobs }) => iterableToArray(jobs),
            );
          }

          return iterableToArray(availableProfitCenters.get(profitCenter)?.availableJobs);
        });

        profitCenters.forEach((profitCenter) => {
          const oldObject = { ...newIgnoreMappings.get(profitCenter)?.get('') };

          let allJobsAvailable =
            availableProfitCenters.get(profitCenter)?.unavailableJobs.size === 0;
          if (profitCenter === '') {
            allJobsAvailable =
              unavailableProfitCenters.size === 0 &&
              iterableToArray(availableProfitCenters.values()).every(
                ({ unavailableJobs }) => unavailableJobs.size === 0,
              );
          }

          const ignoreJobIds = checked && allJobsAvailable;

          newIgnoreMappings.set(
            profitCenter,
            new Map([
              [
                '',
                {
                  ignoreCostCenter: checked,
                  ignoreJobIds,
                  ignoreProfitCenter: Boolean(oldObject.ignoreProfitCenter),
                },
              ],
            ]),
          );

          updateCheckedJobs(checkedJobs, checked, ...allJobs);
        });

        updateState({
          selectedCostCenterName: '',
        });

        handleChange(newIgnoreMappings, checkedJobs);
      },
      [
        availableProfitCenters,
        checkedJobs,
        handleChange,
        ignoreMappings,
        unavailableProfitCenters.size,
        updateState,
      ],
    );

    const handleJobsIgnore = useCallback(
      ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>, ...costCenters: string[]) => {
        const newIgnoreMappings = new Map(ignoreMappings);

        costCenters.forEach((costCenter) => {
          const costCenterObject = costCenterMap?.get(costCenter);

          const {
            profitCenterName = selectedProfitCenterName,
            unavailableJobs = new Set(),
            name = '',
          } = costCenterObject ?? {};

          // Ignore mapping diverges from mapping ids
          let { profitCenterName: ignoreProfitCenterKey = selectedProfitCenterName } =
            costCenterObject ?? {};

          // Handle case when Ignore Profit center is checked
          if (areProfitCentersIgnored({ ignoreMappings })) {
            ignoreProfitCenterKey = '';
          }

          if (!newIgnoreMappings.has(ignoreProfitCenterKey)) {
            newIgnoreMappings.set(ignoreProfitCenterKey, new Map());
          }

          newIgnoreMappings.get(ignoreProfitCenterKey)?.set(name, {
            ignoreCostCenter: false,
            ignoreProfitCenter: false,
            ...newIgnoreMappings.get(ignoreProfitCenterKey)?.get(name),
            ignoreJobIds: checked && unavailableJobs.size === 0,
          });

          let allJobs = iterableToArray(costCenterObject?.availableJobs);

          if (costCenter === '') {
            allJobs = iterableToArray(
              availableProfitCenters.get(selectedProfitCenterName)?.availableJobs,
            );
          }

          if (profitCenterName === '') {
            allJobs = iterableToArray(availableProfitCenters.values()).flatMap(
              ({ availableJobs: jobs }) => iterableToArray(jobs),
            );
          }

          updateCheckedJobs(checkedJobs, checked, ...allJobs);
        });

        handleChange(newIgnoreMappings, checkedJobs);
      },
      [
        availableProfitCenters,
        checkedJobs,
        costCenterMap,
        handleChange,
        ignoreMappings,
        selectedProfitCenterName,
      ],
    );

    const handleBulkCheckboxCheck = useCallback(
      (...items: (Job | CostCenter | ProfitCenter)[]) =>
        (event: ChangeEvent<HTMLInputElement>) => {
          let currentIgnoreMappings = new Map(ignoreMappings);
          let currentCheckedJobs = new Map(checkedJobs);
          items.forEach((item) => {
            handleCheck(
              item,
              event,
              currentIgnoreMappings,
              currentCheckedJobs,
              (newIgnoreMappings, newCheckedJobs) => {
                currentIgnoreMappings = newIgnoreMappings;
                currentCheckedJobs = newCheckedJobs;
              },
            );
          });

          handleChange(currentIgnoreMappings, currentCheckedJobs);
        },
      [checkedJobs, handleChange, handleCheck, ignoreMappings],
    );

    return {
      ...modifiedData,
      ...costCenterMappingState,
      checkedJobs,
      handleBulkCheckboxCheck,
      handleCheck,
      handleCostCenterIgnore,
      handleJobsIgnore,
      handleProfitCenterIgnore,
      ignoreMappings,
    };
  };
