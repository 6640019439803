import type { CellEditingStoppedEvent, CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useRef } from 'react';

import type { APIParams, dice } from '@org/query';
import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';
import { getCellClassesForGroups } from '@org/utils';

import { useColumns } from './useColumns';

export type ControllerProps = APIParams<'masterConfigurationId' | 'yearId'>;

const defaultColDef = {
  cellClass: ({ node: { level } }) => (level === 0 ? 'bg-highlighted font-bold' : 'text-right'),
  checkboxSelection: false,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
} satisfies ColDef;

export const useController = (apiParams: ControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);
  const { t } = useTranslation();

  const {
    accountRanges,
    updateAccountRange,
    createAccountRange,
    deleteAccountRange,
    enableUseAccountPlanAccounts,
    disableUseAccountPlanAccounts,
    isLoading,
  } = aggregated.useAccountRanges(apiParams);

  const rowData = useMemo(
    () =>
      [
        ...(accountRanges?.expenseRange ?? []),
        ...(accountRanges?.revenueRange ?? []),
        ...(accountRanges?.ignoredRange ?? []),
      ].map((range) => ({ ...range, total: range.accounts?.length })),
    [accountRanges],
  );

  const { onGridReady } = useAgGridData({
    agGridRef: tableRef,
    data: rowData,
  });

  const handleAddNewRow = useCallback(
    async (type: dice.RangeDTO['type']) => {
      const emptyRow = {
        accounts: [],
        includedFrom: '',
        includedTo: '',
        type,
      };

      await createAccountRange(emptyRow);
    },
    [createAccountRange],
  );

  const handleAddGroup = useCallback(
    (type: dice.RangeDTO['type']) => () => {
      handleAddNewRow(type);
    },
    [handleAddNewRow],
  );

  const handleRemove = async (data: dice.RangeDTO) => {
    await deleteAccountRange(data.id!);
    tableRef.current?.api.applyTransaction({ remove: [data] });
  };

  const handleOnChange = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      data: eventData,
    } = event;

    if (!colName) {
      return;
    }

    const { id, includedFrom, includedTo } = eventData;

    try {
      if (!id) {
        await createAccountRange(eventData);
      } else if (includedFrom && includedTo) {
        await updateAccountRange(id, eventData);
      }
    } catch (error) {
      const castedError = error as dice.EsraException;

      if (castedError.errorType === 'RANGE_CONFIG_OVERLAPPING') {
        return showNotification('error', t('main:manageMenu.accountRange.error.rangeOverlapping'));
      }

      if (castedError.message) {
        return showNotification('error', castedError?.message);
      }
    }
  });

  const { modal, openModal } = useDeleteRowConfirmModal<dice.RangeDTO>({
    onConfirm: handleRemove,
  });

  const columnDefs = useColumns({
    handleAddNewRow,
    openDeleteModal: openModal,
  });

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererParams: {
        suppressCount: true,
      },
      checkboxSelection: false,
      colId: 'includedFrom',
      editable: ({ node }) => !node.group,
      field: 'includedFrom',
      flex: 3,
      headerName: t('common:from'),
      rowDrag: false,
    }),
    [t],
  );

  const detailCellRendererParams = useMemo(
    () => ({
      apiParams,
    }),
    [apiParams],
  );

  const onCellEditingStopped = useCallback(
    ({ node, value, api, data }: CellEditingStoppedEvent) => {
      if (!value && !data.id) {
        api.applyTransaction({ remove: [node] });
      }
    },
    [],
  );

  const handleOnAccountPlanAccountsChange = useCallback(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      if (checked) {
        await enableUseAccountPlanAccounts();
      } else {
        await disableUseAccountPlanAccounts();
      }
    },
    [disableUseAccountPlanAccounts, enableUseAccountPlanAccounts],
  );

  return {
    autoGroupColumnDef,
    columnDefs,
    defaultColDef,
    detailCellRendererParams,
    handleAddGroup,
    handleOnChange,
    isExpenseAccountRangeGroupMissing: accountRanges?.expenseRange?.length === 0,
    isIgnoreAccountRangeGroupMissing: accountRanges?.ignoredRange?.length === 0,
    isRevenueAccountRangeGroupMissing: accountRanges?.revenueRange?.length === 0,
    missingAccounts: accountRanges?.missingAccounts ?? [],
    useAccountPlanAccounts: accountRanges?.useAccountPlanAccounts ?? false,
    handleOnAccountPlanAccountsChange,
    modal,
    onCellEditingStopped,
    onGridReady,
    tableRef,
    isToggleDisabled: isLoading,
  };
};

export type ControllerType = ReturnType<typeof useController>;
