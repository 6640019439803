import { Button, Modal, ModalBody, ModalFooter } from 'appkit-react';
import PropTypes from 'prop-types';

import { useTranslation } from '@org/locales';

import { useUIContext } from '../../context/UIState';
import { displayAsyncContent } from '../../utils/spinner';

function ModalWrapper({
  children,
  visible = false,
  handleClose,
  handleSave,
  saveActionName,
  loadingLabelKey,
  disabled,
}) {
  const { isLoading } = useUIContext();
  const { t } = useTranslation();

  return (
    <Modal
      onCancel={handleClose}
      style={{ width: '60rem' }}
      visible={visible}
    >
      <ModalBody style={{ padding: '1.25rem' }}>
        {displayAsyncContent(children, isLoading)}
        {isLoading && (
          <span className="d-flex justify-content-center">
            {loadingLabelKey ? t(loadingLabelKey) : t('common:loadingPleaseWait')}
          </span>
        )}
      </ModalBody>

      <ModalFooter
        className={`w-100 d-flex a-mb-20 ${
          handleSave ? 'justify-content-between' : 'justify-content-end'
        }`}
      >
        <>
          {handleSave && (
            <Button
              className="m-0"
              data-test="modal-save"
              disabled={disabled !== undefined ? disabled : isLoading}
              kind="primary"
              onClick={handleSave}
              size="lg"
            >
              {saveActionName || t('common:save')}
            </Button>
          )}
          {!isLoading && (
            <Button
              className="m-0"
              data-test="modal-close"
              kind="secondary"
              onClick={handleClose}
              size="lg"
            >
              {t('common:btnClose')}
            </Button>
          )}
        </>
      </ModalFooter>
    </Modal>
  );
}

ModalWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  loadingLabelKey: PropTypes.string,
  saveActionName: PropTypes.string,
  visible: PropTypes.bool,
};

export default ModalWrapper;
