import type { GridApi } from 'ag-grid-community';

import type { griddy } from '@org/query';
import { getCopyOfTableData } from '@org/utils';

import type { CostCenterMappings } from '../types';

export const getItemsInCostType = (
  api: GridApi | undefined,
  category: griddy.CostCenter['costCenterType'],
) => getCopyOfTableData<CostCenterMappings>(api).filter((cc) => cc.costCenterType === category);
