import type { IAggFunc } from 'ag-grid-community';

import type { griddy, rave } from '@org/query';
import { t } from '@org/locales';
import { resolvePathInObj } from '@org/utils';

import type { BabReportEntry } from '../types';
import { ACCOUNT_TYPES } from '../types';
import { calcGroupedColumnSum } from './calcGroupedColumnSum';

const FIXED_COLUMNS = new Set([
  'expenses_revenues_before_correction',
  'correction',
  'expenses_revenues_after_correction',
]);

export const babRowAggregate =
  (
    totalCostsAfterAllocation: BabReportEntry | undefined,
    totalRevenueAfterAllocation: BabReportEntry | undefined,
    ctaExpenseEntries: griddy.BabReport['ctaExpenseEntries'] | undefined,
    ctaRevenueEntries: griddy.BabReport['ctaRevenueEntries'] | undefined,
    imputedWicapEntries: Record<string, rave.BabImputedWicapEntry> | undefined,
  ): IAggFunc =>
  (params) => {
    const colName = params?.colDef?.field;
    // rowName of an aggregated row
    const rowName = params?.rowNode?.key;
    const parentRowName = params?.rowNode?.parent?.key;

    if (rowName === ACCOUNT_TYPES.EXPENSE) {
      // for collapsed (grouped) columns
      if (params?.colDef?.columnGroupShow === 'closed') {
        return calcGroupedColumnSum(params.column, totalCostsAfterAllocation);
      }
      if (colName) {
        return totalCostsAfterAllocation?.[colName as keyof BabReportEntry];
      }
    }

    if (rowName === ACCOUNT_TYPES.REVENUE) {
      // for collapsed (grouped) columns
      if (params?.colDef?.columnGroupShow === 'closed') {
        return calcGroupedColumnSum(params.column, totalRevenueAfterAllocation);
      }
      if (colName) {
        return totalRevenueAfterAllocation?.[colName as keyof BabReportEntry];
      }
    }

    if (
      rowName === t('main:reportPanels.babReport.tableRows.IMPUTED_WITHDRAWAL_CAPITAL') &&
      colName
    ) {
      return (
        imputedWicapEntries?.IMPUTED_WITHDRAWAL_CAPITAL?.[
          colName as keyof rave.BabImputedWicapEntry
        ] ??
        imputedWicapEntries?.IMPUTED_WITHDRAWAL_CAPITAL?.costCenterValueMap?.[colName] ??
        0
      );
    }

    /* if rowName is a costTypeAccount and its parent key is EXPENSE, then get the corresponding
     cell value from ctaExpenseEntries  */
    if (
      rowName &&
      ctaExpenseEntries &&
      Object.keys(ctaExpenseEntries).includes(rowName) &&
      parentRowName === ACCOUNT_TYPES.EXPENSE &&
      colName
    ) {
      // if column name is one of FIXED_COLUMNS get the value from ctaExpenseEntries, else
      // get the value from the sub object costCenterValueMap
      if (FIXED_COLUMNS.has(colName)) {
        return resolvePathInObj([rowName, colName], ctaExpenseEntries);
      }
      return resolvePathInObj([rowName, 'costCenterValueMap', colName], ctaExpenseEntries);
    }

    /* if rowName is a costTypeAccount and its parent key is REVENUE, then get the corresponding
     cell value from ctaRevenueEntries  */
    if (
      rowName &&
      ctaRevenueEntries &&
      Object.keys(ctaRevenueEntries).includes(rowName) &&
      parentRowName === ACCOUNT_TYPES.REVENUE &&
      colName
    ) {
      // if column name is one of FIXED_COLUMNS get the value from ctaExpenseEntries, else
      // get the value from the sub object costCenterValueMap
      if (FIXED_COLUMNS.has(colName)) {
        return resolvePathInObj([rowName, colName], ctaRevenueEntries);
      }
      return resolvePathInObj([rowName, 'costCenterValueMap', colName], ctaRevenueEntries);
    }
    // for allocation rows, aggregate in safe, TODO: move this to rave as well
    return params.values.reduce((acc: number, val) => (val === undefined ? acc : acc + +val), 0);
  };
