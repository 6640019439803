import type { InitialGroupOrderComparatorParams, IRowNode } from 'ag-grid-community';

const getLowestIdFromNode = (row: IRowNode<{ rowIndex: number }>) => {
  if (row.childrenAfterGroup) {
    let lowestVal: number | undefined = undefined;
    row.childrenAfterGroup.forEach((child) => {
      const lowestId = getLowestIdFromNode(child);
      if (lowestVal === undefined || lowestId < lowestVal) {
        lowestVal = lowestId;
      }
    });
    return lowestVal ?? 0;
  }
  return row.data?.rowIndex ?? 0;
};

export const initialGroupOrderComparator = ({
  nodeA,
  nodeB,
}: InitialGroupOrderComparatorParams) => {
  const a = getLowestIdFromNode(nodeA);
  const b = getLowestIdFromNode(nodeB);

  return a - b;
};
