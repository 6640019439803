import { createMuiTheme as createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const MUI_TABLE_THEME = createTheme({
  palette: {
    primary: {
      main: '#2d2d2d',
    },
    secondary: {
      main: '#d04a02',
    },
  },
});

export const StyledTable = styled.div`
  width: 100%;

  // style of the table top panel (with title, search etc)
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    margin: 0;
    padding: 5px;

    .MuiTypography-root {
      font-size: var(--font-size-primary);
      margin-bottom: 10px;
    }
  }

  .table-top-action-buttons {
    margin: 5px;
  }

  // style of add button
  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    margin: -13px 0;
    padding: 5px;
  }

  // adjust styles of table header
  .MuiTableCell-root.MuiTableCell-head {
    height: 15px;
    line-height: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  // layout of cells and action buttons
  .MuiIconButton-root,
  .MuiTableCell-root {
    padding: 3px;
  }

  // cells and edit cells style
  tr.MuiTableRow-root,
  .MuiTableCell-root,
  .MuiInputBase-input.MuiInput-input {
    font-size: var(--font-size-primary);
    line-height: 14px;
  }

  // Deletion hint style
  .MuiTypography-root {
    font-size: var(--font-size-primary);
    display: flex;
    justify-content: center;
  }

  .MuiPaper-root {
    box-shadow: unset;
    border: none;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #eb8c00;
  }

  .MuiCircularProgress-colorPrimary {
    color: #d04a02;
  }

  .secondary-input-container > * {
    padding: 5px;
  }

  .a-tag {
    margin: 0 5px 5px 0;
    font-size: var(--font-size-primary);
    height: 18px;
    padding: 10px;
  }
`;

export const textCutStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
