/* eslint-disable jsx-a11y/no-autofocus */
import type { ICellEditorParams } from 'ag-grid-community';
import type { FC } from 'react';

import { NumberInput } from '../NumberInput';

export interface NumericCellFieldProps extends ICellEditorParams {
  onValueChange: (value: unknown) => void;
  defaultValue?: number;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
}

export const NumericCellField: FC<NumericCellFieldProps> = (props) => {
  const { value, defaultValue, onValueChange, decimalScale, fixedDecimalScale } = props;
  const internalValue = value ?? defaultValue;

  return (
    <NumberInput
      autoFocus
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      onChange={(newValue) => onValueChange(newValue)}
      value={internalValue}
    />
  );
};
