import { useMemo } from 'react';

import PropTypes from 'prop-types';

import type { ConfigMenuItem } from '@org/models';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { get, set } from '@org/utils/object';

import { CONFIG_ITEM_GROUPS, CONFIG_ITEMS } from '../Utils/configMenuItems';
import { ConfigItemGroup } from './ConfigItemGroup';
import { ConfigMenuItem as ConfigMenuItemComp } from './ConfigMenuItem';

const ConfigMenu = ({
  selectedConfigId,
  configItem,
  setConfigItem,
}: {
  selectedConfigId: string;
  configItem: string;
  setConfigItem: (val: string) => void;
}) => {
  const { masterConfiguration: selectedConfig } = aggregated.useMasterConfiguration({
    masterConfigurationId: selectedConfigId,
  });

  const { t } = useTranslation();
  const availableConfigItemsByGroup = useMemo(
    () =>
      (CONFIG_ITEMS as unknown as ConfigMenuItem[]).reduce((prev, curr) => {
        if (!curr.group || curr.inputSectionConfig) {
          return prev;
        }

        if (!get(prev, curr.group.label)) {
          set(prev, curr.group.label, [curr]);
        } else {
          get(prev, curr.group.label).push(curr);
        }

        return prev;
      }, {}),
    [],
  );

  return (
    <>
      {selectedConfig &&
        Object.values(CONFIG_ITEM_GROUPS).map((configItemGroup, idx) => (
          <ConfigItemGroup
            defaultExpanded={configItemGroup.defaultExpanded}
            groupLabel={t(configItemGroup.labelKey)}
            key={idx}
          >
            {get(availableConfigItemsByGroup, configItemGroup.label)?.map(
              (item: ConfigMenuItem, secondIdx: number) => (
                <ConfigMenuItemComp
                  configItem={configItem}
                  configMenuItem={item}
                  key={secondIdx}
                  setConfigItem={setConfigItem}
                />
              ),
            )}
          </ConfigItemGroup>
        ))}
    </>
  );
};

ConfigMenu.propTypes = {
  configItem: PropTypes.string,
  selectedConfigId: PropTypes.string,
  setConfigItem: PropTypes.func.isRequired,
};

export default ConfigMenu;
