import type { ReportsResponse } from '../../rave';

interface GenerateCostUnitReportProps {
  reportObject: ReportsResponse;
}

interface CostUnitReportRow {
  group?: string;
  subGroup?: string;
  subSubGroup?: string;
  rowName?: string;
  visible?: boolean;
  // Other costUnits
  [other: string]: unknown;
}

export const generateCostUnitReport = ({
  reportObject,
}: GenerateCostUnitReportProps): CostUnitReportRow[] => {
  const costs =
    reportObject.costUnitReport?.costCoverageRequirement?.subTotal?.costs?.entries ?? [];
  const revenues =
    reportObject.costUnitReport?.costCoverageRequirement?.subTotal?.revenues?.entries ?? [];
  const allocationPreviousYears =
    reportObject.costUnitReport?.costCoverageRequirement?.allocationPreviousYears?.entries ?? [];
  const feeIncome = reportObject.costUnitReport?.feeIncome?.entries ?? [];
  const overUnderFunding = reportObject.costUnitReport?.overUnderFunding;
  const feeRate = reportObject.costUnitReport?.feeRate;
  const costCoverageRequirementQuantityFee =
    reportObject.costUnitReport?.costCoverageRequirementQuantityFee;
  const assessmentBase = reportObject.costUnitReport?.assessmentBase;

  // reportObject.costUnitReport?.
  return [
    ...costs.map(({ costUnitValueMap, rowName, ...other }) => ({
      group: 'costCoverage',
      subGroup: 'subTotal',
      subSubGroup: 'costs',
      rowName,
      ...other,
      ...costUnitValueMap,
    })),
    ...revenues.map(({ rowName, costUnitValueMap, ...other }) => ({
      group: 'costCoverage',
      subGroup: 'subTotal',
      subSubGroup: 'revenues',
      rowName,
      ...other,
      ...costUnitValueMap,
    })),
    ...allocationPreviousYears.map(({ costUnitValueMap, rowName, ...other }) => ({
      group: 'costCoverage',
      subGroup: 'allocationPreviousYears',
      subSubGroup: undefined,
      rowName,
      ...other,
      ...costUnitValueMap,
    })),
    ...feeIncome.map(({ rowName, costUnitValueMap, ...other }) => ({
      group: 'FEE_INCOME',
      subGroup: undefined,
      subSubGroup: undefined,
      rowName,
      ...other,
      ...costUnitValueMap,
    })),
    {
      group: undefined,
      subGroup: undefined,
      subSubGroup: undefined,
      rowName: overUnderFunding?.rowName,
      visible: overUnderFunding?.visible,
      total: overUnderFunding?.total,
      ...overUnderFunding?.costUnitValueMap,
    },
    {
      group: undefined,
      subGroup: undefined,
      subSubGroup: undefined,
      rowName: costCoverageRequirementQuantityFee?.rowName,
      visible: costCoverageRequirementQuantityFee?.visible,
      total: costCoverageRequirementQuantityFee?.total,
      ...costCoverageRequirementQuantityFee?.costUnitValueMap,
    },
    {
      group: undefined,
      subGroup: undefined,
      subSubGroup: undefined,
      fakeGroup: true,
      disableBold: true,
      disableHighlight: true,
      rowName: assessmentBase?.rowName,
      visible: assessmentBase?.visible,
      total: assessmentBase?.total,
      ...assessmentBase?.costUnitValueMap,
    },
    {
      group: undefined,
      subGroup: undefined,
      subSubGroup: undefined,
      rowName: feeRate?.rowName,
      visible: feeRate?.visible,
      total: feeRate?.total,
      ...feeRate?.costUnitValueMap,
    },
  ].map((row, rowIndex) => ({ ...row, rowIndex }));
};
