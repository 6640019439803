import type { dice } from '../../../../query/src';

export type SetWorkingCapitalEnabledInMasterConfiguration = (
  masterConfiguration: dice.MasterConfiguration,
  workingCapitalEnabled: boolean,
) => dice.MasterConfiguration;

export const setWorkingCapitalEnabledInMasterConfiguration: SetWorkingCapitalEnabledInMasterConfiguration =
  (masterConfiguration, workingCapitalEnabled) => ({
    ...masterConfiguration,
    imputedWorkingCapitalConfig: {
      ...masterConfiguration.imputedWorkingCapitalConfig,
      workingCapitalEnabled,
    },
  });
