import type { AsyncTransactionsFlushed } from 'ag-grid-community';

import { uniqBy } from 'lodash';

import type { dice, erase, griddy } from '@org/query';

interface OnReportModalAsyncTransactionsFlushedType {
  recalculate: (newConfig?: dice.MasterConfiguration) => Promise<void>;
  executeCloseModal: () => void;
  saveTransactionModificationBulks: (
    transactions: dice.AccountTransaction[],
    updatedData: Partial<dice.TransactionModification>[],
  ) => dice.MasterConfiguration | undefined;
  updateConfiguration: (newConfig: dice.MasterConfiguration) => Promise<unknown>;
}

interface TableData {
  data: dice.AccountTransaction[] | erase.Asset[];
  _updatedData: Partial<dice.TransactionModification>[];
}

export const onReportModalAsyncTransactionsFlushed =
  ({
    recalculate,
    executeCloseModal,
    saveTransactionModificationBulks,
    updateConfiguration,
  }: OnReportModalAsyncTransactionsFlushedType) =>
  async (e: AsyncTransactionsFlushed) => {
    const savedAsyncTransactions = e.results
      .flatMap((asyncRowNodeTx) => asyncRowNodeTx.update)
      .map((rowNode) => rowNode?.data);

    // The most recent user change of a tx within data._updatedData; so we can just remove duplicates by the unique index
    const uniqueAsyncTransactions = uniqBy(savedAsyncTransactions, 'index').reduce<TableData>(
      (acc, curr) => {
        acc.data.push(curr);
        acc._updatedData.push(curr?._updatedData);
        return acc;
      },
      { _updatedData: [], data: [] },
    );

    let newConfig;
    if (saveTransactionModificationBulks) {
      newConfig = saveTransactionModificationBulks(
        uniqueAsyncTransactions.data as griddy.AccountTransaction[],
        uniqueAsyncTransactions._updatedData as Partial<griddy.TransactionModification>[],
      );
    }

    if (newConfig) {
      await updateConfiguration(newConfig);
      await recalculate(newConfig);
      executeCloseModal();
    }
  };
