import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AdminMenu } from '@org/features';

// import AdminMenu from '../AdminMenu/AdminMenu';
import BreadCrumb from '../BreadCrumb';
import HelpMenu from '../HelpMenu/HelpMenu';
import LanguageMenu from '../LanguageMenu/LanguageMenu';

const StyledHeader = styled.div`
  .logout {
    text-decoration: none;
    color: unset;
    cursor: default;
  }
  .appkiticon {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .a-brand-container {
    margin: 10px;
  }
  .a-btn:focus {
    outline: none;
  }
`;

const Header = ({ title }) => {
  const navigate = useNavigate();

  const toHome = () => {
    navigate('/');
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <StyledHeader>
      <div className="normal-header-container">
        <nav className="a-header-wrapper justify-content-between">
          <div className="a-brand-container">
            <div
              className="a-pwc-logo-grid a-md"
              onClick={toHome}
              style={{ cursor: 'pointer' }}
            />
            <div className="a-divider" />
            <span className="font-weight-medium">{title}</span>
            <div className="a-divider" />
            <span style={{ color: '#7D7D7D' }}>PwC ADI</span>
          </div>
          <div className="a-brand-container">
            <AdminMenu />
            <div className="a-divider" />
            <LanguageMenu />
            <div className="a-divider" />
            <HelpMenu />
            <div className="a-divider" />
            <a
              className="logout"
              onClick={handleLogout}
            >
              <span
                className="appkiticon icon-log-out-outline"
                title="logout"
              />
            </a>
          </div>
        </nav>
        <nav className="a-h-30 a-bg-grey-lighter a-header-wrapper align-content-center">
          <BreadCrumb />
        </nav>
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  title: propTypes.string.isRequired,
};

export default Header;
