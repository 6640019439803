import type { TooltipProps } from '@nextui-org/react';
import { forwardRef } from 'react';

import { extendVariants, Tooltip as NextTooltip } from '@nextui-org/react';

export const TooltipVariant = extendVariants(NextTooltip, {
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      default: {
        base: ['bg-[#333] rounded before:bg-[#333]'],
        content: ['bg-[#333] text-white'],
      },
    },
  },
});

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <TooltipVariant
      classNames={{ base: 'pointer-events-none' }}
      closeDelay={100}
      isDismissable
      ref={ref}
      showArrow
      {...rest}
    >
      {children}
    </TooltipVariant>
  );
});
