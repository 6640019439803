import { useCallback } from 'react';

import type { APIParams } from '../..';
import type { YearDTOV2 } from '../dice';
import { useDeleteYear, useGetYears, usePostYear, usePutYear } from '../dice';

interface UseYearsParams extends APIParams<'publicFacilityId'> {}

export const useYears = (pathParams: UseYearsParams) => {
  const { publicFacilityId } = pathParams;

  const { data: years, isPending: yearsIsPending } = useGetYears(
    {
      pathParams: {
        publicFacilityId,
      },
    },
    {
      enabled: Boolean(publicFacilityId),
    },
  );

  const { mutateAsync: createYearAsync } = usePostYear({});
  const { mutateAsync: updateYearAsync } = usePutYear({});
  const { mutateAsync: deleteYearAsync } = useDeleteYear({});

  const createYear = useCallback(
    async ({ year }: { year: string }) =>
      await createYearAsync({
        body: {
          publicFacilityId,
          year,
        },
        pathParams: {
          publicFacilityId,
        },
      }),
    [createYearAsync, publicFacilityId],
  );

  const updateYear = useCallback(
    async (id: string, data: YearDTOV2) =>
      await updateYearAsync({
        body: data,
        pathParams: {
          publicFacilityId,
          yearId: id,
        },
      }),
    [updateYearAsync, publicFacilityId],
  );

  const deleteYear = useCallback(
    async (id: string) =>
      await deleteYearAsync({
        pathParams: {
          yearId: id,
        },
      }),
    [deleteYearAsync],
  );

  return {
    createYear,
    deleteYear,
    isLoading: yearsIsPending,
    updateYear,
    years,
  };
};
