import type { FileType, InfoMappingConstCenter, InfoMappingProfitCenter } from '../types';
import { FILE_TYPE } from '../constants';

export const getApiFileType = (fileType: FileType) =>
  fileType.apiType ?? getFileTypeWithTypeOrApiType(fileType)?.apiType;

const getFileTypeWithTypeOrApiType = (typeOrApiType: FileType) => {
  for (const fileType of Object.values(FILE_TYPE)) {
    if (typeof typeOrApiType === 'string' && isFileType(typeOrApiType, fileType)) {
      return fileType;
    }
  }

  return undefined;
};

export const isFileType = (typeOrApiType = '', fileType: FileType) =>
  typeOrApiType.toUpperCase() === fileType.type.toUpperCase() ||
  typeOrApiType.toUpperCase() === fileType.apiType.toUpperCase();

interface InitValues {
  costCenterIds: string[];
  jobIds: string[];
  profitCenterIds: string[];
}

const initValues: InitValues = {
  costCenterIds: [],
  jobIds: [],
  profitCenterIds: [],
};

export const mapCCtoProfitInfoMapping = (profitCenters: InfoMappingProfitCenter[]) =>
  profitCenters.reduce((acc: InitValues, pc: InfoMappingProfitCenter) => {
    acc.profitCenterIds = [...acc.profitCenterIds, pc.profitCenterId];

    pc.costCenters.forEach((cc: InfoMappingConstCenter) => {
      acc.costCenterIds = [...acc.costCenterIds, cc.costCenterId];
      acc.jobIds = [...acc.jobIds, ...cc.jobIds];
    });

    return acc;
  }, initValues);
