import type { FC } from 'react';

import classnames from 'classnames';

import { Svg } from '../Svg';
import styles from './LoaderWrapper.module.css';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type LoaderWrapperProps = {
  loading?: boolean;
} & OuterProps;

export const LoaderWrapper: FC<LoaderWrapperProps> = (props) => {
  const { className, children, loading, ...otherProps } = props;
  return (
    <div
      className={classnames(styles.LoaderWrapper, className, 'relative mr-8 h-full w-full flex-1', {
        'overflow-hidden': loading,
      })}
      data-testid="LoaderWrapper"
      {...otherProps}
    >
      {loading ? (
        <>
          <div className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 text-9xl">
            <Svg
              data-testid="spinner"
              name="Spinner"
            />
          </div>
          <div className="absolute left-0 top-0 z-10 h-full w-full bg-white opacity-90" />
        </>
      ) : (
        children
      )}
    </div>
  );
};
