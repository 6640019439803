import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { getCellClassesForGroups, getCellClassesForGroupsWithActions } from '@org/utils';

interface UseColumnsProps {
  openDeleteModal: (data: dice.RangeDTO) => Promise<void> | void;
  handleAddNewRow: (type: dice.RangeDTO['type']) => Promise<void> | void;
}

export const useColumns = ({
  openDeleteModal,
  handleAddNewRow,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          colId: 'type',
          field: 'type',
          hide: true,
          rowGroup: true,
          valueFormatter: ({ value }) => t(`main:manageMenu.accountRange.types.${value}.groupName`),
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'includedTo',
          editable: ({ node }) => node.level === 1,
          field: 'includedTo',
          flex: 3,
          headerName: t('common:to'),
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'total',
          editable: false,
          // @ts-expect-error missing cols
          field: 'total',
          flex: 1,
          headerName: t('common:total'),
        },
        {
          cellClass: getCellClassesForGroupsWithActions,
          cellRendererSelector: (params) => {
            const { node, data } = params;

            if (node?.level === 0) {
              return {
                component: 'multipleButtons',
                params: {
                  buttonParams: [
                    {
                      icon: 'icon-plus-outline',
                      onClick: () => handleAddNewRow(node.key as dice.RangeDTO['type']),
                      size: 'iconButton',
                    },
                  ],
                  className: 'flex',
                },
              };
            }
            return {
              component: 'multipleButtons',
              params: {
                buttonParams: [
                  {
                    icon: 'icon-delete-outline',
                    onClick: () => openDeleteModal(data!),
                    size: 'iconButton',
                  },
                ],
                className: 'flex',
              },
            };
          },
          colId: 'actions',
          editable: false,
          filter: null,
          headerName: t('common:actions'),
          suppressHeaderMenuButton: true,
        },
      ] satisfies (ColDef<dice.RangeDTO> | ColGroupDef<dice.RangeDTO>)[],
    [handleAddNewRow, openDeleteModal, t],
  );
};
