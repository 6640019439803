import { useCallback } from 'react';

import type { APIParams } from '../..';
import type {
  CreatePriceSubGroupEntryPathParams,
  CreatePriceSubGroupPathParams,
  DeletePriceGroupPathParams,
  DeletePriceSubGroupEntriesPathParams,
  DeletePriceSubGroupPathParams,
  MovePriceSheetSubgroupDTO,
  PatchPriceSheetGroupDTO,
  PatchPriceSheetSubGroupDTO,
  PostPriceSheetGroupDTO,
  PostPriceSheetSubGroupDTO,
  PriceSheetGroupDTO,
  PriceSheetSubGroupEntryDTO,
} from '../dice';
import {
  useCreatePriceGroup,
  useCreatePriceSubGroup,
  useCreatePriceSubGroupEntry,
  useDeletePriceGroup,
  useDeletePriceSubGroup,
  useDeletePriceSubGroupEntries,
  useGetPriceSheetConfig,
  useMovePriceSubGroup,
  useUpdatePriceGroup,
  useUpdatePriceSubGroup,
  useUpdatePriceSubGroupEntries,
} from '../dice';

interface UsePriceSheetParams extends APIParams<'masterConfigurationId' | 'yearId'> {}

export interface PriceSheetRow {
  costUnit?: string;
  currency?: string;
  factor1?: string;
  factor2?: string;
  factor3?: string;
  factor4?: string;
  groupId?: string;
  groupName?: string;
  groupRowIndex?: number;
  subGroupId?: string;
  subGroupName?: string;
  subGroupRowIndex?: number;
  entryId?: string;
  entryName?: string;
  source?: 'BASIC_FEE' | 'PRICE_SHEET';
}

export const flattenPriceSheetGroups = (
  priceSheetGroups: PriceSheetGroupDTO[],
): PriceSheetRow[] => {
  if (!Array.isArray(priceSheetGroups)) {
    return [];
  }

  return priceSheetGroups.flatMap((priceSheet) => {
    const { id: groupId, name: groupName, rowIndex: groupRowIndex, priceSubGroups } = priceSheet;
    return (
      priceSubGroups?.flatMap(
        ({
          id: subGroupId,
          name: subGroupName,
          currency,
          priceSubGroupEntries,
          rowIndex: subGroupRowIndex,
        }) =>
          priceSubGroupEntries?.reduce(
            (acc: PriceSheetRow[], { id: entryId, name: entryName, ...rest }) => {
              acc.push({
                ...rest,
                currency,
                entryId,
                entryName,
                groupId,
                groupName,
                groupRowIndex,
                subGroupId,
                subGroupName,
                subGroupRowIndex,
              });

              return acc;
            },
            [],
          ) ?? [],
      ) ?? []
    );
  });
};

export const usePriceSheet = ({
  masterConfigurationId: masterConfigId,
  yearId,
}: UsePriceSheetParams) => {
  const { mutateAsync: addGroup, isPending: addGroupIsLoading } = useCreatePriceGroup({});
  const { mutateAsync: addSubGroup, isPending: addSubGroupIsLoading } = useCreatePriceSubGroup({});
  const { mutateAsync: addEntry, isPending: addEntryIsLoading } = useCreatePriceSubGroupEntry({});
  const { mutateAsync: updateGroup, isPending: updateGroupIsLoading } = useUpdatePriceGroup({});
  const { mutateAsync: moveSubGroup, isPending: moveSubGroupIsLoading } = useMovePriceSubGroup({});
  const { mutateAsync: updateSubGroup, isPending: updateSubGroupIsLoading } =
    useUpdatePriceSubGroup({});
  const { mutateAsync: updateEntry, isPending: updateEntryIsLoading } =
    useUpdatePriceSubGroupEntries({});
  const { mutateAsync: deleteGroup, isPending: deleteGroupIsLoading } = useDeletePriceGroup({});
  const { mutateAsync: deleteSubGroup, isPending: deleteSubGroupIsLoading } =
    useDeletePriceSubGroup({});
  const { mutateAsync: deleteEntry, isPending: deleteEntryIsLoading } =
    useDeletePriceSubGroupEntries({});

  const { data: priceSheetGroups } = useGetPriceSheetConfig(
    {
      pathParams: {
        masterConfigId,
        yearId,
      },
    },
    {
      select: flattenPriceSheetGroups,
    },
  );

  const addPriceSheetGroup = useCallback(
    async (data: PostPriceSheetGroupDTO) =>
      await addGroup({
        body: {
          masterConfigId,
          yearId,
          ...data,
        },
      }),
    [addGroup, masterConfigId, yearId],
  );

  const addPriceSheetSubGroup = useCallback(
    async (
      groupId: CreatePriceSubGroupPathParams['priceGroupId'],
      data: PostPriceSheetSubGroupDTO,
    ) =>
      await addSubGroup({
        body: data,
        pathParams: {
          priceGroupId: groupId,
        },
      }),
    [addSubGroup],
  );

  const addPriceSheetEntry = useCallback(
    async (
      subGroupId: CreatePriceSubGroupEntryPathParams['priceSubGroupId'],
      data: PriceSheetSubGroupEntryDTO,
    ) =>
      await addEntry({
        body: data,
        pathParams: {
          priceSubGroupId: subGroupId,
        },
      }),
    [addEntry],
  );

  const updatePriceSheetGroup = useCallback(
    async (items: PatchPriceSheetGroupDTO[]) =>
      await updateGroup({
        body: items,
      }),
    [updateGroup],
  );

  const updatePriceSheetSubGroup = useCallback(
    async (items: PatchPriceSheetSubGroupDTO[]) =>
      await updateSubGroup({
        body: items,
      }),
    [updateSubGroup],
  );

  const updatePriceSheetEntry = useCallback(
    async (items: PriceSheetSubGroupEntryDTO[]) =>
      await updateEntry({
        body: items,
      }),
    [updateEntry],
  );

  const movePriceSheetSubGroup = useCallback(
    async (
      targetGroupId: MovePriceSheetSubgroupDTO['targetGroupId'],
      subgroupId: MovePriceSheetSubgroupDTO['subgroupId'],
    ) =>
      await moveSubGroup({
        body: {
          subgroupId,
          targetGroupId,
        },
      }),
    [moveSubGroup],
  );

  const deletePriceSheetGroup = useCallback(
    async (groupId: DeletePriceGroupPathParams['priceGroupId']) =>
      await deleteGroup({
        pathParams: {
          priceGroupId: groupId,
        },
      }),
    [deleteGroup],
  );

  const deletePriceSheetSubGroup = useCallback(
    async (subGroupId: DeletePriceSubGroupPathParams['priceSubGroupId']) =>
      await deleteSubGroup({
        pathParams: {
          priceSubGroupId: subGroupId,
        },
      }),
    [deleteSubGroup],
  );

  const deletePriceSheetEntry = useCallback(
    async (entryId: DeletePriceSubGroupEntriesPathParams['priceSubGroupEntryId']) =>
      await deleteEntry({
        pathParams: {
          priceSubGroupEntryId: entryId,
        },
      }),
    [deleteEntry],
  );

  const isLoading =
    addGroupIsLoading ||
    addSubGroupIsLoading ||
    addEntryIsLoading ||
    updateGroupIsLoading ||
    updateSubGroupIsLoading ||
    updateEntryIsLoading ||
    moveSubGroupIsLoading ||
    deleteGroupIsLoading ||
    deleteSubGroupIsLoading ||
    deleteEntryIsLoading;

  return {
    addPriceSheetEntry,
    addPriceSheetGroup,
    addPriceSheetSubGroup,
    deletePriceSheetEntry,
    deletePriceSheetGroup,
    deletePriceSheetSubGroup,
    isLoading,
    movePriceSheetSubGroup,
    priceSheetGroups,
    updatePriceSheetEntry,
    updatePriceSheetGroup,
    updatePriceSheetSubGroup,
  };
};
