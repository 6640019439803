import { Fragment } from 'react';

import { Tag } from 'appkit-react';

import { objDelete } from '../../../../utils/object';
import { WithdrawAllocationInputGroup } from '../WithdrawAllocationInputGroup';

export const ManualAllocationRow = ({
  costCenterMapping,
  costCenter,
  allocationMap,
  updateCostCenterMap,
}) => (
  <div className="w-100">
    <WithdrawAllocationInputGroup
      costCenters={costCenterMapping.map((element) => element.shortName)}
      onAdd={(val) => updateCostCenterMap(costCenter, val)}
      value={allocationMap}
    />
    <div className="d-flex">
      {allocationMap &&
        Object.entries(allocationMap)
          .sort()
          .map(([key, val], index) => (
            <Fragment key={index}>
              <Tag
                className="a-tag-da6"
                closeable
                key={key}
                onClose={() => {
                  const newAllocations = allocationMap;
                  objDelete(newAllocations, key);
                  updateCostCenterMap(costCenter, newAllocations);
                }}
                size="sm"
              >
                {' '}
                {`${key} : ${val}%`}
              </Tag>
            </Fragment>
          ))}
    </div>
  </div>
);
