import _ from 'lodash';

import { t } from '@org/locales';
import { getStatusValue, WORKFLOW_STEP_STATUS } from '@org/utils';

import { AllocationKeysConfiguration } from '../../../../pages/AllocationKeysConfiguration';
import { AssetCostCenter } from '../../../../pages/AssetCostCenter';
import { AssetForecast } from '../../../../pages/AssetForecast';
import { BabChangelog } from '../../../../pages/BabChangelog';
import { CorporateRisk } from '../../../../pages/CorporateRisk';
import { CostCenterConfigPage } from '../../../../pages/CostCenterMappingPage';
import { CostTypeAccounts } from '../../../../pages/CostTypeAccounts';
import { CostUnitAllocation } from '../../../../pages/CostUnitAllocation';
import { CostUnitConfiguration } from '../../../../pages/CostUnitConfiguration';
import { CustomLabels } from '../../../../pages/CustomLabels';
import { ExpenseRevenueAccounts } from '../../../../pages/ExpenseRevenueAccounts';
import { FutureAssets } from '../../../../pages/FutureAssets';
import { ImputedConcessionFee } from '../../../../pages/ImputedConcessionFee';
import { ImputedTradeTax } from '../../../../pages/ImputedTradeTax';
import { ImputedWithdrawalCapital } from '../../../../pages/ImputedWithdrawalCapital';
import { ImputedWorkingCapital } from '../../../../pages/ImputedWorkingCapital';
import { InterestRates } from '../../../../pages/InterestRates';
import { ManageWithdrawCapital as ManageWithdrawCapitalPageBeta } from '../../../../pages/ManageWithdrawCapital';
import { ManualCorrectionsBab } from '../../../../pages/ManualCorrectionsBab';
import { ManualCorrectionsInterestPage } from '../../../../pages/ManualCorrectionsInterest';
import { PlannedCostRevenuePage } from '../../../../pages/PlannedCostRevenuePage';
import { PriceSheet } from '../../../../pages/PriceSheet';
import { TechnicalData } from '../../../../pages/TechnicalData';
import { objGet } from '../../../../utils';
import ManageWithdrawCapital from '../../../ManageMenu/ManageWithdrawCapital/ManageWithdrawCapital';

// const PRODUCTION_ENV = 'production';
// const DEPLOYMENT_ENV = process.env.REACT_APP_DEPLOYMENT_ENV;
// // IMPORTANT!!: please remove this hack
// const IS_DEV =
//   window?.location?.host?.includes('localhost') ||
//   window?.location?.host?.includes('portal-esra-stg.np.dealstechmanagedservices.pwc.de') ||
//   window?.location?.host?.includes('portal-esra-dev-r2.np.dealstechmanagedservices.pwc.de');

const getStatusCostUnits = (config) => {
  if (config?.costUnitConfig?.length) {
    return WORKFLOW_STEP_STATUS.SUCCESS;
  }
  return WORKFLOW_STEP_STATUS.ERROR;
};

const getStatusStandard = (config, configName) => {
  if (config) {
    const obj = objGet(config, configName);
    if (Array.isArray(obj)) {
      if (obj.length > 0) {
        return WORKFLOW_STEP_STATUS.CHANGED;
      }
    } else if (obj) {
      return WORKFLOW_STEP_STATUS.CHANGED;
    }
  }
  return '';
};

const getChangedStatusByValue = (value) => (value ? WORKFLOW_STEP_STATUS.CHANGED : '');

export const CONFIG_ITEM_GROUPS = {
  BAB: {
    defaultExpanded: true,
    labelKey: 'common:enums.configItemGroups.BAB',
    label: t('common:enums.configItemGroups.BAB'),
  },
  INTEREST_REPORT: {
    defaultExpanded: true,
    labelKey: 'common:enums.configItemGroups.INTEREST_REPORT',
    label: t('common:enums.configItemGroups.INTEREST_REPORT'),
  },
  COST_UNIT_REPORT: {
    defaultExpanded: true,
    labelKey: 'common:enums.configItemGroups.COST_UNIT_REPORT',
    label: t('common:enums.configItemGroups.COST_UNIT_REPORT'),
  },
  PRICE_SHEET: {
    defaultExpanded: true,
    labelKey: 'common:enums.configItemGroups.PRICE_SHEET',
    label: t('common:enums.configItemGroups.PRICE_SHEET'),
  },
  OTHER: {
    defaultExpanded: false,
    labelKey: 'common:enums.configItemGroups.OTHER',
    label: t('common:enums.configItemGroups.OTHER'),
  },
  LOGS: {
    defaultExpanded: false,
    labelKey: 'common:enums.configItemGroups.LOGS',
    label: t('common:enums.configItemGroups.LOGS'),
  },
};

export const CONFIG_ITEMS = [
  {
    getStatus: ({ selectedConfig }) =>
      getChangedStatusByValue(selectedConfig?.assetForecastConfig?.isEnabled),
    group: CONFIG_ITEM_GROUPS.OTHER,
    value: 'assetForecast',
    view: AssetForecast,
  },
  {
    getStatus: ({ selectedConfig }) =>
      getChangedStatusByValue(selectedConfig?.futureAssetConfig?.isEnabled),
    group: CONFIG_ITEM_GROUPS.OTHER,
    value: 'futureAssetConfig',
    view: FutureAssets,
  },
  {
    getStatus: ({ configsStatus }) =>
      getStatusValue({ configsStatus, name: 'PLANNED_COST_REVENUE' }),
    group: CONFIG_ITEM_GROUPS.OTHER,
    value: 'plannedCostRevenueConfig',
    view: PlannedCostRevenuePage,
  },
  {
    getStatus: ({ configsStatus }) =>
      getStatusValue({ configsStatus, name: 'TECHNICAL_DATA_CONFIG' }),
    group: CONFIG_ITEM_GROUPS.OTHER,
    value: 'technicalData',
    view: TechnicalData,
  },
  {
    getStatus: ({ configsStatus }) =>
      getStatusValue({ configsStatus, name: 'ACCOUNT_RANGE_CONFIG' }),
    group: CONFIG_ITEM_GROUPS.BAB,
    value: 'accountMapping',
    view: ExpenseRevenueAccounts,
  },
  {
    getStatus: ({ configsStatus }) =>
      getStatusValue({ configsStatus, name: 'COST_TYPE_ACCOUNT_CONFIG' }),
    group: CONFIG_ITEM_GROUPS.BAB,
    value: 'costTypeAccounts',
    view: CostTypeAccounts,
  },
  {
    getStatus: ({ configsStatus }) => getStatusValue({ configsStatus, name: 'COST_CENTER_CONFIG' }),
    group: CONFIG_ITEM_GROUPS.BAB,
    value: 'costCenters',
    view: CostCenterConfigPage,
  },
  {
    getStatus: ({ configsStatus }) =>
      getStatusValue({ configsStatus, name: 'ALLOCATION_KEY_CONFIG' }),
    group: CONFIG_ITEM_GROUPS.BAB,
    value: 'allocationKeys',
    view: AllocationKeysConfiguration,
  },
  {
    getStatus: ({ selectedConfig }) =>
      getChangedStatusByValue(selectedConfig?.concessionFeeConfig?.concessionFeeEnabled),
    group: CONFIG_ITEM_GROUPS.BAB,
    hideCommonHeader: false,
    value: 'imputedConcessionFee',
    view: ImputedConcessionFee,
  },
  {
    getStatus: ({ selectedConfig }) => getStatusStandard(selectedConfig, 'corporateRiskConfig'),
    group: CONFIG_ITEM_GROUPS.BAB,
    value: 'corporateRisk',
    view: CorporateRisk,
  },
  {
    getStatus: ({ selectedConfig }) => getStatusStandard(selectedConfig, 'imputedTradeTaxConfig'),
    group: CONFIG_ITEM_GROUPS.BAB,
    value: 'imputedTradeTax',
    view: ImputedTradeTax,
  },
  {
    getStatus: ({ configsStatus }) => getStatusValue({ configsStatus, name: 'ASSET_CONFIG' }),
    group: CONFIG_ITEM_GROUPS.INTEREST_REPORT,
    value: 'assetCostCenters',
    view: AssetCostCenter,
  },
  {
    getStatus: ({ selectedConfig }) => getStatusStandard(selectedConfig, 'withdrawCapitalConfig'),
    group: CONFIG_ITEM_GROUPS.INTEREST_REPORT,
    value: 'withdrawCapital',
    view: ManageWithdrawCapital,
  },
  {
    getStatus: null,
    group: CONFIG_ITEM_GROUPS.INTEREST_REPORT,
    value: 'withdrawCapitalBeta',
    view: ManageWithdrawCapitalPageBeta,
    // disabled: !IS_DEV,
  },
  {
    getStatus: ({ selectedConfig }) =>
      getChangedStatusByValue(
        selectedConfig?.imputedWithdrawalCapitalConfig?.imputedWithdrawalCapitalEnabled,
      ),
    group: CONFIG_ITEM_GROUPS.INTEREST_REPORT,
    value: 'imputedWithdrawalCapital',
    view: ImputedWithdrawalCapital,
  },
  {
    getStatus: ({ selectedConfig }) =>
      getChangedStatusByValue(selectedConfig?.interestRates?.uniformInterestRate),
    group: CONFIG_ITEM_GROUPS.INTEREST_REPORT,
    value: 'interestRates',
    view: InterestRates,
  },
  {
    getStatus: ({ selectedConfig }) =>
      getChangedStatusByValue(selectedConfig?.imputedWorkingCapitalConfig?.workingCapitalEnabled),
    group: CONFIG_ITEM_GROUPS.INTEREST_REPORT,
    value: 'imputedWorkingCapital',
    view: ImputedWorkingCapital,
  },
  {
    getStatus: ({ selectedConfig }) => getStatusCostUnits(selectedConfig),
    group: CONFIG_ITEM_GROUPS.COST_UNIT_REPORT,
    value: 'costUnits',
    view: CostUnitConfiguration,
  },
  {
    getStatus: ({ configsStatus }) =>
      getStatusValue({ configsStatus, name: 'COST_UNIT_ALLOCATION_CONFIG' }),
    group: CONFIG_ITEM_GROUPS.COST_UNIT_REPORT,
    value: 'costUnitsAllocation',
    view: CostUnitAllocation,
  },
  {
    getStatus: () => 'SUCCESS',
    group: CONFIG_ITEM_GROUPS.PRICE_SHEET,
    value: 'priceSheet',
    view: PriceSheet,
  },
  {
    getStatus: ({ selectedConfig }) => getStatusStandard(selectedConfig, 'manualCorrections'),
    group: CONFIG_ITEM_GROUPS.LOGS,
    value: 'manualCorrections',
    view: ManualCorrectionsBab,
  },
  {
    getStatus: ({ selectedConfig }) =>
      getStatusStandard(selectedConfig, 'manualCorrectionsInterest'),
    group: CONFIG_ITEM_GROUPS.LOGS,
    value: 'manualCorrectionsInterest',
    view: ManualCorrectionsInterestPage,
  },
  {
    getStatus: ({ selectedConfig }) =>
      getStatusStandard(selectedConfig, 'transactionModifications'),
    group: CONFIG_ITEM_GROUPS.LOGS,
    value: 'babModifications',
    view: BabChangelog,
  },
  {
    getStatus: ({ selectedConfig }) => getStatusStandard(selectedConfig, 'customLabels'),
    group: CONFIG_ITEM_GROUPS.LOGS,
    value: 'customLabels',
    view: CustomLabels,
  },
];
