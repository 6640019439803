import type { ICellRendererParams, IRowNode } from 'ag-grid-community';

import type { erase } from '@org/query';
import { useTranslation } from '@org/locales';
import { CheckboxCellRenderer } from '@org/ui';
import { isAllEqualInArray } from '@org/utils';

// returns true / false for indeterminate checkbox state of group rows
const isOperatingIndeterminate = (node: IRowNode): boolean => {
  const childValues = node?.childrenAfterGroup?.map(
    (childNode) => childNode.data?.currentIsOperatingAsset,
  );
  return childValues ? !isAllEqualInArray(childValues) : false;
};

interface OperatingAssetRendererProps extends ICellRendererParams {
  yearId: string;
  assetCategoryConfigs: erase.AssetCategoryConfigDTO[];
  updateAssetConfig: (categoryConfigs: erase.AssetCategoryConfigDTO[]) => Promise<void>;
  updateAssets: (updatedRows: erase.Asset[]) => Promise<void>;
}

export const OperatingAssetRenderer = (props: OperatingAssetRendererProps) => {
  const { t } = useTranslation();

  const { value, node, assetCategoryConfigs, updateAssetConfig, updateAssets } = props;

  const indeterminate = isOperatingIndeterminate(node);

  // all assets inside the group have the same assetCategory
  const currentAssetCategory = node?.childrenAfterGroup?.[0].data.assetCategory;

  const handleDefaultCheckboxOnEdit = (checked: boolean) => {
    const updatedAssetCategoryConfigs = assetCategoryConfigs.map((config) =>
      config.assetCategory === currentAssetCategory
        ? { ...config, isDefaultOperatingAsset: checked }
        : config,
    );

    updateAssetConfig(updatedAssetCategoryConfigs);
  };

  const handleCheckboxOnEdit = async (checked: boolean) => {
    if (currentAssetCategory) {
      const updatedRows = (node?.childrenAfterGroup ?? []).map((row) => ({
        ...row.data,
        currentIsOperatingAsset: checked,
      }));

      return await updateAssets(updatedRows);
    }

    if (node?.data) {
      const updatedRow = [{ ...node.data, currentIsOperatingAsset: checked }];
      return await updateAssets(updatedRow);
    }
  };

  const currentAssetConfig = assetCategoryConfigs.find(
    ({ assetCategory }) => assetCategory === currentAssetCategory,
  );

  return (
    <div className="align-items-end flex flex-col">
      {node.level === 0 && (
        <div className="-mb-1.5 flex items-center space-x-2">
          <span className="italic text-orange-400">{t('main:helpMenu.tableColumns.default')}</span>
          <CheckboxCellRenderer
            {...props}
            onEdit={handleDefaultCheckboxOnEdit}
            source="defaultCategoryCheckbox"
            value={!!currentAssetConfig?.isDefaultOperatingAsset}
          />
        </div>
      )}
      <div className="my-1">
        <CheckboxCellRenderer
          {...props}
          indeterminate={indeterminate}
          onEdit={handleCheckboxOnEdit}
          source="operatingCheckbox"
          value={value && !indeterminate}
        />
      </div>
    </div>
  );
};
