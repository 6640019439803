import { Link, useParams } from 'react-router-dom';

import { YearsOverview as Page } from '@org/pages';

export const YearsOverviewPage = () => {
  const { clientId, publicFacilityId = '' } = useParams<{
    clientId: string;
    publicFacilityId: string;
  }>();

  return (
    <Page
      LinkComponent={Link}
      getLinkProps={({ type, yearCalculationId }: { type: string; yearCalculationId: string }) => ({
        to: `/client/${clientId}/${publicFacilityId}/${yearCalculationId}/${type}`,
      })}
      publicFacilityId={publicFacilityId}
    />
  );
};
