import type { ForwardedRef } from 'react';
import { forwardRef, useMemo, useRef } from 'react';

import { FocusScope } from '@react-aria/focus';
import {
  DismissButton,
  useModal,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger,
} from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { tv } from 'tailwind-variants';

import type { OverridableComponent, OverrideProps } from '../../types';
import type { Placement } from '../Overlay';
import { mergeRefs } from '../../utils';

const popoverVariants = tv({
  base: [
    'bg-white',
    'box-border',
    'shadow-xl',
    'inline-flex',
    'flex-col',
    'overflow-y-hidden',
    'overflow-x-auto',
    'rounded-md',
    'outline-0',
  ],
});

export interface PopoverTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props: AdditionalProps & {
    /**
     * Whether to close the overlay when the user interacts outside it.
     *
     * @default true
     */
    isDismissable?: boolean;
    /**
     * Whether pressing the escape key to close the popover should be disabled.
     *
     * @default false
     */
    isKeyboardDismissDisabled?: boolean;
    /**
     *  Whether the popover is currently open.
     */
    isOpen?: boolean;
    /**
     * Handler that is called when the popover should close.
     */
    onClose?: () => void;
    /**
     * Whether the overlay should close when focus is lost or moves outside it.
     */
    shouldCloseOnBlur?: boolean;
    /**
     * When user interacts with the argument element outside of the overlay ref,
     * return true if onClose should be called.  This gives you a chance to filter
     * out interaction with elements that should not dismiss the overlay.
     * By default, onClose will always be called on interaction outside the overlay ref.
     */
    shouldCloseOnInteractOutside?: (element: Element) => boolean;
  };
  defaultComponent: DefaultComponent;
}

export type PopoverProps<
  RootComponent extends React.ElementType = PopoverTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<PopoverTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const Popover = forwardRef(
  <BaseComponentType extends React.ElementType = PopoverTypeMap['defaultComponent']>(
    inProps: PopoverProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const {
      as: Component = 'div',
      children,
      isDismissable,
      isOpen,
      isKeyboardDismissDisabled,
      onClose,
      shouldCloseOnBlur,
      shouldCloseOnInteractOutside,
      className,
      ...otherProps
    } = inProps;
    const overlayRef = useRef<HTMLDivElement>(null);

    const styles = useMemo(
      () =>
        popoverVariants({
          className,
        }),
      [className],
    );

    const { overlayProps } = useOverlay(
      {
        isDismissable: isDismissable && isOpen,
        isKeyboardDismissDisabled,
        isOpen,
        onClose,
        shouldCloseOnBlur,
        shouldCloseOnInteractOutside,
      },
      overlayRef,
    );

    const { modalProps } = useModal({ isDisabled: true });

    return (
      <FocusScope restoreFocus>
        <Component
          {...mergeProps(overlayProps, modalProps, otherProps as Record<string, unknown>)}
          className={styles}
          ref={mergeRefs(overlayRef, ref)}
          role="presentation"
        >
          <DismissButton onDismiss={onClose} />
          {children}
          <DismissButton onDismiss={onClose} />
        </Component>
      </FocusScope>
    );
  },
) as OverridableComponent<PopoverTypeMap>;

export interface PopoverTriggerProps {
  /**
   * Whether the popover is open by default (uncontrolled).
   */
  defaultOpen?: boolean;
  /**
   *  Whether the popover is open by default (controlled).
   */
  isOpen?: boolean;
  /**
   * The maxHeight specified for the popover element.
   *
   * By default, it will take all space up to the current viewport height.
   */
  maxHeight?: number;
  /**
   * The additional offset applied along the main axis between the element and its
   * anchor element.
   *
   * @default 4
   */
  offset?: number;
  /**
   * Handler that is called when the popover's open state changes.
   */
  onOpenChange?: (isOpen: boolean) => void;
  /**
   * The placement of the element with respect to its anchor element.
   *
   * @default 'bottom'
   */
  placement?: Placement;
  /**
   * A ref for the scrollable region within the popover.
   *
   * @default overlayRef
   */
  scrollRef?: React.RefObject<Element>;
  /**
   * Whether the element should flip its orientation (e.g. top to bottom or left to right) when
   * there is insufficient room for it to render completely.
   *
   * @default true
   */
  shouldFlip?: boolean;
  /**
   * Whether the popover should update its position automatically.
   *
   * @default true
   */
  shouldUpdatePosition?: boolean;
  /**
   * Type of popover that is opened by the trigger.
   *
   * @default 'dialog'
   */
  type?: 'dialog' | 'grid' | 'listbox' | 'menu' | 'tree';
}

export interface PopoverTriggerState {
  /** Whether the popover is currently open. */
  readonly isOpen: boolean;
  /** Sets whether the popover is open. */
  setOpen: (isOpen: boolean) => void;
  /** Closes the popover. */
  close: () => void;
  /** Opens the popover. */
  open: () => void;
  /** Toggles the popover's visibility. */
  toggle: () => void;
}

export interface PopoverTriggerStateProps {
  /** Whether the popover is open by default (uncontrolled). */
  defaultOpen?: boolean;
  /**
   * Whether pressing the escape key to close the popover should be disabled.
   *
   * @default false
   */
  /**
   * Whether the popover is open by default (controlled).
   */
  isOpen?: boolean;
  /** Handler that is called when the popover's open state changes. */
  onOpenChange?: (isOpen: boolean) => void;
}

export function usePopoverTrigger(props: PopoverTriggerProps, state: PopoverTriggerState) {
  const {
    maxHeight,
    offset = 4,
    placement = 'bottom',
    scrollRef,
    shouldFlip = true,
    shouldUpdatePosition = true,
    type = 'dialog',
  } = props;

  const overlayRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const { triggerProps, overlayProps } = useOverlayTrigger({ type }, state, triggerRef);
  const { overlayProps: positionProps } = useOverlayPosition({
    isOpen: state.isOpen,
    maxHeight,
    offset,
    onClose: state.close,
    overlayRef,
    placement,
    scrollRef,
    shouldFlip,
    shouldUpdatePosition,
    targetRef: triggerRef,
  });

  return {
    overlayProps: { ...overlayProps, ...positionProps },
    overlayRef,
    state,
    triggerProps,
    triggerRef,
  };
}

export function usePopoverTriggerState(props: PopoverTriggerStateProps): PopoverTriggerState {
  const state = useOverlayTriggerState(props);

  return state;
}
