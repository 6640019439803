import PropTypes from 'prop-types';

import { useTranslation } from '@org/locales';

import ConfigMenu from './ConfigMenu/ConfigMenu';

import './WorkflowMenu.scss';

import { Link, useParams } from 'react-router-dom';

import { YearMenu } from '@org/features';

const WorkflowMenu = ({ selectedConfigId = '', configItem, setConfigItem }) => {
  const { t } = useTranslation();
  const { clientId, publicFacilityId, yearId, type, configId } = useParams();

  if (!configId) {
    return (
      <YearMenu
        LinkComponent={Link}
        getConfigurationsLinkProps={() => ({
          to: `/client/${clientId}/${publicFacilityId}/${yearId}/${type}`,
          'data-selected':
            window.location.pathname ===
            `/client/${clientId}/${publicFacilityId}/${yearId}/${type}`,
        })}
        getFileLinkProps={(fileType, hoverTarget = 'OVERVIEW') => {
          const url = new URL(
            `/client/${clientId}/${publicFacilityId}/${yearId}/${type}/files`,
            window.location.origin,
          );
          url.searchParams.set('fileType', fileType);
          url.searchParams.set('tab', hoverTarget);
          return {
            to: url.toString(),
            'data-selected': window.location.search.includes(`fileType=${fileType}`),
          };
        }}
        yearId={yearId}
      />
    );
  }

  return (
    <div
      className="h-[calc(100vh-125px)]"
      id="workflow-menu"
    >
      <div className="title-item">{t('main:clients.menu.title')}</div>
      <ConfigMenu
        configItem={configItem}
        selectedConfigId={selectedConfigId}
        setConfigItem={setConfigItem}
      />
    </div>
  );
};

WorkflowMenu.propTypes = {
  configItem: PropTypes.string,
  setConfigItem: PropTypes.func.isRequired,
};

export default WorkflowMenu;
