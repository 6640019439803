import type { FC } from 'react';
import { useState } from 'react';

import { InformationCircleIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/solid';
import {
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  useDisclosure,
} from '@nextui-org/react';
import classNames from 'classnames';

import type { dice } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, Modal, ModalContent, showNotification, Tooltip } from '@org/ui';
import { bindControllers, formatToSimpleDate } from '@org/utils';

import type { ControllerType } from './useController';
import {
  ActivateConfigurationModalContent,
  CopyConfigurationModalContent,
  DeleteConfigurationModalContent,
  FinalizeConfigurationModalContent,
  UpdateConfigurationModalContent,
} from './components';
import { useController } from './useController';

export interface ConfigurationProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

type ModalContentKeys =
  | 'COPY_CONFIG'
  | 'DELETE_CONFIG'
  | 'FINALIZE_CONFIG'
  | 'UPDATE_CONFIG'
  | 'ACTIVATE_CONFIG';

export interface ModalContentProps {
  disclosureState: ReturnType<typeof useDisclosure>;
  entity: dice.MasterConfigurationEntityDTO;
  publicFacilityId: string;
  yearId: string;
}

const ConfigurationView: FC<ConfigurationProps> = (props) => {
  const {
    type,
    columnDefs,
    onGridReady,
    tableRef,
    defaultColDef,
    setBackups,
    LinkComponent,
    filterType,
    setVariants,
    promoteVariantModal,
    getLinkProps,
    onCellValueChange,
    createBackup,
    primaryConfigurationEntity,
    restoreBackupModal,
    createVariant,
    deleteModal,
    createNewMasterConfig,
    selectedYear,
    publicFacilityId,
    getRowId,
    yearId,
    createBackupMutation,
    createVariantMutation,
  } = props;

  const { t } = useTranslation();

  const disclosureState = useDisclosure();
  const { isOpen, onOpenChange, onOpen } = disclosureState;
  const [activeModalKey, setActiveModalKey] = useState<ModalContentKeys>('COPY_CONFIG');

  const MODAL_CONTENT_LIST: Record<ModalContentKeys, React.FC<ModalContentProps>> = {
    ACTIVATE_CONFIG: ActivateConfigurationModalContent,
    COPY_CONFIG: CopyConfigurationModalContent,
    DELETE_CONFIG: DeleteConfigurationModalContent,
    FINALIZE_CONFIG: FinalizeConfigurationModalContent,
    UPDATE_CONFIG: UpdateConfigurationModalContent,
  };

  const modalContentProps = {
    disclosureState,
    entity: primaryConfigurationEntity ?? {},
    publicFacilityId,
    yearId,
  };

  const SelectedModalContent = MODAL_CONTENT_LIST[activeModalKey];

  const handleOnDropdownItemClick = (key: ModalContentKeys) => {
    onOpen();
    setActiveModalKey(key);
  };

  return (
    <>
      <Card className="h-full flex-1 !rounded-l-md !rounded-r-none bg-gray-100">
        <div className="flex h-[240px] w-full items-center justify-center">
          {primaryConfigurationEntity?.id ? (
            <Card
              as={LinkComponent}
              className="min-h-[120px] w-[340px] rounded-md p-4 shadow-md hover:text-black hover:no-underline"
              {...getLinkProps(primaryConfigurationEntity?.id)}
            >
              <div>
                <div className="flex justify-between space-x-4">
                  <div className="flex items-center space-x-2">
                    <Tooltip
                      className="max-w-sm"
                      content={t(
                        'main:manageMenu.configurationPage.primaryConfigurationExplanation',
                      )}
                    >
                      <InformationCircleIcon className="h-5 w-5 text-gray-500" />
                    </Tooltip>
                    <div className="text-lg font-medium">
                      {type === 'post' &&
                        t(`main:reportGeneration.selectionSection.postCalculation`)}
                      {type === 'pre' && t(`main:reportGeneration.selectionSection.preCalculation`)}
                    </div>
                    <div />
                  </div>
                  <Dropdown>
                    <DropdownTrigger className="h-8 min-h-8 w-8 min-w-8 rounded p-1 hover:bg-gray-200">
                      <EllipsisVerticalIcon
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Static Actions">
                      <DropdownItem
                        onClick={() => handleOnDropdownItemClick('UPDATE_CONFIG')}
                        startContent={<PencilSquareIcon className="h-4 w-4" />}
                      >
                        {t('common:update')}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleOnDropdownItemClick('DELETE_CONFIG')}
                        startContent={<TrashIcon className="h-4 w-4" />}
                      >
                        {t('common:contextMenu.delete')}
                      </DropdownItem>
                      {primaryConfigurationEntity.configActivityStatus === 'ACTIVE' ? (
                        <DropdownItem
                          onClick={() => handleOnDropdownItemClick('FINALIZE_CONFIG')}
                          startContent={<CheckCircleIcon className="h-4 w-4" />}
                        >
                          {t('common:contextMenu.modalFinalize.btnFinalize')}
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          onClick={() => handleOnDropdownItemClick('ACTIVATE_CONFIG')}
                          startContent={<ArrowPathIcon className="h-4 w-4" />}
                        >
                          {t('common:contextMenu.modalActivate.btnActivate')}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {primaryConfigurationEntity.name && (
                  <div className="my-2 line-clamp-1 text-sm font-medium">
                    {primaryConfigurationEntity.name}
                  </div>
                )}
                <Tooltip
                  className="max-w-sm"
                  content={primaryConfigurationEntity.description}
                >
                  {primaryConfigurationEntity.description && (
                    <div className="line-clamp-2 text-sm text-gray-500">
                      {primaryConfigurationEntity.description}
                    </div>
                  )}
                </Tooltip>
              </div>
              <div className="mt-4 flex justify-between text-sm text-gray-500">
                <div className="flex space-x-2">
                  <CalendarDaysIcon className="h-5 w-5" />
                  <div>
                    {primaryConfigurationEntity?.lastUpdateIsoDateTime &&
                      formatToSimpleDate(
                        new Date(primaryConfigurationEntity?.lastUpdateIsoDateTime),
                        'MMM dd, yyyy HH:mm',
                      )}
                  </div>
                </div>
                <Tooltip
                  className="max-w-sm"
                  content={t('main:manageMenu.configurationPage.configIsFinalized')}
                >
                  <div>
                    {primaryConfigurationEntity.configActivityStatus === 'FINALIZED' && (
                      <div className="flex space-x-2">
                        <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        <span className="capitalize">{t('main:final')}</span>
                      </div>
                    )}
                  </div>
                </Tooltip>
              </div>
            </Card>
          ) : (
            <div className="space-y-2">
              <Button
                onClick={() => handleOnDropdownItemClick('COPY_CONFIG')}
                size="md"
                variant="primary"
              >
                {t('main:manageMenu.configurationPage.copyDataFromPreviousYear')}
              </Button>
              <Button
                onClick={() => createNewMasterConfig({ year: selectedYear?.year ?? '' })}
                size="md"
                variant="secondary"
              >
                {t('main:manageMenu.configurationPage.createNewConfiguration')}
              </Button>
            </div>
          )}
        </div>
        <div className="h-[calc(100vh-400px)] p-4">
          <div className="mb-2 flex justify-between">
            <div className="flex space-x-2">
              <Button
                onClick={setVariants}
                size="md"
                variant={filterType === 'VARIANT' ? 'primary' : 'transparent'}
              >
                <div className="flex items-center space-x-2">
                  <Tooltip
                    className="max-w-sm"
                    content={t('main:manageMenu.configurationPage.variantExplanation')}
                  >
                    <InformationCircleIcon
                      className={classNames('text-gray h-5 w-5', {
                        'text-white': filterType === 'VARIANT',
                      })}
                    />
                  </Tooltip>
                  <div>{t('main:manageMenu.configurationPage.variants')}</div>
                </div>
              </Button>
              <Button
                onClick={setBackups}
                size="md"
                variant={filterType === 'BACKUP' ? 'primary' : 'transparent'}
              >
                <div className="flex items-center space-x-2">
                  <Tooltip
                    className="max-w-sm"
                    content={t('main:manageMenu.configurationPage.backupExplanation')}
                  >
                    <InformationCircleIcon
                      className={classNames('text-gray h-5 w-5', {
                        'text-white': filterType === 'BACKUP',
                      })}
                    />
                  </Tooltip>
                  <div>{t('main:manageMenu.configurationPage.backups')}</div>
                </div>
              </Button>
            </div>

            {primaryConfigurationEntity?.id && (
              <Button
                isDisabled={createBackupMutation.isPending || createVariantMutation.isPending}
                onClick={() => {
                  if (filterType === 'BACKUP') {
                    try {
                      createBackup(primaryConfigurationEntity);
                      showNotification(
                        'success',
                        t('main:manageMenu.configurationPage.createBackupSuccess'),
                      );
                    } catch {
                      showNotification(
                        'warning',
                        t('main:clients.clientsOverviewPanel.alerts.warning.general'),
                      );
                    }
                  }
                  if (filterType === 'VARIANT') {
                    try {
                      createVariant(primaryConfigurationEntity);
                      showNotification(
                        'success',
                        t('main:manageMenu.configurationPage.createVariantSuccess'),
                      );
                    } catch {
                      showNotification(
                        'warning',
                        t('main:clients.clientsOverviewPanel.alerts.warning.general'),
                      );
                    }
                  }
                }}
                size="md"
                startContent={<span className="pb-0.5 text-xl">+</span>}
                variant="secondary"
              >
                {t(
                  `main:manageMenu.configurationPage.${
                    filterType === 'VARIANT' ? 'newVariant' : 'newBackup'
                  }`,
                )}
              </Button>
            )}
          </div>
          <BaseEditTable
            animateRows
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableGroupEdit
            getRowId={getRowId}
            groupDisplayType="singleColumn"
            onCellValueChanged={onCellValueChange}
            onGridReady={onGridReady}
            ref={tableRef}
            singleClickEdit
          />
        </div>
      </Card>
      {promoteVariantModal}
      {restoreBackupModal}
      {deleteModal}
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="2xl"
      >
        <ModalContent>
          <SelectedModalContent {...modalContentProps} />
        </ModalContent>
      </Modal>
    </>
  );
};

export const ConfigurationPage = bindControllers(ConfigurationView, [useController]);
ConfigurationPage.displayName = 'ConfigurationPage';
