const DEFAULT_VALUE = 0;

export const parseGermanPriceFormat = (germanPrice: string | number): number => {
  if (!germanPrice) {
    return DEFAULT_VALUE;
  }

  const priceString = typeof germanPrice === 'string' ? germanPrice : germanPrice.toString();

  const numberString = priceString.toString().replace(',', '.');
  const number = Number(numberString);

  return Number.isNaN(number) ? DEFAULT_VALUE : number;
};
