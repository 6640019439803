import type { ICellRendererParams } from 'ag-grid-community';

import { useTranslation } from '@org/locales';
import { costCenterTypes } from '@org/utils';

import { DEFAULT_GROUP_NAME } from '../../constants';

export const CustomGroupCellRenderer = (props: ICellRendererParams) => {
  const { node, value } = props;
  const { t } = useTranslation();

  if (node.key === DEFAULT_GROUP_NAME) {
    const parentMainCategory = node.parent!.key;

    return (
      <div>
        {t(`main:manageMenu.manageCostCenters.costCenterDefaultGroups.${parentMainCategory}`)}
      </div>
    );
  } else if (node.group && node.level === 0) {
    return <div>{t(costCenterTypes[value as keyof typeof costCenterTypes].label)}</div>;
  }
  return <div>{value}</div>;
};
