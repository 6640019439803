import { useCallback } from 'react';

import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface SelectedAllCheckboxProps {
  handleEditConfig: (accounts: dice.WithdrawCapital['accountsAllocation']) => Promise<void>;
  accountsAllocation: dice.WithdrawCapital['accountsAllocation'];
  defaultAllocationMap: dice.WithdrawCapital['accountsAllocation'];
}

export const SelectAllCheckbox = ({
  accountsAllocation,
  handleEditConfig,
  defaultAllocationMap,
}: SelectedAllCheckboxProps) => {
  const accountCostCentersLength = Object.keys(defaultAllocationMap ?? {}).length;

  const accountsAllocationLength = Object.keys(accountsAllocation ?? {}).length;

  const isFullySelected =
    accountCostCentersLength === accountsAllocationLength && accountsAllocationLength > 0;

  const handleOnEdit = useCallback(
    async (isSelected: boolean) => {
      if (isSelected) {
        await handleEditConfig(defaultAllocationMap);
      } else {
        await handleEditConfig({});
      }
    },
    [defaultAllocationMap, handleEditConfig],
  );

  const indeterminate = !isFullySelected && accountsAllocationLength > 0;

  return (
    <div className="ml-2">
      <CheckboxCellRenderer
        indeterminate={indeterminate}
        onEdit={handleOnEdit}
        source="headerSelectAll"
        value={isFullySelected}
      />
    </div>
  );
};
