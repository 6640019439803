import type { FC } from 'react';

import Link from '@tiptap/extension-link';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { useTranslation } from '@org/locales';

interface CommentProps {
  content?: string;
  onChange: (newContent: string) => void;
  isDisabled?: boolean;
}

const extensions = [
  StarterKit,
  Link.configure({
    openOnClick: true,
    autolink: true,
    defaultProtocol: 'https',
  }),
];

export const Comment: FC<CommentProps> = ({ content = '', onChange, isDisabled = false }) => {
  const { t } = useTranslation();

  const editor = useEditor({
    extensions,
    editable: !isDisabled,
    content,
    onUpdate: ({ editor: localEditor }) => {
      onChange(localEditor.getHTML());
    },
    editorProps: {
      attributes: {
        spellcheck: 'false',
        class:
          'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto w-full h-full border border-solid border-gray-500 bg-white p-2 focus:outline-none',
      },
    },
  });

  return (
    <div className="flex h-full w-full flex-col gap-2">
      <div className="text-small">{t('main:comments.header')}</div>
      <EditorContent
        className={`h-full w-full ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}
        editor={editor}
      />
    </div>
  );
};
