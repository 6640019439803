import type { GetRowIdParams } from 'ag-grid-community';
import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface ManualCorrectionsBabProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const getRowId = ({ data }: GetRowIdParams) => data?.name;

const View: FC<ManualCorrectionsBabProps> = (props) => {
  const { tableRef, autoGroupColumnDef, columnDefs, defaultColDef, onGridReady, modal, onChange } =
    props;

  return (
    <div
      className="h-full flex-1"
      data-testid="CostTypeAccount"
    >
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        groupDisplayType="singleColumn"
        isGroupOpenByDefault={() => true}
        masterDetail
        onCellValueChanged={onChange}
        onGridReady={onGridReady}
        ref={tableRef}
      />
      {modal}
    </div>
  );
};

export const ManualCorrectionsBabPage = bindControllers(View, [useController]);

ManualCorrectionsBabPage.displayName = 'ManualCorrectionsBabPage';
