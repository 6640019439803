import { useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';
import { aggregated, dice } from '@org/query';

import type { CostCenter, Job, ProfitCenter } from '../types';
import { getCostCenterName, getOrCreateCostCenter, getOrCreateProfitCenter } from '../helpers';
import { useLabels } from './useLabels';

interface UseCostUnitProfitCenterTreeProps {
  selectedAccounts?: (string | number)[];
  shortName?: string;
  apiParams: APIParams<'yearId' | 'masterConfigurationId'>;
}

interface CreateProfitCenterTreeArgs {
  costUnitConfig?: griddy.MasterConfiguration['costUnitConfig'];
  shortName?: string;
  jobIdMap: Map<string, Job>;
  costCenterMap: Map<string, CostCenter>;
  profitCenterMap: Map<string, ProfitCenter>;
}
function createProfitCenterTree({
  costUnitConfig = [],
  shortName,
  profitCenterMap,
  jobIdMap,
  costCenterMap,
}: CreateProfitCenterTreeArgs) {
  const localAvailableProfitCenters = new Map<string, ProfitCenter>(profitCenterMap);
  const localUnavailableProfitCenters = new Map<string, ProfitCenter>();
  const unavailableKeys = new Set(
    costUnitConfig
      ?.filter(({ shortName: currentShortName }) => shortName !== currentShortName)
      .flatMap(
        ({ selectRevenueAccountsConfiguration }) => selectRevenueAccountsConfiguration?.mappingKeys,
      )
      .filter(Boolean) as dice.MappingKey[],
  );

  unavailableKeys.forEach((unavailableKey) => {
    const item = jobIdMap?.get(unavailableKey?.id ?? '');
    // Profit center tree can be out of date
    if (!item) {
      return;
    }

    item.isUnavailable = true;
    const currentCostCenter = costCenterMap!.get(item.costCenterName)!;
    currentCostCenter.unavailableJobs.add(item);
    currentCostCenter.availableJobs.delete(item);
    currentCostCenter.isUnavailable = currentCostCenter.availableJobs.size === 0;

    profitCenterMap!.get(item.profitCenterName)!.unavailableJobs.add(item);
    profitCenterMap!.get(item.profitCenterName)!.availableJobs.delete(item);
  });

  costCenterMap?.forEach((item) => {
    const { profitCenterName, isUnavailable } = item;

    const currentProfitCenter = profitCenterMap!.get(profitCenterName)!;
    if (!isUnavailable) {
      return;
    }
    currentProfitCenter.unavailableCostCenters.add(item);
    currentProfitCenter.availableCostCenters.delete(item);
    currentProfitCenter.isUnavailable = currentProfitCenter.availableCostCenters.size === 0;

    if (currentProfitCenter.isUnavailable) {
      localUnavailableProfitCenters.set(currentProfitCenter.name, currentProfitCenter);
      localAvailableProfitCenters.delete(currentProfitCenter.name);
    }
  });

  return {
    availableProfitCenters: localAvailableProfitCenters,
    unavailableProfitCenters: localUnavailableProfitCenters,
  };
}

export const useCostUnitProfitCenterTree = (props: UseCostUnitProfitCenterTreeProps) => {
  const { apiParams, selectedAccounts = [], shortName } = props;
  const { yearId, masterConfigurationId: masterConfigId } = apiParams;

  const { costCenterLabels, isLoading, jobIdLabels, profitCenterLabels } = useLabels(yearId);
  const { data: originalData } = dice.useGetProfitCenterTreeForCostUnit(
    {
      pathParams: {
        masterConfigId,
        yearId,
      },
    },
    {
      enabled: !isLoading,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  const { masterConfiguration: { costUnitConfig } = {} } =
    aggregated.useMasterConfiguration(apiParams);

  const returnObject = useMemo(() => {
    if (!originalData) {
      return {
        availableProfitCenters: new Map<string, ProfitCenter>(),
        costCenterMap: undefined,
        unavailableProfitCenters: new Map<string, ProfitCenter>(),
      };
    }

    const accountIdsSet = new Set(selectedAccounts.map((item) => item?.toString()));
    const data = originalData as Record<
      string,
      {
        [K in keyof dice.AcctTrxSelectCostUnitCriteriaDTO]-?: dice.AcctTrxSelectCostUnitCriteriaDTO[K];
      }
    >;
    const localJobIdMap = new Map<string, Job>();
    const localCostCenterMap = new Map<string, CostCenter>();
    const localProfitCenterMap = new Map<string, ProfitCenter>();

    for (const item of Object.values(data)) {
      const { costCenter, id, jobId, profitCenter, accountId } = item;

      if (!accountIdsSet.has(accountId?.toString())) {
        continue;
      }

      const costCenterName = getCostCenterName(profitCenter, costCenter);
      localJobIdMap.set(id, {
        accountId,
        costCenter,
        costCenterName,
        id,
        label: jobIdLabels?.get(jobId),
        name: jobId,
        profitCenterName: profitCenter,
      });

      const currentJob = localJobIdMap.get(id)!;

      const currentCostCenter = getOrCreateCostCenter(
        localCostCenterMap,
        item,
        costCenterName,
        costCenterLabels?.get(costCenter),
      );
      currentCostCenter.availableJobs.add(currentJob);

      const currentProfitCenter = getOrCreateProfitCenter(
        localProfitCenterMap,
        item,
        profitCenterLabels?.get(profitCenter),
      );
      currentProfitCenter.availableJobs.add(currentJob);
      if (!currentProfitCenter.availableCostCenters.has(currentCostCenter)) {
        currentProfitCenter.availableCostCenters.add(currentCostCenter);
      }
    }

    const { availableProfitCenters, unavailableProfitCenters } = createProfitCenterTree({
      costCenterMap: localCostCenterMap,
      costUnitConfig,
      jobIdMap: localJobIdMap,
      profitCenterMap: localProfitCenterMap,
      shortName,
    });

    return {
      availableProfitCenters,
      costCenterMap: localCostCenterMap,
      unavailableProfitCenters,
    };
  }, [
    costCenterLabels,
    costUnitConfig,
    jobIdLabels,
    originalData,
    profitCenterLabels,
    selectedAccounts,
    shortName,
  ]);

  return returnObject;
};
