/* eslint-disable @typescript-eslint/no-explicit-any */
import { isString } from '../../type-guards';

export type Get = <
  Value = any,
  T extends Record<string | number | symbol, any> = Record<string | number | symbol, any>,
>(
  obj: T | null | undefined,
  key: keyof T | string | number | symbol,
) => Value | null | undefined;

/**
 * @deprecated use `array[index]` or `object.property`/`object[computedProperty]`
 * @param obj
 * @param key
 * @returns
 */
export const get: Get = (obj, key) => {
  if (!obj) {
    return;
  }
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  }

  if (isString(key) && key.includes('.')) {
    return resolvePathInObj(key, obj);
  }
};

export const resolvePathInObj = <T extends Record<string | number, any>>(
  path: string | string[],
  obj: T | null | undefined,
  separator = '.',
): any => {
  if (!path || !obj) {
    return;
  }
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev: T | null | undefined, curr) => prev && get(prev, curr), obj);
};
