import type { FC } from 'react';

import { useTranslation } from '@org/locales';
import {
  BaseEditTable,
  Button,
  FullSizeModal,
  IconCellRenderer,
  LoaderWrapper,
  ModalBody,
  ModalContent,
  ModalHeader,
  StatusCellRenderer,
} from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const {
    handleAsyncTransactionsFlushed,
    description,
    onClose,
    modalGridRef,
    className,
    constCenterLongName,
    modalActionButtonsDisabled,
    transactionsPresentToFlush,
    discardMadeManualChanges,
    isRecalculating,
    columnDefs,
    defaultColDef,
    handleTableChange,
    onFilterChanged,
    agGridProps,
    isOpen,
    onOpenChange,
  } = props;
  const { t } = useTranslation();

  return (
    <FullSizeModal
      className={className}
      isOpen={isOpen}
      onClose={discardMadeManualChanges}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        <ModalHeader>
          <h5>
            {description} - <span className="font-bold">{constCenterLongName}</span>
          </h5>
        </ModalHeader>
        <ModalBody>
          <div className={`w-full pr-2.5 ${modalActionButtonsDisabled ? 'disableEdit' : ''}`}>
            <div className="flex">
              <Button
                className="mr-3"
                isDisabled={!transactionsPresentToFlush}
                onClick={discardMadeManualChanges}
                size="sm"
                variant="primary"
              >
                {t('common:discardChanges')}
              </Button>
              <Button
                isDisabled={!transactionsPresentToFlush}
                onClick={onClose}
                size="sm"
                variant="primary"
              >
                {t('common:saveAndRecalculate')}
              </Button>
            </div>
          </div>
          <div className="mt-4 h-full">
            <LoaderWrapper loading={isRecalculating}>
              <BaseEditTable
                animateRows
                asyncTransactionWaitMillis={600_000}
                className="min-w-[300px]"
                columnDefs={columnDefs}
                components={{
                  icon: IconCellRenderer,
                  status: StatusCellRenderer,
                }}
                defaultColDef={defaultColDef}
                getRowId={({ data: asset }) => asset?._id}
                onAsyncTransactionsFlushed={handleAsyncTransactionsFlushed}
                onChange={handleTableChange}
                onFilterChanged={onFilterChanged}
                onFirstDataRendered={onFilterChanged}
                ref={modalGridRef}
                rowSelection="multiple"
                {...agGridProps}
              />
            </LoaderWrapper>
          </div>
        </ModalBody>
      </ModalContent>
    </FullSizeModal>
  );
};

export const InterestReportModal = bindControllers(View, [useController]);
InterestReportModal.displayName = 'InterestReportModal';
