import type { InputProps as NextInputProps } from '@nextui-org/react';
import type { Control } from 'react-hook-form';

import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { extendVariants, Input as NextInput } from '@nextui-org/react';
import { Controller } from 'react-hook-form';

import { useTranslation } from '@org/locales';

import styles from './Input.module.css';

interface InputProps extends Omit<NextInputProps, 'variant'> {
  variant?: 'default';
}

export const Input = extendVariants(NextInput, {
  defaultVariants: {
    size: 'sm',
    variant: 'default',
  },
  slots: {},
  variants: {
    size: {
      md: {
        input: 'text-md',
        inputWrapper: styles.inputMd,
      },
      sm: {
        input: 'text-sm',
        inputWrapper: styles.inputSm,
      },
      xl: {
        input: 'text-xl',
        inputWrapper: styles.inputXl,
      },
      xs: {
        input: 'text-xs hover:text-xs',
        inputWrapper: styles.inputXs,
      },
    },
    variant: {
      default: {
        innerWrapper: 'bg-transparent',
        input: ['bg-transparent', 'text-black/90', 'placeholder:text-default-700'],
        inputWrapper: [
          'bg-white',
          'border-1',
          'border-solid',
          'border-[#d1d1d1]',
          'rounded-sm',
          '!cursor-text',
          'focus-within:border-orange-default',
          styles.DefaultStyles,
        ],
        label: 'text-black/50',
      },
    },
  },
});

interface TextFieldProps extends InputProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  required?: boolean;
}

export const SearchField = (props: TextFieldProps) => {
  const { control, name, onChange, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Input
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            field.onChange(value);
            onChange?.(event);
          }}
          placeholder={t('common:search')}
          radius="none"
          startContent={<MagnifyingGlassIcon className="h-4 w-4" />}
          type="text"
          value={field.value ?? ''}
          variant="default"
          {...rest}
        />
      )}
    />
  );
};
