export const insertToArr = <T>(array: T[], items: T[], index: number): T[] => {
  const newArr = Array.from(array);
  newArr.splice(index, 0, ...items);

  return newArr;
};

export const removeFromArr = <T>(array: T[], index: number, numOfItems = 1): T[] => {
  const newArr = Array.from(array);
  newArr.splice(index, numOfItems);

  return newArr;
};

export const iterableToArray = <T>(iterable: Iterable<T> = new Set()) => [...iterable];

export const removeFromArrayAtIndexMut = <T>(array: T[], index: number): T[] =>
  array.splice(index, 1);

export const addToArrayAtIndexMut = <T>(array: T[], items: T[], index: number): T[] =>
  array.splice(index, 0, ...items);
