/**
 * @deprecated please use get from utils/object instead
 */
export const objGet = <T extends Record<string | number, any>, Value = any>(
  obj: T | null | undefined,
  key: keyof T | string | number,
): Value | null | undefined => {
  if (!obj) {
    return;
  }
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  }
};

/**
 * @deprecated please use objCopyAndSet instead & set from utils/object instead!
 */
export const objSet = <T extends Record<string | number, any>, Value = any>(
  obj: T | undefined | null,
  key: keyof T | number | string,
  val: Value,
): (T & Record<number | string, Value>) | undefined | null => {
  if (!obj) {
    return;
  }

  Object.defineProperty(obj, key, {
    configurable: true,
    enumerable: true,
    value: val,
    writable: true,
  });
  return obj;
};

export const objCopyAndSet = <T extends Record<string | number, any>, Value = any>(
  obj: T | undefined | null,
  key: keyof T | number | string,
  val: Value,
): (T & Record<number | string, Value>) | undefined | null => {
  if (!obj) {
    return;
  }

  return Object.defineProperty({ ...obj }, key, {
    configurable: true,
    enumerable: true,
    value: val,
    writable: true,
  });
};

export const objDelete = <T>(
  obj: T[] | Record<string | number, any> | null | undefined,
  key: string | number,
): T[] | Record<string | number, any> | null | undefined => {
  if (!obj) {
    return;
  }

  if (Array.isArray(obj)) {
    if (typeof key !== 'number') {
      console.error(
        '[object.js] When deleting an index from an array the `key` should be an integer!',
      );
      return null;
    }

    obj.splice(key, 1);
    return obj;
  }

  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    delete obj[key];
    return obj;
  }
};

export const resolvePathInObj = <T extends Record<string | number, any>>(
  path: string | string[],
  obj: T | null | undefined,
  separator = '.',
): any => {
  if (!path || !obj) {
    return;
  }
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev: T | null | undefined, curr) => prev && objGet(prev, curr), obj);
};
