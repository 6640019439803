import type { ICellRendererParams } from 'ag-grid-community';
import type { ChangeEvent } from 'react';
import { useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import type { dice } from '@org/query';
import { useDebounce, useReinitializeForm } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { ReactSelect, showNotification, TextInput, Tooltip } from '@org/ui';
import {
  groupBy,
  INDEX_FACTORS_AVAILABLE_FROM_YEAR,
  parseDateString,
  removeFromArr,
} from '@org/utils';

interface WiberaIndexRowRendererProps extends ICellRendererParams {
  path: string;
  masterConfiguration: dice.MasterConfiguration | undefined;
  indexFactors: dice.GetIndexFactorForYearAndFieldResponse | undefined;
}

const NOT_EQUAL_CHAR = '≠';

const emptySelectRow = { label: '', value: '-1' };
const notEqualSelectRow = { isDisabled: true, label: NOT_EQUAL_CHAR, value: '' };

const createOptions = (indexes: dice.GetIndexFactorForYearAndFieldResponse) => {
  const generatedOptions = indexes.map((indx) => ({
    label: `${indx.indexRow} ${indx.indexName}`,
    value: String(indx.indexRow),
  }));
  generatedOptions.push(emptySelectRow, notEqualSelectRow);
  return generatedOptions;
};

const createOption = (
  mapping: dice.WiberaMapping,
  indexes: dice.GetIndexFactorForYearAndFieldResponse,
) => {
  const index = indexes.find((idx) => idx.indexRow === mapping.indexRow);
  if (!index) {
    return emptySelectRow;
  }
  return {
    label: `${index.indexRow} ${index.indexName}`,
    value: String(index.indexRow),
  };
};

export const WiberaIndexRowRenderer = ({
  node,
  data,
  masterConfiguration,
  indexFactors,
}: WiberaIndexRowRendererProps) => {
  const { t } = useTranslation();
  const { updateMasterConfiguration } = aggregated.useMasterConfiguration();
  const yearOriginal = parseDateString(data?.purchasingDate)?.getFullYear().toString() ?? null;

  const defaultValues = useMemo(() => {
    const wiberaMapping = masterConfiguration?.wiberaConfig?.wiberaMapping ?? [];
    const mappingGroupByAssetCategory = groupBy(wiberaMapping, 'assetCategory');

    const currentAssetCategory =
      node.level === 0 ? node.childrenAfterGroup?.[0].data.assetCategory : data.assetCategory;

    const mappings = mappingGroupByAssetCategory.get(currentAssetCategory);

    const currentAssetNumber = data?.assetNumber;

    let wiberaIndex = emptySelectRow;
    let nbrOfYearsForFactorsCalculation = yearOriginal ?? '';

    if (mappings) {
      const categoryMapping = mappings.find((mapping) => mapping.assetNumber === null);

      if (node.level === 0) {
        if (categoryMapping) {
          wiberaIndex = createOption(categoryMapping, indexFactors ?? []);
        }
      } else {
        const assetNumberMapping = mappings.find(
          (mapping) => mapping.assetNumber === currentAssetNumber,
        );

        if (assetNumberMapping) {
          wiberaIndex = createOption(assetNumberMapping, indexFactors ?? []);
        } else if (categoryMapping) {
          wiberaIndex = createOption(categoryMapping, indexFactors ?? []);
        } else {
          wiberaIndex = emptySelectRow;
        }

        nbrOfYearsForFactorsCalculation = String(
          (assetNumberMapping?.indexFactorYear ?? 0) > 0
            ? assetNumberMapping?.indexFactorYear
            : yearOriginal,
        );
      }
    }

    return {
      nbrOfYearsForFactorsCalculation,
      wiberaIndex,
    };
  }, [
    masterConfiguration?.wiberaConfig?.wiberaMapping,
    node.level,
    node.childrenAfterGroup,
    data?.assetCategory,
    data?.assetNumber,
    indexFactors,
    yearOriginal,
  ]);

  const { control, reset } = useForm({
    defaultValues,
  });

  const updateWiberaIndexCategoryAsset = async (indexRow: string) => {
    const currentAssetCategory = node?.childrenAfterGroup?.[0].data.assetCategory;
    const wiberaMapping = masterConfiguration?.wiberaConfig?.wiberaMapping ?? [];

    const assetsWithChangedYears = wiberaMapping.filter(
      (tmpWiberaMapItem) =>
        tmpWiberaMapItem.assetCategory === currentAssetCategory && tmpWiberaMapItem.indexFactorYear,
    );
    const updatedAssetsWithChangedYears = assetsWithChangedYears.map((tmpAsset) => ({
      ...tmpAsset,
      indexRow,
    }));
    const filteredWiberaMapping = wiberaMapping.filter(
      (tmpWiberaMapItem) => tmpWiberaMapItem.assetCategory !== currentAssetCategory,
    );

    let updatedWiberaMapping;
    if (indexRow === '-1') {
      const categoryMappingIndex = wiberaMapping.findIndex(
        (mapping) => mapping.assetNumber === null && mapping.assetCategory === currentAssetCategory,
      );

      if (categoryMappingIndex === -1) {
        return;
      }
      updatedWiberaMapping = removeFromArr(wiberaMapping, categoryMappingIndex);
    } else {
      updatedWiberaMapping = [
        ...filteredWiberaMapping,
        { assetCategory: currentAssetCategory, indexRow },
        ...updatedAssetsWithChangedYears,
      ];
    }

    await updateWiberaIndex(updatedWiberaMapping as dice.WiberaMapping[]);
  };

  const updateWiberaIndexAsset = async (indexRow: string) => {
    const currentAssetCategory = data?.assetCategory;
    const currentAssetNumber = data?.assetNumber;

    const wiberaMapping = masterConfiguration?.wiberaConfig?.wiberaMapping ?? [];
    const existingAssetMapping = wiberaMapping.find(
      (tmpWiberaMapItem) => tmpWiberaMapItem.assetNumber === currentAssetNumber,
    );
    // change wibera index for one asset
    const filteredWiberaMapping = wiberaMapping.filter(
      (tmpWiberaMapItem) => tmpWiberaMapItem.assetNumber !== currentAssetNumber,
    );

    let updatedWiberaMapping;
    if (indexRow === '-1') {
      if (existingAssetMapping) {
        // remove wibera config for single asset
        updatedWiberaMapping = filteredWiberaMapping;
      }
    } else {
      const updatedMapping = existingAssetMapping
        ? { ...existingAssetMapping, indexRow }
        : {
            assetCategory: currentAssetCategory,
            assetNumber: currentAssetNumber,
            indexRow,
          };

      updatedWiberaMapping = [...filteredWiberaMapping, updatedMapping];
    }

    await updateWiberaIndex(updatedWiberaMapping as dice.WiberaMapping[]);
  };

  const updateWiberaAssetIndexFactorYear = async (indexFactorYear: string) => {
    if (Number(indexFactorYear) < INDEX_FACTORS_AVAILABLE_FROM_YEAR) {
      showNotification(
        'error',
        t('main:manageMenu.manageWiberaMapping.alerts.errorMinYearInput', {
          year: INDEX_FACTORS_AVAILABLE_FROM_YEAR,
        }),
      );
      return;
    }

    const currentAssetNumber = data?.assetNumber;

    const wiberaMapping = masterConfiguration?.wiberaConfig?.wiberaMapping ?? [];
    const existingAssetMapping = wiberaMapping.find(
      (tmpWiberaMapItem) => tmpWiberaMapItem.assetNumber === currentAssetNumber,
    );
    // change wibera index for one asset
    const filteredWiberaMapping = wiberaMapping.filter(
      (tmpWiberaMapItem) => tmpWiberaMapItem.assetNumber !== currentAssetNumber,
    );
    const updatedMapping = { ...existingAssetMapping, indexFactorYear };

    const updatedWiberaMapping = [...filteredWiberaMapping, updatedMapping];

    await updateWiberaIndex(updatedWiberaMapping as dice.WiberaMapping[]);
  };

  const updateWiberaIndex = async (wiberaMapping: dice.WiberaMapping[]) => {
    const updatedConfiguration = {
      ...masterConfiguration,
      wiberaConfig: {
        ...masterConfiguration?.wiberaConfig,
        wiberaMapping,
      },
    };

    await updateMasterConfiguration(updatedConfiguration as dice.MasterConfiguration);
  };

  const updateYearFactorDb = useDebounce(async (e: ChangeEvent<HTMLInputElement>) => {
    await updateWiberaAssetIndexFactorYear(e.target.value);
  }, 500);

  useReinitializeForm({ defaultValues, reset });

  const options = useMemo(() => createOptions(indexFactors ?? []), [indexFactors]);
  const wiberaCurrentAsset = masterConfiguration?.wiberaConfig?.wiberaMapping?.find(
    (tmpWiberaMapItem) => tmpWiberaMapItem.assetNumber === data?.assetNumber,
  );

  return (
    <div className="justify-content-start align-items-center flex w-full gap-4 p-1">
      <Controller
        control={control}
        name="wiberaIndex"
        render={({ field: { onChange, value, name } }) => (
          <ReactSelect
            className="w-full"
            classNames={{
              control: (_) => '!min-h-0 !rounded-none',
              menuList: (_) => 'text-xs bg-white',
              placeholder: (_) => 'text-xs leading-[1.1]',
              valueContainer: (_) => 'p-0 pl-2 leading-[1.1]',
            }}
            components={{ IndicatorSeparator: () => null }}
            data-testid="SelectInput-wiberaIndex"
            hideSelectedOptions={false}
            menuPortalTarget={document.querySelector<HTMLElement>('#root')}
            name={name}
            onChange={async (selectedOption) => {
              onChange(selectedOption!.value);
              node.level === 0
                ? await updateWiberaIndexCategoryAsset(selectedOption!.value)
                : await updateWiberaIndexAsset(selectedOption!.value);
            }}
            options={options}
            placeholder=""
            value={options.filter((c) => value.value === c.value)}
          />
        )}
      />

      {node.level !== 0 && (
        <div className="relative flex h-full gap-x-1">
          {defaultValues.nbrOfYearsForFactorsCalculation !== yearOriginal && (
            <div className="absolute left-0 top-0 border-r-[16px] border-t-[12px] border-solid border-b-transparent border-l-[#0089EB80] border-r-transparent border-t-[#0089EB80]" />
          )}
          <Tooltip
            content={
              defaultValues.nbrOfYearsForFactorsCalculation !== yearOriginal
                ? t('main:manageMenu.manageWiberaMapping.yearInput.tooltipOriginalYear', {
                    year: yearOriginal,
                  })
                : t('main:manageMenu.manageWiberaMapping.yearInput.tooltipAllowedYear', {
                    year: INDEX_FACTORS_AVAILABLE_FROM_YEAR,
                  })
            }
            placement="top"
          >
            <Controller
              control={control}
              name="nbrOfYearsForFactorsCalculation"
              render={({ field }) => (
                <TextInput
                  {...field}
                  className="h-[40px] min-w-[5rem] text-xs hover:min-w-[5rem] hover:text-xs focus:border-1 focus:outline focus:outline-1 focus:outline-gray-500"
                  disabled={!wiberaCurrentAsset}
                  min={INDEX_FACTORS_AVAILABLE_FROM_YEAR}
                  onBlur={async (e) => {
                    field.onChange(e);
                    await updateYearFactorDb(e);
                  }}
                  pattern="[0-9]*"
                  placeholder={t(
                    'main:manageMenu.manageAssetCostCenters.wiberaCalculationFactorInYears',
                  )}
                  type="number"
                />
              )}
            />
          </Tooltip>
          {defaultValues.nbrOfYearsForFactorsCalculation !== yearOriginal && (
            <Tooltip
              content={t('main:manageMenu.manageWiberaMapping.yearInput.tooltipReset')}
              placement="top"
            >
              <button
                onClick={async () => await updateWiberaAssetIndexFactorYear(yearOriginal ?? '')}
              >
                <span className="appkiticon icon-refresh-outline a-font-16" />
              </button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};
