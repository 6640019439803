import { useTranslation } from '@org/locales';
import { CheckboxCellRenderer } from '@org/ui';

interface AccountTableCheckboxProps {
  allSelected: boolean;
  handleOnChangeAutomaticAll: (isSelected: boolean) => Promise<void>;
}

export const AutomaticSelectAllCheckbox = ({
  allSelected,
  handleOnChangeAutomaticAll,
}: AccountTableCheckboxProps) => {
  const { t } = useTranslation();

  return (
    <div className="m-2">
      <CheckboxCellRenderer
        label={t('common:selectAll')}
        onEdit={handleOnChangeAutomaticAll}
        value={allSelected}
      />
    </div>
  );
};
