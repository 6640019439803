import type { FetcherOptions } from '../../common';
import type { DiceContext } from './diceContext';
import { buildFetcher } from '../../common';

const baseUrl = '/diceApi';

export type DiceFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = FetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
> &
  DiceContext['fetcherOptions'];

export const diceFetch = buildFetcher(baseUrl);

export { type ErrorWrapper } from '../../common';
