import type { GetRowIdParams, IDetailCellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';

import type { APIParams, dice } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { CostUnitAllocationMasterDetail } from '@org/features';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const getRowId = ({ data }: GetRowIdParams) => data?.id;

interface CostUnitAllocationProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

interface DetailCellRendererProps extends IDetailCellRendererParams {
  apiParams: APIParams<'masterConfigurationId'>;
  getRowItems: (rowId: string) => { items: dice.AllocationKeyConfigDTO[]; currentRowIndex: number };
  data: dice.AllocationKeyConfigDTO;
}

const DetailCellRenderer = (params: DetailCellRendererProps) => {
  const { data, apiParams, api } = params;
  return (
    <CostUnitAllocationMasterDetail
      api={api}
      apiParams={apiParams}
      data={data}
    />
  );
};

const CostUnitAllocationView: FC<CostUnitAllocationProps> = (props: CostUnitAllocationProps) => {
  const {
    autoGroupColumnDef,
    columnDefs,
    tableRef,
    defaultColDef,
    detailCellRendererParams,
    onGridReady,
  } = props;

  return (
    <div className="flex h-full flex-col">
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        detailCellRenderer={DetailCellRenderer}
        detailCellRendererParams={detailCellRendererParams}
        detailRowHeight={525}
        getRowId={getRowId}
        masterDetail
        onGridReady={onGridReady}
        ref={tableRef}
        singleClickEdit
      />
    </div>
  );
};

export const CostUnitAllocationPage = bindControllers(CostUnitAllocationView, [useController]);

CostUnitAllocationPage.displayName = 'CostUnitAllocationPage';
