import { useParams } from 'react-router-dom';

import { ManualCorrectionsBabPage as Page } from '@org/pages';

export const ManualCorrectionsBab = () => {
  const {
    configId: masterConfigurationId = '',
    yearId = '',
    publicFacilityId = '',
    clientId = '',
    type = '',
  } = useParams<{
    clientId: string;
    yearId: string;
    configId: string;
    publicFacilityId: string;
    type: string;
  }>();

  return (
    <Page
      clientId={clientId}
      masterConfigurationId={masterConfigurationId}
      publicFacilityId={publicFacilityId}
      type={type}
      yearId={yearId}
    />
  );
};
