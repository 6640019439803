import type * as reactQuery from '@tanstack/react-query';
import { useCallback } from 'react';

import type { APIParams } from '../..';
import type {
  ImportAccountPlanError,
  ImportAccountPlanVariables,
  ImportAssetRequest,
  ImportAssetsError,
  ImportAssetsVariables,
  ImportFileResponse,
  ImportJournalError,
  ImportJournalRequest,
  ImportJournalVariables,
  ImportLabelsError,
  ImportLabelsVariables,
  ImportLedgerAccountError,
  ImportLedgerAccountRequest,
  ImportLedgerAccountVariables,
  ImportRequest,
  ImportSaveFilterDataTO,
} from '../dice';
import {
  useImportAccountPlan,
  useImportAssets,
  useImportJournal,
  useImportLabels,
  useImportLedgerAccount,
  useSaveFilterData,
} from '../dice';

export type ImportErrorType = ImportLabelsError | ImportAssetsError | ImportJournalError;

type ImportLabelsOptionsType = Omit<
  reactQuery.UseMutationOptions<ImportFileResponse, ImportLabelsError, ImportLabelsVariables>,
  'mutationFn'
>;

type ImportAssetsOptionsType = Omit<
  reactQuery.UseMutationOptions<ImportFileResponse, ImportAssetsError, ImportAssetsVariables>,
  'mutationFn'
>;

type ImportJournalOptionsType = Omit<
  reactQuery.UseMutationOptions<ImportFileResponse, ImportJournalError, ImportJournalVariables>,
  'mutationFn'
>;

type ImportAccountPlansType = Omit<
  reactQuery.UseMutationOptions<
    ImportFileResponse,
    ImportAccountPlanError,
    ImportAccountPlanVariables
  >,
  'mutationFn'
>;

type ImportLedgerAccountsOptionsType = Omit<
  reactQuery.UseMutationOptions<
    ImportFileResponse,
    ImportLedgerAccountError,
    ImportLedgerAccountVariables
  >,
  'mutationFn'
>;

interface UseImportFilesParams extends APIParams<'yearId'> {
  globalOptions?: ImportLabelsOptionsType | ImportAssetsOptionsType | ImportJournalOptionsType;
  importLabelsOptions?: ImportLabelsOptionsType;
  importAssetsOptions?: ImportAssetsOptionsType;
  importJournalsOptions?: ImportJournalOptionsType;
  importAccountPlansOptions?: ImportAccountPlansType;
  importLedgerAccountsOptions?: ImportLedgerAccountsOptionsType;
}

export const createAssetFilterQuery = () => ({
  assetClasses: [],
  assetSubNumbers: [],
  costCenterSetKeys: [],
  depreciationAreaKeys: [],
  depreciationBooks: [],
  depreciationKeys: [],
  numbers: [],
});

export const createJournalFilterQuery = () => ({
  ledgerKeys: [],
  recordTypeKeys: [],
  valueTypeKeys: [],
  versionKeys: [],
});

export const createLedgerAccountFilterQuery = () => ({
  assetClasses: [],
  costCenterSetss: [],
  depreciationAreas: [],
  depreciationBooks: [],
  depreciationKeys: [],
  ledgers: [],
  numbers: [],
  pcaLedgers: [],
  pcaRecordTypes: [],
  pcaVersions: [],
  recordTypes: [],
  subnumbers: [],
  versions: [],
});

export const useCamsImportData = ({
  yearId,
  globalOptions,
  importAssetsOptions,
  importLabelsOptions,
  importAccountPlansOptions,
  importJournalsOptions,
  importLedgerAccountsOptions,
}: UseImportFilesParams) => {
  const { mutateAsync: importLabelsCall, isPending: importLabelsCallIsLoading } = useImportLabels({
    ...globalOptions,
    ...importLabelsOptions,
  });

  const { mutateAsync: importAssetsCall, isPending: importAssetsCallIsLoading } = useImportAssets({
    ...globalOptions,
    ...importAssetsOptions,
  });

  const { mutateAsync: importJournalsCall, isPending: importJournalsCallIsLoading } =
    useImportJournal({
      ...globalOptions,
      ...importJournalsOptions,
    });

  const { mutateAsync: importAccountPlanCall, isPending: importAccountPlanIsLoading } =
    useImportAccountPlan({
      ...globalOptions,
      ...importAccountPlansOptions,
    });

  const { mutateAsync: importLedgerAccountCall, isPending: importLedgerAccountIsLoading } =
    useImportLedgerAccount({
      ...globalOptions,
      ...importLedgerAccountsOptions,
    });

  const { mutateAsync: importAutosaveCall, isPending: importAutosaveCallIsLoading } =
    useSaveFilterData();

  const isLoading =
    importLabelsCallIsLoading ||
    importAssetsCallIsLoading ||
    importJournalsCallIsLoading ||
    importAccountPlanIsLoading ||
    importLedgerAccountIsLoading ||
    importAutosaveCallIsLoading;

  const importLabels = useCallback(
    async (params: Omit<ImportRequest, 'yearId'>) =>
      await importLabelsCall({
        body: {
          ...params,
          yearId,
        },
      }),
    [importLabelsCall, yearId],
  );

  const importAssets = useCallback(
    async (params: Omit<ImportAssetRequest, 'yearId'>) =>
      await importAssetsCall({
        body: {
          ...params,
          yearId,
        },
      }),
    [importAssetsCall, yearId],
  );

  const importJournals = useCallback(
    async (params: Omit<ImportJournalRequest, 'yearId'>) =>
      await importJournalsCall({
        body: {
          ...params,
          yearId,
        },
      }),
    [importJournalsCall, yearId],
  );

  const importAccountPlans = useCallback(
    async (params: Omit<ImportRequest, 'yearId'>) =>
      await importAccountPlanCall({
        body: {
          ...params,
          yearId,
        },
      }),
    [importAccountPlanCall, yearId],
  );

  const importLedgerAccounts = useCallback(
    async (params: Omit<ImportLedgerAccountRequest, 'yearId'>) =>
      await importLedgerAccountCall({
        body: {
          ...params,
          yearId,
        },
      }),
    [importLedgerAccountCall, yearId],
  );

  const importAutosave = useCallback(
    async (params: Omit<ImportSaveFilterDataTO, 'yearId'>) =>
      await importAutosaveCall({
        body: {
          ...params,
          yearId,
        },
      }),
    [importAutosaveCall, yearId],
  );

  return {
    importAccountPlans,
    importAssets,
    importJournals,
    importLabels,
    importLedgerAccounts,
    importAutosave,
    isLoading,
  };
};
