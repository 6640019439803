import type { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

interface UseColumnsProps {
  handleDeleteModal: (props: ButtonCellRendererProps) => unknown;
  apiParams: APIParams<'masterConfigurationId'>;
}

export const useColumns = ({ handleDeleteModal, apiParams }: UseColumnsProps) => {
  const { getCostCenterLongName } = aggregated.useCostCenters(apiParams);
  const { t } = useTranslation();
  return useMemo(
    () =>
      [
        {
          colId: 'costCenterShortName',
          field: 'costCenterShortName',
          headerName: t('main:manageMenu.manageManualCorrectionsInterest.tableColumns.costCenter'),
          valueFormatter: ({ value }) => getCostCenterLongName(value),
        },
        {
          colId: 'rowId',
          field: 'rowId',
          headerName: t('main:manageMenu.manageManualCorrectionsInterest.tableColumns.field'),
        },
        {
          colId: 'withdrawCapitalName',
          field: 'withdrawCapitalName',
          headerName: t(
            'main:manageMenu.manageManualCorrectionsInterest.tableColumns.withdrawCapital',
          ),
        },
        {
          cellEditor: 'numeric',
          colId: 'differenceAmount',
          editable: true,
          field: 'differenceAmount',
          headerName: t('main:manageMenu.manageManualCorrectionsInterest.tableColumns.correction'),
          type: 'price',
        },
        {
          cellRenderer: 'multipleButtons',
          cellRendererParams: {
            buttonParams: [
              {
                icon: 'icon-delete-outline',
                onClick: (props: ButtonCellRendererProps) => handleDeleteModal(props),
                size: 'iconButton',
              },
            ],
            className: 'flex mr-[-4px]',
          },
          colId: 'actions',
          filter: null,
          headerName: t('common:actions'),
          maxWidth: 60,
        },
      ] satisfies ColDef<griddy.ManualCorrectionInterest>[],
    [getCostCenterLongName, handleDeleteModal, t],
  );
};
