import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import { ArrowUpOnSquareStackIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';

import type { CorrectAny } from '@org/models';
import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { Tooltip } from '@org/ui';
import { formatToSimpleDate } from '@org/utils';

interface UseColumnsProps {
  filterType: string;
  handleOnRestoreBackup: (entity: dice.MasterConfigurationEntityDTO) => void;
  handleOnPromoteVariant: (entity: dice.MasterConfigurationEntityDTO) => void;
  handleOnDeleteMasterConfig: (entity: dice.MasterConfigurationEntityDTO) => void;
  LinkComponent: React.ComponentType<CorrectAny>;
  getLinkProps: (clientName: string) => Record<string, unknown>;
}

export const useColumns = ({
  filterType,
  handleOnRestoreBackup,
  handleOnPromoteVariant,
  handleOnDeleteMasterConfig,
  LinkComponent,
  getLinkProps,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          colId: 'type',
          editable: true,
          field: 'type',
          flex: 1,
          hide: true,
        },
        {
          colId: 'name',
          editable: true,
          field: 'name',
          flex: 1,
          headerName: t('main:manageMenu.configurationPage.name'),
        },
        {
          colId: 'description',
          editable: true,
          field: 'description',
          flex: 1,
          headerName: t('main:manageMenu.configurationPage.description'),
        },
        {
          colId: 'lastUpdateIsoDateTime',
          field: 'lastUpdateIsoDateTime',
          flex: 1,
          headerName: t('main:manageMenu.configurationPage.lastUpdated'),
          valueFormatter: ({ value }) =>
            value ? formatToSimpleDate(new Date(value), 'MMM dd, yyyy HH:mm') : '',
        },

        {
          cellRenderer: (params: ICellRendererParams) => {
            const { node } = params;

            if (node?.data?.id) {
              return (
                <div className="flex space-x-2">
                  <Tooltip
                    className="max-w-sm"
                    content={t('main:manageMenu.configurationPage.tooltips.openConfiguration')}
                  >
                    <LinkComponent
                      {...getLinkProps(node.data.id)}
                      className="text-black hover:text-black"
                    >
                      <EyeIcon className="h-5 w-5 cursor-pointer" />
                    </LinkComponent>
                  </Tooltip>
                  <Tooltip
                    className="max-w-sm"
                    content={t('main:manageMenu.configurationPage.tooltips.promoteToMaster')}
                  >
                    <ArrowUpOnSquareStackIcon
                      className="h-5 w-5 cursor-pointer"
                      onClick={() => {
                        if (filterType === 'BACKUP') {
                          handleOnRestoreBackup(node.data);
                        }

                        if (filterType === 'VARIANT') {
                          handleOnPromoteVariant(node.data);
                        }
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    className="max-w-sm"
                    content={t('main:manageMenu.configurationPage.tooltips.deleteConfiguration')}
                  >
                    <TrashIcon
                      className="h-5 w-5 cursor-pointer"
                      onClick={() => {
                        handleOnDeleteMasterConfig(node.data);
                      }}
                    />
                  </Tooltip>
                </div>
              );
            }
          },
          colId: 'actions',
          editable: false,
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          maxWidth: 140,
        },
      ] satisfies (
        | ColDef<dice.MasterConfigurationEntityDTO>
        | ColGroupDef<dice.MasterConfigurationEntityDTO>
      )[],
    [
      LinkComponent,
      filterType,
      getLinkProps,
      handleOnDeleteMasterConfig,
      handleOnPromoteVariant,
      handleOnRestoreBackup,
      t,
    ],
  );
};
