/**
 * "Returns a function that returns the Cost Type Account Name label."
 */
import type { ValueFormatterParams } from 'ag-grid-community';

import { t } from '@org/locales';
import { NOT_AVAILABLE } from '@org/utils';

export const getCostTypeAccountLabel = (params: ValueFormatterParams) =>
  params.value === NOT_AVAILABLE
    ? t('main:reportPanels.babReport.alerts.costTypeNameMissing')
    : params.value;
