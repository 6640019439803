import type { FC } from 'react';
import { memo } from 'react';

import classNames from 'classnames';

export type ConfigItemProps = {
  selected?: boolean;
  disabled?: boolean;
  statusColorClassName?: string;
  withCircle?: boolean;
} & React.JSX.IntrinsicElements['div'];

export const ConfigItemMemo: FC<ConfigItemProps> = (props) => {
  const {
    className,
    selected,
    disabled,
    statusColorClassName,
    withCircle = true,
    children,
    ...otherProps
  } = props;

  return (
    <div
      className={classNames(
        `menu-item config-item`,
        {
          disabled,
          selected,
        },
        className,
      )}
      {...otherProps}
    >
      {withCircle && <span className={classNames(`item-icon a-mr-5`, statusColorClassName)} />}
      <span className="item-name">{children}</span>
    </div>
  );
};

export const ConfigItem = memo(ConfigItemMemo);
