import { useCallback, useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';
import { aggregated } from '@org/query';
import {
  setDefaultWorkingCapitalRateInMasterConfiguration,
  setImputedWorkingCapitalsInMasterConfiguration,
  setWorkingCapitalEnabledInMasterConfiguration,
} from '@org/utils';

type WorkingCapital = griddy.WorkingCapital;

export const useImputedWorkingCapital = (apiProps: APIParams<'masterConfigurationId'>) => {
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiProps);

  const imputedWorkingCapitalConfig = useMemo(
    () => masterConfiguration?.imputedWorkingCapitalConfig,
    [masterConfiguration?.imputedWorkingCapitalConfig],
  );

  const updateEnableWorkingCapital = useCallback(
    async (enabled: boolean) => {
      await updateMasterConfiguration(
        setWorkingCapitalEnabledInMasterConfiguration(masterConfiguration!, enabled),
      );
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const updateDefaultWorkingCapitalRate = useCallback(
    async (newWorkingCapitalRate: number) => {
      await updateMasterConfiguration(
        setDefaultWorkingCapitalRateInMasterConfiguration(
          masterConfiguration!,
          newWorkingCapitalRate,
        ),
      );
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const updateWorkingCapitals = useCallback(
    async (newWorkingCapitals: WorkingCapital[]) => {
      await updateMasterConfiguration(
        setImputedWorkingCapitalsInMasterConfiguration(masterConfiguration!, newWorkingCapitals),
      );
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  return {
    imputedWorkingCapitalConfig,
    updateDefaultWorkingCapitalRate,
    updateEnableWorkingCapital,
    updateWorkingCapitals,
  };
};
