import styled from 'styled-components';

export const ConfigPagePanelWrapper = styled.div`
  padding: 15px;
  height: 100%;
  width: 100%;
  margin: 0;
  #config-content {
    height: 100%;
    overflow-x: auto;
  }
  justify-content: center;
  .disableEdit {
    pointer-events: none;
    opacity: 0.8;
  }
  .a-panel-content {
    height: 85%;
  }
`;
