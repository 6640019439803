import { useCallback } from 'react';

import type { APIParams } from '../..';
import type { AssetCategoryConfigDTO } from '../dice';
import { useGetAllAssetCategoryConfigs, useUpdateAssetCategoryConfig } from '../dice';

export interface UseAssetsConfigsParams extends APIParams<'masterConfigurationId' | 'yearId'> {}

export function useAssetConfigs({
  yearId,
  masterConfigurationId: masterConfigId,
}: UseAssetsConfigsParams) {
  const { mutateAsync: mutatePut } = useUpdateAssetCategoryConfig();

  const {
    data: { assetCategoryConfigs = [] } = {},
    error,
    isLoading,
  } = useGetAllAssetCategoryConfigs(
    {
      queryParams: {
        masterConfigId,
        yearId,
      },
    },
    {
      retry: false,
    },
  );

  const updateAssetConfig = useCallback(
    async (categoryConfigs: AssetCategoryConfigDTO[]) => {
      await mutatePut({
        body: {
          assetCategoryConfigs: categoryConfigs,
          masterConfigId,
          yearId,
        },
      });
    },
    [mutatePut, masterConfigId, yearId],
  );

  return { assetCategoryConfigs, error, isLoading, updateAssetConfig };
}
