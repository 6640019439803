import PropTypes from 'prop-types';
import styled from 'styled-components';

import globalConfig from '../../config';
import Footer from './Footer';
import Header from './Header';

const StyledLayout = styled.div`
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
`;

const Layout = ({ children }) => (
  <StyledLayout id="layout">
    <Header title={globalConfig.APP_NAME} />
    {children}
    <Footer legalNotice />
  </StyledLayout>
);

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;
