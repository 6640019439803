import type { MutationOptions } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import type { APIParams } from '../..';
import type {
  Asset,
  AssetConfigMappingResponseObject,
  UpdateConfigToAssetVariables,
} from '../dice';
import {
  useDiceContext,
  useGetAllAssets1,
  useMapConfigToAssets,
  useResetManuallySetBabCCForAssets,
  useUpdateConfigToAsset,
} from '../dice';

export interface UseAssetsParams extends APIParams<'masterConfigurationId' | 'yearId'> {}

export function useAssets({ yearId, masterConfigurationId: masterConfigId }: UseAssetsParams) {
  const queryClient = useQueryClient();
  const { queryKeyFn } = useDiceContext();
  const postSendRef = useRef(false);
  const { mutate: mutatePost } = useMapConfigToAssets();

  const getAllAssetsKey = queryKeyFn({
    operationId: 'getAllAssets1',
    path: '/asset-cc-config',
    variables: {
      queryParams: {
        masterConfigId,
        yearId,
      },
    },
  });

  const updateQueryClientAssets = async (
    arg: UpdateConfigToAssetVariables | AssetConfigMappingResponseObject,
  ) => {
    const assets =
      (arg as UpdateConfigToAssetVariables)?.body?.assets ??
      (arg as AssetConfigMappingResponseObject)?.assets;
    await queryClient.cancelQueries({
      queryKey: getAllAssetsKey,
    });

    const { assets: previousAssets } =
      queryClient.getQueryData<AssetConfigMappingResponseObject>(getAllAssetsKey) ?? {};

    if (!previousAssets) {
      return;
    }

    const newAssets = previousAssets.map((asset) => {
      const updatedAsset = assets?.find(
        (updatedRow) => updatedRow.assetNumber === asset.assetNumber,
      );

      return updatedAsset ?? asset;
    });

    queryClient.setQueryData(getAllAssetsKey, {
      assets: newAssets,
      masterConfigId,
      yearId,
    });

    return { previousAssets };
  };

  const optimisticUpdateParams: MutationOptions<
    AssetConfigMappingResponseObject,
    unknown,
    UpdateConfigToAssetVariables | AssetConfigMappingResponseObject
  > = {
    onError: (_, __, context: any) => {
      const previousAssets = context?.previousAssets as Asset[] | undefined;
      if (previousAssets) {
        queryClient.setQueryData(getAllAssetsKey, {
          assets: previousAssets,
          masterConfigId,
          yearId,
        });
      }
    },
    onSuccess: updateQueryClientAssets,
  };

  const { mutateAsync: mutateReset } = useResetManuallySetBabCCForAssets(optimisticUpdateParams);

  const { mutateAsync: mutatePut } = useUpdateConfigToAsset(optimisticUpdateParams);

  const {
    data: { assets = [] } = {},
    error,
    isLoading,
    isError,
  } = useGetAllAssets1(
    {
      queryParams: {
        masterConfigId,
        yearId,
      },
    },
    {
      retry: false,
    },
  );

  useEffect(() => {
    if (isError && !postSendRef.current) {
      // TODO: Remove this once fixed or tested in DICE
      // if () {
      //   queryClient.setQueryData(getAllAssetsKey, {
      //     assets: [],
      //     masterConfigId,
      //     yearId,
      //   });
      // } else {
      postSendRef.current = true;
      mutatePost({
        body: {
          masterConfigId,
          yearId,
        },
      });
    }
  }, [getAllAssetsKey, isError, masterConfigId, mutatePost, queryClient, yearId]);

  const updateAssets = useCallback(
    async (updatedRows: Asset[]) => {
      await mutatePut({
        body: {
          assets: updatedRows,
          masterConfigId,
          yearId,
        },
      });
    },
    [masterConfigId, mutatePut, yearId],
  );

  const resetBabCCByAssetIds = useCallback(
    async (assetIds: string[]) => {
      await mutateReset({
        body: {
          assetIds,
          masterConfigId,
          yearId,
        },
      });
    },
    [masterConfigId, mutateReset, yearId],
  );

  return { assets, error, isLoading, resetBabCCByAssetIds, updateAssets };
}
