import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { ButtonCellRenderer } from '@org/ui';
import { getCellClassesForGroups } from '@org/utils';

import { ACCOUNT_STATUSES } from '../../utils';

interface UseColumnsProps {
  handleDeleteModal: (props: ButtonCellRendererProps) => void;
  addNewRow: (props: ButtonCellRendererProps) => void;
  costCenterOptions?: { label?: string; value?: string }[];
  getCostCenterLongName: (id: string) => string;
  selectedAccountStatus: Record<string, boolean>;
  oneToManyList: Record<string, boolean>;
}

interface ManualAccountAllocation {
  clientsCostCenterName: number;
  costCenterShortName: string;
  percentage: number;
  source: string;
}

export const useColumns = ({
  handleDeleteModal,
  getCostCenterLongName,
  addNewRow,
  costCenterOptions,
  selectedAccountStatus,
  oneToManyList,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();

  const someHasOneToMany = Object.values(oneToManyList).some(Boolean);

  return useMemo(
    () =>
      [
        {
          cellRenderer: 'agGroupCellRenderer',
          colId: 'source',
          field: 'source' as keyof ManualAccountAllocation,
          cellClass: getCellClassesForGroups,
          hide: true,
          rowGroup: true,
          valueFormatter: (params) =>
            params?.value === ACCOUNT_STATUSES.NA
              ? t('main:manageMenu.manageWithdrawCapital.labelManualAllocation')
              : t('main:manageMenu.manageWithdrawCapital.labelAutomaticAllocation'),
        },

        {
          cellClass: getCellClassesForGroups,
          colId: 'clientsCostCenterName',
          field: 'clientsCostCenterName' as keyof ManualAccountAllocation,
          rowGroup: true,
          valueFormatter: (params) =>
            params?.value === ACCOUNT_STATUSES.NA
              ? t('common:noClientCostCenterNumber')
              : params.value,
          hide: true,
        },

        {
          cellEditor: 'select',
          cellClass: getCellClassesForGroups,
          cellEditorParams: {
            options: costCenterOptions,
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          cellEditorPopup: true,
          valueFormatter: (params) => getCostCenterLongName(params.data?.costCenterShortName ?? ''),
          colId: 'costCenterShortName',
          editable: (params) => {
            const isManual = params?.data?.clientsCostCenterName.toString() === ACCOUNT_STATUSES.NA;

            if (!params.node.group && isManual && params.node.data?.costCenterShortName === '') {
              return true;
            }

            return false;
          },
          field: 'costCenterShortName',
          flex: 4,
          headerName: t('common:imputedCostCenterName'),
        },
        {
          colId: 'percentage',
          cellClass: getCellClassesForGroups,

          hide: !(someHasOneToMany || selectedAccountStatus.isManual),
          field: 'percentage',
          valueFormatter: (params) => {
            const isManual = params?.data?.clientsCostCenterName.toString() === ACCOUNT_STATUSES.NA;

            if (isManual || params.node?.uiLevel === 1 || params.node?.uiLevel === 2) {
              return params.value;
            }

            return '';
          },

          editable: (params) => {
            if (
              !params.node.group &&
              params?.data?.clientsCostCenterName.toString() === ACCOUNT_STATUSES.NA
            ) {
              return true;
            }

            // TODO: this will be enabled once
            // the backend is ready to handle updating percentages for one-to-many automatic allocations

            // if (!params.node.group && oneToManyList?.[params?.data?.clientsCostCenterName ?? '']) {
            //   return true;
            // }

            return false;
          },
        },
        {
          colId: 'actions',
          cellClass: getCellClassesForGroups,
          hide: !selectedAccountStatus.isManual,
          maxWidth: 60,
          cellRenderer: (params: ICellRendererParams) => {
            const isManual =
              params?.data?.clientsCostCenterName.toString() === ACCOUNT_STATUSES.NA ||
              params?.node?.key === ACCOUNT_STATUSES.NA;
            const hasVisibleGroupParent = params.node?.uiLevel === 1;

            if (params.node.group && isManual && hasVisibleGroupParent) {
              return (
                <ButtonCellRenderer
                  {...params}
                  disabled={false}
                  gridProps={params}
                  icon="icon-plus-fill"
                  onClick={(props: unknown) => addNewRow(props as ButtonCellRendererProps)}
                />
              );
            }

            if (!params.node.group && isManual) {
              return (
                <ButtonCellRenderer
                  {...params}
                  disabled={false}
                  gridProps={params}
                  icon="icon-delete-outline"
                  onClick={(props: unknown) => handleDeleteModal(props as ButtonCellRendererProps)}
                />
              );
            }
          },

          editable: false,
          flex: 1,
          floatingFilterComponent: 'button',
          suppressFloatingFilterButton: true,
        },
      ] satisfies (ColDef<ManualAccountAllocation> | ColGroupDef<ManualAccountAllocation>)[],
    [
      costCenterOptions,
      t,
      someHasOneToMany,
      selectedAccountStatus.isManual,
      getCostCenterLongName,
      addNewRow,
      handleDeleteModal,
    ],
  );
};
