import type { FC } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const View: FC<ViewProps> = (props) => {
  const {
    columnDefs,
    agGridProps,
    agGridRef,
    defaultColDef,
    isCostCenterGroupMissing,
    isCostUnitGroupMissing,
    getRowId,
    modal,
    handleChange,
    handleAddGroup,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col gap-2">
      {(isCostCenterGroupMissing || isCostUnitGroupMissing) && (
        <div className="flex justify-end gap-2">
          {isCostCenterGroupMissing && (
            <Button
              onClick={handleAddGroup('COST_CENTER')}
              size="sm"
              variant="primary"
            >
              {t('main:manageMenu.manageTechnicalData.createCostCenterGroup')}
            </Button>
          )}
          {isCostUnitGroupMissing && (
            <Button
              onClick={handleAddGroup('COST_UNIT')}
              size="sm"
              variant="primary"
            >
              {t('main:manageMenu.manageTechnicalData.createCostUnitGroup')}
            </Button>
          )}
        </div>
      )}
      <BaseEditTable<dice.TechnicalDataDTO>
        {...agGridProps}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        enableGroupEdit
        getRowId={getRowId}
        groupAllowUnbalanced={false}
        groupDefaultExpanded={2}
        groupDisplayType="custom"
        onCellEditingStopped={handleChange}
        ref={agGridRef}
        singleClickEdit
      />
      {modal}
    </div>
  );
};

export const TechnicalData = bindControllers(View, [useController]);
TechnicalData.displayName = 'TechnicalData';
