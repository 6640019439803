import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ModalContentProps } from '..';

export const FinalizeConfigurationModalContent = ({
  disclosureState,
  yearId,
  entity,
}: ModalContentProps) => {
  const { t } = useTranslation();
  const { onClose } = disclosureState;

  const { finalizeConfig, finalizeConfigMutation } =
    aggregated.useMasterConfigurationByYearCalculation({
      yearCalculationId: yearId,
    });

  const handleOnDelete = async () => {
    try {
      await finalizeConfig(entity);
      onClose();
    } catch {
      showNotification('warning', t('main:clients.clientsOverviewPanel.alerts.warning.general'));
    }
  };

  return (
    <>
      <ModalHeader>
        {t('common:contextMenu.modalFinalize.title', {
          name: entity?.name,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>{t('common:contextMenu.modalFinalize.label')}</ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common:close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={finalizeConfigMutation.isPending}
          onClick={handleOnDelete}
          size="md"
          variant="primary"
        >
          {t('common:contextMenu.modalFinalize.btnFinalize')}
        </Button>
      </ModalFooter>
    </>
  );
};
