/* eslint-disable jsx-a11y/no-autofocus */
import type { FocusableProps, PressEvents } from '@react-types/shared';
import type { ElementType, ForwardedRef, ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import React, { forwardRef, useMemo } from 'react';

import { useButton } from '@react-aria/button';
import { FocusRing, useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverridableComponent, OverrideProps } from '../../types';
import { dataFocusVisibleClasses, mergeRefs } from '../../utils';

const buttonVariants = tv({
  base: [
    'tap-highlight-transparent',
    'group',
    'relative',
    'z-0',
    'box-border',
    'inline-flex',
    'min-w-max',
    'cursor-pointer',
    'select-none',
    'appearance-none',
    'items-center',
    'justify-center',
    'gap-2',
    'overflow-hidden',
    'whitespace-nowrap',
    'rounded-sm',
    'border',
    'border-solid',
    'px-3',
    'text-center',
    'font-medium',
    'uppercase',
    'subpixel-antialiased',
    'outline-none',
    'focus:outline-none',
    // focus ring
    ...dataFocusVisibleClasses,
  ],
  compoundVariants: [
    {
      class: '!border-gray-300 !bg-gray-300 !text-gray-400',
      isDisabled: true,
      variant: 'solid',
    },
    {
      class:
        'min-w-0 !border-none bg-transparent text-orange-default hover:!bg-orange-default hover:text-white active:!bg-orange-default active:text-white',
      variant: 'transparent',
    },
    {
      class:
        '!inline-flex h-[2.125rem] min-w-0 !border-none bg-transparent !px-0 py-[10px] text-orange-default hover:!bg-orange-default hover:text-white active:!bg-orange-default active:text-white',
      variant: 'cellRenderer',
    },
  ],
  defaultVariants: {
    isDisabled: false,
    size: 'md',
    variant: 'solid',
  },
  variants: {
    color: {
      primary:
        '!border-orange-default bg-orange-default text-white hover:bg-orange-default/90 active:bg-orange-default/80',
      secondary:
        '!border-orange-default bg-transparent text-orange-default hover:bg-orange-default/20 active:bg-orange-default/10',
    },
    disableAnimation: {
      false:
        'transition-transform-colors-opacity data-[pressed=true]:scale-[0.97] motion-reduce:transition-none',
      true: '!transition-none',
    },
    fullWidth: {
      true: 'w-full',
    },
    isDisabled: {
      true: 'pointer-events-none opacity-85',
    },
    size: {
      lg: 'h-9 px-5 text-sm',
      md: 'h-[34px] text-xs',
      sm: 'h-6 text-xs',
    },
    variant: {
      cellRenderer:
        '!inline-flex h-[2.125rem] min-w-0 !border-none bg-transparent !px-0 py-[10px] text-orange-default hover:!bg-orange-default hover:text-white active:!bg-orange-default active:text-white',
      solid: '',
      transparent: '!border-none bg-transparent',
    },
  },
});

export interface ButtonTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'button',
> {
  props: AdditionalProps &
    PressEvents &
    FocusableProps &
    VariantProps<typeof buttonVariants> & {
      /** Whether the element should receive focus on render. */
      autoFocus?: boolean;
      /** Whether the button is disabled. */
      isDisabled?: boolean;
      /**
       * The button start content.
       */
      startContent?: ReactNode;
      /**
       * The button end content.
       */
      endContent?: ReactNode;
      /**
       * Whether the button is pending action.
       * @default false
       */
      isPending?: boolean;
      href?: string;
    };
  defaultComponent: DefaultComponent;
}

export type ButtonProps<
  RootComponent extends React.ElementType = ButtonTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<ButtonTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalButtonProps<
  RootComponent extends ElementType = ButtonTypeMap['defaultComponent'],
  AdditionalProps = {},
> = InternalComponentProps<ButtonTypeMap<AdditionalProps, RootComponent>>;

function disablePendingProps<T extends ButtonProps>(props: T) {
  // Don't allow interaction while isPending is true
  if (props.isPending) {
    props.onPress = undefined;
    props.onPressStart = undefined;
    props.onPressEnd = undefined;
    props.onPressChange = undefined;
    props.onPressUp = undefined;
    props.onKeyDown = undefined;
    props.onKeyUp = undefined;
    props.onClick = undefined;
    props.href = undefined;
  }
  return props;
}

export const Button = forwardRef(
  <BaseComponentType extends React.ElementType = ButtonTypeMap['defaultComponent']>(
    inProps: InternalButtonProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const {
      as: Component = 'button',
      startContent,
      endContent,
      children,
      autoFocus = false,
      size = 'md',
      color = 'primary',
      fullWidth = false,
      disableAnimation = false,
      isDisabled = false,
      variant = 'solid',
      className,
      type = 'button',
      onClick,
      isPending = false,
      onPress,
      ...otherProps
    } = disablePendingProps(inProps);

    const domRef = React.useRef<HTMLButtonElement>(null);

    const styles = useMemo(
      () =>
        buttonVariants({
          className,
          color,
          disableAnimation,
          fullWidth,
          isDisabled,
          size,
          variant,
        }),
      [size, color, variant, fullWidth, isDisabled, disableAnimation, className],
    );

    const { isFocusVisible, focusProps, isFocused } = useFocusRing({ autoFocus });
    const { isHovered, hoverProps } = useHover({ isDisabled });

    const { buttonProps, isPressed } = useButton(
      {
        ...otherProps,
        elementType: typeof Component === 'string' ? (Component as ElementType) : 'button',
        isDisabled,
        onClick,
        onPress,
        type,
      },
      domRef,
    );

    return (
      <FocusRing autoFocus={autoFocus}>
        <Component
          {...mergeProps(buttonProps, focusProps, hoverProps)}
          aria-disabled={isDisabled || isPending ? 'true' : undefined}
          className={styles}
          data-disabled={isDisabled}
          data-focus={isFocused}
          data-focus-visible={isFocusVisible}
          data-hover={isHovered}
          data-pressed={isPressed}
          ref={mergeRefs(domRef, ref)}
          type={type}
        >
          {startContent}
          {children}
          {endContent}
        </Component>
      </FocusRing>
    );
  },
) as OverridableComponent<ButtonTypeMap>;
