import type { ITooltipParams } from 'ag-grid-community';
import type { FC } from 'react';
import { memo } from 'react';

import { isNumber, isString } from '@org/utils';

export const TooltipCellRenderer: FC<ITooltipParams> = memo((props) => {
  const { value } = props;

  if (!isString(value) && !isNumber(value)) {
    return null;
  }

  return <div className="rounded-sm bg-black/70 p-2 text-white">{value}</div>;
});
