import { useState } from 'react';

import { Input } from '@nextui-org/react';

import { useKeys } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { PublicFacilitiesModalContentProps } from '..';
import { isNameDuplicate } from '../utils';

export const AddNewPublicFacilityModalContent = ({
  disclosureState,
  createPublicFacility,
  sortedPublicFacilities,
}: PublicFacilitiesModalContentProps) => {
  const { t } = useTranslation();

  const [newPublicFacilityName, setNewPublicFacilityName] = useState('');

  const { isOpen, onClose } = disclosureState;

  const duplicatedPublicFacility = isNameDuplicate(
    newPublicFacilityName,
    sortedPublicFacilities?.map((item) => item.name?.toLowerCase() ?? '') ?? [],
  );

  const handleCloseModal = () => {
    onClose?.();
    setNewPublicFacilityName('');
  };

  useKeys('Enter', async () => {
    await createNewPublicFacility();
  });

  const createNewPublicFacility = async () => {
    if (!newPublicFacilityName) {
      return;
    }

    const isValid = isOpen && !duplicatedPublicFacility;

    if (!isValid) {
      return;
    }

    try {
      await createPublicFacility({ name: newPublicFacilityName });
      handleCloseModal();
    } catch {
      showNotification('warning', t('main:clients.clientsOverviewPanel.alerts.warning.general'));
    }
  };

  return (
    <>
      <ModalHeader>{t('main:clients.publicFacilitiesOverviewPanel.header')}</ModalHeader>
      <ModalBody>
        <Input
          label={t('main:clients.publicFacilitiesOverviewPanel.lblName')}
          onChange={({ target: { value } }) => {
            setNewPublicFacilityName(value);
          }}
          value={newPublicFacilityName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={handleCloseModal}
          size="md"
          variant="secondary"
        >
          {t('common:close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={duplicatedPublicFacility || !newPublicFacilityName}
          onClick={createNewPublicFacility}
          size="md"
          variant="primary"
        >
          {t('common:create')}
        </Button>
      </ModalFooter>
    </>
  );
};
