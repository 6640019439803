import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface CostCenterTableCheckboxProps {
  data: { shortName: string };
  value: string | number;
  costCenterDistributionMap: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'];
  onSelectionChanged: (
    selection: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'],
  ) => Promise<void>;
}

export const CostCenterTableCheckbox = ({
  data,
  value,
  onSelectionChanged,
  costCenterDistributionMap,
}: CostCenterTableCheckboxProps) => {
  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      const updatedValue = { ...costCenterDistributionMap, [data?.shortName]: 0 };

      await onSelectionChanged(updatedValue);
    } else if (
      costCenterDistributionMap &&
      Object.hasOwn(costCenterDistributionMap, data?.shortName)
    ) {
      const clone = { ...costCenterDistributionMap };
      delete clone[data?.shortName];

      await onSelectionChanged(clone);
    }
  };

  return (
    <CheckboxCellRenderer
      label={value.toString()}
      onEdit={handleOnEdit}
      value={!!Object.hasOwn(costCenterDistributionMap!, data?.shortName)}
    />
  );
};
