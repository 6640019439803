import { useTranslation } from '@org/locales';
import { griddy } from '@org/query';
import { getAccountIdTextWithDescription } from '@org/utils';

import { FILE_TYPE, LABEL_FILE_ROW_TYPES } from '../../CostCenterConfiguration';
import { getApiFileType } from '../../CostCenterConfiguration/helpers/infoMapping';
import { useLabels } from '../../CostCenterConfiguration/hooks/useLabels';

export function ConfiguredAccountsInfo({
  selectAccountsConfig,
  yearId,
}: {
  selectAccountsConfig: griddy.SelectAccountsConfiguration[];
  yearId: string;
}) {
  const { t } = useTranslation();
  const { data } = griddy.useGetAllAccountPlanRecords({
    queryParams: {
      fileType: getApiFileType(
        FILE_TYPE.ACCOUNTS_PLAN,
      ) as griddy.GetAllLabelsByTypeQueryParams['fileType'],
      yearId,
    },
  });

  const jobLabels = useLabels(LABEL_FILE_ROW_TYPES.AUFTRAG.key, [], yearId);

  const accountsKeyByAccountIds = Object.fromEntries(
    (data ?? []).map(({ accountId, accountDescription }) => [accountId, accountDescription]),
  );

  return (
    <div>
      {selectAccountsConfig?.map((accountConfig, idx) => (
        <div key={idx}>
          {accountConfig?.accountId && (
            <div>
              {getAccountIdTextWithDescription(
                accountConfig.accountId.toString(),
                accountsKeyByAccountIds,
              )}
            </div>
          )}
          <div className="-mt-2">
            {accountConfig.selectEntireAccount && (
              <span>
                - {t('main:manageMenu.manageCostUnits.tableColumns.wholeAccountSelected')}
              </span>
            )}
            {accountConfig.allTransactionsWithNoJobId && (
              <span>
                - {t('main:manageMenu.manageCostUnits.tableColumns.allTransactionsWithNoJobId')}
              </span>
            )}
            {!accountConfig.selectEntireAccount && !accountConfig.allTransactionsWithNoJobId && (
              <div className="-space-y-2">
                {accountConfig.jobIdList?.map((jobId, secondIdx) => (
                  <div key={secondIdx}>{`- ${jobId} ${jobLabels[jobId] ?? ''}`}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
