import type { rave } from '@org/query';
import { t } from '@org/locales';

import type { BabRow } from '../../ReportTable/types';
import { ACCOUNT_TYPES } from '../../ReportTable/types';

export const extractAllocationKeyCalculations = (
  apiResponse: rave.ReportsResponse | undefined,
  type: ACCOUNT_TYPES,
): BabRow[] => {
  const {
    costAllocationKeyResponse = [],
    revenueAllocationKeyResponse = [],
    costCenters,
  } = apiResponse ?? {};

  const ccShortNameToGroupMap = new Map(costCenters?.map((ccm) => [ccm.shortName, ccm.group]));
  const ccShortNameToLongNameMap = new Map(
    costCenters?.map((ccm) => [ccm.shortName, ccm.longName]),
  );
  const allocationResponseByType =
    type === ACCOUNT_TYPES.EXPENSE ? costAllocationKeyResponse : revenueAllocationKeyResponse;

  return allocationResponseByType?.map(({ ccShortName, allocationBasis, allocationMap }) => {
    const ccLongName = ccShortNameToLongNameMap.get(ccShortName);
    const groupName =
      ccShortNameToGroupMap.get(ccShortName) ?? t('main:reportPanels.babReport.noGroup');

    // Pivot by cost center name and group
    return {
      accountId: allocationBasis,
      accountType: type,
      costTypeName: ccLongName,
      groupName,
      ...allocationMap,
    };
  });
};
