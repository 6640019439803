import { useState } from 'react';

export const SELECTED_DETAIL_COL = 'SELECTED_DETAIL_COL';

export type ActiveColID = string | number;
export type ActiveColName = string;

export interface UseMasterDetailStateProps {
  SELECTED_DETAIL_COL: string;
  activeCols: Record<ActiveColID, ActiveColName>;
}

export const useMasterDetailState = () => {
  const [tableContext, setTableContext] = useState<UseMasterDetailStateProps>({
    SELECTED_DETAIL_COL: '',
    activeCols: {},
  });

  return {
    setTableContext,
    tableContext,
  };
};
