import type { ICellEditorParams, ValueFormatterParams } from 'ag-grid-community';

import { isObjectShape } from '../../type-guards';

export const provideCellEditorParams = <Return = unknown>(
  params: ICellEditorParams | ValueFormatterParams,
): Return => {
  if (typeof params.colDef.cellEditorParams === 'function') {
    return params.colDef.cellEditorParams(params);
  }
  return params.colDef.cellEditorParams;
};

export const renderSingleSelectCellValue = (params: ValueFormatterParams) => {
  const cellEditorParams = provideCellEditorParams(params);

  if (
    !params.value ||
    !isObjectShape(cellEditorParams, { options: true }) ||
    !Array.isArray(cellEditorParams.options)
  ) {
    return '';
  }

  return cellEditorParams.options.find((option) => option.value === params.value)?.label ?? '';
};
