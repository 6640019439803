import type { griddy } from '../../../../query/src';

export type CreateNewEmptyConfig = ({
  name,
  year,
}: {
  name: string;
  year: string;
}) => Partial<griddy.MasterConfiguration>;

export const createNewEmptyConfig: CreateNewEmptyConfig = ({ name, year }) => {
  const configYear = Number(year);

  // ID will be created on API side
  const emptyConfig: Omit<griddy.MasterConfiguration, 'id'> = {
    calculationYear: configYear,
    concessionFeeConfig: { concessionFeeEnabled: false, costCenterConcessionFees: [] },
    costCenterConfig: { costCenterMapping: [] },
    costUnitAllocationConfig: {
      costCenterToCostUnitDistributions: [],
    },
    costUnitConfig: [],
    customLabels: [],
    futureAssetConfig: {
      isEnabled: false,
    },
    imputedWithdrawalCapitalConfig: {
      imputedWithdrawalCapitalEnabled: false,
      imputedWithdrawalCapitals: [],
    },
    imputedWorkingCapitalConfig: {
      defaultWorkingCapitalRate: 0,
      workingCapitalEnabled: false,
      workingCapitals: [],
    },
    interestRates: {
      applyCalculatedInterests: true,
      costCenterInterestRates: [],
      interestBearingCapitalBased: 'AVERAGE',
      uniformInterestRate: 0,
    },
    manualCorrections: [],
    manualCorrectionsInterest: [],
    name,
    plannedCostRevenueConfig: {
      ctaAccountEntries: [],
      globalPriceIncreasePercentage: 0,
      isEnabled: false,
    },

    transactionModifications: [],
    updatedAt: Date.now(),
    wiberaConfig: {
      applyReplacementValueDepreciation: false,
      configYear,
      nbrOfYearsForFactorsCalculation: 3,
      wiberaMapping: [],
    },
    withdrawCapitalConfig: [],
  };
  return emptyConfig;
};
