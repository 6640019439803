import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const {
    onGridReady,
    manualTableRef,
    columnDefs,
    defaultColDef,
    autoGroupColumnDef,
    modal,
    onCellValueChange,
    groupRemoveSingleChildren,
  } = props;

  return (
    <>
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        groupDefaultExpanded={2}
        groupRemoveSingleChildren={groupRemoveSingleChildren}
        onCellValueChanged={onCellValueChange}
        onGridReady={onGridReady}
        ref={manualTableRef}
        useAutoGeneratedRowId
      />
      {modal}
    </>
  );
};

export const ManualTable = bindControllers(View, [useController]);
ManualTable.displayName = 'ManualTable';
