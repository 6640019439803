import { isNil } from '../../type-guards';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateAgGridIdsByAutoGeneratedValues = (rowData: any[]) => {
  const existingIds = new Set();
  return rowData?.map((data) => {
    if (isNil(data.agGridRowId) || existingIds.has(data.agGridRowId)) {
      data.agGridRowId = crypto.randomUUID();
    }
    existingIds.add(data.agGridRowId);
    return data;
  });
};
