import type { APIParams } from '@org/query';
import { dice, griddy } from '@org/query';

import type { CostCenter, Job, ProfitCenter } from '../types';
import { getCostCenterName, getOrCreateCostCenter, getOrCreateProfitCenter } from '../helpers';
import { useLabels } from './useLabels';

export interface UseProfitCenterTreeProps {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  shortName?: string | undefined;
}

export const useProfitCenterTree = (props: UseProfitCenterTreeProps) => {
  const {
    apiParams: { yearId, masterConfigurationId },
    shortName,
  } = props;
  const { costCenterLabels, isLoading, jobIdLabels, profitCenterLabels } = useLabels(yearId);

  const {
    data: { costCenterMap, jobIdMap, profitCenterMap } = {},
    isLoading: isProfitTreeLoading,
  } = griddy.useGetFlattenedProfitCenterTrees(
    {
      queryParams: {
        fileType: 'JOURNAL',
        yearId,
      },
    },
    {
      enabled: !isLoading,
      select(originalData) {
        const data = originalData as Record<
          string,
          {
            [K in keyof griddy.AcctTrxSelectCriteria]-?: griddy.AcctTrxSelectCriteria[K];
          }
        >;
        const localJobIdMap = new Map<string, Job>();
        const localCostCenterMap = new Map<string, CostCenter>();
        const localProfitCenterMap = new Map<string, ProfitCenter>();

        for (const item of Object.values(data)) {
          const { costCenter, id, jobId, profitCenter } = item;
          const costCenterName = getCostCenterName(profitCenter, costCenter);
          localJobIdMap.set(id, {
            costCenter,
            costCenterName,
            id,
            label: jobIdLabels?.get(jobId),
            name: jobId,
            profitCenterName: profitCenter,
          });

          const currentJob = localJobIdMap.get(id)!;

          const currentCostCenter = getOrCreateCostCenter(
            localCostCenterMap,
            item,
            costCenterName,
            costCenterLabels?.get(costCenter),
          );
          currentCostCenter.availableJobs.add(currentJob);

          const currentProfitCenter = getOrCreateProfitCenter(
            localProfitCenterMap,
            item,
            profitCenterLabels?.get(profitCenter),
          );
          currentProfitCenter.availableJobs.add(currentJob);
          if (!currentProfitCenter.availableCostCenters.has(currentCostCenter)) {
            currentProfitCenter.availableCostCenters.add(currentCostCenter);
          }
        }

        return {
          costCenterMap: localCostCenterMap,
          jobIdMap: localJobIdMap,
          profitCenterMap: localProfitCenterMap,
        };
      },
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  const {
    data: {
      availableProfitCenters = new Map<string, ProfitCenter>(),
      unavailableProfitCenters = new Map<string, ProfitCenter>(),
    } = {},
  } = dice.useGetMasterConfiguration(
    {
      pathParams: {
        masterConfigurationId,
      },
    },
    {
      enabled: !isProfitTreeLoading,
      select({ costCenterConfig }) {
        const localAvailableProfitCenters = new Map<string, ProfitCenter>(profitCenterMap);
        const localUnavailableProfitCenters = new Map<string, ProfitCenter>();
        const unavailableKeys = new Set(
          costCenterConfig?.costCenterMapping
            ?.filter(({ shortName: currentShortName }) => shortName !== currentShortName)
            .flatMap(({ mappingKeys }) => mappingKeys!),
        );

        unavailableKeys.forEach((unavailableKey) => {
          const item = jobIdMap?.get(unavailableKey?.id ?? '');
          // Profit center tree can be out of date
          if (!item) {
            return;
          }

          item.isUnavailable = true;
          const currentCostCenter = costCenterMap!.get(item.costCenterName)!;
          currentCostCenter.unavailableJobs.add(item);
          currentCostCenter.availableJobs.delete(item);
          currentCostCenter.isUnavailable = currentCostCenter.availableJobs.size === 0;

          profitCenterMap!.get(item.profitCenterName)!.unavailableJobs.add(item);
          profitCenterMap!.get(item.profitCenterName)!.availableJobs.delete(item);
        });

        costCenterMap?.forEach((item) => {
          const { profitCenterName, isUnavailable } = item;

          const currentProfitCenter = profitCenterMap!.get(profitCenterName)!;
          if (!isUnavailable) {
            return;
          }
          currentProfitCenter.unavailableCostCenters.add(item);
          currentProfitCenter.availableCostCenters.delete(item);
          currentProfitCenter.isUnavailable = currentProfitCenter.availableCostCenters.size === 0;

          if (currentProfitCenter.isUnavailable) {
            localUnavailableProfitCenters.set(currentProfitCenter.name, currentProfitCenter);
            localAvailableProfitCenters.delete(currentProfitCenter.name);
          }
        });

        return {
          availableProfitCenters: localAvailableProfitCenters,
          unavailableProfitCenters: localUnavailableProfitCenters,
        };
      },
    },
  );

  return {
    availableProfitCenters,
    costCenterMap,
    unavailableProfitCenters,
  };
};

export type ProfitCenterTree = ReturnType<typeof useProfitCenterTree>;
