/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks -- This isn't violation, we need to call all controllers & we call them every render which is what hooks needs */
import type { ComponentPropsWithRef, FC } from 'react';
import { createElement, memo } from 'react';

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

export function bindControllers<
  ViewParam extends FC<any>,
  ViewProps extends ComponentPropsWithRef<ViewParam>,
  ControllerProps extends Partial<ViewProps> & Record<string, any>,
>(
  View: ViewParam,
  controllers: readonly ((props: ControllerProps) => Partial<ViewProps> | null | undefined)[],
) {
  return memo((props: Prettify<ControllerProps & Partial<ViewProps>>) =>
    createElement<Prettify<ViewProps>>(
      View,
      Object.assign(
        {},
        ...controllers.map((useController) => useController(props)).filter(Boolean),
      ),
    ),
  );
}
