import { useCallback, useState } from 'react';

import { useDisclosure } from '@nextui-org/react';

import { useTranslation } from '@org/locales';
import { ENTRY, GROUP, SUB_GROUP } from '@org/utils';

import { BaseConfirmationModal } from './components';

export const RowTypes = [GROUP, SUB_GROUP, ENTRY] as const;
export type RowType = (typeof RowTypes)[number];

interface CanContainsType {
  type?: RowType;
}

interface UseConfirmModalProps<T> {
  initialData?: T;
  onConfirm?: (data: T) => unknown;
  onCancel?: (data: T) => unknown;
  isGroup?: boolean;
}

export const useDeleteRowConfirmModal = <T,>(props: UseConfirmModalProps<T>) => {
  const { initialData, onCancel, onConfirm, isGroup = false } = props;
  const [data, setData] = useState<T | undefined>(initialData);

  const { onClose, onOpenChange, isOpen, onOpen } = useDisclosure();

  const { t } = useTranslation();

  const openModal = useCallback(
    (openData: T) => {
      if (typeof openData === 'function') {
        setData(() => openData);
      } else {
        setData(openData);
      }
      onOpen();
    },
    [onOpen],
  );

  const closeModal = useCallback(() => {
    setData(undefined);
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm?.(data!);
    closeModal();
  }, [closeModal, data, onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel?.(data!);
    closeModal();
  }, [closeModal, data, onCancel]);

  let deletingGroup = isGroup && 'group';

  if (typeof data === 'object' && Array.isArray(data) === false) {
    deletingGroup = (data as CanContainsType)?.type === GROUP && 'group';
  }

  const deletingSelection = Array.isArray(data) && 'selection';

  return {
    closeModal,
    modal: (
      <BaseConfirmationModal
        confirmButtonText={t('common:btnDelete')}
        contentText={t(`common:modals.table.${deletingSelection || deletingGroup || 'row'}.text`)}
        isOpen={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        onOpenChange={onOpenChange}
        titleText={t(`common:modals.table.${deletingSelection || deletingGroup || 'row'}.title`)}
      />
    ),
    openModal,
  };
};
