import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { ManualTable } from './components';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const {
    agGridProps,
    columnDefs,
    defaultColDef,
    getRowId,
    onFirstSelectFirst,
    onRowSelectionChanged,
    tableRef,
    apiParams,
    manualTableRef,
    selectedAccountLedger,
    selectedAccountsAllocation,
    updateAccountsAllocation,
  } = props;

  return (
    <div className="flex h-full">
      <div className="h-full w-1/2 p-2">
        <BaseEditTable
          {...agGridProps}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          onFirstDataRendered={onFirstSelectFirst}
          onSelectionChanged={onRowSelectionChanged}
          ref={tableRef}
          rowSelection="single"
          suppressRowClickSelection={false}
        />
      </div>

      <div className="flex h-full w-1/2 flex-col p-2">
        <ManualTable
          apiParams={apiParams}
          manualTableRef={manualTableRef}
          selectedAccountLedger={selectedAccountLedger}
          selectedAccountsAllocation={selectedAccountsAllocation}
          updateAccountsAllocation={updateAccountsAllocation}
        />
      </div>
    </div>
  );
};

export const WithdrawCapitalMasterDetail = bindControllers(View, [useController]);

WithdrawCapitalMasterDetail.displayName = 'WithdrawCapitalMasterDetail';
