import { useState } from 'react';

import type { CorrectAny } from '@org/models';

/**
 * Hook for asynchronous calls with loading state.
 *
 * @example Usage
 * const MyComponent = () => {
 *    const { isLoading, asyncAction } = useLoadingAction();
 *
 *    const myAsyncCallback = asyncAction(async (arg: MyType) => {
 *      await myRequest(arg);
 *    });
 *
 *    return <MyLoadingComponent isLoading={isLoading} />
 * }
 */

export type AsyncAction = <Callback extends (...args: CorrectAny[]) => Promise<CorrectAny>>(
  callback: Callback,
) => (...args: Parameters<Callback>) => Promise<void>;

export const useLoadingAction = () => {
  const [isLoading, setIsLoading] = useState(false);

  const asyncAction =
    <Callback extends (...args: CorrectAny[]) => Promise<CorrectAny>>(callback: Callback) =>
    async (...args: Parameters<Callback>) => {
      setIsLoading(true);

      try {
        await callback(...args);
      } finally {
        setIsLoading(false);
      }
    };

  const asyncActionDirect = async <Callback extends (...args: CorrectAny[]) => Promise<CorrectAny>>(
    callback: Callback,
    ...args: Parameters<Callback>
  ) => {
    setIsLoading(true);

    try {
      await callback(...args);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    asyncAction,
    asyncActionDirect,
    isLoading,
  };
};
