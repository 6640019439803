import type { dice } from '../../../../../query/src';

export const getPrimaryCostCenters = (costCenterMapping?: dice.CostCenter[]): dice.CostCenter[] =>
  costCenterMapping?.filter((ccm) => ccm.costCenterType === 'PRIMARY_COST_CENTER') ?? [];

const DEFAULT_CONCESSION_FEE_RATE = 0;

const findCCConcessionFeeRateByCCName = (
  shortName: string,
  ccConcessionFees: dice.CostCenterConcessionFee[],
): dice.CostCenterConcessionFee | undefined =>
  ccConcessionFees.find((obj) => obj.primaryCostCenterShortName === shortName);

/*
 Adds a costCenterConcessionFee entry for each new Primary cost center with default concession fee rate
 Deletes entries for every deleted Primary cost center
 Sorts the entries by CC Name
 */
export const updateImputedConcessionFeeByCostCenters = (
  masterConfiguration: dice.MasterConfiguration,
): dice.ConcessionFeeConfig => {
  const primaryCCNames = getPrimaryCostCenters(
    masterConfiguration?.costCenterConfig?.costCenterMapping,
  )
    .reduce((acc: string[], cc) => {
      if (cc.shortName) {
        acc.push(cc.shortName);
      }
      return acc;
    }, [])
    .sort();

  const previousConcessionFees =
    masterConfiguration.concessionFeeConfig?.costCenterConcessionFees ?? [];

  const updatedCostCenterConcessionFees = primaryCCNames.map(
    (ccName) =>
      findCCConcessionFeeRateByCCName(ccName ?? '', previousConcessionFees) ?? {
        concessionFeeRate: DEFAULT_CONCESSION_FEE_RATE,
        primaryCostCenterShortName: ccName,
      },
  );

  return {
    ...masterConfiguration.concessionFeeConfig,
    costCenterConcessionFees: updatedCostCenterConcessionFees,
  };
};
