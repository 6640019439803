import type { ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { getCellClassesForGroups } from '@org/utils';

import { flattenAllocations } from '../utils';
import { AccountTableCheckbox, SelectAllCheckbox } from './components';
import { useColumns } from './useColumns';

export type ControllerType = ReturnType<typeof useController>;

const defaultColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
} satisfies ColDef;

export interface ControllerProps {
  rankOfCC?: number;
  basisType: dice.AllocationKeyConfigDTO['basisType'];
  gridRef: RefObject<AgGridReact>;
  selectedAllocationBasis?: dice.AllocationBasisConfigEntryDTO;
  selectedAllocationId?: string;
  otherMergedAllocations: dice.AllocationBasisConfigEntryDTO;
  handleUpdateEntry: (entry: dice.AllocationBasisConfigEntryDTO) => Promise<void>;
  apiParams: APIParams<'masterConfigurationId'>;
}

export function useController({
  apiParams,
  rankOfCC,
  basisType,
  gridRef,
  selectedAllocationBasis,
  handleUpdateEntry,
  otherMergedAllocations,
}: ControllerProps) {
  const { preselectedAllocations } = aggregated.useAllocationKeys(apiParams);

  const { t } = useTranslation();

  const allocations = useMemo(
    () =>
      flattenAllocations({
        basisType,
        nestedAllocations: preselectedAllocations,
        rankOfCC,
        t,
      }),
    [rankOfCC, preselectedAllocations, t, basisType],
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererParams: {
        suppressCount: true,
      },
      cellRendererSelector: ({ node: { group }, ...other }) => {
        if (group) {
          return { component: 'agGroupCellRenderer' };
        }
        return {
          component: () => (
            <AccountTableCheckbox
              {...other}
              handleUpdateEntry={handleUpdateEntry}
              otherMergedAllocations={otherMergedAllocations}
              selectedAllocationBasis={selectedAllocationBasis}
            />
          ),
        };
      },
      editable: false,
      field: 'group',
      flex: 3,
      headerComponent: () => (
        <div className="ml-2 flex space-x-2">
          <SelectAllCheckbox
            basisType={basisType}
            handleUpdateEntry={handleUpdateEntry}
            otherMergedAllocations={otherMergedAllocations}
            preselectedAllocations={preselectedAllocations}
            rankOfCC={rankOfCC}
            selectedAllocationBasis={selectedAllocationBasis}
          />
          <div>{t('main:manageMenu.priceSheet.tableColumns.description')}</div>
        </div>
      ),
      valueGetter: ({ data: { description } }) => description,
    }),
    [
      handleUpdateEntry,
      otherMergedAllocations,
      selectedAllocationBasis,
      basisType,
      preselectedAllocations,
      rankOfCC,
      t,
    ],
  );

  const columnsDefs = useColumns();

  return {
    autoGroupColumnDef,
    columnsDefs,
    defaultColDef,
    gridRef,
    handleUpdateEntry,
    rowData: allocations,
    selectedAllocationBasis,
  };
}
