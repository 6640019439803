import type { GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback, useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import { aggregated } from '@org/query';

import { useColumns } from './useColumns';

export interface ControllerProps {
  selectedAllocationBasis?: dice.AllocationBasisConfigEntryDTO;
  handleUpdateEntry: (entry: dice.AllocationBasisConfigEntryDTO) => Promise<void>;
  gridRef: RefObject<AgGridReact>;
  apiParams: APIParams<'masterConfigurationId'>;
}

export type ControllerType = ReturnType<typeof useController>;

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
};

export function useController({
  selectedAllocationBasis,
  apiParams,
  handleUpdateEntry,
  gridRef,
}: ControllerProps) {
  const { preselectedAllocations } = aggregated.useAllocationKeys(apiParams);

  const rowData = useMemo(() => {
    const expenseAccounts = preselectedAllocations?.expenseAccounts ?? [];
    const revenueAccounts = preselectedAllocations?.revenueAccounts ?? [];

    return expenseAccounts.concat(revenueAccounts);
  }, [preselectedAllocations]);

  const onSelectionChanged = async (accountIds: string[]) => {
    const copy = {
      ...selectedAllocationBasis,
      allocationDistributionConfig: {
        ...selectedAllocationBasis?.allocationDistributionConfig,
        accountIds,
      },
    };

    await handleUpdateEntry(copy);
  };

  const getRowId = useCallback(({ data }: GetRowIdParams) => data?.accountId, []);

  const columnDefs = useColumns({
    accountIds: selectedAllocationBasis?.allocationDistributionConfig?.accountIds ?? [],
    onSelectionChanged,
    rowData,
  });

  return {
    columnDefs,
    defaultColDef,
    getRowId,
    gridRef,
    onSelectionChanged,
    rowData,
  };
}
