export const isProd = () => process.env.NODE_ENV === 'production';
export const isDev = () => !isProd();

export const pdfToUrl = (file) => {
  if (file.url) {
    return file.url;
  }

  const blob = new Blob([file], { type: 'application/pdf' });
  return URL.createObjectURL(blob);
};

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export * from './object';
