/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ICellRendererParams } from 'ag-grid-community';
import type { ComponentProps, FC } from 'react';
import { memo } from 'react';

import classnames from 'classnames';

import { useTranslation } from '@org/locales';
import { sortByOrder } from '@org/utils';

import { Tooltip } from '../Tooltip';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type IconListType<T extends Record<string, any>, P extends keyof T> = Record<
  Required<T>[P],
  { icon: FC; iconProps?: ComponentProps<'svg'> }
>;

export interface IconCellRendererProps<T extends Record<string, any>, P extends keyof T>
  extends OuterProps,
    Omit<ICellRendererParams, 'value'> {
  value: T | T[];
  iconList: IconListType<T, P>;
  fieldName: string;
}

const iconParams: ComponentProps<'svg'> = {
  height: 20,
  width: 20,
};

const ICON_ARRAY_ORDER = ['UPLOADED_ASSET', 'ERP_ASSET', 'FORECAST_ASSET', 'FUTURE_ASSET'];

export const IconCellRenderer: FC<IconCellRendererProps<Record<string, any>, string>> = memo(
  (props) => {
    const { className, value, iconList, fieldName } = props;
    const { t } = useTranslation();

    const valueAsArray = Array.isArray(value) ? value : [value];

    const iconsByStatus = [...new Set(valueAsArray.map((item) => item[fieldName]))].flatMap(
      (item) =>
        item
          ? [{ Icon: iconList[item].icon, itemProps: iconList[item].iconProps, status: item }]
          : [],
    );

    const sortedStatuses = sortByOrder(iconsByStatus, 'status', ICON_ARRAY_ORDER);

    return (
      <div
        className={classnames(
          'icon-cell-renderer grid grid-flow-col items-center justify-items-start gap-1',
          className,
        )}
        data-testid="IconCellRenderer"
      >
        {sortedStatuses.map(({ status, Icon, itemProps }, idx) => (
          <Tooltip
            content={t(`common:enums.${fieldName}.${status}`)}
            key={idx}
          >
            <Icon
              {...iconParams}
              {...itemProps}
            />
          </Tooltip>
        ))}
      </div>
    );
  },
);

IconCellRenderer.displayName = 'IconCellRenderer';
