import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import { isEmpty } from 'lodash';

import type { dice, griddy } from '@org/query';
import { useTranslation } from '@org/locales';
import { getAccountIdTextWithDescription } from '@org/utils';

import { AllocationStatusIcon } from '../AllocationStatusIcon';
import { AccountTableCheckbox, SelectAllCheckbox } from './components';
import { getMixedAllocationStatus, getSelectedAccountSelectionStatus } from './utils';

interface LedgerAccounts {
  account: string;
  allocationStatus: string;
  status: string;
}

interface UseColumnsProps {
  selectedAccountsAllocation: dice.WithdrawCapital['accountsAllocation'];
  updateAccountsAllocation: (accounts: dice.WithdrawCapital['accountsAllocation']) => Promise<void>;
  defaultAllocationMap: dice.WithdrawCapital['accountsAllocation'];
  accountsPlanMap: griddy.GetAllAccountIdToAccountDescriptionMapResponse | undefined;
}

export const useColumns = ({
  selectedAccountsAllocation,
  updateAccountsAllocation,
  defaultAllocationMap,
  accountsPlanMap,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellRenderer: ({ data, ...other }: ICellRendererParams) => (
            <AccountTableCheckbox
              {...other}
              accountsAllocation={selectedAccountsAllocation}
              data={data}
              defaultAllocationMap={defaultAllocationMap}
              handleEditConfig={updateAccountsAllocation}
              label={getAccountIdTextWithDescription(data.account, accountsPlanMap)}
            />
          ),
          colId: 'account',
          field: 'account',
          flex: 2,
          headerComponent: () => (
            <div className="flex space-x-2">
              <SelectAllCheckbox
                accountsAllocation={selectedAccountsAllocation}
                defaultAllocationMap={defaultAllocationMap}
                handleEditConfig={updateAccountsAllocation}
              />
              <div>{t('main:manageMenu.manageAllocationKeys.accounts')}</div>
            </div>
          ),
        },

        {
          cellRenderer: (params: ICellRendererParams) => {
            const keys = Object.keys(selectedAccountsAllocation?.[params.data.account] ?? {});
            const statusSelection = getSelectedAccountSelectionStatus(keys);

            const isSelected = Boolean(selectedAccountsAllocation?.[params.data.account]) ?? false;

            if (!isSelected) {
              return null;
            }

            return statusSelection.isManual && statusSelection.isAutomatic
              ? t('main:manageMenu.manageWithdrawCapital.mixedManualAllocation')
              : statusSelection.isManual
                ? t('main:manageMenu.manageWithdrawCapital.labelManualAllocation')
                : t('main:manageMenu.manageWithdrawCapital.labelAutomaticAllocation');
          },
          colId: 'allocationStatus',
          field: 'allocationStatus',
          filter: 'agTextColumnFilter',
          flex: 1,
          floatingFilter: true,
        },

        {
          cellClass: '!flex items-center justify-center',
          cellRenderer: (params: ICellRendererParams) => {
            const selectedAccount = selectedAccountsAllocation?.[params.data.account];
            const isSelected = Boolean(selectedAccount) ?? false;

            if (!isSelected) {
              return null;
            }

            const keys = Object.keys(selectedAccountsAllocation?.[params.data.account] ?? {});
            const statusSelection = getSelectedAccountSelectionStatus(keys);

            const totalPercentage = Object.values(selectedAccount?.NA ?? {}).reduce(
              (acc, item) => acc + item,
              0,
            );

            const isPartiallyAllocated = Object.entries(selectedAccount ?? {}).some(
              ([, allocationMap]) => isEmpty(allocationMap),
            );

            const hasNothingAllocated = Object.entries(selectedAccount ?? {}).every(
              ([, allocationMap]) => isEmpty(allocationMap),
            );

            const automaticValue = hasNothingAllocated
              ? 'NOTHING_ALLOCATED'
              : isPartiallyAllocated
                ? 'PARTIALLY_ALLOCATED'
                : 'FULLY_ALLOCATED';

            const manualValue =
              totalPercentage === 100
                ? 'FULLY_ALLOCATED'
                : totalPercentage === 0
                  ? 'NOTHING_ALLOCATED'
                  : 'PARTIALLY_ALLOCATED';

            if (statusSelection.isManual && statusSelection.isAutomatic) {
              const mixedStatusAllocation = getMixedAllocationStatus({
                automaticValue,
                manualValue,
              });

              return (
                <AllocationStatusIcon
                  {...params}
                  value={mixedStatusAllocation}
                />
              );
            }

            return (
              <AllocationStatusIcon
                {...params}
                value={statusSelection.isManual ? manualValue : automaticValue}
              />
            );
          },
          colId: 'status',
          field: 'status',
          filter: 'agTextColumnFilter',
          flex: 1,
          floatingFilter: true,
          headerName: t('main:manageMenu.allocationKeysConfiguration.tableColumns.status'),
          maxWidth: 45,
        },
      ] satisfies (ColDef<LedgerAccounts> | ColGroupDef<LedgerAccounts>)[],
    [
      t,
      selectedAccountsAllocation,
      accountsPlanMap,
      defaultAllocationMap,
      updateAccountsAllocation,
    ],
  );
};
