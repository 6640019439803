import { useCallback, useEffect, useRef, useState } from 'react';

const useDidMountEffect = (toExecute: () => void, onChangeOf: unknown[]) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      toExecute();
    } else {
      didMount.current = true;
    }
  }, onChangeOf);
};

function useLocalStorage<T>(
  key: string,
  initialValue: T,
  parseValue: (input: string | null) => T = (v) => v as T,
) {
  const [item, setValue] = useState(() => {
    const value = parseValue(localStorage.getItem(key)) || initialValue;
    localStorage.setItem(key, String(value));
    return value;
  });

  const setItem = useCallback(
    (newValue: T) => {
      setValue(newValue);
      window.localStorage.setItem(key, String(newValue));
    },
    [key],
  );

  return [item, setItem] as const;
}

export function useSetPersistentTimeout(name: string, fn: () => void, timeout: number) {
  const [callfn, setCallfn] = useState(false);
  const [lapse, setLapse] = useLocalStorage(`${name}-nanoedmo:time`, 0, Number);
  const [running, setRunning] = useLocalStorage(
    `${name}-nanoedmo:running`,
    false,
    (string) => string === 'true',
  );

  const timeoutRef = useRef(timeout);

  useEffect(() => {
    // round to the nearest second
    const timer = setInterval(() => {
      if (running) {
        const loggedInTime = Number(sessionStorage.getItem('time'));
        setLapse(Date.now() - loggedInTime);
      }
      // round to the nearest second
    }, 1000);

    if (lapse >= timeoutRef.current) {
      setCallfn(true);
      clearInterval(timer);
      setRunning(false);
    }
    return () => {
      clearInterval(timer);
    };
  }, [running, lapse, setLapse, timeout, setRunning]);

  useDidMountEffect(() => {
    if (callfn) {
      fn();
    }
  }, [callfn]);

  const reset = useCallback(() => {
    const loggedInTime = Number(sessionStorage.getItem('time'));
    timeoutRef.current = timeout + Date.now() - loggedInTime;
    setCallfn(false);
    setLapse(0);
    setRunning(true);
  }, [setLapse, setRunning, timeout]);

  const startTimer = useCallback(() => {
    setRunning(true);
  }, [setRunning]);

  return [startTimer, reset];
}
