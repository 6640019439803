import type { ICellEditor, ICellRendererParams } from 'ag-grid-community';
import type { ChangeEvent } from 'react';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';

import { CheckboxInput } from '../CheckboxInput';

export interface CheckboxCellRendererProps extends Partial<ICellRendererParams> {
  value: boolean;
  onEdit?: (value: boolean) => void;
  disabled?: boolean | (() => boolean);
  indeterminate?: boolean | (() => boolean);
  label?: string;
  source?: string;
}

const CheckboxCellRendererMemo = forwardRef<ICellEditor, CheckboxCellRendererProps>(
  (props, ref) => {
    const { onEdit, label, indeterminate, disabled, value, node, column, api, source } = props;
    const [changed, setChanged] = useState(false);
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    useEffect(() => {
      if (changed) {
        api?.stopEditing();
      }
    }, [api, changed]);

    useImperativeHandle(ref, () => ({
      // the final value to send to the grid, on completion of editing
      getValue() {
        return internalValue;
      },
    }));

    const handleChange = ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      const colId = column?.getColId();

      const newNode = node?.data ?? node?.aggData;

      if (node?.id && colId && !node.group) {
        newNode[colId] = checked;
        api?.applyTransactionAsync({
          update: [newNode],
        });
        api?.applyTransaction({
          update: [newNode],
        });
      }
      node?.setDataValue(colId!, checked, source);
      setInternalValue(checked);
      setChanged((prevValue) => !prevValue);
      onEdit?.(checked);
    };

    return (
      <div className="flex h-full items-center">
        <CheckboxInput
          checked={internalValue}
          data-testid="CheckboxRenderer"
          disabled={disabled instanceof Function ? disabled() : disabled}
          indeterminate={indeterminate instanceof Function ? indeterminate() : indeterminate}
          onChange={handleChange}
        >
          {label ? <span className="pl-1">{label}</span> : ''}
        </CheckboxInput>
      </div>
    );
  },
);

export const CheckboxCellRenderer = memo(CheckboxCellRendererMemo);
