import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

export const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  filterParams: {
    keepAppliedFilter: {
      newRowsAction: 'keep',
    },
  },
  floatingFilter: true,
};

interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId'>;
  setTransactionsPresentToFlush: (transactionsPresentToFlush: boolean) => void;
}

export const useColumns = (props: UseColumnsProps) => {
  const { apiParams, setTransactionsPresentToFlush } = props;

  const { t } = useTranslation();
  const { getCostCenterLongName, costCenterOptions } = aggregated.useCostCenters(apiParams);

  return useMemo(
    () =>
      [
        {
          checkboxSelection: true,
          colId: 'index',
          field: 'index',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerName: t('main:reportPanels.babReport.tableColumns.index'),
        },
        {
          colId: 'description',
          field: 'description',
          flex: 1,
          headerName: t('main:reportPanels.babReport.tableColumns.description'),
        },
        {
          colId: 'transactionAmount',
          field: 'transactionAmount',
          headerName: t('main:reportPanels.babReport.tableColumns.amount'),
          type: 'report-price',
          width: 100,
        },
        {
          colId: 'transactionType',
          field: 'transactionType',
          headerName: t('main:reportPanels.babReport.tableColumns.transactionType'),
          width: 120,
        },
        {
          cellEditor: 'select',
          cellEditorParams: {
            options: costCenterOptions,
          },
          colId: 'mappedCostCenterShortname',
          editable: true,
          field: 'mappedCostCenterShortname',
          filterValueGetter: ({ data }) =>
            getCostCenterLongName(data?.mappedCostCenterShortname ?? ''),
          flex: 1,
          headerName: t('main:reportPanels.babReport.tableColumns.costCenterType'),
          valueFormatter: ({ data }) =>
            getCostCenterLongName(data?.mappedCostCenterShortname ?? ''),
          valueSetter: (params) => {
            setTransactionsPresentToFlush(true);
            const selectedNodes = params.api.getSelectedNodes();
            params.data.mappedCostCenterShortname = params.newValue;
            params.data.mappedCostCenterLongName = getCostCenterLongName(params.newValue)!;

            params.data._updatedData = {
              ...params.data._updatedData,
              mappedCostCenterLongName: getCostCenterLongName(params.newValue)!,
              // @ts-expect-error -- this is typo in the backend
              mappedCostCenterShortName: params.newValue,
              mappedCostCenterShortname: params.newValue,
            };

            if (selectedNodes.length > 1) {
              selectedNodes.forEach((node) => {
                if (!node.data) {
                  return;
                }
                node.data.mappedCostCenterShortname = params.newValue;
                node.data.mappedCostCenterLongName = getCostCenterLongName(params.newValue)!;

                node.data._updatedData = {
                  ...node.data._updatedData,
                  mappedCostCenterLongName: getCostCenterLongName(params.newValue)!,
                  // @ts-expect-error -- this is typo in the backend we need both casings
                  mappedCostCenterShortName: params.newValue,
                  mappedCostCenterShortname: params.newValue,
                };
              });
              params.api.applyTransactionAsync({
                update: selectedNodes.flatMap(({ data }) => (data ? [data] : [])),
              });
            } else {
              params.api.applyTransactionAsync({
                update: [params.data],
              });
            }

            return true;
          },
        },
        {
          cellRenderer: 'checkbox',
          colId: 'enabled',
          field: 'enabled',
          headerName: t('main:reportPanels.babReport.tableColumns.enabled'),
          valueSetter: (params) => {
            setTransactionsPresentToFlush(true);
            const selectedNodes = params.api.getSelectedNodes();

            params.data.enabled = params.newValue;

            params.data._updatedData = {
              ...params.data._updatedData,
              enabled: params.newValue,
            };
            if (selectedNodes.length > 1) {
              selectedNodes.forEach((node) => {
                if (!node.data) {
                  return true;
                }
                node.data.enabled = params.newValue;

                node.data._updatedData = {
                  ...node.data?._updatedData,
                  enabled: params.newValue,
                };
              });
              params.api.applyTransactionAsync({
                update: selectedNodes.flatMap(({ data }) => (data ? [data] : [])),
              });
            } else {
              params.api.applyTransactionAsync({
                update: [params.data],
              });
            }

            return true;
          },
          width: 80,
        },
      ] satisfies (
        | ColDef<griddy.AccountTransaction & { _updatedData?: Partial<griddy.AccountTransaction> }>
        | ColGroupDef<
            griddy.AccountTransaction & { _updatedData?: Partial<griddy.AccountTransaction> }
          >
      )[],
    [costCenterOptions, getCostCenterLongName, setTransactionsPresentToFlush, t],
  );
};
