import type { ICellRendererParams } from 'ag-grid-community';
import { useCallback } from 'react';

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { flushSync } from 'react-dom';

import type { useMasterDetailState, UseMasterDetailStateProps } from './hooks';

export interface CustomMasterDetailCellRendererProps extends ICellRendererParams {
  colName: string;
  setTableContext: ReturnType<typeof useMasterDetailState>['setTableContext'];
  tableContext: UseMasterDetailStateProps;
  value: string;
  formatter?: (value: string) => string;
}

export function CustomMasterDetailCellRenderer({
  node,
  colName,
  value,
  tableContext,
  setTableContext,
  context,
  api,
  formatter = (val) => val,
}: CustomMasterDetailCellRendererProps) {
  const { expanded: isExpanded = false, id: currentNodeId = '' } = node ?? {};
  let openCol = null;

  const chevronHandleOnClick = useCallback(() => {
    const getCurrentNode = tableContext?.activeCols?.[currentNodeId];
    if (getCurrentNode === colName && isExpanded) {
      // we clicked on the opened column (now close it)
      delete tableContext?.activeCols[currentNodeId];
      node.setExpanded(false);
    } else {
      tableContext.activeCols[currentNodeId] = colName;
      node.setExpanded(true);
    }

    // We need this to be synchronous without this the table will not update
    flushSync(() => {
      setTableContext({
        SELECTED_DETAIL_COL: colName,
        activeCols: tableContext?.activeCols,
      });
    });

    // @ts-expect-error -- missing types; Accessing private API
    if (node.detailNode) {
      // FIXME: Try to find another approach
      // @ts-expect-error -- missing types; Accessing private API
      api.redrawRows({ rowNodes: [node.detailNode, node] });
    }
  }, [api, colName, currentNodeId, isExpanded, node, setTableContext, tableContext]);

  if (context) {
    openCol = context?.activeCols?.[currentNodeId];
  }

  const chevronStateIsOpen = isExpanded && openCol === colName;
  return (
    <div className="master-container flex flex-row items-center gap-x-2">
      <button
        className="w-[16px] cursor-pointer focus:outline-0"
        id={currentNodeId}
        onClick={chevronHandleOnClick}
      >
        {chevronStateIsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
      </button>

      <span>{formatter(value)}</span>
    </div>
  );
}
