import type { ListState } from '@react-stately/list';
import type { AriaListBoxProps } from '@react-types/listbox';
import type { ElementType, ForwardedRef, ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo, useRef } from 'react';

import { useListBox } from '@react-aria/listbox';
import { tv } from 'tailwind-variants';

import type { OverrideProps } from '../../types';
import { createComponent, mergeRefs } from '../../utils';
import { ListBoxItemPrivate } from '../ListBoxItem';
import { ListBoxSectionPrivate } from '../ListBoxSection';
import { ListBoxContext } from './ListBoxContext';

const listBoxVariants = tv({
  slots: {
    base: 'flex flex-col gap-2 rounded-lg p-2',
    loading: '',
    noResult: 'text-text-disabled',
  },
  variants: {
    size: {
      md: {},
      sm: {},
    },
  },
});

export interface ListBoxBaseTypeMap<
  AdditionalProps = {},
  DefaultComponent extends ElementType = 'div',
> {
  props: AdditionalProps &
    AriaListBoxProps<object> &
    VariantProps<typeof listBoxVariants> & {
      /**
       * The collection list state.
       */
      state: ListState<object>;
      /**
       * Specifies if the component is in a loading state
       */
      isLoading?: boolean;
      /**
       * Specifies the loading state content for the combobox. If not provided
       * no text will be displayed in the loading state
       */
      loadingText?: ReactNode;
      /**
       * Specifies the no results ui elements for the combobox
       */
      noResult?: ReactNode;
    };
  defaultComponent: DefaultComponent;
}

export type ListBoxBaseProps<
  RootComponent extends ElementType = ListBoxBaseTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<ListBoxBaseTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const ListBoxBase = createComponent<ListBoxBaseTypeMap>(
  <BaseComponentType extends ElementType = ListBoxBaseTypeMap['defaultComponent']>(
    inProps: ListBoxBaseProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const {
      as: Component = 'div',
      state,
      isLoading,
      loadingText,
      noResult,
      className,
      size = 'md',
      children,
      ...otherProps
    } = inProps;
    const listboxRef = useRef<HTMLDivElement>(null);

    const context = useMemo(() => ({ state }), [state]);

    const { listBoxProps } = useListBox(otherProps, state, listboxRef);
    const { selectionMode } = state.selectionManager;

    const styles = useMemo(() => listBoxVariants({ className }), [className]);

    return (
      <ListBoxContext.Provider value={context}>
        <Component
          {...listBoxProps}
          className={styles.base({ className })}
          ref={mergeRefs(listboxRef, ref)}
        >
          {children}
          {!isLoading &&
            [...state.collection].map((item) => {
              if (item.type === 'section') {
                return (
                  <ListBoxSectionPrivate
                    item={item}
                    key={item.key}
                    selectionMode={selectionMode}
                    size={size}
                  />
                );
              }
              return (
                <ListBoxItemPrivate
                  item={item}
                  key={item.key}
                  selectionMode={selectionMode}
                  size={size}
                />
              );
            })}
          {!isLoading && noResult && state.collection.size === 0 && (
            <div className={styles.noResult()}>{noResult}</div>
          )}
          {isLoading && loadingText && <div className={styles.loading()}>{loadingText}</div>}
        </Component>
      </ListBoxContext.Provider>
    );
  },
);
