// FIXME: This file should be migrated to getCCColumnsForGroup with calculating total in query
import type { CellClassParams, CellStyle, ColDef, ColGroupDef } from 'ag-grid-community';

import classNames from 'classnames';

import type { griddy } from '@org/query';
import { t } from '@org/locales';

import type { BabRow, GroupedCostCenter } from './types';
import { numericColumnProps } from './defaults';
import { calcGroupedColumnSum, getCostCenterCellStyle, joinGroupedColumnString } from './helpers';

export type RenderColumnGroupParams = {
  costCentersGroups: GroupedCostCenter[];
  modifiedCostCenters: BabRow['modifiedCostCenters'];
  manuallyCorrectedCostCenters: BabRow['manuallyCorrectedCostCenters'];
  showModifications: boolean;
  type: string;
  cellClassName?: string;
  cellClass?: (cellClassParams: CellClassParams, rowsToBold?: string[]) => string[];
} & ColDef;

export const renderColumnGroup = (props: RenderColumnGroupParams) => {
  const { costCentersGroups, type, aggFunc, cellClass: defaultCellClass } = props;

  if (costCentersGroups.length === 0) {
    return null;
  }

  const isSeparated = type === 'PRIMARY_COST_CENTER';

  return costCentersGroups.map(([groupName, costCentersList], groupIndex) => {
    const cols = getCCColumnsForGroup(costCentersList, groupIndex, props, isSeparated);

    const groupBorderClasses = classNames({
      'border-left-1px': groupIndex !== 0,
      'border-left-2px': groupIndex === 0 && isSeparated,
    });

    const groupTotalsColumn: ColDef = {
      aggFunc,
      cellClass: (params: CellClassParams) => [
        ...(defaultCellClass ? defaultCellClass(params) : []),
        groupBorderClasses,
        'accounting-number',
      ],
      colId: `total-${groupName ?? 'no-group'}${groupIndex}`,
      columnGroupShow: 'closed',
      headerClass: classNames('bg-white', groupBorderClasses),
      headerName: t('common:total'),
      valueGetter: ({ column, data, node }) => {
        const isFirstRow = node?.childIndex === 0 && node?.level === 0; // CC,PC or Job ids row

        return isFirstRow
          ? joinGroupedColumnString(column, data)
          : calcGroupedColumnSum(column, data);
      },
      ...numericColumnProps,
    };

    if (cols.length > 1) {
      cols.unshift(groupTotalsColumn);
    }

    if (groupName) {
      const groupCol: ColGroupDef = {
        children: cols,
        headerClass: classNames(groupBorderClasses, {
          'grey-light-background': groupIndex % 2,
          'grey-lighter-background': !(groupIndex % 2),
        }),
        headerName: groupName,
        openByDefault: true,
      };

      return groupCol;
    }

    const groupCol: ColGroupDef = {
      headerClass: classNames(groupBorderClasses, 'bg-white'),
      headerName: t('main:reportPanels.babReport.noGroup'),
      openByDefault: true,
      showRowGroup: true,
      ...numericColumnProps,
      children: cols,
    };

    return groupCol;
  });
};

const getCCColumnsForGroup = (
  costCentersList: griddy.CostCenter[],
  groupIndex: number,
  props: RenderColumnGroupParams,
  isSeparated: boolean,
) => {
  const { showModifications, type, aggFunc, cellClassName, cellClass: cellClassProp } = props;
  return costCentersList.map((cc, index) => {
    const cellClasses = classNames({
      'border-left-1px': index === 0 && groupIndex !== 0,
      // Separator should be added only for first cost center which index is 0
      'border-left-2px': isSeparated && index === 0 && groupIndex === 0,
    });

    const col: ColDef = {
      aggFunc,
      cellClass: (params: CellClassParams) => [
        ...(cellClassProp ? cellClassProp(params) : []),
        cellClasses,
        cellClassName ?? '',
        'accounting-number',
      ],
      colId: cc.shortName,
      columnGroupShow: 'open',
      field: cc.shortName,
      headerClass: classNames('bg-white', cellClasses),
      headerName: cc.longName,
      ...numericColumnProps,
      cellStyle: getCostCenterCellStyle(
        type,
        cc?.shortName ?? '',
        showModifications,
        numericColumnProps?.cellStyle as CellStyle,
      ),
    };

    return col;
  });
};
