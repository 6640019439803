import type { IRowNode } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback } from 'react';

import { useDisclosure } from '@nextui-org/react';

import type { APIParams, dice } from '@org/query';
import { aggregated, rave } from '@org/query';

import { defaultColDef, useColumns } from './useColumns';

export interface UseControllerProps {
  accountId?: string;
  costCenterName?: string;
  recalculate: (masterConfig?: dice.MasterConfiguration) => Promise<void>;
  modalGridRef: RefObject<AgGridReact>;
  handleClose: (discardChanges?: boolean) => void;
  yearId: string;
  transactionsPresentToFlush: boolean;
  setTransactionsPresentToFlush: (val: boolean) => void;
  isOpen: boolean;
  onOpenChange: () => void;
  apiParams: APIParams<
    'masterConfigurationId' | 'yearId' | 'publicFacilityId' | 'clientId' | 'type'
  >;
  accounts?: rave.AccountBase[];
  costTypeAccount?: string;
}

const FIXED_COLUMNS = new Set([
  'expenses_revenues_before_correction',
  'correction',
  'expenses_revenues_after_correction',
]);

export const useController = (props: UseControllerProps) => {
  const {
    accountId,
    yearId,
    apiParams,
    modalGridRef,
    handleClose,
    setTransactionsPresentToFlush,
    costCenterName,
    costTypeAccount,
    accounts,
  } = props;
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);
  const { costTypeAccountConfig } = aggregated.useCostTypeAccounts(apiParams);

  const { recalculateReport, isRecalculating } = aggregated.useReport(apiParams);

  const { year } = aggregated.useYear(apiParams);

  const accountIds = accountId
    ? [accountId]
    : (accounts
        ?.filter((account) => account.costTypeName === costTypeAccount)
        .map((account) => account.accountId)
        .filter(Boolean) as string[]);

  const { data: transactions } = rave.useTransactionsAccounts(
    {
      body: masterConfiguration!,
      queryParams: {
        accountIds,
        yearId,
      },
    },
    {
      enabled: !!masterConfiguration && accountIds?.length > 0,
      select: (data) => data?.toSorted((a, b) => Number(a.index ?? 0) - Number(b.index ?? 0)),
    },
  );

  const selectedAdjustedAccount = costTypeAccountConfig?.costTypeAccounts
    ?.flatMap(({ adjustedAccountIds }) => adjustedAccountIds)
    .includes(accountId);

  const discardChanges = useCallback(() => {
    modalGridRef?.current?.props?.rowData?.forEach((row) => delete row._updatedData);
    setTransactionsPresentToFlush(false);
    handleClose(true);
  }, [handleClose, modalGridRef, setTransactionsPresentToFlush]);

  const columnDefs = useColumns({
    apiParams,
    setTransactionsPresentToFlush,
  });

  const disclosureState = useDisclosure();

  const saveTransactionModificationBulks = useCallback(
    (
      originalTransactions: dice.AccountTransaction[],
      updatedData: Partial<dice.TransactionModification>[],
    ) => {
      const transactionModifications = originalTransactions.reduce(
        (modifications, originalTransaction, currentIndex) => {
          const { index, clientsCostCenterName } = originalTransaction;
          const newModification: dice.TransactionModification = {
            clientsCostCenterName,
            index,
            originalTransaction,
            ...updatedData[currentIndex],
          };
          const originalModificationIndex = modifications.findIndex((mod) => mod.index === index);

          const filteredTransactions =
            originalModificationIndex === -1
              ? modifications
              : modifications.toSpliced(originalModificationIndex, 1);
          const mergedModification: dice.TransactionModification = {
            ...modifications[originalModificationIndex],
            ...newModification,
            originalTransaction:
              modifications[originalModificationIndex]?.originalTransaction ??
              newModification.originalTransaction,
          };

          if (
            mergedModification.mappedCostCenterLongName ??
            mergedModification.mappedCostCenterShortName ??
            !mergedModification.enabled
          ) {
            filteredTransactions.push(mergedModification);
          }

          return filteredTransactions;
        },
        masterConfiguration?.transactionModifications ?? [],
      );

      return {
        ...masterConfiguration!,
        transactionModifications,
      };
    },
    [masterConfiguration],
  );

  const recalculate = useCallback(
    async (selectedMasterConfiguration?: dice.MasterConfiguration) => {
      await recalculateReport({
        masterConfiguration: selectedMasterConfiguration,
        year,
      });
    },
    [recalculateReport, year],
  );

  const doesExternalFilterPass = useCallback(
    ({ data }: IRowNode<rave.AccountTransaction>) => {
      if (!data) {
        return true;
      }

      return data.mappedCostCenterShortname === costCenterName;
    },
    [costCenterName],
  );

  const isExternalFilterPresent = useCallback(
    () => !FIXED_COLUMNS.has(costCenterName ?? ''),
    [costCenterName],
  );

  return {
    ...props,
    accountIds,
    columnDefs,
    defaultColDef,
    discardChanges,
    disclosureState,
    doesExternalFilterPass,
    isExternalFilterPresent,
    isRecalculating,
    modalGridRef,
    recalculate,
    recalculateReport,
    saveTransactionModificationBulks,
    selectedAdjustedAccount,
    transactions,
    updateMasterConfiguration,
  };
};

export type ViewProps = ReturnType<typeof useController>;
