import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';

import { AccountIdsTableCheckbox, SelectAllCheckbox } from './components';

interface UseColumnsProps {
  accountIds: string[];
  onSelectionChanged: (selection: string[]) => Promise<void>;
  rowData: dice.AccountDTO[];
}

export const useColumns = ({ accountIds, rowData, onSelectionChanged }: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellRenderer: ({ data, value }: ICellRendererParams) => (
            <AccountIdsTableCheckbox
              accountIds={accountIds}
              data={data}
              onSelectionChanged={onSelectionChanged}
              value={value}
            />
          ),
          colId: 'accountId',
          field: 'accountId',
          flex: 2,
          headerComponent: () => (
            <div className="ml-2 flex space-x-2">
              <SelectAllCheckbox
                accountIds={accountIds}
                onSelectionChanged={onSelectionChanged}
                rowData={rowData}
              />
              <div>{t('main:manageMenu.allocationKeysConfiguration.methodEnum.ACCOUNTID')}</div>
            </div>
          ),
        },
      ] satisfies ColDef<dice.AccountDTO>[],
    [accountIds, onSelectionChanged, rowData, t],
  );
};
