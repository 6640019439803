// @ts-check
import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import { ConfigurationPage } from '@org/pages';
import { aggregated } from '@org/query';

import { FILE_CONTENT_DEFAULT } from '../../components/Clients/FileContentPanel/helper';
import { CONFIG_ITEMS } from '../../components/Clients/Menu/Utils/configMenuItems';
import WorkflowMenu from '../../components/Clients/Menu/WorkflowMenu';
import ConfigurationPanel from '../../components/ManageMenu/ConfigurationPanel';
import ErrorBoundary from '../../utils/ErrorHandling/ErrorBoundary';
import { StyledWrapper } from '../styled/StyledComponents';

export const Configuration = () => {
  const {
    configId,
    clientId,
    publicFacilityId = '',
    type = '',
    yearId: yearCalculationId = '',
  } = useParams();

  const { year: selectedYear = {} } = aggregated.useYear({
    publicFacilityId,
    type,
    yearId: yearCalculationId,
  });

  const { masterConfiguration: configuration } = aggregated.useMasterConfiguration({
    masterConfigurationId: configId,
  });

  const [configItem, setConfigItem] = useState(configId ? CONFIG_ITEMS[0].value : '');
  const [fileContentToShow, setFileContentToShow] = useState(FILE_CONTENT_DEFAULT);

  useEffect(() => {
    if (configId && !configItem) {
      setConfigItem(CONFIG_ITEMS[0].value);
    }
  }, [configItem, configId]);

  const handleConfigItemClick = (selectedConfigItem) => {
    setConfigItem(selectedConfigItem);
    setFileContentToShow(FILE_CONTENT_DEFAULT);
  };

  return (
    <StyledWrapper>
      <ErrorBoundary>
        <div className="mt-1 grid h-full w-full grid-cols-[1fr_5fr] grid-rows-1 gap-3 p-0">
          <WorkflowMenu
            configItem={configItem}
            selectedConfigId={configId}
            setConfigItem={handleConfigItemClick}
          />
          <div className="h-full w-full">
            {selectedYear && !configId && !fileContentToShow.show && (
              <ConfigurationPage
                LinkComponent={Link}
                clientId={clientId ?? ''}
                getLinkProps={(configurationId) => ({
                  to: `/client/${clientId}/${publicFacilityId}/${yearCalculationId}/${type}/${configurationId}/configuration`,
                })}
                masterConfigurationId={configId ?? ''}
                publicFacilityId={publicFacilityId}
                type={type === 'pre' ? 'pre' : 'post'}
                yearId={yearCalculationId}
              />
            )}

            {selectedYear && configId && configuration && !fileContentToShow.show && (
              <ConfigurationPanel
                configItem={configItem}
                selectedYear={selectedYear}
              />
            )}
          </div>
        </div>
      </ErrorBoundary>
    </StyledWrapper>
  );
};
