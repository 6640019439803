import type { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback, useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';

import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId'>;
  selectedPlannedDirectEntryId?: string;
  gridRef: RefObject<AgGridReact>;
  selectedPlannedAccountEntry?: dice.PlannedAccountEntryDTO;
  currentMethodType: dice.PlannedDirectEntryDistributionConfigDTO['allocationMethod'];
  updateAndReplacePlannedCostRevenueDirectEntry: (
    updatedEntry: dice.CostUnitAllocationBasisConfigEntryDTO,
  ) => Promise<void>;
}

const defaultColDef = {
  cellClass: ({ node: { level } }) => (level === 0 ? 'bg-highlighted font-bold' : 'text-right'),
  checkboxSelection: false,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
} satisfies ColDef;

export const useController = ({
  apiParams,
  selectedPlannedDirectEntryId,
  selectedPlannedAccountEntry,
  updateAndReplacePlannedCostRevenueDirectEntry,
  currentMethodType,
  gridRef,
}: ControllerProps) => {
  const { t } = useTranslation();

  const { getPlannedDirectEntry } = aggregated.usePlannedCostRevenue(apiParams);

  const selectedPlannedDirectEntry = getPlannedDirectEntry({
    plannedAccountEntryId: selectedPlannedAccountEntry?.id ?? '',
    plannedCostTypeAccountEntryId: selectedPlannedDirectEntryId ?? '',
  });

  const { preselectedAllocations } = aggregated.useAllocationKeys(apiParams);

  const ccOptions = useMemo(
    () =>
      preselectedAllocations?.costCenters?.map((ccItem) => ({
        label: ccItem.longName ?? '',
        value: ccItem.shortName ?? '',
      })) ?? [],
    [preselectedAllocations],
  );

  const rowData = useMemo(
    () =>
      Object.entries(selectedPlannedDirectEntry?.distributionConfig?.allocationMapping ?? {}).map(
        ([shortName, percent]) => ({
          originalShortName: shortName,
          percent,
          shortName,
        }),
      ),
    [selectedPlannedDirectEntry?.distributionConfig?.allocationMapping],
  );

  const { onGridReady } = useAgGridData({
    agGridRef: gridRef,
    data: rowData,
  });

  const handleAddNewRow = useCallback(() => {
    gridRef.current?.api.applyTransaction({
      add: [{ percent: 0, shortName: null }],
    });
  }, [gridRef]);

  const handleRemoveCC = async (data: Record<string, string>) => {
    const selected = selectedPlannedDirectEntry?.distributionConfig?.allocationMapping;

    const deletedShortName = data.shortName;
    delete selected?.[deletedShortName];

    gridRef.current?.api.applyTransaction({ remove: [data] });

    const updatedDirectEntry = {
      ...selectedPlannedDirectEntry,
      distributionConfig: {
        ...selectedPlannedDirectEntry?.distributionConfig,
        allocationMethod: currentMethodType,
        allocationMapping: selected,
      },
    };

    await updateAndReplacePlannedCostRevenueDirectEntry(updatedDirectEntry);
  };

  const handleOnChange = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      data: eventData,
      oldValue,
    } = event;

    if (!colName) {
      return;
    }

    const { originalShortName, shortName, percent } = eventData;

    // take copy - object will be mutated
    const copy = {
      ...selectedPlannedDirectEntry?.distributionConfig?.allocationMapping,
    };

    if (copy) {
      if (originalShortName !== shortName) {
        delete copy?.[originalShortName];
      }

      copy[shortName] = percent;

      const sum = Object.entries(copy ?? {}).reduce((acc, [, value]) => (acc += value), 0);

      if (sum > 100) {
        gridRef.current?.api.applyTransaction({
          update: [{ ...eventData, percent: oldValue ?? 0 }],
        });
        return showNotification(
          'error',
          t('main:manageMenu.allocationKeysConfiguration.maxManualPercent'),
        );
      }

      const updatedDirectEntry = {
        ...selectedPlannedDirectEntry,
        distributionConfig: {
          ...selectedPlannedDirectEntry?.distributionConfig,
          allocationMethod: currentMethodType,
          allocationMapping: copy,
        },
      };

      gridRef.current?.api.applyTransaction({ update: [eventData] });
      await updateAndReplacePlannedCostRevenueDirectEntry(updatedDirectEntry);
    }
  });

  const { modal, openModal } = useDeleteRowConfirmModal<Record<string, string>>({
    onConfirm: handleRemoveCC,
  });

  const columnDefs = useColumns({
    ccOptions,
    disabledOptions: Object.entries(
      selectedPlannedDirectEntry?.distributionConfig?.allocationMapping ?? {},
    ).map(([label]) => label),
    handleAddNewRow,
    openDeleteModal: openModal,
  });

  return {
    columnDefs,
    defaultColDef,
    handleOnChange,
    modal,
    onGridReady,
    selectedPlannedAccountEntry,
    gridRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
