import type { ExcelStyle } from 'ag-grid-community';

export const defaultExcelStyles: ExcelStyle[] = [
  {
    id: 'font-bold',
    font: {
      bold: true,
    },
  },
  {
    id: 'header',
    font: {
      bold: true,
    },
  },
  {
    id: 'white-background',
    interior: {
      color: '#ffffff',
      pattern: 'Solid',
    },
  },
  {
    id: 'gray-lightest-background',
    interior: {
      color: '#f7f7f7',
      pattern: 'Solid',
    },
  },
  {
    id: 'excel-bg-highlighted',
    interior: {
      color: '#f5dacb',
      pattern: 'Solid',
    },
  },
  {
    id: 'bg-highlighted',
    interior: {
      color: '#f5dacb',
      pattern: 'Solid',
    },
  },
  {
    id: 'alignment-indent-1',
    alignment: {
      indent: 1,
    },
    dataType: 'String',
  },
  {
    id: 'alignment-indent-2',
    alignment: {
      indent: 2,
    },
    dataType: 'String',
  },
  {
    id: 'alignment-indent-3',
    alignment: {
      indent: 3,
    },
    dataType: 'String',
  },
  {
    id: 'string-type',
    dataType: 'String',
  },
  {
    id: 'align-right',
    alignment: {
      horizontal: 'Right',
    },
  },
  {
    id: 'ag-wrap-words',
    alignment: {
      wrapText: true,
    },
  },
  {
    id: 'accounting-number',
    numberFormat: {
      // Formats value to the accounting format
      // * when value is a postive or a negative number with or without euro:
      //   formats with '.' as thousands separator, to 2 decimal places, without euro and with alignment
      // * when value is 0: formats to the '-'
      // * when value is a text: displays as it is
      // https://support.microsoft.com/en-us/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68
      format: '_-#,##0.00 _€_-;-#,##0.00 _€_-;_-* -?? _€_-;_-@_-',
    },
  },
  {
    id: 'number',
    numberFormat: {
      // Formats value to the number format
      // * when value is a number:
      //   formats with '.' as thousands separator and to 2 decimal places
      // * when value is a text: displays as it is
      // https://support.microsoft.com/en-us/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68
      format: '#,##0.00;-#,##0.00;@',
    },
  },
];
