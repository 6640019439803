import type { GridApi } from 'ag-grid-community';

// TODO Add tests
export const prepareGroupRowNamesFromGridAPI = (
  gridApi: GridApi | undefined,
  columnWithNames: string,
  level = 0,
) => {
  const names: string[] = [];
  gridApi?.forEachNode((row) =>
    row.level === level && row.field === columnWithNames ? names.push(row?.key ?? '') : '',
  );

  return names;
};
