import { Accordion, AccordionItem } from '@nextui-org/react';
import { Controller } from 'react-hook-form';

import { Combobox, ComboboxItem } from '@org/design';
import { useTranslation } from '@org/locales';
import { Input, Upload, VirtualizedList } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const View = ({
  ErrorLine,
  control,
  errors,
  responseErrorItems,
  handleFileUpload,
  isLoading,
  dataSources,
  alreadyUploadedFileForYear,
}: ViewProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Controller
        control={control}
        name="dataSource"
        render={({ field: { onChange, value, ref: _ref, ...rest } }) => (
          <Combobox
            {...rest}
            aria-label="Index Factor"
            onSelectionChange={onChange}
            selectedKey={value}
          >
            {dataSources?.map((item) => <ComboboxItem key={item}>{item}</ComboboxItem>)}
          </Combobox>
        )}
      />
      <Controller
        control={control}
        name="year"
        render={({ field }) => (
          <Input
            {...field}
            className="pt-3"
            errorMessage={
              !!errors.year ? t('main:adminMenu.indexFactors.alerts.warning.invalidYear') : null
            }
            isDisabled={isLoading}
            isInvalid={!!errors.year}
            isRequired
            label={t('main:adminMenu.indexFactors.placeholderInputYear')}
            labelPlacement="outside"
            onChange={(event) => {
              const {
                target: { value: targetValue },
              } = event;
              field.onChange(targetValue);
            }}
            radius="none"
            type="number"
            value={field.value ?? ''}
            variant="default"
          />
        )}
        rules={{ max: 2999, min: 1900, required: true }}
      />

      <div className="flex h-7 items-center text-xs text-red-500">
        {alreadyUploadedFileForYear && t('main:adminMenu.indexFactors.fileAlreadyUploaded')}
      </div>

      <Upload
        acceptedFileTypes={['text/csv']}
        isBtnDisabled={isLoading || !!errors.year}
        isUploading={isLoading}
        onUpload={handleFileUpload}
      >
        <div className="flex flex-col">
          <span className="pt-4 text-orange-default">
            {t('main:adminMenu.indexFactors.upload.hint1')}
          </span>
          <span className="pt-4 text-gray-500 underline">
            {t('main:adminMenu.indexFactors.upload.hint2')}
          </span>
        </div>
      </Upload>

      {responseErrorItems.length > 0 && (
        <Accordion
          className="mt-4"
          defaultExpandedKeys={['1']}
          isCompact
          itemClasses={{
            base: 'py-0 w-full',
            content: 'text-small px-2',
            indicator: 'text-medium',
            title: 'font-normal text-medium',
            trigger:
              'px-2 py-0 bg-default-50 data-[hover=true]:bg-default-100 h-14 flex items-center border-b-1 border-solid border-slate-300',
          }}
          variant="bordered"
        >
          <AccordionItem
            key="1"
            title={t('main:adminMenu.indexFactors.alerts.error.validationError', {
              count: responseErrorItems.length,
            })}
          >
            <VirtualizedList
              Component={ErrorLine}
              height={120}
              itemCount={responseErrorItems.length}
            />
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};

export const IndexFactorsUpload = bindControllers(View, [useController]);
IndexFactorsUpload.displayName = 'IndexFactorsUpload';
