import type {
  CellClassParams,
  ColDef,
  ColGroupDef,
  IAggFunc,
  RowClassParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import type { aggregated, APIParams } from '@org/query';
import { useCustomLabels } from '@org/hooks';
import { useTranslation } from '@org/locales';
import {
  addPriceClassForCorrectType,
  generateColumnGroups,
  getAlignmentIndentClass,
  getCellClasses,
} from '@org/utils';

export const boldClasses = (params: CellClassParams | RowClassParams) =>
  params.node.level === 0 || params.node.level < 2 ? 'font-bold' : '';

const aggFunc: IAggFunc = (params) => {
  if (params.rowNode.level === 1) {
    return (params.values.at(0) ?? 0) - (params.values.at(1) ?? 0);
  }
  const sum = params.values
    .filter((item = 0) => typeof item === 'number')
    .reduce((acc, item = 0) => acc + item, 0);
  return sum;
};

export const defaultColDef: ColDef = {
  aggFunc,
  cellClass: getCellClasses(boldClasses),
  cellRendererParams: {
    suppressCount: true,
  },
  flex: 1,
  minWidth: 100,
  resizable: true,
  sortable: false,
  suppressHeaderMenuButton: true,
  suppressMovable: true,
  tooltipValueGetter: (params) => params.value,
  width: 100,
};

interface UseControllerProps {
  report?: aggregated.ExtendedReportObjectsResponse;
  apiParams: APIParams<'masterConfigurationId'>;
}

export const useColumns = ({ report, apiParams }: UseControllerProps) => {
  const { t } = useTranslation();
  const getCustomLabel = useCustomLabels(apiParams);

  const { indirectCostCentersGrouped, primaryCostCentersGrouped } = report ?? {};

  return useMemo(
    () =>
      [
        {
          children: [
            {
              cellClass: getCellClasses(getAlignmentIndentClass, boldClasses),
              cellRenderer: 'agGroupCellRenderer',
              colId: 'rowName',
              flex: 1,
              headerName: '',
              minWidth: 200,
              pinned: 'left',
              showRowGroup: true,
              valueGetter: ({ node }) =>
                getCustomLabel(
                  `tradeTax.${node?.data?.rowName}`,
                  t(`main:reportPanels.tradeTax.tableRows.${node?.data?.rowName}`),
                ),
              width: 300,
            },
            {
              colId: 'group',
              field: 'group',
              hide: true,
              rowGroup: true,
              valueGetter: ({ data }) =>
                data.group &&
                getCustomLabel(
                  `tradeTax.${data.group}`,
                  t(`main:reportPanels.tradeTax.tableRows.${data.group}`),
                ),
            },
            {
              colId: 'subGroup',
              field: 'subGroup',
              hide: true,
              rowGroup: true,
              valueGetter: ({ data }) =>
                data.subGroup &&
                getCustomLabel(
                  `tradeTax.${data.subGroup}`,
                  t(`main:reportPanels.tradeTax.tableRows.${data.subGroup}`),
                ),
            },
            {
              cellClass: getCellClasses(addPriceClassForCorrectType, boldClasses),
              colId: 'total',
              field: 'total',
              headerName: t('common:total'),
              pinned: 'left',
              type: 'report-price',
            },
          ],
          headerName: '',
          pinned: 'left',
        },
        ...(indirectCostCentersGrouped
          ? ([
              {
                children: generateColumnGroups({
                  cellClass: getCellClasses(boldClasses),
                  costCentersGroups: indirectCostCentersGrouped,
                  t,
                  type: 'INDIRECT_COST_CENTER',
                }),
                headerClass: 'white-background',
                headerName: t('common:supportCostCenter'),
                pinned: 'left',
              },
            ] satisfies (ColDef | ColGroupDef)[])
          : []),
        ...(primaryCostCentersGrouped
          ? ([
              {
                children: generateColumnGroups({
                  cellClass: getCellClasses(boldClasses),
                  costCentersGroups: primaryCostCentersGrouped,
                  isSeparated: true,
                  t,
                  type: 'PRIMARY_COST_CENTER',
                }),
                headerClass: 'white-background border-left-2px',
                headerName: t('common:mainCostCenter'),
                pinned: 'left',
              },
            ] satisfies (ColDef | ColGroupDef)[])
          : []),
      ] satisfies (ColDef | ColGroupDef)[],
    [getCustomLabel, indirectCostCentersGrouped, primaryCostCentersGrouped, t],
  );
};
