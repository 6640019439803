import type { ColDef, ExcelExportParams, GetRowIdFunc } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { APIParams } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';
import { ExcelReportType, getCellClasses, getDefaultExcelExportParams } from '@org/utils';

import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<
    'masterConfigurationId' | 'yearId' | 'clientId' | 'publicFacilityId' | 'type'
  >;
}

export type ControllerType = ReturnType<typeof useController>;

const getRowId: GetRowIdFunc = ({ data }) => String(data.rowIndex);

const defaultColDef = {
  cellClass: getCellClasses(),
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  useValueFormatterForExport: true,
  cellRendererParams: {
    suppressCount: true,
  },
  sortable: false,
  suppressHeaderMenuButton: true,
} satisfies ColDef;

export function useController({ apiParams }: ControllerProps) {
  const { clientId } = apiParams;
  const { publicFacility } = aggregated.usePublicFacilities(apiParams);
  const { year } = aggregated.useYear(apiParams);
  const columnsDefs = useColumns();
  const gridRef = useRef<AgGridReact>(null);

  const { priceSheetGroups = [], isLoading } = aggregated.usePriceSheetReport(apiParams);

  const { getClient } = aggregated.useClients({});

  const handleExport = useCallback(() => {
    gridRef.current?.api?.exportDataAsExcel();
  }, []);

  const defaultExcelExportParams = useMemo<ExcelExportParams>(
    () =>
      getDefaultExcelExportParams(
        `${getClient(clientId)?.clientName}_${publicFacility?.name ?? ''}_${year?.year ?? ''}_Preisblatt`,
        ExcelReportType.PRICE_SHEET,
      ),
    [clientId, getClient, publicFacility?.name, year?.year],
  );

  const { onGridReady, rowData } = useAgGridData({
    agGridRef: gridRef,
    data: priceSheetGroups,
  });

  return {
    columnsDefs,
    defaultColDef,
    isLoading,
    handleExport,
    getRowId,
    gridRef,
    defaultExcelExportParams,
    onGridReady,
    rowData,
  };
}
