import { useState } from 'react';

import { Button, Input } from 'appkit-react';
import _ from 'lodash';

import { showNotification } from '@org/ui';

import { sendPost } from '../../utils/apiCalls';
import {
  _getClientID,
  _getClientSecret,
  _getLoginUrl,
  _getOrgId,
  _getPiamConfig,
} from '../../utils/env-url';

import './Auth.scss';

const emailPattern = /^\w.+@[\w-]+?(\.[A-Za-z]+)?\.[A-Za-z]{2,3}(.[A-Za-z]{2,3})?$/;

const LandingPage = ({ userLogin = () => {} }) => {
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (emailValue) => {
    if (emailValue.trim() !== '' && emailPattern.test(emailValue)) {
      setEmailError('');
    }
    setUserEmail(emailValue);
  };

  const handleGetAccess = () => {
    var isEmailValid = false;
    if (userEmail.trim() === '') {
      setEmailError('Please enter your email id');
    } else if (!emailPattern.test(userEmail)) {
      setEmailError('Please enter a valid email id');
    } else {
      isEmailValid = true;
      setEmailError('');
    }
    if (isEmailValid) {
      selfInviteUser();
      setUserEmail('');
    }
  };

  const selfInviteUser = async () => {
    try {
      const piamConfig = _getPiamConfig();
      const payload = {
        email: userEmail,
        emailLanguage: piamConfig.emailLanguage,
        orgId: _getOrgId(),
        product: _getClientID(),
        role: piamConfig.role,
        state: piamConfig.state,
      };

      sendPost('/productIAM/invite', payload).then((res) => {
        if (res.status === 'SUCCESS') {
          showNotification('success', res.description);
        } else {
          showNotification('error', res.description);
        }
      });
    } catch (error) {
      showNotification('error', error?.message || 'Something went wrong!');
    }
  };

  const handleLogin = () => {
    userLogin();
  };

  return (
    <div className="landing-page">
      <div className="pwc-logo">
        <div className="a-pwc-full-logo-grid a-md" />
      </div>
      <div className="login-container">
        <p className="apkt-heading-m">Gebühren- & Entgeltkalkulator</p>
        <p className="apkt-body having-back">It's nice to have you back!</p>
        <p className="apkt-label">Email</p>
        <Input
          className="new-user-email"
          onChange={handleEmailChange}
          type="text"
          value={userEmail}
        />
        <p className="apkt-error-label">{emailError}</p>
        <Button
          className="get-access-btn"
          kind="secondary"
          onClick={handleGetAccess}
        >
          Request Access
        </Button>

        <div className="custom-divider">
          <hr
            className="solid"
            style={{ width: '40%' }}
          />
          <p>OR</p>
          <hr
            className="solid"
            style={{ width: '40%' }}
          />
        </div>
        <Button
          className="login-btn"
          kind="primary"
          onClick={handleLogin}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default LandingPage;
