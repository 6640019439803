import type { TFunction } from '@org/locales';
import type { ErrorWrapper } from '@org/query';

export function getErrorMessage(error: ErrorWrapper<Error> | undefined, t: TFunction) {
  const errorMessage = t('common:alerts.error.general');
  if (!error) {
    return errorMessage;
  }
  if (error instanceof Error) {
    return error.message;
  }

  return error.payload ?? errorMessage;
}
