import { objGet, objSet } from './object';

export const getCostCenterLongNameWithShortName = (selectedConfiguration, ccShortName) => {
  const costCenterMapping = selectedConfiguration?.costCenterConfig?.costCenterMapping ?? [];
  const ccShortNameToLongNameMapping = costCenterMapping.reduce(
    (prev, curr) => objSet(prev, curr.shortName, curr.longName),
    {},
  );

  return objGet(ccShortNameToLongNameMapping, ccShortName) ?? '';
};
