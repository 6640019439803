import { Controller } from 'react-hook-form';

import { Combobox, ComboboxItem } from '@org/design';
import { useTranslation } from '@org/locales';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const View = (props: ViewProps) => {
  const { t } = useTranslation();
  const { options, control, handleTechnicalDataSelection } = props;
  return (
    <Controller
      control={control}
      name="technicalData"
      render={({ field }) => {
        const { value, ...other } = field;
        return (
          <Combobox
            {...other}
            aria-label="technicalData"
            isDisabled={false}
            isFullWidth
            isMultiple={false}
            onSelectionChange={handleTechnicalDataSelection(field)}
            placeholder={t(
              'main:manageMenu.allocationKeysConfiguration.placeholderSelectTechnicalData',
            )}
            selectedKey={value}
          >
            {options.map(({ label, value: key }) => (
              <ComboboxItem key={key}>{label}</ComboboxItem>
            ))}
          </Combobox>
        );
      }}
    />
  );
};

export const TechnicalData = bindControllers(View, [useController]);
TechnicalData.displayName = 'TechnicalData';
