/* INCA DOES NOT HAVE SWAGGER -> IDEALLY USE DICE - BUT AS OF NOW ERROR HANDLING IS NOT FIXED */
import type { QueryKey, UseMutationOptions } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useMutation } from '@tanstack/react-query';

import type { QueryOperation } from '../dice';
import { buildFetcher } from '../../common';

const incaFetcher = buildFetcher('/incaApi');

export interface ImportWiberaIndicesRequest {
  indexFactors: Record<string, unknown>[];
  year: number;
}

export type ImportWiberaIndicesError =
  | undefined
  | {
      status: number;
      statusText: 'unknown' | (string & {});
      payload: string;
    };

interface FetchOptions {
  fetcherOptions: {
    /**
     * Headers to inject in the fetcher
     */
    headers?: {};
    /**
     * Query params to inject in the fetcher
     */
    queryParams?: {};
  };
  queryOptions: {
    /**
     * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
     * Defaults to `true`.
     */
    enabled?: boolean;
  };
  /**
   * Query key manager.
   */
  queryKeyFn: (operation: QueryOperation) => QueryKey;
}

export type ImportWiberaIndicesVariables = {
  body: ImportWiberaIndicesRequest;
} & Partial<FetchOptions>;

export const fetchImportWiberaIndices = (
  variables: ImportWiberaIndicesVariables,
  signal?: AbortSignal,
) =>
  incaFetcher<unknown, ImportWiberaIndicesError, ImportWiberaIndicesRequest, {}, {}, {}>({
    method: 'post',
    url: '/indexfactor',
    ...variables,
    signal,
  });

export const useImportWiberaIndices = (
  options?: Omit<
    UseMutationOptions<unknown, ImportWiberaIndicesError, ImportWiberaIndicesVariables>,
    'mutationFn'
  >,
) => {
  const fetcherOptions = {};
  return useMutation<unknown, ImportWiberaIndicesError, ImportWiberaIndicesVariables>({
    mutationFn: (variables: ImportWiberaIndicesVariables) =>
      fetchImportWiberaIndices({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export const useIndexFactors = () => {
  const { mutateAsync: importWiberaIndicesAsync, isPending } = useImportWiberaIndices({
    onSettled: () => {},
  });

  const importWiberaIndices = useCallback(
    async (body: ImportWiberaIndicesRequest) =>
      await importWiberaIndicesAsync({
        body,
      }),
    [importWiberaIndicesAsync],
  );

  return {
    importWiberaIndices,
    isLoading: isPending,
  };

  // TBD: USE THIS IF BE ERROR HANDLING IS FIXED IN RESPONSE

  // const { mutateAsync: importWiberaIndicesAsync, isPending } = usePostIndexFactors({
  //   onSettled: () => {},
  // });
  //
  // const importWiberaIndices = useCallback(
  //   async (body: IndexFactorConfig) =>
  //     await importWiberaIndicesAsync({
  //       body,
  //     }),
  //   [importWiberaIndicesAsync],
  // );
  //
  // return {
  //   importWiberaIndices,
  //   isLoading: isPending,
  // };
};
