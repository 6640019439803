import type { FC } from 'react';
import { createElement } from 'react';

import classnames from 'classnames';

import styles from './Label.module.css';

type OuterProps = React.JSX.IntrinsicElements['label'];

export type LabelProps = {
  intent?: 'default' | 'error';
  as?: keyof React.JSX.IntrinsicElements;
} & OuterProps;

export const Label: FC<LabelProps> = (props) => {
  const { className, children, intent = 'default', as = 'label', ...otherProps } = props;

  return createElement(
    as,
    {
      className: classnames(styles.Label, className, 'select-none', {
        '': intent === 'default',
        'text-red-600': intent === 'error',
      }),
      'data-testid': 'Label',
      ...otherProps,
    },
    children,
  );
};
