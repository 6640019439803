import type { ExcelExportParams } from 'ag-grid-community';

export enum ExcelReportType {
  BAB = 'BAB',
  INTEREST = 'Zinstableau',
  COST_UNIT = 'KTR',
  TRADE_TAX = 'Gewerbesteuer',
  PRICE_SHEET = 'Preisblatt',
}

export const getDefaultExcelExportParams = (
  fileName: string,
  reportType: ExcelReportType,
): ExcelExportParams => ({
  fileName,
  processRowGroupCallback: (params) => params.node.key ?? '',
  sheetName: reportType,
});
