import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { getCellClassesForGroups, getCellClassesForGroupsWithActions } from '@org/utils';

export interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId'>;
  addNewSubGroup: (type: dice.TechnicalDataDTO['type']) => unknown;
  deleteSubGroup: (type: dice.TechnicalDataDTO['type'], name: string) => unknown;
  deleteItem: (id: string) => unknown;
  addItem: (props: {
    type: dice.TechnicalDataDTO['type'];
    name: string;
    groupId?: string;
  }) => unknown;
}

export const useColumns = ({
  apiParams,
  addNewSubGroup,
  addItem,
  deleteItem,
  deleteSubGroup,
}: UseColumnsProps) => {
  const { getCostCenterLongName, costCenterOptions } = aggregated.useCostCenters(apiParams);
  const { costUnitOptions, getCostUnitLongName } = aggregated.useCostUnit(apiParams);
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          colId: 'type',
          field: 'type',
          hide: true,
          rowGroup: true,
          valueFormatter: ({ value }) => t(`main:manageMenu.manageTechnicalData.${value}`),
        },
        {
          aggFunc: ({ values }) => values.at(0),
          colId: 'groupId',
          field: 'groupId',
          hide: true,
        },
        {
          cellClass: getCellClassesForGroups,
          cellRenderer: 'agGroupCellRenderer',
          colId: 'name',
          editable: ({ node }) => node.level === 1,
          field: 'name',
          headerName: '',
          rowGroup: true,
          showRowGroup: true,
          valueFormatter: ({ value, node }) => {
            if ((node?.level ?? 0) < 2) {
              return value;
            }
            return '';
          },
          valueSetter: ({ newValue, node, api }) => {
            node?.allLeafChildren?.forEach((child) => {
              if (!child.data) {
                return;
              }
              child.data.name = newValue;
              api.applyTransaction({
                update: [child.data],
              });
            });
            return true;
          },
        },
        {
          aggFunc: ({ rowNode, values }) => {
            if (rowNode.level === 1) {
              return values.at(0);
            }
          },
          cellClass: (params) => [...getCellClassesForGroups(params), 'text-right'],
          colId: 'unit',
          editable: ({ node }) => node.level === 1,
          field: 'unit',
          headerName: t('main:manageMenu.manageTechnicalData.tableColumns.unit'),
          valueSetter: ({ newValue, node, api }) => {
            node?.allLeafChildren?.forEach((child) => {
              if (!child.data) {
                return;
              }
              child.data.unit = newValue;
              api.applyTransaction({
                update: [child.data],
              });
            });
            return true;
          },
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditorSelector: ({ node }) => {
            if (node.data?.type === 'COST_CENTER') {
              return {
                component: 'select',
                params: {
                  options: costCenterOptions,
                },
              };
            }

            return {
              component: 'select',
              params: {
                options: costUnitOptions,
              },
            };
          },
          colId: 'costCenter_costUnit',
          editable: ({ node }) => node.level === 2,
          headerName: t('main:manageMenu.manageTechnicalData.tableColumns.allocationKey'),
          valueFormatter: ({ data }) => {
            if (data?.type === 'COST_CENTER') {
              return getCostCenterLongName(data.costCenter ?? '');
            }

            if (data?.type === 'COST_UNIT') {
              return getCostUnitLongName(data.costUnit ?? '');
            }

            return data?.costUnit ?? '';
          },
          valueGetter: ({ data }) =>
            data?.type === 'COST_CENTER' ? data.costCenter : data?.costUnit,
          valueSetter: ({ data, newValue }) => {
            if (data.type === 'COST_CENTER') {
              data.costCenter = newValue;
              return true;
            }
            data.costUnit = newValue;
            return true;
          },
        },
        {
          cellClass: (params) => [...getCellClassesForGroups(params), 'ag-cell-type-number'],
          colId: 'amount',
          editable: ({ node }) => node.level === 2,
          field: 'amount',
          headerName: t('main:manageMenu.manageTechnicalData.tableColumns.amount'),
          type: 'number',
          cellEditor: 'numeric',
        },
        {
          cellClass: getCellClassesForGroupsWithActions,
          cellRendererSelector: (params) => {
            const { node } = params;
            if (node?.level === 0) {
              return {
                component: 'multipleButtons',
                params: {
                  buttonParams: [
                    {
                      icon: 'icon-plus-outline',
                      onClick: () => addNewSubGroup(node?.groupData?.name ?? 'costCenter'),
                      size: 'iconButton',
                    },
                  ],
                  className: 'flex',
                },
              };
            }
            if (node.level === 1) {
              return {
                component: 'multipleButtons',
                params: {
                  buttonParams: [
                    {
                      icon: 'icon-plus-outline',
                      onClick: () => {
                        addItem({
                          groupId: node.aggData.groupId,
                          name: node?.groupData?.name,
                          type: node?.parent?.groupData?.name ?? 'COST_CENTER',
                        });
                      },
                      size: 'iconButton',
                    },
                    {
                      icon: 'icon-delete-outline',
                      onClick: () =>
                        deleteSubGroup(
                          node?.parent?.groupData?.name ?? 'COST_CENTER',
                          node?.groupData?.name ?? '',
                        ),
                      size: 'iconButton',
                    },
                  ],
                  className: 'flex',
                },
              };
            }
            return {
              component: 'multipleButtons',
              params: {
                buttonParams: [
                  {
                    icon: 'icon-delete-outline',
                    onClick: () => deleteItem(node.data?.id ?? ''),
                    size: 'iconButton',
                  },
                ],
                className: 'flex',
              },
            };
          },
          colId: 'actions',
          editable: false,
          filter: null,
          headerName: t('common:actions'),
          suppressHeaderMenuButton: true,
        },
      ] satisfies (ColDef<dice.TechnicalDataDTO> | ColGroupDef<dice.TechnicalDataDTO>)[],
    [
      addItem,
      addNewSubGroup,
      costCenterOptions,
      costUnitOptions,
      deleteItem,
      deleteSubGroup,
      getCostCenterLongName,
      getCostUnitLongName,
      t,
    ],
  );
};
