import { Link, useSearchParams } from 'react-router-dom';

import { AdminPage, ClientsOverview as Page } from '@org/pages';

export const ClientsOverviewPage = () => {
  const [params] = useSearchParams();

  // TODO: this is temporal fix
  if (params.get('page') === 'admin') {
    return <AdminPage />;
  }
  return (
    <Page
      LinkComponent={Link}
      getLinkProps={(clientId) => ({ to: `/client/${clientId}` })}
    />
  );
};
