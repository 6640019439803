import type {
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { getCostCenterByShortName } from '@org/utils';

interface UseColumnsProps {
  handleDeleteModal: (props: ButtonCellRendererProps) => void;
  masterConfiguration?: dice.MasterConfiguration;
  imputedConcessionFeeEnabled: boolean;
}

export const useColumns = ({
  masterConfiguration,
  handleDeleteModal,
  imputedConcessionFeeEnabled,
}: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          colId: 'primaryCostCenterShortName',
          field: 'primaryCostCenterShortName',
          filter: 'agTextColumnFilter',
          filterValueGetter: ({ data }: ValueGetterParams) =>
            getCostCenterByShortName(masterConfiguration, data?.primaryCostCenterShortName) ??
            data?.primaryCostCenterShortName,
          floatingFilter: true,
          headerName: t('main:manageMenu.manageImputedConcessionFee.costCenters'),
          valueFormatter: ({ data, value }: ValueFormatterParams) =>
            getCostCenterByShortName(masterConfiguration, data?.primaryCostCenterShortName)
              ?.longName ?? value,
        },
        {
          cellClass: 'align-to-center-and-right',
          cellEditor: 'numeric',
          cellEditorParams: {
            defaultValue: 0,
          },
          colId: 'concessionFeeRate',
          comparator: (valueA: number, valueB: number) => valueA - valueB,
          editable: imputedConcessionFeeEnabled,
          field: 'concessionFeeRate',
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageImputedConcessionFee.concessionFeeRate'),
          type: 'number',
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRenderer: 'multipleButtons',
          cellRendererParams: {
            buttonParams: [
              {
                disabled: !imputedConcessionFeeEnabled,
                icon: 'icon-delete-outline',
                onClick: (props: ButtonCellRendererProps) => handleDeleteModal(props),
                size: 'iconButton',
              },
            ],
          },
          colId: 'actions',
          flex: 3,
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
        },
      ] satisfies (
        | ColDef<dice.CostCenterConcessionFee>
        | ColGroupDef<dice.CostCenterConcessionFee>
      )[],
    [masterConfiguration, handleDeleteModal, t, imputedConcessionFeeEnabled],
  );
};
