import { useCallback, useState } from 'react';

import { useDisclosure } from '@nextui-org/react';

import { useTranslation } from '@org/locales';

import { BaseConfirmationModal } from './components';

interface UseConfirmModalProps<T> {
  initialData?: T;
  onConfirm?: (data: T) => unknown;
  onCancel?: (data: T) => unknown;
  textKeys?: {
    confirmButtonText?: string;
    contentText: string;
    titleText: string;
  };
}

export const useConfirmModal = <T,>(props: UseConfirmModalProps<T>) => {
  const { t } = useTranslation();

  const {
    initialData,
    onCancel,
    onConfirm,
    textKeys: { confirmButtonText = t('common:confirm'), contentText, titleText } = {},
  } = props;
  const [data, setData] = useState<T | undefined>(initialData);

  const { onClose, onOpenChange, isOpen, onOpen } = useDisclosure();

  const openModal = useCallback(
    (openData: T) => {
      setData(openData);
      onOpen();
    },
    [onOpen],
  );
  const closeModal = useCallback(() => {
    setData(undefined);
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm?.(data!);
    closeModal();
  }, [closeModal, data, onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel?.(data!);
    closeModal();
  }, [closeModal, data, onCancel]);

  return {
    closeModal,
    modal: (
      <BaseConfirmationModal
        confirmButtonText={confirmButtonText}
        contentText={contentText}
        isOpen={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        onOpenChange={onOpenChange}
        titleText={titleText}
      />
    ),
    openModal,
  };
};
