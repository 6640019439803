import type { CostCenter } from '../../griddy';
import type { ReportsResponse } from '../../rave';
import { getGroupTotalsOfRow } from './groupTotals';

interface GenerateNonOperatingInterestRateReportProps {
  reportObject: ReportsResponse;
  primaryCostCentersGrouped: [string, CostCenter[]][];
  indirectCostCentersGrouped: [string, CostCenter[]][];
}

export const generateNonOperatingInterestRateReport = ({
  indirectCostCentersGrouped,
  primaryCostCentersGrouped,
  reportObject,
}: GenerateNonOperatingInterestRateReportProps) => {
  const avgRowIndex = reportObject.newInterestTableReport?.nonOperating?.entries?.findIndex(
    ({ description }) => description === 'RBV_CAPITAL_NON_OPERATING_ASSET_AVG',
  );

  const rowData =
    reportObject.newInterestTableReport?.nonOperating?.entries
      ?.toSpliced(avgRowIndex!, 1)
      ?.map((row, rowIndex) => {
        const { ccShortNameToValueMap, description, reportingDate, imputedInterestAmount } = row;
        return {
          ...ccShortNameToValueMap,
          group: 'NON_OPERATING_ASSETS',
          imputedInterestAmount,
          reportingDate,
          description,
          rowIndex,
          subGroup: 'ROW_HEADER_2',
          ...getGroupTotalsOfRow({
            indirectCCGroups: indirectCostCentersGrouped,
            primaryCCGroups: primaryCostCentersGrouped,
            row: {
              costCenterValueMap: ccShortNameToValueMap,
            },
          }),
        };
      }) ?? [];
  return rowData;
};
