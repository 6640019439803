import { createContext, useContext, useState } from 'react';

import { isDev, pdfToUrl } from '../utils';

const StateContext = createContext();

const useFileContext = () => useContext(StateContext);

const FileStateProvider = (props) => {
  const [files, setFiles] = useState([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);

  const saveFiles = (tmpFiles) => {
    if (isDev() && tmpFiles[0].fileName === 'Sample PDF') {
      setFiles(tmpFiles);
      return;
    }

    const processedFiles = tmpFiles.map((file) => ({
      fileName: file.name,
      url: pdfToUrl(file),
    }));
    setFiles(processedFiles);
  };

  return (
    <StateContext.Provider
      value={{
        files,
        saveFiles,
        selectedFileIndex,
        setSelectedFileIndex,
      }}
      {...props}
    />
  );
};

export { useFileContext, FileStateProvider };
