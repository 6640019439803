import type { ColDef, ExcelExportParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useMemo } from 'react';

import type { APIParams, erase } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { ExcelReportType, getDefaultExcelExportParams, replaceSpecificRowLabels } from '@org/utils';

import type { useInterestReportModal } from '../InterestReportModal';
import { getCellClasses } from './helpers';
import { useColumns } from './useColumns';

interface UseControllerProps extends Pick<ReturnType<typeof useInterestReportModal>, 'handleOpen'> {
  apiParams: APIParams<
    'masterConfigurationId' | 'yearId' | 'clientId' | 'publicFacilityId' | 'type'
  >;
  agGridRef: RefObject<AgGridReact>;
  data?:
    | aggregated.OperatingInterestTradeReportData
    | aggregated.NonOperatingInterestTradeReportData;
}

export const getAssetTransactionsByCells = (assets: erase.Asset[]) => {
  const { nonOperatingAssetsTransactionsByCC, operatingAssetsTransactionsByCC } = assets.reduce(
    (acc, current) => {
      const { currentIsOperatingAsset, currentBabCCShortName } = current;

      if (!currentBabCCShortName) {
        return acc;
      }

      const assetTransactions = currentIsOperatingAsset
        ? acc.operatingAssetsTransactionsByCC
        : acc.nonOperatingAssetsTransactionsByCC;

      if (!assetTransactions.has(currentBabCCShortName)) {
        assetTransactions.set(currentBabCCShortName, []);
      }

      assetTransactions.get(currentBabCCShortName)?.push(current);

      return acc;
    },
    {
      nonOperatingAssetsTransactionsByCC: new Map<string, erase.Asset[]>(),
      operatingAssetsTransactionsByCC: new Map<string, erase.Asset[]>(),
    },
  );

  return {
    nonOperatingAssetsTransactionsByCC: Object.fromEntries(nonOperatingAssetsTransactionsByCC),
    operatingAssetsTransactionsByCC: Object.fromEntries(operatingAssetsTransactionsByCC),
  };
};

export const useController = (props: UseControllerProps) => {
  const { handleOpen, data, agGridRef, apiParams } = props;
  const { clientId } = apiParams;
  const { t } = useTranslation();
  const columnDefs = useColumns(apiParams);

  const agGridProps = useAgGridData({
    agGridRef,
    data,
  });

  const defaultColDef = useMemo(
    () =>
      ({
        aggFunc: 'sum',
        cellClass: getCellClasses,
        cellRendererParams: {
          suppressCount: true,
        },
        flex: 1,
        minWidth: 100,
        onCellClicked: (event) => {
          const { field } = event.colDef;
          handleOpen(event, field ?? '');
        },
        resizable: true,
        sortable: false,
        suppressHeaderMenuButton: true,
        tooltipValueGetter: (params) => params.value,
        valueFormatter: ({ value }) => replaceSpecificRowLabels(t)(value),
        width: 100,
      }) satisfies ColDef,
    [handleOpen, t],
  );

  const { year: { year = '' } = {} } = aggregated.useYear(apiParams);
  const { publicFacility: { name = '' } = {} } = aggregated.usePublicFacilities(apiParams);

  const defaultExcelExportParams = useMemo<ExcelExportParams>(
    () =>
      getDefaultExcelExportParams(
        `${clientId}_${name}_${year}_${ExcelReportType.INTEREST}`,
        ExcelReportType.INTEREST,
      ),
    [clientId, name, year],
  );

  return {
    ...props,
    agGridProps,
    agGridRef,
    columnDefs,
    defaultColDef,
    defaultExcelExportParams,
  };
};

export type ViewProps = ReturnType<typeof useController>;
