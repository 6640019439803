import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import { TableCellsIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { formatNumberToLocaleString } from '@org/utils';

interface UseColumnsProps {
  handleAddNew: () => void;
  openDeleteModal: (id: string) => void;
  openTechnicalModal: (id: string) => void;
}

type PlannedDirectEntryDTO = dice.PlannedDirectEntryDTO;

interface PlannedDirectEntryDTOWithTechnicalIcon extends PlannedDirectEntryDTO {
  technicalIcon: string;
}

export const useColumns = ({
  handleAddNew,
  openDeleteModal,
  openTechnicalModal,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          colId: 'name',
          field: 'name',
          editable: true,
          flex: 2,
        },
        {
          colId: 'amount',
          field: 'amount',
          valueFormatter: ({ value }) => formatNumberToLocaleString(value),
          editable: true,
          flex: 1,
        },
        {
          colId: 'technicalIcon',
          field: 'technicalIcon',
          editable: false,
          cellClass: 'align-to-center',
          maxWidth: 60,
          headerName: '',
          filter: false,
          cellRenderer: ({ data }: ICellRendererParams) => (
            <TableCellsIcon
              className={classNames('cursor-pointer', {
                'text-orange-default': data.detail?.selectedTechnicalData && data.detail?.price,
                'text-gray-300': !data.detail?.selectedTechnicalData || !data.detail?.price,
              })}
              height={20}
              onClick={() => openTechnicalModal(data.id)}
              width={20}
            />
          ),
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRendererSelector: () => ({
            component: 'button',
            params: {
              icon: 'icon-delete-outline',
              onClick: (props: ButtonCellRendererProps) => openDeleteModal(props.data.id),
              size: 'iconButton',
            },
          }),
          colId: 'actions',
          editable: false,
          flex: 1,
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            disabled: false,
            icon: 'icon-plus-fill',
            onClick: handleAddNew,
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          minWidth: 55,
        },
      ] satisfies (
        | ColDef<PlannedDirectEntryDTOWithTechnicalIcon>
        | ColGroupDef<PlannedDirectEntryDTOWithTechnicalIcon>
      )[],
    [handleAddNew, openDeleteModal, t, openTechnicalModal],
  );
};
