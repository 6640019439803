import type { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import type { APIParams, dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useAgGridData } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';
import { getCellClassesForGroups } from '@org/utils';

import { AllocationStatusIcon } from '../../../AllocationStatusIcon';
import { ACCOUNT_STATUSES, getSelectedAccountSelectionStatus } from '../../utils';
import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId'>;
  manualTableRef: React.RefObject<AgGridReact>;
  selectedAccountLedger: string;
  updateAccountsAllocation: (accounts: dice.WithdrawCapital['accountsAllocation']) => Promise<void>;
  selectedAccountsAllocation: dice.WithdrawCapital['accountsAllocation'];
}

const defaultColDef = {
  flex: 1,
  checkboxSelection: false,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
} satisfies ColDef;

export const useController = ({
  apiParams,
  manualTableRef,
  selectedAccountLedger,
  updateAccountsAllocation,
  selectedAccountsAllocation,
}: ControllerProps) => {
  const { t } = useTranslation();
  const { costCenterOptions, getCostCenterLongName } = aggregated.useCostCenters(apiParams);

  const keys = useMemo(
    () => Object.keys(selectedAccountsAllocation?.[selectedAccountLedger] ?? {}),
    [selectedAccountsAllocation, selectedAccountLedger],
  );

  const selectedAccountStatus = getSelectedAccountSelectionStatus(keys);

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellRendererParams: {
        suppressCount: true,
      },
      cellClass: getCellClassesForGroups,
      cellRendererSelector: (params) => {
        const { data, node } = params;
        if (node.group) {
          return {
            component: 'agGroupCellRenderer',
          };
        }

        const firstChild = params.node?.uiLevel === 1;
        const secondChild = params.node?.uiLevel === 2;

        const isManual = params?.data?.clientsCostCenterName.toString() === ACCOUNT_STATUSES.NA;

        return {
          component: () => (
            <div
              className={classNames('flex items-center justify-start space-x-2 self-center', {
                'ml-10': firstChild,
                'ml-20': secondChild,
              })}
            >
              <div>
                {data?.clientsCostCenterName === ACCOUNT_STATUSES.NA
                  ? ''
                  : data?.clientsCostCenterName}
              </div>
              {data?.costCenterShortName === '' && !isManual && (
                <AllocationStatusIcon
                  {...params}
                  value="PARTIALLY_ALLOCATED"
                />
              )}
            </div>
          ),
        };
      },
      checkboxSelection: false,
      field: 'clientsCostCenterName',
      headerName: t('common:clientCostCenterNumber'),
      flex: 2,
    }),
    [t],
  );
  const rowData = useMemo(() => {
    const assetAllocations = selectedAccountsAllocation?.[selectedAccountLedger] ?? {};

    return Object.entries(assetAllocations).flatMap(([clientsCostCenterName, accounts]) => {
      if (Object.keys(accounts).length === 0) {
        return [
          {
            clientsCostCenterName,
            costCenterShortName: '',
            percentage: 0,
            source:
              clientsCostCenterName === ACCOUNT_STATUSES.NA
                ? ACCOUNT_STATUSES.NA
                : ACCOUNT_STATUSES.AUTOMATIC,
          },
        ];
      }

      return Object.entries(accounts).map(([costCenterShortName, percentage]) => ({
        clientsCostCenterName,
        costCenterShortName,
        percentage,
        source:
          clientsCostCenterName === ACCOUNT_STATUSES.NA
            ? ACCOUNT_STATUSES.NA
            : ACCOUNT_STATUSES.AUTOMATIC,
      }));
    });
  }, [selectedAccountsAllocation, selectedAccountLedger]);

  const addNewRow = useCallback(({ api }: ButtonCellRendererProps) => {
    api?.applyTransaction({
      add: [
        {
          clientsCostCenterName: ACCOUNT_STATUSES.NA,
          costCenterShortName: '',
          source: ACCOUNT_STATUSES.NA,
        },
      ],
    });
  }, []);

  const oneToManyList = hasOneToMany(selectedAccountsAllocation?.[selectedAccountLedger]);

  const onCellValueChange = useCallback(
    async ({
      colDef,
      data: { costCenterShortName, percentage, clientsCostCenterName = '' },
      node: { parent },
    }: CellValueChangedEvent) => {
      const colName = colDef.field;

      if (!colName) {
        return;
      }

      const totalPercentage =
        parent?.allLeafChildren?.reduce((acc, item) => acc + item.data?.percentage || 0, 0) ?? 0;

      if (selectedAccountStatus.isManual && totalPercentage > 100) {
        return showNotification(
          'error',
          t('main:manageMenu.allocationKeysConfiguration.maxManualPercent'),
        );
      }

      if (costCenterShortName && percentage > 0) {
        const updatedAccountsAllocation = {
          ...selectedAccountsAllocation,
          [selectedAccountLedger]: {
            ...selectedAccountsAllocation?.[selectedAccountLedger],
            [clientsCostCenterName]: {
              ...selectedAccountsAllocation?.[selectedAccountLedger][clientsCostCenterName],
              [costCenterShortName]: percentage,
            },
          },
        };

        await updateAccountsAllocation(updatedAccountsAllocation);
      }
    },
    [
      selectedAccountStatus,
      t,
      selectedAccountsAllocation,
      selectedAccountLedger,
      updateAccountsAllocation,
    ],
  );

  const deleteRow = useCallback(
    async ({ node, data, api }: ButtonCellRendererProps) => {
      api.applyTransaction({
        remove: [node.data],
      });

      const { [data.costCenterShortName]: _, ...remainingCostCenters } =
        selectedAccountsAllocation?.[selectedAccountLedger][data.clientsCostCenterName] ?? {};

      const updatedAccountsAllocation = {
        ...selectedAccountsAllocation,
        [selectedAccountLedger]: {
          ...selectedAccountsAllocation?.[selectedAccountLedger],
          [data.clientsCostCenterName]: remainingCostCenters,
        },
      };

      await updateAccountsAllocation(updatedAccountsAllocation);
    },
    [selectedAccountsAllocation, selectedAccountLedger, updateAccountsAllocation],
  );

  const handleDelete = useCallback(
    async (btnProps: ButtonCellRendererProps) => {
      await deleteRow(btnProps);
    },
    [deleteRow],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonCellRendererProps>(
    {
      onConfirm: handleDelete,
    },
  );

  const { onGridReady } = useAgGridData({
    agGridRef: manualTableRef,
    data: rowData,
  });

  const columnDefs = useColumns({
    getCostCenterLongName,
    handleDeleteModal,
    addNewRow,
    costCenterOptions,
    selectedAccountStatus,
    oneToManyList,
  });

  const isAutomatic = selectedAccountStatus.isAutomatic && !selectedAccountStatus.isManual;

  return {
    columnDefs,
    defaultColDef,
    manualTableRef,
    onGridReady,
    autoGroupColumnDef,
    rowData,
    modal,
    onCellValueChange,
    groupRemoveSingleChildren: isAutomatic,
  };
};

export type ControllerType = ReturnType<typeof useController>;

const hasOneToMany = (
  obj: Record<string, Record<string, number>> | undefined,
): Record<string, boolean> => {
  const result: Record<string, boolean> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const nested = obj[key];
      const itemCount = Object.keys(nested).length;

      // Set true if more than one item, false otherwise
      result[key] = itemCount > 1;
    }
  }

  return result;
};
