import type { SVGProps } from 'react';
import { forwardRef } from 'react';

import * as Svgs from './components';

export interface SvgProps extends SVGProps<SVGSVGElement> {
  name: keyof typeof Svgs;
}

export const Svg = forwardRef<SVGSVGElement, SvgProps>((props, ref) => {
  const { name, ...otherProps } = props;
  const Ico = Svgs[name];

  if (!Ico) {
    console.error(`Svg name ${name} not found`);
    return null;
  }

  return (
    <Ico
      {...otherProps}
      data-testid={`Svg-${name}`}
      ref={ref}
    />
  );
});
