import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import type { griddy } from '@org/query';
import { useEvent, useLoadingAction } from '@org/hooks';
import { aggregated, dice } from '@org/query';

import type { BaseControllerType } from '../types';

export type UseControllerProps = BaseControllerType;

export const useController = (apiParams: UseControllerProps) => {
  const { masterConfigurationId: configId, yearId, publicFacilityId } = apiParams;
  const { isLoading: asyncIsLoading, asyncAction } = useLoadingAction();

  const { masterConfiguration, updateMasterConfiguration, isPending } =
    aggregated.useMasterConfiguration(apiParams);
  const { mutateAsync: updateConfig, isPending: updateConfigIsLoading } = dice.useProcessConfig();
  const { control } = useForm<griddy.AssetForecastConfig>({
    values: masterConfiguration?.assetForecastConfig,
  });

  const { configurations } = aggregated.useGetAllConfigurations({
    publicFacilityId,
  });

  const assetForecastConfig: griddy.AssetForecastConfig = useMemo(() => {
    const firstYear = configurations.at(0)!;
    const masterConfigurationId = firstYear?.configs.at(0);

    return (
      masterConfiguration?.assetForecastConfig ??
      ({
        isEnabled: false,
        masterConfigurationId: masterConfigurationId?.id,
        postCalculationYearId: masterConfigurationId?.yearCalculationId,
      } as griddy.AssetForecastConfig)
    );
  }, [configurations, masterConfiguration?.assetForecastConfig]);

  const handleChangeConfig = useCallback(
    async (masterConfigurationId: string) => {
      await updateMasterConfiguration({
        ...masterConfiguration,
        assetForecastConfig: {
          ...assetForecastConfig,
          masterConfigurationId,
          postCalculationYearId: configurations
            .map((yearItem) =>
              yearItem.configs.find((config) => config.id === masterConfigurationId),
            )
            .at(0)?.yearCalculationId,
        },
      });
    },
    [assetForecastConfig, configurations, masterConfiguration, updateMasterConfiguration],
  );

  const handleActivateAssetForecast = useEvent(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      await updateMasterConfiguration({
        ...masterConfiguration,
        assetForecastConfig: {
          ...assetForecastConfig,
          isEnabled: checked,
        },
      });
    },
  );

  const createAssetForecast = asyncAction(async () => {
    await updateConfig({
      body: {
        masterConfigId: configId,
        yearId,
      },
      queryParams: {
        'config-type': 'forecast-assets',
      },
    });
  });

  const isLoading = asyncIsLoading || updateConfigIsLoading;
  const isEnabled = assetForecastConfig?.isEnabled ?? false;

  return {
    ...apiParams,
    control,
    createAssetForecast,
    handleActivateAssetForecast,
    handleChangeConfig,
    isDisabled: !isEnabled || isLoading,
    isEnabled,
    isLoading,
    isToggleDisabled: isPending,
    masterConfigurationId: assetForecastConfig.masterConfigurationId,
  };
};

export type ControllerType = ReturnType<typeof useController>;
