import { useGetMasterConfigurations } from '../dice';

export interface UseGetAllConfigurationsProps {
  publicFacilityId: string;
}

export const useGetAllConfigurations = ({ publicFacilityId }: UseGetAllConfigurationsProps) => {
  const { data: extendedYears } = useGetMasterConfigurations({
    pathParams: {
      publicFacilityId,
    },
  });

  const configurations =
    extendedYears
      ?.map((extendedYear) => ({
        configs: [
          ...(extendedYear.preYearCalculationDTO?.masterConfigurationEntities?.map((config) => ({
            configActivityStatus: config.configActivityStatus,
            configType: 'pre',
            description: config.description,
            id: config.id,
            name: config.name,
            type: config.type,
            yearCalculationId: extendedYear.preYearCalculationDTO?.id,
          })) ?? []),
          ...(extendedYear.postYearCalculationDTO?.masterConfigurationEntities?.map((config) => ({
            configActivityStatus: config.configActivityStatus,
            configType: 'post',
            description: config.description,
            id: config.id,
            name: config.name,
            type: config.type,
            yearCalculationId: extendedYear.postYearCalculationDTO?.id,
          })) ?? []),
        ],
        year: extendedYear.year,
        yearId: extendedYear.id,
      }))
      ?.sort((a, b) => Number(b?.year) - Number(a?.year)) ?? [];

  return {
    configurations,
  };
};
