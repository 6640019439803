import styled from 'styled-components';

export const ListItemWrapper = styled.div`
  display: flex;
  padding: 3px 0;

  &:not(:last-child) {
    border-bottom: 1px lightgray solid;
  }
`;
