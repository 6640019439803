import type { TFunction } from '@org/locales';
import type { dice } from '@org/query';

interface Allocation {
  data?:
    | dice.AccountDTO
    | dice.AllocationConfigCostTypeAccountDTO
    | dice.AllocationConfigCostCenterDTO
    | string;
  group?: string;
  subGroup?: string;
  accountType?: string;
  key?: string;
  description: string;
  shortName?: string | number;
}

interface FlattenAllocationsParams {
  nestedAllocations?: dice.AllocationConfigPreselectedValuesDTO;
  rankOfCC?: number;
  basisType: dice.AllocationKeyConfigDTO['basisType'];
  t: TFunction;
}

export const flattenAllocations = ({
  nestedAllocations,
  rankOfCC,
  basisType,
  t,
}: FlattenAllocationsParams) => {
  const allocations: Allocation[] = [];

  if (!nestedAllocations || !rankOfCC) {
    return allocations;
  }

  Object.entries(nestedAllocations).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    if (basisType === 'ACCOUNT' && (key === 'revenueAccounts' || key === 'expenseAccounts')) {
      value.forEach((item: dice.AccountDTO) => {
        const { accountId, accountType, accountDescription } = item;

        allocations.push({
          accountType,
          data: item,
          description: `${accountId} - ${accountDescription}`,
          group:
            accountType === 'EXPENSE'
              ? t('main:manageMenu.manageAllocationKeys.cost')
              : t('main:manageMenu.manageAllocationKeys.revenue'),
          key,
          shortName: accountId ?? '',
          subGroup: t('main:manageMenu.manageAllocationKeys.accounts'),
        });
      });
    }

    if (
      basisType === 'COST_TYPE_ACCOUNT' &&
      (key === 'revenueCostTypeAccounts' || key === 'expenseCostTypeAccounts')
    ) {
      value.forEach((item: dice.AllocationConfigCostTypeAccountDTO) => {
        const { name, type, id } = item;

        allocations.push({
          accountType: type,
          data: item,
          description: name ?? '',
          group:
            type === 'EXPENSE'
              ? t('main:manageMenu.manageAllocationKeys.cost')
              : t('main:manageMenu.manageAllocationKeys.revenue'),
          key,
          shortName: id,
          subGroup: t('main:manageMenu.manageAllocationKeys.costTypeAccounts'),
        });
      });
    }

    if (key === 'costCenters') {
      value.forEach((item: dice.AllocationConfigCostCenterDTO) => {
        if ((item?.rank ?? 0) < rankOfCC) {
          const baseCostCenter = {
            data: item,
            description: item.longName ?? '',
            key,
            shortName: item.shortName,
            subGroup: t('main:manageMenu.allocationKeysConfiguration.allocationTableHeader'),
          };

          allocations.push(
            {
              ...baseCostCenter,
              accountType: 'EXPENSE',
              group: t('main:manageMenu.manageAllocationKeys.cost'),
            },
            {
              ...baseCostCenter,
              accountType: 'REVENUE',
              group: t('main:manageMenu.manageAllocationKeys.revenue'),
            },
          );
        }
      });
    }

    if (key === 'calculatedCosts') {
      value.forEach((item: string) => {
        allocations.push({
          accountType: 'EXPENSE',
          data: item,
          description: item || '',
          group: t('main:manageMenu.manageAllocationKeys.cost'),
          key,
          shortName: item,
          subGroup: t('main:manageMenu.manageAllocationKeys.lblImputedCosts'),
        });
      });
    }

    if (key === 'calculatedRevenues') {
      value.forEach((item: string) => {
        allocations.push({
          accountType: 'REVENUE',
          data: item,
          description: item || '',
          group: t('main:manageMenu.manageAllocationKeys.revenue'),
          key,
          shortName: item,
          subGroup: t('main:manageMenu.manageAllocationKeys.lblImputedCosts'),
        });
      });
    }
  });

  return allocations;
};
