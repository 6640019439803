import type { griddy } from '@org/query';

import type { GroupedCostCenter } from '../types';

/**
 * Given an array of cost centers, group them by group and return an array of tuples.
 * Group will be null if for cost center doesn't exist group
 * @param {array type} costCenters - the array of cost centers
 * @returns An array of objects with the group name and the cost centers within that group.
 */

export const groupCostCenters = (costCenters: griddy.CostCenter[]): GroupedCostCenter[] =>
  Array.from(
    costCenters
      .reduce(
        (entryMap, e) => entryMap.set(e.group ?? '', [...(entryMap.get(e.group ?? '') || []), e]),
        new Map(),
      )
      .entries(),
    // Filter empty lists
  ).filter(([, list]) => list.length > 0);
