import type { dice, griddy } from '../../../../query/src';
import { addCostCenterToMasterConfiguration } from './addCostCenterToMasterConfiguration';

export type UpdateCostCenterInMasterConfiguration = (
  newCostCenter: dice.CostCenter,
  masterConfiguration: dice.MasterConfiguration,
  accountCostCentersMap: Record<number, string[]>,
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse,
) => dice.MasterConfiguration;

export const updateCostCenterInMasterConfiguration: UpdateCostCenterInMasterConfiguration = (
  newCostCenter,
  masterConfiguration,
  accountCostCentersMap,
  flatProfitCenterTree,
) => {
  let newLocalCostCenter = newCostCenter;
  if (newCostCenter.assignCostCenterMappings === false) {
    newLocalCostCenter = {
      ...newCostCenter,
      costCenterIgnoreMappings: [],
      costCenterMappings: [],
      mappingKeys: [],
    };
  }

  if (newCostCenter.costCenterType === 'INDIRECT_COST_CENTER') {
    const { costCenterToCostUnitDistributions: _costCenterToCostUnitDistributions = [] } =
      masterConfiguration?.costUnitAllocationConfig ?? {};

    const costCenterToCostUnitDistributions = _costCenterToCostUnitDistributions.filter(
      (allocation) => allocation.primaryCostCenterShortName !== newCostCenter.shortName,
    );

    return addCostCenterToMasterConfiguration(
      newLocalCostCenter,
      {
        ...masterConfiguration,
        costUnitAllocationConfig: {
          ...masterConfiguration?.costUnitAllocationConfig,
          costCenterToCostUnitDistributions,
        },
      },
      accountCostCentersMap,
      flatProfitCenterTree,
    );
  }
  return addCostCenterToMasterConfiguration(
    newLocalCostCenter,
    masterConfiguration,
    accountCostCentersMap,
    flatProfitCenterTree,
  );
};
