// import { useQuery } from '@tanstack/react-query';

// import { buildFetcher } from '../../common';

// const proxyAdminFetcher = buildFetcher('/admin');

export const useIsAdmin = () =>
  // const { isError } = useQuery<unknown>({
  //   queryFn: ({ signal }) =>
  //     proxyAdminFetcher({
  //       method: 'GET',
  //       signal,
  //       url: '/',
  //     }),
  //   queryKey: ['getIsCurrentUserAdmin'],
  // });

  ({
    isAdmin: false,
    //!isError,
  });
