import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

import { useTranslation } from '@org/locales';
import { Tooltip } from '@org/ui';

export const FromPreviousYearHeaderTooltip = () => {
  const { t } = useTranslation();
  return (
    <div className="flex space-x-2">
      <div>
        {t(
          'main:manageMenu.manageCostUnits.tableColumns.creditOrReimbursementFromPreviousYearsShort',
        )}
      </div>
      <Tooltip
        content={t(
          'main:manageMenu.manageCostUnits.tableColumns.creditOrReimbursementFromPreviousYears',
        )}
        placement="top"
      >
        <QuestionMarkCircleIcon className="h-4 text-gray-600" />
      </Tooltip>
    </div>
  );
};
