import type { ICellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';
import { memo } from 'react';

import { t } from '@org/locales';
import { Tooltip } from '@org/ui';

export type ModifiedTransactionsCellRendererProps = ICellRendererParams;

const ModifiedTransactionsCellRendererMemo: FC<ModifiedTransactionsCellRendererProps> = (props) =>
  props.value ? (
    <div className="justify-center text-center">
      <Tooltip
        content={`${props.value} ${t(
          'main:reportPanels.babReport.tooltipTransactionModifications',
        )}`}
        placement="left"
      >
        <div className="inline-flex h-6 items-center border-r-[0.75rem] bg-blue-default px-2.5 py-0 text-xs font-bold leading-6 text-white">
          {props.value}
        </div>
      </Tooltip>
    </div>
  ) : null;

export const ModifiedTransactionsCellRenderer = memo(ModifiedTransactionsCellRendererMemo);
