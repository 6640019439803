import { useCallback } from 'react';

import type { APIParams } from '../..';
import type { RangeDTO } from '../dice';
import {
  useDeleteRange,
  useGetRangeConfigForMasterConfiguration,
  usePostRange,
  usePutRange,
  useUseAccountPlanAccounts,
  useUseJournalAccounts,
} from '../dice';

interface UseAccountRangesProps extends APIParams<'masterConfigurationId'> {}

export const useAccountRanges = ({ masterConfigurationId }: UseAccountRangesProps) => {
  const { data: accountRanges, isPending } = useGetRangeConfigForMasterConfiguration({
    pathParams: { masterConfigurationId },
  });

  const { mutateAsync: enableUseAccountPlanAccountsAsync } = useUseAccountPlanAccounts({});
  const { mutateAsync: disableUseAccountPlanAccountsAsync } = useUseJournalAccounts({});

  const { mutateAsync: createAccountRangeAsync } = usePostRange({});

  const { mutateAsync: updateAccountRangeAsync } = usePutRange({});

  const { mutateAsync: deleteAccountRangeAsync } = useDeleteRange({});

  const createAccountRange = useCallback(
    async (body: RangeDTO) =>
      await createAccountRangeAsync({
        body,
        pathParams: {
          masterConfigurationId,
        },
      }),
    [createAccountRangeAsync, masterConfigurationId],
  );

  const updateAccountRange = useCallback(
    async (id: string, body: RangeDTO) =>
      await updateAccountRangeAsync({
        body,
        pathParams: {
          id,
          masterConfigurationId,
        },
      }),
    [updateAccountRangeAsync, masterConfigurationId],
  );

  const deleteAccountRange = useCallback(
    async (id: string) =>
      await deleteAccountRangeAsync({
        pathParams: {
          id,
          masterConfigurationId,
        },
      }),
    [deleteAccountRangeAsync, masterConfigurationId],
  );

  const enableUseAccountPlanAccounts = useCallback(
    async () =>
      await enableUseAccountPlanAccountsAsync({
        pathParams: {
          masterConfigurationId,
        },
      }),
    [enableUseAccountPlanAccountsAsync, masterConfigurationId],
  );

  const disableUseAccountPlanAccounts = useCallback(
    async () =>
      await disableUseAccountPlanAccountsAsync({
        pathParams: {
          masterConfigurationId,
        },
      }),
    [disableUseAccountPlanAccountsAsync, masterConfigurationId],
  );
  return {
    accountRanges,
    createAccountRange,
    deleteAccountRange,
    isLoading: isPending,
    updateAccountRange,
    enableUseAccountPlanAccounts,
    disableUseAccountPlanAccounts,
  };
};
