import type { GridApi, IRowNode } from 'ag-grid-community';

export const getFilteredRowNodes = (gridApi: GridApi | undefined) => {
  const data: IRowNode[] = [];
  if (gridApi) {
    gridApi?.forEachNodeAfterFilter((rowNode) => {
      data.push(rowNode);
    });
  }
  return data;
};
