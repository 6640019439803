import type { dice, rave } from '@org/query';

import type { BabRow } from '../../ReportTable/types';
import { ACCOUNT_TYPES } from '../../ReportTable/types';
import { flattenWicapAPIResponseToRows, prepareRevenueCalculations } from '../helpers';
import { usePrepareAccountData } from './usePrepareAccountData';

export const useBABRevenueValues = (
  apiResponse?: rave.ReportsResponse,
  selectedConfiguration?: dice.MasterConfiguration,
): BabRow[] => {
  const revenueAccountsData = usePrepareAccountData(
    selectedConfiguration,
    apiResponse?.babReport?.ctaRevenueEntries ?? {},
    ACCOUNT_TYPES.REVENUE,
  );

  const imputedWicapData = flattenWicapAPIResponseToRows(
    apiResponse?.babReport?.imputedWicapEntries ?? {},
    ACCOUNT_TYPES.REVENUE,
  );

  return [...revenueAccountsData, ...imputedWicapData, ...prepareRevenueCalculations(apiResponse)];
};
