import type { ColDef, ColGroupDef, ValueSetterParams } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';

import {
  ArrowDownTrayIcon,
  ArrowTrendingUpIcon,
  ArrowUpIcon,
  DocumentChartBarIcon,
} from '@heroicons/react/24/outline';

import type { APIParams, erase } from '@org/query';
import type { IconListType } from '@org/ui';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

const emptySelectValue = { label: '', value: '' };

interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId'>;
  handleChange: (val: boolean) => void;
  onRefresh: (asset: erase.Asset | erase.Asset[]) => unknown;
}

export const useColumns = ({
  apiParams,
  onRefresh,
  handleChange,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  const { costCentersNameMap } = aggregated.useCostCenters(apiParams);

  const costCenterOptions = useMemo(
    () => Array.from(costCentersNameMap.entries()).map(([value, label]) => ({ label, value })),
    [costCentersNameMap],
  );

  const iconList = useMemo(
    () => ({
      ERP_ASSET: {
        icon: ArrowDownTrayIcon,
      },
      FORECAST_ASSET: {
        icon: DocumentChartBarIcon,
      },
      FUTURE_ASSET: {
        icon: ArrowTrendingUpIcon,
      },
      UPLOADED_ASSET: {
        icon: ArrowUpIcon,
      },
    }),
    [],
  ) satisfies IconListType<erase.Asset, 'assetType'>;

  const handleCostCenterChange = useCallback(
    (params: ValueSetterParams) => {
      handleChange(true);
      params.data.currentBabCCLongName = costCentersNameMap.get(params.newValue);
      params.data.currentBabCCShortName = params.newValue;
      const selectedNodes = params.api.getSelectedNodes();

      if (selectedNodes.length > 1) {
        selectedNodes.forEach((node) => {
          if (!node.data) {
            return true;
          }
          node.data.currentBabCCLongName = costCentersNameMap.get(params.newValue);
          node.data.currentBabCCShortName = params.newValue;
        });
        params.api.applyTransactionAsync({
          update: selectedNodes.flatMap(({ data }) => (data ? [data] : [])),
        });
        params.api.refreshCells({
          columns: ['currentBabCCShortName', 'currentBabCCShortName1'],
          rowNodes: selectedNodes,
        });
      } else {
        params.api.applyTransactionAsync({
          update: [params.data],
        });
      }
      return true;
    },
    [costCentersNameMap, handleChange],
  );

  return useMemo(
    () =>
      [
        {
          children: [
            {
              checkboxSelection: true,
              colId: 'assetDescription',
              field: 'assetDescription',
              flex: 1,
              headerCheckboxSelection: true,
              headerName: t('main:reportPanels.interestTable.tableColumns.assetDescription'),
              minWidth: 250,
              valueGetter: ({ data: { assetNumber, assetDescription } = {} }) =>
                `${assetNumber} ${assetDescription ?? ''}`.trim(),
              width: 350,
            },
            {
              colId: 'assetNumber',
              columnGroupShow: 'open',
              field: 'assetNumber',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.assetNumber'),
            },
            {
              colId: 'assetCategory',
              columnGroupShow: 'open',
              field: 'assetCategory',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.assetCategoryName'),
            },
            {
              colId: 'acquisitionValueEnd',
              columnGroupShow: 'open',
              field: 'acquisitionValueEnd',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.acquisitionValueEnd'),
              type: 'price',
            },
            {
              colId: 'purchasingDate',
              columnGroupShow: 'open',
              field: 'purchasingDate',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.purchasingDate'),
              type: 'date',
            },
          ],
          groupId: 'masterData',
          headerName: t('main:reportPanels.interestTable.tableColumns.masterData'),
          openByDefault: false,
        },
        {
          children: [
            {
              colId: 'acquisitionValueEnd',
              field: 'acquisitionValueEnd',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.acquisitionValueEnd'),
              type: 'price',
            },
            {
              cellEditor: 'select',
              cellEditorParams: {
                options: [...costCenterOptions, emptySelectValue],
                placeholder: t(
                  'main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter',
                ),
              },
              colId: 'currentBabCCShortName',
              columnGroupShow: 'open',
              editable: true,
              field: 'currentBabCCShortName',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.costCenter'),
              minWidth: 250,
              valueFormatter: (params) => costCentersNameMap.get(params?.value) ?? '',
              valueSetter: handleCostCenterChange,
              width: 350,
            },
            {
              colId: 'lifeTime',
              columnGroupShow: 'open',
              field: 'lifeTime',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.lifeTime'),
              type: 'integer',
            },
            {
              colId: 'assetDepreciation',
              columnGroupShow: 'open',
              field: 'assetDepreciation',
              flex: 1,
              headerName: t(
                'main:reportPanels.interestTable.tableColumns.imputedAssetDepreciation',
              ),
              type: 'price',
            },
            {
              colId: 'rbvCapitalAssetsBegin',
              columnGroupShow: 'open',
              field: 'rbvCapitalAssetsBegin',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.rbvStart'),
              type: 'price',
            },
            {
              colId: 'rbvCapitalAssetsEnd',
              columnGroupShow: 'open',
              field: 'rbvCapitalAssetsEnd',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.rbvEnd'),
              type: 'price',
            },
          ],
          groupId: 'commercialLaw',
          headerName: t('main:reportPanels.interestTable.tableColumns.commercialLaw'),
          openByDefault: false,
        },
        {
          children: [
            {
              colId: 'acquisitionValueEnd1',
              field: 'acquisitionValueEnd',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.acquisitionValueEnd'),
              type: 'price',
            },
            {
              cellEditor: 'select',
              cellEditorParams: {
                options: [...costCenterOptions, emptySelectValue],
                placeholder: t(
                  'main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter',
                ),
              },
              colId: 'currentBabCCShortName1',
              columnGroupShow: 'open',
              editable: true,
              field: 'currentBabCCShortName',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.costCenter'),
              minWidth: 250,
              valueFormatter: (params) => costCentersNameMap.get(params?.value) ?? '',
              valueSetter: handleCostCenterChange,
              width: 350,
            },
            {
              colId: 'imputedTimeOfUsage',
              columnGroupShow: 'open',
              field: 'imputedTimeOfUsage',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.lifeTime'),
              type: 'integer',
            },
            {
              colId: 'imputedAssetDepreciation',
              columnGroupShow: 'open',
              field: 'imputedAssetDepreciation',
              flex: 1,
              headerName: t(
                'main:reportPanels.interestTable.tableColumns.imputedAssetDepreciation',
              ),
              type: 'price',
            },
            {
              colId: 'imputedRbvCapitalAssetsBegin',
              columnGroupShow: 'open',
              field: 'imputedRbvCapitalAssetsBegin',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.rbvStart'),
              type: 'price',
            },
            {
              colId: 'imputedRbvCapitalAssetsEnd',
              columnGroupShow: 'open',
              field: 'imputedRbvCapitalAssetsEnd',
              flex: 1,
              headerName: t('main:reportPanels.interestTable.tableColumns.rbvEnd'),
              type: 'price',
            },
          ],
          groupId: 'imputed',
          headerName: t('main:reportPanels.interestTable.tableColumns.imputed'),
          openByDefault: true,
        },
        {
          cellRenderer: 'icon',
          cellRendererParams: {
            fieldName: 'assetType',
            iconList,
          },
          colId: 'assetType',
          field: 'assetType',
          flex: 1,
          headerName: t('main:reportPanels.interestTable.tableColumns.assetSource'),
          minWidth: 100,
          valueGetter: ({ data }) => data,
        },
        {
          cellRenderer: 'checkbox',
          colId: 'currentIsOperatingAsset',
          field: 'currentIsOperatingAsset',
          flex: 1,
          headerName: t('main:reportPanels.interestTable.tableColumns.typeOperatingAssets'),
          minWidth: 70,
          valueSetter: (params) => {
            handleChange(true);
            params.data.currentIsOperatingAsset = params.newValue;
            const selectedNodes = params.api.getSelectedNodes();

            if (selectedNodes.length > 1) {
              selectedNodes.forEach((node) => {
                if (!node.data) {
                  return;
                }
                node.data.currentIsOperatingAsset = params.newValue;
              });
              params.api.applyTransactionAsync({
                update: selectedNodes.flatMap(({ data }) => (data ? [data] : [])),
              });

              params.api.refreshCells({
                columns: ['currentIsOperatingAsset'],
                rowNodes: selectedNodes,
              });
            } else {
              params.api.applyTransactionAsync({
                update: [params.data],
              });
            }
            return true;
          },
        },
        {
          aggFunc: ({ values }) => [...new Set(values)],
          cellRenderer: 'status',
          cellRendererParams: {
            onRefresh,
          },
          colId: 'status',
          headerName: t('main:manageMenu.manageAssetCostCenters.status'),
          tooltipValueGetter: () => '',
          valueGetter: ({ data }) => data,
          // AG Grid set too high width when autowidth is called.
          // This value allows to minimize column width and show all statuses.
          width: 120,
        },
      ] satisfies (ColDef<erase.Asset> | ColGroupDef<erase.Asset>)[],
    [
      costCenterOptions,
      costCentersNameMap,
      handleChange,
      handleCostCenterChange,
      iconList,
      onRefresh,
      t,
    ],
  );
};
