import type { GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback } from 'react';

import type { APIParams, dice } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';

import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId'>;
  selectedAllocationBasis?: dice.AllocationBasisConfigEntryDTO;
  handleUpdateEntry: (entry: dice.AllocationBasisConfigEntryDTO) => Promise<void>;
  gridRef: RefObject<AgGridReact>;
}

export type ControllerType = ReturnType<typeof useController>;

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
};

export function useController({
  selectedAllocationBasis,
  apiParams,
  handleUpdateEntry,
  gridRef,
}: ControllerProps) {
  const { preselectedAllocations } = aggregated.useAllocationKeys(apiParams);

  const calculatedCosts = preselectedAllocations?.calculatedCosts ?? [];
  const calculatedRevenues = preselectedAllocations?.calculatedRevenues ?? [];

  const rowData = calculatedCosts.concat(calculatedRevenues);

  const { onGridReady } = useAgGridData({ agGridRef: gridRef, data: rowData });

  const onSelectionChanged = async (
    allocationCalculatedCost: dice.AllocationBasisConfigTypeEntryDTO['allocationBasisCalculatedCost'],
  ) => {
    const copy: dice.AllocationBasisConfigEntryDTO = {
      ...selectedAllocationBasis,
      allocationDistributionConfig: {
        ...selectedAllocationBasis?.allocationDistributionConfig,
        allocationCalculatedCost,
      },
    };

    await handleUpdateEntry(copy);
  };

  const getRowId = useCallback(({ data }: GetRowIdParams) => data, []);

  const columnDefs = useColumns({
    allocationCalculatedCosts:
      selectedAllocationBasis?.allocationDistributionConfig?.allocationCalculatedCost ?? [],
    onSelectionChanged,
    rowData,
  });

  return {
    columnDefs,
    defaultColDef,
    getRowId,
    gridRef,
    onGridReady,
    onSelectionChanged,
  };
}
