import type { ChangeEvent, Dispatch } from 'react';
import { useCallback, useReducer } from 'react';

import type { griddy } from '@org/query';

import type { IgnoreMappings, Job } from '../types';
import type { useProfitCenterTree } from './useProfitCenterTree';
import {
  areCostCentersIgnored,
  areJobsIgnored,
  areProfitCentersIgnored,
  conditionalFilter,
  formatName,
  getCorrectCostCenters,
  getCorrectJobs,
  isValidCostCenterMapping,
  iterableToArray,
  sort,
} from '../helpers';

export interface UseDerivedStateProps extends ReturnType<typeof useProfitCenterTree> {
  ignoreMappings: IgnoreMappings;
  checkedJobs: Map<string, griddy.MappingKey>;
  isJobChecked: (job: Job) => boolean;
}

export interface State {
  profitCenterSearch: string;
  costCenterSearch: string;
  jobIdSearch: string;
  selectedProfitCenterName: string;
  selectedCostCenterName: string;
}

type EnsureCorrectStateProps = Pick<
  ReturnType<typeof useProfitCenterTree> & ReturnType<typeof useCostCenterMappingState>,
  | 'selectedProfitCenterName'
  | 'selectedCostCenterName'
  | 'ignoreProfitCenters'
  | 'ignoreCostCenters'
  | 'availableProfitCenters'
  | 'availableCostCenters'
>;

/**
 * When state is in incorrect state, this function will ensure that it is corrected.
 * This is called every render and sets state inline which ensures that ui won't flicker and show inconsistent state.
 */
const ensureCorrectState = (
  {
    selectedProfitCenterName,
    selectedCostCenterName,
    ignoreProfitCenters,
    ignoreCostCenters,
    availableProfitCenters,
    availableCostCenters,
  }: EnsureCorrectStateProps,
  updateState: Dispatch<Partial<State>>,
) => {
  const change: Partial<State> = {};

  if (ignoreProfitCenters && selectedProfitCenterName !== '') {
    change.selectedProfitCenterName = '';
  }
  if (ignoreCostCenters && selectedCostCenterName !== '') {
    change.selectedProfitCenterName = '';
  }

  const firstProfitCenter = availableProfitCenters.values().next().value;
  if (false === ignoreProfitCenters && selectedProfitCenterName === '' && firstProfitCenter) {
    change.selectedProfitCenterName = firstProfitCenter.name;
  }

  if (
    false === ignoreCostCenters &&
    selectedCostCenterName === '' &&
    availableCostCenters.length > 0
  ) {
    change.selectedCostCenterName = availableCostCenters.at(0)?.fullName ?? '';
  }

  if (Object.entries(change).length > 0) {
    updateState(change);
  }
};

export const useCostCenterMappingState = ({
  availableProfitCenters,
  costCenterMap,
  unavailableProfitCenters,
  ignoreMappings,
  checkedJobs,
  isJobChecked,
}: UseDerivedStateProps) => {
  const [localState, updateState] = useReducer(
    (state: State, action: Partial<State>) => ({ ...state, ...action }),
    {
      costCenterSearch: '',
      jobIdSearch: '',
      profitCenterSearch: '',
      selectedCostCenterName: '',
      selectedProfitCenterName: '',
    },
  );

  const {
    selectedProfitCenterName,
    costCenterSearch,
    jobIdSearch,
    selectedCostCenterName,
    profitCenterSearch,
  } = localState;

  const ignoreProfitCenters = areProfitCentersIgnored({
    ignoreMappings,
  });

  const ignoreCostCenters = areCostCentersIgnored({
    ignoreMappings,
    selectedProfitCenterName,
  });

  const ignoreJobIds = areJobsIgnored({
    ignoreMappings,
    selectedCostCenterName: costCenterMap?.get(selectedCostCenterName)?.name,
    selectedProfitCenterName,
  });

  const costCenters = getCorrectCostCenters(
    availableProfitCenters,
    unavailableProfitCenters,
    ignoreProfitCenters,
    selectedProfitCenterName,
  );

  const jobs = getCorrectJobs(
    costCenters.availableCostCenters,
    costCenters.unavailableCostCenters,
    costCenterMap,
    ignoreCostCenters,
    selectedCostCenterName,
  );

  const availableCostCenters = sort(costCenters.availableCostCenters, formatName);
  const unavailableCostCenters = sort(costCenters.unavailableCostCenters, formatName);

  const availableJobs = sort(jobs.availableJobs, formatName);
  const unavailableJobs = sort(jobs.unavailableJobs, formatName);

  const allAvailableProfitCenters = iterableToArray(availableProfitCenters?.values());
  const allAvailableProfitCentersJobs = allAvailableProfitCenters.flatMap(
    ({ availableJobs: availableProfitCentersJobs }) => [...availableProfitCentersJobs],
  );

  const allProfitCenters = [
    ...allAvailableProfitCenters,
    ...(unavailableProfitCenters?.values() ?? []),
  ];

  const allSelectedCostCenters = [...availableCostCenters, ...unavailableCostCenters];

  const allSelectedJobs = [...availableJobs, ...unavailableJobs];

  const profitCentersWithFilter = conditionalFilter(
    allProfitCenters,
    formatName,
    profitCenterSearch,
  );

  const costCentersWithFilter = conditionalFilter(
    allSelectedCostCenters,
    formatName,
    costCenterSearch,
  );
  const jobsWithFilter = conditionalFilter(allSelectedJobs, formatName, jobIdSearch);

  const allJobsSelected =
    ignoreJobIds || (availableJobs.length > 0 && availableJobs.every(isJobChecked));

  const someJobsSelected = !allJobsSelected && availableJobs.some(isJobChecked);

  const allCostCentersSelected =
    availableCostCenters.length > 0 &&
    availableCostCenters
      .flatMap(({ availableJobs: costCenterAvailableJobs }) =>
        iterableToArray(costCenterAvailableJobs),
      )
      .every(isJobChecked);

  const someCostCentersSelected =
    !allCostCentersSelected &&
    availableCostCenters
      .flatMap(({ availableJobs: costCenterAvailableJobs }) =>
        iterableToArray(costCenterAvailableJobs),
      )
      .some(isJobChecked);

  const allProfitCentersSelected = allAvailableProfitCentersJobs.every(isJobChecked);

  const someProfitCentersSelected =
    !allProfitCentersSelected && allAvailableProfitCentersJobs.some(isJobChecked);

  const isIgnoreProfitCenterDisabled = allAvailableProfitCenters.length === 0;

  const isIgnoreCostCenterDisabled = availableCostCenters.length === 0;

  const isIgnoreJobsDisabled = unavailableJobs.length > 0;

  const isValidConfiguration = isValidCostCenterMapping(ignoreMappings, checkedJobs);

  const handleInputChange = useCallback(
    (key: keyof State) =>
      ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
        updateState({
          [key]: value,
        });
      },
    [],
  );

  ensureCorrectState(
    {
      availableCostCenters,
      availableProfitCenters,
      ignoreCostCenters,
      ignoreProfitCenters,
      selectedCostCenterName,
      selectedProfitCenterName,
    },
    updateState,
  );

  return {
    allAvailableProfitCenters,
    allAvailableProfitCentersJobs,
    allCostCentersSelected,
    allJobsSelected,
    allProfitCenters,
    allProfitCentersSelected,
    allSelectedCostCenters,
    allSelectedJobs,
    availableCostCenters,
    availableJobs,
    costCentersWithFilter,
    ignoreCostCenters,
    ignoreJobIds,
    ignoreProfitCenters,
    isIgnoreCostCenterDisabled,
    isIgnoreJobsDisabled,
    isIgnoreProfitCenterDisabled,
    isValidConfiguration,
    jobsWithFilter,
    profitCentersWithFilter,
    someCostCentersSelected,
    someJobsSelected,
    someProfitCentersSelected,
    unavailableCostCenters,
    unavailableJobs,
    selectedProfitCenterName,
    costCenterSearch,
    jobIdSearch,
    selectedCostCenterName,
    profitCenterSearch,
    handleInputChange,
    updateState,
  };
};
