import type { FetcherOptions } from '../../common';
import type { EraseContext } from './eraseContext';
import { buildFetcher } from '../../common';

const baseUrl = '/eraseApi';

export type EraseFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = FetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
> &
  EraseContext['fetcherOptions'];

export const eraseFetch = buildFetcher(baseUrl);

export { type ErrorWrapper } from '../../common';
