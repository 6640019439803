import { createGlobalStyle } from 'styled-components';

// Please use 6 digits code for the hex values. AG Grid Excel export doesn't support 3 digits codes.
export const base = {
  pwcBlack: '#404041',
  pwcYellow: '#ffb600',
  pwcTangerine: '#eb8c00',
  pwcOrange: '#d04a02',
  pwcRed: '#e0301e',
  pwcRose: '#d93954',
  pwcBurgundy: '#a32020',
  pwcMaroon: '#602320',
  pwcBlackLight: '#6d6e71',
  white: '#ffffff',
  black: '#000000',
  pwcGrey: '#7d7d7d',
  pwcGreyDark: '#2d2d2d',
  pwcGreyMedium: '#464646',
  pwcGreyLight: '#dedede',
  pwcGreyLighter: '#f2f2f2',
  pwcGreyLightest: '#f7f7f7',

  textDefault: '#2d2d2d',
  borderDefault: '#7d7d7d',
  highlight: '#D04A0234',
  highlightLighter: '#D04A0215',
};

export const dark = {
  backgroundColor: '#404041',
  fontColor: '#000000',
  ...base,
};

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  #nprogress {
    .bar {
      background: #d04a02 !important;
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;

      width: 100%;
      height: 2px;
      margin-top: 60px;
    }

    .peg, .spinner {
      display: none !important;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`;
