import type { ICellRendererParams, IRowNode } from 'ag-grid-community';
import type { FocusEvent } from 'react';
import { useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import type { erase } from '@org/query';
import { useReinitializeForm } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { TextInput } from '@org/ui';

const commonInputClassname =
  'text-xs hover:text-xs h-5 focus:outline focus:border-1 focus:outline-1 focus:outline-gray-500';

const NOT_EQUAL_CHAR = '≠';
const INPUT_PATTERN = `[0-9${NOT_EQUAL_CHAR}]*`;

const areAllChidrenImputedValueEqual = ({ childrenAfterGroup }: IRowNode) => {
  const childValues = childrenAfterGroup?.map(({ data }) => data?.imputedTimeOfUsage);
  return childValues?.every((val) => val === childValues[0]);
};

interface ImputedValueRendererProps extends ICellRendererParams {
  path: string;
  assetCategoryConfigs: erase.AssetCategoryConfigDTO[];
  updateAssetConfig: (categoryConfigs: erase.AssetCategoryConfigDTO[]) => Promise<void>;
}

export const ImputedValueRenderer = ({
  value: imputedTimeOfUsage,
  node,
  path,
  assetCategoryConfigs,
  updateAssetConfig,
}: ImputedValueRendererProps) => {
  const { t } = useTranslation();

  const currentAssetCategory = node?.childrenAfterGroup?.[0].data.assetCategory;

  const currentAssetConfig = assetCategoryConfigs.find(
    ({ assetCategory }) => assetCategory === currentAssetCategory,
  );

  const handleDefaultCheckboxOnBlur = (months: number) => {
    const updatedAssets = assetCategoryConfigs.map((config) =>
      config.assetCategory === currentAssetCategory
        ? { ...config, defaultLifetimeInMonths: months }
        : config,
    );

    updateAssetConfig(updatedAssets);
  };

  const childrenImputedValueEqual = areAllChidrenImputedValueEqual(node);

  const defaultValues = useMemo(
    () => ({
      defaultLifetimeInMonths: currentAssetConfig?.defaultLifetimeInMonths,
      imputedValue:
        node.level === 0 && !childrenImputedValueEqual ? NOT_EQUAL_CHAR : imputedTimeOfUsage,
    }),
    [
      currentAssetConfig?.defaultLifetimeInMonths,
      node.level,
      childrenImputedValueEqual,
      imputedTimeOfUsage,
    ],
  );
  const { control, reset } = useForm({
    defaultValues,
  });

  useReinitializeForm({ defaultValues, reset });

  return (
    <div className="align-items-end flex flex-col px-1">
      {node.level === 0 && (
        <div className="space-x-2">
          <span className="italic text-orange-400">{t('main:helpMenu.tableColumns.default')}</span>
          <div className="inline-block w-12">
            <Controller
              control={control}
              name="defaultLifetimeInMonths"
              render={({ field }) => (
                <TextInput
                  {...field}
                  className={commonInputClassname}
                  onBlur={({ target }: FocusEvent<HTMLInputElement>) => {
                    const numberValue = Number(target.value);
                    const isNumber = !Number.isNaN(numberValue);

                    if (target.value && isNumber) {
                      handleDefaultCheckboxOnBlur(numberValue);
                    }
                  }}
                  pattern={INPUT_PATTERN}
                />
              )}
            />
          </div>
        </div>
      )}

      <div className="w-12">
        <Controller
          control={control}
          name="imputedValue"
          render={({ field }) => (
            <TextInput
              {...field}
              className={commonInputClassname}
              onBlur={({ target }: FocusEvent<HTMLInputElement>) => {
                const numberValue = Number(target.value);
                const isNumber = !Number.isNaN(numberValue);

                if (target.value && isNumber) {
                  node.setDataValue(path, numberValue);
                }
              }}
              pattern={INPUT_PATTERN}
            />
          )}
        />
      </div>
    </div>
  );
};
