import { useCallback } from 'react';

import type { APIParams } from '../../types';
import type { AccountDTO, CostTypeAccountConfigDTO } from '../dice';
import {
  useDiceContext,
  useGetAccountsByMasterConfiguration,
  useGetConfig,
  useUpsertConfig,
} from '../dice';

interface UseCostTypeAccountsParams extends APIParams {
  enableFetch?: boolean;
}

export const useCostTypeAccounts = ({
  masterConfigurationId,
  enableFetch = true,
}: UseCostTypeAccountsParams = {}) => {
  const preselectedAccountsQuery = useGetAccountsByMasterConfiguration(
    {
      pathParams: {
        masterConfigurationId: masterConfigurationId!,
      },
    },
    {
      enabled: !!masterConfigurationId,
    },
  );

  const costTypeAccountQuery = useGetConfig(
    {
      pathParams: {
        masterConfigId: masterConfigurationId!,
      },
    },
    {
      enabled: preselectedAccountsQuery.isFetched || enableFetch,
      select: (data) => {
        const { costTypeAccounts } = data;
        const selectedAccountsMap = new Map<string, boolean>();
        const adjustedAccountsMap = new Map<string, boolean>();
        const costTypeAccountMap = new Map<string, { selected: string; adjusted: string }>();

        preselectedAccountsQuery?.data?.forEach(({ accountId = '' }) => {
          const selectedAccountId = costTypeAccounts?.find((cost) =>
            cost.accountIds?.includes(accountId),
          );
          const adjustedAccountId = costTypeAccounts?.find((cost) =>
            cost.adjustedAccountIds?.includes(accountId),
          );

          const accountIsSelected = selectedAccountId !== undefined;
          const accountIsAdjusted = adjustedAccountId !== undefined;

          selectedAccountsMap.set(accountId, accountIsSelected);
          adjustedAccountsMap.set(accountId, accountIsAdjusted);
          costTypeAccountMap.set(accountId, {
            adjusted: accountIsAdjusted ? (adjustedAccountId?.name ?? '') : '',
            selected: accountIsSelected ? (selectedAccountId?.name ?? '') : '',
          });
        });

        const revenueAccounts: AccountDTO[] = [];
        const expenseAccounts: AccountDTO[] = [];

        preselectedAccountsQuery?.data?.forEach((account) => {
          if (account.accountType === 'REVENUE') {
            revenueAccounts.push(account);
          } else if (account.accountType === 'EXPENSE') {
            expenseAccounts.push(account);
          }
        });

        const rowData =
          costTypeAccounts?.map((item) => {
            let newAccounts;
            if (item.type === 'REVENUE') {
              newAccounts =
                revenueAccounts?.map((revAcc) => {
                  const accountId = revAcc.accountId ?? '';

                  return {
                    accountChecked: item.accountIds?.includes(accountId) ?? false,
                    accountDesc: `${revAcc.accountId} - ${revAcc.accountDescription}`,
                    accountId,
                    adjusted: item.adjustedAccountIds?.includes(accountId) ?? false,
                    costType: costTypeAccountMap.get(accountId)?.selected ?? false,
                  };
                }) ?? [];
            } else {
              newAccounts =
                expenseAccounts?.map((revAcc) => {
                  const accountId = revAcc.accountId ?? '';

                  return {
                    accountChecked: item.accountIds?.includes(accountId) ?? false,
                    accountDesc: `${revAcc.accountId} - ${revAcc.accountDescription}`,
                    accountId,
                    adjusted: item.adjustedAccountIds?.includes(accountId) ?? false,
                    costType: costTypeAccountMap.get(accountId)?.selected ?? false,
                  };
                }) ?? [];
            }

            return {
              accountIds: item.accountIds ?? [],
              accounts: newAccounts,
              adjustedAccountIds: item.adjustedAccountIds ?? [],
              id: item.id!,
              name: item.name!,
              rank: item.rank!,
              type: item.type,
            };
          }) ?? [];

        return {
          adjustedAccountsMap: Object.fromEntries(adjustedAccountsMap),
          costTypeAccountConfig: data,
          costTypeAccountMap: Object.fromEntries(costTypeAccountMap),
          rowData,
          selectedAccountsMap: Object.fromEntries(selectedAccountsMap),
        } as const;
      },
    },
  );

  const { queryKeyFn } = useDiceContext();

  const getCostTypeAccountQueryKey = useCallback(
    (configId = masterConfigurationId) =>
      queryKeyFn({
        operationId: 'getConfig',
        path: '/master-configs/{masterConfigId}/cost-type-accounts',
        variables: {
          pathParams: {
            masterConfigId: configId!,
          },
        },
      }),
    [masterConfigurationId, queryKeyFn],
  );

  const { mutateAsync: updateCostTypeAccountCall, isPending: updateCostTypeAccountIsLoading } =
    useUpsertConfig({});

  const updateCostTypeAccount = useCallback(
    async (entry: CostTypeAccountConfigDTO) =>
      await updateCostTypeAccountCall({
        body: entry,
      }),
    [updateCostTypeAccountCall],
  );

  return {
    costTypeAccountConfig: costTypeAccountQuery.data?.costTypeAccountConfig,
    costTypeAccountQuery,
    extendedCostTypeAccountConfig: costTypeAccountQuery.data,
    isLoading: updateCostTypeAccountIsLoading,
    preselectedAccounts: preselectedAccountsQuery.data,
    preselectedAccountsQuery,
    updateCostTypeAccount,
    getCostTypeAccountQueryKey,
  };
};
