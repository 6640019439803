import type { CellClickedEvent } from 'ag-grid-community';
import { useCallback, useMemo, useReducer } from 'react';

import { useDisclosure } from '@nextui-org/react';

import type { rave } from '@org/query';
import { useTranslation } from '@org/locales';

import type { BabReportModalProps } from './BabReportModal';
import { BabReportModal } from './BabReportModal';

interface UseBabReportModalProps
  extends Pick<BabReportModalProps, 'apiParams' | 'recalculate' | 'modalGridRef' | 'yearId'> {
  accounts?: rave.AccountBase[];
}

interface State {
  accountId?: string;
  costCenterName?: string;
  transactionsPresentToFlush: boolean;
  costTypeAccount?: string;
}

export const useBabReportModal = (props: UseBabReportModalProps) => {
  const disclosureState = useDisclosure();
  const { isOpen, onClose, onOpenChange, onOpen } = disclosureState;
  const { t } = useTranslation();
  const { recalculate, modalGridRef, accounts } = props;
  const groupsWithUnClickableRows = useMemo(
    () => new Set([t('main:reportPanels.babReport.tableRows.IMPUTED_WITHDRAWAL_CAPITAL')]),
    [t],
  );

  const [{ accountId, transactionsPresentToFlush, costCenterName, costTypeAccount }, setState] =
    useReducer((prev: State, next: Partial<State>) => ({ ...prev, ...next }), {
      transactionsPresentToFlush: false,
    });

  const handleOpenModal = useCallback(
    (params: CellClickedEvent, columnName: string) => {
      if (!columnName) {
        return;
      }

      if (params?.node?.level === 1 && !!params?.node?.key) {
        if (transactionsPresentToFlush) {
          recalculate?.();
        }
        setState({
          accountId: undefined,
          costCenterName: columnName,
          costTypeAccount: params?.node?.key,
        });
        onOpen();
      } else if (
        // only account rows are clickable
        params?.node?.level === 2 &&
        params?.node?.data?.accountId &&
        !groupsWithUnClickableRows.has(params?.node?.parent?.key ?? '')
      ) {
        if (transactionsPresentToFlush) {
          recalculate?.();
        }
        setState({
          accountId: params.data.accountId,
          costCenterName: columnName,
        });
        onOpen();
      }
    },
    [groupsWithUnClickableRows, onOpen, recalculate, transactionsPresentToFlush],
  );

  const handleCloseModal = useCallback(
    (discardChanges = false) => {
      if (!discardChanges && transactionsPresentToFlush) {
        // There were some manual made changes within the BAB modal
        setState({ transactionsPresentToFlush: false });
        modalGridRef.current?.api?.flushAsyncTransactions();
      } else {
        setState({
          accountId: undefined,
          costCenterName: undefined,
          costTypeAccount: undefined,
          transactionsPresentToFlush: false,
        });
        onClose();
      }
    },
    [modalGridRef, onClose, transactionsPresentToFlush],
  );

  return {
    handleOpenModal,
    modal: (
      <BabReportModal
        {...props}
        accountId={accountId}
        accounts={accounts}
        costCenterName={costCenterName}
        costTypeAccount={costTypeAccount}
        handleClose={handleCloseModal}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        setTransactionsPresentToFlush={(val) => setState({ transactionsPresentToFlush: val })}
        transactionsPresentToFlush={transactionsPresentToFlush}
      />
    ),
  };
};
