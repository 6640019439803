import type { ColDef } from 'ag-grid-community';

import { formatNonZeroNumberToLocaleString } from '@org/utils';

export const numericColumnProps: Partial<ColDef> = {
  cellStyle: { textAlign: 'right' },
  valueFormatter: ({ value }) =>
    typeof value === 'number'
      ? formatNonZeroNumberToLocaleString(value, { defaultValue: '-' })
      : value,
};
