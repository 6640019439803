import type { FC } from 'react';

import classnames from 'classnames';

import styles from './RadioInput.module.css';

type OuterProps = React.JSX.IntrinsicElements['input'];

export type RadioInputProps = {} & OuterProps;

export const RadioInput: FC<RadioInputProps> = (props) => {
  const { className, children, ...otherProps } = props;
  return (
    <label className="mb-1 flex flex-row gap-2 align-middle">
      <input
        className={classnames(styles.RadioInput, className)}
        data-testid="RadioInput"
        {...otherProps}
        type="radio"
      />
      {children}
    </label>
  );
};
