import type { CompareDir } from '../../types';
import { compare as compareString } from '../../string';
import { isNumber, isString } from '../../type-guards';

export type SortByKeyArg = object;

export type SortByKeyArgs = [SortByKeyArg];

export type SortByKeyResult = void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SortByKey = <T extends Record<string, any>, Key extends keyof T>(
  rows: T[],
  key: Key,
  dir?: CompareDir,
) => T[];

export const sortByKey: SortByKey = (rows, key, dir = 'ASC') =>
  rows.toSorted((firstItem, secondItem) => {
    const a = firstItem[key];
    const b = secondItem[key];

    if (isNumber(a) && isNumber(b)) {
      return dir === 'ASC' ? a - b : b - a;
    }
    if (isString(a) && isString(b)) {
      return compareString(dir)(a, b);
    }

    return -1;
  });
