import { useDisclosure } from '@nextui-org/react';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import {
  Button,
  CancelButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@org/ui';

import { IndexFactorsUpload } from '../IndexFactorsUpload';

export const AdminMenu = () => {
  const { t } = useTranslation();

  const { isAdmin } = aggregated.useIsAdmin();

  const { onOpenChange, isOpen, onOpen, onClose } = useDisclosure();

  if (!isAdmin) {
    return null;
  }
  return (
    <>
      <Button
        onClick={onOpen}
        size="md"
        variant="primary"
      >
        {t('common:admin')}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          <>
            <ModalHeader>{t('main:adminMenu.indexFactors.tab')}</ModalHeader>
            <ModalBody>
              <IndexFactorsUpload onClose={onClose} />
            </ModalBody>
            <ModalFooter>
              <CancelButton
                onClick={onClose}
                size="md"
                type="button"
              />
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};
