import { Badge, Button, Panel } from 'appkit-react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from '@org/locales';

import { StyledWrapper } from './styled/StyledComponents';

export const StyledPanel = styled(Panel)`
  padding: 20px !important;
  margin-top: 20px;
`;

export const StyledMessageBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin-top: 20px;
  border: 1px solid #ccc;
`;

export const StyledValidationErrorContainer = styled.div`
  margin-top: 20px;
`;

export const StyledActionsPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export const getErrorMsgOfLocationOrError = (location, error, t) =>
  location?.error?.response?.data?.message ||
  location?.error?.message ||
  error?.response?.data?.message ||
  error?.message ||
  t('common:alerts.error.general');

export const getErrorStatusOfError = (error) =>
  error?.response?.data?.status || error?.response?.status || error?.status;

const ErrorPage = ({ error }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const toStart = () => {
    navigate('/');
  };

  const renderSingleError = () => {
    const message = getErrorMsgOfLocationOrError(location, error, t);
    return <StyledMessageBody>{message}</StyledMessageBody>;
  };

  return (
    <StyledWrapper>
      <div className="container">
        <StyledPanel>
          <Badge
            kind="danger"
            type="error"
          >
            Error!
          </Badge>

          <div className="errors-content">{renderSingleError()}</div>

          <StyledActionsPanel>
            <Button
              kind="secondary"
              onClick={toStart}
            >
              Return to start menu
            </Button>
          </StyledActionsPanel>
        </StyledPanel>
      </div>
    </StyledWrapper>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object,
};

export default ErrorPage;
