import type { Rank } from '../types';
import { sortByRank } from '../sortByRank';

export type RebuildRanks = <T extends Rank>(rows: T[]) => T[];

export const rebuildRanks: RebuildRanks = (rows) =>
  sortByRank(rows).map((row, index) => ({
    ...row,
    rank: index + 1,
  }));
