import type {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';
import { t } from '@org/locales';
import { aggregated } from '@org/query';

interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId'>;
  resetWorkingCapitalRate: (node: IRowNode) => void;
}

export const useColumns = ({ resetWorkingCapitalRate, apiParams }: UseColumnsProps) => {
  const { getCostCenterLongName } = aggregated.useCostCenters(apiParams);

  return useMemo(
    () =>
      [
        {
          colId: 'primaryCostCenterShortName',
          field: 'primaryCostCenterShortName',
          filter: 'agTextColumnFilter',
          filterValueGetter: (params: ValueGetterParams) =>
            getCostCenterLongName(params?.data?.primaryCostCenterShortName),
          flex: 1,
          floatingFilter: true,
          headerName: t('main:manageMenu.manageImputedWorkingCapital.costCenter'),
          valueFormatter: (params: ValueFormatterParams) => getCostCenterLongName(params?.value),
        },
        {
          cellClass: 'align-to-center-and-right',
          cellEditor: 'numeric',
          cellEditorParams: {
            defaultValue: 0,
          },
          colId: 'workingCapitalRate',
          comparator: (valueA: number, valueB: number) => valueA - valueB,
          editable: true,
          field: 'workingCapitalRate',
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageImputedWorkingCapital.workingCapitalRate'),
          type: 'number',
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRenderer: 'button',
          cellRendererParams: {
            className: 'flex',
            icon: 'icon-refresh-outline',
            onClick: (params: ICellRendererParams) => resetWorkingCapitalRate(params.node),
            size: 'iconButton',
          },
          colId: 'actions',
          flex: 0,
          floatingFilter: false,
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          sortable: false,
          width: 100,
        },
      ] satisfies (ColDef<griddy.WorkingCapital> | ColGroupDef<griddy.WorkingCapital>)[],
    [getCostCenterLongName, resetWorkingCapitalRate],
  );
};
