import type { griddy } from '@org/query';

import type { CostCenterMappings } from '../types';

interface CreateRowProps {
  type: griddy.CostCenter['costCenterType'];
  group?: string;
  longName?: string;
  shortName?: string;
  rank?: number;
}

export const createRow = ({
  type,
  group,
  shortName,
  longName,
  rank,
}: CreateRowProps): CostCenterMappings => ({
  assignCostCenterMappings: false,
  ccMappingsToProfitCenters: [],
  costCenterType: type,
  group: group ?? '',
  id: crypto.randomUUID(),
  longName: longName ?? '',
  mappingObject: {
    costCenterIgnoreMappings: [],
    mappingKeys: [],
  },
  rank: rank ?? undefined,
  shortName: shortName ?? '',
});
