import type { FC } from 'react';

import classnames from 'classnames';

import type { CorrectAny } from '@org/models';
import type { aggregated, dice } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { Combobox, ComboboxItem, Tab, TabList, TabPanel, TabPanels, Tabs } from '@org/design';
import { FilteringImportFileTable } from '@org/features';
import { useTranslation } from '@org/locales';
import { BaseEditTable, ImportButton, Label, LoaderWrapper, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { DeleteUploadedFileModalButton, UploadSingleFile } from './components';
import { useController } from './useController';
import { showFilteringTable } from './utils';

interface FileOverviewProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const FileOverviewView: FC<FileOverviewProps> = ({
  columnDefs,
  defaultColDef,
  masterConfigurationId,
  apiFileType,
  yearCalculationId,
  isLoading,
  fileMetadata,
  baseFileType,
  camsFiltersTableRef,
  handleFiltering,
  setTab,
  importFileData,
  tab,
  updateFileMetadataForUploadOrErp,
  clientId,
  type,
  year,
  preselectedErpTypes,
  erpType,
  handleErpTypeChange,
  overViewAgGridProps,
  setFiltersWithChildren,
  overViewTableRef,
  updateToggle,
  onFilterRowCheckboxSelected,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 pt-8">
      <Tabs
        onSelectionChange={(key) => {
          setTab(key.toString());
        }}
        selectedKey={tab}
      >
        <div className="space-between flex">
          <TabList className="space-x-4">
            <Tab key="UPLOAD">{t('main:clients.menu.fileMenu.dataUpload')}</Tab>
            <Tab key="ERP">{t('main:clients.menu.fileMenu.erpImport')}</Tab>
            <Tab key="OVERVIEW">{t('main:clients.menu.fileMenu.overview')}</Tab>
          </TabList>

          {fileMetadata?.id && tab === 'OVERVIEW' && (
            <DeleteUploadedFileModalButton
              apiFileType={apiFileType}
              fileId={fileMetadata?.fileId?.toString() ?? ''}
              fileName={fileMetadata?.fileName}
              masterConfigurationId={masterConfigurationId}
              yearId={yearCalculationId}
            />
          )}

          {tab === 'ERP' && (
            <div className="flex">
              <Combobox
                aria-label="erpType"
                className="mr-4"
                onSelectionChange={(key) => {
                  handleErpTypeChange(key as dice.FileErpTypeDTO['erpType']);
                }}
                selectedKey={erpType}
              >
                {(preselectedErpTypes ?? [])?.map((value) => (
                  <ComboboxItem key={value}>{value}</ComboboxItem>
                ))}
              </Combobox>

              <ImportButton
                isDisabled={!!masterConfigurationId || isLoading}
                onClick={
                  showFilteringTable(apiFileType, erpType as dice.FileErpTypeDTO['erpType'])
                    ? handleFiltering
                    : importFileData
                }
                size="md"
              />
            </div>
          )}
        </div>

        <div className="mt-8 h-[calc(100vh-250px)] w-full">
          <LoaderWrapper loading={isLoading}>
            <TabPanels>
              <TabPanel key="UPLOAD">
                <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
                  <ToggleInput
                    checked={fileMetadata?.fileUploadToggleEnabled ?? false}
                    name={t('main:clients.menu.fileMenu.fileUploadToggleEnabled')}
                    onChange={(e) => updateFileMetadataForUploadOrErp(e, 'fileUploadToggleEnabled')}
                  />
                  {t('main:clients.menu.fileMenu.fileUploadToggleEnabled')}
                </Label>
                {fileMetadata?.id && (
                  <div className="text-l pb-3">
                    <span className="font-bold">
                      {t('main:clients.menu.fileMenu.uploadedFile')}:
                    </span>{' '}
                    {fileMetadata?.fileName ?? t('main:clients.menu.fileMenu.fileNameNotAvailable')}
                  </div>
                )}
                <div className="mx-auto max-w-2xl">
                  <div className="text-xl">{t('main:clients.menu.fileMenu.uploadTitle')}</div>
                  <div className="my-2">{t('main:clients.menu.fileMenu.uploadDescription')}</div>
                  <UploadSingleFile
                    apiFileType={apiFileType}
                    baseFileType={baseFileType}
                    masterConfigurationId={masterConfigurationId}
                    yearCalculationId={yearCalculationId}
                  />
                </div>
              </TabPanel>
              <TabPanel key="ERP">
                <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
                  <ToggleInput
                    checked={fileMetadata?.erpToggleEnabled ?? false}
                    name={t('main:clients.menu.fileMenu.erpToggleEnabled')}
                    onChange={(e) => updateFileMetadataForUploadOrErp(e, 'erpToggleEnabled')}
                  />
                  {t('main:clients.menu.fileMenu.erpToggleEnabled')}
                </Label>
                {showFilteringTable(apiFileType, erpType as dice.FileErpTypeDTO['erpType']) && (
                  <div className="flex-1' mt-2 h-[calc(100vh-250px)] space-y-2">
                    <FilteringImportFileTable
                      agGridRef={camsFiltersTableRef}
                      apiFileType={
                        apiFileType as Extract<
                          aggregated.ApiFileType,
                          'ASSETS' | 'JOURNAL' | 'LEDGER_ACCOUNT'
                        >
                      }
                      clientId={clientId!}
                      erpType={erpType}
                      onFilterRowCheckboxSelected={onFilterRowCheckboxSelected}
                      setFiltersWithChildren={setFiltersWithChildren}
                      tab={tab}
                      type={type}
                      updateToggle={updateToggle}
                      year={year!}
                      yearCalculationId={yearCalculationId}
                    />
                  </div>
                )}
              </TabPanel>
              <TabPanel key="OVERVIEW">
                <div className={classnames('h-[calc(100vh-250px)] flex-1')}>
                  <BaseEditTable<CorrectAny>
                    {...overViewAgGridProps}
                    animateRows
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    enableGroupEdit
                    ref={overViewTableRef}
                    singleClickEdit
                  />
                </div>
              </TabPanel>
            </TabPanels>
          </LoaderWrapper>
        </div>
      </Tabs>
    </div>
  );
};

export const FileOverview = bindControllers(FileOverviewView, [useController]);
FileOverview.displayName = 'FileOverview';
