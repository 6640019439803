import type { FC } from 'react';

import { useTranslation } from '@org/locales';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const View: FC<ViewProps> = ({
  getRowId,
  agGridProps,
  agGridRef,
  columnDefs,
  defaultColDef,
  modal,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h6 className="my-3">{t('main:manageMenu.manageTransactionModifications.tableTitle')}</h6>
      <div className="mt-2.5 h-full flex-1">
        <BaseEditTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          ref={agGridRef}
          rowSelection="multiple"
          suppressFieldDotNotation={false}
          {...agGridProps}
        />
        {modal}
      </div>
    </>
  );
};

export const BabChangelog = bindControllers(View, [useController]);
BabChangelog.displayName = 'BabChangelog';
