import type { ColDef, ColGroupDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

const TRADE_TAX_KEYS = [
  'COMMERCIAL_LAW_DEPRECIATION',
  'COMMERCIAL_LAW_INTEREST',
  'DELTA_DEPRECIATION',
  'DELTA_INTEREST',
  'DELTA_TOTAL',
  'IMPUTED_DEPRECIATION_APC',
  'IMPUTED_DEPRECIATION_RPV',
  'IMPUTED_INTEREST',
  'TRADE_TAX_RATE',
  'IMPUTED_TRADE_TAX',
] as const;

const BAB_KEYS = [
  'IMPUTED_DEPRECIATION_APC',
  'IMPUTED_DEPRECIATION_RPV',
  'IMPUTED_INTEREST',
  'IMPUTED_TRADE_TAX',
  'TOTAL_COSTS_AFTER_COST_ALLOCATION',
  'TOTAL_COSTS',
  'TOTAL_REVENUE_AFTER_REVENUE_ALLOCATION',
  'TOTAL_REVENUE',
] as const;

const INTEREST_KEYS = [
  'IMPUTED_INTEREST',
  'RBV_CAPITAL_IMPUTED_AVG',
  'RBV_CAPITAL_IMPUTED_BEGIN',
  'RBV_CAPITAL_IMPUTED_END',
  'RBV_CAPITAL_LEDGER_ACCOUNT_AVG',
  'RBV_CAPITAL_LEDGER_ACCOUNT_BEGIN',
  'RBV_CAPITAL_LEDGER_ACCOUNT_END',
  'RBV_CAPITAL_NON_OPERATING_ASSET_AVG',
  'RBV_CAPITAL_NON_OPERATING_ASSET_BEGIN',
  'RBV_CAPITAL_NON_OPERATING_ASSET_END',
  'RBV_CAPITAL_OPERATING_ASSET_AVG',
  'RBV_CAPITAL_OPERATING_ASSET_BEGIN',
  'RBV_CAPITAL_OPERATING_ASSET_END',
] as const;

const costUnit = [
  'FEE_INCOME',
  'FEE_INCOME_BASIC_CHARGE',
  'FEE_INCOME_QUANTITY_FEE',
  'COST_COVERAGE_REQUIREMENT_QUALITY_FEE',
] as const;

const availableKeysOptions = [
  {
    label: 'tradeTax',
    options: TRADE_TAX_KEYS.map((key) => ({
      label: `tradeTax.${key}`,
      value: `tradeTax.${key}`,
    })),
  },
  {
    label: 'bab',
    options: BAB_KEYS.map((key) => ({
      label: `bab.${key}`,
      value: `bab.${key}`,
    })),
  },
  {
    label: 'interest',
    options: INTEREST_KEYS.map((key) => ({
      label: `interest.${key}`,
      value: `interest.${key}`,
    })),
  },
  {
    label: 'costUnit',
    options: costUnit.map((key) => ({
      label: `costUnit.${key}`,
      value: `costUnit.${key}`,
    })),
  },
];

interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId'>;
  openModal: (index: number) => void;
  gridRef: RefObject<AgGridReact>;
}

export const useColumns = ({ openModal, gridRef, apiParams }: UseColumnsProps) => {
  const { t } = useTranslation();
  const { masterConfiguration: { customLabels = [] } = {} } =
    aggregated.useMasterConfiguration(apiParams);

  return useMemo(
    () =>
      [
        {
          cellEditor: 'select',
          cellEditorParams: {
            disabledOptions: customLabels.map(({ key }) => key),
            options: availableKeysOptions,
          },
          colId: 'key',
          editable: true,
          field: 'key',
          headerName: t('main:manageMenu.manageCustomLabels.tableColumns.key'),
        },
        {
          colId: 'originalName',
          editable: false,
          field: 'originalName',
          headerName: t('main:manageMenu.manageCustomLabels.tableColumns.originalName'),
          valueGetter: ({ data }) => (data?.key ? t(`main:customLabels.${data.key}`) : ''),
        },
        {
          colId: 'name',
          editable: true,
          field: 'name',
          headerName: t('main:manageMenu.manageCustomLabels.tableColumns.name'),
        },
        {
          cellRenderer: 'multipleButtons',
          cellRendererParams: {
            buttonParams: [
              {
                className: 'align-to-center-and-right',
                icon: 'icon-delete-outline',
                onClick: (props: ButtonCellRendererProps) => {
                  openModal(props.node.rowIndex!);
                },
                size: 'iconButton',
              },
            ],
          },
          colId: 'actions',
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            icon: 'icon-plus-fill',
            onClick: () => {
              gridRef.current?.api?.applyTransaction({ add: [{}] });
            },
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerName: t('common:actions'),
          maxWidth: 120,
        },
      ] satisfies (ColDef<griddy.CustomLabel> | ColGroupDef<griddy.CustomLabel>)[],
    [customLabels, gridRef, openModal, t],
  );
};
