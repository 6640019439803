import type { CostCenter } from '../../rave';

const defaultAggFunction = <
  T extends {
    costCenterValueMap?: Record<string, number>;
  },
>(
  inRow: T,
  costCenters: CostCenter[],
) =>
  costCenters.reduce(
    (sum, { shortName = '' }) => sum + (inRow?.costCenterValueMap?.[shortName] ?? 0),
    0,
  );

interface GetGroupTotalsProps<
  T extends {
    costCenterValueMap?: Record<string, number>;
    [key: string]: any;
  },
> {
  row: T;
  indirectCCGroups: [string, CostCenter[]][];
  primaryCCGroups: [string, CostCenter[]][];
}

export const getGroupTotalsOfRow = <
  T extends {
    costCenterValueMap?: Record<string, number>;
    aggFunction?: (row: Omit<T, 'aggFunction'>, costCentersOfGroup: CostCenter[]) => number;
    [key: string]: any;
  },
>({
  row,
  indirectCCGroups,
  primaryCCGroups,
}: GetGroupTotalsProps<T>) => ({
  ...getGroupTotalsForType<T>({
    ccGroupsByType: indirectCCGroups,
    row,
    typeKey: 'INDIRECT_COST_CENTER',
  }),
  ...getGroupTotalsForType<T>({
    ccGroupsByType: primaryCCGroups,
    row,
    typeKey: 'PRIMARY_COST_CENTER',
  }),
});

interface GetGroupTotalsForTypeProps<
  T extends {
    costCenterValueMap?: Record<string, number>;
  },
> {
  row: T & { aggFunction?: (row: T, costCentersOfGroup: CostCenter[]) => number };
  ccGroupsByType: [string, CostCenter[]][];
  typeKey: 'INDIRECT_COST_CENTER' | 'PRIMARY_COST_CENTER';
}

const getGroupTotalsForType = <
  T extends {
    costCenterValueMap?: Record<string, number>;
  },
>({
  row,
  ccGroupsByType,
  typeKey,
}: GetGroupTotalsForTypeProps<T>) => {
  const { aggFunction = defaultAggFunction<T> } = row;
  const sum = ccGroupsByType.reduce(
    (resultTotals, [groupName, costCentersOfGroup]) => {
      const key = `${typeKey}_GROUP_TOTAL_${groupName}`;
      const groupSum = aggFunction(row, costCentersOfGroup);
      return { ...resultTotals, [key]: groupSum };
    },
    {} as Record<string, number>,
  );
  return sum;
};
