import type { dice } from '../../../../query/src';

export type SetImputedWorkingCapitalsInMasterConfiguration = (
  masterConfiguration: dice.MasterConfiguration,
  workingCapitals: dice.WorkingCapital[],
) => dice.MasterConfiguration;

export const setImputedWorkingCapitalsInMasterConfiguration: SetImputedWorkingCapitalsInMasterConfiguration =
  (masterConfiguration, workingCapitals) => ({
    ...masterConfiguration,
    imputedWorkingCapitalConfig: {
      ...masterConfiguration.imputedWorkingCapitalConfig,
      workingCapitals,
    },
  });
