import type { ICellRendererParams, IRowNode } from 'ag-grid-community';

import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';
import { isAllEqualInArray } from '@org/utils';

interface AccountPriceIncreaseCheckboxRendererProps extends ICellRendererParams {
  plannedCostTypeAccountEntries?: dice.PlannedCostTypeAccountEntryDTO[];
  updatePlannedCostRevenueAccountEntry: (
    updatedPlannedAccountEntry: dice.PlannedAccountEntryDTO,
  ) => Promise<dice.PlannedAccountEntryDTO>;
  updatePlannedCostRevenueCostTypeAccountEntry: (
    updatedPlannedCostTypeAccountEntry: dice.PlannedCostTypeAccountEntryDTO,
  ) => Promise<dice.PlannedCostTypeAccountEntryDTO>;
}

export const AccountPriceIncreaseCheckboxRenderer = ({
  value,
  node,
  data,
  plannedCostTypeAccountEntries,
  updatePlannedCostRevenueCostTypeAccountEntry,
  updatePlannedCostRevenueAccountEntry,
}: AccountPriceIncreaseCheckboxRendererProps) => {
  if (node.group) {
    const { originalId } = node.allLeafChildren?.at(0)?.data ?? {};

    const selectedCostTypeAccountEntry = plannedCostTypeAccountEntries?.find(
      ({ id }) => id === originalId,
    );

    const indeterminate = isIndeterminate(node);

    return (
      <CheckboxCellRenderer
        indeterminate={indeterminate}
        onEdit={async (isSelected) => {
          await updatePlannedCostRevenueCostTypeAccountEntry({
            ...selectedCostTypeAccountEntry,
            ctaPriceIncreaseActivated: isSelected,
          });
        }}
        value={!indeterminate && !!selectedCostTypeAccountEntry?.ctaPriceIncreaseActivated}
      />
    );
  }

  return (
    <CheckboxCellRenderer
      onEdit={async (isSelected) => {
        await updatePlannedCostRevenueAccountEntry({
          ...data,
          accountPriceIncreaseActivated: isSelected,
        });
      }}
      value={value}
    />
  );
};

const isIndeterminate = (node: IRowNode) => {
  const childValues = node?.childrenAfterGroup?.map(
    (childNode) => childNode.data?.accountPriceIncreaseActivated,
  );
  return childValues ? !isAllEqualInArray(childValues) : false;
};
