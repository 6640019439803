import classNames from 'classnames';

import type { griddy } from '@org/query';

import { LABEL_FILE_ROW_TYPES } from '../constants';
import {
  mapMappingsToProfitCenters,
  mergeKeysWithIgnoreMapping,
} from '../helpers/mapMappingsToProfitCenters';
import { useLabels } from '../hooks/useLabels';

export const InfoMappingRenderer = ({
  mappingKeys,
  yearId,
  costCenterIgnoreMappings,
  isAgGrid = false,
}: {
  mappingKeys: griddy.MappingKey[];
  yearId: string;
  costCenterIgnoreMappings: griddy.CostCenterIgnoreMapping[];
  isAgGrid?: boolean;
}) => {
  const profitCenterLabels = useLabels(
    LABEL_FILE_ROW_TYPES.PROFITCENTER.key,
    mappingKeys.map(({ profitCenter }) => profitCenter ?? ''),
    yearId,
  );

  const costCenterLabels = useLabels(
    LABEL_FILE_ROW_TYPES.KST.key,
    mappingKeys.map(({ costCenter }) => costCenter ?? ''),
    yearId,
  );

  const jobIdLabels = useLabels(
    LABEL_FILE_ROW_TYPES.AUFTRAG.key,
    mappingKeys.map(({ jobId }) => jobId ?? ''),
    yearId,
  );

  const mergedMappings = mergeKeysWithIgnoreMapping(mappingKeys, costCenterIgnoreMappings);

  const profitCenters = mapMappingsToProfitCenters(mergedMappings);

  return (
    <>
      {profitCenters.map(({ profitCenterId, costCenters }) => (
        <div key={profitCenterId}>
          {profitCenterId} {profitCenterLabels?.[profitCenterId]}
          <div
            className={classNames('ml-2', {
              '-mt-2': isAgGrid,
            })}
          >
            {costCenters.map(({ costCenterId, jobIds }) => (
              <div key={costCenterId}>
                <span>
                  - {costCenterId} {costCenterLabels[costCenterId]}
                </span>
                <div
                  className={classNames('ml-4', {
                    '-mt-2': isAgGrid,
                  })}
                >
                  {jobIds.map((jobId) => (
                    <div key={jobId}>
                      - {jobId} {jobIdLabels[jobId]}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
