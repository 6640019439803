import type { FC } from 'react';

import { Controller } from 'react-hook-form';

import { Combobox, ComboboxItem, ComboboxSection } from '@org/design';
import { useTranslation } from '@org/locales';
import { DisabableContent, Label, NumberInput, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

export type ImputedTradeTaxPage = ControllerType;

export const ImputedTradeTaxView: FC<ImputedTradeTaxPage> = (props) => {
  const { t } = useTranslation();

  const {
    accountIdSelectOptionsByRange,
    accountIdSelectOptions,
    control,
    handleDataChange,
    handleEnableConfigChange,
    handleSubmit,
    isEnabled,
    getValues,
  } = props;
  return (
    <div
      className="flex flex-1 flex-col"
      data-testid="ImputedTradeTaxPage"
    >
      <h6 className="my-3">{t('main:manageMenu.manageImputedTradeTax.tableTitle')}</h6>
      <div>
        <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
          <ToggleInput
            checked={isEnabled}
            name={t('main:manageMenu.manageImputedTradeTax.activateImputedTradeTax')}
            onChange={handleEnableConfigChange}
          />
          {t('main:manageMenu.manageImputedTradeTax.activateImputedTradeTax')}
        </Label>
      </div>
      <DisabableContent
        className="h-full"
        disabled={!isEnabled}
      >
        <form
          className="mb-4 w-1/2 flex-col items-end justify-start gap-2 pt-2"
          onChange={handleSubmit(handleDataChange)}
          onSubmit={handleSubmit(handleDataChange)}
        >
          <div className="flex gap-2 pt-2">
            <div className="flex w-1/2 flex-col">
              <Label className="text-grey mb-0">
                {t('main:manageMenu.manageImputedTradeTax.rateOfTradeTax')}
              </Label>
              <Controller
                control={control}
                name="rateOfTradeTax"
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    ref={null}
                  />
                )}
              />
            </div>
            <div className="flex w-1/2 flex-col">
              <Label className="text-grey mb-0">
                {t('main:manageMenu.manageImputedTradeTax.tradeTaxIndexValue')}
              </Label>
              <Controller
                control={control}
                name="tradeTaxIndexValue"
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    ref={null}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex pt-2">
            <div className="flex w-full flex-col">
              <Label className="text-grey mb-0">
                {t('main:manageMenu.manageImputedTradeTax.interestAccounts')}
              </Label>
              <Controller
                control={control}
                name="accountIds"
                render={({ field: { onChange, value, disabled, name, ...other } }) => (
                  <Combobox
                    {...other}
                    aria-label="Select accounts"
                    enableSelectAll
                    formatSelectedText={({ selectedItems, selectionManager }) => {
                      if (selectionManager.isSelectAll) {
                        return t('common:select.allSelected');
                      }
                      return t('common:select.selected', {
                        count: selectedItems.length,
                        of: accountIdSelectOptions.length,
                      });
                    }}
                    isDisabled={disabled ?? !isEnabled}
                    isMultiple
                    name={name}
                    onSelectionChange={(selectedOptions) => {
                      if (selectedOptions === 'all') {
                        handleDataChange({
                          ...getValues(),
                          accountIds: accountIdSelectOptions.map(({ value: newValue }) => newValue),
                        });
                        onChange(accountIdSelectOptions.map(({ value: newValue }) => newValue));
                        return;
                      }

                      handleDataChange({
                        ...getValues(),
                        accountIds: [...selectedOptions].map(String),
                      });
                      onChange([...selectedOptions].map(String));
                    }}
                    placeholder={t('main:manageMenu.placeholderSelectAccounts')}
                    selectedKeys={value ?? []}
                  >
                    {accountIdSelectOptionsByRange.map(({ label, options }) => (
                      <ComboboxSection
                        key={label}
                        title={label}
                      >
                        {options.map(({ label: itemLabel, value: key }) => (
                          <ComboboxItem key={key?.toString()}>{itemLabel}</ComboboxItem>
                        ))}
                      </ComboboxSection>
                    ))}
                  </Combobox>
                )}
              />
            </div>
          </div>
        </form>
      </DisabableContent>
    </div>
  );
};

export const ImputedTradeTax = bindControllers(ImputedTradeTaxView, [useController]);

ImputedTradeTax.displayName = 'ImputedTradeTax';
