import { useEffect, useState } from 'react';

import { Button, Input, Tooltip } from 'appkit-react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { sendPost } from '../../utils/apiCalls';
import { _getClientID, _getDTMSLink, _getLoginUrl } from '../../utils/env-url';

const validatePassword = (password) => {
  // Check if password length is between 8 and 20
  if (password.length < 8 || password.length > 20) {
    return false;
  }
  // Check for uppercase letter
  if (!/[A-Z]/.test(password)) {
    return false;
  }
  // Check for lowercase letter
  if (!/[a-z]/.test(password)) {
    return false;
  }
  // Check for number
  if (!/\d/.test(password)) {
    return false;
  }
  // Check for special character
  if (!/[!$%&*?@]/.test(password)) {
    return false;
  }
  return true;
};

const Register = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({
    email: false,
    fName: false,
    lName: false,
    password: false,
  });

  const [credentials, setCredentials] = useState({
    email: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    password: '',
    product: _getClientID(),
    registrationToken: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const path = _getLoginUrl();

  useEffect(() => {
    const code = params.regToken || undefined;
    const email = params.regEmail ?? '';
    if (code !== undefined) {
      setCredentials({ ...credentials, registrationToken: code, email });
    } else {
      window.location.href = path;
    }
  }, [credentials, params.regToken, params.regEmail, path]);

  const onEmailChange = (value) => {
    setCredentials({ ...credentials, email: value });
    setIsError({ ...isError, email: value.trim() === '' });
  };
  const onFNameChange = (value) => {
    if (/^[A-Za-z]*$/.test(value)) {
      setCredentials({ ...credentials, firstName: value });
      setIsError({ ...isError, fName: value.trim() === '' });
    }
  };
  const onLNameChange = (value) => {
    if (/^[A-Za-z]*$/.test(value)) {
      setCredentials({ ...credentials, lastName: value });
      setIsError({ ...isError, lName: value.trim() === '' });
    }
  };
  const onPasswordChange = (value) => {
    setCredentials({ ...credentials, password: value });
    setIsError({ ...isError, password: value.trim() === '' });
  };

  const handleRegister = async () => {
    setIsLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(credentials.email);
    const isPasswordValid = validatePassword(credentials.password);
    const isMobileNumberValid = !credentials.mobileNumber || credentials.mobileNumber.length === 10;

    if (
      !isEmailValid ||
      !isPasswordValid ||
      !isMobileNumberValid ||
      credentials.email.trim() === '' ||
      credentials.firstName.trim() === '' ||
      credentials.lastName.trim() === '' ||
      credentials.password.trim() === ''
    ) {
      setIsLoading(false);
      setIsError({
        ...isError,
        email: credentials.email.trim() === '' || !isEmailValid,
        fName: credentials.firstName.trim() === '',
        lName: credentials.lastName.trim() === '',
        mobileNumber: !isMobileNumberValid,
        password: credentials.password.trim() === '' || !isPasswordValid,
      });
    } else {
      try {
        const response = await sendPost('/productIAM/register', {
          ...credentials,
          preferredLanguage: 'en',
        });
        if (response.status === 'SUCCESS') {
          toast.success('You have been successfully registered!', {
            autoClose: 3000,
            closeOnClick: true,
            hideProgressBar: false,
            onClose: goToLoginLink,
            position: 'top-right',
          });
          setIsLoading(false);
        } else if (response.status) {
          setIsLoading(false);
          toast.error(`${response.status}`, {
            autoClose: 3000,
            closeOnClick: true,
            hideProgressBar: false,
            onClose: goToLoginLink,
            position: 'top-right',
          });
        }
      } catch {
        toast.error('Something went wrong!', {
          autoClose: 3000,
          closeOnClick: true,
          hideProgressBar: false,
          position: 'top-right',
        });
        setIsLoading(false);
      }
    }
  };

  const goToLoginLink = () => {
    navigate('/');
  };

  const onMNumberChange = (oldValue) => {
    let newValue = oldValue.replaceAll(/\D/g, '');
    setCredentials({ ...credentials, mobileNumber: newValue });
  };

  return (
    <>
      <div className="z-10 flex h-screen w-full flex-col overflow-auto bg-[#f6f6f7]">
        <div className="flex grow items-center justify-center">
          <div className="w-1/2 rounded bg-white p-8 shadow-2xl md:w-auto">
            <div className="message mb-2 text-lg">Please submit the form below to register</div>
            <div className="onboard-form register-form">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input
                  className="register_details"
                  errMsg="Please Enter First Name"
                  hasError={isError.fName}
                  label="First Name"
                  name="orgFName"
                  onChange={onFNameChange}
                  required
                  style={{ width: '100%' }}
                  type="text"
                  value={credentials.firstName}
                />
                <div style={{ width: '16px' }} />
                <Input
                  className="register_details"
                  errMsg="Please Enter Last Name"
                  hasError={isError.lName}
                  label="Last Name"
                  name="orgLName"
                  onChange={onLNameChange}
                  required
                  style={{ width: '100%' }}
                  type="text"
                  value={credentials.lastName}
                />
              </div>
              <Input
                className="register_details"
                errMsg="Please Enter Valid Email"
                hasError={isError.email}
                label="Email Address"
                name="orgEMail"
                onChange={onEmailChange}
                required
                style={{ margin: '0.75rem 0' }}
                type="text"
                value={credentials.email}
              />
              <Input
                className="register_details"
                errMsg="Please Enter 10 digit Mobile Number"
                hasError={isError.mobileNumber}
                label="Phone Number (optional)"
                name="orgMobile"
                onChange={onMNumberChange}
                style={{ margin: '0.75rem 0' }}
                type="text"
                value={credentials.mobileNumber}
              />
              <Input
                className="register_details"
                errMsg="Enter Password within the allowed limits"
                hasError={isError.password}
                label="Password"
                name="orgPassword"
                onChange={onPasswordChange}
                required
                style={{ margin: '0.75rem 0' }}
                suffix={
                  <span
                    style={{
                      display: 'flex',
                      fontSize: 18,
                      justifyContent: 'space-between',
                      width: '44px',
                    }}
                  >
                    <span
                      onClick={() => {
                        setShowPassword((show) => !show);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {showPassword ? (
                        <span className="icon-view-fill" />
                      ) : (
                        <span className="icon-view-outline" />
                      )}
                    </span>
                    <Tooltip
                      className="auth-tooltip"
                      content={[
                        <ul
                          key="list"
                          style={{
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '14px',
                          }}
                        >
                          <li>Password must have 8 to 20 characters.</li>
                          <li>Password must have atleast one uppercase character.</li>
                          <li>Password must have atleast one lowercase character.</li>
                          <li>Password must have atleast one number.</li>
                          <li>
                            Password must have atleast one special character out of "@", "$", "!",
                            "%", "*", "?", and "&".
                          </li>
                        </ul>,
                      ]}
                      placement="bottom"
                      tooltipTheme="light"
                    >
                      <span
                        className="icon-help-question-fill appkiticon"
                        style={{ fontSize: 16 }}
                      />
                    </Tooltip>
                  </span>
                }
                type={showPassword ? 'text' : 'password'}
                value={credentials.password}
              />
            </div>
            <Button
              className="dtms-login-btn float-right"
              isLoading={isLoading}
              isLoadingBlocking
              onClick={handleRegister}
              size="lg"
              style={{ margin: '0.75rem 0' }}
            >
              Register
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
