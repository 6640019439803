import { useCallback, useMemo } from 'react';

import { createNewEmptyConfig } from '@org/utils';

import type { MasterConfiguration, MasterConfigurationEntityDTO } from '../dice';
import {
  useActivateMasterConfiguration,
  useCopyConfig,
  useCreateBackupOfMasterConfiguration,
  useCreateVariantConfigurations,
  useDeleteMasterConfiguration,
  useFinalizeMasterConfiguration,
  useGetMasterConfigurationByYearCalculation,
  usePostMasterConfiguration,
  usePromoteMasterConfigurationToPrimary,
  usePutMasterConfigurationEntity,
  useRestoreBackupOfMasterConfiguration,
} from '../dice';

export function useMasterConfigurationByYearCalculation({
  yearCalculationId,
  masterConfigurationId,
}: {
  yearCalculationId: string;
  masterConfigurationId?: string;
}) {
  const variables = useMemo(
    () => ({
      pathParams: {
        yearCalculationId: yearCalculationId!,
      },
    }),
    [yearCalculationId],
  );

  const { data: masterConfigurationEntities } = useGetMasterConfigurationByYearCalculation(
    variables,
    {
      enabled: !!yearCalculationId,
      refetchOnWindowFocus: false,
    },
  );

  const createBackupMutation = useCreateBackupOfMasterConfiguration({});

  const copyConfigMutation = useCopyConfig({});

  const { mutateAsync: restoreBackupAsync } = useRestoreBackupOfMasterConfiguration({});

  const createVariantMutation = useCreateVariantConfigurations({});

  const { mutateAsync: promoteConfigAsync } = usePromoteMasterConfigurationToPrimary({});

  const updateMasterConfigEntityMutation = usePutMasterConfigurationEntity({});

  const deleteMasterConfigMutation = useDeleteMasterConfiguration({});

  const { mutateAsync: createNewMasterConfigAsync } = usePostMasterConfiguration({});

  const finalizeConfigMutation = useFinalizeMasterConfiguration({});

  const activateConfigMutation = useActivateMasterConfiguration({});

  const activateConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await activateConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [activateConfigMutation],
  );

  const finalizeConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await finalizeConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [finalizeConfigMutation],
  );

  const createNewMasterConfig = useCallback(
    async ({ year }: { year: string }) => {
      await createNewMasterConfigAsync({
        body: createNewEmptyConfig({ name: 'New configuration', year }) as MasterConfiguration,
        pathParams: {
          yearCalculationId,
        },
      });
    },
    [createNewMasterConfigAsync, yearCalculationId],
  );

  const copyMasterConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await copyConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
          targetYearCalculationId: yearCalculationId,
        },
      });
    },
    [copyConfigMutation, yearCalculationId],
  );

  const promoteConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await promoteConfigAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [promoteConfigAsync],
  );

  const deleteMasterConfig = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await deleteMasterConfigMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [deleteMasterConfigMutation],
  );

  const updateMasterConfigEntity = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await updateMasterConfigEntityMutation.mutateAsync({
        body: entity,
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [updateMasterConfigEntityMutation],
  );

  const createVariant = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await createVariantMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [createVariantMutation],
  );

  const createBackup = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await createBackupMutation.mutateAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [createBackupMutation],
  );

  const restoreBackup = useCallback(
    async (entity: MasterConfigurationEntityDTO) => {
      await restoreBackupAsync({
        pathParams: {
          masterConfigurationId: entity.id ?? '',
        },
      });
    },
    [restoreBackupAsync],
  );

  const selectedEntity = masterConfigurationEntities?.find(
    (config) => config.id === masterConfigurationId,
  );

  return {
    activateConfig,
    copyMasterConfig,
    createBackup,
    createNewMasterConfig,
    createVariant,
    deleteMasterConfig,
    finalizeConfig,
    masterConfigurationEntities,
    promoteConfig,
    restoreBackup,
    selectedEntity,
    updateMasterConfigEntity,
    copyConfigMutation,
    deleteMasterConfigMutation,
    createBackupMutation,
    createVariantMutation,
    activateConfigMutation,
    finalizeConfigMutation,
    updateMasterConfigEntityMutation,
  };
}
