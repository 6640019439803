import { useCallback } from 'react';

import type { SaveToggleFilterDataQueryParams } from '../dice';
import { useSaveToggleFilterData } from '../dice';

interface UseCamsTogglesParams {
  calculationYear: string;
  fileType: SaveToggleFilterDataQueryParams['fileType'];
  clientId: string;
  yearId: string;
  isPostCalculation: SaveToggleFilterDataQueryParams['isPostCalculation'];
  erpType: string;
}

export const useCamsToggles = ({
  clientId,
  yearId,
  calculationYear,
  fileType,
  isPostCalculation,
  erpType,
}: UseCamsTogglesParams) => {
  const { mutateAsync: updateToggleAsync, isPending: updateToggleIsLoading } =
    useSaveToggleFilterData();

  const updateToggle = useCallback(
    async ({ toggleName, toggleValue }: { toggleName: string; toggleValue: boolean }) =>
      await updateToggleAsync({
        queryParams: {
          calculationYear: Number(calculationYear),
          clientId,
          yearId,
          fileType,
          toggleValue,
          toggleName,
          isPostCalculation,
          erpType: erpType as SaveToggleFilterDataQueryParams['erpType'],
        },
      }),
    [calculationYear, clientId, erpType, fileType, isPostCalculation, updateToggleAsync, yearId],
  );

  return {
    updateToggle,
    updateToggleIsLoading,
  };
};
