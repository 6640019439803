import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import { t } from '@org/locales';

export interface UseColumnsProps {
  openDeleteModal: (data: ICellRendererParams) => void;
  activeCorporateRisk: boolean;
  costCentersConfig: dice.CostCenter[];
}

export const useColumns = (props: UseColumnsProps) => {
  const { openDeleteModal, activeCorporateRisk, costCentersConfig } = props;

  const costCenterOptions = costCentersConfig.map((cc) => ({
    label: cc.longName,
    value: cc.shortName,
  }));

  return useMemo(
    () =>
      [
        {
          cellEditor: 'select',
          cellEditorParams: {
            isMulti: true,
            options: costCenterOptions,
            placeholder: t('main:manageMenu.manageCorporateRisk.placeholderCostCenters'),
          },
          cellEditorPopup: true,
          colId: 'costCenterShortNames',
          editable: activeCorporateRisk,
          field: 'costCenterShortNames',
          flex: 1,
          headerName: t('main:manageMenu.manageCorporateRisk.costCenters'),
        },
        {
          cellClass: 'align-to-center-and-right',
          colId: 'surchargeRate',
          editable: activeCorporateRisk,
          field: 'surchargeRate',
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageCorporateRisk.surchargeRate'),
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRenderer: 'checkbox',
          cellRendererParams: () => ({
            disabled: !activeCorporateRisk,
          }),
          colId: 'considerRevenues',
          editable: false,
          field: 'considerRevenues',
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageCorporateRisk.considerRevenues'),
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRenderer: 'button',
          cellRendererParams: {
            disabled: !activeCorporateRisk,
            icon: 'icon-delete-outline',
            onClick: openDeleteModal,
            size: 'iconButton',
          },
          colId: 'actions',
          flex: 3,
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
        },
      ] satisfies (ColDef<dice.CorporateRiskConfig> | ColGroupDef<dice.CorporateRiskConfig>)[],
    [costCenterOptions, activeCorporateRisk, openDeleteModal],
  );
};
