import { useState } from 'react';

import { Input } from '@nextui-org/react';

import { useTranslation } from '@org/locales';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { PublicFacilitiesModalContentProps } from '..';
import { isNameDuplicate } from '../utils';

export const RenamePublicFacilityModalContent = ({
  disclosureState,
  sortedPublicFacilities,
  selectedPublicFacility,
  updatePublicFacility,
}: PublicFacilitiesModalContentProps) => {
  const { t } = useTranslation();
  const { name: selectedPublicFacilityName } = selectedPublicFacility;
  const { onClose } = disclosureState;

  const [newPublicFacilityName, setNewPublicFacilityName] = useState(
    selectedPublicFacilityName ?? '',
  );

  const duplicatedPublicFacility = isNameDuplicate(
    newPublicFacilityName,
    sortedPublicFacilities?.map((item) => item.name?.toLowerCase() ?? '') ?? [],
  );

  const handleCloseModal = () => {
    onClose?.();
    setNewPublicFacilityName('');
  };

  return (
    <>
      <ModalHeader>
        {t('common:contextMenu.modalUpdate.title', {
          name: selectedPublicFacilityName,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>
        <Input
          label={t('main:clients.clientsOverviewPanel.lblName')}
          onChange={({ target: { value } }) => {
            setNewPublicFacilityName(value);
          }}
          value={newPublicFacilityName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={handleCloseModal}
          size="md"
          variant="secondary"
        >
          {t('common:btnCancel')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={duplicatedPublicFacility || !newPublicFacilityName}
          onClick={async () => {
            try {
              await updatePublicFacility({
                ...selectedPublicFacility,
                name: newPublicFacilityName,
              });
              handleCloseModal();
            } catch {
              showNotification(
                'warning',
                t('main:clients.clientsOverviewPanel.alerts.warning.general'),
              );
            }
          }}
          size="md"
          variant="primary"
        >
          {t('common:update')}
        </Button>
      </ModalFooter>
    </>
  );
};
