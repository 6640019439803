import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import { AllocationStatusIcon } from '@org/features';
import { useTranslation } from '@org/locales';

export const useColumns = (costCenters?: dice.AllocationConfigCostCenterDTO[]) => {
  const { t } = useTranslation();

  const costCentersMap = useMemo(
    () => new Map(costCenters?.map((ccm) => [ccm.shortName, ccm.longName])),
    [costCenters],
  );

  return useMemo(
    () =>
      [
        {
          cellRenderer: 'agGroupCellRenderer',
          colId: 'rankOfCC',
          field: 'rankOfCC',
          filter: 'agTextColumnFilter',
          flex: 1,
          floatingFilter: true,
          headerName: t('main:manageMenu.allocationKeysConfiguration.tableColumns.rank'),
          minWidth: 80,
        },
        {
          colId: 'indirectCostCenterShortName',
          field: 'indirectCostCenterShortName',
          filter: 'agTextColumnFilter',
          flex: 10,
          floatingFilter: true,
          headerName: t(
            'main:manageMenu.allocationKeysConfiguration.tableColumns.indirectCostCenters',
          ),
          valueFormatter: ({ value }) => costCentersMap.get(value) ?? value,
        },
        {
          colId: 'basisType',
          field: 'basisType',
          filter: 'agTextColumnFilter',
          flex: 2,
          floatingFilter: true,
          headerName: t('main:manageMenu.allocationKeysConfiguration.tableColumns.basis'),
          valueFormatter: ({ value }) =>
            value ? t(`main:manageMenu.allocationKeysConfiguration.basisEnum.${value}`) : '',
        },
        {
          colId: 'methodType',
          field: 'methodType',
          filter: 'agTextColumnFilter',
          flex: 2,
          floatingFilter: true,
          headerName: t('main:manageMenu.allocationKeysConfiguration.tableColumns.method'),
          valueFormatter: ({ value }) =>
            value ? t(`main:manageMenu.allocationKeysConfiguration.methodEnum.${value}`) : '',
        },
        {
          cellRenderer: AllocationStatusIcon,
          colId: 'status',
          field: 'status',
          filter: 'agTextColumnFilter',
          flex: 1,
          floatingFilter: true,
          headerName: t('main:manageMenu.allocationKeysConfiguration.tableColumns.status'),
          tooltipValueGetter: ({ value }) =>
            t(`main:manageMenu.allocationKeysConfiguration.allocationStatus.${value}`),
        },
      ] satisfies (
        | ColDef<dice.AllocationKeyConfigDTO>
        | ColGroupDef<dice.AllocationKeyConfigDTO>
      )[],
    [costCentersMap, t],
  );
};
