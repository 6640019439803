import { useCallback } from 'react';

import type { APIParams } from '../..';
import type { TechnicalDataDTO } from '../dice';
import {
  useDeleteTechnicalDataEntry,
  useGetTechnicalDataByMasterConfigurationId,
  usePostTechnicalDataEntry,
  usePutTechnicalDataEntries,
  usePutTechnicalDataEntry,
} from '../dice';

export interface UseTechnicalDataParams extends APIParams<'masterConfigurationId'> {}

export const useTechnicalData = (params: UseTechnicalDataParams) => {
  const { data: technicalData } = useGetTechnicalDataByMasterConfigurationId({
    pathParams: params,
  });

  const { mutateAsync: createTechnicalData } = usePostTechnicalDataEntry({});

  const { mutateAsync: deleteTechnicalData } = useDeleteTechnicalDataEntry({});

  const { mutateAsync: updateTechnicalData } = usePutTechnicalDataEntries({});

  const { mutateAsync: updateTechnicalDataEntryAsync } = usePutTechnicalDataEntry({});

  const deleteTechnicalDataEntries = useCallback(
    async (ids: string[]) =>
      await deleteTechnicalData({
        pathParams: {
          ...params,
          ids,
        },
      }),
    [deleteTechnicalData, params],
  );

  const createTechnicalDataEntry = useCallback(
    async (body: TechnicalDataDTO) =>
      await createTechnicalData({
        body,
        pathParams: params,
      }),
    [createTechnicalData, params],
  );

  const updateTechnicalDataEntries = useCallback(
    async (body: TechnicalDataDTO[]) =>
      await updateTechnicalData({
        body,
        pathParams: params,
      }),
    [params, updateTechnicalData],
  );

  const updateTechnicalDataEntry = useCallback(
    async ({ id, body }: { id: string; body: TechnicalDataDTO }) =>
      await updateTechnicalDataEntryAsync({
        body,
        pathParams: {
          masterConfigurationId: params.masterConfigurationId,
          id,
        },
      }),
    [params, updateTechnicalDataEntryAsync],
  );

  return {
    createTechnicalDataEntry,
    deleteTechnicalDataEntries,
    technicalData,
    updateTechnicalDataEntries,
    updateTechnicalDataEntry,
  };
};
