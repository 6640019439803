import type {
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGroupSelectionState,
} from 'ag-grid-community';
import type { Dispatch, SetStateAction } from 'react';

import type { TFunction } from '@org/locales';
import type { aggregated } from '@org/query';
import { dice } from '@org/query';
import { showErrorCamsNotification, showNotification } from '@org/ui';
import { sortByKey } from '@org/utils';

import type { InitialFilterMap } from './utils';
import {
  createFilterStructure,
  getSelectedNodes,
  getSelectedNodesTreeRoot,
  setNodesInSelectedStructure,
} from './utils';

let initialData: Map<string, InitialFilterMap> | undefined;
let pendingPromises: {
  controller: AbortController;
}[] = [];
interface ApiFilterParams extends dice.GetImportAssetFiltersQueryParams {
  apiFileType: Extract<aggregated.ApiFileType, 'ASSETS' | 'JOURNAL' | 'LEDGER_ACCOUNT'>;
  isPostCalculation: boolean;
}

export function clearServerSideCache(api: GridApi) {
  initialData = undefined;
  api.refreshServerSide({ purge: true });
}

export function createServerSideDatasource(
  apiParams: ApiFilterParams,
  t: TFunction,
  setFiltersWithChildren: Dispatch<SetStateAction<Set<unknown>>>,
) {
  const dataSource: IServerSideDatasource = {
    destroy() {
      // pending promises blocking dataSource
      pendingPromises.forEach(({ controller }) => {
        controller.abort();
      });
      pendingPromises = [];
    },
    getRows: async (params: IServerSideGetRowsParams) => {
      try {
        if (initialData === undefined) {
          let fetchedData;

          if (apiParams.apiFileType === 'ASSETS') {
            const controller = new AbortController();
            const { signal } = controller;
            pendingPromises.push({
              controller,
            });
            fetchedData = await dice.fetchGetImportAssetFilters(
              {
                queryParams: apiParams,
              },
              signal,
            );
          } else if (apiParams.apiFileType === 'LEDGER_ACCOUNT') {
            const controller = new AbortController();
            const { signal } = controller;
            pendingPromises.push({
              controller,
            });
            fetchedData = await dice.fetchGetImportLedgerAccountFilters(
              {
                queryParams: apiParams,
              },
              signal,
            );
          } else {
            const controller = new AbortController();
            const { signal } = controller;
            pendingPromises.push({
              controller,
            });
            fetchedData = await dice.fetchGetImportJournalFilters(
              {
                queryParams: apiParams,
              },
              signal,
            );
          }

          const { xInternalErrorCode } = fetchedData;

          if (xInternalErrorCode !== 200) {
            params.success({
              rowData: [],
              rowCount: 0,
            });

            showErrorCamsNotification(t, fetchedData);
            return;
          }

          initialData = createFilterStructure(fetchedData);

          const initialDataArray = Array.from(initialData.values());

          const rowData = sortByKey(initialDataArray, 'nodeKey');

          const filtersWithChildren = rowData
            .filter(({ children }) => children.length > 0)
            .map(({ id }) => id);

          setFiltersWithChildren(new Set(filtersWithChildren));

          // costCenterSetKeys labels and other in this data set
          params.success({
            rowData,
            rowCount: rowData.length,
          });

          const parentNodes: IServerSideGroupSelectionState[] = [];

          rowData.forEach((row) => {
            const { children, id } = row;

            if (children.length > 0) {
              const selectedAll = children.every((child) => child.isSelected === 'FULLY');

              if (selectedAll) {
                parentNodes.push({
                  nodeId: id ?? '',
                  selectAllChildren: true,
                  toggledNodes: [],
                });
              } else {
                const selectedNodes = getSelectedNodes(children) ?? [];

                if (selectedNodes.length > 0) {
                  parentNodes.push({
                    nodeId: id ?? '',
                    selectAllChildren: false,
                    toggledNodes: selectedNodes,
                  });
                }
              }
            }

            const selectionStateTree = getSelectedNodesTreeRoot();
            selectionStateTree.toggledNodes = parentNodes;

            params.api.setServerSideSelectionState(
              selectionStateTree as IServerSideGroupSelectionState,
            );
          });
        } else {
          const groupKey = params.request.groupKeys.at(-1);
          const parentNodeId = params.parentNode.id ?? '';

          // check whether there is data in ag grid
          // getServerSideGroupLevelState - returns number of rendered groups, there is also 1 default and at least 1 for initial labels
          if (params.api.getServerSideGroupLevelState()?.length < 2) {
            clearServerSideCache(params.api);
            return;
          }

          if (Array.from(initialData.keys()).includes(groupKey ?? '')) {
            const initialDataList = Array.from(initialData.values());
            const treePart = initialDataList.find((item) => item.nodeKey === groupKey);

            const sortedTreePart = treePart?.children ?? [];

            params.success({
              rowData: sortedTreePart,
              rowCount: sortedTreePart.length,
            });
          } else {
            let ccData;
            if (apiParams.apiFileType === 'ASSETS') {
              ccData = await dice.fetchGetCostCenterChildrenAsset({
                body: {
                  clientId: apiParams.esraClientId,
                  calculationYear: Number(apiParams.calculationYear),
                  selectedRoot: parentNodeId,
                  yearId: apiParams.yearCalculationId,
                  erpType: apiParams.erpType,
                },
              });
            } else if (apiParams.apiFileType === 'LEDGER_ACCOUNT') {
              ccData = await dice.fetchGetCostCenterChildrenLedger({
                body: {
                  clientId: apiParams.esraClientId,
                  calculationYear: Number(apiParams.calculationYear),
                  selectedRoot: parentNodeId,
                  yearId: apiParams.yearCalculationId,
                  erpType: apiParams.erpType,
                },
              });
            } else {
              ccData = await dice.fetchGetCostCenterChildrenJournal({
                body: {
                  clientId: apiParams.esraClientId,
                  calculationYear: Number(apiParams.calculationYear),
                  selectedRoot: parentNodeId,
                  yearId: apiParams.yearCalculationId,
                  erpType: apiParams.erpType,
                },
              });
            }

            const toggleValue =
              initialData.get('costCenterSetss')?.toggle ??
              initialData.get('costCenterSetKeys')?.toggle;

            const rowData =
              ccData?.map(({ nodeKey, id, isGroup, isSelected }) => ({
                nodeKey,
                id,
                isGroup,
                isSelected,
                toggle: toggleValue,
                groupName:
                  apiParams.apiFileType === 'LEDGER_ACCOUNT'
                    ? 'costCenterSetss'
                    : 'costCenterSetKeys',
              })) ?? [];

            const sortedRowData = sortByKey(rowData, 'nodeKey');

            params.success({
              rowData: sortedRowData,
              rowCount: sortedRowData.length,
            });

            const currentSelectionState =
              params.api.getServerSideSelectionState() as IServerSideGroupSelectionState;
            const selectedNodes = getSelectedNodes(
              sortedRowData,
            ) as IServerSideGroupSelectionState[];

            const selectionStateTree = setNodesInSelectedStructure(
              currentSelectionState,
              parentNodeId,
              selectedNodes,
            );

            params.api.setServerSideSelectionState(
              selectionStateTree as IServerSideGroupSelectionState,
            );
          }
        }
      } catch (error) {
        params.success({
          rowData: [],
          rowCount: 0,
        });

        // @ts-expect-error custom errors
        if (error.name !== 'AbortError') {
          showNotification(
            'error',
            t(
              'common:modals.camsImport.errors.NO_DATA_FOUND_FOR_ESRA_CLIENT_ID_AND_CALCULATION_YEAR',
            ),
            5000,
          );
        }
      }
    },
  };
  return dataSource;
}
