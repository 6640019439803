import type { griddy } from '../../../../query/src';

export const getAccountIdDescription = (
  accountId: string,
  accountsPlanMap: undefined | griddy.GetAllAccountIdToAccountDescriptionMapResponse,
) => accountsPlanMap?.[accountId] ?? '';

export const getAccountIdTextWithDescription = (
  accountId: string,
  accountsPlanMap: undefined | griddy.GetAllAccountIdToAccountDescriptionMapResponse,
) => {
  const accountDescription = getAccountIdDescription(accountId, accountsPlanMap);

  return `${accountId} ${accountDescription}`.trim();
};
