import type { IDetailCellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';

import type { APIParams } from '@org/query';
import type { BaseEditTableProps } from '@org/ui';
import { CostCenterMapping } from '@org/features';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, Label, LoaderWrapper } from '@org/ui';
import { bindControllers, costCenterTypes } from '@org/utils';

import type { CostCenterMappings } from './types';
import type { ControllerType } from './useController';
import { defaultColDef } from './constants';
import { useController } from './useController';

interface CostCenterConfigurationTableProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

interface DetailCellRendererProps extends IDetailCellRendererParams {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  data: CostCenterMappings;
}

const DetailCellRenderer = (params: DetailCellRendererProps) => {
  const { data, apiParams, node } = params;

  return (
    <CostCenterMapping
      apiParams={apiParams}
      mappingObject={data.mappingObject}
      onChange={(changed) => {
        node.setDataValue('mappingObject', {
          ...data.mappingObject,
          ...changed,
        });
      }}
      shortName={data.shortName}
    />
  );
};

const CostCenterConfigurationView: FC<CostCenterConfigurationTableProps> = (props) => {
  const { t } = useTranslation();
  const {
    agGridProps,
    columnDefs,
    detailCellRendererParams,
    autoGroupColumnDef,
    onRowDragMove,
    getRowId,
    onRowDragEnd,
    onChange,
    isLoading,
    createAutomaticCC,
    modal,
    tableRef,
    addNewGroupToCostCenterType,
    costCenterTypesExist,
    postSortRow,
  } = props;

  return (
    <div
      className="flex h-full flex-1 flex-col"
      data-testid="CostCenterConfiguration"
    >
      <div className="flex justify-between">
        <div className="inline-flex">
          <Label className="flex gap-2 text-xs">
            {t('main:manageMenu.manageCostCenters.createAutomaticCostCenters')}
          </Label>
          <Button
            className="mb-2 ml-3"
            onClick={() => createAutomaticCC('PROFIT_CENTER')}
            size="sm"
            variant="primary"
          >
            <span className="appkiticon icon-news-outline" />
            {t('main:manageMenu.manageCostCenters.profitCenter')}
          </Button>
          <Button
            className="mb-2 ml-3"
            onClick={() => createAutomaticCC('COST_CENTER')}
            size="sm"
            variant="primary"
          >
            <span className="appkiticon icon-news-outline" />
            {t('main:manageMenu.manageCostCenters.costCenters')}
          </Button>
          <Button
            className="mb-2 ml-3"
            onClick={() => createAutomaticCC('JOB_ID')}
            size="sm"
            variant="primary"
          >
            <span className="appkiticon icon-news-outline" />
            {t('main:manageMenu.manageCostCenters.jobIds')}
          </Button>
        </div>

        {(!costCenterTypesExist.INDIRECT_COST_CENTER ||
          !costCenterTypesExist.PRIMARY_COST_CENTER) && (
          <div className="inline-flex">
            <Label className="flex gap-2 text-xs">
              {t('main:manageMenu.manageCostCenters.createCostCenterCategory')}
            </Label>
            {!costCenterTypesExist.INDIRECT_COST_CENTER && (
              <Button
                className="mb-2 ml-3"
                onClick={() =>
                  addNewGroupToCostCenterType(costCenterTypes.INDIRECT_COST_CENTER.key)
                }
                size="sm"
                variant="primary"
              >
                <span className="appkiticon icon-news-outline" />
                {t('main:manageMenu.manageCostCenters.costCenterEnum.indirect')}
              </Button>
            )}
            {!costCenterTypesExist.PRIMARY_COST_CENTER && (
              <Button
                className="mb-2 ml-3"
                onClick={() => addNewGroupToCostCenterType(costCenterTypes.PRIMARY_COST_CENTER.key)}
                size="sm"
                variant="primary"
              >
                <span className="appkiticon icon-news-outline" />
                {t('main:manageMenu.manageCostCenters.costCenterEnum.primary')}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="flex-1 pb-2">
        <LoaderWrapper loading={isLoading}>
          <BaseEditTable
            {...agGridProps}
            animateRows
            autoGroupColumnDef={autoGroupColumnDef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            detailCellRenderer={DetailCellRenderer}
            detailCellRendererParams={detailCellRendererParams}
            detailRowHeight={415}
            enableCellTextSelection={false}
            enableGroupEdit
            getRowId={getRowId}
            groupDisplayType="singleColumn"
            isGroupOpenByDefault={() => true}
            masterDetail
            onCellValueChanged={onChange}
            onRowDragEnd={onRowDragEnd}
            onRowDragMove={onRowDragMove}
            postSortRows={postSortRow}
            ref={tableRef}
            rowDragMultiRow
            rowSelection="multiple"
          />
        </LoaderWrapper>
      </div>

      {modal}
    </div>
  );
};

export const CostCenterConfigurationPage = bindControllers(CostCenterConfigurationView, [
  useController,
]);
CostCenterConfigurationPage.displayName = 'CostCenterConfigurationPage';
