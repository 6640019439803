import type {
  CellRendererSelectorResult,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { useCallback, useMemo } from 'react';

import type { APIParams, griddy } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import { t } from '@org/locales';
import { aggregated } from '@org/query';
import {
  ENTRY,
  formatToSimpleDate,
  getCellClassesForGroups,
  getCellClassesForGroupsCustom,
  getCellClassesForGroupsWithActions,
  GROUP,
  sumBooleanValues,
} from '@org/utils';

const dateFormatter = (params: ValueFormatterParams | ValueGetterParams) =>
  params?.data?.purchasingDate ? formatToSimpleDate(new Date(params.data.purchasingDate)) : '';

export interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId'>;
  activeConfiguration: boolean;
  onTableDataChange: (tableData?: griddy.ImputedWithdrawalCapital[]) => void;
  addNewRow: () => void;
  addSubRow: (params: ICellRendererParams) => void;
  handleDeleteModal: (props: ButtonHandlerProps) => Promise<void> | void;
  rowData: griddy.ImputedWithdrawalCapital[];
}

export const useColumns = (props: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const {
    activeConfiguration,
    onTableDataChange,
    handleDeleteModal,
    addNewRow,
    addSubRow,
    apiParams,
    rowData,
  } = props;
  const { getCostCenterLongName, costCenterOptions } = aggregated.useCostCenters(apiParams);

  const activateReportsSectionCellRendererSelector = useCallback(
    (params: ICellRendererParams): CellRendererSelectorResult => {
      if (params.node.level > 0) {
        return {};
      }
      return {
        component: 'checkbox',
        params: {
          disabled: !activeConfiguration,
          editable: false,
          onEdit: (checked: boolean) => {
            const groupName = params.node.key;
            const colName = params.colDef?.field;

            onTableDataChange(
              rowData.map((item) => {
                if (item.groupName === groupName) {
                  return {
                    ...item,
                    [colName!]: checked,
                  };
                }
                return item;
              }),
            );
          },
        },
      };
    },
    [activeConfiguration, onTableDataChange, rowData],
  );

  const isHeaderCheckboxDisabled = useCallback(
    () => !activeConfiguration || rowData.length === 0,
    [activeConfiguration, rowData.length],
  );

  return useMemo(
    () =>
      [
        {
          colId: 'groupName',
          field: 'groupName',
          hide: true,
          rowGroup: true,
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'datePicker',
          cellEditorPopup: true,
          cellEditorPopupPosition: 'under',
          colId: 'purchasingDate',
          field: 'purchasingDate',
          filterValueGetter: dateFormatter,
          flex: 2,
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageImputedWithdrawalCapital.purchasingDate'),
          type: 'date',
        },
        {
          aggFunc: 'sum',
          cellClass: getCellClassesForGroups,
          colId: 'purchaseAmount',
          field: 'purchaseAmount',
          flex: 2,
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageImputedWithdrawalCapital.purchaseAmount'),
          type: 'price',
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'numeric',
          cellEditorParams: {
            decimalScale: 0,
          },
          colId: 'lifeTimeInMonths',
          field: 'lifeTimeInMonths',
          flex: 2,
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.manageImputedWithdrawalCapital.lifeTimeInMonths'),
          type: 'integer',
        },
        {
          cellClass: getCellClassesForGroups,
          cellEditor: 'select',
          cellEditorParams: {
            options: costCenterOptions,
            placeholder: t('main:manageMenu.manageImputedWithdrawalCapital.placeholderCostCenter'),
          },
          cellEditorPopup: true,
          colId: 'costCenterName',
          field: 'costCenterName',
          filterValueGetter: (params) => getCostCenterLongName(params?.data?.costCenterName ?? ''),
          flex: 3,
          headerName: t('main:manageMenu.manageImputedWithdrawalCapital.costCenterName'),
          valueFormatter: (params) => getCostCenterLongName(params?.data?.costCenterName ?? ''),
        },
        {
          cellClass: getCellClassesForGroups,
          colId: 'description',
          field: 'description',
          flex: 3,
          headerName: t('main:manageMenu.manageImputedWithdrawalCapital.description'),
        },
        {
          children: [
            {
              aggFunc: ({ values }) => sumBooleanValues(values),
              cellClass: (params) =>
                getCellClassesForGroupsCustom(params, ['align-to-center-and-center']),
              cellRendererSelector: activateReportsSectionCellRendererSelector,
              colId: 'babReportEnabled',
              editable: false,
              field: 'babReportEnabled',
              flex: 1,
              headerComponent: 'checkbox',
              headerComponentParams: {
                disabled: isHeaderCheckboxDisabled,
                indeterminate: !rowData.every(
                  (item) => item.babReportEnabled === rowData[0].babReportEnabled,
                ),
                label: 'BAB',
                onEdit: (checked: boolean) => {
                  onTableDataChange(
                    rowData.map((item) => ({
                      ...item,
                      babReportEnabled: checked,
                    })),
                  );
                },
                value: () => rowData.every((item) => item.babReportEnabled),
              },
              minWidth: 85,
              valueFormatter: () => '',
            },
            {
              aggFunc: ({ values }) => sumBooleanValues(values),
              cellClass: (params) =>
                getCellClassesForGroupsCustom(params, ['align-to-center-and-center']),
              cellRendererSelector: activateReportsSectionCellRendererSelector,
              colId: 'interestTableReportEnabled',
              editable: false,
              field: 'interestTableReportEnabled',
              flex: 1,
              headerComponent: 'checkbox',
              headerComponentParams: {
                disabled: isHeaderCheckboxDisabled,
                editable: false,
                indeterminate: !rowData.every(
                  (item) =>
                    item.interestTableReportEnabled === rowData[0].interestTableReportEnabled,
                ),
                label: t('main:manageMenu.manageImputedWithdrawalCapital.irr'),
                onEdit: (checked: boolean) => {
                  onTableDataChange(
                    rowData.map((item) => ({
                      ...item,
                      interestTableReportEnabled: checked,
                    })),
                  );
                },
                value: () => rowData.every((item) => item.interestTableReportEnabled),
              },
              minWidth: 85,
              valueFormatter: () => '',
            },
          ],
          headerName: t('main:manageMenu.manageImputedWithdrawalCapital.activate'),
        },
        {
          cellClass: getCellClassesForGroupsWithActions,
          cellRendererSelector: (params) => {
            if (params.node.group) {
              return {
                component: 'button',
                params: {
                  className: 'flex',
                  disabled: !activeConfiguration,
                  icon: 'icon-delete-outline',
                  onClick: (btnProps: ButtonCellRendererProps) =>
                    handleDeleteModal({ ...btnProps, type: GROUP }),
                  size: 'iconButton',
                },
              };
            }
            return {
              component: 'multipleButtons',
              params: {
                buttonParams: [
                  {
                    disabled: !activeConfiguration,
                    icon: 'icon-plus-fill',
                    onClick: addSubRow,
                    size: 'iconButton',
                  },
                  {
                    disabled: !activeConfiguration,
                    icon: 'icon-delete-outline',
                    onClick: (btnProps: ButtonCellRendererProps) =>
                      handleDeleteModal({ ...btnProps, type: ENTRY }),
                    size: 'iconButton',
                  },
                ],
                className: 'flex',
              },
            };
          },
          colId: 'actions',
          editable: false,
          flex: 1,
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            disabled: !activeConfiguration,
            icon: 'icon-plus-fill',
            onClick: addNewRow,
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          minWidth: 55,
        },
      ] satisfies (
        | ColDef<griddy.ImputedWithdrawalCapital>
        | ColGroupDef<griddy.ImputedWithdrawalCapital>
      )[],
    [
      costCenterOptions,
      activateReportsSectionCellRendererSelector,
      isHeaderCheckboxDisabled,
      rowData,
      activeConfiguration,
      addNewRow,
      getCostCenterLongName,
      onTableDataChange,
      addSubRow,
      handleDeleteModal,
    ],
  );
};
