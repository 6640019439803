import type { dice } from '../../../../../query/src';
import { isPrimaryCostCenter } from '../utils';

export const updateImputedWorkingCapitalByCostCenterDelete = (props: {
  costCenter: dice.CostCenter;
  imputedWorkingCapitalConfig: dice.ImputedWorkingCapitalConfig | undefined;
}): dice.ImputedWorkingCapitalConfig => {
  const { costCenter, imputedWorkingCapitalConfig = {} } = props;
  const { workingCapitals = [] } = imputedWorkingCapitalConfig;

  if (isPrimaryCostCenter(costCenter)) {
    const updatedWorkingCapital = workingCapitals.filter(
      (ccWc) => ccWc.primaryCostCenterShortName !== costCenter.shortName,
    );
    return {
      ...imputedWorkingCapitalConfig,
      workingCapitals: updatedWorkingCapital,
    };
  }
  return imputedWorkingCapitalConfig;
};
