import type { FC } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { useTranslation } from '@org/locales';

export interface ErrorMessageProps {
  isValid: boolean;
}
export const ErrorMessage: FC<ErrorMessageProps> = ({ isValid }) => {
  const { t } = useTranslation();
  if (isValid) {
    return null;
  }

  return (
    <div className="absolute left-2 top-3 flex items-center justify-center gap-1 rounded bg-red-500 bg-opacity-80 p-2 text-white">
      <ExclamationCircleIcon className="h-5 w-5" />
      <span>{t('main:manageMenu.manageCostCenters.invalidConfiguration')}</span>
    </div>
  );
};
