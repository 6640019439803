import type { aggregated, dice } from '@org/query';
import { t } from '@org/locales';
import { FILE_CONFIG } from '@org/utils';

const getErrorMessage = (errorData: dice.Message, baseFileType: aggregated.BaseFileType) => {
  switch (errorData.type) {
    case 'MissingColumns': {
      return t('main:clients.menu.fileMenu.alerts.error.missingMandatoryColumns', {
        columns: errorData.message,
        type: baseFileType,
      });
    }
    case 'MandatoryFields': {
      return t('main:clients.menu.fileMenu.alerts.error.missingMandatoryFields', {
        columnName: errorData.attribute,
        count: errorData.count,
      });
    }
    case 'DataType': {
      return t('main:clients.menu.fileMenu.alerts.error.wrongDataTypes', {
        columnName: errorData.attribute,
        count: errorData.count,
      });
    }
    case 'Unique': {
      return t('main:clients.menu.fileMenu.alerts.error.columnEntriesNotUnique', {
        columnNames: errorData.attribute,
      });
    }
    case 'Griddy':
    case 'Parser': {
      if (errorData.attribute === 'BOM') {
        return t('main:clients.menu.fileMenu.alerts.error.fileHasBom');
      }
      return t('main:clients.menu.fileMenu.alerts.error.generalError');
    }
    default: {
      return t('main:clients.menu.fileMenu.alerts.error.generalError');
    }
  }
};

export function getFileUploadErrorMessage(
  errorResponse: dice.UploadResponse,
  baseFileType: aggregated.BaseFileType,
) {
  const errorMessages = errorResponse.errorMessages?.map((message) => {
    const { row } = message;

    let rowNumber;
    let fileColName;

    if (row) {
      switch (baseFileType) {
        case FILE_CONFIG.ASSETS.fileName: {
          rowNumber = (row as dice.Asset)?.assetNumber;
          fileColName = FILE_CONFIG.ASSETS.fields.assetNumber.inputName;

          break;
        }
        case FILE_CONFIG.ACCOUNT_PLAN.fileName: {
          rowNumber = (row as dice.AccountPlan)?.accountId;
          fileColName = FILE_CONFIG.ACCOUNT_PLAN.fields.accountId.inputName;

          break;
        }
        case FILE_CONFIG.JOURNAL.fileName: {
          rowNumber = (row as dice.AccountTransaction)?.accountId;
          fileColName = FILE_CONFIG.JOURNAL.fields.accountId.inputName;

          break;
        }
        case FILE_CONFIG.LABELS.fileName: {
          rowNumber = (row as dice.LabelRecord)?.number;
          fileColName = FILE_CONFIG.LABELS.fields.number.inputName;

          break;
        }
        case FILE_CONFIG.LEDGER_ACCOUNT.fileName: {
          rowNumber = (row as dice.LedgerAccount)?.accountId;
          fileColName = FILE_CONFIG.LEDGER_ACCOUNT.fields.accountId.inputName;
        }
      }
    } else {
      rowNumber = "'unknown'";
    }
    return `Row number: ${rowNumber}${fileColName ? ` (${fileColName})` : ''} - ${getErrorMessage(
      message,
      baseFileType,
    )}`;
  });

  return {
    detailErrorMessages: errorMessages ?? [],
    errorMessage: errorResponse.message,
  };
}
