import type {
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  GetRowIdParams,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';
import {
  generateGroupRowName,
  getCellClassesForGroups,
  GROUP,
  prepareGroupRowNamesFromGridAPI,
  SUB_GROUP,
} from '@org/utils';

import type { BaseControllerType } from '../types';
import type { GroupColType, PriceSheetRow, RowType } from './types';
import { MOVE_DIRECTION } from '../CostCenterConfiguration';
import { BASIC_FEE, GROUP_COL_NAME, SUB_GROUP_COL_NAME } from './constants';
import {
  createNestedStructure,
  createRow,
  getPriceSheetItem,
  getSubGroupsFromNestedStructure,
} from './helpers';
import { useColumns } from './useColumns';

export type ControllerProps = BaseControllerType;

export type ControllerType = ReturnType<typeof useController>;

const defaultColDef = {
  editable: (params: EditableCallbackParams) => {
    if (
      // enable editing for currency only on subgroup level
      params.node.group &&
      params.column.getColId() === 'currency' &&
      params.node.parent?.rowIndex !== null
    ) {
      return true;
    }

    return !params.node.group && params.colDef.field !== 'currency';
  },
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
  resizable: true,
} satisfies ColDef;

export function useController(apiParams: ControllerProps) {
  const { masterConfigurationId: masterConfigId } = apiParams;
  const { t } = useTranslation();
  const tableRef = useRef<AgGridReact>(null);

  const {
    priceSheetGroups,
    addPriceSheetGroup,
    addPriceSheetSubGroup,
    addPriceSheetEntry,
    updatePriceSheetGroup,
    updatePriceSheetSubGroup,
    updatePriceSheetEntry,
    deletePriceSheetGroup,
    deletePriceSheetSubGroup,
    deletePriceSheetEntry,
    isLoading,
  } = aggregated.usePriceSheet(apiParams);

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      cellClass: getCellClassesForGroups,
      cellRendererParams: {
        suppressCount: true,
      },
      editable: ({ node }: EditableCallbackParams) => {
        const isBasicFeeGroup = node.allLeafChildren?.[0]?.data?.source === BASIC_FEE;

        const isGroup = node.level === 0;
        const isSubGroupBasicFee = node.level === 1 && isBasicFeeGroup;
        const isEntryBasicFee = node.level === 2 && node.data?.source === BASIC_FEE;

        return !(
          isGroup ||
          isSubGroupBasicFee ||
          isEntryBasicFee ||
          node.data?.source === BASIC_FEE ||
          isBasicFeeGroup
        );
      },
      field: 'entryName',
      flex: 3,
      headerName: t('main:manageMenu.priceSheet.tableColumns.description'),
    }),
    [t],
  );

  const generateGroupName = useCallback(
    (colName: string, level: number, type: string = 'newGroup') =>
      generateGroupRowName(
        t(`main:manageMenu.manageImputedWithdrawalCapital.${type}`),
        prepareGroupRowNamesFromGridAPI(tableRef.current?.api, colName, level),
      ),
    [t],
  );

  const addNewGroup = useCallback(async () => {
    const groupName = generateGroupName(GROUP_COL_NAME, 1);
    const subGroupName = 'newSubGroup';

    const {
      groupName: _,
      subGroupName: __,
      currency,
      ...newRow
    } = createRow({
      groupName,
      subGroupName,
    });
    const tableData = priceSheetGroups ?? [];
    const groupCount = new Set(tableData.map((row) => row.groupName)).size;

    const resGroup = await addPriceSheetGroup({
      name: groupName,
      rowIndex: groupCount,
    });

    const resSubGroup = await addPriceSheetSubGroup(resGroup.id!, {
      currency,
      name: subGroupName,
    });

    await addPriceSheetEntry(resSubGroup.id!, newRow);
  }, [
    addPriceSheetEntry,
    addPriceSheetGroup,
    addPriceSheetSubGroup,
    generateGroupName,
    priceSheetGroups,
  ]);

  const addNewRow = useCallback(
    async (type: RowType, props: ButtonCellRendererProps | undefined) => {
      let groupName: string;
      let subGroupName: string;
      const node = props?.gridProps?.node;
      const { parent, key: nodeKey, level = -1 } = node ?? {};

      if (level > 2 && parent?.key) {
        groupName = parent?.key;
        subGroupName = nodeKey!;
      } else if (level === 2) {
        groupName = nodeKey!;
        subGroupName = generateGroupName(SUB_GROUP_COL_NAME, 2, 'newSubGroup');
      } else {
        // adding new group tree
        groupName = generateGroupName(GROUP_COL_NAME, 1);
        subGroupName = generateGroupName(GROUP_COL_NAME, 2, 'newSubGroup');
      }

      const newRow = createRow({
        groupName,
        subGroupName,
      });

      const newlyAddedRow = tableRef.current?.api?.applyTransaction({
        add: [newRow],
      });

      if (newlyAddedRow?.add?.length) {
        const {
          add: [{ rowIndex }],
        } = newlyAddedRow;

        tableRef.current?.api?.ensureIndexVisible(rowIndex!, 'middle');
      }

      const { groupName: _, subGroupName: subGroupNameData, currency, ...entryData } = newRow;

      if (type === SUB_GROUP) {
        const nodeData = getPriceSheetItem(
          priceSheetGroups,
          GROUP_COL_NAME,
          props?.gridProps?.node.key ?? '',
        );
        if (nodeData?.groupId) {
          const resSubGroup = await addPriceSheetSubGroup(nodeData.groupId, {
            currency,
            name: subGroupNameData,
          });

          await addPriceSheetEntry(resSubGroup.id!, entryData);
        }
      } else {
        const { subGroupId } = props?.node?.allLeafChildren?.at(0)?.data ?? {};
        if (subGroupId) {
          await addPriceSheetEntry(subGroupId, entryData);
        }
      }
    },
    [addPriceSheetEntry, addPriceSheetSubGroup, generateGroupName, priceSheetGroups],
  );

  const onChange = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      node: { field: nodeField, key: nodeKey },
      data,
      value,
    } = event;

    if (!colName) {
      return;
    }

    if (nodeField === GROUP_COL_NAME || nodeField === SUB_GROUP_COL_NAME) {
      await onGroupCellChange(event, nodeField, nodeKey!);
    } else {
      const item = getPriceSheetItem(
        priceSheetGroups,
        SUB_GROUP_COL_NAME,
        data.subGroupName,
        GROUP_COL_NAME,
        data.groupName,
      );

      if (item) {
        const allowedColumns = ['factor1', 'factor2', 'factor3', 'factor4'];

        if (
          allowedColumns.includes(colName) &&
          (Number.isNaN(Number(value)) || value === '0' || !value)
        ) {
          showNotification('error', t('common:alerts.error.nonZeroNumber'));
          return;
        }

        await updatePriceSheetEntry([
          {
            costUnit: data.costUnit,
            factor1: data.factor1,
            factor2: data.factor2,
            factor3: data.factor3,
            factor4: data.factor4,
            id: data.entryId,
            name: data.entryName,
          },
        ]);
      }
    }
  });

  const onGroupCellChange = async (
    event: CellValueChangedEvent,
    fieldName: GroupColType,
    name: string,
  ) => {
    const { data, node } = event;

    const item =
      node.field === 'groupName'
        ? getPriceSheetItem(priceSheetGroups, fieldName, name)
        : getPriceSheetItem(
            priceSheetGroups,
            'groupName',
            node.parent?.key ?? '',
            'subGroupName',
            name ?? '',
          );

    if (item) {
      if (fieldName === GROUP_COL_NAME) {
        await updatePriceSheetGroup([
          {
            id: item.groupId,
            name: data.entryName ?? item.groupName,
            rowIndex: item.groupRowIndex,
          },
        ]);
      } else {
        await updatePriceSheetSubGroup([
          {
            currency: data.currency ?? item.currency,
            id: item.subGroupId,
            name: data.entryName ?? item.subGroupName,
            rowIndex: item.subGroupRowIndex,
          },
        ]);
      }
    }
  };

  const handleDelete = useCallback(
    async (btnProps: ButtonHandlerProps) => {
      const { type, ...props } = btnProps;

      let priceSheetItem: aggregated.PriceSheetRow | undefined;

      if (type === GROUP || type === SUB_GROUP) {
        const {
          gridProps: {
            node: { key: groupNameOrSubgroupName, parent },
          },
        } = props;

        if (type === GROUP) {
          priceSheetItem = getPriceSheetItem(
            priceSheetGroups,
            'groupName',
            groupNameOrSubgroupName ?? '',
          );
        } else {
          priceSheetItem = getPriceSheetItem(
            priceSheetGroups,
            'groupName',
            parent?.key ?? '',
            'subGroupName',
            groupNameOrSubgroupName ?? '',
          );
        }
      }

      if (type === GROUP) {
        if (priceSheetItem) {
          await deletePriceSheetGroup(priceSheetItem.groupId!);
        }
      } else if (type === SUB_GROUP) {
        if (priceSheetItem) {
          const items = priceSheetGroups?.filter(
            (group) => group?.groupName === priceSheetItem!.groupName,
          );

          await deletePriceSheetSubGroup(priceSheetItem.subGroupId!);

          if (items?.length === 1) {
            await deletePriceSheetGroup(priceSheetItem.groupId!);
          }
        }
      } else {
        const { data } = props;
        await deletePriceSheetEntry(data.entryId);

        const items = priceSheetGroups?.filter((group) => group?.groupName === data.groupName);

        // Deletes group and subgroup if there is only one last entry
        if (items?.length === 1) {
          await deletePriceSheetGroup(data.groupId);
          await deletePriceSheetSubGroup(data.subGroupId);
        }
      }
    },
    [deletePriceSheetEntry, deletePriceSheetGroup, deletePriceSheetSubGroup, priceSheetGroups],
  );

  const moveRow = useCallback(
    async ({ gridProps: { node } }: ButtonCellRendererProps, direction: string) => {
      const { level } = node;
      let newData: PriceSheetRow[] = [];
      const movingItems: PriceSheetRow[] = [];
      const movingSet = new Set<string>([]);

      node?.allLeafChildren?.forEach(({ data }) => {
        movingItems.push(data);
        movingSet.add(data.entryId);
      });

      newData = priceSheetGroups?.filter(({ entryId }) => !movingSet.has(entryId ?? '')) ?? [];

      if (level === 0) {
        if (direction === MOVE_DIRECTION.MOVE_UP) {
          newData.unshift(...movingItems);
        } else {
          newData.push(...movingItems);
        }
      } else {
        const field: keyof PriceSheetRow = level === 1 ? 'groupId' : 'subGroupId';

        const currentGroupIndex =
          node.parent?.childrenAfterGroup?.findIndex(({ key }) => key === node.key) ?? -1;

        const neighboringGroup = node.parent?.childrenAfterGroup?.at(
          currentGroupIndex + (direction === MOVE_DIRECTION.MOVE_UP ? -1 : 1),
        );

        const groupId = neighboringGroup?.allLeafChildren?.at(0)?.data?.[field];
        const canMoveUp = currentGroupIndex > 0 && direction === MOVE_DIRECTION.MOVE_UP;
        const canMoveDown =
          currentGroupIndex < (node.parent?.childrenAfterGroup?.length ?? 0) &&
          direction === MOVE_DIRECTION.MOVE_DOWN;

        if (groupId && (canMoveUp || canMoveDown)) {
          const indexOfInsertion = newData[
            direction === MOVE_DIRECTION.MOVE_UP ? 'findIndex' : 'findLastIndex'
          ]((data) => data?.[field] === groupId);
          if (indexOfInsertion === -1) {
            throw new Error(
              `Field: ${field} with id: ${groupId} not found, cannot insert current ${field}.`,
            );
          }

          newData.splice(
            indexOfInsertion + Number(direction === MOVE_DIRECTION.MOVE_DOWN), // +1 if moving down
            0,
            ...movingItems,
          );
        } else {
          newData = priceSheetGroups ?? [];
        }
      }

      if (level < 2) {
        const patchData = newData.reduce((acc, { groupId, groupName }) => {
          if (!acc.has(groupId!)) {
            acc.set(groupId!, {
              id: groupId,
              name: groupName,
              rowIndex: acc.size,
            });
          }
          return acc;
        }, new Map<string, dice.PatchPriceSheetGroupDTO>());

        await updatePriceSheetGroup([...patchData.values()]);
      } else {
        const sortedSubGroups = getSubGroupsFromNestedStructure(createNestedStructure(newData));
        await updatePriceSheetSubGroup(sortedSubGroups);
      }
    },
    [priceSheetGroups, updatePriceSheetGroup, updatePriceSheetSubGroup],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonHandlerProps>({
    onConfirm: handleDelete,
  });

  const columnDefs = useColumns({ addNewRow, apiParams, handleDeleteModal, moveRow, addNewGroup });

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: priceSheetGroups,
    onGridReady: () => tableRef?.current?.api?.setGridOption('resetRowDataOnUpdate', true),
  });

  const getRowId = useCallback(({ data }: GetRowIdParams) => data?.entryId, []);

  return {
    ...apiParams,
    agGridProps,
    autoGroupColumnDef,
    columnDefs,
    defaultColDef,
    getRowId,
    handleDelete,
    isLoading,
    masterConfigId,
    modal,
    onChange,
    tableRef,
  };
}
