import type { dice } from '@org/query';

export const mergeOtherAllocations = (allocations: dice.AllocationBasisConfigEntryDTO[]) => {
  const mergedAllocations: dice.AllocationBasisConfigEntryDTO = {
    costTypeEntry: {
      allocationBasisAccountIds: [],
      allocationBasisCalculatedCost: [],
      allocationBasisCostCenters: [],
      allocationBasisCostTypeNames: [],
    },
    revenueTypeEntry: {
      allocationBasisAccountIds: [],
      allocationBasisCalculatedCost: [],
      allocationBasisCostCenters: [],
      allocationBasisCostTypeNames: [],
    },
  };

  for (const allocation of allocations) {
    const { costTypeEntry, revenueTypeEntry } = allocation;

    if (costTypeEntry) {
      (mergedAllocations.costTypeEntry?.allocationBasisAccountIds ?? []).push(
        ...(costTypeEntry.allocationBasisAccountIds ?? []),
      );

      (mergedAllocations.costTypeEntry?.allocationBasisCalculatedCost ?? []).push(
        ...(costTypeEntry.allocationBasisCalculatedCost ?? []),
      );

      (mergedAllocations.costTypeEntry?.allocationBasisCostCenters ?? []).push(
        ...(costTypeEntry.allocationBasisCostCenters ?? []),
      );

      (mergedAllocations.costTypeEntry?.allocationBasisCostTypeNames ?? []).push(
        ...(costTypeEntry.allocationBasisCostTypeNames ?? []),
      );
    }

    if (revenueTypeEntry) {
      (mergedAllocations.revenueTypeEntry?.allocationBasisAccountIds ?? []).push(
        ...(revenueTypeEntry.allocationBasisAccountIds ?? []),
      );

      (mergedAllocations.revenueTypeEntry?.allocationBasisCalculatedCost ?? []).push(
        ...(revenueTypeEntry.allocationBasisCalculatedCost ?? []),
      );

      (mergedAllocations.revenueTypeEntry?.allocationBasisCostCenters ?? []).push(
        ...(revenueTypeEntry.allocationBasisCostCenters ?? []),
      );

      (mergedAllocations.revenueTypeEntry?.allocationBasisCostTypeNames ?? []).push(
        ...(revenueTypeEntry.allocationBasisCostTypeNames ?? []),
      );
    }
  }

  return mergedAllocations;
};
