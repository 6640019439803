import type { CellClassParams, ColDef, ColGroupDef, IAggFunc } from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams, dice, griddy } from '@org/query';
import { useCustomLabels } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { getAlignmentIndentClass, getCellClasses, getExcelHighlightedClass } from '@org/utils';

import type { BabRow, GroupedCostCenter } from '../../ReportTable/types';
import { numericColumnProps } from '../../ReportTable/defaults';
import { renderColumnGroup } from '../../ReportTable/renderColumnGroup';
import { accountIdValue } from '../helpers';
import { getCostTypeAccountLabel } from '../helpers/getCostTypeAccountLabel';

export interface UseColumnsProps {
  showModifications?: boolean;
  masterConfiguration?: dice.MasterConfiguration;
  accountsPlanMap: griddy.GetAllAccountIdToAccountDescriptionMapResponse | undefined;
  costAggFunc: IAggFunc;
  indirectCCHeaders: GroupedCostCenter[];
  primaryCCHeaders: GroupedCostCenter[];
  modifiedCostCenters: BabRow['modifiedCostCenters'];
  manuallyCorrectedCostCenters: BabRow['manuallyCorrectedCostCenters'];
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
}

const NON_CLICKABLE_ROWS = new Set([
  'IMPUTED_INTEREST',
  'IMPUTED_DEPRECIATION_APC',
  'TOTAL_COSTS',
  'TOTAL_COSTS_AFTER_COST_ALLOCATION',
  'TOTAL_REVENUE',
  'TOTAL_REVENUE_AFTER_REVENUE_ALLOCATION',
]);

const getCursorPointerClass = (params: CellClassParams): string => {
  const { node, colDef } = params;
  const { level: nodeLevel, data, key } = node;
  const clickableRowName = !NON_CLICKABLE_ROWS.has(data?.rowName);
  return nodeLevel === 1 && key && colDef.colId !== 'group' && clickableRowName
    ? 'cursor-pointer'
    : '';
};

const rowGroupCellClass = (params: CellClassParams) => [
  ...getCellClasses()(params),
  getExcelHighlightedClass(params),
  getAlignmentIndentClass(params),
  getCursorPointerClass(params),
];

const accountingNumberCellClass = (params: CellClassParams) => [
  ...getCellClasses()(params),
  getCursorPointerClass(params),
  'accounting-number',
];

export const useColumns = (props: UseColumnsProps) => {
  const { t } = useTranslation();
  const {
    showModifications = false,
    accountsPlanMap,
    costAggFunc,
    indirectCCHeaders,
    primaryCCHeaders,
    modifiedCostCenters,
    manuallyCorrectedCostCenters,
    apiParams,
  } = props;

  const getCustomLabel = useCustomLabels(apiParams);

  return useMemo<(ColDef | ColGroupDef)[]>(
    () =>
      [
        {
          children: [
            {
              cellClass: rowGroupCellClass,
              cellRenderer: 'agGroupCellRenderer',
              colId: 'group',
              flex: 1,
              headerClass: 'bg-white',
              headerName: '',
              minWidth: 300,
              pinned: 'left',
              showRowGroup: true,
              valueGetter: (params) => {
                if (params?.node?.level === 1) {
                  return getCustomLabel(
                    `bab.${params.data.rowName ?? ''}`,
                    t(`main:reportPanels.babReport.tableRows.${params.data.accountIdRaw}`),
                  );
                }

                return accountIdValue(params, accountsPlanMap);
              },
            },
            {
              colId: 'accountType',
              field: 'accountType',
              hide: true,
              rowGroup: true,
            },
            {
              colId: 'groupName',
              field: 'groupName',
              hide: true,
              rowGroup: true,
            },
            {
              colId: 'costTypeName',
              field: 'costTypeName',
              headerClass: 'bg-white',
              hide: true,
              pinned: 'left',
              rowGroup: true,
              valueFormatter: getCostTypeAccountLabel,
            },
            {
              aggFunc: costAggFunc,
              cellClass: accountingNumberCellClass,
              colId: 'expenses_revenues_before_correction',
              field: 'expenses_revenues_before_correction',
              headerClass: 'white-background',
              headerName: t('main:reportPanels.babReport.tableColumns.expensesRevenues1'),
              pinned: 'left',
              ...numericColumnProps,
            },
            ...(showModifications
              ? ([
                  {
                    cellClass: getCellClasses(),
                    cellRenderer: 'modifiedTransactionCellRenderer',
                    field: 'modifications',
                    headerClass: 'bg-white text-black',
                    headerName: t('main:reportPanels.babReport.tableColumns.manualCorrection'),
                    minWidth: 60,
                    pinned: 'left',
                    width: 60,
                  },
                ] as const)
              : []),
            {
              aggFunc: costAggFunc,
              cellClass: accountingNumberCellClass,
              colId: 'correction',
              field: 'correction',
              headerClass: 'white-background',
              headerName: t('main:reportPanels.babReport.tableColumns.correction'),
              pinned: 'left',
              ...numericColumnProps,
            },
            {
              aggFunc: costAggFunc,
              cellClass: accountingNumberCellClass,
              colId: 'expenses_revenues_after_correction',
              field: 'expenses_revenues_after_correction',
              headerClass: 'bg-white',
              headerName: t(
                'main:reportPanels.babReport.tableColumns.expensesRevenuesAfterCorrection1',
              ),
              pinned: 'left',
              ...numericColumnProps,
            },
          ],
          headerName: '',
          pinned: 'left',
        },
        ...([
          indirectCCHeaders.length > 0 && {
            children:
              renderColumnGroup({
                aggFunc: costAggFunc,
                cellClass: getCellClasses(),
                costCentersGroups: indirectCCHeaders,
                manuallyCorrectedCostCenters,
                modifiedCostCenters,
                showModifications,
                type: 'INDIRECT_COST_CENTER',
              }) ?? [],
            headerClass: 'bg-white',
            headerName: t('main:reportPanels.babReport.tableColumns.supportCostCenter'),
          },
          primaryCCHeaders.length > 0 && {
            children:
              renderColumnGroup({
                aggFunc: costAggFunc,
                cellClass: getCellClasses(),
                costCentersGroups: primaryCCHeaders,
                manuallyCorrectedCostCenters,
                modifiedCostCenters,
                showModifications,
                type: 'PRIMARY_COST_CENTER',
              }) ?? [],
            headerClass: 'bg-white border-left-2px',
            headerName: t('main:reportPanels.babReport.tableColumns.mainCostCenter'),
          },
        ].filter(Boolean) as ColGroupDef[]),
      ] satisfies (ColDef | ColGroupDef)[],
    [
      accountsPlanMap,
      costAggFunc,
      getCustomLabel,
      indirectCCHeaders,
      manuallyCorrectedCostCenters,
      modifiedCostCenters,
      primaryCCHeaders,
      showModifications,
      t,
    ],
  );
};
