import type { dice } from '../../../../query/src';

export type SetDefaultWorkingCapitalRateInMasterConfiguration = (
  masterConfiguration: dice.MasterConfiguration,
  newWorkingCapitalRate: number,
) => dice.MasterConfiguration;

export const setDefaultWorkingCapitalRateInMasterConfiguration: SetDefaultWorkingCapitalRateInMasterConfiguration =
  (masterConfiguration, newWorkingCapitalRate) => ({
    ...masterConfiguration,
    imputedWorkingCapitalConfig: {
      ...masterConfiguration.imputedWorkingCapitalConfig,
      defaultWorkingCapitalRate: newWorkingCapitalRate,
    },
  });
