import type { ICellRendererParams } from 'ag-grid-community';
import type { FC } from 'react';
import { memo } from 'react';

import type { ButtonCellRendererProps } from '../ButtonCellRenderer';
import { ButtonCellRenderer } from '../ButtonCellRenderer';

export type MultipleButtonRendererProps = {
  buttonParams: ButtonCellRendererProps[];
  className?: string;
} & ICellRendererParams;

export const MultipleButtonsMemo: FC<MultipleButtonRendererProps> = (props) => {
  const { className, ...restProps } = props;
  return (
    <div className={className}>
      {props?.buttonParams?.map((prop, index) => (
        <ButtonCellRenderer
          {...props}
          {...prop}
          data={props.data}
          gridProps={restProps}
          key={index}
        />
      ))}
    </div>
  );
};

export const MultipleButtonsRenderer = memo(MultipleButtonsMemo);
