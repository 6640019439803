import { useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import type { APIParams, dice } from '@org/query';
import { useReinitializeForm } from '@org/hooks';
import { aggregated } from '@org/query';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId'>;
  updateAndReplacePlannedCostRevenueDirectEntry: (
    updatedEntry: dice.CostUnitAllocationBasisConfigEntryDTO,
  ) => Promise<void>;
  isOpenTechnicalModal: boolean;
  onOpenChangeTechnicalModal: (value: boolean) => void;
  selectedPlannedAccountEntry?: dice.PlannedAccountEntryDTO;
  selectedPlannedDirectEntryId?: string;
}

export type ViewProps = ReturnType<typeof useController>;

export const useController = (props: ControllerProps) => {
  const {
    apiParams,
    updateAndReplacePlannedCostRevenueDirectEntry,
    isOpenTechnicalModal,
    onOpenChangeTechnicalModal,
    selectedPlannedAccountEntry,
    selectedPlannedDirectEntryId,
  } = props;

  const { getPlannedDirectEntry, preselectedTechnicalDataOptions } =
    aggregated.usePlannedCostRevenue(apiParams);

  const selectedPlannedDirectEntry = getPlannedDirectEntry({
    plannedAccountEntryId: selectedPlannedAccountEntry?.id ?? '',
    plannedCostTypeAccountEntryId: selectedPlannedDirectEntryId ?? '',
  });

  const technicalData = selectedPlannedDirectEntry?.detail?.selectedTechnicalData ?? '';
  const technicalDataPrice = selectedPlannedDirectEntry?.detail?.price ?? '';

  const defaultValues = useMemo(
    () => ({
      technicalData,
      technicalDataPrice,
    }),
    [technicalData, technicalDataPrice],
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
  });

  useReinitializeForm({ defaultValues, reset });

  const handleOnSubmit = useCallback(
    async (data: { technicalData: string; technicalDataPrice: string | number }) => {
      const updatedDirectEntry = {
        ...selectedPlannedDirectEntry,
        detail: {
          ...selectedPlannedDirectEntry?.detail,
          selectedTechnicalData: data.technicalData,
          price: data.technicalDataPrice,
        },
      };

      await updateAndReplacePlannedCostRevenueDirectEntry(updatedDirectEntry);
    },
    [selectedPlannedDirectEntry, updateAndReplacePlannedCostRevenueDirectEntry],
  );

  return {
    control,
    options: preselectedTechnicalDataOptions,
    handleSubmit: handleSubmit(handleOnSubmit),
    isOpenTechnicalModal,
    onOpenChangeTechnicalModal,
  };
};
