import type { CellClassParams, ColDef, ColGroupDef, IAggFunc } from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams } from '@org/query';
import { useCustomLabels } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { generateColumnGroups, getAlignmentIndentClass } from '@org/utils';

import { getCellClasses } from './helpers';

type UseColumnsProps = APIParams<
  'masterConfigurationId' | 'yearId' | 'publicFacilityId' | 'clientId' | 'type'
>;

const dateCellClass = (params: CellClassParams) => [...getCellClasses(params), 'string-type'];

const accountingNumberCellClass = (params: CellClassParams) => [
  ...getCellClasses(params),
  'accounting-number',
];

const aggFunc: IAggFunc = ({ rowNode, values }) => {
  const sum = values.reduce((acc, item) => {
    if (typeof item === 'number') {
      return item + acc;
    }
    return acc;
  }, 0);

  if (rowNode?.level === 1 && values.length > 0) {
    return sum / values.length;
  }
  return sum;
};

export const useColumns = (apiParams: UseColumnsProps) => {
  const { t } = useTranslation();
  const { report } = aggregated.useReport(apiParams);

  const {
    indirectCostCentersGrouped: indirectCCHeaders,
    primaryCostCentersGrouped: primaryCCHeaders,
  } = report ?? {};

  const getCustomLabel = useCustomLabels(apiParams);

  return useMemo(() => {
    const baseColumns: (ColDef | ColGroupDef)[] = [
      {
        children: [
          {
            cellClass: (params) => [...getCellClasses(params), getAlignmentIndentClass(params)],
            cellRenderer: 'agGroupCellRenderer',
            colId: 'rowDescription',
            headerClass: 'white-background',
            headerName: '',
            minWidth: 300,
            pinned: 'left',
            showRowGroup: true,
            type: 'text',
            valueGetter: ({ node }) => {
              const rowKey = node?.data?.description;
              return getCustomLabel(
                `interest.${rowKey}`,
                t(`main:reportPanels.interestTable.tableRows.${rowKey}`),
              );
            },
          },
          {
            colId: 'group',
            field: 'group',
            hide: true,
            rowGroup: true,
            valueGetter: ({ data }) =>
              data?.group &&
              getCustomLabel(
                `interest.${data.group}`,
                t(`main:reportPanels.interestTable.tableRows.${data.group}`),
              ),
          },
          {
            colId: 'subGroup',
            field: 'subGroup',
            hide: true,
            rowGroup: true,
            valueGetter: ({ data }) =>
              data?.subGroup &&
              getCustomLabel(
                `interest.${data.subGroup}`,
                t(`main:reportPanels.interestTable.tableRows.${data.subGroup}`, {
                  defaultValue: data.subGroup,
                }),
              ),
          },
          {
            aggFunc: ({ values, rowNode }) => {
              if (rowNode?.level === 1) {
                if (values.length === 1) {
                  return values.at(0);
                }
                const [value] = values;
                return `ø ${(value as string)?.split('.')?.at(2) ?? value}`;
              }
            },
            cellClass: dateCellClass,
            colId: 'reportingDate',
            field: 'reportingDate',
            headerClass: 'white-background',
            headerName: t('main:reportPanels.interestTable.tableColumns.date'),
            pinned: 'left',
          },
          {
            aggFunc,
            cellClass: accountingNumberCellClass,
            colId: 'imputedInterestAmount',
            field: 'imputedInterestAmount',
            headerClass: 'white-background',
            headerName: t('main:reportPanels.interestTable.tableColumns.valueInEuro'),
            pinned: 'left',
            type: 'report-price',
          },
        ],
        headerName: '',
        pinned: 'left',
      },
    ];
    if (indirectCCHeaders) {
      baseColumns.push({
        children: generateColumnGroups({
          aggFunc,
          cellClass: getCellClasses,
          costCentersGroups: indirectCCHeaders,
          t,
          type: 'INDIRECT_COST_CENTER',
        }),
        headerClass: 'white-background',
        headerName: t('common:supportCostCenter'),
        pinned: 'left',
      });
    }
    if (primaryCCHeaders) {
      baseColumns.push({
        children: generateColumnGroups({
          aggFunc,
          cellClass: getCellClasses,
          costCentersGroups: primaryCCHeaders,
          isSeparated: true,
          t,
          type: 'PRIMARY_COST_CENTER',
        }),
        headerClass: 'white-background border-left-2px',
        headerName: t('common:mainCostCenter'),
        pinned: 'left',
      });
    }
    return baseColumns;
  }, [getCustomLabel, indirectCCHeaders, primaryCCHeaders, t]);
};
