import type { CellClassParams } from 'ag-grid-community';

import classnames from 'classnames';

import { findRoot } from '../findRoot';

const getGroupClasses = (params: CellClassParams) =>
  params.node.level === 0
    ? classnames('bg-highlighted', 'font-bold')
    : classnames('bg-gray-200', 'font-bold');

export const getTreeGroupClasses = (params: CellClassParams) =>
  params.node.level === 0 ? classnames('bg-highlighted', 'font-bold') : classnames('bg-white');

const getBackgroundClass = ({ node }: CellClassParams, rootLevel = 1): string => {
  const { level: nodeLevel } = node;
  if (nodeLevel >= rootLevel) {
    const { childIndex: rootChildNodeIndex } = findRoot(node, rootLevel);
    return rootChildNodeIndex % 2 ? 'gray-lightest-background' : 'white-background';
  }
  return '';
};

export const getCellClassesForGroups = (params: CellClassParams) => {
  if (params.node.group) {
    return [getGroupClasses(params), getBackgroundClass(params)];
  }
  return ['manage-cost-center', getBackgroundClass(params)];
};

export const getCellClassesForGroupsWithActions = (params: CellClassParams) => {
  const groupClasses = params.node.group
    ? [getGroupClasses(params), getBackgroundClass(params)]
    : [getBackgroundClass(params)];

  return classnames(
    'align-to-center-and-right',
    {
      'manage-cost-center': !params.node.group,
    },
    ...groupClasses,
  );
};

export const getCellClassesForGroupsCustom = (
  params: CellClassParams,
  additionalClasses: string[] = [],
) => {
  const groupClasses = params.node.group
    ? [getGroupClasses(params), getBackgroundClass(params)]
    : [getBackgroundClass(params)];

  return classnames(...groupClasses, ...additionalClasses);
};
