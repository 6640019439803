// @ts-check

let BASE_URL = '';
let IAM_HOST = '';
let CLIENT_ID = '';
let CLIENT_SECRET = process.env.PIAM_CLIENT_SCRT || 'Ufym8WulsBBrF85eGtHw';
let ENCODER_KEY = '';
let ORG_ID = '';
let DTMS_URL = '';

switch (process.env.REACT_APP_ENV) {
  case 'stg': {
    BASE_URL = process.env.REACT_APP_DTMS_APP_URL_STAGE;
    IAM_HOST = process.env.REACT_APP_IAM_HOST_NP;
    CLIENT_ID = process.env.REACT_APP_CLIENT_ID_STAGE;
    ENCODER_KEY = process.env.REACT_APP_encoder_key_stage;
    ORG_ID = process.env.REACT_APP_ORG_ID_STAGE;
    DTMS_URL = process.env.REACT_APP_REDIRECT_URI_STAGE;

    break;
  }
  case 'prod': {
    BASE_URL = process.env.REACT_APP_DTMS_APP_URL_PROD;
    IAM_HOST = process.env.REACT_APP_IAM_HOST;
    CLIENT_ID = process.env.REACT_APP_CLIENT_ID_PROD;
    ENCODER_KEY = process.env.REACT_APP_encoder_key_prod;
    ORG_ID = process.env.REACT_APP_ORG_ID_PROD;
    DTMS_URL = process.env.REACT_APP_REDIRECT_URI_PROD;

    break;
  }
  default: {
    BASE_URL = process.env.REACT_APP_DTMS_APP_URL_DEV_R2;
    IAM_HOST = process.env.REACT_APP_IAM_HOST_NP;
    CLIENT_ID = process.env.REACT_APP_CLIENT_ID_DEV;
    ENCODER_KEY = process.env.REACT_APP_encoder_key_dev_r2;
    ORG_ID = process.env.REACT_APP_ORG_ID_DEV;
    DTMS_URL = process.env.REACT_APP_REDIRECT_URI_DEV_R2;

    break;
  }
}

const getRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export function _getBaseUrl() {
  return BASE_URL;
}

export function _getRedirectUri() {
  if (process.env.REACT_APP_ENV === 'local') {
    return 'http://localhost:4000/';
  } else {
    return BASE_URL;
  }
}

export function _getIAMHost() {
  return IAM_HOST;
}

export function _getClientID() {
  return CLIENT_ID;
}

export function _getSignOutUrl() {
  if (process.env.REACT_APP_ENV === 'local') {
    return 'http://localhost:4000/';
  } else {
    return BASE_URL;
  }
}

export function _getLoginUrl() {
  const iamHost = _getIAMHost();
  const redirectUri = _getRedirectUri();
  const clientid = _getClientID();
  const randomString = getRandomString(10);

  return `https://${iamHost}/openam/oauth2/realms/venturessso/authorize?response_type=code&client_id=${clientid}&redirect_uri=${redirectUri}&scope=ventures%20openid&state=${randomString}`;
}

export function _getLoggedInUserDetails() {
  return JSON.parse(sessionStorage.getItem('UserDts'));
}

export function _getLogoutUrl() {
  const iamHost = _getIAMHost();
  const signout = _getSignOutUrl();
  return `https://${iamHost}/openam/XUI/?realm=%2Fventuressso#logout/&goto=${signout}`;
}

export function _getAuthToken() {
  let pIAM_authorization_token = sessionStorage.getItem('pIAM_authorization_token');
  return pIAM_authorization_token ?? '';
}

export function _getIdToken() {
  let pIAM_id_token = sessionStorage.getItem('pIAM_id_token');
  return pIAM_id_token ?? '';
}

export function _getAuthHeaders() {
  const headers = {
    Accept: 'application/json',
    Authorization: _getAuthToken(),
    'Content-Type': 'application/json',
    param: _getIdToken(),
  };
  return headers;
}

export function _getClientSecret() {
  return CLIENT_SECRET;
}

export function _getEncoderKey() {
  return ENCODER_KEY;
}

export function _getOrgId() {
  return ORG_ID || 'a3da134f-d2a6-45cc-8bf3-a5623fab7f50';
}

export function _getPiamConfig() {
  return {
    emailLanguage: 'en',
    role: 'MEMBER',
    state: '123456',
  };
}

export function _getDTMSLink() {
  return DTMS_URL || 'https://portal-dev-r2.np.dealstechmanagedservices.pwc.de/';
}
