import classNames from 'classnames';

import type { griddy } from '@org/query';
import { Combobox, ComboboxItem } from '@org/design';
import { useTranslation } from '@org/locales';
import { NumberInput } from '@org/ui';

const QUANTITY_UNITS = ['m²', 'm³'];
const RATE_UNITS = ['€/m²', '€/m³'];

interface ValueLabelRowProps {
  field: keyof griddy.RevenueDefinition;
  unitField?: keyof griddy.RevenueDefinition;
  readOnly?: boolean;
  warningText?: string;
  updateValue: (
    unitField: keyof griddy.RevenueDefinition,
    val: number | string | boolean | null,
  ) => void;
  revenueDefinition: griddy.RevenueDefinition;
}

export const ValueLabelRow = ({
  field,
  unitField,
  readOnly,
  warningText,
  updateValue,
  revenueDefinition,
}: ValueLabelRowProps) => {
  const { t } = useTranslation();

  const fieldValue = revenueDefinition?.[field];

  return (
    <div className="w-100 mt-4 flex">
      <div className="label w-full">
        {t(`main:manageMenu.manageCostUnits.revenueDefinition.${field}`)}
        <span className="font-weight-bold text-red-default"> {warningText}</span>
      </div>

      {typeof fieldValue === 'number' && (
        <div className="value flex w-full">
          <NumberInput
            className={classNames({
              'mr-10': unitField,
            })}
            onChange={(value?: number) => !readOnly && value && updateValue(field, value)}
            value={fieldValue}
          />
          {(unitField === 'feeRelevantQuantityUnit' ||
            unitField === 'precalculatedFeeRateUnit' ||
            unitField === 'feeRateUnit') && (
            <Combobox
              onSelectionChange={(value) => {
                updateValue(unitField, value);
              }}
              selectedKey={revenueDefinition?.[unitField]}
              size="sm"
            >
              {(field === 'feeRelevantQuantity' ? QUANTITY_UNITS : RATE_UNITS).map((unit) => (
                <ComboboxItem key={unit}>{unit}</ComboboxItem>
              ))}
            </Combobox>
          )}
        </div>
      )}
    </div>
  );
};
