import type { CellClickedEvent, ColDef, ExcelExportParams, GetRowIdFunc } from 'ag-grid-community';
import type { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useMemo } from 'react';

import type { APIParams, rave } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { t } from '@org/locales';
import { ExcelReportType } from '@org/models';
import { aggregated, griddy } from '@org/query';
import { getCellClasses, getDefaultExcelExportParams, replaceSpecificRowLabels } from '@org/utils';

import type { BabReportEntry } from '../../ReportTable/types';
import { babRowAggregate, filterCostCenters, groupCostCenters } from '../../ReportTable/helpers';
import { useBABData } from './useBABData';
import { useColumns } from './useColumns';

export interface ModifiedBabReport extends rave.BabReport {
  TOTAL_COSTS_AFTER_COST_ALLOCATION: BabReportEntry;
  TOTAL_REVENUE_AFTER_REVENUE_ALLOCATION: BabReportEntry;
}

export interface ReportsResponse extends aggregated.ExtendedReportObjectsResponse {
  babReport: ModifiedBabReport;
}

export interface ControllerProps extends Omit<AgGridReactProps, 'rowData'> {
  showModifications?: boolean;
  apiResponse?: ReportsResponse;
  tableGridRef: RefObject<AgGridReact>;
  getReportExcelFileName: (type: ExcelReportType) => string;
  isCCIdsVisible: boolean;
  handleOpenModal: (event: CellClickedEvent, colName: string) => void;
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
}

export type ControllerReturnType = ReturnType<typeof useController>;

const getRowId: GetRowIdFunc = ({ data }) => String(data.rowIndex);

export function useController({
  showModifications = false,
  apiResponse,
  tableGridRef,
  apiParams,
  getReportExcelFileName,
  handleOpenModal,
  ...restProps
}: ControllerProps) {
  const { yearId } = apiParams;
  const { data: accountsPlanMap } = griddy.useGetAllAccountIdToAccountDescriptionMap({
    queryParams: {
      fileType: 'ACCOUNT_PLAN',
      yearId,
    },
  });
  const { masterConfiguration } = aggregated.useMasterConfiguration(apiParams);

  const costCenters = apiResponse?.costCenters ?? [];

  const indirectCCHeaders = groupCostCenters(
    filterCostCenters(costCenters, 'INDIRECT_COST_CENTER'),
  );
  const primaryCCHeaders = groupCostCenters(filterCostCenters(costCenters, 'PRIMARY_COST_CENTER'));

  const data = useBABData(apiResponse, masterConfiguration);
  const { modifiedCostCenters, manuallyCorrectedCostCenters, rowData } = data;

  const agGridProps = useAgGridData({
    agGridRef: tableGridRef,
    data: rowData,
  });

  const defaultColDef: ColDef = useMemo(
    () => ({
      aggFunc: 'sum',
      cellClass: getCellClasses(),
      cellRendererParams: {
        suppressCount: true,
      },
      flex: 1,
      minWidth: 100,
      onCellClicked: (event) => {
        const { field } = event.colDef;
        handleOpenModal(event, field ?? '');
      },
      resizable: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      valueFormatter: ({ value }) => replaceSpecificRowLabels(t)(value),
      width: 100,
    }),
    [handleOpenModal],
  );

  const costAggFunc = babRowAggregate(
    apiResponse?.babReport?.TOTAL_COSTS_AFTER_COST_ALLOCATION,
    apiResponse?.babReport?.TOTAL_REVENUE_AFTER_REVENUE_ALLOCATION,
    apiResponse?.babReport?.ctaExpenseEntries,
    apiResponse?.babReport?.ctaRevenueEntries,
    apiResponse?.babReport?.imputedWicapEntries,
  );

  const defaultExcelExportParams = useMemo<ExcelExportParams>(
    () =>
      getDefaultExcelExportParams(getReportExcelFileName(ExcelReportType.BAB), ExcelReportType.BAB),
    [getReportExcelFileName],
  );

  const columnsDefs = useColumns({
    accountsPlanMap,
    costAggFunc,
    indirectCCHeaders,
    manuallyCorrectedCostCenters,
    masterConfiguration,
    modifiedCostCenters,
    primaryCCHeaders,
    showModifications,
    apiParams,
  });

  return {
    ...restProps,
    agGridProps,
    columnsDefs,
    defaultColDef,
    defaultExcelExportParams,
    getRowId,
    tableGridRef,
  };
}
