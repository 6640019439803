/* eslint-disable unicorn/prefer-module */
import 'reflect-metadata';

import { NextUIProvider } from '@nextui-org/react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';

import { DesignProvider } from '@org/design';
import { QueryProvider } from '@org/query';
import { showNotification } from '@org/ui';

import { FileStateProvider } from './context/FileState';
import { LayoutStateProvider } from './context/LayoutState';
import { UIStateProvider } from './context/UIState';
import { UserStateProvider } from './context/UserState';
import { base, GlobalStyle } from './globalStyles';
import { fetcher } from './utils/apiCalls';

import './index.scss';

import { BrowserRouter as Router } from 'react-router-dom';

import Auth from './Auth';
import config from './config';
import { setLicenseKey } from './utils/ag-grid/setLicenseKey';

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', `${config.APP_NAME}:*`);
}
setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);

createRoot(document.querySelector('#root')).render(
  <QueryProvider showNotification={showNotification}>
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
      }}
    >
      <UserStateProvider>
        <FileStateProvider>
          <LayoutStateProvider>
            <UIStateProvider>
              <DesignProvider>
                <NextUIProvider>
                  <ThemeProvider theme={base}>
                    <Router>
                      <Auth />
                    </Router>
                  </ThemeProvider>
                  <GlobalStyle />
                </NextUIProvider>
              </DesignProvider>
            </UIStateProvider>
          </LayoutStateProvider>
        </FileStateProvider>
      </UserStateProvider>
    </SWRConfig>
  </QueryProvider>,
);

if (module.hot) {
  module.hot.accept();
}
