import type {
  CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback } from 'react';

import { useAgGridData } from '@org/hooks';
import { useDeleteRowConfirmModal } from '@org/ui';

import type { AllocationBasisConfigEntryUnionType } from '../types';
import { useColumns } from './useColumns';

export interface ControllerProps {
  rows: AllocationBasisConfigEntryUnionType[];
  gridRef: RefObject<AgGridReact>;
  handleSelectAllocation: (event: SelectionChangedEvent) => void;
  handleOnChange: (event: CellValueChangedEvent) => void;
  handleRemoveAllocation: (id: string) => void;
  handleAddNewAllocation: () => Promise<void>;
}

const defaultColDef = {
  cellClass: ({ node: { level } }) => (level === 0 ? 'bg-highlighted font-bold' : 'text-right'),
  checkboxSelection: false,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
} satisfies ColDef;

export const useController = ({
  rows,
  gridRef,
  handleSelectAllocation,
  handleOnChange,
  handleRemoveAllocation,
  handleAddNewAllocation,
}: ControllerProps) => {
  const { modal, openModal } = useDeleteRowConfirmModal<string>({
    onConfirm: handleRemoveAllocation,
  });

  const { onGridReady } = useAgGridData({ agGridRef: gridRef, data: rows });

  const columnDefs = useColumns({ handleAddNewAllocation, openDeleteModal: openModal });

  const onFirstSelectFirst = useCallback(
    (params: FirstDataRenderedEvent) => params.api.getDisplayedRowAtIndex(0)!.setSelected(true),
    [],
  );

  return {
    columnDefs,
    defaultColDef,
    gridRef,
    handleOnChange,
    handleSelectAllocation,
    modal,
    onFirstSelectFirst,
    onGridReady,
  };
};

export type ControllerType = ReturnType<typeof useController>;
