import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { griddy } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import {
  costCenterTypes,
  ENTRY,
  getCellClassesForGroups,
  getCellClassesForGroupsWithActions,
  GROUP,
} from '@org/utils';

import type { CostCenterMappings } from './types';
import { InfoMappingRenderer } from './components';
import { MOVE_DIRECTION } from './constants';
import { isGroupEditable } from './helpers';

interface UseColumnsProps {
  yearId: string;
  addNewRow: (props: ButtonCellRendererProps) => Promise<void> | void;
  moveRow: (props: ButtonCellRendererProps, direction: string) => Promise<void> | void;
  handleDeleteModal: (props: ButtonHandlerProps) => Promise<void> | void;
}

export const useColumns = ({ yearId, addNewRow, moveRow, handleDeleteModal }: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          children: [
            {
              colId: 'longName',
              editable: true,
              field: 'longName',
              flex: 1,
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.longName'),
              hide: true,
            },
            {
              cellClass: getCellClassesForGroups,
              colId: 'costCenterTypeGroup',
              editable: false,
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.type'),
              maxWidth: 150,
              valueGetter: (params) =>
                params.data
                  ? t(costCenterTypes[params.data.costCenterType!]?.label)
                  : t('main:manageMenu.manageCostCenters.tableColumns.group'),
            },
            {
              cellClass: getCellClassesForGroups,
              colId: 'rank',
              editable: false,
              field: 'rank',
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.rank'),
              maxWidth: 100,
              sort: 'asc',
              type: 'integer',
            },
            {
              colId: 'costCenterType',
              editable: false,
              field: 'costCenterType',
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.costCenterType'),
              hide: true,
              rowGroup: true,
              valueFormatter: ({ value }) =>
                t(costCenterTypes[value as NonNullable<griddy.CostCenter['costCenterType']>].label),
            },
            {
              colId: 'mappingObject',
              editable: false,
              field: 'mappingObject',
              flex: 2,
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.costCenterList'),
              hide: true,
              valueFormatter: () => '',
            },
            {
              cellStyle: { verticalAlign: 'top' },
              colId: 'group',
              editable: true,
              field: 'group',
              headerClass: 'border-right-1px',
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.group'),
              hide: true,
              rowGroup: true,
            },
          ],
          groupId: 'costCenter',
          headerClass: 'border-right-1px',
          headerName: '',
          pinned: 'left',
          resizable: false,
        },
        {
          children: [
            {
              autoHeight: true,
              cellClass: getCellClassesForGroups,
              cellRenderer: ({ data }: ICellRendererParams) => {
                if (data?.mappingObject) {
                  const { costCenterIgnoreMappings, mappingKeys } = data.mappingObject;

                  return (
                    <InfoMappingRenderer
                      costCenterIgnoreMappings={costCenterIgnoreMappings}
                      isAgGrid
                      mappingKeys={mappingKeys}
                      yearId={yearId}
                    />
                  );
                }
              },
              colId: 'mappingKeys',
              columnGroupShow: 'closed',
              editable: false,
              field: 'mappingKeys',
              flex: 2,
              groupId: 'mappings',
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.mappings'),
              minWidth: 300,
              sortable: false,
            },
            {
              cellClass: getCellClassesForGroupsWithActions,
              cellRendererSelector: (params) => {
                const { node } = params;
                if (node.group) {
                  const icons = [
                    {
                      icon: 'icon-plus-outline',
                      onClick: addNewRow,
                      size: 'iconButton',
                    },
                  ];

                  if (isGroupEditable(node)) {
                    icons.unshift(
                      {
                        icon: 'icon-arrow-up-outline',
                        onClick: (props: ButtonCellRendererProps) =>
                          moveRow(props, MOVE_DIRECTION.MOVE_UP),
                        size: 'iconButton',
                      },
                      {
                        icon: 'icon-arrow-down-outline',
                        onClick: (props: ButtonCellRendererProps) =>
                          moveRow(props, MOVE_DIRECTION.MOVE_DOWN),
                        size: 'iconButton',
                      },
                    );
                    icons.push({
                      icon: 'icon-delete-outline',
                      onClick: (props: ButtonCellRendererProps) =>
                        handleDeleteModal({ ...props, type: GROUP }),

                      size: 'iconButton',
                    });
                  }

                  return {
                    component: 'multipleButtons',
                    params: {
                      buttonParams: icons,
                      className: 'flex',
                    },
                  };
                } else {
                  const deleteIcon = {
                    icon: 'icon-delete-outline',
                    onClick: (props: ButtonCellRendererProps) =>
                      handleDeleteModal({ ...props, type: ENTRY }),
                    size: 'iconButton',
                  };

                  return {
                    component: 'multipleButtons',
                    params: {
                      buttonParams: [deleteIcon],
                      className: 'flex',
                    },
                  };
                }
              },
              colId: 'actions',
              editable: false,
              flex: 1,
              floatingFilterComponent: 'button',
              headerClass: 'ag-right-aligned-header',
              headerName: t('common:actions'),
              maxWidth: 150,
            },
          ],
          groupId: 'infoMappings',
          headerName: t('main:manageMenu.manageCostCenters.tableColumns.infoMappings'),
          openByDefault: true,
        },
      ] satisfies (ColDef<CostCenterMappings> | ColGroupDef<CostCenterMappings>)[],
    [addNewRow, handleDeleteModal, moveRow, t, yearId],
  );
};
