import type { FetcherOptions } from '../../common';
import type { GriddyContext } from './griddyContext';
import { buildFetcher } from '../../common';

const baseUrl = '/griddyApi';

export type GriddyFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = FetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
> &
  GriddyContext['fetcherOptions'];

export const griddyFetch = buildFetcher(baseUrl);

export { type ErrorWrapper } from '../../common';
