import { useCallback } from 'react';

import type { APIParams } from '../../types';
import type { PublicFacilityDTO } from '../dice';
import {
  useDeletePublicFacility,
  useGetPublicFacilitiesByClient,
  useGetPublicFacility,
  usePostPublicFacility,
  usePutPublicFacility,
} from '../dice';

interface UsePublicFacilitiesProps extends APIParams<'clientId'> {
  defaultEnabled?: boolean;
}

export const usePublicFacilities = ({
  clientId,
  publicFacilityId,
  defaultEnabled = true,
}: UsePublicFacilitiesProps) => {
  const { data: publicFacilities, isPending: isPendingPublicFacilities } =
    useGetPublicFacilitiesByClient(
      {
        pathParams: {
          clientId,
        },
      },
      {
        enabled: Boolean(clientId) && Boolean(!publicFacilityId) && defaultEnabled,
      },
    );

  const { data: publicFacility, isPending: isPendingPublicFacility } = useGetPublicFacility(
    {
      pathParams: {
        clientId,
        publicFacilityId: publicFacilityId!,
      },
    },
    {
      enabled: Boolean(clientId) && Boolean(publicFacilityId) && defaultEnabled,
    },
  );

  const { mutateAsync: createPublicFacilityAsync } = usePostPublicFacility({});
  const { mutateAsync: updatePublicFacilityAsync } = usePutPublicFacility({});
  const { mutateAsync: deletePublicFacilityAsync } = useDeletePublicFacility({});

  const createPublicFacility = useCallback(
    async (body: PublicFacilityDTO) =>
      await createPublicFacilityAsync({
        body: {
          clientId,
          ...body,
        },
        pathParams: {
          clientId,
        },
      }),
    [clientId, createPublicFacilityAsync],
  );

  const updatePublicFacility = useCallback(
    async (body: PublicFacilityDTO) =>
      await updatePublicFacilityAsync({
        body: {
          clientId,
          ...body,
        },
        pathParams: {
          clientId,
        },
      }),
    [clientId, updatePublicFacilityAsync],
  );

  const deletePublicFacility = useCallback(
    async (id: string) =>
      await deletePublicFacilityAsync({
        pathParams: {
          clientId,
          publicFacilityId: id,
        },
      }),
    [clientId, deletePublicFacilityAsync],
  );

  return {
    createPublicFacility,
    deletePublicFacility,
    isLoading: isPendingPublicFacilities || isPendingPublicFacility,
    publicFacilities,
    publicFacility,
    updatePublicFacility,
  };
};
