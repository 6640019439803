import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { FILE_CONFIG } from '@org/utils';

import type { APIParams } from '../..';
import type { Asset, AssetGroupDTO, GetAllAssetsQueryParams } from '../dice';
import {
  useCreateAssetGroup,
  useDeleteAll,
  useDeleteAssetGroup,
  useDeleteFile1,
  useDiceContext,
  useFindGroups,
  useGetAllAssets,
  useInsertAssets,
  useUpdateAsset,
  useUpdateAssetGroup,
} from '../dice';

export interface AssetWithGroup extends Asset {
  groupId: string | undefined;
  groupName: string | undefined;
}

export const addGroupToAssets = (
  assets: Asset[],
  assetGroups: AssetGroupDTO[],
): AssetWithGroup[] => {
  if (!Array.isArray(assets)) {
    return [];
  }

  const groupMap = new Map<string | undefined, Pick<AssetWithGroup, 'groupId' | 'groupName'>>();
  assetGroups.forEach((group) => {
    group.assetIds?.forEach((assetId) =>
      groupMap.set(assetId, { groupId: group.id, groupName: group.name }),
    );
  });

  return assets.map((asset) => {
    const groupData = groupMap.get(asset._id);
    return {
      ...asset,
      groupId: groupData?.groupId ?? '',
      groupName: groupData?.groupName ?? '',
    };
  });
};

export interface UseUpdateAssetsParams extends APIParams<'masterConfigurationId' | 'yearId'> {
  assetType?: GetAllAssetsQueryParams['assetType'];
}

export function useUpdateAssets({
  yearId,
  masterConfigurationId,
  assetType = undefined,
}: UseUpdateAssetsParams) {
  const queryParams: GetAllAssetsQueryParams = useMemo(
    () => ({
      assetType,
      fileType: FILE_CONFIG.ASSETS.apiType,
      masterConfigurationId,
      yearId,
    }),
    [assetType, masterConfigurationId, yearId],
  );

  const queryClient = useQueryClient();
  const { queryKeyFn } = useDiceContext();

  const getAllAssetsKey = queryKeyFn({
    operationId: 'getAllAssets',
    path: '/assets',
    variables: {
      queryParams,
    },
  });

  const { data: assetGroups, isFetched } = useFindGroups({
    pathParams: {
      masterConfigurationId,
    },
  });

  const { data: futureAssets = [] } = useGetAllAssets(
    {
      queryParams,
    },
    {
      enabled: isFetched,
      select(data) {
        return addGroupToAssets(data, assetGroups ?? []);
      },
    },
  );

  const { mutateAsync: mutatePost } = useInsertAssets();
  const { mutateAsync: mutatePut } = useUpdateAsset({
    onMutate({ body: asset }) {
      const currentData = queryClient.getQueryData<Asset[]>(getAllAssetsKey)!;
      const modifiedAssetIndex = currentData?.findIndex(
        (futureAsset) => futureAsset._id === asset?._id,
      );
      const updatedAssets = Array.from(currentData);
      updatedAssets[modifiedAssetIndex] = asset ?? ({} as Asset);
      queryClient.setQueryData(getAllAssetsKey, updatedAssets);
    },
  });
  const { mutateAsync: mutateDelete } = useDeleteFile1();
  const { mutateAsync: mutateDeleteBatch } = useDeleteAll();

  const { mutateAsync: mutateGroupPost } = useCreateAssetGroup();
  const { mutateAsync: mutateGroupPut } = useUpdateAssetGroup({});
  const { mutateAsync: mutateGroupDelete } = useDeleteAssetGroup();

  const addAsset = useCallback(
    async (asset: Asset) =>
      mutatePost({
        body: {
          assets: [asset],
          fileType: FILE_CONFIG.ASSETS.apiType,
          yearId,
        },
      }),
    [mutatePost, yearId],
  );

  const updateAsset = useCallback(
    async (asset: Asset) => {
      await mutatePut({
        body: asset,
      });
    },
    [mutatePut],
  );

  const deleteAsset = useCallback(
    async (assetId: string) => {
      await mutateDelete({
        pathParams: {
          assetId,
        },
      });
    },
    [mutateDelete],
  );

  const deleteBatchAsset = useCallback(
    async (assetIds: string[]) => {
      await mutateDeleteBatch({
        body: assetIds,
      });
    },
    [mutateDeleteBatch],
  );

  const addAssetGroup = useCallback(
    async (assetGroup: AssetGroupDTO) => {
      await mutateGroupPost({
        body: assetGroup,
      });
    },
    [mutateGroupPost],
  );

  const updateAssetGroup = useCallback(
    async (assetGroup: AssetGroupDTO) => {
      await mutateGroupPut({
        body: assetGroup,
      });
    },
    [mutateGroupPut],
  );

  const deleteAssetGroup = useCallback(
    async (groupId: string) => {
      await mutateGroupDelete({
        pathParams: {
          groupId,
        },
      });
    },
    [mutateGroupDelete],
  );

  return {
    addAsset,
    addAssetGroup,
    deleteAsset,
    deleteAssetGroup,
    deleteBatchAsset,
    futureAssets,
    updateAsset,
    updateAssetGroup,
  };
}
