import type {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  IDetailCellRendererParams,
} from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useRef } from 'react';

import type { APIParams, dice } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';

import type { CostTypeAccountRowData } from './types';
import { useColumns } from './useColumns';

export interface ControllerProps extends IDetailCellRendererParams<CostTypeAccountRowData> {
  apiParams: APIParams<'masterConfigurationId'>;
}

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
} satisfies ColDef;

export const useController = ({ data, apiParams, node, api }: ControllerProps) => {
  const { costTypeAccountConfig, updateCostTypeAccount, extendedCostTypeAccountConfig } =
    aggregated.useCostTypeAccounts(apiParams);

  useEffect(
    () => () => {
      // the detail grid is automatically destroyed as it is a React component
      api.removeDetailGridInfo(node.id!);
    },
    [api, node.id],
  );

  const onGridReady = (params: GridReadyEvent) => {
    const gridInfo = {
      api: params.api,
      id: node.id!,
    };

    api.addDetailGridInfo(node.id!, gridInfo);
  };

  const tableRef = useRef<AgGridReact>(null);
  const { id = '', accounts = [] } =
    extendedCostTypeAccountConfig?.rowData.find(({ id: rowDataId }) => rowDataId === data?.id) ??
    {};
  const { adjustedAccountsMap, selectedAccountsMap } = extendedCostTypeAccountConfig ?? {};

  const handleEditConfig = useCallback(
    async (config: dice.CostTypeAccountConfigDTO) => {
      await updateCostTypeAccount(config);
    },
    [updateCostTypeAccount],
  );

  const columnDefs = useColumns({
    adjustedAccountsMap,
    costTypeAccountConfig,
    costTypeAccountId: id,
    detailAccounts: accounts,
    handleEditConfig,
    selectedAccountsMap,
    tableRef,
  });

  const getRowId = useCallback(
    ({ data: getRowParamsData }: GetRowIdParams) => getRowParamsData?.accountId,
    [],
  );

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: accounts,
    onGridReady,
  });

  return {
    agGridProps,
    columnDefs,
    defaultColDef,
    getRowId,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
