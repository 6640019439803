import type { TabListState } from '@react-stately/tabs';
import type { MutableRefObject } from 'react';
import { createContext } from 'react';

import type { TabsProps } from './Tabs';

interface TabsContext<T> {
  tabProps: TabsProps;
  tabState: {
    tabListState: TabListState<T>;
    setTabListState: (state: TabListState<T>) => void;
    selectedTab: Element | undefined;
  };
  refs: {
    tablistRef: MutableRefObject<HTMLDivElement | undefined>;
  };
}
export const TabContext = createContext<TabsContext<any>>(null as unknown as TabsContext<any>);
