import type { griddy } from '@org/query';
import { t } from '@org/locales';

interface CostCenterIgnoreMappingWithJobId extends griddy.CostCenterIgnoreMapping {
  jobId?: string;
}

interface MemoItem {
  costCenters: Map<
    string,
    {
      costCenterId: string;
      jobIds: Set<string>;
    }
  >;
}

export const mergeKeysWithIgnoreMapping = (
  mappingKeys: griddy.MappingKey[],
  costCenterIgnoreMappings: griddy.CostCenterIgnoreMapping[],
) =>
  mappingKeys.map(
    ({ profitCenter: profitCenterId, costCenter: costCenterId, jobId }: griddy.MappingKey) => {
      const foundMatch = costCenterIgnoreMappings.find(
        (mapping: griddy.CostCenterIgnoreMapping) => {
          const allIgnoreSelected =
            mapping.profitCenterId === '' &&
            mapping.costCenterId === '' &&
            mapping.ignoreProfitCenter &&
            mapping.ignoreJobIds &&
            mapping.ignoreCostCenter;

          const ignoreCCandJobSelected =
            mapping.profitCenterId === profitCenterId &&
            mapping.costCenterId === '' &&
            mapping.ignoreCostCenter &&
            mapping.ignoreJobIds;

          const ignoreJobSelected =
            mapping.profitCenterId === profitCenterId &&
            mapping.costCenterId === costCenterId &&
            mapping.ignoreJobIds;

          const ignoreProfitCentersSelected =
            mapping.profitCenterId === '' &&
            mapping.costCenterId === costCenterId &&
            mapping.ignoreProfitCenter;

          const ignoreProfitAndIgnoreCostCenters =
            mapping.profitCenterId === '' &&
            mapping.costCenterId === '' &&
            mapping.ignoreProfitCenter &&
            mapping.ignoreCostCenter;

          return Boolean(
            allIgnoreSelected ??
              ignoreCCandJobSelected ??
              ignoreJobSelected ??
              ignoreProfitCentersSelected ??
              ignoreProfitAndIgnoreCostCenters,
          );
        },
      );

      if (foundMatch) {
        return {
          jobId,
          ...foundMatch,
        };
      }

      return {
        costCenterId,
        ignoreCostCenter: false,
        ignoreJobIds: false,
        ignoreProfitCenter: false,
        jobId,
        profitCenterId,
      };
    },
  );

export const mapMappingsToProfitCenters = (mappings: griddy.MappingKey[]) => {
  const memo = new Map<string, MemoItem>();

  mappings.forEach(
    ({
      ignoreProfitCenter,
      ignoreCostCenter,
      ignoreJobIds,
      ...other
    }: CostCenterIgnoreMappingWithJobId) => {
      const profitCenterId = ignoreProfitCenter
        ? t('main:manageMenu.manageCostCenters.ignoreProfitCenters')
        : (other.profitCenterId ?? '');

      if (!memo.has(profitCenterId)) {
        memo.set(profitCenterId, {
          costCenters: new Map(),
        });
      }

      const currentProfitCenter = memo.get(profitCenterId)!;

      const costCenterId = ignoreCostCenter
        ? t('main:manageMenu.manageCostCenters.ignoreCostCenters')
        : (other.costCenterId ?? '');

      if (!currentProfitCenter.costCenters.has(costCenterId)) {
        currentProfitCenter.costCenters.set(costCenterId, {
          costCenterId,
          jobIds: new Set(),
        });
      }
      const currentCostCenter = currentProfitCenter.costCenters.get(costCenterId)!;
      const jobId = ignoreJobIds
        ? t('main:manageMenu.manageCostCenters.ignoreJobIds')
        : other.jobId;

      if (jobId && !currentCostCenter.jobIds.has(jobId)) {
        currentCostCenter.jobIds.add(jobId);
      }
    },
  );

  return Array.from(memo.entries()).map(([profitCenterId, { costCenters }]) => ({
    costCenters: Array.from(costCenters.values()).map(({ jobIds, costCenterId }) => ({
      costCenterId,
      jobIds: [...jobIds],
    })),
    profitCenterId,
  }));
};
