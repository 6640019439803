import { makeRoutePath } from 'make-route-path';

import config from '../../config';

interface WithFileTypeAndYearIdQuery {
  fileType: string;
  yearId: string;
}

export const GRIDDY_CLIENTS = makeRoutePath<string, { onlyArchived?: boolean }>(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_CLIENTS_PATH,
);

export const GRIDDY_ACCOUNT_COST_CENTER = makeRoutePath(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_ACCOUNT_COST_CENTER_PATH,
);

export const GRIDDY_ACCOUNT_IDS = makeRoutePath<string, WithFileTypeAndYearIdQuery>(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_ACCOUNT_ID_PATH,
);

export const GRIDDY_ACCOUNTS_PLAN_MAP = makeRoutePath<string, WithFileTypeAndYearIdQuery>(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_ACCOUNTS_PLAN_DESCRIPTION_MAP_PATH,
);

export const GRIDDY_PROFIT_CENTER = makeRoutePath<string, WithFileTypeAndYearIdQuery>(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_PROFIT_CENTER_PATH,
);

export const GRIDDY_FLAT_PROFIT_CENTER_TREE = makeRoutePath<string, WithFileTypeAndYearIdQuery>(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_FLAT_PROFIT_CENTER_TREE_PATH,
);

export const GRIDDY_PROFIT_CENTER_TREES_NA = makeRoutePath<string, WithFileTypeAndYearIdQuery>(
  `${config.PROXY_GRIDDY_PATH + config.GRIDDY_PROFIT_CENTER_TREES_PATH}/NA`,
);

export const GRIDDY_PROFIT_CENTER_TREES = makeRoutePath(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_PROFIT_CENTER_TREES_PATH,
);

export const GRIDDY_JOB_ID = makeRoutePath<string, WithFileTypeAndYearIdQuery>(
  config.PROXY_GRIDDY_PATH + config.GRIDDY_JOB_ID_PATH,
);

export const GRIDDY_DATA_CONTAINER = makeRoutePath(
  `${config.PROXY_GRIDDY_PATH + config.GRIDDY_DATA_CONTAINER_PATH}/:dataContainerId`,
);

export const GRIDDY_FILE = makeRoutePath(
  `${config.PROXY_GRIDDY_PATH + config.GRIDDY_OBJECTS_PATH}/:fileId`,
);

export const GRIDDY_YEARS = makeRoutePath(
  `${config.PROXY_GRIDDY_PATH + config.GRIDDY_YEARS_PATH}/:yearIds`,
);

export const GRIDDY_REPORT = makeRoutePath(
  `${config.PROXY_GRIDDY_PATH + config.GRIDDY_OBJECTS_PATH}/:reportId`,
);

export const GRIDDY_MASTER_CONFIG = makeRoutePath(
  `${config.PROXY_GRIDDY_PATH + config.GRIDDY_MASTER_CONFIG_PATH}/:masterConfigurationId`,
);

export const RAVE_ACCOUNT_TRANSACTIONS = makeRoutePath<
  string,
  { accountId: number; yearId: string }
>(config.PROXY_PATH + config.RAVE_ACCOUNT_TRANSACTIONS_PATH);
