import type { dice, rave } from '@org/query';

import type { BabRow } from '../../ReportTable/types';
import { ACCOUNT_TYPES } from '../../ReportTable/types';
import { prepareExpenseCalculations } from '../helpers';
import { usePrepareAccountData } from './usePrepareAccountData';

export const useBABExpenseValues = (
  apiResponse?: rave.ReportsResponse,
  selectedConfiguration?: dice.MasterConfiguration,
): BabRow[] => {
  const expenseAccountsData = usePrepareAccountData(
    selectedConfiguration,
    apiResponse?.babReport?.ctaExpenseEntries ?? {},
    ACCOUNT_TYPES.EXPENSE,
  );
  const applyRPV = selectedConfiguration?.wiberaConfig?.applyReplacementValueDepreciation;
  const applyCalcInt = selectedConfiguration?.interestRates?.applyCalculatedInterests;

  return [
    ...expenseAccountsData,
    ...prepareExpenseCalculations(apiResponse, selectedConfiguration, applyRPV, applyCalcInt),
  ];
};
