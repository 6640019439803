import type { griddy } from '@org/query';

export const getDuplicatedClientName = (
  newClientName: string,
  clients?: griddy.GetClientsResponse,
) => clients?.find((client) => client.clientName?.toLowerCase() === newClientName?.toLowerCase());

const NOT_ALLOWED_CHARS = ['$', '&', '+', ',', '/', ':', ';', '=', '?', '@'];

export const checkStringForNotAllowedChars = (string: string) =>
  NOT_ALLOWED_CHARS.find((notAllowedChar) => string.includes(notAllowedChar)) ?? '';
