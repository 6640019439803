import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useDebounce } from './useDebounce';

interface UseSearchNamesProps<T, U extends keyof T> {
  data?: T[];
  fieldName: U;
}

export const useSearchNames = <T, U extends keyof T>({
  data,
  fieldName,
}: UseSearchNamesProps<T, U>) => {
  const { control } = useForm({});

  const [searchedItems, updateItems] = useState<T[] | undefined>(undefined);

  const handleSearch = useDebounce(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      updateItems(data);
      return;
    }

    const foundItems = data?.filter((item) => {
      const found = (item[fieldName] as string)?.search(new RegExp(value, 'i'));
      if (found !== -1) {
        return item;
      }
    });
    updateItems(foundItems ?? []);
  }, 200);

  return {
    control,
    handleSearch,
    searchedItems,
  };
};
