import { useCallback } from 'react';

import { useTranslation } from '@org/locales';
import { dice } from '@org/query';
import { getErrorMessage, showNotification } from '@org/ui';

export const useAutomaticCCCreation = () => {
  const { t } = useTranslation();
  const { mutateAsync: mutateCreate } = dice.useCreateBabCostCenter({
    onError: (error) =>
      showNotification(
        'error',
        t('common:alerts.error.generalWithMsg', {
          msg: getErrorMessage(error, t),
        }),
        5000,
      ),
    onSuccess: (data) => {
      if (data.created) {
        showNotification(
          'info',
          t('common:automaticCCCreated', {
            amount: data.numberOfCreated,
          }),
          5000,
        );
      } else {
        showNotification('info', t('common:automaticCCNothingToCreate'), 5000);
      }
    },
  });

  const createAutomaticCCs = useCallback(
    async ({ creationType, masterConfigId, yearId }: dice.CreateBabCostCenterPathParams) => {
      await mutateCreate({
        pathParams: {
          creationType,
          masterConfigId,
          yearId,
        },
      });
    },
    [mutateCreate],
  );
  return { createAutomaticCCs };
};
