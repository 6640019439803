// @ts-check
import { memo, useCallback, useEffect, useState } from 'react';

import { Button } from 'appkit-react';
import _ from 'lodash';
import MaterialTable, { MTableEditRow, MTableToolbar } from 'material-table';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useTranslation } from '@org/locales';
import { aggregated, dice, griddy } from '@org/query';
import { NOT_AVAILABLE } from '@org/utils';

import { objGet } from '../../../utils';
import { handleShowAlert } from '../../../utils/Alerts';
import { isEmptyOrSpaces } from '../../../utils/formatUtils';
import { getMaterialTableLocalization } from '../../../utils/i18nMaterialTable';
import { parseToFloat } from '../../../utils/parseUtils';
import tableIcons from '../../../utils/TableIcons';
import { StyledTable } from '../StyledTable';
import { resetAllAllocations } from './utils';
import { createAllocationStructureFromMapping } from './WithdrawCapitalHelper';
import { generateWithdrawCapitalColumns } from './WithdrawCapitalTableInfo';

const EditRow = memo((props) => {
  const { t } = useTranslation();
  return (
    <MTableEditRow
      {...props}
      onKeyDown={() => {}}
      // Access the cancel add row
      onEditingApproved={(mode, rowData) => {
        if (
          mode !== 'delete' &&
          (isEmptyOrSpaces(rowData.shortName) ||
            isEmptyOrSpaces(rowData.longName) ||
            _.isEmpty(rowData.accountsAllocation))
        ) {
          handleShowAlert('error', t('common:alerts.error.notAllFieldsFilled'));
        } else {
          // for each accounts
          // for each cc
          // if cc allocation !== 100 -> print account, cc and allocation

          const isCostCenterAllocationCorrect = (costCenterAllocation) => {
            let sum = 0;
            Object.values(costCenterAllocation).forEach((value) => {
              sum += parseToFloat(value);
            });
            return Math.round(sum) === 100;
          };

          let errors = false;
          if (rowData?.accountsAllocation) {
            for (const [accountId, costCenterMap] of Object.entries(rowData.accountsAllocation)) {
              if (errors) {
                break;
              }
              if (costCenterMap) {
                for (const [costCenterId, allocationMap] of Object.entries(costCenterMap)) {
                  if (errors) {
                    break;
                  }
                  if (!_.isEmpty(allocationMap) && !isCostCenterAllocationCorrect(allocationMap)) {
                    errors = true;
                    if (costCenterId === NOT_AVAILABLE) {
                      handleShowAlert(
                        'error',
                        t(
                          'main:manageMenu.manageWithdrawCapital.alerts.error.sumNot100PercentageManual',
                          { accountId },
                        ),
                      );
                    } else {
                      handleShowAlert(
                        'error',
                        t(
                          'main:manageMenu.manageWithdrawCapital.alerts.error.sumNot100Percentage',
                          {
                            accountId,
                            costCenter: costCenterId,
                          },
                        ),
                      );
                    }
                  }
                }
              }
            }
          }
          if (!errors) {
            props.onEditingApproved(mode, rowData, props.data);
          }
        }
      }}
    />
  );
});

function ManageWithdrawCapital({ addRow, disableEditing }) {
  const { t } = useTranslation();
  const { configId, yearId = '' } = useParams();
  const { data: accountCostCentersMap } = griddy.useGetAllAcctIdToCostCenterNameMap({
    queryParams: {
      fileType: 'LEDGER_ACCOUNT',
      yearId,
    },
  });
  // useAccountCostCenters();
  const { data: accountsPlanMap } = griddy.useGetAllAccountIdToAccountDescriptionMap({
    queryParams: {
      fileType: 'ACCOUNT_PLAN',
      yearId,
    },
  });
  const { data: profitCenterTrees } = griddy.useGetTransactionsByFileId({
    queryParams: {
      fileType: 'JOURNAL',
      yearId,
    },
  });
  const [columns, setColumns] = useState([]);
  const { masterConfiguration, updateMasterConfiguration } = aggregated.useMasterConfiguration({
    masterConfigurationId: configId,
  });
  const { mutateAsync: updateWithdrawalCapitalConfig } = dice.useCreateWithdrawCapitalConfig({});

  useEffect(() => {
    updateWithdrawalCapitalConfig({
      queryParams: {
        masterConfigId: configId,
        yearId,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- ugly workaround for bug fix
  }, []);

  useEffect(() => {
    const defaultAllocationMap = createAllocationStructureFromMapping(
      accountCostCentersMap,
      masterConfiguration?.costCenterConfig,
      profitCenterTrees,
    );

    const columnData = generateWithdrawCapitalColumns(
      masterConfiguration,
      accountsPlanMap,
      defaultAllocationMap,
      t,
    );
    setColumns(columnData);
    // TODO: delete this workaround and get proper data from backend or map it directly in api hook
    // Huge performance fix with JSON.stringify. Need to refactor this useEffect. Now it's recalling useEffect every rerender.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(accountCostCentersMap),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(accountsPlanMap),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(profitCenterTrees),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(masterConfiguration),
    t,
  ]);

  const updateWithdrawCapitalConfig = useCallback(
    async (withdrawCapitalConfig, successMsgCode) => {
      await updateMasterConfiguration({
        ...masterConfiguration,
        withdrawCapitalConfig,
      });
      await updateWithdrawalCapitalConfig({
        queryParams: {
          masterConfigId: configId,
          yearId,
        },
      });
      handleShowAlert(
        'success',
        t(`main:manageMenu.manageWithdrawCapital.alerts.success.resetAll${successMsgCode}`),
      );
    },
    [
      configId,
      masterConfiguration,
      t,
      updateMasterConfiguration,
      updateWithdrawalCapitalConfig,
      yearId,
    ],
  );

  const handleResetAllAllocations = useCallback(async () => {
    const updatedWiCapConfig = resetAllAllocations(
      masterConfiguration?.withdrawCapitalConfig,
      masterConfiguration?.costCenterConfig,
      accountCostCentersMap,
      profitCenterTrees,
    );
    await updateWithdrawCapitalConfig(updatedWiCapConfig, 'resetAll');
  }, [
    accountCostCentersMap,
    masterConfiguration?.costCenterConfig,
    masterConfiguration?.withdrawCapitalConfig,
    profitCenterTrees,
    updateWithdrawCapitalConfig,
  ]);

  const updateRow = useCallback(
    async (newRow, oldRow, configName) => {
      const updatedRows = [
        ...objGet(masterConfiguration, configName).filter((row) => !row.tableData.editing),
        newRow,
      ];
      await updateMasterConfiguration({
        ...masterConfiguration,
        [configName]: updatedRows,
      });
      await updateWithdrawalCapitalConfig({
        queryParams: {
          masterConfigId: configId,
          yearId,
        },
      });
      handleShowAlert('success', t('main:manageMenu.alerts.success.updatedRow'));
    },
    [
      configId,
      masterConfiguration,
      t,
      updateMasterConfiguration,
      updateWithdrawalCapitalConfig,
      yearId,
    ],
  );

  const deleteRow = useCallback(
    async (configName) => {
      const updatedRows = objGet(masterConfiguration, configName).filter(
        (row) => !row.tableData.editing,
      );
      await updateMasterConfiguration({
        ...masterConfiguration,
        [configName]: updatedRows,
      });
      await updateWithdrawalCapitalConfig({
        queryParams: {
          masterConfigId: configId,
          yearId,
        },
      });
      handleShowAlert('success', t('main:manageMenu.alerts.success.deletedRow'));
    },
    [
      configId,
      masterConfiguration,
      t,
      updateMasterConfiguration,
      updateWithdrawalCapitalConfig,
      yearId,
    ],
  );

  return (
    <StyledTable>
      <MaterialTable
        columns={columns}
        components={{
          EditRow,
          Toolbar: (props) => (
            <div className="a-pb-10">
              <MTableToolbar {...props} />
              <div className="table-top-action-buttons">
                <Button onClick={handleResetAllAllocations}>
                  {t('main:manageMenu.manageWithdrawCapital.labelResetAll')}
                </Button>
              </div>
            </div>
          ),
        }}
        data={masterConfiguration?.withdrawCapitalConfig}
        editable={
          disableEditing
            ? {}
            : {
                onRowAdd: (newRow) => addRow(newRow, 'withdrawCapitalConfig'),
                onRowDelete: () => deleteRow('withdrawCapitalConfig'),
                onRowUpdate: (newRow, oldRow) => updateRow(newRow, oldRow, 'withdrawCapitalConfig'),
              }
        }
        icons={tableIcons}
        localization={getMaterialTableLocalization(t)}
        options={{
          actionsColumnIndex: -1,
          minBodyHeight: '41em',
          paging: false,
        }}
        title={t('main:manageMenu.manageWithdrawCapital.tableTitle')}
      />
    </StyledTable>
  );
}

ManageWithdrawCapital.propTypes = {
  addRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  disableEditing: PropTypes.bool,
  updateConfiguration: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
};

export default ManageWithdrawCapital;
