import { useCallback } from 'react';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ModalContentProps } from '..';

export const ActivateConfigurationModalContent = ({
  disclosureState,
  entity,
  yearId,
}: ModalContentProps) => {
  const { t } = useTranslation();
  const { onClose } = disclosureState;

  const { activateConfig, activateConfigMutation } =
    aggregated.useMasterConfigurationByYearCalculation({
      yearCalculationId: yearId,
    });

  const handleOnActivate = useCallback(async () => {
    try {
      onClose();
      await activateConfig(entity);
    } catch {
      showNotification('warning', t('main:clients.clientsOverviewPanel.alerts.warning.general'));
    }
  }, [activateConfig, entity, onClose, t]);

  return (
    <>
      <ModalHeader>
        {t('common:contextMenu.modalActivate.title', {
          name: entity?.name,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>{t('common:contextMenu.modalActivate.label')}</ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common:close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={activateConfigMutation.isPending}
          onClick={handleOnActivate}
          size="md"
          variant="primary"
        >
          {t('common:contextMenu.modalActivate.btnActivate')}
        </Button>
      </ModalFooter>
    </>
  );
};
