import type { ICellRendererParams } from 'ag-grid-community';

import { useTranslation } from '@org/locales';
import { RadioInput } from '@org/ui';

import { REVENUE_TYPES } from '../constants';

interface RevenueTypeRendererProps extends ICellRendererParams {
  path: string;
}

export const RevenueTypeRenderer = (props: RevenueTypeRendererProps) => {
  const { t } = useTranslation();

  const revenueTypeOptions = [
    {
      displayName: t('main:manageMenu.manageCostUnits.revenueTypes.selectAccounts'),
      name: t('main:manageMenu.manageCostUnits.revenueTypes.selectAccounts'),
      value: REVENUE_TYPES.SELECT_ACCOUNTS,
    },
    {
      displayName: t('main:manageMenu.manageCostUnits.revenueTypes.defineRevenues'),
      name: t('main:manageMenu.manageCostUnits.revenueTypes.defineRevenues'),
      value: REVENUE_TYPES.DEFINE_REVENUES,
    },
  ];

  const { value, node, path } = props;
  return (
    <div
      className="flex-column flex"
      role="radiogroup"
    >
      {revenueTypeOptions.map((option) => (
        <RadioInput
          checked={value === option.value}
          key={option.value}
          name={node.id}
          onChange={({ currentTarget: { value: newValue } }) => {
            node.setDataValue(path, newValue);
          }}
          value={option.value}
        >
          <div className="text-xs">{option.name}</div>
        </RadioInput>
      ))}
    </div>
  );
};
