import type { ToastOptions } from 'react-toastify';

import { Accordion, AccordionItem } from '@nextui-org/react';
import { toast } from 'react-toastify';

// import { AccordionItem, AccordionItemBody, AccordionItemHeader, Accordions } from 'appkit-react';

type StatusTypes = 'info' | 'warning' | 'error' | 'success';

const getOptions = (
  status: StatusTypes,
  duration?: false | number,
  detailedMessage?: string | string[] | React.JSX.Element,
) => {
  const options: ToastOptions = {
    autoClose: duration ?? 5000,
    closeOnClick: true,
  };

  if (detailedMessage) {
    options.closeOnClick = false;
  }

  return options;
};

const renderMessage = (
  message: string,
  detailedMessage?: string | string[] | React.JSX.Element,
) => {
  if (detailedMessage) {
    let errorList: React.JSX.Element | React.JSX.Element[] = <span>{detailedMessage}</span>;
    if (Array.isArray(detailedMessage)) {
      errorList = detailedMessage.map((detailedMsg, index) => <li key={index}>{detailedMsg}</li>);
    }
    return (
      <div className="max-w-[50vw]">
        <Accordion
          defaultExpandedKeys={['details']}
          isCompact
        >
          <AccordionItem
            key="details"
            title={message}
          >
            {errorList}
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
  return (
    <div>
      <div className="max-w-3xl">{message}</div>
    </div>
  );
};

export const showNotification = (
  status: StatusTypes,
  message: string,
  duration?: false | number,
  detailedMessage?: string | string[] | React.JSX.Element,
) => {
  switch (status) {
    case 'info': {
      toast.info(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage),
      );
      break;
    }

    case 'warning': {
      toast.warn(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage),
      );
      break;
    }

    case 'error': {
      toast.error(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage),
      );
      break;
    }

    case 'success': {
      toast.success(
        renderMessage(message, detailedMessage),
        getOptions(status, duration, detailedMessage),
      );
      break;
    }

    default: {
      toast(renderMessage(message, detailedMessage), getOptions(status, duration, detailedMessage));
    }
  }
};
