import { useCallback, useMemo } from 'react';

import { useTranslation } from '@org/locales';

import type { APIParams } from '../..';
import type { AccountDTO } from '../dice';
import { useGetAccountsByMasterConfiguration } from '../dice';

interface UseAccountIdsParams extends APIParams<'masterConfigurationId'> {}

export const useAccountIds = (params: UseAccountIdsParams) => {
  const { masterConfigurationId } = params;

  const { data: accounts = { expense: [], revenue: [], other: [], accountMap: new Map() } } =
    useGetAccountsByMasterConfiguration(
      {
        pathParams: {
          masterConfigurationId,
        },
      },
      {
        select(data) {
          return data.reduce(
            (acc, current) => {
              if (current.accountType === 'EXPENSE') {
                acc.expense.push(current);
              } else if (current.accountType === 'REVENUE') {
                acc.revenue.push(current);
              } else {
                acc.other.push(current);
              }
              acc.accountMap.set(current.accountId!, current);
              return acc;
            },
            {
              expense: [] as AccountDTO[],
              revenue: [] as AccountDTO[],
              other: [] as AccountDTO[],
              accountMap: new Map<string, AccountDTO>(),
            },
          );
        },
      },
    );

  const { t } = useTranslation();

  const getAccount = useCallback(
    (accountId: string) => accounts.accountMap.get(accountId),
    [accounts.accountMap],
  );

  const getAccountWithDescription = useCallback(
    (accountId: string) => `${accountId} ${getAccount(accountId)?.accountDescription ?? ''}`.trim(),
    [getAccount],
  );

  const expenseAccountOptions = useMemo(
    () =>
      accounts.expense.map(({ accountId, accountDescription }) => ({
        label: `${accountId} ${accountDescription ?? ''}`.trim(),
        value: accountId!,
      })),
    [accounts.expense],
  );

  const revenueAccountOptions = useMemo(
    () =>
      accounts.revenue.map(({ accountId, accountDescription }) => ({
        label: `${accountId} ${accountDescription ?? ''}`.trim(),
        value: accountId!,
      })),
    [accounts.revenue],
  );

  const accountIdSelectOptions = [...expenseAccountOptions, ...revenueAccountOptions];

  const accountIdSelectOptionsByRange = [
    ...(expenseAccountOptions.length > 0
      ? [
          {
            label: t('main:manageMenu.accountsTypes.expense.groupName'),
            options: expenseAccountOptions,
          },
        ]
      : []),
    ...(revenueAccountOptions.length > 0
      ? [
          {
            label: t('main:manageMenu.accountsTypes.revenue.groupName'),
            options: revenueAccountOptions,
          },
        ]
      : []),
  ];

  return {
    accountIdSelectOptions,
    accountIdSelectOptionsByRange,
    accounts,
    expenseAccountOptions,
    revenueAccountOptions,
    getAccount,
    getAccountWithDescription,
  };
};
