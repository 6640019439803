import type { IAggFuncParams } from 'ag-grid-community';
import type { FC } from 'react';

import classnames from 'classnames';

import { t } from '@org/locales';
import {
  BaseEditTable,
  IconCellRenderer,
  Label,
  LoaderWrapper,
  StatusCellRenderer,
  ToggleInput,
} from '@org/ui';
import { bindControllers, isAllEqualInArray, sumBooleanValues } from '@org/utils';

import type { ControllerType } from './useController';
import { WiberaFactorYearInput, WiberaYearSelect } from './components';
import { autoGroupColumnDef, defaultColDef } from './constants';
import { useController } from './useController';

const components = {
  icon: IconCellRenderer,
  status: StatusCellRenderer,
};

const aggregateCostCenter = (params: IAggFuncParams) =>
  isAllEqualInArray(params?.values)
    ? params.values[0]
    : t('main:manageMenu.manageAssetCostCenters.lblMultipleCostCentersAssigned');

const AssetCostCenter: FC<ControllerType> = (props) => {
  const {
    onCellValueChange,
    columnDefs,
    tableRef,
    getRowId,
    isLoading,
    handleImputedAssetActivated,
    enableAssetImputedUsageTimeConfig,
    handleWiberaDepreciationActivated,
    enableWiberaDepreciationConfig,
    updateSelectedWiberaFileYear,
    currentYear,
    wiberaConfigYearId,
    updateNbrOfYearsForFactorsCalculation,
    wiberaYearFactorNum,
    agGridProps,
    selectedIndexFactorYear,
  } = props;

  return (
    <div
      className="flex flex-1 flex-col"
      data-testid="AssetCostCenter"
    >
      <div className="mb-8 mt-2.5 flex flex-row items-center gap-x-4">
        <Label className="mb-0 inline-flex cursor-pointer gap-2 text-xs">
          <ToggleInput
            checked={enableAssetImputedUsageTimeConfig}
            className="[&>label]:mb-0"
            name={t('main:manageMenu.manageAssetCostCenters.switchEnableImputedTime')}
            onChange={handleImputedAssetActivated}
          />
          {`${t('main:manageMenu.manageAssetCostCenters.switchEnableImputedTime')} (Beta)`}
        </Label>
        <Label className="mb-0 inline-flex cursor-pointer gap-2 text-xs">
          <ToggleInput
            checked={enableWiberaDepreciationConfig}
            className={classnames('[&>label]:mb-0')}
            name={t('main:manageMenu.manageAssetCostCenters.switchEnableWiberaDepreciation')}
            onChange={handleWiberaDepreciationActivated}
          />
          {`${t('main:manageMenu.manageAssetCostCenters.switchEnableWiberaDepreciation')} (Beta)`}
        </Label>
        <div className="flex gap-x-4 text-xs">
          <WiberaYearSelect
            disabled={!enableWiberaDepreciationConfig}
            selectedYearId={wiberaConfigYearId}
            updateSelectedWiberaYear={updateSelectedWiberaFileYear}
          />
          {currentYear > Number(selectedIndexFactorYear ?? 0) && (
            <WiberaFactorYearInput
              currentYear={currentYear}
              disabled={!enableWiberaDepreciationConfig}
              updateNbrOfYearsForFactorsCalculation={updateNbrOfYearsForFactorsCalculation}
              wiberaYearFactorNum={wiberaYearFactorNum}
            />
          )}
        </div>
      </div>
      <LoaderWrapper loading={isLoading}>
        <BaseEditTable
          {...agGridProps}
          aggFuncs={{
            uniqueItems: ({ values }) => [...new Set(values)],
            booleanSum: ({ values }) => sumBooleanValues(values),
            aggregateCostCenters: aggregateCostCenter,
          }}
          animateRows
          autoGroupColumnDef={autoGroupColumnDef}
          columnDefs={columnDefs}
          components={components}
          defaultColDef={defaultColDef}
          enableGroupEdit
          getRowId={getRowId}
          groupDisplayType="singleColumn"
          onCellValueChanged={onCellValueChange}
          ref={tableRef}
        />
      </LoaderWrapper>
    </div>
  );
};

export const AssetCostCenterPage = bindControllers(AssetCostCenter, [useController]);
AssetCostCenterPage.displayName = 'AssetCostCenterPage';
