import type { APIParams } from '../..';
import type { GetAllLabelsByTypeQueryParams } from '../griddy';
import { useGetAllLabelsByType } from '../griddy';

export interface UseLabelsParams extends APIParams<'yearId'> {
  fileType: GetAllLabelsByTypeQueryParams['fileType'];
  labelType: GetAllLabelsByTypeQueryParams['labelType'];
}

export const useLabels = ({ yearId, fileType, labelType }: UseLabelsParams) => {
  const { data: labels, isLoading: labelsIsLoading } = useGetAllLabelsByType(
    {
      queryParams: {
        fileType,
        labelType,
        yearId,
      },
    },
    {
      enabled: !!yearId,
      networkMode: 'offlineFirst',
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select(data) {
        return new Map(data.map(({ number, description }) => [number, description]));
      },
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  return {
    isLoading: labelsIsLoading,
    labels,
  };
};
