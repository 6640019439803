import { useCallback, useMemo, useState } from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useForm, useWatch } from 'react-hook-form';

import { useTranslation } from '@org/locales';
import { dice } from '@org/query';
import { showNotification } from '@org/ui';
import { parseCSV } from '@org/utils';

import type { ResponseErrorType } from './utils';
import { createValidRow, getLocalizedErrors } from './utils';

const filterCsv = (fileRows: Record<string, unknown>[]) =>
  fileRows.map((row) => createValidRow(row));

export interface UseControllerProps {
  onClose?: () => void;
}

export type ViewProps = ReturnType<typeof useController>;

export const useController = ({ onClose }: UseControllerProps) => {
  const [responseErrorItems, setResponseErrorItems] = useState<string[]>([]);
  const { mutateAsync: importWiberaIndices, isPending: isLoading } = dice.usePostIndexFactors();
  const { t } = useTranslation();

  const { data: dataSources = [] } = dice.useGetIndexFactorDataSources({});
  const { data: indexFactorYears } = dice.useGetIndexFactorsAllYears({});

  const {
    control,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      year: new Date().getFullYear().toString(),
      dataSource: null,
    },
  });

  const { year: inputYear, dataSource: inputDataSource } = useWatch({
    control,
  });

  const alreadyUploadedFileForYear = useMemo(
    () =>
      indexFactorYears?.some(
        (factorYear) =>
          factorYear.year === inputYear &&
          (factorYear.dataSource === inputDataSource || !inputDataSource),
      ),
    [indexFactorYears, inputDataSource, inputYear],
  );

  const handleFileUpload = useCallback(
    async (files: File[]) => {
      if (files.length === 0) {
        return;
      }
      const year = getValues('year');
      const indexFactor = getValues('dataSource');

      if (!year) {
        setError('year', {});
        return;
      }
      if (!indexFactor) {
        setError('dataSource', {});
        return;
      }

      const file = files.at(0)!;
      const parsedFile = await parseCSV(file);
      const filtered = filterCsv(parsedFile as Record<string, unknown>[]);

      try {
        await importWiberaIndices({
          body: {
            indexFactors: filtered,
            dataSource: indexFactor,
            year,
          },
        });
        showNotification('success', t('main:adminMenu.indexFactors.alerts.success.upload'));
        onClose?.();
      } catch (rError) {
        const error = rError as { errors: ResponseErrorType[] };
        const responseErrors = error?.errors ?? [];
        showNotification(
          'error',
          t('main:adminMenu.indexFactors.alerts.error.validationError', {
            count: responseErrors.length,
          }),
        );
        setResponseErrorItems(getLocalizedErrors(responseErrors, t));
      }
    },
    [getValues, importWiberaIndices, onClose, setError, t],
  );

  const ErrorLine = useCallback(
    ({ index = 0 }) => {
      const item = responseErrorItems[index];
      return (
        <li className="flex items-center break-words text-left text-xs">
          <span className="pr-2">
            <ExclamationTriangleIcon className="h-4 w-4 text-red-400" />{' '}
          </span>
          <span>{item}</span>
        </li>
      );
    },
    [responseErrorItems],
  );

  return {
    dataSources,
    control,
    ErrorLine,
    errors,
    handleFileUpload,
    isLoading,
    responseErrorItems,
    alreadyUploadedFileForYear,
  };
};
