// @ts-check
import { useEffect } from 'react';

import { setAutoFreeze } from 'immer';
import { useIdleTimer } from 'react-idle-timer';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { useTranslation } from '@org/locales';
import { ToastContainer } from '@org/ui';

import ReportsPage from './pages/ReportsPages/ReportsPage';

import './utils/i18n';

import Banner from './components/Banner';
import Layout from './components/Layout';
import Logout from './components/Logout';
import config from './config';
import { ClientsOverviewPage as ClientsOverview } from './pages/ClientsOverview/ClientsOverview';
import { Configuration, FilesPage } from './pages/Configuration';
import ErrorPage from './pages/ErrorPage';
import { PublicFacilitiesOverviewPage as PublicFacilitiesOverview } from './pages/PublicFacilitiesOverview/PublicFacilitiesOverview';
import { YearsOverviewPage as YearsOverview } from './pages/YearsOverview/YearsOverview';
// prettier-ignore
import 'bootstrap/dist/css/bootstrap.css';
// prettier-ignore
import 'react-toastify/dist/ReactToastify.min.css';
// prettier-ignore
import 'appkit-react/style/appkit-react.default.css';
// prettier-ignore
import './_variables.scss';

setAutoFreeze(false);

const onIdle = (navigate) => {
  const env = process.env.REACT_APP_ENV;
  if (env !== config.LOCAL && env !== config.DEVR2) {
    navigate('/logout');
  }
};

const App = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { start } = useIdleTimer({
    onIdle: () => onIdle(navigate),
    throttle: 500,
    timeout: 1000 * 60 * 14,
  });

  useEffect(() => {
    start();
  }, [start]);

  // @ts-expect-error missing type
  if (document.documentMode) {
    return <div>{t('common:alerts.warning.ie')}</div>;
  } // checks if browser is IE

  return (
    <>
      <Banner content={t('common:alerts.error.backendNotResponsive')} />
      <ToastContainer
        autoClose={5000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss={false}
        pauseOnHover
        position="top-left"
        rtl={false}
      />

      <Layout>
        <Routes>
          <Route
            Component={ClientsOverview}
            path="/"
          />

          <Route
            Component={PublicFacilitiesOverview}
            path="/client/:clientId"
          />

          <Route
            Component={YearsOverview}
            path="/client/:clientId/:publicFacilityId"
          />

          <Route
            Component={Configuration}
            path="/client/:clientId/:publicFacilityId/:yearId/:type"
          />

          <Route
            Component={FilesPage}
            path="/client/:clientId/:publicFacilityId/:yearId/:type/files"
          />

          <Route
            Component={Configuration}
            path="/client/:clientId/:publicFacilityId/:yearId/:type/:configId/configuration"
          />

          <Route
            Component={ReportsPage}
            path="/client/:clientId/:publicFacilityId/:yearId/:type/:configId/report"
          />
          <Route
            Component={ErrorPage}
            path="/error"
          />
          <Route
            Component={Logout}
            path="/logout"
          />
          <Route
            Component={() => <Navigate to="/" />}
            path="*"
          />
        </Routes>
      </Layout>
    </>
  );
};

export default App;
