import type { AriaTabPanelProps } from '@react-aria/tabs';
import type { CollectionChildren, DOMProps, ItemProps, Node } from '@react-types/shared';
import type { ComponentProps } from 'react';
import React, { useCallback, useContext, useMemo, useRef } from 'react';

import { FocusRing } from '@react-aria/focus';
import { useTabPanel } from '@react-aria/tabs';
import { Item, useCollection } from '@react-stately/collections';
import { ListCollection } from '@react-stately/list';
import { tv } from 'tailwind-variants';

import { TabContext } from './TabContext';

const tabPanelVariants = tv({
  slots: {
    base: '',
    focusRing: '',
  },
});

interface InternalTabPanelProps extends AriaTabPanelProps, ComponentProps<'div'> {
  focusRingClassName?: string;
}

// @private
function InternalTabPanel(props: InternalTabPanelProps) {
  const { className, children, focusRingClassName } = props;
  const { tabState } = useContext(TabContext);
  const { tabListState } = tabState;
  const ref = useRef<HTMLDivElement>(null);
  const { tabPanelProps } = useTabPanel(props, tabListState, ref);

  const styles = useMemo(() => tabPanelVariants({}), []);

  return (
    <FocusRing focusRingClass={styles.focusRing({ className: focusRingClassName })}>
      <div
        {...tabPanelProps}
        className={styles.base({ className })}
        ref={ref}
      >
        {children}
      </div>
    </FocusRing>
  );
}

export interface TabPanelProps
  extends Omit<InternalTabPanelProps, 'children' | 'title'>,
    ItemProps<object> {}

export const TabPanel = Item as (props: TabPanelProps) => React.JSX.Element;

interface TabPanelsProps extends DOMProps, Omit<ComponentProps<'div'>, 'children'> {
  /** The contents of each tab. Item keys should match the key of the corresponding `<Item>` within the `<TabList>` element. */
  children: CollectionChildren<object>;
}

/**
 * TabPanels is used within Tabs as a container for the content of each tab.
 * The keys of the items within the <TabPanels> must match up with a corresponding item inside the <TabList>.
 */
export const TabPanels = (props: TabPanelsProps) => {
  const { tabState, tabProps } = useContext(TabContext);
  const { tabListState } = tabState;

  const factory = useCallback((nodes: Iterable<Node<object>>) => new ListCollection(nodes), []);
  const collection = useCollection({ items: tabProps.items, ...props }, factory, {
    suppressTextValueWarning: true,
  });

  const selectedItem = tabListState ? collection.getItem(tabListState.selectedKey) : null;

  return (
    <InternalTabPanel
      {...props}
      key={tabListState?.selectedKey}
    >
      {selectedItem?.props.children}
    </InternalTabPanel>
  );
};
