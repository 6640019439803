import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Label, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface CorporateRiskProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const CorporateRiskView: FC<CorporateRiskProps> = (props) => {
  const { t } = useTranslation();

  const {
    activeCorporateRisk,
    handleActivateCorporateRisk,
    rowData,
    setIsEditing,
    modal,
    columnDefs,
    onCellValueChange,
  } = props;

  return (
    <div
      className="h-full flex-1"
      data-testid="CorporateRisk"
    >
      <h6>{t('main:manageMenu.manageCorporateRisk.tableTitle')}</h6>

      <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
        <ToggleInput
          checked={activeCorporateRisk}
          name={t('main:manageMenu.manageCostCenters.switchUseInCalculation')}
          onChange={handleActivateCorporateRisk}
        />
        {t('main:manageMenu.manageCostCenters.switchUseInCalculation')}
      </Label>

      <BaseEditTable
        columnDefs={columnDefs}
        isDisabled={!activeCorporateRisk}
        onCellValueChanged={onCellValueChange}
        rowData={rowData}
        setIsEditing={setIsEditing}
      />
      {modal}
    </div>
  );
};

export const CorporateRiskPage = bindControllers(CorporateRiskView, [useController]);
CorporateRiskPage.displayName = 'CorporateRiskPage';
