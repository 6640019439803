import type { griddy } from '../../query/src';

export enum CostUnitAllocationRowType {
  EXPENSE = 'EXPENSE',
  REVENUE = 'REVENUE',
}

export enum CostUnitAllocationBasisType {
  TOTAL = 'TOTAL',
  ACCOUNT_IDS = 'ACCOUNT_IDS',
  COST_TYPE_ACCOUNTS = 'COST_TYPE_ACCOUNTS',
}

export enum CostUnitAllocationMethod {
  MANUAL = 'MANUAL',
  TECHNICAL_DATA = 'TECHNICAL_DATA',
}

export interface CostUnitsToPercentage {
  costUnitShortName?: string;
  percentage?: number;
}

export interface CostUnitAllocationRow extends griddy.CostCenterToCostUnitDistribution {
  primaryCostCenterRank: number;
  primaryCostCenterLongName: string;
  selectedEntriesBasis?: string[] | number[];
  costUnit?: string;
  percentage?: number;
  allocationBasisId?: number; // identifies rows with the same allocation basis
  description?: string;
}

export type CostUnitAllocationTable = CostUnitAllocationRow[];
