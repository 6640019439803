import type { APIParams } from '../..';
import type { GetFileErpTypesQueryParams } from '../dice';
import { useGetFileErpTypes } from '../dice';

export interface UseCamsGetOneErpTypeProps extends APIParams<'yearId'> {
  clientId: GetFileErpTypesQueryParams['clientId'];
  year?: string;
  yearId: GetFileErpTypesQueryParams['yearId'];
}

export const useCamsGetOneErpType = ({ yearId, clientId, year }: UseCamsGetOneErpTypeProps) => {
  const { data: erpType, isPending } = useGetFileErpTypes(
    {
      queryParams: {
        clientId,
        calculationYear: Number(year),
        yearId,
      },
    },
    {
      enabled: Boolean(yearId) && Boolean(year),
    },
  );

  return {
    isLoading: isPending,
    erpType,
  };
};
