// https://material-table.com/#/docs/all-props and search for 'localization'
export const getMaterialTableLocalization = (t) => ({
  body: {
    addTooltip: t('common:materialTable.tooltipAdd'),
    deleteTooltip: t('common:materialTable.tooltipDelete'),
    editRow: {
      cancelTooltip: t('common:materialTable.tooltipCancel'),
      deleteText: t('common:materialTable.deleteText'),
      saveTooltip: t('common:materialTable.tooltipSave'),
    },
    editTooltip: t('common:materialTable.tooltipEdit'),
    emptyDataSourceMessage: t('common:materialTable.emptyDataSourceMessage'),
    filterRow: {
      filterTooltip: t('common:materialTable.tooltipFilter'),
    },
  },
  header: {
    actions: t('common:materialTable.actions'),
  },
  pagination: {
    firstAriaLabel: t('common:materialTable.tooltipFirstPage'),
    firstTooltip: t('common:materialTable.tooltipFirstPage'),
    labelDisplayedRows: t('common:materialTable.labelDisplayedRows'),
    labelRowsSelect: t('common:materialTable.labelRowsSelect'),
    lastAriaLabel: t('common:materialTable.tooltipLastPage'),
    lastTooltip: t('common:materialTable.tooltipLastPage'),
    nextAriaLabel: t('common:materialTable.tooltipNextPage'),
    nextTooltip: t('common:materialTable.tooltipNextPage'),
    previousAriaLabel: t('common:materialTable.tooltipPreviousPage'),
    previousTooltip: t('common:materialTable.tooltipPreviousPage'),
  },
  toolbar: {
    searchPlaceholder: t('common:materialTable.placeholderSearch'),
    searchTooltip: t('common:materialTable.tooltipSearch'),
  },
});
