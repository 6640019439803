import type { ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';

import type { APIParams, dice } from '@org/query';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';

import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId'>;
  selectedPlannedDirectEntryId?: string;
  selectedPlannedAccountEntry?: dice.PlannedAccountEntryDTO;
  gridRef: RefObject<AgGridReact>;
  currentMethodType: dice.PlannedDirectEntryDistributionConfigDTO['allocationMethod'];
  updateAndReplacePlannedCostRevenueDirectEntry: (
    updatedEntry: dice.CostUnitAllocationBasisConfigEntryDTO,
  ) => Promise<void>;
}

const defaultColDef = {
  cellClass: ({ node: { level } }) => (level === 0 ? 'bg-highlighted font-bold' : 'text-right'),
  checkboxSelection: false,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
} satisfies ColDef;

export const useController = ({
  apiParams,
  selectedPlannedDirectEntryId,
  selectedPlannedAccountEntry,
  updateAndReplacePlannedCostRevenueDirectEntry,
  gridRef,
}: ControllerProps) => {
  const { preselectedAllocations } = aggregated.useAllocationKeys(apiParams);

  const { onGridReady } = useAgGridData({
    agGridRef: gridRef,
    data: preselectedAllocations?.costCenters ?? [],
  });

  const { getPlannedDirectEntry } = aggregated.usePlannedCostRevenue(apiParams);

  const selectedPlannedDirectEntry = getPlannedDirectEntry({
    plannedAccountEntryId: selectedPlannedAccountEntry?.id ?? '',
    plannedCostTypeAccountEntryId: selectedPlannedDirectEntryId ?? '',
  });

  const handleOnSelectionChanged = async (
    allocationMapping: dice.AllocationDistributionConfigDTO['allocationMapping'],
  ) => {
    const updatedDirectEntry = {
      ...selectedPlannedDirectEntry,
      distributionConfig: {
        ...selectedPlannedDirectEntry?.distributionConfig,
        allocationMapping,
      },
    };

    await updateAndReplacePlannedCostRevenueDirectEntry(updatedDirectEntry);
  };

  const columnDefs = useColumns({
    costCenterDistributionMap:
      selectedPlannedDirectEntry?.distributionConfig?.allocationMapping ?? {},
    onSelectionChanged: handleOnSelectionChanged,
    rowData: preselectedAllocations?.costCenters ?? [],
  });

  return {
    columnDefs,
    defaultColDef,
    selectedPlannedAccountEntry,
    onGridReady,
    gridRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
