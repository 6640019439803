import * as reactQuery from '@tanstack/react-query';

import type { RaveContext } from './raveContext';
import type * as Fetcher from './raveFetcher';
import type * as Schemas from './raveSchemas';
import { useRaveContext } from './raveContext';
import { raveFetch } from './raveFetcher';

export interface GetTransactionsForAccountsQueryParams {
  accountIds: string[];
  yearId: string;
}

export type GetTransactionsForAccountsError = Fetcher.ErrorWrapper<undefined>;

export type GetTransactionsForAccountsResponse = Schemas.AccountTransaction[];

export type GetTransactionsForAccountsVariables = {
  body: Schemas.MasterConfiguration;
  queryParams: GetTransactionsForAccountsQueryParams;
} & RaveContext['fetcherOptions'];

export const fetchGetTransactionsAccounts = (
  variables: GetTransactionsForAccountsVariables,
  signal?: AbortSignal,
) =>
  raveFetch<
    GetTransactionsForAccountsResponse,
    GetTransactionsForAccountsError,
    Schemas.MasterConfiguration,
    {},
    GetTransactionsForAccountsQueryParams,
    {}
  >({ method: 'post', url: '/account', ...variables, signal });

export const useTransactionsAccounts = <TData = GetTransactionsForAccountsResponse>(
  variables: GetTransactionsForAccountsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTransactionsForAccountsResponse,
      GetTransactionsForAccountsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useRaveContext();
  return reactQuery.useQuery<
    GetTransactionsForAccountsResponse,
    GetTransactionsForAccountsError,
    TData
  >({
    queryFn: ({ signal }) =>
      fetchGetTransactionsAccounts({ ...fetcherOptions, ...variables }, signal),
    // @ts-expect-error -- Types are incorrect and need to be overridden
    queryKey: queryKeyFn({ operationId: 'getAccounts', path: '/account', variables }),
    ...options,
    ...queryOptions,
  });
};
