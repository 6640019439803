import { useMemo } from 'react';

import { griddy } from '@org/query';

import { FILE_TYPE } from '../constants';
import { getApiFileType } from '../helpers/infoMapping';

export const useLabels = (labelType: string, nrArray: string[], yearId: string) => {
  const { data: labels } = griddy.useGetAllLabelsByType(
    {
      queryParams: {
        fileType: getApiFileType(
          FILE_TYPE.LABELS,
        ) as griddy.GetAllLabelsByTypeQueryParams['fileType'],
        labelType: labelType as griddy.GetAllLabelsByTypeQueryParams['labelType'],
        yearId,
      },
    },
    {
      enabled: !!yearId,
      networkMode: 'offlineFirst',
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const resultMemo = useMemo(() => {
    if (labels) {
      const filteredLabels =
        nrArray.length > 0
          ? labels.filter((labelEntry) => nrArray.includes(labelEntry.number))
          : labels;

      return filteredLabels.reduce<Record<string, string>>((acc, labelEntry) => {
        acc[labelEntry.number] = labelEntry.description;
        return acc;
      }, {});
    }
    return {};
  }, [labels, nrArray]);

  return resultMemo;
};
