import type { AsyncTransactionsFlushed } from 'ag-grid-community';

import { uniqBy } from 'lodash';

import type { dice, erase } from '@org/query';

import type { Asset, TableData } from './types';

export interface OnReportModalAsyncTransactionsFlushedType {
  recalculate: (newConfig?: dice.MasterConfiguration) => Promise<void>;
  executeCloseModal: () => void;
  updateConfiguration: (newConfig: dice.MasterConfiguration) => Promise<unknown>;
  updateAssets: (updatedRows: erase.Asset[]) => Promise<void>;
  masterConfiguration?: dice.MasterConfiguration | undefined;
}

export const onReportModalAsyncTransactionsFlushed =
  ({
    recalculate,
    executeCloseModal,
    updateAssets,
    updateConfiguration,
    masterConfiguration,
  }: OnReportModalAsyncTransactionsFlushedType) =>
  async ({ results }: AsyncTransactionsFlushed) => {
    const savedAsyncTransactions = results
      .flatMap((asyncRowNodeTx) => asyncRowNodeTx.update)
      .map((rowNode) => rowNode?.data);
    // The most recent user change of a tx within data._updatedData; so we can just remove duplicates by the unique index
    const uniqueAsyncTransactions = uniqBy(savedAsyncTransactions, 'assetNumber').reduce<TableData>(
      (acc, curr) => {
        acc.data.push(curr);
        acc._updatedData.push(curr?._updatedData);
        return acc;
      },
      { _updatedData: [], data: [] },
    );

    let newConfig;

    const tableAssetsData = uniqueAsyncTransactions.data as Asset[];

    const updatedAssets = tableAssetsData.map(({ _updatedData: changes, ...other }) => ({
      ...other,
      ...changes,
    }));

    await updateAssets(updatedAssets);
    await recalculate(masterConfiguration);

    executeCloseModal();

    if (newConfig) {
      await updateConfiguration(newConfig);
      await recalculate(newConfig);
      executeCloseModal();
    }
  };
