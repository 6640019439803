import type { RowClassParams } from 'ag-grid-community';

import type { BaseEditTableProps } from '@org/ui';
import { BaseEditTable, LoaderWrapper } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const getRowClass = ({ node }: RowClassParams) =>
  (node.group ?? node.footer) ? 'bg-highlighted-important font-bold' : '';

interface BasicFeeTableProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const View = ({
  columnsDefs,
  rowData,
  isLoading,
  autoGroupColumnDef,
  defaultColDef,
  tableRef,
  onCellValueChanged,
  modal,
}: BasicFeeTableProps) => (
  <>
    <LoaderWrapper loading={isLoading}>
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnsDefs}
        defaultColDef={defaultColDef}
        enableGroupEdit
        getRowClass={getRowClass}
        groupDefaultExpanded={1}
        groupDisplayType="singleColumn"
        groupIncludeTotalFooter
        onCellValueChanged={onCellValueChanged}
        ref={tableRef}
        rowData={rowData}
        suppressAggFuncInHeader
      />
    </LoaderWrapper>
    {modal}
  </>
);

export const BasicFeeTable = bindControllers(View, [useController]);

BasicFeeTable.displayName = 'BasicFeeTable';
