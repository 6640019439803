const config = {
  APP_NAME: 'Gebühren- & Entgeltkalkulator',
  DEVR2: 'dev_r2',
  DICE_ASSETS_PATH: '/assets',
  EATER_CSV_PATH: '/csv',
  GRIDDY_ACCOUNTS_PLAN_DESCRIPTION_MAP_PATH: '/accountsplan/account_description_map',
  GRIDDY_ACCOUNTS_PLAN_PAGINATION_PATH: '/accountsplan/page',
  GRIDDY_ACCOUNTS_PLAN_PATH: '/accountsplan',
  GRIDDY_ACCOUNT_COST_CENTER_PATH: '/ledgeraccounts/account_costcenter_map',
  GRIDDY_ACCOUNT_ID_PATH: '/accountids',
  GRIDDY_ACCOUNT_JOB_ID_PATH: '/account_jobid_map',
  GRIDDY_CLIENTS_PATH: '/clients',
  GRIDDY_CLIENTS_SHARE_PATH: '/clients/share',
  GRIDDY_COST_CENTER_PATH: '/costcenters',
  GRIDDY_DATA_CONTAINER_PATH: '/datacontainers',
  GRIDDY_FLAT_PROFIT_CENTER_TREE_PATH: '/flat_profit_center_tree',
  GRIDDY_JOB_ID_PATH: '/jobids',
  GRIDDY_JOURNAL_PAGINATION_PATH: '/journal/page',
  GRIDDY_JOURNAL_PATH: '/journal',
  GRIDDY_LABELS_PAGINATION_PATH: '/labels/page',
  GRIDDY_LABELS_PATH: '/labels',
  GRIDDY_LABELS_TYPE_PATH: '/labels/type',
  GRIDDY_LEDGER_ACCOUNT_PAGINATION_PATH: '/ledgeraccounts/page',
  GRIDDY_LEDGER_ACCOUNT_PATH: '/ledgeraccounts',
  GRIDDY_MASTER_CONFIG_PATH: '/masterConfiguration',
  GRIDDY_OBJECTS_PATH: '/objects',
  GRIDDY_PROFIT_CENTER_PATH: '/profitcenters',
  GRIDDY_PROFIT_CENTER_TREES_PATH: '/profitcenter_trees',
  GRIDDY_USERS_PATH: '/users',
  GRIDDY_YEARS_PATH: '/years',
  INCA_INDEX_FACTOR_PATH: '/indexfactor',
  LOCAL: 'local',
  PROXY_ADMIN: '/admin/',
  PROXY_CURRENT_USER_DETAILS: '/current-user-details',
  PROXY_DICE_PATH: '/diceApi',
  PROXY_EATER_PATH: '/eaterApi',
  PROXY_ERASE_PATH: '/eraseApi',
  PROXY_GRIDDY_PATH: '/griddyApi',
  PROXY_INCA_PATH: '/incaApi',
  PROXY_PATH: '/api',
  PROXY_SAFE_PATH: '/safeApi',
  RAVE_ACCOUNT_IDS_PATH: '/ranges/',
  RAVE_ACCOUNT_TRANSACTIONS_PATH: '/account',
  RAVE_ALLOCATION_KEY_PATH: '/allocationkey/',
  RAVE_CALCULATION_PATH: '/calculation/',
  RAVE_COSTCENTER_PATH: '/costcenter/',
  RAVE_COSTUNIT_PATH: '/costunits/',
  RAVE_INTEREST_PATH: '/interest/calculation/',
  RAVE_REPORT_PATH: '/reports',
  RAVE_WITHDRAW_CAPITAL_PATH: '/withdraw/',
};

export default config;
