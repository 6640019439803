import { SelectOption } from 'appkit-react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { griddy } from '@org/query';
import { getAccountIdTextWithDescription } from '@org/utils';

import { BaseSelect } from '../BaseSelect';
import { StyledDescription } from './style/StyledDescription';

const renderSimpleSelectionOptions = (items, accountsPlanMap, getItemDescription) =>
  items.map((item, idx) => (
    <SelectOption
      aria-label={item}
      key={idx}
      value={item}
    >
      <div className="d-flex">
        {item.name || getAccountIdTextWithDescription(item, accountsPlanMap)}
        {item && getItemDescription && typeof getItemDescription === 'function' && (
          <StyledDescription
            title={getItemDescription(item)}
            width="80px"
          >
            {getItemDescription(item)}
          </StyledDescription>
        )}
      </div>
    </SelectOption>
  ));

const SelectComponent = ({
  value = [],
  defaultValue,
  items,
  onSelect,
  showSearchOnToggle,
  multiple,
  showSelectAll,
  placeholder,
  getItemDescription,
  disabled,
}) => {
  const { yearId = '' } = useParams();
  const { data: accountsPlanMap } = griddy.useGetAllAccountIdToAccountDescriptionMap({
    queryParams: {
      fileType: 'ACCOUNT_PLAN',
      yearId,
    },
  });

  return (
    <BaseSelect
      data-test="select"
      defaultValue={defaultValue ?? null}
      disabled={disabled}
      multiple={multiple}
      onSelect={onSelect}
      placeholder={placeholder}
      required
      showSearchOnToggle={showSearchOnToggle}
      showSelectAll={showSelectAll}
      value={value ?? null}
    >
      {renderSimpleSelectionOptions(items, accountsPlanMap, getItemDescription)}
    </BaseSelect>
  );
};

SelectComponent.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  getItemDescription: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  ).isRequired,
  multiple: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showSearchOnToggle: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
    PropTypes.number,
  ]),
};

SelectComponent.defaultProps = {
  placeholder: '',
  showSearchOnToggle: true,
  showSelectAll: true,
};

export default SelectComponent;
