import type { griddy } from '@org/query';

import type {
  AcctTrxSelectCriteriaItem,
  CostCenter,
  IgnoreMappings,
  IgnoreMappingsProps,
  IgnoreMappingValue,
  Job,
  ProfitCenter,
} from './types';

export function getOrCreateProfitCenter(
  profitCenterMap: Map<string, ProfitCenter>,
  { profitCenter }: AcctTrxSelectCriteriaItem,
  label?: string,
) {
  if (!profitCenterMap.has(profitCenter)) {
    profitCenterMap.set(profitCenter, {
      availableCostCenters: new Set(),
      availableJobs: new Set(),
      label,
      name: profitCenter,
      unavailableCostCenters: new Set(),
      unavailableJobs: new Set(),
    });
  }

  return profitCenterMap.get(profitCenter)!;
}

export function getOrCreateCostCenter(
  costCenterMap: Map<string, CostCenter>,
  { costCenter, profitCenter }: AcctTrxSelectCriteriaItem,
  costCenterName: string,
  label?: string,
) {
  if (!costCenterMap.has(costCenterName)) {
    costCenterMap.set(costCenterName, {
      availableJobs: new Set(),
      fullName: getCostCenterName(profitCenter, costCenter),
      label,
      name: costCenter,
      profitCenterName: profitCenter,
      unavailableJobs: new Set(),
    });
  }

  return costCenterMap.get(costCenterName)!;
}

export function isProfitCenter(item: ProfitCenter | CostCenter | Job): item is ProfitCenter {
  return Object.hasOwn(item, 'availableCostCenters');
}

export function isCostCenter(item: ProfitCenter | CostCenter | Job): item is CostCenter {
  return !isProfitCenter(item) && Object.hasOwn(item, 'fullName');
}

export function isJob(item: ProfitCenter | CostCenter | Job): item is Job {
  return !isProfitCenter(item) && !isCostCenter(item);
}

export function areProfitCentersIgnored({ ignoreMappings }: IgnoreMappingsProps) {
  return iterableToArray(ignoreMappings.get('')?.values()).some(
    ({ ignoreProfitCenter }) => ignoreProfitCenter,
  );
}

export function areCostCentersIgnored({
  ignoreMappings,
  selectedProfitCenterName = '',
}: IgnoreMappingsProps) {
  return Boolean(ignoreMappings.get(selectedProfitCenterName)?.get('')?.ignoreCostCenter);
}

export function areJobsIgnored({
  ignoreMappings,
  selectedProfitCenterName = '',
  selectedCostCenterName = '',
}: IgnoreMappingsProps) {
  return Boolean(
    ignoreMappings.get(selectedProfitCenterName)?.get(selectedCostCenterName)?.ignoreJobIds,
  );
}

export function getCostCenterName(profitCenter: string, costCenter: string) {
  return `${profitCenter}${costCenter}`;
}

export function formatName(
  { label = '', name = '' },
  ...conditionalFormatters: {
    condition?: boolean;
    formatter: (value: string) => string;
  }[]
) {
  let currentName = name;

  if (label) {
    currentName = `${name} ${label}`;
  }

  return conditionalFormatters.reduce((currentValue, { condition, formatter }) => {
    if (!condition) {
      return currentValue;
    }

    return formatter(currentValue);
  }, currentName);
}

export function conditionalFilter<T>(
  collection: T[],
  valueGetter: (item: T) => string,
  searchString = '',
) {
  if (searchString.trim() === '') {
    return collection;
  }

  return collection.filter((item) =>
    valueGetter(item).toLowerCase().includes(searchString.toLowerCase()),
  );
}

export function sort<T>(set = new Set<T>(), valueGetter: (item: T) => string) {
  return iterableToArray(set).sort((a, b) => {
    const aValue = valueGetter(a);
    const bValue = valueGetter(b);

    return aValue.localeCompare(bValue);
  });
}

export function iterableToArray<T>(iterable: Iterable<T> = new Set()) {
  return [...iterable];
}

function addRootIgnoreMapping(ignoreMappings: IgnoreMappings) {
  if (!ignoreMappings.has('')) {
    ignoreMappings.set('', new Map());
  }

  if (!ignoreMappings.get('')?.has('')) {
    ignoreMappings.get('')?.set('', {
      ignoreCostCenter: false,
      ignoreJobIds: false,
      ignoreProfitCenter: true,
    });
  }

  ignoreMappings.get('')?.set('', {
    ignoreCostCenter: false,
    ignoreJobIds: false,
    ...ignoreMappings.get('')?.get(''),
    ignoreProfitCenter: true,
  });
}

export function serializeMappingObject(inObject?: griddy.CostCenterIgnoreMapping[]) {
  const computedIgnoreMappings: IgnoreMappings = new Map();

  inObject?.forEach(
    ({
      profitCenterId = '',
      costCenterId = '',
      ignoreCostCenter = false,
      ignoreJobIds = false,
    }) => {
      computedIgnoreMappings.set(
        profitCenterId,
        computedIgnoreMappings.get(profitCenterId) ?? new Map(),
      );

      computedIgnoreMappings.get(profitCenterId)?.set(costCenterId, {
        ignoreCostCenter,
        ignoreJobIds,
        // Do not set ignore profit center
        ignoreProfitCenter: false,
      });
    },
  );

  if (inObject?.some(({ ignoreProfitCenter }) => ignoreProfitCenter)) {
    addRootIgnoreMapping(computedIgnoreMappings);
  }

  return computedIgnoreMappings;
}

export function cleanupEmptyIgnoreMappings(currentIgnoreMapping: IgnoreMappings) {
  iterableToArray(currentIgnoreMapping.entries()).forEach(([profitCenterId, costCenters]) => {
    iterableToArray(costCenters).forEach(
      ([costCenter, { ignoreCostCenter, ignoreJobIds, ignoreProfitCenter }]) => {
        if (!ignoreCostCenter && !ignoreJobIds && !ignoreProfitCenter) {
          currentIgnoreMapping.get(profitCenterId)?.delete(costCenter);
        }
      },
    );
    if (currentIgnoreMapping.get(profitCenterId)?.size === 0) {
      currentIgnoreMapping.delete(profitCenterId);
    }
  });
}

export function isValidCostCenterMapping(
  currentIgnoreMapping: IgnoreMappings,
  checkedJobs: Map<string, griddy.MappingKey>,
) {
  return iterableToArray(currentIgnoreMapping.entries()).every(([profitCenterId, costCenters]) =>
    iterableToArray(costCenters.entries()).every(([costCenterId]) =>
      iterableToArray(checkedJobs.values()).some(
        ({ costCenter, profitCenter }) =>
          (profitCenterId === '' || profitCenterId === profitCenter) &&
          (costCenterId === '' || costCenterId === costCenter),
      ),
    ),
  );
}

export function deserializeMappingObject(inObject: IgnoreMappings) {
  const ignoreMappingsObject: griddy.CostCenterIgnoreMapping[] = [];

  const ignoreProfitCenter = areProfitCentersIgnored({ ignoreMappings: inObject });

  iterableToArray(inObject.entries()).forEach(([profitCenterId, costCenterMap]) => {
    iterableToArray(costCenterMap.entries()).forEach(([costCenterId, value]) => {
      ignoreMappingsObject.push({
        costCenterId,
        profitCenterId,
        ...value,
        ignoreProfitCenter,
      });
    });
  });

  return ignoreMappingsObject;
}

export function buildSelector(selectedJobs: Map<string, griddy.MappingKey>) {
  return ({ id }: Job) => selectedJobs.has(id);
}

export function updateCheckedJobs(
  newCheckedJobs: Map<string, griddy.MappingKey | griddy.CostUnitMappingKey>,
  add: boolean,
  ...jobs: Job[]
) {
  jobs.forEach(({ id, profitCenterName, costCenter, name, accountId }) => {
    if (add) {
      newCheckedJobs.set(id, {
        costCenter,
        id,
        jobId: name,
        profitCenter: profitCenterName,
        // Only add account id if defined
        ...(accountId ? { accountId } : undefined),
      });
    } else {
      newCheckedJobs.delete(id);
    }
  });
  return newCheckedJobs;
}

export function updateIgnoreMapping(
  newIgnoreMappings: IgnoreMappings,
  profitCenterName: string,
  costCenterName: string,
  diff: Partial<IgnoreMappingValue>,
) {
  if (!newIgnoreMappings.has(profitCenterName)) {
    newIgnoreMappings.set(profitCenterName, new Map());
  }

  if (!newIgnoreMappings.get(profitCenterName)?.has(costCenterName)) {
    newIgnoreMappings.get(profitCenterName)?.set(costCenterName, {
      ignoreCostCenter: false,
      ignoreJobIds: false,
      ignoreProfitCenter: false,
    });
  }

  newIgnoreMappings.get(profitCenterName)?.set(costCenterName, {
    ignoreCostCenter: false,
    ignoreJobIds: false,
    ignoreProfitCenter: false,
    ...newIgnoreMappings.get(profitCenterName)?.get(costCenterName),
    ...diff,
  });
}

export function getCorrectCostCenters(
  availableProfitCenters?: Map<string, ProfitCenter>,
  unavailableProfitCenters?: Map<string, ProfitCenter>,
  ignoreProfitCenters?: boolean,
  selectedProfitCenterName = '',
) {
  if (ignoreProfitCenters) {
    return {
      availableCostCenters: new Set(
        iterableToArray(availableProfitCenters?.values()).flatMap(({ availableCostCenters }) =>
          iterableToArray(availableCostCenters),
        ),
      ),
      unavailableCostCenters: new Set([
        ...iterableToArray(availableProfitCenters?.values()).flatMap(({ unavailableCostCenters }) =>
          iterableToArray(unavailableCostCenters),
        ),
        ...iterableToArray(unavailableProfitCenters?.values()).flatMap(
          ({ unavailableCostCenters }) => iterableToArray(unavailableCostCenters),
        ),
      ]),
    };
  }
  if (selectedProfitCenterName !== '') {
    return availableProfitCenters?.get(selectedProfitCenterName) ?? {};
  }
  return {};
}

export function getCorrectJobs(
  availableCostCenters?: Set<CostCenter>,
  unavailableCostCenters?: Set<CostCenter>,
  costCenterMap?: Map<string, CostCenter>,
  ignoreCostCenters?: boolean,
  selectedCostCenterName = '',
) {
  if (ignoreCostCenters) {
    return {
      availableJobs: new Set(
        iterableToArray(availableCostCenters?.values()).flatMap(({ availableJobs }) =>
          iterableToArray(availableJobs),
        ),
      ),
      unavailableJobs: new Set([
        ...iterableToArray(availableCostCenters?.values()).flatMap(({ unavailableJobs }) =>
          iterableToArray(unavailableJobs),
        ),
        ...iterableToArray(unavailableCostCenters?.values()).flatMap(({ unavailableJobs }) =>
          iterableToArray(unavailableJobs),
        ),
      ]),
    };
  }

  if (selectedCostCenterName !== '') {
    return costCenterMap?.get(selectedCostCenterName) ?? {};
  }

  return {};
}

export const mappingKeyMapToArray = (mapObject: Map<string, griddy.MappingKey>) =>
  Array.from(mapObject, ([_, { costCenter, jobId, profitCenter, id, ...rest }]) => ({
    costCenter,
    id,
    jobId,
    profitCenter,
    ...rest,
  }));
