import Papa from 'papaparse';

export const parseCSV = (file: File) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete(results) {
        resolve(results.data);
      },
      dynamicTyping: true,
      encoding: 'utf8',
      error(err) {
        reject(err);
      },
      header: true,
      skipEmptyLines: true,
    });
  });
