import { useCallback, useEffect, useRef } from 'react';

import type { CorrectAny } from '@org/models';

export const useDebounce = <T extends (...args: CorrectAny) => void>(
  toDebounce: T,
  wait: number,
): T => {
  const timeoutReference = useRef<number | null>(null);

  useEffect(
    () => () => {
      // Clear timeout on unmount
      if (timeoutReference.current) {
        clearTimeout(timeoutReference.current);
      }
    },
    [],
  );

  const fn = useCallback(
    (...args: CorrectAny) => {
      if (timeoutReference.current) {
        clearTimeout(timeoutReference.current);
      }

      timeoutReference.current = setTimeout(() => {
        timeoutReference.current = null;
        toDebounce(...args);
      }, wait) as unknown as number;
    },
    [toDebounce, wait],
  );

  return fn as T;
};
