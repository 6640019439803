import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';

import { PressResponder } from '@react-aria/interactions';
import { filterDOMProps, useObjectRef } from '@react-aria/utils';

export interface FileTriggerProps {
  /**
   * Specifies what mime type of files are allowed.
   */
  acceptedFileTypes?: string[];
  /**
   * Whether multiple files can be selected.
   */
  allowsMultiple?: boolean;
  /**
   * Specifies the use of a media capture mechanism to capture the media on the spot.
   */
  defaultCamera?: 'user' | 'environment';
  /**
   * Handler when a user selects a file.
   */
  onSelect?: (files: FileList | null) => void;
  /**
   * The children of the component.
   */
  children?: ReactNode;
  /**
   * Enables the selection of directories instead of individual files.
   */
  acceptDirectory?: boolean;
}

export const FileTrigger = forwardRef(
  (inProps: FileTriggerProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      onSelect,
      acceptedFileTypes,
      allowsMultiple,
      defaultCamera,
      children,
      acceptDirectory,
      ...rest
    } = inProps;

    const inputRef = useObjectRef(ref);
    const domProps = filterDOMProps(rest);

    return (
      <>
        <PressResponder
          onPress={() => {
            if (inputRef.current?.value) {
              inputRef.current.value = '';
            }
            inputRef.current?.click();
          }}
        >
          {children}
        </PressResponder>
        <input
          {...domProps}
          accept={acceptedFileTypes?.toString()}
          capture={defaultCamera}
          multiple={allowsMultiple}
          onChange={(e) => onSelect?.(e.target.files)}
          ref={inputRef}
          style={{ display: 'none' }}
          type="file"
          // @ts-expect-error new prop issue
          webkitdirectory={acceptDirectory ? '' : undefined}
        />
      </>
    );
  },
);

export { Pressable } from '@react-aria/interactions';
