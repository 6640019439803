import type React from 'react';

import { useDisclosure } from '@nextui-org/react';

import { useSearchNames } from '@org/hooks';
import { aggregated } from '@org/query';

export interface UseControllerProps {
  clientId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LinkComponent: React.ComponentType<any>;
  getLinkProps: (clientId: string) => Record<string, unknown>;
}

export const useController = ({ LinkComponent, getLinkProps, clientId }: UseControllerProps) => {
  const { publicFacilities, createPublicFacility, updatePublicFacility, deletePublicFacility } =
    aggregated.usePublicFacilities({
      clientId,
    });

  const disclosureState = useDisclosure();

  const { searchedItems, control, handleSearch } = useSearchNames({
    data: publicFacilities,
    fieldName: 'name',
  });

  const facilitiesToBeShown = searchedItems ?? publicFacilities;

  const sortedPublicFacilities =
    facilitiesToBeShown?.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    }) ?? [];

  return {
    LinkComponent,
    createPublicFacility,
    deletePublicFacility,
    disclosureState,
    getLinkProps,
    sortedPublicFacilities,
    updatePublicFacility,
    control,
    handleSearch,
    searchedItems,
  };
};

export type ControllerType = ReturnType<typeof useController>;
