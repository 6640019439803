import { formatNumberToLocaleString } from '@org/utils';
import { formatToSimpleDate } from '@org/utils/date';

import { objGet } from './object';

export const CURRENCY_FIELDS = [
  'betrag',
  'restbuchwert_beginn',
  'restbuchwert_ende',
  'indexfaktor',
  'anschaffungswert_ende',
  'anlage_afa',
];

export const LABEL_FILE_ROW_TYPES = {
  ANLAGEN_KLASSE: {
    description: 'Anlagenklasse',
    key: 'ANLAGEN_KLASSE',
  },
  AUFTRAG: {
    description: 'Auftrag',
    key: 'AUFTRAG',
  },
  BEWERTUNGSBEREICH: {
    description: 'Bewertungsbereich',
    key: 'BEWERTUNGSBEREICH',
  },
  KST: {
    description: 'Kostenstelle',
    key: 'KST',
  },
  KST_BEREICH: {
    description: 'Kostenstellen Bereich',
    key: 'KST_BEREICH',
  },
  PROFITCENTER: {
    description: 'Profit Center',
    key: 'PROFITCENTER',
  },
  PROFITCENTER_BEREICH: {
    description: 'Profit Center Bereich',
    key: 'PROFITCENTER_BEREICH',
  },
  PROJEKT: {
    description: 'Projekt',
    key: 'PROJEKT',
  },
};

export const FILE_CONFIGURATION = {
  assets: {
    inputFields: [
      {
        description: 'Eindeutige Nummer des Vermögensgegenstands',
        inputName: 'anlagennummer',
        internalName: 'assetNumberOriginal',
        type: 'text',
      },
      {
        description: 'Bezeichnung des Vermögensgegenstands',
        editable: true,
        inputName: 'anlagenbezeichnung',
        internalName: 'assetDescription',
        optional: true,
        type: 'text',
      },
      {
        description: 'Nummer der Anlagenklasse',
        editable: true,
        inputName: 'anlagen_klasse_nr',
        internalName: 'assetCategory',
        type: 'text',
      },
      {
        description: 'Unternummer der Anlage',
        editable: true,
        inputName: 'anlagen_unr',
        internalName: 'assetAccount',
        optional: true,
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Restbuchwert des Vermögensgegenstands zum Anfang des Wirtschaftsjahres',
        inputName: 'restbuchwert_beginn',
        internalName: 'rbvCapitalAssetsBegin',
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Restbuchwert des Vermögensgegenstands zum Ende des Wirtschaftsjahres',
        inputName: 'restbuchwert_ende',
        internalName: 'rbvCapitalAssetsEnd',
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Anschaffungsdatum',
        inputName: 'anschaffungsdatum',
        internalName: 'purchasingDate',
        type: 'date',
        valueFormatter: (params) =>
          params?.data?.purchasingDate
            ? formatToSimpleDate(new Date(params.data.purchasingDate))
            : '',
      },
      {
        description: 'Nutzungsdauer in Monaten',
        inputName: 'nutzungsdauer_in_monaten',
        internalName: 'lifeTime',
        optional: true,
        type: 'number',
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Anschaffungswert Ende',
        inputName: 'anschaffungswert_ende',
        internalName: 'acquisitionValueEnd',
        optional: true,
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        description: 'Kostenstelle der Anlage',
        editable: true,
        inputName: 'anlage_kostenstelle_nr',
        internalName: 'clientsCostCenterName',
        optional: true,
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Abschreibung der Anlage',
        inputName: 'anlage_afa',
        internalName: 'assetDepreciation',
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'AfA Zugang des Jahres',
        inputName: 'afa_zugang_HGB',
        internalName: 'depreciationHGB',
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        description: 'Data Source',
        inputName: 'dataSource',
        internalName: 'Data source',
        type: 'text',
      },
    ],
    name: 'assets.txt',
  },
  journal: {
    inputFields: [
      {
        description: 'Eindeutige Buchungsnummer (Buchungs ID)',
        inputName: 'index',
        internalName: 'index',
        type: 'text',
      },
      {
        description: 'Kurzbeschreibung zur Buchung',
        editable: true,
        inputName: 'buchungstext',
        internalName: 'description',
        optional: true,
        type: 'text',
      },
      {
        description: 'Kontonummer des Kontos',
        inputName: 'konto_nr',
        internalName: 'accountId',
        type: 'number',
      },

      {
        description: 'Typ der Transaktion',
        inputName: 'soll_haben',
        internalName: 'transactionType',
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Betrag der Buchung',
        inputName: 'betrag',
        internalName: 'transactionAmount',
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        description: 'Nummer der Kostenstelle auf die der Aufwand oder der Ertrag gebucht wurden',
        editable: true,
        inputName: 'kostenstelle_nr',
        internalName: 'clientsCostCenterName',
        optional: true,
        type: 'text',
      },
      {
        description: 'Nummer des Auftrags',
        editable: true,
        inputName: 'auftrag_nr',
        internalName: 'jobId',
        optional: true,
        type: 'text',
      },
      {
        description: 'Nummer des Profit Centers',
        editable: true,
        inputName: 'profitcenter_nr',
        internalName: 'profitCenterId',
        optional: true,
        type: 'text',
      },
      {
        description: 'Data Source',
        inputName: 'dataSource',
        internalName: 'Data source',
        type: 'text',
      },
    ],
    name: 'journal.txt',
  },
  kontenplan: {
    inputFields: [
      {
        description: 'Nummer eines Sachkontos',
        editable: true,
        inputName: 'konto_nr',
        internalName: 'accountId',
        type: 'number',
      },
      {
        description: 'Bezeichnung eines Sachkontos',
        editable: true,
        inputName: 'konto_bez',
        internalName: 'accountDescription',
        optional: true,
        type: 'text',
      },
      {
        description: 'Data Source',
        inputName: 'dataSource',
        internalName: 'Data source',
        type: 'text',
      },
    ],
    name: 'kontenplan.txt',
  },
  labels: {
    inputFields: [
      {
        description:
          'Refers to either a cost center name (kostenstelle_nr in Journal.csv) or a job id (auftrag_nr in Journal.csv)',
        inputName: 'nr',
        internalName: 'number',
        type: 'text',
      },
      {
        description: `Identifies the type of this row with following values: ${Object.values(
          LABEL_FILE_ROW_TYPES,
        )
          .map((type) => `${type.key} (${type.description})`)
          .join(', ')}`,
        inputName: 'typ',
        internalName: 'type',
        type: 'text',
      },
      {
        description: 'Description text',
        inputName: 'bez',
        internalName: 'description',
        optional: true,
        type: 'text',
      },
      {
        description: 'Data Source',
        inputName: 'dataSource',
        internalName: 'Data source',
        type: 'text',
      },
    ],
    name: 'labels.txt',
  },
  sachkonten: {
    inputFields: [
      {
        description: 'Eindeutige Abzugkapital ID/ Abzugkapitalnummer',
        editable: true,
        inputName: 'anlagen_nr',
        internalName: 'assetNumber',
        optional: true,
        type: 'number',
      },
      {
        description: 'Abzugkapital-Unternummer',
        editable: true,
        inputName: 'anlagen_unr',
        internalName: 'assetAccount',
        optional: true,
        type: 'number',
      },
      {
        description: 'Bezeichnung des Abzugkapitals',
        editable: true,
        inputName: 'anlagen_bez',
        internalName: 'assetDescription',
        optional: true,
        type: 'text',
      },
      {
        description: 'Kürzel der Abzugskapitalklasse/-gruppe',
        editable: true,
        inputName: 'klasse_nr',
        internalName: 'assetCategory',
        optional: true,
        type: 'text',
      },
      {
        description: 'Nummer des Sachkontos',
        editable: true,
        inputName: 'konto_nr',
        internalName: 'accountId',
        optional: false,
        type: 'number',
      },
      {
        description: 'Nummer der Kostenstelle',
        editable: true,
        inputName: 'kst_nr',
        internalName: 'clientsCostCenterName',
        optional: true,
        type: 'text',
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Buchwert zum 01.01. des Jahres',
        editable: true,
        inputName: 'bw_anfang',
        internalName: 'rbvCapitalAssetsBegin',
        optional: false,
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        cellStyle: { textAlign: 'right' },
        description: 'Buchwert zum 31.12. des Jahres',
        editable: true,
        inputName: 'bw_ende',
        internalName: 'rbvCapitalAssetsEnd',
        optional: false,
        type: 'number',
        valueFormatter: ({ value }) => formatNumberToLocaleString(value),
      },
      {
        description: 'Data Source',
        inputName: 'dataSource',
        internalName: 'Data source',
        type: 'text',
      },
    ],
    name: 'sachkonten.txt',
  },
};

export const FILE_TYPE = {
  ACCOUNTS_PLAN: {
    apiType: 'ACCOUNT_PLAN',
    nameKey: 'common:files.accountPlan',
    type: 'kontenplan',
  },
  ASSETS: {
    apiType: 'ASSETS',
    nameKey: 'common:files.assets',
    type: 'assets',
    uniqueIds: ['anlagennummer', 'anlagen_unr'],
  },
  JOURNAL: {
    apiType: 'JOURNAL',
    nameKey: 'common:files.journal',
    type: 'journal',
    uniqueIds: ['index'],
  },
  LABELS: { apiType: 'LABELS', nameKey: 'common:files.labels', type: 'labels' },
  LEDGER_ACCOUNT: {
    apiType: 'LEDGER_ACCOUNT',
    nameKey: 'common:files.generalAccounts',
    type: 'sachkonten',
  },
};
export const FILE_TYPES_COUNT = Object.keys(FILE_TYPE).length;

export const getFileTypeWithTypeOrApiType = (typeOrApiType) => {
  for (const fileType of Object.values(FILE_TYPE)) {
    if (isFileType(typeOrApiType, fileType)) {
      return fileType;
    }
  }

  return undefined;
};

export const getApiFileType = (fileType) =>
  fileType.apiType ?? getFileTypeWithTypeOrApiType(fileType)?.apiType;

export const getFileType = (fileType) =>
  fileType.type ?? getFileTypeWithTypeOrApiType(fileType)?.type;

export const isFileType = (typeOrApiType = '', fileType) =>
  typeOrApiType.toUpperCase() === fileType.type.toUpperCase() ||
  typeOrApiType.toUpperCase() === fileType.apiType.toUpperCase();

export const isAnyOfFileTypes = (typeOrApiType = '', fileTypes) =>
  fileTypes.some((fileType) => isFileType(typeOrApiType, fileType));

const getMaterialTableColumns = (fileConfiguration) =>
  fileConfiguration?.inputFields?.map((inputField) => ({
    cellStyle: inputField.cellStyle,
    defaultSort: 'asc',
    editable: inputField.editable ? 'always' : 'never',
    field: inputField.internalName,
    title: inputField.inputName,
    valueFormatter: inputField.valueFormatter ?? null,
    width: 'auto',
  }));

export const getColumnsForInputFile = (type) =>
  objGet(FILE_CONFIGURATION, type) ? getMaterialTableColumns(objGet(FILE_CONFIGURATION, type)) : [];

export const VALID_INPUT_FIELDS_INDEX_FACTOR = {
  indexfaktor: 'indexFactor',
  indexname: 'indexName',
  indexreihe: 'indexRow',
  jahr: 'year',
};

export const interestTableEditableRowIds = [
  'RBV_CAPITAL_OPERATING_ASSET_BEGIN',
  'RBV_CAPITAL_OPERATING_ASSET_END',
  'RBV_CAPITAL_NON_OPERATING_ASSET_BEGIN',
  'RBV_CAPITAL_NON_OPERATING_ASSET_END',
  'RBV_CAPITAL_LEDGER_ACCOUNT_BEGIN',
  'RBV_CAPITAL_LEDGER_ACCOUNT_END',
];

export const interestTableFieldNames = {
  RBV_CAPITAL_LEDGER_ACCOUNT_AVG:
    'main:reportPanels.interestTable.tableRows.rbvCapitalLedgerAccountAvg',
  RBV_CAPITAL_LEDGER_ACCOUNT_BEGIN:
    'main:reportPanels.interestTable.tableRows.rbvCapitalLedgerAccountBegin',
  RBV_CAPITAL_LEDGER_ACCOUNT_END:
    'main:reportPanels.interestTable.tableRows.rbvCapitalLedgerAccountEnd',
  RBV_CAPITAL_NON_OPERATING_ASSET_AVG:
    'main:reportPanels.interestTable.tableRows.avgRBVOfNNOAssets',
  RBV_CAPITAL_NON_OPERATING_ASSET_BEGIN:
    'main:reportPanels.interestTable.tableRows.sumRBVOfNNOAssetsBegin',
  RBV_CAPITAL_NON_OPERATING_ASSET_END:
    'main:reportPanels.interestTable.tableRows.sumRBVOfNNOAssetsEnd',
  RBV_CAPITAL_OPERATING_ASSET_AVG:
    'main:reportPanels.interestTable.tableRows.avgRBVOfNecessaryOperatingAssets',
  RBV_CAPITAL_OPERATING_ASSET_BEGIN:
    'main:reportPanels.interestTable.tableRows.sumRBVOfNecessaryOperatingAssetsBegin',
  RBV_CAPITAL_OPERATING_ASSET_END:
    'main:reportPanels.interestTable.tableRows.sumRBVOfNecessaryOperatingAssetsEnd',
};
