import { Select, SelectOption } from 'appkit-react';
import Flag from 'react-world-flags';
import styled from 'styled-components';

import { useTranslation } from '@org/locales';

const LANGUAGES = [
  { iconKey: 'de', isoLangKey: 'de', key: 'common:german' },
  { iconKey: 'usa', isoLangKey: 'en', key: 'common:english' },
];

const StyledLanguageMenu = styled.div`
  .a-select-wrapper {
    .lang-icon {
      height: 16px;
      margin-right: 10px;
    }

    .select-toggle {
      height: 34px;
    }

    .a-select-list-scroll-wrap,
    .a-select-list-out {
      max-width: 160px;
    }

    .a-dropdown .select-toggle .select-toggle-text-wrapper {
      margin: 0 8px;
    }
  }

  .a-select-wrapper:focus .select-toggle {
    outline: none;
    box-shadow: none;
  }
  .a-select-wrapper:focus {
    border: 0.0625rem solid #dedede;
  }
`;

function LanguageMenu() {
  const { i18n, t } = useTranslation();

  return (
    <StyledLanguageMenu data-test="language-menu">
      <Select
        onSelect={i18n.changeLanguage}
        value={i18n ? i18n.language : ''}
      >
        {LANGUAGES.map((lang, idx) => (
          <SelectOption
            key={idx}
            value={lang.isoLangKey}
          >
            <Flag
              className="lang-icon"
              code={lang.iconKey}
              height="16"
            />
            <span>{t(lang.key)}</span>
          </SelectOption>
        ))}
      </Select>
    </StyledLanguageMenu>
  );
}

export default LanguageMenu;
