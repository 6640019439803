import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const { gridRef, rowData, defaultColDef, columnDefs, getRowId } = props;

  return (
    <BaseEditTable
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      ref={gridRef}
      rowData={rowData}
      rowSelection="multiple"
    />
  );
};

export const CostCenterTable = bindControllers(View, [useController]);
CostCenterTable.displayName = 'CostCenterTable';
