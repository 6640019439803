import { useState } from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import { useTranslation } from '@org/locales';
import { getAccountIdDescription, NOT_AVAILABLE } from '@org/utils';

import { objSet } from '../../../../utils';
import { isEmptyOrNullObj } from '../../../../utils/formatUtils';
import ErrorIcon from '../../../common/ErrorIcon';
import { CostCenterRow } from './CostCenterRow';
import { ManualAllocationRow } from './ManualAllocationRow';

const StyledAccountRow = styled.div`
  margin-top: 5px;
  display: flex;
  width: 100%;

  .account-id {
    display: flex;
    border-right: 1px solid lightgray;
    width: 40vw;
    align-items: start;
    font-size: var(--font-size-primary);
  }

  .allocation {
    flex-grow: 1;
    padding-left: 8px;
    font-size: var(--font-size-primary);
  }

  .deselect-button {
    cursor: pointer;
    font-size: 18px;
    border: none;
  }

  .alert-icon {
    font-size: 18px;
    margin: 0 8px;
  }
`;

export const AccountRow = ({
  accountId,
  costCenterToAllocationMap,
  updateAccountMap,
  costCenterMapping,
  selectedConfig,
  accountsPlanMap,
  deselectAccount,
}) => {
  const [expandContent, setExpandContent] = useState(false);
  const onToggleExpand = () => {
    setExpandContent(!expandContent);
  };
  const { t } = useTranslation();

  const manualCostCenterSelection = Object.keys(costCenterToAllocationMap).includes(NOT_AVAILABLE);

  const hasNotAssignedCostCenters = Object.entries(costCenterToAllocationMap).some(
    ([, allocationMap]) => isEmptyOrNullObj(allocationMap),
  );

  const updateCostCenterMap = (costCenter, newAllocationMap) => {
    const newCostCenterToAllocationMap = structuredClone(costCenterToAllocationMap);
    objSet(newCostCenterToAllocationMap, costCenter, newAllocationMap);
    updateAccountMap(accountId, newCostCenterToAllocationMap);
  };

  return (
    <StyledAccountRow>
      <div className="account-id">
        <span
          className="appkiticon a-text-red icon-circle-delete-outline deselect-button a-mr-10"
          onClick={() => deselectAccount(accountId)}
        />
        <span className="item-name">{accountId}</span>
        <span className={`a-ml-5 a-mr-5 ${expandContent ? '' : 'text-cut'}`}>
          {getAccountIdDescription(accountId, accountsPlanMap)}
        </span>
      </div>
      <div className="allocation">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <span className="a-mr-5">
              {manualCostCenterSelection
                ? t('main:manageMenu.manageWithdrawCapital.labelManualAllocation')
                : t('main:manageMenu.manageWithdrawCapital.labelAutomaticAllocation')}
            </span>

            {hasNotAssignedCostCenters ? (
              <ErrorIcon
                tooltipText={
                  manualCostCenterSelection
                    ? t(
                        'main:manageMenu.manageWithdrawCapital.alerts.warning.manualAllocationMissingAtAccount',
                      )
                    : t(
                        'main:manageMenu.manageWithdrawCapital.alerts.warning.someCCNotAssignedAtAccount',
                      )
                }
                type="warning"
              />
            ) : (
              <span className="appkiticon alert-icon a-text-green icon-circle-checkmark-outline" />
            )}
          </div>

          {!isEmptyOrNullObj(costCenterToAllocationMap) && (
            <span
              className={`appkiticon a-cursor-pointer icon-${
                expandContent ? 'down' : 'right'
              }-chevron-fill `}
              data-test="upload-info-chevron"
              onClick={onToggleExpand}
            />
          )}
        </div>
        <div className="a-pl-5">
          {expandContent &&
            Object.entries(costCenterToAllocationMap).map(
              ([costCenter, allocationMap], index) =>
                costCenter !== NOT_AVAILABLE && (
                  <div
                    className="d-flex"
                    key={index}
                  >
                    <CostCenterRow
                      allocationMap={allocationMap}
                      costCenter={costCenter}
                      selectedConfig={selectedConfig}
                      updateCostCenterMap={updateCostCenterMap}
                    />
                  </div>
                ),
            )}
          {expandContent &&
            Object.entries(costCenterToAllocationMap).map(
              ([costCenter, allocationMap], index) =>
                costCenter === NOT_AVAILABLE && (
                  <div
                    className="d-flex"
                    key={index}
                  >
                    <ManualAllocationRow
                      allocationMap={allocationMap}
                      costCenter={costCenter}
                      costCenterMapping={costCenterMapping}
                      updateCostCenterMap={updateCostCenterMap}
                    />
                  </div>
                ),
            )}
        </div>
      </div>
    </StyledAccountRow>
  );
};
