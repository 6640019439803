import { createContext, useContext, useState } from 'react';

const UIStateContext = createContext();

function UIStateProvider(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <UIStateContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
      {...props}
    />
  );
}

const useUIContext = () => useContext(UIStateContext);

export { UIStateProvider, useUIContext };
