import { useParams } from 'react-router-dom';

import { InterestRatesPage as Page } from '@org/pages';

interface InterestRatesProps {
  disableEditing: boolean;
}

export const InterestRates = ({ disableEditing }: InterestRatesProps) => {
  const {
    configId: masterConfigurationId = '',
    yearId = '',
    publicFacilityId = '',
    clientId = '',
    type = '',
  } = useParams<{
    clientId: string;
    yearId: string;
    configId: string;
    publicFacilityId: string;
    type: string;
  }>();

  return (
    <Page
      clientId={clientId}
      disableEditing={disableEditing}
      masterConfigurationId={masterConfigurationId}
      publicFacilityId={publicFacilityId}
      type={type}
      yearId={yearId}
    />
  );
};
