import i18next from 'i18next';
import i18nBrowserLangDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from '@org/locales';
import commonDe from '@org/locales/languages/de/common.json';
import mainDe from '@org/locales/languages/de/main.json';
import commonEn from '@org/locales/languages/en/common.json';
import mainEn from '@org/locales/languages/en/main.json';

import { resolvePathInObj } from './object';

export const YEAR_CALCULATION_TYPES = {
  NK: {
    label: 'common:postCalculation',
    shortcut: 'common:postCalculationShortcut',
  },
  VK: {
    label: 'common:preCalculation',
    shortcut: 'common:preCalculationShortcut',
  },
};

const LOCALIZED_SETTINGS = {
  de: {
    decimalSeparator: ',',
    thousandSeparator: '.',
  },
  en: {
    decimalSeparator: '.',
    thousandSeparator: ',',
  },
};

const getLocalizedSettingValue = (i18n, path) =>
  resolvePathInObj(`${i18n && i18n.language ? i18n.language : 'en'}.${path}`, LOCALIZED_SETTINGS);

export const getLocalizedThousandSeparator = (i18n) =>
  getLocalizedSettingValue(i18n, 'thousandSeparator');
export const getLocalizedDecimalSeparator = (i18n) =>
  getLocalizedSettingValue(i18n, 'decimalSeparator');

export const getLocalizedYearType = (t, year, getShortcut) => {
  if (!year) {
    return '';
  }

  if (getShortcut) {
    return year.isPreCalculation
      ? t(YEAR_CALCULATION_TYPES.VK.shortcut)
      : t(YEAR_CALCULATION_TYPES.NK.shortcut);
  }

  return year.isPreCalculation
    ? t(YEAR_CALCULATION_TYPES.VK.label)
    : t(YEAR_CALCULATION_TYPES.NK.label);
};

i18next
  .use(i18nBrowserLangDetector)
  .use(initReactI18next)
  .init({
    // Config possibilities: https://www.npmjs.com/package/i18next-browser-languagedetector
    detection: {
      lookupCookie: 'i18nextLng',

      lookupFromPathIndex: 0,

      lookupFromSubdomainIndex: 0,

      lookupLocalStorage: 'i18nextLng',

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      // order and from where user language should be detected
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },

    fallbackLng: 'en',

    interpolation: { escapeValue: false },

    react: {
      wait: true,
    },

    resources: {
      de: {
        common: commonDe,
        main: mainDe,
      },
      en: {
        common: commonEn,
        main: mainEn,
      },
    },

    saveMissing: true,

    saveMissingTo: 'all',

    whitelist: ['en', 'de'],
  });

export const getLocalizedTypeOfYear = (t, year) => {
  if (year.isPreCalculation) {
    return t('common:preCalculationUrlShortcut');
  }
  return t('common:postCalculationUrlShortcut');
};

export const isYearPreCalculation = (t, localizedTypeOfYear) =>
  localizedTypeOfYear === t('common:preCalculationUrlShortcut') ||
  localizedTypeOfYear === 'vk' ||
  localizedTypeOfYear === 'preCalc';

export { default } from 'i18next';
