import { useCallback, useMemo } from 'react';

import type { APIParams } from '../../types';
import { useMasterConfiguration } from './useMasterConfiguration';

export interface UseCostUnitParams extends APIParams<'masterConfigurationId'> {}

export const useCostUnit = ({ masterConfigurationId }: UseCostUnitParams) => {
  const { masterConfiguration } = useMasterConfiguration({ masterConfigurationId });

  const costUnitOptions = useMemo(
    () =>
      (masterConfiguration?.costUnitConfig ?? []).map(({ longName, shortName }) => ({
        label: longName,
        value: shortName,
      })),
    [masterConfiguration?.costUnitConfig],
  );

  const costUnitMap = useMemo(
    () =>
      new Map(
        masterConfiguration?.costUnitConfig?.map(({ longName, shortName }) => [
          shortName,
          longName,
        ]),
      ),
    [masterConfiguration?.costUnitConfig],
  );

  const getCostUnitLongName = useCallback(
    (shortName: string) => costUnitMap?.get(shortName) ?? '',
    [costUnitMap],
  );

  return {
    costUnitConfig: masterConfiguration?.costUnitConfig,
    costUnitMap,
    costUnitOptions,
    getCostUnitLongName,
  };
};
