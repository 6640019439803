import { CheckIcon } from '@heroicons/react/16/solid';
import classNames from 'classnames';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const CheckCircle = ({ status }: { status?: dice.FileStatusDTO['erpImport'] }) =>
  status === 'FULLY_CONFIGURED' ? (
    <div className="flex min-h-4 min-w-4 items-center justify-center rounded-full border-1.5 border-solid border-white bg-green-600">
      <CheckIcon
        className="h-3 w-3 fill-white"
        strokeWidth={2}
      />
    </div>
  ) : (
    <div
      className={classNames(
        'min-h-4 min-w-4 rounded-full border-1.5 border-solid',
        status === 'PARTIALLY_CONFIGURED' && 'border-white bg-warning',
        status !== 'PARTIALLY_CONFIGURED' && 'border-gray-300',
      )}
    />
  );

const View = (props: ViewProps) => {
  const {
    LinkComponent,
    getConfigurationsLinkProps,
    getFileLinkProps,
    hoverTarget,
    getSubLinkProps,
    filesMetadata,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 rounded-lg bg-white p-3 shadow-sm">
      <div className="flex flex-col gap-2">
        <div className="px-1 font-medium">{t('main:yearMenu.start')}</div>
        <LinkComponent
          {...getConfigurationsLinkProps?.()}
          className="rounded-md px-4 py-2 text-grey-dark hover:bg-actions-selected hover:text-grey-dark hover:no-underline data-[selected=true]:bg-actions-selected"
        >
          {t('main:yearMenu.selectConfiguration')}
        </LinkComponent>
      </div>
      <div className="flex flex-col gap-1">
        <div className="px-1 font-medium">{t('main:yearMenu.inputData')}</div>
        <LinkComponent
          {...getFileLinkProps?.('journal', hoverTarget)}
          className="flex flex-col px-4 py-2 text-grey-dark hover:bg-actions-selected hover:text-grey-dark hover:no-underline data-[selected=true]:bg-actions-selected"
        >
          <div className="pb-0.5 font-medium">{t('main:yearMenu.journal')}</div>
          <div {...getSubLinkProps('UPLOAD')}>
            <CheckCircle status={filesMetadata.get('JOURNAL')?.uploadFile} />
            {t('main:yearMenu.dataImport')}
          </div>
          <div {...getSubLinkProps('ERP')}>
            <CheckCircle status={filesMetadata.get('JOURNAL')?.erpImport} />
            {t('main:yearMenu.erpImport')}
          </div>
        </LinkComponent>
        <LinkComponent
          {...getFileLinkProps?.('assets', hoverTarget)}
          className="flex flex-col px-4 py-2 text-grey-dark hover:bg-actions-selected hover:text-grey-dark hover:no-underline data-[selected=true]:bg-actions-selected"
        >
          <div className="pb-0.5 font-medium">{t('main:yearMenu.assets')}</div>
          <div {...getSubLinkProps('UPLOAD')}>
            <CheckCircle status={filesMetadata.get('ASSETS')?.uploadFile} />
            {t('main:yearMenu.dataImport')}
          </div>
          <div {...getSubLinkProps('ERP')}>
            <CheckCircle status={filesMetadata.get('ASSETS')?.erpImport} />
            {t('main:yearMenu.erpImport')}
          </div>
        </LinkComponent>
        <LinkComponent
          {...getFileLinkProps?.('sachkonten', hoverTarget)}
          className="flex flex-col px-4 py-2 text-grey-dark hover:bg-actions-selected hover:text-grey-dark hover:no-underline data-[selected=true]:bg-actions-selected"
        >
          <div className="pb-0.5 font-medium">{t('main:yearMenu.generalAccounts')}</div>
          <div {...getSubLinkProps('UPLOAD')}>
            <CheckCircle status={filesMetadata.get('LEDGER_ACCOUNT')?.uploadFile} />
            {t('main:yearMenu.dataImport')}
          </div>
          <div {...getSubLinkProps('ERP')}>
            <CheckCircle status={filesMetadata.get('LEDGER_ACCOUNT')?.erpImport} />
            {t('main:yearMenu.erpImport')}
          </div>
        </LinkComponent>
        <LinkComponent
          {...getFileLinkProps?.('kontenplan', hoverTarget)}
          className="flex flex-col px-4 py-2 text-grey-dark hover:bg-actions-selected hover:text-grey-dark hover:no-underline data-[selected=true]:bg-actions-selected"
        >
          <div className="pb-0.5 font-medium">{t('main:yearMenu.accountPlan')}</div>
          <div {...getSubLinkProps('UPLOAD')}>
            <CheckCircle status={filesMetadata.get('ACCOUNT_PLAN')?.uploadFile} />
            {t('main:yearMenu.dataImport')}
          </div>
          <div {...getSubLinkProps('ERP')}>
            <CheckCircle status={filesMetadata.get('ACCOUNT_PLAN')?.erpImport} />
            {t('main:yearMenu.erpImport')}
          </div>
        </LinkComponent>
        <LinkComponent
          {...getFileLinkProps?.('labels', hoverTarget)}
          className="flex flex-col px-4 py-2 text-grey-dark hover:bg-actions-selected hover:text-grey-dark hover:no-underline data-[selected=true]:bg-actions-selected"
        >
          <div className="pb-0.5 font-medium">{t('main:yearMenu.labels')}</div>
          <div {...getSubLinkProps('UPLOAD')}>
            <CheckCircle status={filesMetadata.get('LABELS')?.uploadFile} />
            {t('main:yearMenu.dataImport')}
          </div>
          <div {...getSubLinkProps('ERP')}>
            <CheckCircle status={filesMetadata.get('LABELS')?.erpImport} />
            {t('main:yearMenu.erpImport')}
          </div>
        </LinkComponent>
      </div>
    </div>
  );
};

export const YearMenu = bindControllers(View, [useController]);
YearMenu.displayName = 'YearMenu';
