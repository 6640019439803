import type { IRowNode } from 'ag-grid-community';

export const getLastGroupItem = (node?: IRowNode, insertedItems = 0) => {
  const lastParentChildIdx = node?.parent?.allLeafChildren?.length ?? 0;
  if (!lastParentChildIdx) {
    return undefined;
  }

  return node?.parent?.allLeafChildren?.[lastParentChildIdx - (insertedItems + 1)] ?? undefined;
};
