import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface ImputedCostTableCheckboxProps {
  data:
    | 'IMPUTED_INTEREST'
    | 'IMPUTED_DEPRECIATION_RPV'
    | 'IMPUTED_DEPRECIATION_APC'
    | 'IMPUTED_TRADE_TAX'
    | 'IMPUTED_REVERSE_WITHDRAWAL_CAPITAL';
  value: string;
  allocationCalculatedCosts: dice.AllocationConfigPreselectedValuesDTO['calculatedCosts'];
  onSelectionChanged: (
    entry: dice.AllocationConfigPreselectedValuesDTO['calculatedCosts'],
  ) => Promise<void>;
}

export const ImputedCostTableCheckbox = ({
  data,
  value,
  onSelectionChanged,
  allocationCalculatedCosts = [],
}: ImputedCostTableCheckboxProps) => {
  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      await onSelectionChanged([...allocationCalculatedCosts, data]);
    } else {
      const removedArray = allocationCalculatedCosts.filter((item) => item !== data);

      await onSelectionChanged(removedArray);
    }
  };

  const isSelected = allocationCalculatedCosts?.includes(data);

  return (
    <CheckboxCellRenderer
      label={value.toString()}
      onEdit={handleOnEdit}
      value={isSelected}
    />
  );
};
