import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: start;
`;

export const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.pwcGreyLighter};
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  .animated {
    animation-duration: 50ms;
    animation-timing-function: ease-out;
  }
`;
