import { useState } from 'react';

import { Input } from '@nextui-org/react';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ModalContentProps } from '..';

export const UpdateConfigurationModalContent = ({
  disclosureState,
  entity,
  yearId,
}: ModalContentProps) => {
  const { t } = useTranslation();

  const { updateMasterConfigEntity, updateMasterConfigEntityMutation } =
    aggregated.useMasterConfigurationByYearCalculation({
      yearCalculationId: yearId,
    });

  const [configName, setConfigName] = useState(entity.name);
  const [configDescription, setConfigDescription] = useState(entity.description);

  const { onClose } = disclosureState;

  const updateMasterConfig = async () => {
    try {
      onClose();
      await updateMasterConfigEntity({
        ...entity,
        description: configDescription,
        name: configName,
      });
    } catch {
      showNotification('warning', t('main:clients.clientsOverviewPanel.alerts.warning.general'));
    }
  };

  return (
    <>
      <ModalHeader>{t('common:update')}</ModalHeader>
      <ModalBody>
        <Input
          label={t('common:name')}
          onChange={({ target: { value } }) => setConfigName(value)}
          value={configName}
        />
        <Input
          label={t('common:description')}
          onChange={({ target: { value } }) => setConfigDescription(value)}
          value={configDescription}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          color="danger"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common:close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={
            (entity.name === configName && entity.description === configDescription) ||
            updateMasterConfigEntityMutation.isPending
          }
          onClick={updateMasterConfig}
          size="md"
          variant="primary"
        >
          {t('common:update')}
        </Button>
      </ModalFooter>
    </>
  );
};
