import { useCallback } from 'react';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ModalContentProps } from '..';

export const DeleteConfigurationModalContent = ({
  disclosureState,
  entity,
  yearId,
}: ModalContentProps) => {
  const { t } = useTranslation();
  const { onClose } = disclosureState;
  const { deleteMasterConfig, deleteMasterConfigMutation } =
    aggregated.useMasterConfigurationByYearCalculation({
      yearCalculationId: yearId,
    });
  const handleOnDelete = useCallback(async () => {
    try {
      await deleteMasterConfig(entity);
      onClose();
    } catch {
      showNotification('warning', t('main:clients.clientsOverviewPanel.alerts.warning.general'));
    }
  }, [deleteMasterConfig, entity, onClose, t]);

  return (
    <>
      <ModalHeader>
        {t('common:contextMenu.modalDelete.title', {
          name: entity?.name,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>{t('common:contextMenu.modalDelete.label')}</ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common:close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={deleteMasterConfigMutation.isPending}
          onClick={handleOnDelete}
          size="md"
          variant="primary"
        >
          {t('common:delete')}
        </Button>
      </ModalFooter>
    </>
  );
};
