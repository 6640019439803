/*
[
  {GROUP_ID1}: [{SUB_GROUP_ID1: {rowIndex}}, {SUB_GROUP_ID2: {rowIndex}}]
  {GROUP_ID2}: [{SUB_GROUP_ID1: {rowIndex}}, {SUB_GROUP_ID2: {rowIndex}}]
]
 */

import type { dice } from '@org/query';

import type { PriceSheetRow } from '../types';

export const createNestedStructure = (tableData: PriceSheetRow[]) =>
  tableData.reduce((acc, { groupId, subGroupId, subGroupName, currency }) => {
    const group = acc.get(groupId!);
    if (group) {
      const subGroup = group.get(subGroupId!);
      if (!subGroup) {
        group.set(subGroupId!, {
          currency,
          id: subGroupId,
          name: subGroupName,
          rowIndex: group.size,
        });
      }
    } else {
      acc.set(
        groupId!,
        new Map<string, dice.PatchPriceSheetSubGroupDTO>([
          [
            subGroupId!,
            {
              currency,
              id: subGroupId,
              name: subGroupName,
              rowIndex: 0,
            },
          ],
        ]),
      );
    }

    return acc;
  }, new Map<string, Map<string, dice.PatchPriceSheetSubGroupDTO>>());

export const getSubGroupsFromNestedStructure = (
  nestedStructure: Map<string, Map<string, dice.PatchPriceSheetSubGroupDTO>>,
) => [...nestedStructure.values()].flatMap((i) => [...i.values()]);
