import { useCallback } from 'react';

import type { APIParams } from '../..';
import type { FileErpTypeDTO } from '../dice';
import {
  useDeleteFileErpTypes,
  useGetErpType,
  useSaveFileErpTypes,
  useUpdateFileErpTypes,
} from '../dice';

export interface UseCamsErpTypesProps extends APIParams {}

export const useCamsErpTypes = ({}: UseCamsErpTypesProps) => {
  const { mutateAsync: createFileErpTypeAsync } = useSaveFileErpTypes({});
  const { mutateAsync: updateFileErpTypeAsync } = useUpdateFileErpTypes({});
  const { mutateAsync: deleteFileErpTypeAsync } = useDeleteFileErpTypes({});

  const { data: preselectedErpTypes, isPending } = useGetErpType({});

  const createFileErpType = useCallback(
    async (body: FileErpTypeDTO) =>
      await createFileErpTypeAsync({
        body,
      }),
    [createFileErpTypeAsync],
  );

  const updateFileErpType = useCallback(
    async (body: FileErpTypeDTO) =>
      await updateFileErpTypeAsync({
        body,
      }),
    [updateFileErpTypeAsync],
  );

  const deleteFileErpType = useCallback(
    async (body: FileErpTypeDTO) => await deleteFileErpTypeAsync({ body }),
    [deleteFileErpTypeAsync],
  );

  return {
    createFileErpType,
    updateFileErpType,
    deleteFileErpType,
    isLoading: isPending,
    preselectedErpTypes,
  };
};
