import type { ICellEditorParams } from 'ag-grid-community';

import DatePicker from 'react-date-picker';

import { formatToISOLocalDate } from '@org/utils';

interface DatePickerCellEditorProps extends ICellEditorParams {
  value: string;
  onValueChange: (value: unknown) => void;
}

export const DatePickerCellEditor = (props: DatePickerCellEditorProps) => {
  const { value, onValueChange } = props;

  const handleChange = (newValue: Date) => {
    onValueChange(formatToISOLocalDate(newValue));
  };

  return (
    <DatePicker
      format="dd.MM.y"
      onChange={handleChange}
      value={value ? new Date(value) : undefined}
    />
  );
};
