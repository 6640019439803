import numeral from 'numeral';

import type { TFunction } from '@org/locales';

export const CURRENCY_FIELDS = [
  'betrag',
  'restbuchwert_beginn',
  'restbuchwert_ende',
  'indexfaktor',
  'anschaffungswert_ende',
  'anlage_afa',
];

export const VALID_INPUT_FIELDS_INDEX_FACTOR = {
  indexfaktor: 'indexFactor',
  indexname: 'indexName',
  indexreihe: 'indexRow',
  jahr: 'year',
};

export interface ResponseErrorType {
  defaultMessage: string;
  field: IndexFactorKeyofType;
}

type IndexFactorKeyofType = keyof typeof VALID_INPUT_FIELDS_INDEX_FACTOR;

const getOriginalColumnName = (columnName: IndexFactorKeyofType) =>
  Object.keys(VALID_INPUT_FIELDS_INDEX_FACTOR).find(
    (key) => VALID_INPUT_FIELDS_INDEX_FACTOR[key as IndexFactorKeyofType] === columnName,
  );

export const getLocalizedErrors = (tmpErrors: ResponseErrorType[], t: TFunction) =>
  tmpErrors.map((tmpError) => {
    let rowNumber = null;
    const matches = /\[(.*?)]/.exec(tmpError.defaultMessage ?? '');

    if (matches) {
      const rowNumberString = matches.at(1) ?? '';
      try {
        rowNumber = Number.parseInt(rowNumberString) + 1;
      } catch (error) {
        console.error(error);
      }
    }
    const fieldName = getOriginalColumnName(tmpError.field);
    return rowNumber && !!tmpError.field
      ? t('main:adminMenu.indexFactors.alerts.error.validationErrorDetailed', {
          fieldName,
          rowNumber,
        })
      : tmpError.defaultMessage;
  });

export const createValidRow = (row: Record<string, unknown>) => {
  const newRow: Record<string, unknown> = {};
  Object.keys(row).forEach((key) => {
    const validInputFieldIndex =
      VALID_INPUT_FIELDS_INDEX_FACTOR[key.toLowerCase() as IndexFactorKeyofType];
    if (
      Object.keys(VALID_INPUT_FIELDS_INDEX_FACTOR).includes(key.toLowerCase()) &&
      validInputFieldIndex !== undefined
    ) {
      if (CURRENCY_FIELDS.includes(key.toLowerCase())) {
        row[key] = numeral(row[key]).value();
      }
      newRow[validInputFieldIndex] = row[key];
    }
  });
  return newRow;
};
