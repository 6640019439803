import type { griddy } from '@org/query';

import { REVENUE_TYPES } from '../constants';

export const createRow = (costUnitConfig?: griddy.CostUnit[]) => ({
  assessmentBase: 1,
  creditOrReimbursementFromPreviousYears: 0,
  feeFinancedCostUnit: true,
  feeIncomeBasicCharge: 0,
  isNew: true,
  longName: '',
  rank: (costUnitConfig?.length ?? 0) + 1,
  revenueType: REVENUE_TYPES.SELECT_ACCOUNTS,
  shortName: crypto.randomUUID(),
});
