import type { GridApi } from 'ag-grid-community';

const optionalOneOrMoreDigits = String.raw`(\s[0-9]+)?$`;
const oneOrMoreDigits = '[0-9]+$';

const getNameNumber = (name: string | null) => {
  if (name) {
    const matchArray = new RegExp(oneOrMoreDigits).exec(name);
    if (matchArray) {
      // if name has a number, matcher will return array with results
      // we expect that number is a only result, so matchedArray[0]
      return Number.parseInt(matchArray[0]);
    }
  }
  return 0;
};

export const findExistingNamesMaxNumber = (defaultLabel: string, existingNames: string[]) => {
  let maxNumber: number | undefined;
  existingNames?.forEach((name: string) => {
    if (name.match(`^${defaultLabel}${optionalOneOrMoreDigits}`) !== null) {
      const currentNumber: number = getNameNumber(name);
      if ((maxNumber ?? 0) <= currentNumber) {
        maxNumber = currentNumber;
      }
    }
  });
  return maxNumber;
};

const concatName = (defaultLabel: string, maxNumber: number | undefined) =>
  `${defaultLabel}${maxNumber === undefined ? '' : ` ${maxNumber + 1}`}`;

export const generateGroupRowName = (defaultLabel: string, existingNames: string[]) => {
  const maxNumber = findExistingNamesMaxNumber(defaultLabel, existingNames);
  return concatName(defaultLabel, maxNumber);
};

export const prepareGroupRowNamesFromGridAPI = (
  gridApi: GridApi | undefined,
  columnWithNames: string,
) => {
  const names: string[] = [];
  gridApi?.forEachNode((row) =>
    row.level === 0 && row.field === columnWithNames ? names.push(row?.key ?? '') : '',
  );
  return names;
};
