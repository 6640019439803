import type { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useRef } from 'react';

import type { ButtonCellRendererProps } from '@org/ui';
import { useEvent } from '@org/hooks';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';
import { sortByKey } from '@org/utils';

import type { BaseControllerType } from '../types';
import { prepareData, prepareDefaultRowData } from './helpers';
import { useColumns } from './useColumns';

export type UseControllerProps = BaseControllerType;

const defaultColDef = {
  flex: 1,
} satisfies ColDef;

export const useController = (apiParams: UseControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const imputedConcessionFeeEnabled =
    !!masterConfiguration?.concessionFeeConfig?.concessionFeeEnabled;

  const onCellValueChange = useEvent(async (event: CellValueChangedEvent) => {
    const colName = event.colDef.field;

    if (!colName) {
      return;
    }

    const { data } = event;

    if (colName === 'concessionFeeRate') {
      const updatedValues = masterConfiguration?.concessionFeeConfig?.costCenterConcessionFees?.map(
        (config) =>
          data.primaryCostCenterShortName === config.primaryCostCenterShortName ? data : config,
      );

      return await updateMasterConfiguration({
        ...masterConfiguration,
        concessionFeeConfig: {
          ...masterConfiguration?.concessionFeeConfig,
          costCenterConcessionFees: updatedValues,
        },
      });
    }
  });

  const clearConcessionFee = useCallback(
    async ({ gridProps: { node } }: ButtonCellRendererProps) => {
      const configAfterDeletion =
        masterConfiguration?.concessionFeeConfig?.costCenterConcessionFees?.map((config) => {
          if (config.primaryCostCenterShortName === node.data.primaryCostCenterShortName) {
            return {
              concessionFeeRate: 0,
              primaryCostCenterShortName: node.data.primaryCostCenterShortName,
            };
          }

          return config;
        });

      if (configAfterDeletion) {
        await updateMasterConfiguration({
          ...masterConfiguration,
          concessionFeeConfig: {
            ...masterConfiguration?.concessionFeeConfig,
            costCenterConcessionFees: sortByKey(configAfterDeletion, 'primaryCostCenterShortName'),
          },
        });
      }
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const handleDelete = useCallback(
    (props: ButtonCellRendererProps) => {
      clearConcessionFee(props);
    },
    [clearConcessionFee],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonCellRendererProps>(
    {
      onConfirm: handleDelete,
    },
  );

  const columnDefs = useColumns({
    handleDeleteModal,
    imputedConcessionFeeEnabled,
    masterConfiguration,
  });

  const rowData = prepareData(
    prepareDefaultRowData(masterConfiguration),
    masterConfiguration?.concessionFeeConfig?.costCenterConcessionFees ?? [],
  );

  const handleEnableImputedConcessionFeeChange = useEvent(
    async ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      await updateMasterConfiguration({
        ...masterConfiguration,
        concessionFeeConfig: {
          ...masterConfiguration?.concessionFeeConfig,
          concessionFeeEnabled: checked,
        },
      });
    },
  );

  return {
    columnDefs,
    defaultColDef,
    handleDelete,
    handleEnableImputedConcessionFeeChange,
    imputedConcessionFeeEnabled,
    modal,
    onCellValueChange,
    rowData,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
