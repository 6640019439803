import type { GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback, useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import { aggregated } from '@org/query';

import { useColumns } from './useColumns';

export interface ControllerProps {
  selectedAllocationBasis?: dice.AllocationBasisConfigEntryDTO;
  handleUpdateEntry: (entry: dice.AllocationBasisConfigEntryDTO) => Promise<void>;
  gridRef: RefObject<AgGridReact>;
  apiParams: APIParams<'masterConfigurationId'>;
}

export type ControllerType = ReturnType<typeof useController>;

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
};

export function useController({
  selectedAllocationBasis,
  apiParams,
  handleUpdateEntry,
  gridRef,
}: ControllerProps) {
  const { preselectedAllocations } = aggregated.useAllocationKeys(apiParams);

  const rowData = useMemo(
    () => [
      ...(preselectedAllocations?.revenueCostTypeAccounts?.map((revC) => revC) ?? []),
      ...(preselectedAllocations?.expenseCostTypeAccounts?.map((revE) => revE) ?? []),
    ],
    [
      preselectedAllocations?.expenseCostTypeAccounts,
      preselectedAllocations?.revenueCostTypeAccounts,
    ],
  );

  const onSelectionChanged = async (
    costTypeNames: dice.AllocationDistributionConfigDTO['costTypeNames'],
  ) => {
    const copy: dice.AllocationBasisConfigEntryDTO = {
      ...selectedAllocationBasis,
      allocationDistributionConfig: {
        ...selectedAllocationBasis?.allocationDistributionConfig,
        costTypeNames,
      },
    };

    await handleUpdateEntry(copy);
  };

  const getRowId = useCallback(({ data }: GetRowIdParams) => data?.name, []);

  const columnDefs = useColumns({
    costTypeNames: selectedAllocationBasis?.allocationDistributionConfig?.costTypeNames ?? [],
    onSelectionChanged,
    rowData,
  });

  return {
    columnDefs,
    defaultColDef,
    getRowId,
    gridRef,
    rowData,
  };
}
