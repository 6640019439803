import type { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { APIParams, dice } from '@org/query';
import { useAgGridData, useEvent } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';

import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId'>;
  selectedAllocationBasis?: dice.CostUnitAllocationBasisConfigEntryDTO;
  handleUpdateEntry: (entry: dice.CostUnitAllocationBasisConfigEntryDTO) => Promise<void>;
}

const defaultColDef = {
  cellClass: ({ node: { level } }) => (level === 0 ? 'bg-highlighted font-bold' : 'text-right'),
  checkboxSelection: false,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  sortable: false,
} satisfies ColDef;

export const useController = ({
  apiParams,
  selectedAllocationBasis,
  handleUpdateEntry,
}: ControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);
  const { t } = useTranslation();

  const { preselectedAllocations } = aggregated.useCostUnitAllocation(apiParams);

  const ccOptions = useMemo(
    () =>
      preselectedAllocations?.costUnits?.map((ccItem) => ({
        label: ccItem.longName ?? '',
        value: ccItem.shortName ?? '',
      })) ?? [],
    [preselectedAllocations?.costUnits],
  );

  const rowData = useMemo(
    () =>
      Object.entries(
        selectedAllocationBasis?.allocationDistributionConfig?.costCenterDistributionMap ?? {},
      ).map(([shortName, percent]) => ({
        originalShortName: shortName,
        percent,
        shortName,
      })),
    [selectedAllocationBasis?.allocationDistributionConfig?.costCenterDistributionMap],
  );

  const { onGridReady } = useAgGridData({
    agGridRef: tableRef,
    data: rowData,
  });

  const handleAddNewRow = useCallback(() => {
    tableRef.current?.api.applyTransaction({
      add: [{ percent: 0, shortName: null }],
    });
  }, []);

  const handleRemoveCC = async (data: Record<string, string>) => {
    const selected =
      selectedAllocationBasis?.allocationDistributionConfig?.costCenterDistributionMap;
    const deletedShortName = data.shortName;
    delete selected?.[deletedShortName];

    const copy = {
      ...selectedAllocationBasis,
      allocationDistributionConfig: {
        ...selectedAllocationBasis?.allocationDistributionConfig,
        costCenterDistributionMap: selected,
      },
    };

    tableRef.current?.api.applyTransaction({ remove: [data] });
    await handleUpdateEntry(copy);
  };

  const handleOnChange = useEvent(async (event: CellValueChangedEvent) => {
    const {
      colDef: { field: colName },
      data: eventData,
      oldValue,
    } = event;

    if (!colName) {
      return;
    }

    const { originalShortName, shortName, percent } = eventData;

    // take copy - object will be mutated
    const copy = {
      ...selectedAllocationBasis?.allocationDistributionConfig?.costCenterDistributionMap,
    };

    if (copy) {
      if (originalShortName !== shortName) {
        delete copy?.[originalShortName];
      }

      copy[shortName] = percent;

      const sum = Object.entries(copy ?? {}).reduce((acc, [, value]) => acc + (value as number), 0);

      if (sum > 100) {
        tableRef.current?.api.applyTransaction({
          update: [{ ...eventData, percent: oldValue ?? 0 }],
        });
        return showNotification(
          'error',
          t('main:manageMenu.allocationKeysConfiguration.maxManualPercent'),
        );
      }

      const updatedEntry = {
        ...selectedAllocationBasis,
        allocationDistributionConfig: {
          ...selectedAllocationBasis?.allocationDistributionConfig,
          costCenterDistributionMap: copy,
        },
      };

      tableRef.current?.api.applyTransaction({ update: [eventData] });
      await handleUpdateEntry(updatedEntry);
    }
  });

  const { modal, openModal } = useDeleteRowConfirmModal<Record<string, string>>({
    onConfirm: handleRemoveCC,
  });

  const columnDefs = useColumns({
    ccOptions,
    disabledOptions: Object.entries(
      selectedAllocationBasis?.allocationDistributionConfig?.costCenterDistributionMap ?? {},
    ).map(([label]) => label),
    handleAddNewRow,
    openDeleteModal: openModal,
  });

  return {
    columnDefs,
    defaultColDef,
    handleOnChange,
    modal,
    onGridReady,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
