import type { TabListState } from '@react-stately/tabs';
import type { ItemProps, Node } from '@react-types/shared';
import type { ComponentProps, ElementType, ForwardedRef } from 'react';
import React, { forwardRef, useContext, useEffect, useMemo, useRef } from 'react';

import { FocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useTab } from '@react-aria/tabs';
import { mergeProps } from '@react-aria/utils';
import { Item } from '@react-stately/collections';
import { useTabListState } from '@react-stately/tabs';
import { tv } from 'tailwind-variants';

import type { InternalComponentProps, OverridableComponent, OverrideProps } from '../../types';
import { mergeRefs } from '../../utils';
import { TabContext } from './TabContext';

const tabVariants = tv({
  slots: {
    base: 'cursor-pointer px-2 py-1 text-gray-700',
    focusRing: '',
  },
  variants: {
    isDisabled: {
      true: {
        base: 'cursor-not-allowed text-gray-400',
      },
    },
    isHovered: {
      true: {
        base: '!border-orange-default bg-transparent text-orange-default hover:bg-orange-default/20 active:bg-orange-default/10',
      },
    },
    isSelected: {
      true: {
        base: 'rounded-sm bg-orange-default text-red-500 text-white',
      },
    },
  },
});

interface InternalTabProps extends Omit<ComponentProps<'div'>, 'children'> {
  item: Node<object>;
  state: TabListState<object>;
  isDisabled?: boolean;
  focusRingClassName?: string;
}

// @private
function InternalTab(props: InternalTabProps) {
  const { item, state, focusRingClassName, className } = props;
  const { key, rendered } = item;

  const ref = useRef<any>(null);
  const { tabProps, isSelected, isDisabled } = useTab({ key }, state, ref);

  const { hoverProps, isHovered } = useHover({
    ...props,
  });
  const Component: ElementType = item.props.href ? 'a' : 'div';

  const styles = useMemo(
    () =>
      tabVariants({
        className,
        isDisabled,
        isHovered: isHovered && !isSelected,
        isSelected,
      }),
    [className, isDisabled, isHovered, isSelected],
  );

  return (
    <FocusRing focusRingClass={styles.base({ className: focusRingClassName })}>
      <Component
        {...mergeProps(tabProps, hoverProps)}
        className={styles.base({ className })}
        ref={ref}
      >
        {rendered}
      </Component>
    </FocusRing>
  );
}

export interface TabProps
  extends Omit<InternalTabProps, 'state' | 'item' | 'title'>,
    ItemProps<object> {}

export const Tab = Item as (props: TabProps) => React.JSX.Element;

export interface TabListTypeMap<AdditionalProps = {}, RootComponent extends ElementType = 'div'> {
  props: AdditionalProps & {};
  defaultComponent: RootComponent;
}

export type TabListProps<
  RootComponent extends ElementType = TabListTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<TabListTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalTabListProps<
  RootComponent extends ElementType = TabListTypeMap['defaultComponent'],
  AdditionalProps = {},
> = InternalComponentProps<TabListTypeMap<AdditionalProps, RootComponent>>;

const tabListVariants = tv({
  base: 'relative m-0 flex flex-1 select-none items-center p-0 align-top outline-none',
});

export const TabList = forwardRef(
  <BaseComponentType extends ElementType = TabListTypeMap['defaultComponent']>(
    inProps: InternalTabListProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const { as: Component = 'div', children, className } = inProps;

    const tabContext = useContext(TabContext);
    const { refs, tabState, tabProps } = tabContext;
    const { setTabListState } = tabState;
    const { tablistRef } = refs;

    // Pass original Tab props but override children to create the collection.
    const state = useTabListState({ ...tabProps, children });

    useEffect(() => {
      // Passing back to root as useTabPanel needs the TabListState
      setTabListState(state);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.disabledKeys, state.selectedItem, state.selectedKey, children]);

    const styles = useMemo(() => tabListVariants({ className }), [className]);

    return (
      <Component
        className={styles}
        ref={mergeRefs(tablistRef, ref)}
      >
        {[...state.collection].map((item) => (
          <InternalTab
            {...item.props}
            item={item}
            key={item.key}
            state={state}
          />
        ))}
      </Component>
    );
  },
) as OverridableComponent<TabListTypeMap>;
