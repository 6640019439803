import type { FC } from 'react';
import { memo } from 'react';

import { useParams } from 'react-router-dom';

import type { ConfigMenuItem as ConfigMenuItemModel } from '@org/models';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { getStatusColor } from '@org/utils';

import { useAccountIds, useAssetCategories } from '../../../../hooks/api';
import { ConfigItem } from './ConfigItem';

export type ConfigMenuItemProps = {
  configItem: string;
  configMenuItem: ConfigMenuItemModel;
  setConfigItem: (val: string) => void;
} & React.JSX.IntrinsicElements['div'];

export const ConfigMenuItemMemo: FC<ConfigMenuItemProps> = (props) => {
  const { configItem, configMenuItem, setConfigItem, ...otherProps } = props;
  const { t } = useTranslation();
  const { configId } = useParams<{ configId: string }>();
  const { masterConfiguration: selectedConfig } = aggregated.useMasterConfiguration({
    masterConfigurationId: configId,
  });

  const { data: accountIdsJournal } = useAccountIds();
  const { assetCategories } = useAssetCategories();

  const { configsStatus } = aggregated.useConfigStatus({
    masterConfigurationId: selectedConfig?.id ?? '',
  });

  return (
    <ConfigItem
      {...otherProps}
      disabled={configMenuItem.disabled}
      onClick={() => !configMenuItem.disabled && setConfigItem(configMenuItem.value)}
      selected={configItem === configMenuItem.value}
      statusColorClassName={getStatusColor(
        configMenuItem?.getStatus?.({
          accountIdsJournal,
          assetCategories,
          configsStatus,
          selectedConfig,
        }),
      )}
    >
      {t(`main:clients.menu.configMenu.${configMenuItem.value}`)}
    </ConfigItem>
  );
};

export const ConfigMenuItem = memo(ConfigMenuItemMemo);
