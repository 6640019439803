import { type Location, type NavigateFunction } from 'react-router-dom';

import type { TFunction } from '@org/locales';
import type { aggregated, dice } from '@org/query';

import { getErrorMsgOfLocationOrError, getErrorStatusOfError } from '../../../pages/ErrorPage';
import { handleShowAlert } from '../../../utils/Alerts';
import Logger from '../../../utils/Logger';

interface OpenReportProps {
  configuration?: dice.MasterConfiguration;
  recalculate?: boolean;
  selectedYear?: dice.YearDTOV2;
  setIsCalculating: (nextValue: boolean) => void;
  navigate: NavigateFunction;
  location: Location;
  recalculateReports: ReturnType<typeof aggregated.useReport>['recalculateReport'];
  t: TFunction;
}

// TODO refactor this function by introducing a hook with separation of opening and saving a report
export const openReport = async ({
  configuration,
  recalculate = false,
  selectedYear,
  setIsCalculating,
  navigate,
  location,
  t,
  recalculateReports,
}: OpenReportProps) => {
  const errorCallback = (e: Error) => {
    setIsCalculating(false);
    Logger.error(e, 'openReport.js - recalculate');

    // Error hint from BE of 0 amount distribution
    if (
      getErrorStatusOfError(e) === 400 &&
      getErrorMsgOfLocationOrError(null, e, t).toUpperCase().includes('ZERO')
    ) {
      handleShowAlert('error', getErrorMsgOfLocationOrError(null, e, t));
      return;
    }
    navigate('/error');
  };

  const successCallback = () => {
    setIsCalculating(false);

    const updatedPathName = location.pathname.replace(/\/configuration/, '');

    // When calling from the config section, the path already contains the configuration name
    const newPath = updatedPathName.endsWith(updatedConfiguration?.id ?? '')
      ? `${updatedPathName}/report`
      : `${updatedPathName}/${updatedConfiguration?.id}/report`;

    navigate(newPath);
  };

  const updatedConfiguration = configuration;

  if (recalculate) {
    setIsCalculating(true);
    try {
      await recalculateReports({
        masterConfiguration: configuration,
        year: selectedYear,
      });
      successCallback();
    } catch (error) {
      errorCallback(error as Error);
    }
  } else {
    successCallback();
  }
};
