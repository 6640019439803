import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { Item, Section } from '@react-stately/collections';

import type { OverridableComponent } from '../../types';
import type { ListBoxItemProps } from '../ListBoxItem';
import type { ListBoxSectionProps } from '../ListBoxSection';
import type { MultipleComboboxProps } from './MultipleCombobox';
import type { SingleComboboxProps } from './SingleCombobox';
import { MultipleCombobox } from './MultipleCombobox';
import { SingleCombobox } from './SingleCombobox';

export interface ComboboxTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props:
    | (MultipleComboboxProps<DefaultComponent, AdditionalProps> & { isMultiple: true })
    | (SingleComboboxProps<DefaultComponent, AdditionalProps> & { isMultiple?: false });
  defaultComponent: DefaultComponent;
}

export type ComboboxProps<Multiple extends boolean = false> = Multiple extends true
  ? MultipleComboboxProps
  : SingleComboboxProps;

export const Combobox = forwardRef(
  <Multiple extends boolean>(
    props: { isMultiple?: Multiple } & ComboboxProps<Multiple>,
    ref: ForwardedRef<Element>,
  ) => {
    const { isMultiple, ...restProps } = props;

    return isMultiple === true ? (
      // @ts-expect-error -- Ref error; Necessary evil for ref and as to work correctly
      <MultipleCombobox
        ref={ref}
        {...(restProps as MultipleComboboxProps)}
      />
    ) : (
      // @ts-expect-error -- Ref error; Necessary evil for ref and as to work correctly
      <SingleCombobox
        ref={ref}
        {...(restProps as SingleComboboxProps)}
      />
    );
  },
) as OverridableComponent<ComboboxTypeMap>;

export const ComboboxItem = Item as (props: ComboboxItemProps) => React.JSX.Element;

export type ComboboxItemProps = ListBoxItemProps;

export const ComboboxSection = Section as (props: ComboboxSectionProps) => React.JSX.Element;

export type ComboboxSectionProps = ListBoxSectionProps;
