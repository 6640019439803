import { Controller } from 'react-hook-form';

import { Combobox, ComboboxItem } from '@org/design';
import { useTranslation } from '@org/locales';
import {
  CancelButton,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  NumberInput,
  SaveButton,
} from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

export const View = (props: ViewProps) => {
  const { t } = useTranslation();
  const { options, control, handleSubmit, isOpenTechnicalModal, onOpenChangeTechnicalModal } =
    props;

  return (
    <Modal
      isOpen={isOpenTechnicalModal}
      onOpenChange={onOpenChangeTechnicalModal}
      size="2xl"
    >
      <ModalContent>
        {(onClose) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              {t('main:manageMenu.plannedCostRevenueConfiguration.technicalDataDetailModal')}
            </ModalHeader>
            <ModalBody>
              <Label>{t('main:manageMenu.plannedCostRevenueConfiguration.technicalData')}</Label>
              <Controller
                control={control}
                name="technicalData"
                render={({ field }) => {
                  const { value, onChange, ...other } = field;
                  return (
                    <Combobox
                      {...other}
                      aria-label="technicalData"
                      isDisabled={false}
                      isFullWidth
                      isMultiple={false}
                      onSelectionChange={(selected) => {
                        onChange(selected);
                      }}
                      placeholder={t(
                        'main:manageMenu.allocationKeysConfiguration.placeholderSelectTechnicalData',
                      )}
                      selectedKey={value}
                    >
                      {options.map(({ label, value: key }) => (
                        <ComboboxItem key={key}>{label}</ComboboxItem>
                      ))}
                    </Combobox>
                  );
                }}
              />
              <Label>
                {t('main:manageMenu.plannedCostRevenueConfiguration.technicalDataPrice')}
              </Label>
              <Controller
                control={control}
                name="technicalDataPrice"
                render={({ field }) => (
                  <NumberInput
                    allowNegative={false}
                    decimalScale={2}
                    onChange={field.onChange}
                    onValueChange={(v) => field.onChange(v.value)}
                    placeholder={t(
                      'main:manageMenu.plannedCostRevenueConfiguration.technicalDataPrice',
                    )}
                    value={field.value}
                  />
                )}
              />
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onClose} />
              <SaveButton
                onClick={onClose}
                type="submit"
              />
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

export const TechnicalDataDetailModal = bindControllers(View, [useController]);
TechnicalDataDetailModal.displayName = 'TechnicalDataDetailModal';
