import type { griddy } from '@org/query';

import { isEmptyOrSpaces, roundNumberWithTwoDigits } from '../../../utils/formatUtils';
import { isCostCenterNameInCostCenter } from '../ManageAssetModifications/logic/util';

export const createAllocationStructureFromMapping = (
  accountToClientCostCenterMap?: griddy.GetAllAcctIdToCostCenterNameMapResponse,
  costCenterConfig?: griddy.CostCenterConfig,
  profitCenterTrees?: griddy.ProfitCenterTree[],
) => {
  const accountToClientCostCenterAllocationMap: Record<
    string,
    Record<string, Record<string, number>>
  > = {};
  if (!accountToClientCostCenterMap) {
    return accountToClientCostCenterAllocationMap;
  }

  Object.entries(accountToClientCostCenterMap).forEach(([accountId, costCentersList]) => {
    const costCenterIds = costCentersList.filter((costCenter) => !isEmptyOrSpaces(costCenter));
    if (costCenterIds.length === 0) {
      const manualAllocation = { NA: {} };
      accountToClientCostCenterAllocationMap[accountId] = manualAllocation;
      return;
    }
    const linearAllocationMap: Record<string, Record<string, number>> = {};
    costCenterIds.forEach((costCenterId) => {
      const babCostCenters: string[] = [];
      Object.entries(costCenterConfig?.costCenterMapping ?? {}).forEach(([, currentMapping]) => {
        if (isCostCenterNameInCostCenter(costCenterId, currentMapping, profitCenterTrees)) {
          babCostCenters.push(currentMapping.shortName!);
        }
      });

      if (babCostCenters?.length) {
        const allocationPercentage = roundNumberWithTwoDigits(100 / babCostCenters.length);
        const clientCostCenterAllocation: Record<string, number> = {};
        babCostCenters.forEach((babCostCenter) => {
          clientCostCenterAllocation[babCostCenter] = allocationPercentage;
        });
        linearAllocationMap[costCenterId] = clientCostCenterAllocation;
      } else if (!isEmptyOrSpaces(costCenterId)) {
        const clientCostCenterAllocation = {};
        linearAllocationMap[costCenterId] = clientCostCenterAllocation;
      }
    });
    accountToClientCostCenterAllocationMap[accountId] = linearAllocationMap;
  });
  return accountToClientCostCenterAllocationMap;
};
