import type { APIParams } from '../..';
import type { PriceSheetReportGroupDTO } from '../dice';
import { useGetPriceSheetReport } from '../dice';

interface UsePriceSheetReportParams extends APIParams<'masterConfigurationId' | 'yearId'> {}

export const flattenPriceSheetReportGroups = (priceSheetGroups: PriceSheetReportGroupDTO[]) => {
  if (!Array.isArray(priceSheetGroups)) {
    return [];
  }

  let rowIndex = 0;

  return priceSheetGroups.flatMap((priceSheet) => {
    const { name: groupName, priceSheetReportSubGroups, source } = priceSheet;
    return (
      priceSheetReportSubGroups?.flatMap(({ name: subGroupName, currency, amount }) => [
        {
          amount,
          currency,
          groupName,
          subGroupName,
          source,
          rowIndex: rowIndex++,
        },
      ]) ?? []
    );
  });
};

export const usePriceSheetReport = ({
  masterConfigurationId: masterConfigId,
  yearId,
}: UsePriceSheetReportParams) => {
  const { data: priceSheetGroups, isLoading: isPriceSheetReportLoading } = useGetPriceSheetReport(
    {
      pathParams: {
        masterConfigId,
        yearId,
      },
    },
    {
      select(data) {
        return flattenPriceSheetReportGroups(data);
      },
    },
  );

  return {
    isLoading: isPriceSheetReportLoading,
    priceSheetGroups,
  };
};
