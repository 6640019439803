import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { dice, griddy } from '@org/query';
import { useTranslation } from '@org/locales';

import { CostCenterTableCheckbox, SelectAllCheckbox } from './components';

interface UseColumnsProps {
  costCenterDistributionMap?: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'];
  onSelectionChanged: (
    selection: dice.AllocationDistributionConfigDTO['costCenterDistributionMap'],
  ) => Promise<void>;
  rowData: dice.AllocationConfigCostCenterDTO[];
}

export const useColumns = ({
  costCenterDistributionMap,
  rowData,
  onSelectionChanged,
}: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellRenderer: ({ data, value }: ICellRendererParams) => (
            <CostCenterTableCheckbox
              costCenterDistributionMap={costCenterDistributionMap}
              data={data}
              onSelectionChanged={onSelectionChanged}
              value={value}
            />
          ),
          colId: 'longName',
          field: 'longName',
          flex: 2,
          headerComponent: () => (
            <div className="ml-2 flex space-x-2">
              <SelectAllCheckbox
                costCenterDistributionMap={costCenterDistributionMap}
                onSelectionChanged={onSelectionChanged}
                rowData={rowData}
              />
              <div>{t('main:manageMenu.allocationKeysConfiguration.receivingCostCenters')}</div>
            </div>
          ),
        },
      ] satisfies ColDef<griddy.CostCenter>[],
    [costCenterDistributionMap, onSelectionChanged, rowData, t],
  );
};
