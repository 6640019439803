import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useCallback } from 'react';

import { FILE_CONFIG } from '@org/utils';

import type {
  DeleteFileError as AssetDeleteFileError,
  DeleteFileVariables as AssetDeleteFileVariables,
  UploadFileQueryParams,
  UploadFileRequestBody,
} from '../dice';
import type {
  DeleteAccountPlanError,
  DeleteAccountPlanVariables,
  DeleteByFileIdFromGridFSError,
  DeleteFile1Error,
  DeleteFile1Variables,
  DeleteFile2Error,
  DeleteFile2Variables,
  DeleteFileError,
  DeleteFileQueryParams,
  DeleteFileVariables,
} from '../griddy';
import {
  fetchGetYearCalculation,
  useDeleteFile as useDeleteAssetsFile,
  useUploadFile,
} from '../dice';
import {
  useDeleteAccountPlan,
  useDeleteByFileIdFromGridFS,
  useDeleteFile,
  useDeleteFile1,
  useDeleteFile2,
} from '../griddy';
import { useFileMetadata } from './useFileMetadata';

export type DeleteFileErrorType =
  | DeleteFileError
  | DeleteFile1Error
  | DeleteFile2Error
  | DeleteByFileIdFromGridFSError;

export interface DeleteQueryTypeMap {
  deleteLedgerAccount: UseMutateAsyncFunction<undefined, DeleteFileError, DeleteFileVariables>;
  deleteLabels: UseMutateAsyncFunction<undefined, DeleteFile1Error, DeleteFile1Variables>;
  deleteJournal: UseMutateAsyncFunction<undefined, DeleteFile2Error, DeleteFile2Variables>;
  deleteAssets: UseMutateAsyncFunction<undefined, AssetDeleteFileError, AssetDeleteFileVariables>;
  deleteAccountsPlan: UseMutateAsyncFunction<
    undefined,
    DeleteAccountPlanError,
    DeleteAccountPlanVariables
  >;
}

export function createDeleteFileQueryMap({
  deleteAccountsPlan,
  deleteAssets,
  deleteJournal,
  deleteLabels,
  deleteLedgerAccount,
}: DeleteQueryTypeMap) {
  return {
    ACCOUNT_PLAN: deleteAccountsPlan,
    ASSETS: deleteAssets,
    JOURNAL: deleteJournal,
    LABELS: deleteLabels,
    LEDGER_ACCOUNT: deleteLedgerAccount,
  };
}

export type ApiFileType = DeleteFileQueryParams['fileType'];
export type BaseFileType = 'kontenplan' | 'assets' | 'journal' | 'labels' | 'sachkonten';

export const baseToApiFileType: Record<BaseFileType, ApiFileType> = {
  assets: 'ASSETS',
  journal: 'JOURNAL',
  kontenplan: 'ACCOUNT_PLAN',
  labels: 'LABELS',
  sachkonten: 'LEDGER_ACCOUNT',
};

let deleteFileQueryMap: ReturnType<typeof createDeleteFileQueryMap>;

interface UseFilesProps {
  globalErrorHandling?: (error: DeleteFileErrorType) => void;
}

interface DeleteFileAction {
  fileType: ApiFileType;
  fileId?: string;
  yearCalculationId: string;
}

export const useFiles = ({ globalErrorHandling }: UseFilesProps) => {
  const options = { onError: globalErrorHandling };

  const { mutateAsync: deleteLedgerAccount, isPending: deleteLedgerAccountIsLoading } =
    useDeleteFile();
  const { mutateAsync: deleteLabels, isPending: deleteLabelsIsLoading } = useDeleteFile1();
  const { mutateAsync: deleteJournal, isPending: deleteJournalIsLoading } = useDeleteFile2();
  const { mutateAsync: deleteAssets, isPending: deleteAssetsIsLoading } = useDeleteAssetsFile();
  const { mutateAsync: deleteAccountsPlan, isPending: deleteAccountsPlanIsLoading } =
    useDeleteAccountPlan();

  const { mutateAsync: deleteByFileIdFromGridFS, isPending: deleteByFileIdFromGridFSIsLoading } =
    useDeleteByFileIdFromGridFS(options);

  const { mutateAsync: uploadFileAsync, isPending: uploadFileIsLoading } = useUploadFile();

  const { updateFileMetadata, deleteFileMetadata } = useFileMetadata({});

  deleteFileQueryMap ??= createDeleteFileQueryMap({
    deleteAccountsPlan,
    deleteAssets,
    deleteJournal,
    deleteLabels,
    deleteLedgerAccount,
  });

  const isLoading =
    deleteAssetsIsLoading ||
    deleteJournalIsLoading ||
    deleteLabelsIsLoading ||
    deleteAccountsPlanIsLoading ||
    deleteLedgerAccountIsLoading ||
    deleteByFileIdFromGridFSIsLoading;

  const deleteFileTypeByYearId = useCallback(
    async ({ yearCalculationId, fileType }: Omit<DeleteFileAction, 'fileId'>) => {
      // TODO: DeleteFileVariables interface from DICE is different than from GRIDDY
      //  -> refactor later to deleteFileQueryMap[fileType]?.()
      if (fileType === FILE_CONFIG.ASSETS.apiType) {
        return deleteAssets(
          {
            queryParams: {
              yearId: yearCalculationId,
            },
          },
          {
            onError: globalErrorHandling,
          },
        );
      }

      return deleteFileQueryMap[fileType]?.(
        {
          queryParams: {
            fileType,
            yearId: yearCalculationId,
          },
        },
        {
          onError: globalErrorHandling,
        },
      );
    },
    [globalErrorHandling, deleteAssets],
  );

  const deleteFile = useCallback(
    async ({ fileId, yearCalculationId, fileType }: DeleteFileAction) => {
      const year = await fetchGetYearCalculation({ pathParams: { yearCalculationId } });

      if (!year) {
        return;
      }

      if (fileId) {
        await deleteByFileIdFromGridFS({ pathParams: { id: fileId } });
      }

      await deleteFileTypeByYearId({ fileType, yearCalculationId });
      await deleteFileMetadata({ fileType, yearCalculationId });
    },
    [deleteByFileIdFromGridFS, deleteFileMetadata, deleteFileTypeByYearId],
  );

  const uploadFile = useCallback(
    async ({
      body,
      type,
      yearCalculationId,
      fileName,
      userId,
    }: {
      body: UploadFileRequestBody;
      type: UploadFileQueryParams['type'];
      yearCalculationId: UploadFileQueryParams['yearId'];
      fileName: string;
      userId?: string;
    }) => {
      await uploadFileAsync({
        body,
        queryParams: {
          type,
          yearId: yearCalculationId,
        },
      });

      await updateFileMetadata({
        createdAtIsoDateTime: new Date().toISOString(),
        fileName,
        type,
        userId,
        yearCalculationId,
      });
    },

    [updateFileMetadata, uploadFileAsync],
  );

  return {
    deleteFile,
    deleteFileTypeByYearId,
    isLoading: isLoading || uploadFileIsLoading,
    uploadFile,
  };
};
