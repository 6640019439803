import type { ColDef, GetRowIdParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef } from 'react';

import type { APIParams, dice } from '@org/query';
import { useAgGridData } from '@org/hooks';

import { useColumns } from './useColumns';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId' | 'yearId'>;
  data: dice.AccountRangeDTO[];
}

const defaultColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
} satisfies ColDef;

export const useController = ({ data }: ControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);

  const columnDefs = useColumns();

  const getRowId = useCallback(
    ({ data: getRowParamsData }: GetRowIdParams) => getRowParamsData?.accountId,
    [],
  );

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data,
  });

  return {
    agGridProps,
    columnDefs,
    defaultColDef,
    getRowId,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
