import type { FC } from 'react';

import classnames from 'classnames';

import type { BaseEditTableProps } from '@org/ui';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface PriceSheetProps extends Omit<BaseEditTableProps, keyof ControllerType>, ControllerType {}

const PriceSheet: FC<PriceSheetProps> = (props) => {
  const {
    columnDefs,
    defaultColDef,
    masterConfigId,
    autoGroupColumnDef,
    tableRef,
    onChange,
    modal,
    agGridProps,
    getRowId,
  } = props;

  return (
    <div
      className={classnames('mt-8 h-full flex-1', { 'h-[calc(100vh-26rem)]': !!masterConfigId })}
    >
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        enableGroupEdit
        getRowId={getRowId}
        groupDisplayType="singleColumn"
        isGroupOpenByDefault={() => true}
        onCellValueChanged={onChange}
        ref={tableRef}
        singleClickEdit
        {...agGridProps}
      />
      {modal}
    </div>
  );
};

export const PriceSheetPage = bindControllers(PriceSheet, [useController]);
PriceSheetPage.displayName = 'PriceSheetPage';
