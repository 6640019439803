import type { dice } from '../../../../../query/src';
import { isPrimaryCostCenter } from '../utils';

export const updateImputedWorkingCapitalByCostCenterAdd = (props: {
  costCenter: dice.CostCenter;
  imputedWorkingCapitalConfig: dice.ImputedWorkingCapitalConfig | undefined;
}): dice.ImputedWorkingCapitalConfig => {
  const { costCenter, imputedWorkingCapitalConfig = {} } = props;
  const { workingCapitals = [], defaultWorkingCapitalRate = 0 } = imputedWorkingCapitalConfig;
  const isNewCostCenterExisting = workingCapitals.some(
    (ccWc) => ccWc.primaryCostCenterShortName === costCenter.shortName,
  );
  if (isPrimaryCostCenter(costCenter) && !isNewCostCenterExisting) {
    const newCostCenterWorkingCapital: dice.WorkingCapital = {
      primaryCostCenterShortName: costCenter.shortName,
      workingCapitalRate: defaultWorkingCapitalRate,
    };
    return {
      ...imputedWorkingCapitalConfig,
      workingCapitals: [...workingCapitals, newCostCenterWorkingCapital],
    };
  }
  return imputedWorkingCapitalConfig;
};
