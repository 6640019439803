export const costCenterTypes = {
  INDIRECT_COST_CENTER: {
    key: 'INDIRECT_COST_CENTER',
    label: 'main:manageMenu.manageCostCenters.costCenterEnum.indirect',
    name: 'Indirect',
  },
  PRIMARY_COST_CENTER: {
    key: 'PRIMARY_COST_CENTER',
    label: 'main:manageMenu.manageCostCenters.costCenterEnum.primary',
    name: 'Primary',
  },
} as const;
