import type { ICellRendererParams } from 'ag-grid-community';
import { useCallback } from 'react';

import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

import type { CostTypeAccountRowDataAccount } from '../types';

interface AccountTableCheckboxProps extends ICellRendererParams<CostTypeAccountRowDataAccount> {
  selectedAccounts: Record<string, boolean>;
  data: CostTypeAccountRowDataAccount;
  rowId: string;
  costTypeAccountConfig?: dice.CostTypeAccountConfigDTO;
  handleEditConfig: (config: dice.CostTypeAccountConfigDTO) => Promise<void>;
}

export const AccountTableCheckbox = ({
  selectedAccounts,
  rowId,
  data,
  costTypeAccountConfig,
  handleEditConfig,
}: AccountTableCheckboxProps) => {
  const isSelectedInOther = selectedAccounts?.[data.accountId] ?? false;
  const isSelected = data.accountChecked;

  const handleOnEdit = useCallback(
    async (isChecked: boolean) => {
      const costTypeAccounts = costTypeAccountConfig?.costTypeAccounts ?? [];

      const updated = costTypeAccounts.map((currentEntry) => {
        if (currentEntry.id === rowId) {
          if (isChecked) {
            currentEntry.accountIds?.push(data.accountId);
            data.accountChecked = true;
          } else {
            data.accountChecked = false;
            data.adjusted = false;
            currentEntry.accountIds = currentEntry.accountIds?.filter(
              (adj) => adj !== data.accountId,
            );
            currentEntry.adjustedAccountIds = currentEntry.adjustedAccountIds?.filter(
              (accountId) => accountId !== data.accountId,
            );
          }
        }

        return currentEntry;
      });

      const config = {
        ...costTypeAccountConfig,
        costTypeAccounts: updated,
      };

      await handleEditConfig(config);
    },
    [costTypeAccountConfig, data, handleEditConfig, rowId],
  );

  return (
    <div className="ml-8">
      <CheckboxCellRenderer
        disabled={isSelectedInOther && !isSelected}
        label={data.accountDesc}
        onEdit={handleOnEdit}
        value={isSelectedInOther || isSelected}
      />
    </div>
  );
};
