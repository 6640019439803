/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNumber, isString, isSymbol } from '../../type-guards';

// TODO We still need to figure out how to type the result to reflect the possible values type ​​from the getter
export type GroupByResult<T> = Map<string | number | undefined | null, T[]>;

export type GroupByKeyGetterFN<T> = (item: T) => any;
export type GroupByKeyGetter<T> =
  | keyof T
  | string
  | number
  | undefined
  | null
  | GroupByKeyGetterFN<T>;

export type GroupBy = <
  Item extends Record<string | number | symbol, any>,
  KeyGetter extends GroupByKeyGetter<Item>,
>(
  arr: Item[],
  keyGetter: KeyGetter,
) => GroupByResult<Item>;

/**
 * Function returns exact group key depend on keyGetter type
 */
const getKey = <
  Item extends Record<string | number | symbol, unknown>,
  KeyGetter extends GroupByKeyGetter<Item>,
>(
  keyGetter: KeyGetter,
  item: Item,
) => {
  if (isString(keyGetter) || isNumber(keyGetter) || isSymbol(keyGetter)) {
    return item[keyGetter];
  }

  if (typeof keyGetter === 'function') {
    return keyGetter(item);
  }

  return keyGetter;
};

/**
 * Function returns ES6 Map instead of simple object. It allow to have groups with right keys as null or undefined.
 * Unlike groupBy from lodash which converts it to string
 */
export const groupBy: GroupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = getKey(keyGetter, item);
    if (map.has(key)) {
      map.get(key).push(item);
    } else {
      map.set(key, [item]);
    }
  });

  return map;
};
