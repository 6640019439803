import type { CellClassParams, ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import classNames from 'classnames';

import type { griddy } from '@org/query';
import type {
  ButtonCellRendererProps,
  CustomMasterDetailCellRendererProps,
  UseMasterDetailStateProps,
} from '@org/ui';
import { useTranslation } from '@org/locales';
import { CustomMasterDetailCellRenderer } from '@org/ui';
import { formatNonZeroNumberToLocaleString } from '@org/utils';

import {
  ConfiguredAccountsInfo,
  FromPreviousYearHeaderTooltip,
  RevenueTypeRenderer,
} from './components';

interface UseColumnsProps {
  yearId: string;
  handleDeleteModal: (props: ButtonCellRendererProps) => void;
  setTableContext: (data: UseMasterDetailStateProps) => void;
  tableContext: UseMasterDetailStateProps;
}

export const useColumns = ({
  yearId,
  handleDeleteModal,
  setTableContext,
  tableContext,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      [
        {
          children: [
            {
              cellClass: 'align-to-center',
              colId: 'rank',
              field: 'rank',
              headerName: t('main:manageMenu.manageCostUnits.tableColumns.rank'),
              maxWidth: 60,
              rowDrag: true,
              sortable: false,
              sortingOrder: ['asc'],
            },
            {
              cellClass: ({ data }: CellClassParams) =>
                classNames({ '!border-red-400': data?.isNew && !data.longName }, 'align-to-center'),
              colId: 'longName',
              editable: true,
              field: 'longName',
              flex: 3,
              headerName: t('main:manageMenu.manageCostCenters.tableColumns.longName'),
            },
            {
              cellClass: 'align-to-center',
              cellRenderer: CustomMasterDetailCellRenderer,
              cellRendererParams: {
                colName: 'feeIncomeBasicCharge',
                formatter: (value: string) =>
                  formatNonZeroNumberToLocaleString(value, {
                    defaultValue: '-',
                  }),
                setTableContext,
                tableContext,
              },
              colId: 'feeIncomeBasicCharge',
              field: 'feeIncomeBasicCharge',
              headerName: t('main:manageMenu.manageCostUnits.tableColumns.feeIncomeBasicCharge'),
              minWidth: 150,
              type: 'number',
            },
            {
              autoHeight: true,
              cellClass: 'align-to-center',
              cellRenderer: (
                params: ICellRendererParams &
                  Omit<CustomMasterDetailCellRendererProps, 'formatter'>,
              ) => (
                <div className="flex">
                  <CustomMasterDetailCellRenderer
                    {...params}
                    value=""
                  />
                  <RevenueTypeRenderer
                    {...params}
                    path="revenueType"
                  />
                </div>
              ),
              cellRendererParams: {
                colName: 'rank',
                setTableContext,
                tableContext,
              },
              colId: 'revenueType',
              field: 'revenueType',
              headerName: t('main:manageMenu.manageCostUnits.tableColumns.revenueType'),
              maxWidth: 180,
              minWidth: 180,
            },
            {
              cellClass: 'align-to-center-and-right',
              colId: 'creditOrReimbursementFromPreviousYears',
              editable: true,
              field: 'creditOrReimbursementFromPreviousYears',
              headerComponent: FromPreviousYearHeaderTooltip,
              maxWidth: 180,
              type: 'price',
            },
            {
              cellClass: 'align-to-center-and-right',
              colId: 'assessmentBase',
              editable: true,
              field: 'assessmentBase',
              headerName: t('main:manageMenu.manageCostUnits.tableColumns.assessmentBase'),
              type: 'price',
            },
            {
              cellClass: 'align-to-center-and-right',
              colId: 'unit',
              editable: true,
              field: 'unit',
              headerName: t('main:manageMenu.manageCostUnits.tableColumns.unit'),
            },
          ],
          pinned: 'left',
          resizable: false,
        },
        {
          children: [
            {
              autoHeight: true,
              cellRenderer: ({ value }: ICellRendererParams) => (
                <ConfiguredAccountsInfo
                  selectAccountsConfig={value}
                  yearId={yearId}
                />
              ),
              colId: 'selectAccountsConfig',
              columnGroupShow: 'closed',
              field: 'selectAccountsConfig',
              flex: 3,
              headerName: t('main:manageMenu.manageCostUnits.tableColumns.configuredAccounts'),
              maxWidth: 300,
              sortable: false,
            },
            {
              cellRenderer: 'multipleButtons',
              cellRendererParams: {
                buttonParams: [
                  {
                    className: 'align-to-center-and-right',
                    icon: 'icon-delete-outline',
                    onClick: (props: ButtonCellRendererProps) => handleDeleteModal(props),
                    size: 'iconButton',
                  },
                ],
              },
              colId: 'actions',
              headerName: t('common:actions'),
              maxWidth: 80,
            },
          ],
          headerName: t(
            'main:manageMenu.manageCostUnits.tableColumns.switchShowConfiguredAccounts',
          ),
          openByDefault: true,
        },
        {
          colId: 'selectRevenueAccountsConfiguration',
          field: 'selectRevenueAccountsConfiguration',
          hide: true,
        },
      ] satisfies (ColDef<griddy.CostUnit> | ColGroupDef<griddy.CostUnit>)[],
    [handleDeleteModal, setTableContext, t, tableContext, yearId],
  );
};
