import { Tooltip } from 'appkit-react';
import PropTypes from 'prop-types';

const ErrorIcon = ({ type, tooltipText }) => {
  const Icon = (
    <span
      className={`appkiticon alert-icon icon-alert-outline a-cursor-pointer ${
        type === 'error' ? 'a-text-red' : 'a-text-tangerine'
      }`}
    />
  );

  const IconWithTooltip = <Tooltip content={tooltipText}>{Icon}</Tooltip>;

  return tooltipText ? IconWithTooltip : Icon;
};

ErrorIcon.propTypes = {
  tooltipText: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning']),
};

ErrorIcon.defaultProps = {
  type: 'error',
};

export default ErrorIcon;
