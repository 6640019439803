import type { FC } from 'react';

import classnames from 'classnames';

import styles from './DisabableContent.module.css';

type OuterProps = React.JSX.IntrinsicElements['div'];

export type DisabableContentProps = {
  disabled?: boolean;
} & OuterProps;

export const DisabableContent: FC<DisabableContentProps> = (props) => {
  const { className, children, disabled, ...otherProps } = props;
  return (
    <div
      className={classnames(styles.DisabableContent, className, 'relative', {
        'select-none': disabled,
      })}
      data-testid="DisabableContent"
      {...otherProps}
    >
      {children}
      {disabled && (
        <div className="absolute left-0 top-0 z-50 h-full w-full cursor-not-allowed bg-white opacity-70" />
      )}
    </div>
  );
};
