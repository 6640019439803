import type { GroupColType, PriceSheetRow } from '../types';

export const getPriceSheetItem = (
  rows: PriceSheetRow[] | undefined,
  colName: GroupColType,
  value: string,
  colName2?: GroupColType,
  value2?: string,
) =>
  rows?.find((row) =>
    value2 && colName2
      ? row[colName] === value && row[colName2] === value2
      : row[colName] === value,
  );
