import type { ElementType, ForwardedRef, ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useCallback, useMemo, useRef } from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useFilter } from '@react-aria/i18n';
import { useHover } from '@react-aria/interactions';
import { useOverlayPosition } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';

import type { InternalComponentProps, OverrideProps } from '../../types';
import type { ListBoxBaseProps } from '../ListBoxBase';
import type { BaseComboboxProps } from './BaseComboboxProps';
import type { AriaMultipleComboboxProps, MultipleComboboxState } from './useMultipleCombobox';
import { createComponent, mergeRefs } from '../../utils';
import { Chip } from '../Chip';
import { FormControl } from '../FormControl';
import { ListBoxBase } from '../ListBoxBase';
import { Overlay } from '../Overlay';
import { Popover } from '../Popover';
import { comboboxVariants } from './comboboxVariants';
import { SelectAllButtonPrivate } from './SelectAllButtonPrivate';
import { useMultipleCombobox } from './useMultipleCombobox';
import { useMultipleComboboxState } from './useMultipleComboboxState';

export interface MultipleComboboxTypeMap<
  AdditionalProps = {},
  DefaultComponent extends ElementType = 'div',
> {
  props: AdditionalProps &
    AriaMultipleComboboxProps<object> &
    VariantProps<typeof comboboxVariants> &
    BaseComboboxProps & {
      formatSelectedText?: (state: MultipleComboboxState<object>) => string;
      enableSelectAll?: boolean;
      selectAllContent?: ReactNode;
    };

  defaultComponent: DefaultComponent;
}

export type MultipleComboboxProps<
  RootComponent extends ElementType = MultipleComboboxTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<MultipleComboboxTypeMap<AdditionalProps, RootComponent>, RootComponent>;

type InternalMultipleComboboxProps<
  RootComponent extends ElementType = MultipleComboboxTypeMap['defaultComponent'],
  AdditionalProps = {},
> = InternalComponentProps<MultipleComboboxTypeMap<AdditionalProps, RootComponent>>;

export const MultipleCombobox = createComponent<MultipleComboboxTypeMap>(
  <BaseComponentType extends ElementType = MultipleComboboxTypeMap['defaultComponent']>(
    inProps: InternalMultipleComboboxProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const {
      as: Component = 'div',
      hideDropdownButton = false,
      label,
      noResult,
      isLoading,
      loadingText,
      helperText,
      helperTextProps: helperTextPropsProp,
      labelProps: labelPropsProp,
      isInvalid,
      isDisabled,
      autoFocus,
      containerRef: containerRefProp,
      className,
      maxHeight,
      isFullWidth,
      offset = 8,
      placement = 'bottom',
      size = 'sm',
      enableSelectAll = false,
      selectAllContent = 'Select all',
      formatSelectedText = ({ selectedItems, selectionManager }) => {
        if (selectionManager.isSelectAll) {
          return 'All items selected';
        }
        if (selectedItems.length === 1) {
          return '1 item selected';
        }
        return `${selectedItems.length} items selected`;
      },
    } = inProps;
    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const listBoxRef = useRef<HTMLDivElement>(null);
    const selectAllRef = useRef<HTMLButtonElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    const { contains } = useFilter({ sensitivity: 'base' });

    const state = useMultipleComboboxState({
      keepMenuOpen: true,
      selectionMode: 'multiple',
      ...inProps,
      // allow menu to open with no results if the user has set an
      // empty state or the combobox is indicated to be loading.
      // This enables us to open the menu and show these two states.
      allowsEmptyCollection: noResult !== undefined || isLoading,
      defaultFilter: contains,
      menuTrigger: 'focus',
    });
    const {
      buttonProps: triggerProps,
      inputProps,
      listBoxProps,
      labelProps,
      descriptionProps,
      errorMessageProps,
    } = useMultipleCombobox(
      {
        ...inProps,
        buttonRef,
        inputRef,
        listBoxRef,
        popoverRef,
      },
      state,
    );

    const { overlayProps } = useOverlayPosition({
      isOpen: state.isOpen,
      maxHeight,
      offset,
      onClose: state.close,
      overlayRef: popoverRef,
      placement,
      scrollRef: listBoxRef,
      shouldFlip: true,
      targetRef: containerRef,
    });

    const { buttonProps } = useButton(triggerProps, buttonRef);
    const { hoverProps, isHovered } = useHover({ isDisabled });
    const { isFocusVisible, isFocused, focusProps } = useFocusRing({
      autoFocus,
      within: true,
    });

    const styles = useMemo(
      () =>
        comboboxVariants({
          className,
          isDisabled,
          isFocusVisible,
          isFocused,
          isFullWidth,
          isHovered,
          isInvalid,
          isOpen: state.isOpen,
          size,
        }),
      [
        className,
        isDisabled,
        isFocusVisible,
        isFocused,
        isFullWidth,
        isHovered,
        isInvalid,
        size,
        state.isOpen,
      ],
    );

    const handleClose = useCallback(() => void state.close(), [state]);

    const handleSelectAll = useCallback(() => {
      if (state.selectionManager.isSelectAll) {
        state.setSelectedKeys(new Set([]));
      } else {
        state.setSelectedKeys('all');
      }
      state.close();
    }, [state]);

    const containerDimensions = containerRef.current?.getBoundingClientRect();

    return (
      <FormControl
        className={styles.formControl()}
        helperText={helperText}
        helperTextProps={mergeProps(descriptionProps, errorMessageProps, helperTextPropsProp)}
        isFullWidth={isFullWidth}
        isInvalid={isInvalid}
        label={label}
        labelProps={mergeProps(labelProps, labelPropsProp)}
      >
        <Component
          {...mergeProps(hoverProps, focusProps)}
          className={styles.base()}
          ref={mergeRefs(containerRef, ref)}
        >
          {state.selectedItems.length > 0 && (
            <Chip
              className={styles.chip()}
              size={size}
            >
              {formatSelectedText(state)}
            </Chip>
          )}
          <input
            {...inputProps}
            className={styles.input()}
            ref={inputRef}
          />

          {!hideDropdownButton && (
            <button
              {...buttonProps}
              className={styles.button()}
              ref={buttonRef}
            >
              <ChevronDownIcon className={styles.icon()} />
            </button>
          )}

          <Overlay
            containerRef={containerRefProp}
            isOpen={state.isOpen && !isDisabled}
          >
            <Popover
              {...overlayProps}
              className={styles.popover()}
              isOpen={state.isOpen}
              onClose={handleClose}
              ref={popoverRef}
              style={{
                ...overlayProps.style,
                left: containerDimensions?.left,
                width: containerDimensions?.width,
              }}
            >
              <div className={styles.listBoxWrapper()}>
                {enableSelectAll && (
                  <SelectAllButtonPrivate
                    isDisabled={isDisabled}
                    onPress={handleSelectAll}
                    ref={selectAllRef}
                    size={size}
                    type="button"
                  >
                    {selectAllContent}
                  </SelectAllButtonPrivate>
                )}
                <ListBoxBase
                  {...(listBoxProps as ListBoxBaseProps)}
                  className={styles.listBox()}
                  disallowEmptySelection
                  isLoading={isLoading}
                  loadingText={loadingText}
                  noResult={noResult}
                  ref={listBoxRef}
                  size={size}
                  state={state}
                />
              </div>
            </Popover>
          </Overlay>
        </Component>
      </FormControl>
    );
  },
);
