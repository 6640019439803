/**
 * "Returns a function that returns the account ID text with description."
 */
import type { ValueGetterParams } from 'ag-grid-community';

import type { griddy } from '@org/query';
import { getAccountIdTextWithDescription } from '@org/utils';

export const accountIdValue = (
  params: ValueGetterParams,
  accountsPlanMap?: griddy.GetAllAccountIdToAccountDescriptionMapResponse,
) => getAccountIdTextWithDescription(params?.data?.accountId, accountsPlanMap);
