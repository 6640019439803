import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const { onGridReady, gridRef, columnDefs, defaultColDef } = props;

  return (
    <BaseEditTable
      animateRows
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      ref={gridRef}
      rowSelection="single"
      suppressRowClickSelection={false}
      useAutoGeneratedRowId
    />
  );
};

export const DistributionTable = bindControllers(View, [useController]);
DistributionTable.displayName = 'DistributionTable';
