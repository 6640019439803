import { useCallback } from 'react';

import type { APIParams } from '../..';
import type { DeleteFileMetadata1PathParams, FileMetadataDTO } from '../dice';
import { useDeleteFileMetadata1, useGetFilesMetadata, useUpsertFileMetadata } from '../dice';

export interface UseFileMetadataParams extends APIParams {
  yearCalculationId?: string;
}

export function useFileMetadata({ yearCalculationId = '' }: UseFileMetadataParams) {
  const { data: filesMetadata, refetch: refetchFileMetadata } = useGetFilesMetadata(
    {
      pathParams: {
        yearCalculationId,
      },
    },
    {
      enabled: Boolean(yearCalculationId),
    },
  );

  const { mutateAsync: updateFileMetadataAsync, isPending: updateFileMetadataIsLoading } =
    useUpsertFileMetadata({});

  const { mutateAsync: deleteFileMetadataAsync, isPending: deleteFileMetadataIsLoading } =
    useDeleteFileMetadata1({});

  const updateFileMetadata = useCallback(
    async (body: FileMetadataDTO) => {
      await updateFileMetadataAsync({
        body,
      });
    },
    [updateFileMetadataAsync],
  );

  const createNewFileMetadata = useCallback(
    async ({
      type,
      yearCalcId,
    }: {
      type: FileMetadataDTO['type'];
      yearCalcId: FileMetadataDTO['yearCalculationId'];
    }) => {
      await updateFileMetadata({
        createdAtIsoDateTime: new Date().toISOString(),
        fileName: `cams-${type}`, // TODO: fix filename
        type,
        userId: '', // TODO: add userId
        yearCalculationId: yearCalcId,
      });
    },

    [updateFileMetadata],
  );

  const deleteFileMetadata = useCallback(
    async ({ yearCalculationId: id, fileType }: DeleteFileMetadata1PathParams) =>
      await deleteFileMetadataAsync({
        pathParams: {
          fileType,
          yearCalculationId: id,
        },
      }),
    [deleteFileMetadataAsync],
  );

  return {
    deleteFileMetadata,
    filesMetadata,
    isLoading: deleteFileMetadataIsLoading || updateFileMetadataIsLoading,
    refetchFileMetadata,
    updateFileMetadata,
    createNewFileMetadata,
  };
}
