/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import nameOf from 'easy-tsnameof';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps, ButtonHandlerProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { ENTRY, formatNonZeroNumberToLocaleString, GROUP } from '@org/utils';

export interface CostUnitBasicFeeEntryDTONew extends dice.CostUnitBasicFeeEntryDTO {
  groupName: string;
  groupId: string;
}

export const columns = nameOf<CostUnitBasicFeeEntryDTONew, never>();

interface UseColumnsProps {
  addNewRow: (type: string, props?: ButtonCellRendererProps) => Promise<void> | void;
  duplicateEntry: (type: string, props?: ButtonCellRendererProps) => Promise<void> | void;
  handleDeleteModal: (props: ButtonHandlerProps) => Promise<void> | void;
}

export const useColumns = ({ addNewRow, duplicateEntry, handleDeleteModal }: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        field: columns.groupName.$path,
        headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.description'),
        hide: true,
        rowGroup: true,
      },
      {
        editable: (params) => !(params.node.group || params.node.footer),
        field: columns.factor.$path,
        headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.factor'),
        type: 'price',
        valueFormatter: ({ value, node }) =>
          node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        editable: (params) => !(params.node.group || params.node.footer),
        field: columns.basis.$path,
        headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.basis'),
        type: 'price',
        valueFormatter: ({ value, node }) =>
          node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        editable: (params) =>
          !(params.node.group || params.node.footer || params.node.childIndex !== 0),
        field: columns.factorDependent.$path,
        headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.factorDependent'),
        type: 'price',
        valueFormatter: ({ value, node }) =>
          node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        field: columns.totalBasisAndFactorDependent.$path,
        headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.total'),
        type: 'price',
        valueFormatter: ({ value, node }) =>
          node?.group ? '' : formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        aggFunc: 'sum',
        editable: (params) => !(params.node.group || params.node.footer),
        field: columns.number.$path,
        headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.number'),
        type: 'price',
        valueFormatter: ({ value }) =>
          formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        aggFunc: 'sum',
        field: columns.totalNumberAndBasis.$path,
        headerName: t('main:manageMenu.manageCostUnitPriceSheet.tableColumns.totalNumberAndBasis'),
        type: 'price',
        valueFormatter: ({ value }) =>
          formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        aggFunc: 'sum',
        field: columns.totalNumberAndFactorDependent.$path,
        headerName: t(
          'main:manageMenu.manageCostUnitPriceSheet.tableColumns.totalNumberAndFactorDependent',
        ),
        type: 'price',
        valueFormatter: ({ value }) =>
          formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        aggFunc: 'sum',
        field: columns.totalNumberAndBasisAndFactorDependent.$path,
        headerName: t(
          'main:manageMenu.manageCostUnitPriceSheet.tableColumns.totalBasisAndFactorDependent',
        ),
        type: 'price',
        valueFormatter: ({ value }) =>
          formatNonZeroNumberToLocaleString(value, { defaultValue: '0,00' }),
      },
      {
        cellClass: 'align-to-center-and-right',
        cellRendererSelector: (params) => {
          const { node } = params;

          let icons;
          if (node.group && !node.footer) {
            icons = [
              {
                icon: 'icon-delete-outline',
                onClick: (data: ButtonCellRendererProps) =>
                  handleDeleteModal({ ...data, type: GROUP }),
                size: 'iconButton',
              },
              {
                icon: 'icon-plus-outline',
                onClick: (data: ButtonCellRendererProps) => addNewRow(ENTRY, data),
                size: 'iconButton',
              },
            ];
          } else if (!node.footer) {
            icons = [
              {
                icon: 'icon-delete-outline',
                onClick: (data: ButtonCellRendererProps) =>
                  handleDeleteModal({ ...data, type: ENTRY }),
                size: 'iconButton',
              },
              {
                icon: 'icon-duplicate-outline',
                onClick: (data: ButtonCellRendererProps) => duplicateEntry(ENTRY, data),
                size: 'iconButton',
              },
            ];
          }

          return {
            component: 'multipleButtons',
            params: {
              buttonParams: icons,
              className: 'flex',
            },
          };
        },
        editable: false,
        flex: 1,
        floatingFilterComponent: 'button',
        floatingFilterComponentParams: {
          className: 'justify-end',
          icon: 'icon-plus-fill',
          onClick: () => addNewRow(GROUP),
          size: 'iconButton',
          suppressFloatingFilterButton: true,
        },
        headerClass: 'ag-right-aligned-header',
        headerName: t('common:actions'),
        maxWidth: 140,
      },
    ],
    [t, handleDeleteModal, addNewRow, duplicateEntry],
  ) satisfies (ColDef | ColGroupDef)[];
};
