// FIXME: MIGRATE this functionality to the aggregated query
import { useMemo } from 'react';

import type { dice, rave } from '@org/query';

import type { BabRow } from '../../ReportTable/types';
import { prepareSubTotalRow } from '../helpers';
import { useBABExpenseValues } from './useBABExpenseValues';
import { useBABRevenueValues } from './useBABRevenueValues';

const getItem = (cc: rave.CostCenterDTO, type?: 'PROFIT_CENTER' | 'CC_ID' | 'JOB_ID') => {
  if (type === 'CC_ID') {
    return [...new Set(cc.clientCostCenterIds ?? [])].join(', ');
  }

  if (type === 'PROFIT_CENTER') {
    return [...new Set(cc.profitCenterIds ?? [])].join(', ');
  }

  if (type === 'JOB_ID') {
    return [...new Set(cc.jobIds ?? [])].join(', ');
  }

  return null;
};

export function useBABData(
  apiResponse?: rave.ReportsResponse,
  selectedConfiguration?: dice.MasterConfiguration,
) {
  const expenseValues = useBABExpenseValues(apiResponse, selectedConfiguration);
  const revenueValues = useBABRevenueValues(apiResponse, selectedConfiguration);

  const subTotalRow = prepareSubTotalRow(apiResponse?.babReport);

  const manuallyCorrectedCostCenters: BabRow['manuallyCorrectedCostCenters'] = useMemo(
    () =>
      [...expenseValues, ...revenueValues].flatMap(
        (account) => account.manuallyCorrectedCostCenters ?? [],
      ),
    [expenseValues, revenueValues],
  );

  const modifiedCostCenters: BabRow['modifiedCostCenters'] = useMemo(
    () =>
      [...expenseValues, ...revenueValues].flatMap((account) => account.modifiedCostCenters ?? []),
    [expenseValues, revenueValues],
  );

  const ccIdsRow = useMemo(() => {
    const { type } = selectedConfiguration?.babConfig ?? {};

    if (!type) {
      return null;
    }

    const customCcIdsRow = {
      accountId: '',
      rowIndex: 0,
      ...Object.fromEntries(
        (apiResponse?.costCenters ?? []).map((cc) => [cc.shortName, getItem(cc, type)]),
      ),
    };

    return customCcIdsRow;
  }, [apiResponse?.costCenters, selectedConfiguration?.babConfig]);

  const rowData = [...expenseValues, ...revenueValues, subTotalRow].map((row, index) => {
    row.rowIndex = index + 1;
    return row;
  });

  if (ccIdsRow) {
    rowData.unshift(ccIdsRow);
  }

  return {
    manuallyCorrectedCostCenters,
    modifiedCostCenters,
    rowData,
  };
}
