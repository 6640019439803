import type { FC } from 'react';

import type { ReportsResponse } from '@org/features';
import { Combobox, ComboboxItem } from '@org/design';
import { BABReportTable } from '@org/features';
import { useTranslation } from '@org/locales';
import { Button, Switch } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const View: FC<ViewProps> = ({
  handleDataChange,
  handleExport,
  isCCIdsVisible,
  masterConfiguration,
  options,
  showManualCorrections,
  setShowManualCorrections,
  DEFAULT_CC_TYPE,
  doesExternalFilterPass,
  getReportExcelFileName,
  isExternalFilterPresent,
  tableGridRef,
  apiParams,
  modal,
  handleOpenModal,
  selectedReport,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="float-right inline-flex items-center gap-4">
        <div className="flex space-x-2">
          <Switch
            className="switch-show-changes"
            isSelected={isCCIdsVisible}
            onValueChange={(value) => {
              handleDataChange(value, DEFAULT_CC_TYPE);
            }}
          >
            {t('main:reportPanels.babReport.showNumbers')}
          </Switch>
          <div className="w-52">
            <Combobox
              aria-label="select cc type"
              isDisabled={!isCCIdsVisible}
              onSelectionChange={(key) => {
                handleDataChange(isCCIdsVisible, key as string);
              }}
              selectedKey={masterConfiguration?.babConfig?.type ?? DEFAULT_CC_TYPE}
            >
              {options.map(({ value, label }) => (
                <ComboboxItem key={value}>{t(label)}</ComboboxItem>
              ))}
            </Combobox>
          </div>
        </div>
        <Switch
          className="switch-show-changes"
          isSelected={showManualCorrections}
          onValueChange={() =>
            setShowManualCorrections((currentShowChanges) => !currentShowChanges)
          }
        >
          {t('main:reportPanels.manualChanges.switchShowChanges')}
        </Switch>
        <Button
          onClick={handleExport}
          size="sm"
          variant="primary"
        >
          <span className="appkiticon icon-download-outline" />
          EXPORT
        </Button>
      </div>

      <div className="flex h-[calc(100vh-200px)] w-[calc(100vw-50px)] p-0 pt-3">
        <BABReportTable
          apiParams={apiParams}
          apiResponse={selectedReport as ReportsResponse}
          doesExternalFilterPass={doesExternalFilterPass}
          getReportExcelFileName={getReportExcelFileName}
          handleOpenModal={handleOpenModal}
          isCCIdsVisible={isCCIdsVisible}
          isExternalFilterPresent={isExternalFilterPresent}
          showModifications={showManualCorrections}
          tableGridRef={tableGridRef}
        />
      </div>
      {modal}
    </>
  );
};

export const BabReport = bindControllers(View, [useController]);
BabReport.displayName = 'BabReport';
