import type { CostCenter } from '../../griddy';

const costCenterReduce = (costCenterMap: Map<string, CostCenter[]>, costCenter: CostCenter) => {
  const { group = '' } = costCenter;
  if (costCenterMap.has(group)) {
    costCenterMap.get(group)?.push(costCenter);
  } else {
    costCenterMap.set(group, [costCenter]);
  }

  return costCenterMap;
};
export const groupCostCenters = (costCenters: CostCenter[] = []) => {
  const { primary, indirect } = costCenters.reduce(
    (acc, costCenter) => {
      if (costCenter.costCenterType === 'INDIRECT_COST_CENTER') {
        acc.indirect.push(costCenter);
      } else {
        acc.primary.push(costCenter);
      }
      return acc;
    },
    {
      indirect: [] as CostCenter[],
      primary: [] as CostCenter[],
    },
  );

  return {
    indirectCostCentersGrouped: Array.from(indirect.reduce(costCenterReduce, new Map()).entries()),
    primaryCostCentersGrouped: Array.from(primary.reduce(costCenterReduce, new Map()).entries()),
  } as const;
};
