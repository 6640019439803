import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from '@org/locales';

interface CommentAreaTextProps {
  comment: string;
  handleOnChangeComment: (value: string) => Promise<void>;
}

export const CommentTextArea = ({ comment, handleOnChangeComment }: CommentAreaTextProps) => {
  const { t } = useTranslation();

  const { control } = useForm({
    defaultValues: {
      comment,
    },
  });

  return (
    <div className="m-2 space-y-2">
      <div className="font-bold">{t('main:comments.header')}</div>
      <Controller
        control={control}
        name="comment"
        render={({ field }) => (
          <textarea
            {...field}
            className="w-full border border-solid border-gray-500 bg-white p-2"
            onChange={async ({ target: { value } }) => {
              field.onChange(value);
              await handleOnChangeComment(value);
            }}
            rows={4}
          />
        )}
      />
    </div>
  );
};
