import debug from 'debug';

import config from '../config';
import { objGet } from './object';

const BASE = config.APP_NAME;
const COLOURS = {
  error: 'red',
  info: 'blue',
  trace: 'lightblue',
  warn: 'pink',
};

class Logger {
  generateMessage(level, message, source) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);
    createDebug.color = objGet(COLOURS, level);

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  }

  trace(message, source) {
    return this.generateMessage('trace', message, source);
  }

  info(message, source) {
    return this.generateMessage('info', message, source);
  }

  warn(message, source) {
    // eslint-disable-next-line no-console
    console.warn(`[${source}]`, message);
    return this.generateMessage('warn', message, source);
  }

  error(message, source) {
    console.error(`[${source}]`, message);
    return this.generateMessage('error', message, source);
  }
}

export default new Logger();
