import type { FC } from 'react';
import { useRef } from 'react';

import { useVirtualizer } from '@tanstack/react-virtual';

export interface VirtualizedListProps {
  height?: number;
  width?: string;
  itemSize?: number;
  itemCount: number;
  Component: React.ComponentType<{ index: number }>;
  'data-testid'?: string;
}

export const VirtualizedList: FC<VirtualizedListProps> = (props) => {
  const { height = 250, width = '100%', itemSize = 25, itemCount, Component, ...rest } = props;
  const parentRef = useRef(null);

  const rowVirtualizer = useVirtualizer({
    count: itemCount,
    estimateSize: () => itemSize,
    getScrollElement: () => parentRef.current,
  });

  return (
    <>
      <div
        className="overflow-auto"
        data-testid="VirtualizedList"
        ref={parentRef}
        style={{
          height,
        }}
        {...rest}
      >
        <div
          className="relative overflow-x-hidden break-words"
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width,
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <div
              className="absolute left-0 top-0 w-full overflow-hidden p-0.5"
              data-index={virtualItem.index}
              key={virtualItem.key}
              ref={rowVirtualizer.measureElement}
              style={{
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <Component index={virtualItem.index} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
