import type { FC } from 'react';
import { memo } from 'react';

import { useDisclosure } from '@nextui-org/react';

import { useTranslation } from '@org/locales';
import { aggregated, dice } from '@org/query';
import { BaseConfirmationModal, DeleteButton, getErrorMessage, showNotification } from '@org/ui';

export interface DeleteUploadedFileModalButtonProps {
  fileId?: string;
  apiFileType: aggregated.ApiFileType;
  fileName?: string;
  yearId: string;
  masterConfigurationId?: string;
}

const DeleteUploadedFileModalButtonMemo: FC<DeleteUploadedFileModalButtonProps> = ({
  fileId,
  fileName = '',
  apiFileType,
  masterConfigurationId,
  yearId,
}) => {
  const { t } = useTranslation();

  const {
    onOpenChange: onOpenChangeDeleteModal,
    isOpen: deleteModalVisible,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();

  const { refetch: refetchGetFile } = dice.useGetFile(
    {
      queryParams: {
        fileType: apiFileType,
        yearId,
      },
    },
    {
      enabled: false,
    },
  );

  const { isLoading, deleteFile } = aggregated.useFiles({
    globalErrorHandling: onError,
  });

  const { refetchFileMetadata } = aggregated.useFileMetadata({
    yearCalculationId: yearId,
  });

  function onError(error: aggregated.DeleteFileErrorType) {
    showNotification(
      'error',
      t('main:clients.menu.fileMenu.deleteModal.error', {
        msg: getErrorMessage(error, t),
      }),
      5000,
    );
    closeDeleteModal();
  }

  const handleDeleteFile = async () => {
    await deleteFile({ fileId, fileType: apiFileType, yearCalculationId: yearId });
    closeDeleteModal();
    await refetchGetFile();
    await refetchFileMetadata();

    showNotification('success', t('main:manageMenu.alerts.success.deletedRow'));
  };

  return (
    <>
      <div>
        <DeleteButton
          className="mr-3"
          isDisabled={!!masterConfigurationId}
          onClick={openDeleteModal}
          size="md"
        />
      </div>
      <BaseConfirmationModal
        contentText={
          isLoading
            ? t('main:clients.menu.fileMenu.deleteModal.loadingLabelKey')
            : t('main:clients.menu.fileMenu.deleteModal.text', {
                fileName,
              })
        }
        isOpen={deleteModalVisible}
        onCancel={closeDeleteModal}
        onConfirm={handleDeleteFile}
        onOpenChange={onOpenChangeDeleteModal}
        titleText={t('main:clients.menu.fileMenu.deleteModal.title', {
          apiFileType,
        })}
      />
    </>
  );
};

export const DeleteUploadedFileModalButton = memo(DeleteUploadedFileModalButtonMemo);
