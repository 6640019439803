import { useCallback, useMemo } from 'react';

import type { APIParams } from '../../types';
import type {
  PlannedAccountEntryDTO,
  PlannedCostRevenueDetailDTO,
  PlannedCostTypeAccountEntryDTO,
} from '../dice';
import {
  useCreatePlannedCostRevenueDirectEntry,
  useGetPlannedCostRevenueConfig,
  useGetPlannedCostRevenuePreselectedValues,
  useSavePlannedCostRevenueAccountEntry,
  useSavePlannedCostRevenueConfigDetails,
  useSavePlannedCostRevenueCostTypeAccountEntry,
} from '../dice';

interface UsePlannedCostRevenueProps extends APIParams<'masterConfigurationId'> {}

export const usePlannedCostRevenue = ({ masterConfigurationId }: UsePlannedCostRevenueProps) => {
  const { data: plannedCostRevenue, isPending } = useGetPlannedCostRevenueConfig(
    {
      pathParams: {
        masterConfigurationId: masterConfigurationId ?? '',
      },
    },
    {
      select: (data) => ({
        plannedCostRevenueDetail: data?.plannedCostRevenueDetail ?? {},
        plannedCostTypeAccountEntries: data?.plannedCostTypeAccountEntries ?? [],
        plannedAccountEntries:
          data?.plannedCostTypeAccountEntries
            ?.sort((entryA, entryB) => (entryA?.rank ?? 0) - (entryB?.rank ?? 0))
            ?.flatMap(
              (entry) =>
                entry.plannedAccountEntries?.flatMap((accountEntry) => ({
                  ...accountEntry,
                  costTypeAccountName: entry?.costTypeAccountName,
                })) ?? [],
            ) ?? [],
      }),
    },
  );
  const updatePlannedCostRevenueDetailsMutation = useSavePlannedCostRevenueConfigDetails({});

  const { data: preselectedValues } = useGetPlannedCostRevenuePreselectedValues({
    pathParams: {
      masterConfigurationId: masterConfigurationId ?? '',
    },
  });

  const preselectedTechnicalDataOptions = useMemo(
    () =>
      preselectedValues?.technicalDataGroups?.map(({ id, name }) => ({
        label: name ?? '',
        value: id ?? '',
      })) ?? [],
    [preselectedValues],
  );

  const { mutateAsync: updatePlannedCostRevenueCostTypeAccountEntryAsync } =
    useSavePlannedCostRevenueCostTypeAccountEntry();

  const { mutateAsync: updatePlannedCostRevenueAccountEntryAsync } =
    useSavePlannedCostRevenueAccountEntry();

  const { mutateAsync: createPlannedCostRevenueDirectEntryAsync } =
    useCreatePlannedCostRevenueDirectEntry();

  const updatePlannedCostRevenueCostTypeAccountEntry = useCallback(
    async (updatedPlannedCostTypeAccountEntry: PlannedCostTypeAccountEntryDTO) =>
      await updatePlannedCostRevenueCostTypeAccountEntryAsync({
        body: updatedPlannedCostTypeAccountEntry,
        pathParams: {
          plannedCostTypeAccountEntryId: updatedPlannedCostTypeAccountEntry.id ?? '',
          masterConfigurationId,
        },
      }),
    [masterConfigurationId, updatePlannedCostRevenueCostTypeAccountEntryAsync],
  );

  const updatePlannedCostRevenueAccountEntry = useCallback(
    async (updatedPlannedAccountEntry: PlannedAccountEntryDTO) =>
      await updatePlannedCostRevenueAccountEntryAsync({
        body: updatedPlannedAccountEntry,
        pathParams: {
          masterConfigurationId,
          plannedAccountEntryId: updatedPlannedAccountEntry?.id ?? '',
          plannedCostTypeAccountEntryId: updatedPlannedAccountEntry?.originalId ?? '',
        },
      }),
    [masterConfigurationId, updatePlannedCostRevenueAccountEntryAsync],
  );

  const createPlannedCostRevenueDirectEntry = useCallback(
    async ({
      plannedAccountEntryId,
      plannedCostTypeAccountEntryId,
      directEntryName,
    }: {
      plannedAccountEntryId: string;
      plannedCostTypeAccountEntryId: string;
      directEntryName: string;
    }) =>
      await createPlannedCostRevenueDirectEntryAsync({
        pathParams: {
          directEntryName,
          masterConfigurationId,
          plannedAccountEntryId,
          plannedCostTypeAccountEntryId,
        },
      }),
    [createPlannedCostRevenueDirectEntryAsync, masterConfigurationId],
  );

  const updatePlannedCostRevenueDetails = useCallback(
    (body: PlannedCostRevenueDetailDTO) =>
      updatePlannedCostRevenueDetailsMutation.mutateAsync({
        pathParams: {
          masterConfigurationId,
        },
        body,
      }),
    [masterConfigurationId, updatePlannedCostRevenueDetailsMutation],
  );

  const getPlannedDirectEntry = useCallback(
    ({
      plannedAccountEntryId,
      plannedCostTypeAccountEntryId,
    }: {
      plannedAccountEntryId: string;
      plannedCostTypeAccountEntryId: string;
    }) => {
      const selectedPlannedAccountEntryNew = plannedCostRevenue?.plannedAccountEntries.find(
        (entry) => entry?.id === plannedAccountEntryId,
      );

      return selectedPlannedAccountEntryNew?.plannedDirectEntries?.find(
        (entry) => entry?.id === plannedCostTypeAccountEntryId,
      );
    },
    [plannedCostRevenue?.plannedAccountEntries],
  );

  return {
    plannedCostRevenueDetail: plannedCostRevenue?.plannedCostRevenueDetail ?? {},
    plannedAccountEntries: plannedCostRevenue?.plannedAccountEntries ?? [],
    isPending: isPending || updatePlannedCostRevenueDetailsMutation.isPending,
    plannedCostTypeAccountEntries: plannedCostRevenue?.plannedCostTypeAccountEntries,
    updatePlannedCostRevenueDetails,
    updatePlannedCostRevenueAccountEntry,
    createPlannedCostRevenueDirectEntry,
    getPlannedDirectEntry,
    preselectedTechnicalDataOptions,
    updatePlannedCostRevenueCostTypeAccountEntry,
  };
};

export interface FlattenPlannedAccountEntries extends PlannedAccountEntryDTO {
  costTypeAccountName: string;
}
