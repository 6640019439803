import { isEmptyOrNullArray } from '../../../../utils/formatUtils';

function isCostCenterNameInProfitCenter(profitCenter, costCenterName, mapping) {
  if (
    mapping.selectEntireCostCenter ||
    (!mapping.allTransactionsWithNoJobId && isEmptyOrNullArray(mapping.jobIds))
  ) {
    return Object.keys(profitCenter.profitCenterTree).includes(costCenterName);
  }
  return mapping.jobIds.some((jobId) =>
    Object.entries(profitCenter.profitCenterTree).some(
      ([costCenter, jobIds]) => costCenter === costCenterName && jobIds.includes(jobId),
    ),
  );
}

export function isCostCenterNameInMapping(mapping, costCenterName, profitCenterTrees) {
  const isCostCenterIdsIncludesCostCenterName = Boolean(
    mapping?.costCenterIds?.includes(costCenterName),
  );

  if (isCostCenterIdsIncludesCostCenterName) {
    return true;
  }

  const isEntireProfitCenterSelected = Boolean(mapping?.selectEntireProfitCenter);
  const isEmptyCostCenterIds = isEmptyOrNullArray(mapping?.costCenterIds);
  const isAllTransactionsWithNoCostCenterNotSelected = !mapping?.allTransactionsWithNoCostCenter;

  return Boolean(
    (isEntireProfitCenterSelected ||
      (isAllTransactionsWithNoCostCenterNotSelected && isEmptyCostCenterIds)) &&
      profitCenterTrees?.some(
        (pct) =>
          (mapping?.ignoreProfitCenters ||
            mapping?.allTransactionsWithNoProfitCenter ||
            mapping?.profitCenterIds.includes(pct?.profitCenterId)) &&
          isCostCenterNameInProfitCenter(pct, costCenterName, mapping),
      ),
  );
}

export function isCostCenterNameInCostCenter(costCenterName, costCenter, profitCenterTrees) {
  if (costCenterName === costCenter.shortName) {
    return true;
  }
  return costCenter.costCenterMappings?.some((mapping) =>
    isCostCenterNameInMapping(mapping, costCenterName, profitCenterTrees),
  );
}
