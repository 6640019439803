import { CheckboxCellRenderer } from '@org/ui';

interface AccountIdsTableCheckboxProps {
  data: { accountId: string; accountDescription: string };
  value: string;
  accountIds: string[];
  onSelectionChanged: (selection: string[]) => Promise<void>;
}

export const AccountIdsTableCheckbox = ({
  data,
  value,
  onSelectionChanged,
  accountIds,
}: AccountIdsTableCheckboxProps) => {
  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      await onSelectionChanged([...accountIds, value]);
    } else {
      const removedArray = accountIds.filter((item) => item !== value);

      await onSelectionChanged(removedArray);
    }
  };

  const isSelected = accountIds?.includes(data.accountId);

  return (
    <CheckboxCellRenderer
      label={`${data.accountId} - ${data.accountDescription}`}
      onEdit={handleOnEdit}
      value={isSelected}
    />
  );
};
