import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import type { APIParams, dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

interface UseColumnsProps {
  apiParams: APIParams<'masterConfigurationId'>;
  isDisabled: boolean;
  handleResetRow: (costCenterInterestRates: dice.CostCenterInterestRates) => void;
}

export const useColumns = ({ isDisabled, handleResetRow, apiParams }: UseColumnsProps) => {
  const { t } = useTranslation();
  const { getCostCenterLongName } = aggregated.useCostCenters(apiParams);

  return useMemo(
    () =>
      [
        {
          checkboxSelection: true,
          colId: 'ccShortName',
          field: 'ccShortName',
          filterValueGetter: (params) => getCostCenterLongName(params?.data?.ccShortName ?? ''),
          flex: 2,
          headerCheckboxSelection: true,
          headerName: t('common:costCenter'),
          valueFormatter: ({ value }) => getCostCenterLongName(value),
        },
        {
          cellClass: 'align-to-center-and-right',
          colId: 'interestRate',
          editable: !isDisabled,
          field: 'interestRate',
          flex: 1,
          headerName: t('main:manageMenu.manageInterestRates.tableColumns.interestRate'),
          type: 'number',
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRenderer: 'button',
          cellRendererParams: {
            className: 'flex',
            disabled: isDisabled,
            icon: 'icon-refresh-outline',
            onClick: (params: ICellRendererParams) => handleResetRow(params.data),
            size: 'iconButton',
          },
          colId: 'actions',
          flex: 0,
          floatingFilter: false,
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          sortable: false,
          width: 100,
        },
      ] satisfies ColDef<dice.CostCenterInterestRates>[],
    [getCostCenterLongName, handleResetRow, isDisabled, t],
  );
};
