import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerProps, ControllerType } from './useController';
import { useController } from './useController';

export type CostTypeAccountMasterDetailProps = ControllerProps;

const View: FC<ControllerType> = (props) => {
  const { columnDefs, defaultColDef, agGridProps, getRowId, tableRef } = props;
  return (
    <div className="h-[415px]">
      <BaseEditTable
        {...agGridProps}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        ref={tableRef}
      />
    </div>
  );
};

export const CostTypeAccountMasterDetail = bindControllers(View, [useController]);
CostTypeAccountMasterDetail.displayName = 'CostTypeAccountMasterDetail';
