import type { Node, SelectionMode } from '@react-types/shared';
import type { ElementType, ForwardedRef } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo } from 'react';

import { useListBoxSection } from '@react-aria/listbox';
import { tv } from 'tailwind-variants';

import type { OverrideProps } from '../../types';
import { createComponent } from '../../utils';
import { useListBoxContext } from '../ListBoxBase/ListBoxContext';
import { ListBoxItemPrivate } from '../ListBoxItem';

const listBoxSectionVariants = tv({
  slots: {
    base: 'flex flex-col gap-1',
    group: 'flex flex-col gap-1',
    heading: 'bg-actions-hover px-2 py-1 text-sm',
  },
  variants: {
    size: {
      md: {},
      sm: {
        heading: 'text-xs',
      },
    },
  },
});

export interface ListBoxSectionPrivateTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props: AdditionalProps &
    VariantProps<typeof listBoxSectionVariants> & {
      /**
       * Item object in the collection.
       */
      item: Node<object>;
      /**
       * The type of selection that is allowed in the collection.
       */
      selectionMode?: SelectionMode;
    };
  defaultComponent: DefaultComponent;
}

export type ListBoxSectionPrivateProps<
  RootComponent extends ElementType = ListBoxSectionPrivateTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<ListBoxSectionPrivateTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const ListBoxSectionPrivate = createComponent<ListBoxSectionPrivateTypeMap>(
  <BaseComponentType extends React.ElementType = ListBoxSectionPrivateTypeMap['defaultComponent']>(
    inProps: ListBoxSectionPrivateProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const { as: Component = 'div', item, className, size, selectionMode } = inProps;

    const { state } = useListBoxContext()!;

    const { itemProps, headingProps, groupProps } = useListBoxSection({
      'aria-label': item['aria-label'],
      heading: item.rendered,
    });

    const styles = useMemo(
      () =>
        listBoxSectionVariants({
          className,
          size,
        }),
      [className, size],
    );

    return (
      <Component
        {...itemProps}
        className={styles.base()}
        ref={ref}
      >
        {item.rendered && (
          <div
            {...headingProps}
            className={styles.heading()}
          >
            {item.rendered}
          </div>
        )}
        <div
          {...groupProps}
          className={styles.group()}
        >
          {[...(state.collection.getChildren?.(item.key) ?? [])].map((node) => (
            <ListBoxItemPrivate
              item={node}
              key={node.key}
              selectionMode={selectionMode}
              size={size}
            />
          ))}
        </div>
      </Component>
    );
  },
);
