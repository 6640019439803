import type { FileDropItem } from '@react-types/shared';
import React, { useState } from 'react';

import { ArrowUpOnSquareIcon, DocumentCheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import type { FileTriggerProps } from '@org/design';
import { Button, DropZone, FileTrigger, Pressable } from '@org/design';
import { useTranslation } from '@org/locales';

import { Spinner } from '../Svg/components';

interface UploadProps {
  allowMultiple?: FileTriggerProps['allowsMultiple'];
  acceptDirectory?: FileTriggerProps['acceptDirectory'];
  acceptedFileTypes?: FileTriggerProps['acceptedFileTypes'];
  onChange?: (files: File[]) => void | Promise<void>;
  onUpload: (files: File[]) => void | Promise<void>;
  children: React.ReactNode;
  isBtnDisabled?: boolean;
  isIconVisible?: boolean;
  isUploading?: boolean;
}

export const Upload = ({
  allowMultiple = false,
  acceptDirectory = false,
  acceptedFileTypes = [],
  onChange,
  onUpload,
  isIconVisible = true,
  children,
  isBtnDisabled = false,
  isUploading = false,
}: UploadProps) => {
  const [addedFiles, setFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  return (
    <>
      {isUploading ? (
        <div className="flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-sm border-1 border-dashed border-gray-500 p-8 text-center">
          <Spinner
            className="m-8"
            height="48px"
            width="48px"
          />
        </div>
      ) : (
        <FileTrigger
          acceptDirectory={acceptDirectory}
          acceptedFileTypes={acceptedFileTypes}
          allowsMultiple={allowMultiple}
          onSelect={(e) => {
            const files = Array.from(e ?? []);
            setFiles(files);
            onChange?.(files);
          }}
        >
          <Pressable>
            <div>
              <DropZone
                onDrop={async (e) => {
                  if (!allowMultiple && e.items.length > 1) {
                    return;
                  }
                  let fileItems = e.items.filter((file) => file.kind === 'file') as FileDropItem[];

                  if (acceptedFileTypes?.length > 0) {
                    fileItems = fileItems.filter((file) => acceptedFileTypes?.includes(file.type));
                    if (fileItems.length === 0) {
                      return;
                    }
                  }

                  const files = await Promise.all(fileItems.map((fileItem) => fileItem.getFile()));
                  setFiles(files);
                  onChange?.(files);
                }}
                variant="bordered"
              >
                <div
                  className="flex flex-col items-center justify-center"
                  slot="label"
                >
                  {isIconVisible && <ArrowUpOnSquareIcon className="h-8 w-8 text-orange-default" />}
                  {children}
                </div>
              </DropZone>
            </div>
          </Pressable>
        </FileTrigger>
      )}
      {addedFiles?.length > 0 && (
        <div className="w-full border-1 border-y-0 border-dashed border-gray-500 bg-actions-hover">
          <ul className="">
            {addedFiles.map((file) => (
              <li
                className="flex w-full justify-between border-b-1 border-dashed border-gray-500 p-3"
                key={file.name}
              >
                <div className="flex items-center">
                  <DocumentCheckIcon className="h-5 w-5 text-black" />
                  <div className="pl-4 text-black">{file.name}</div>
                </div>
                <div className="cursor-pointer hover:bg-actions-hover">
                  <XMarkIcon
                    className="h-5 w-5 text-black"
                    onClick={() =>
                      setFiles(addedFiles.filter((everyFile) => everyFile.name !== file.name))
                    }
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <Button
        className="mt-4 w-full"
        isDisabled={addedFiles?.length === 0 || isBtnDisabled}
        onClick={() => onUpload(addedFiles)}
      >
        {t('common:upload')}
      </Button>
    </>
  );
};
