import type { dice, griddy } from '../../../../query/src';
import { move, sortByRank } from '../../array';
import { costUnitAllocationsByCostCenterShortName } from './updateCostUnitAllocations';
import { updateImputedConcessionFeeByCostCenters } from './updateImputedConcessionFee';
import { updateImputedWorkingCapitalByCostCenterAdd as updateImputedWorkingCapital } from './updateImputedWorkingCapital';
import { updateInterestRatesByCostCenters } from './updateInterestRates';
import { resetAutomaticAllocations } from './updateWithdrawCapital';
import { isPrimaryCostCenter } from './utils';

export type AddCostCenterToMasterConfiguration = (
  costCenter: dice.CostCenter,
  masterConfiguration: dice.MasterConfiguration,
  accountCostCentersMap: Record<number, string[]>,
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse,
) => dice.MasterConfiguration;

export const addCostCenterToMasterConfiguration: AddCostCenterToMasterConfiguration = (
  costCenter,
  masterConfiguration,
  accountCostCentersMap,
  flatProfitCenterTree,
): dice.MasterConfiguration => {
  const { costCenterMapping: _costCenterMapping = [] } = masterConfiguration.costCenterConfig;

  // add new cost center to the list or update one via spread (number of items will be the same)
  const filteredCostCenterMapping = [
    ..._costCenterMapping.filter((r) => r.shortName !== costCenter.shortName),
    costCenter,
  ];

  let costCenterMapping = filteredCostCenterMapping;
  const ccRank = costCenter.rank ?? filteredCostCenterMapping.length + 1;

  // reorder cost centers in master config new cc is added
  if (ccRank !== filteredCostCenterMapping.length) {
    const lastCostCenterRank =
      filteredCostCenterMapping.length > 0 ? filteredCostCenterMapping.length : 1;

    const filteredCostCenterMappingLastIdx = filteredCostCenterMapping.length - 1;
    const lastCC = filteredCostCenterMapping[filteredCostCenterMappingLastIdx]!;
    lastCC.rank = lastCostCenterRank;

    const unsortedCostCenterMapping = filteredCostCenterMapping.slice(0);
    unsortedCostCenterMapping[filteredCostCenterMappingLastIdx] = lastCC;

    costCenterMapping = sortByRank(unsortedCostCenterMapping);

    // swap cc in list
    costCenterMapping = move<dice.CostCenter>(
      costCenterMapping,
      costCenterMapping.length - 1, // new cc in added at the end of the list
      ccRank - 1, // needs to be placed on index specified with cc rank
    ).map((row, index) => ({
      ...row,
      rank: index + 1,
    }));
  }

  const masterConfigurationWithCostCenterMapping = {
    ...masterConfiguration,
    costCenterConfig: {
      ...masterConfiguration.costCenterConfig,
      costCenterMapping,
    },
  };
  return updateOtherConfigurationSteps({
    accountCostCentersMap,
    costCenter,
    flatProfitCenterTree,
    masterConfiguration: masterConfigurationWithCostCenterMapping,
  });
};

const updateOtherConfigurationSteps = (props: {
  costCenter: dice.CostCenter;
  masterConfiguration: dice.MasterConfiguration;
  accountCostCentersMap: Record<number, string[]>;
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse;
}): dice.MasterConfiguration => {
  const { costCenter, masterConfiguration, accountCostCentersMap, flatProfitCenterTree } = props;
  /* TODO: update only needed parts for CostUnitAllocationConfig, InterestRates
      and do ot mutate the object */
  let updatedConfiguration = updateCostUnitAllocationConfig({
    costCenter,
    masterConfiguration,
  });
  updatedConfiguration = updateInterestRatesByCostCenters(updatedConfiguration);
  const concessionFeeConfig = updateImputedConcessionFeeByCostCenters(updatedConfiguration);
  const imputedWorkingCapitalConfig = updateImputedWorkingCapital({
    costCenter,
    imputedWorkingCapitalConfig: masterConfiguration.imputedWorkingCapitalConfig,
  });
  const withdrawCapitalConfig = resetAutomaticAllocations(
    updatedConfiguration.withdrawCapitalConfig ?? [],
    updatedConfiguration.costCenterConfig,
    accountCostCentersMap,
    flatProfitCenterTree,
  );
  return {
    ...updatedConfiguration,
    concessionFeeConfig,
    imputedWorkingCapitalConfig,
    withdrawCapitalConfig,
  };
};

const updateCostUnitAllocationConfig = (props: {
  costCenter: dice.CostCenter;
  masterConfiguration: dice.MasterConfiguration;
}): dice.MasterConfiguration => {
  const { costCenter, masterConfiguration } = props;
  const { costCenterToCostUnitDistributions = [] } =
    masterConfiguration?.costUnitAllocationConfig ?? {};

  const isNewCostCenterExisting = costCenterToCostUnitDistributions.some(
    (r) => r.primaryCostCenterShortName === costCenter.shortName,
  );

  if (isPrimaryCostCenter(costCenter) && !isNewCostCenterExisting) {
    const newCostUnitAllocations = costUnitAllocationsByCostCenterShortName(
      costCenter?.shortName ?? '',
    );
    return {
      ...masterConfiguration,
      costUnitAllocationConfig: {
        ...masterConfiguration?.costUnitAllocationConfig,
        costCenterToCostUnitDistributions: [
          ...costCenterToCostUnitDistributions,
          ...newCostUnitAllocations,
        ],
      },
    };
  }
  return masterConfiguration;
};
