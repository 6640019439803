import type { rave } from '@org/query';
import { t } from '@org/locales';

import type { ACCOUNT_TYPES, BabCtaEntryRecord, BabRow } from '../../ReportTable/types';

export const flattenAPIResponseToRows = (
  ctaEntries: BabCtaEntryRecord,
  accountType: ACCOUNT_TYPES,
): BabRow[] =>
  Object.entries(ctaEntries).flatMap(([costTypeName, ctaValues]) =>
    Object.entries(ctaValues.ctaAccounts ?? []).map(([accountId, accountValues]) => ({
      ...accountValues.costCenterValueMap,
      accountId,
      accountType,
      correction: accountValues.correction,
      costTypeAccountRank: ctaValues.rank,
      costTypeName,
      expenses_revenues_after_correction: accountValues.expenses_revenues_after_correction,
      expenses_revenues_before_correction: accountValues.expenses_revenues_before_correction,
    })),
  );

export const flattenWicapAPIResponseToRows = (
  wicapEntries: Record<string, rave.BabImputedWicapEntry>,
  accountType: ACCOUNT_TYPES,
): BabRow[] =>
  Object.entries(wicapEntries).flatMap(([wicapKey, wicapValues]) =>
    Object.entries(wicapValues?.imputedWicapGroups ?? {}).map(([groupName, groupValues]) => ({
      ...groupValues.entryMap,
      accountId: groupName,
      accountType,
      correction: groupValues.correction,
      costTypeName: t(`main:reportPanels.babReport.tableRows.${wicapKey}`),
      expenses_revenues_after_correction: groupValues.expenses_revenues_after_correction,
      expenses_revenues_before_correction: groupValues.expenses_revenues_before_correction,
    })),
  );
