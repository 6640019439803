import type { CorrectAny } from '@org/models';
import type { griddy } from '@org/query';

export enum ACCOUNT_TYPES {
  EXPENSE = 'EXPENSE',
  REVENUE = 'REVENUE',
}

export interface BabRow extends griddy.BabCtaEntry {
  accountId?: string | number | null;
  accountType?: ACCOUNT_TYPES;
  groupName?: string;
  costTypeName?: string;
  costTypeAccountRank?: number;
  modifiedCostCenters?: (string | undefined)[];
  manuallyCorrectedCostCenters?: (string | undefined)[];
  modifications?: number;
  [key: string]: CorrectAny;
}

export type BabCtaEntryRecord = Record<string, griddy.BabCtaEntry>;

export interface BabReportEntry {
  rowName: string;
  costCenterValueMap: Record<string, number>;
  expenses_revenues_before_correction: number;
  expenses_revenues_after_correction: number;
  correction: number;
}

export type GroupedCostCenter = [string, griddy.CostCenter[]];
