import { useEffect } from 'react';

export const useKeys = (key: string, callback: () => void) => {
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      let keyArray = [];
      if (!Array.isArray(key)) {
        keyArray.push(key);
      } else {
        keyArray = key;
      }

      if (keyArray.includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
        callback();
      }
    };

    window.addEventListener('keydown', handleKeyUp);
    return () => window.removeEventListener('keydown', handleKeyUp);
  }, [key, callback]);
};
