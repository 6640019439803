import { useCallback } from 'react';

import type { APIParams } from '../..';
import { useDiceContext, useGetConfigsStatus } from '../dice';

export interface UseConfigStatusParams extends APIParams {}

export function useConfigStatus({ masterConfigurationId }: UseConfigStatusParams = {}) {
  const { queryKeyFn } = useDiceContext();

  const getConfigsStatusKey = useCallback(
    (configId = masterConfigurationId) =>
      queryKeyFn({
        operationId: 'getConfigsStatus',
        path: '/master-configs/{masterConfigurationId}/status',
        variables: {
          pathParams: {
            masterConfigurationId: configId!,
          },
        },
      }),
    [masterConfigurationId, queryKeyFn],
  );

  const { data: configsStatus } = useGetConfigsStatus(
    {
      pathParams: {
        masterConfigurationId: masterConfigurationId!,
      },
    },
    {
      enabled: !!masterConfigurationId,
    },
  );

  return { configsStatus, getConfigsStatusKey };
}
