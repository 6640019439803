/* eslint-disable @typescript-eslint/no-explicit-any */
export type Set = <T extends Record<string | number | symbol, any>, Value = any>(
  obj: T | null | undefined,
  key: keyof T | string | number | symbol,
  val: any,
) => Value | null | undefined;
/**
 * @deprecated Do not use set function use `array[index] = value` or `object.property = value`/`object[computedProperty] = value`
 * @param obj
 * @param key
 * @param val
 * @returns
 */
export const set: Set = (obj, key, val) => {
  if (!obj) {
    return;
  }

  Object.defineProperty(obj, key, {
    configurable: true,
    enumerable: true,
    value: val,
    writable: true,
  });
  return obj;
};
