import type { AsyncTransactionsFlushed, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { APIParams, erase } from '@org/query';
import type { ModalProps } from '@org/ui';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';

import type { Asset } from './types';
import { onReportModalAsyncTransactionsFlushed } from './onReportModalAsyncTransactionsFlushed';
import { useColumns } from './useColumns';

export interface UseControllerProps {
  apiParams: APIParams<
    'masterConfigurationId' | 'yearId' | 'publicFacilityId' | 'clientId' | 'type'
  >;
  data: Asset[];
  modalGridRef: RefObject<AgGridReact>;
  setTransactionsPresentToFlush: (val: boolean) => void;
  transactionsPresentToFlush: boolean;
  executeCloseModal: () => void;
  costCenterName: string;
  description: string;
  onClose?: ModalProps['onClose'];
  className?: ModalProps['className'];
  isOpen?: ModalProps['isOpen'];
  onOpenChange?: ModalProps['onOpenChange'];
}

const defaultColDef: ColDef = {
  cellRendererParams: {
    suppressCount: true,
  },
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  minWidth: 100,
  resizable: true,
  suppressHeaderMenuButton: true,
};

export const useController = (props: UseControllerProps) => {
  const {
    apiParams,
    data,
    modalGridRef,
    setTransactionsPresentToFlush,
    executeCloseModal,
    costCenterName,
  } = props;
  const { masterConfiguration, updateMasterConfiguration: updateConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const { resetBabCCByAssetIds } = aggregated.useAssets(apiParams);
  const { year } = aggregated.useYear(apiParams);

  const { recalculateReport, isRecalculating } = aggregated.useReport(apiParams);
  const recalculate = useCallback(
    async (configuration = masterConfiguration) => {
      await recalculateReport({
        masterConfiguration: configuration,
        year,
      });
    },
    [masterConfiguration, recalculateReport, year],
  );

  const { updateAssets } = aggregated.useAssets(apiParams);

  const resetBabCc = useCallback(
    async (assetsToReset: erase.Asset | erase.Asset[]) => {
      const assetsAsArray = Array.isArray(assetsToReset) ? assetsToReset : [assetsToReset];

      const assetIds = assetsAsArray
        .filter(({ currentCcMappingStatus }) => currentCcMappingStatus === 'MANUAL')
        .map((asset) => asset._id)
        .filter(String) as string[];

      await resetBabCCByAssetIds(assetIds);
    },
    [resetBabCCByAssetIds],
  );

  const columnDefs = useColumns({
    apiParams,
    handleChange: setTransactionsPresentToFlush,
    onRefresh: resetBabCc,
  });

  const { getCostCenterLongName } = aggregated.useCostCenters(apiParams);

  const [filteredRowsAssetNumbers, setFilteredRowsAssetNumbers] = useState(
    data.map(({ assetNumber }) => assetNumber!),
  );

  const filteredData = useMemo(
    () => data.filter((asset) => filteredRowsAssetNumbers.includes(asset.assetNumber!)),
    [data, filteredRowsAssetNumbers],
  );

  // TODO: FILE API
  const modalActionButtonsDisabled = false;
  // const modalActionButtonsDisabled =
  //   year?.finalizedConfigurationForReport === masterConfiguration?.name;

  const discardMadeManualChanges = useCallback(() => {
    modalGridRef?.current?.props?.rowData?.forEach((row) => delete row._updatedData);
    filteredData.forEach((row) => delete row._updatedData);

    setTransactionsPresentToFlush(false);
    executeCloseModal();
  }, [executeCloseModal, filteredData, modalGridRef, setTransactionsPresentToFlush]);

  const handleAsyncTransactionsFlushed = useCallback(
    async (event: AsyncTransactionsFlushed) => {
      await onReportModalAsyncTransactionsFlushed({
        executeCloseModal,
        masterConfiguration,
        recalculate,
        updateAssets,
        updateConfiguration,
      })(event);
    },
    [executeCloseModal, masterConfiguration, recalculate, updateAssets, updateConfiguration],
  );

  const agGridProps = useAgGridData({
    agGridRef: modalGridRef,
    data,
  });

  const onFilterChanged = useCallback(() => {
    const assetNumbers: string[] = [];
    modalGridRef.current?.api.forEachNodeAfterFilter((node) => {
      assetNumbers.push(node.data?.assetNumber ?? '');
    });

    setFilteredRowsAssetNumbers(assetNumbers);
  }, [modalGridRef, setFilteredRowsAssetNumbers]);

  const handleTableChange = useCallback(() => {
    setTransactionsPresentToFlush(true);
  }, [setTransactionsPresentToFlush]);

  return {
    ...props,
    agGridProps,
    columnDefs,
    constCenterLongName: getCostCenterLongName(costCenterName),
    defaultColDef,
    discardMadeManualChanges,
    filteredRowsAssetNumbers,
    handleAsyncTransactionsFlushed,
    handleTableChange,
    isRecalculating,
    modalActionButtonsDisabled,
    onFilterChanged,
  };
};

export type ControllerType = ReturnType<typeof useController>;
