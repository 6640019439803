import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { griddy } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useTranslation } from '@org/locales';

interface UseColumnsProps {
  handleDeleteModal: (props: ButtonCellRendererProps) => void;
  addNewRow: (props: ButtonCellRendererProps) => void;
}

export const useColumns = ({ handleDeleteModal, addNewRow }: UseColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellRenderer: 'agGroupCellRenderer',
          colId: 'longName',
          editable: true,
          field: 'longName',
          flex: 1,
          headerName: t('main:manageMenu.manageImputedWithdrawalCapital.costCenterName'),
        },
        {
          cellClass: 'align-to-center-and-right',
          cellRenderer: 'button',
          cellRendererParams: {
            icon: 'icon-delete-outline',
            onClick: (props: ButtonCellRendererProps) => handleDeleteModal(props),
            size: 'iconButton',
          },
          colId: 'actions',
          maxWidth: 60,
          headerClass: 'ag-right-aligned-header',
          headerName: t('common:actions'),
          floatingFilterComponent: 'button',
          floatingFilterComponentParams: {
            className: 'justify-end',
            icon: 'icon-plus-fill',
            onClick: (props: ButtonCellRendererProps) => addNewRow(props),
            size: 'iconButton',
            suppressFloatingFilterButton: true,
          },
        },
      ] satisfies (ColDef<griddy.WithdrawCapital> | ColGroupDef<griddy.WithdrawCapital>)[],
    [addNewRow, handleDeleteModal, t],
  );
};
