/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from '@tanstack/react-query';

import type { GriddyContext } from './griddyContext';
import type * as Fetcher from './griddyFetcher';
import type * as Schemas from './griddySchemas';
import { useGriddyContext } from './griddyContext';
import { griddyFetch } from './griddyFetcher';

export type GetAllDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDataContainerResponse = Schemas.Year[];

export type GetAllDataContainerVariables = GriddyContext['fetcherOptions'];

export const fetchGetAllDataContainer = (
  variables: GetAllDataContainerVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<GetAllDataContainerResponse, GetAllDataContainerError, undefined, {}, {}, {}>({
    url: '/years',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllDataContainer = <TData = GetAllDataContainerResponse>(
  variables: GetAllDataContainerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllDataContainerResponse, GetAllDataContainerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllDataContainerResponse, GetAllDataContainerError, TData>({
    queryKey: queryKeyFn({ path: '/years', operationId: 'getAllDataContainer', variables }),
    queryFn: ({ signal }) => fetchGetAllDataContainer({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type UpdateDataContainerVariables = {
  body?: Schemas.Year;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateDataContainer = (
  variables: UpdateDataContainerVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<Schemas.Year, UpdateDataContainerError, Schemas.Year, {}, {}, {}>({
    url: '/years',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Year,
      UpdateDataContainerError,
      UpdateDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.Year,
    UpdateDataContainerError,
    UpdateDataContainerVariables
  >({
    mutationFn: (variables: UpdateDataContainerVariables) =>
      fetchUpdateDataContainer({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InsertDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type InsertDataContainerVariables = {
  body?: Schemas.Year;
} & GriddyContext['fetcherOptions'];

export const fetchInsertDataContainer = (
  variables: InsertDataContainerVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<Schemas.Year, InsertDataContainerError, Schemas.Year, {}, {}, {}>({
    url: '/years',
    method: 'post',
    ...variables,
    signal,
  });

export const useInsertDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Year,
      InsertDataContainerError,
      InsertDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.Year,
    InsertDataContainerError,
    InsertDataContainerVariables
  >({
    mutationFn: (variables: InsertDataContainerVariables) =>
      fetchInsertDataContainer({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertFileMetadataError = Fetcher.ErrorWrapper<undefined>;

export type UpsertFileMetadataVariables = {
  body?: Schemas.FileMetadataDTO;
} & GriddyContext['fetcherOptions'];

export const fetchUpsertFileMetadata = (
  variables: UpsertFileMetadataVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.FileMetadataDTO,
    UpsertFileMetadataError,
    Schemas.FileMetadataDTO,
    {},
    {},
    {}
  >({ url: '/year-calculations/files-metadata', method: 'put', ...variables, signal });

export const useUpsertFileMetadata = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FileMetadataDTO,
      UpsertFileMetadataError,
      UpsertFileMetadataVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.FileMetadataDTO,
    UpsertFileMetadataError,
    UpsertFileMetadataVariables
  >({
    mutationFn: (variables: UpsertFileMetadataVariables) =>
      fetchUpsertFileMetadata({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetYear1PathParams {
  publicFacilityId: string;
  yearId: string;
}

export type GetYear1Error = Fetcher.ErrorWrapper<undefined>;

export type GetYear1Variables = {
  pathParams: GetYear1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetYear1 = (variables: GetYear1Variables, signal?: AbortSignal) =>
  griddyFetch<Schemas.YearDTOV2, GetYear1Error, undefined, {}, {}, GetYear1PathParams>({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetYear1 = <TData = Schemas.YearDTOV2>(
  variables: GetYear1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearDTOV2, GetYear1Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.YearDTOV2, GetYear1Error, TData>({
    queryKey: queryKeyFn({
      path: '/public-facilities/{publicFacilityId}/years/{yearId}',
      operationId: 'getYear1',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetYear1({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PutYearPathParams {
  publicFacilityId: string;
  yearId: string;
}

export type PutYearError = Fetcher.ErrorWrapper<undefined>;

export type PutYearVariables = {
  body?: Schemas.YearDTOV2;
  pathParams: PutYearPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPutYear = (variables: PutYearVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.YearDTOV2, PutYearError, Schemas.YearDTOV2, {}, {}, PutYearPathParams>({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.YearDTOV2, PutYearError, PutYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<Schemas.YearDTOV2, PutYearError, PutYearVariables>({
    mutationFn: (variables: PutYearVariables) => fetchPutYear({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteYearPathParams {
  publicFacilityId: string;
  yearId: string;
}

export type DeleteYearError = Fetcher.ErrorWrapper<undefined>;

export type DeleteYearVariables = {
  pathParams: DeleteYearPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteYear = (variables: DeleteYearVariables, signal?: AbortSignal) =>
  griddyFetch<undefined, DeleteYearError, undefined, {}, {}, DeleteYearPathParams>({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteYearError, DeleteYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteYearError, DeleteYearVariables>({
    mutationFn: (variables: DeleteYearVariables) =>
      fetchDeleteYear({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetMasterConfigurationsByYearIdQueryParams {
  yearId: string;
}

export type GetMasterConfigurationsByYearIdError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationsByYearIdResponse = Schemas.MasterConfiguration[];

export type GetMasterConfigurationsByYearIdVariables = {
  queryParams: GetMasterConfigurationsByYearIdQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetMasterConfigurationsByYearId = (
  variables: GetMasterConfigurationsByYearIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    undefined,
    {},
    GetMasterConfigurationsByYearIdQueryParams,
    {}
  >({ url: '/master-configurations', method: 'get', ...variables, signal });

export const useGetMasterConfigurationsByYearId = <TData = GetMasterConfigurationsByYearIdResponse>(
  variables: GetMasterConfigurationsByYearIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsByYearIdResponse,
      GetMasterConfigurationsByYearIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetMasterConfigurationsByYearIdResponse,
    GetMasterConfigurationsByYearIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations',
      operationId: 'getMasterConfigurationsByYearId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMasterConfigurationsByYearId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateMasterConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateMasterConfigurationsVariables = {
  body?: Schemas.MasterConfigurationRequest;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateMasterConfigurations = (
  variables: UpdateMasterConfigurationsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.EntityUpsertedResponse,
    UpdateMasterConfigurationsError,
    Schemas.MasterConfigurationRequest,
    {},
    {},
    {}
  >({ url: '/master-configurations', method: 'put', ...variables, signal });

export const useUpdateMasterConfigurations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EntityUpsertedResponse,
      UpdateMasterConfigurationsError,
      UpdateMasterConfigurationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.EntityUpsertedResponse,
    UpdateMasterConfigurationsError,
    UpdateMasterConfigurationsVariables
  >({
    mutationFn: (variables: UpdateMasterConfigurationsVariables) =>
      fetchUpdateMasterConfigurations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type GetMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationVariables = {
  pathParams: GetMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetMasterConfiguration = (
  variables: GetMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfiguration,
    GetMasterConfigurationError,
    undefined,
    {},
    {},
    GetMasterConfigurationPathParams
  >({ url: '/master-configurations/{masterConfigurationId}', method: 'get', ...variables, signal });

export const useGetMasterConfiguration = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.MasterConfiguration, GetMasterConfigurationError, TData>({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}',
      operationId: 'getMasterConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMasterConfiguration({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PutMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type PutMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type PutMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
  pathParams: PutMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPutMasterConfiguration = (
  variables: PutMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfiguration,
    PutMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    PutMasterConfigurationPathParams
  >({ url: '/master-configurations/{masterConfigurationId}', method: 'put', ...variables, signal });

export const usePutMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfiguration,
      PutMasterConfigurationError,
      PutMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfiguration,
    PutMasterConfigurationError,
    PutMasterConfigurationVariables
  >({
    mutationFn: (variables: PutMasterConfigurationVariables) =>
      fetchPutMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UpdateActivityStatusPathParams {
  masterConfigurationId: string;
  configActivityStatus: 'ACTIVE' | 'FINALIZED';
}

export type UpdateActivityStatusError = Fetcher.ErrorWrapper<undefined>;

export type UpdateActivityStatusVariables = {
  pathParams: UpdateActivityStatusPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateActivityStatus = (
  variables: UpdateActivityStatusVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    UpdateActivityStatusError,
    undefined,
    {},
    {},
    UpdateActivityStatusPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/update-config-activity-status/{configActivityStatus}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateActivityStatus = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateActivityStatusError,
      UpdateActivityStatusVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    UpdateActivityStatusError,
    UpdateActivityStatusVariables
  >({
    mutationFn: (variables: UpdateActivityStatusVariables) =>
      fetchUpdateActivityStatus({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetTechnicalDataByMasterConfigurationIdPathParams {
  masterConfigurationId: string;
}

export type GetTechnicalDataByMasterConfigurationIdError = Fetcher.ErrorWrapper<undefined>;

export type GetTechnicalDataByMasterConfigurationIdResponse = Schemas.TechnicalDataDTO[];

export type GetTechnicalDataByMasterConfigurationIdVariables = {
  pathParams: GetTechnicalDataByMasterConfigurationIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetTechnicalDataByMasterConfigurationId = (
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    undefined,
    {},
    {},
    GetTechnicalDataByMasterConfigurationIdPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTechnicalDataByMasterConfigurationId = <
  TData = GetTechnicalDataByMasterConfigurationIdResponse,
>(
  variables: GetTechnicalDataByMasterConfigurationIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTechnicalDataByMasterConfigurationIdResponse,
      GetTechnicalDataByMasterConfigurationIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetTechnicalDataByMasterConfigurationIdResponse,
    GetTechnicalDataByMasterConfigurationIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/technical-data',
      operationId: 'getTechnicalDataByMasterConfigurationId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTechnicalDataByMasterConfigurationId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PutTechnicalDataEntriesPathParams {
  masterConfigurationId: string;
}

export type PutTechnicalDataEntriesError = Fetcher.ErrorWrapper<undefined>;

export type PutTechnicalDataEntriesResponse = Schemas.TechnicalDataDTO[];

export type PutTechnicalDataEntriesRequestBody = Schemas.TechnicalDataDTO[];

export type PutTechnicalDataEntriesVariables = {
  body?: PutTechnicalDataEntriesRequestBody;
  pathParams: PutTechnicalDataEntriesPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPutTechnicalDataEntries = (
  variables: PutTechnicalDataEntriesVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    PutTechnicalDataEntriesResponse,
    PutTechnicalDataEntriesError,
    PutTechnicalDataEntriesRequestBody,
    {},
    {},
    PutTechnicalDataEntriesPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutTechnicalDataEntries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PutTechnicalDataEntriesResponse,
      PutTechnicalDataEntriesError,
      PutTechnicalDataEntriesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    PutTechnicalDataEntriesResponse,
    PutTechnicalDataEntriesError,
    PutTechnicalDataEntriesVariables
  >({
    mutationFn: (variables: PutTechnicalDataEntriesVariables) =>
      fetchPutTechnicalDataEntries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PostTechnicalDataEntryPathParams {
  masterConfigurationId: string;
}

export type PostTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type PostTechnicalDataEntryVariables = {
  body?: Schemas.TechnicalDataDTO;
  pathParams: PostTechnicalDataEntryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPostTechnicalDataEntry = (
  variables: PostTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.TechnicalDataDTO,
    PostTechnicalDataEntryError,
    Schemas.TechnicalDataDTO,
    {},
    {},
    PostTechnicalDataEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TechnicalDataDTO,
      PostTechnicalDataEntryError,
      PostTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.TechnicalDataDTO,
    PostTechnicalDataEntryError,
    PostTechnicalDataEntryVariables
  >({
    mutationFn: (variables: PostTechnicalDataEntryVariables) =>
      fetchPostTechnicalDataEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteTechnicalDataEntryPathParams {
  masterConfigurationId: string;
}

export type DeleteTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteTechnicalDataEntryVariables = {
  pathParams: DeleteTechnicalDataEntryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteTechnicalDataEntry = (
  variables: DeleteTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteTechnicalDataEntryError,
    undefined,
    {},
    {},
    DeleteTechnicalDataEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteTechnicalDataEntryError,
      DeleteTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeleteTechnicalDataEntryError,
    DeleteTechnicalDataEntryVariables
  >({
    mutationFn: (variables: DeleteTechnicalDataEntryVariables) =>
      fetchDeleteTechnicalDataEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PutTechnicalDataEntryPathParams {
  masterConfigurationId: string;
  id: string;
}

export type PutTechnicalDataEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutTechnicalDataEntryVariables = {
  body?: Schemas.TechnicalDataDTO;
  pathParams: PutTechnicalDataEntryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPutTechnicalDataEntry = (
  variables: PutTechnicalDataEntryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.TechnicalDataDTO,
    PutTechnicalDataEntryError,
    Schemas.TechnicalDataDTO,
    {},
    {},
    PutTechnicalDataEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutTechnicalDataEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TechnicalDataDTO,
      PutTechnicalDataEntryError,
      PutTechnicalDataEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.TechnicalDataDTO,
    PutTechnicalDataEntryError,
    PutTechnicalDataEntryVariables
  >({
    mutationFn: (variables: PutTechnicalDataEntryVariables) =>
      fetchPutTechnicalDataEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface RestoreBackupOfMasterConfigurationToPrimaryPathParams {
  masterConfigurationId: string;
}

export type RestoreBackupOfMasterConfigurationToPrimaryError = Fetcher.ErrorWrapper<undefined>;

export type RestoreBackupOfMasterConfigurationToPrimaryVariables = {
  pathParams: RestoreBackupOfMasterConfigurationToPrimaryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchRestoreBackupOfMasterConfigurationToPrimary = (
  variables: RestoreBackupOfMasterConfigurationToPrimaryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfigurationEntityDTO,
    RestoreBackupOfMasterConfigurationToPrimaryError,
    undefined,
    {},
    {},
    RestoreBackupOfMasterConfigurationToPrimaryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/restore-backup',
    method: 'put',
    ...variables,
    signal,
  });

export const useRestoreBackupOfMasterConfigurationToPrimary = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      RestoreBackupOfMasterConfigurationToPrimaryError,
      RestoreBackupOfMasterConfigurationToPrimaryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    RestoreBackupOfMasterConfigurationToPrimaryError,
    RestoreBackupOfMasterConfigurationToPrimaryVariables
  >({
    mutationFn: (variables: RestoreBackupOfMasterConfigurationToPrimaryVariables) =>
      fetchRestoreBackupOfMasterConfigurationToPrimary({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PromoteMasterConfigurationVariantToPrimaryPathParams {
  masterConfigurationId: string;
}

export type PromoteMasterConfigurationVariantToPrimaryError = Fetcher.ErrorWrapper<undefined>;

export type PromoteMasterConfigurationVariantToPrimaryVariables = {
  pathParams: PromoteMasterConfigurationVariantToPrimaryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPromoteMasterConfigurationVariantToPrimary = (
  variables: PromoteMasterConfigurationVariantToPrimaryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfigurationEntityDTO,
    PromoteMasterConfigurationVariantToPrimaryError,
    undefined,
    {},
    {},
    PromoteMasterConfigurationVariantToPrimaryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/promote',
    method: 'put',
    ...variables,
    signal,
  });

export const usePromoteMasterConfigurationVariantToPrimary = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      PromoteMasterConfigurationVariantToPrimaryError,
      PromoteMasterConfigurationVariantToPrimaryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    PromoteMasterConfigurationVariantToPrimaryError,
    PromoteMasterConfigurationVariantToPrimaryVariables
  >({
    mutationFn: (variables: PromoteMasterConfigurationVariantToPrimaryVariables) =>
      fetchPromoteMasterConfigurationVariantToPrimary({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SavePlannedCostRevenueCostTypeAccountEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
}

export type SavePlannedCostRevenueCostTypeAccountEntryError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueCostTypeAccountEntryVariables = {
  body?: Schemas.PlannedCostTypeAccountEntryDTO;
  pathParams: SavePlannedCostRevenueCostTypeAccountEntryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueCostTypeAccountEntry = (
  variables: SavePlannedCostRevenueCostTypeAccountEntryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedCostTypeAccountEntryDTO,
    SavePlannedCostRevenueCostTypeAccountEntryError,
    Schemas.PlannedCostTypeAccountEntryDTO,
    {},
    {},
    SavePlannedCostRevenueCostTypeAccountEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueCostTypeAccountEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostTypeAccountEntryDTO,
      SavePlannedCostRevenueCostTypeAccountEntryError,
      SavePlannedCostRevenueCostTypeAccountEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostTypeAccountEntryDTO,
    SavePlannedCostRevenueCostTypeAccountEntryError,
    SavePlannedCostRevenueCostTypeAccountEntryVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueCostTypeAccountEntryVariables) =>
      fetchSavePlannedCostRevenueCostTypeAccountEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SavePlannedCostRevenueAccountEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
  plannedAccountEntryId: string;
}

export type SavePlannedCostRevenueAccountEntryError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueAccountEntryVariables = {
  body?: Schemas.PlannedAccountEntryDTO;
  pathParams: SavePlannedCostRevenueAccountEntryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueAccountEntry = (
  variables: SavePlannedCostRevenueAccountEntryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedAccountEntryDTO,
    SavePlannedCostRevenueAccountEntryError,
    Schemas.PlannedAccountEntryDTO,
    {},
    {},
    SavePlannedCostRevenueAccountEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}/account/{plannedAccountEntryId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueAccountEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedAccountEntryDTO,
      SavePlannedCostRevenueAccountEntryError,
      SavePlannedCostRevenueAccountEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.PlannedAccountEntryDTO,
    SavePlannedCostRevenueAccountEntryError,
    SavePlannedCostRevenueAccountEntryVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueAccountEntryVariables) =>
      fetchSavePlannedCostRevenueAccountEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UpdateDirectEntryDistributionConfigPathParams {
  masterConfigurationId: string;
}

export type UpdateDirectEntryDistributionConfigError = Fetcher.ErrorWrapper<undefined>;

export type UpdateDirectEntryDistributionConfigVariables = {
  pathParams: UpdateDirectEntryDistributionConfigPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateDirectEntryDistributionConfig = (
  variables: UpdateDirectEntryDistributionConfigVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    UpdateDirectEntryDistributionConfigError,
    undefined,
    {},
    {},
    UpdateDirectEntryDistributionConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/update-distribution-configs',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateDirectEntryDistributionConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateDirectEntryDistributionConfigError,
      UpdateDirectEntryDistributionConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    UpdateDirectEntryDistributionConfigError,
    UpdateDirectEntryDistributionConfigVariables
  >({
    mutationFn: (variables: UpdateDirectEntryDistributionConfigVariables) =>
      fetchUpdateDirectEntryDistributionConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UpdatePlannedCostRevenueConfigPathParams {
  masterConfigurationId: string;
}

export type UpdatePlannedCostRevenueConfigError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePlannedCostRevenueConfigVariables = {
  pathParams: UpdatePlannedCostRevenueConfigPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchUpdatePlannedCostRevenueConfig = (
  variables: UpdatePlannedCostRevenueConfigVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    UpdatePlannedCostRevenueConfigError,
    undefined,
    {},
    {},
    UpdatePlannedCostRevenueConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/update-configs',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdatePlannedCostRevenueConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdatePlannedCostRevenueConfigError,
      UpdatePlannedCostRevenueConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    UpdatePlannedCostRevenueConfigError,
    UpdatePlannedCostRevenueConfigVariables
  >({
    mutationFn: (variables: UpdatePlannedCostRevenueConfigVariables) =>
      fetchUpdatePlannedCostRevenueConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetPlannedCostRevenueConfigDetailsPathParams {
  masterConfigurationId: string;
}

export type GetPlannedCostRevenueConfigDetailsError = Fetcher.ErrorWrapper<undefined>;

export type GetPlannedCostRevenueConfigDetailsVariables = {
  pathParams: GetPlannedCostRevenueConfigDetailsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetPlannedCostRevenueConfigDetails = (
  variables: GetPlannedCostRevenueConfigDetailsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedCostRevenueDetailDTO,
    GetPlannedCostRevenueConfigDetailsError,
    undefined,
    {},
    {},
    GetPlannedCostRevenueConfigDetailsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPlannedCostRevenueConfigDetails = <TData = Schemas.PlannedCostRevenueDetailDTO>(
  variables: GetPlannedCostRevenueConfigDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenueDetailDTO,
      GetPlannedCostRevenueConfigDetailsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.PlannedCostRevenueDetailDTO,
    GetPlannedCostRevenueConfigDetailsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
      operationId: 'getPlannedCostRevenueConfigDetails',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlannedCostRevenueConfigDetails({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface SavePlannedCostRevenueConfigDetailsPathParams {
  masterConfigurationId: string;
}

export type SavePlannedCostRevenueConfigDetailsError = Fetcher.ErrorWrapper<undefined>;

export type SavePlannedCostRevenueConfigDetailsVariables = {
  body?: Schemas.PlannedCostRevenueDetailDTO;
  pathParams: SavePlannedCostRevenueConfigDetailsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchSavePlannedCostRevenueConfigDetails = (
  variables: SavePlannedCostRevenueConfigDetailsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    SavePlannedCostRevenueConfigDetailsError,
    Schemas.PlannedCostRevenueDetailDTO,
    {},
    {},
    SavePlannedCostRevenueConfigDetailsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
    method: 'put',
    ...variables,
    signal,
  });

export const useSavePlannedCostRevenueConfigDetails = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      SavePlannedCostRevenueConfigDetailsError,
      SavePlannedCostRevenueConfigDetailsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostRevenueConfigDTO,
    SavePlannedCostRevenueConfigDetailsError,
    SavePlannedCostRevenueConfigDetailsVariables
  >({
    mutationFn: (variables: SavePlannedCostRevenueConfigDetailsVariables) =>
      fetchSavePlannedCostRevenueConfigDetails({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreatePlannedCostRevenueConfigDetailsPathParams {
  masterConfigurationId: string;
}

export type CreatePlannedCostRevenueConfigDetailsError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlannedCostRevenueConfigDetailsVariables = {
  body?: Schemas.PlannedCostRevenueDetailDTO;
  pathParams: CreatePlannedCostRevenueConfigDetailsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCreatePlannedCostRevenueConfigDetails = (
  variables: CreatePlannedCostRevenueConfigDetailsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    CreatePlannedCostRevenueConfigDetailsError,
    Schemas.PlannedCostRevenueDetailDTO,
    {},
    {},
    CreatePlannedCostRevenueConfigDetailsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/details',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePlannedCostRevenueConfigDetails = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      CreatePlannedCostRevenueConfigDetailsError,
      CreatePlannedCostRevenueConfigDetailsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.PlannedCostRevenueConfigDTO,
    CreatePlannedCostRevenueConfigDetailsError,
    CreatePlannedCostRevenueConfigDetailsVariables
  >({
    mutationFn: (variables: CreatePlannedCostRevenueConfigDetailsVariables) =>
      fetchCreatePlannedCostRevenueConfigDetails({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreatePlannedCostRevenueTransactionsPathParams {
  masterConfigurationId: string;
}

export type CreatePlannedCostRevenueTransactionsError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlannedCostRevenueTransactionsResponse = Schemas.AccountTransaction[];

export type CreatePlannedCostRevenueTransactionsVariables = {
  pathParams: CreatePlannedCostRevenueTransactionsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCreatePlannedCostRevenueTransactions = (
  variables: CreatePlannedCostRevenueTransactionsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    CreatePlannedCostRevenueTransactionsResponse,
    CreatePlannedCostRevenueTransactionsError,
    undefined,
    {},
    {},
    CreatePlannedCostRevenueTransactionsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/create',
    method: 'put',
    ...variables,
    signal,
  });

export const useCreatePlannedCostRevenueTransactions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreatePlannedCostRevenueTransactionsResponse,
      CreatePlannedCostRevenueTransactionsError,
      CreatePlannedCostRevenueTransactionsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    CreatePlannedCostRevenueTransactionsResponse,
    CreatePlannedCostRevenueTransactionsError,
    CreatePlannedCostRevenueTransactionsVariables
  >({
    mutationFn: (variables: CreatePlannedCostRevenueTransactionsVariables) =>
      fetchCreatePlannedCostRevenueTransactions({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetMasterConfigurationEntityPathParams {
  masterConfigurationId: string;
}

export type GetMasterConfigurationEntityError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationEntityVariables = {
  pathParams: GetMasterConfigurationEntityPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetMasterConfigurationEntity = (
  variables: GetMasterConfigurationEntityVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfigurationEntityDTO,
    GetMasterConfigurationEntityError,
    undefined,
    {},
    {},
    GetMasterConfigurationEntityPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/entities',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetMasterConfigurationEntity = <TData = Schemas.MasterConfigurationEntityDTO>(
  variables: GetMasterConfigurationEntityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MasterConfigurationEntityDTO,
      GetMasterConfigurationEntityError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.MasterConfigurationEntityDTO,
    GetMasterConfigurationEntityError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/entities',
      operationId: 'getMasterConfigurationEntity',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMasterConfigurationEntity({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PutMasterConfigurationEntityPathParams {
  masterConfigurationId: string;
}

export type PutMasterConfigurationEntityError = Fetcher.ErrorWrapper<undefined>;

export type PutMasterConfigurationEntityVariables = {
  body?: Schemas.MasterConfigurationEntityDTO;
  pathParams: PutMasterConfigurationEntityPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPutMasterConfigurationEntity = (
  variables: PutMasterConfigurationEntityVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfigurationEntityDTO,
    PutMasterConfigurationEntityError,
    Schemas.MasterConfigurationEntityDTO,
    {},
    {},
    PutMasterConfigurationEntityPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/entities',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutMasterConfigurationEntity = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      PutMasterConfigurationEntityError,
      PutMasterConfigurationEntityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    PutMasterConfigurationEntityError,
    PutMasterConfigurationEntityVariables
  >({
    mutationFn: (variables: PutMasterConfigurationEntityVariables) =>
      fetchPutMasterConfigurationEntity({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PutRangePathParams {
  masterConfigurationId: string;
  id: string;
}

export type PutRangeError = Fetcher.ErrorWrapper<undefined>;

export type PutRangeVariables = {
  body?: Schemas.RangeDTO;
  pathParams: PutRangePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPutRange = (variables: PutRangeVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.RangeConfigDTO, PutRangeError, Schemas.RangeDTO, {}, {}, PutRangePathParams>({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, PutRangeError, PutRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, PutRangeError, PutRangeVariables>({
    mutationFn: (variables: PutRangeVariables) =>
      fetchPutRange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteRangePathParams {
  masterConfigurationId: string;
  id: string;
}

export type DeleteRangeError = Fetcher.ErrorWrapper<undefined>;

export type DeleteRangeVariables = {
  pathParams: DeleteRangePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteRange = (variables: DeleteRangeVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.RangeConfigDTO, DeleteRangeError, undefined, {}, {}, DeleteRangePathParams>({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, DeleteRangeError, DeleteRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, DeleteRangeError, DeleteRangeVariables>({
    mutationFn: (variables: DeleteRangeVariables) =>
      fetchDeleteRange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UseJournalAccountsPathParams {
  masterConfigurationId: string;
}

export type UseJournalAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UseJournalAccountsVariables = {
  pathParams: UseJournalAccountsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchUseJournalAccounts = (
  variables: UseJournalAccountsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.RangeConfigDTO,
    UseJournalAccountsError,
    undefined,
    {},
    {},
    UseJournalAccountsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/use-journal-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUseJournalAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RangeConfigDTO,
      UseJournalAccountsError,
      UseJournalAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.RangeConfigDTO,
    UseJournalAccountsError,
    UseJournalAccountsVariables
  >({
    mutationFn: (variables: UseJournalAccountsVariables) =>
      fetchUseJournalAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface UseAccountPlanAccountsPathParams {
  masterConfigurationId: string;
}

export type UseAccountPlanAccountsError = Fetcher.ErrorWrapper<undefined>;

export type UseAccountPlanAccountsVariables = {
  pathParams: UseAccountPlanAccountsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchUseAccountPlanAccounts = (
  variables: UseAccountPlanAccountsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.RangeConfigDTO,
    UseAccountPlanAccountsError,
    undefined,
    {},
    {},
    UseAccountPlanAccountsPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/use-account-plan-accounts',
    method: 'put',
    ...variables,
    signal,
  });

export const useUseAccountPlanAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RangeConfigDTO,
      UseAccountPlanAccountsError,
      UseAccountPlanAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.RangeConfigDTO,
    UseAccountPlanAccountsError,
    UseAccountPlanAccountsVariables
  >({
    mutationFn: (variables: UseAccountPlanAccountsVariables) =>
      fetchUseAccountPlanAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllDataContainer1Error = Fetcher.ErrorWrapper<undefined>;

export type GetAllDataContainer1Response = Schemas.DataContainer[];

export type GetAllDataContainer1Variables = GriddyContext['fetcherOptions'];

export const fetchGetAllDataContainer1 = (
  variables: GetAllDataContainer1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<GetAllDataContainer1Response, GetAllDataContainer1Error, undefined, {}, {}, {}>({
    url: '/datacontainers',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllDataContainer1 = <TData = GetAllDataContainer1Response>(
  variables: GetAllDataContainer1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllDataContainer1Response, GetAllDataContainer1Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllDataContainer1Response, GetAllDataContainer1Error, TData>({
    queryKey: queryKeyFn({
      path: '/datacontainers',
      operationId: 'getAllDataContainer1',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetAllDataContainer1({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateDataContainer1Error = Fetcher.ErrorWrapper<undefined>;

export type UpdateDataContainer1Variables = {
  body?: Schemas.DataContainer;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateDataContainer1 = (
  variables: UpdateDataContainer1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<Schemas.DataContainer, UpdateDataContainer1Error, Schemas.DataContainer, {}, {}, {}>({
    url: '/datacontainers',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateDataContainer1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DataContainer,
      UpdateDataContainer1Error,
      UpdateDataContainer1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.DataContainer,
    UpdateDataContainer1Error,
    UpdateDataContainer1Variables
  >({
    mutationFn: (variables: UpdateDataContainer1Variables) =>
      fetchUpdateDataContainer1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InsertDataContainer1Error = Fetcher.ErrorWrapper<undefined>;

export type InsertDataContainer1Variables = {
  body?: Schemas.DataContainer;
} & GriddyContext['fetcherOptions'];

export const fetchInsertDataContainer1 = (
  variables: InsertDataContainer1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<Schemas.DataContainer, InsertDataContainer1Error, Schemas.DataContainer, {}, {}, {}>({
    url: '/datacontainers',
    method: 'post',
    ...variables,
    signal,
  });

export const useInsertDataContainer1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DataContainer,
      InsertDataContainer1Error,
      InsertDataContainer1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.DataContainer,
    InsertDataContainer1Error,
    InsertDataContainer1Variables
  >({
    mutationFn: (variables: InsertDataContainer1Variables) =>
      fetchInsertDataContainer1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetPublicFacilitiesByClientPathParams {
  clientId: string;
}

export interface GetPublicFacilitiesByClientHeaders {
  authorization?: string;
}

export type GetPublicFacilitiesByClientError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicFacilitiesByClientResponse = Schemas.PublicFacilityDTO[];

export type GetPublicFacilitiesByClientVariables = {
  headers?: GetPublicFacilitiesByClientHeaders;
  pathParams: GetPublicFacilitiesByClientPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetPublicFacilitiesByClient = (
  variables: GetPublicFacilitiesByClientVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    undefined,
    GetPublicFacilitiesByClientHeaders,
    {},
    GetPublicFacilitiesByClientPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'get', ...variables, signal });

export const useGetPublicFacilitiesByClient = <TData = GetPublicFacilitiesByClientResponse>(
  variables: GetPublicFacilitiesByClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPublicFacilitiesByClientResponse,
      GetPublicFacilitiesByClientError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetPublicFacilitiesByClientResponse,
    GetPublicFacilitiesByClientError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/public-facilities',
      operationId: 'getPublicFacilitiesByClient',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPublicFacilitiesByClient({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PutPublicFacilityPathParams {
  clientId: string;
}

export type PutPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type PutPublicFacilityVariables = {
  body?: Schemas.PublicFacilityDTO;
  pathParams: PutPublicFacilityPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPutPublicFacility = (
  variables: PutPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PublicFacilityDTO,
    PutPublicFacilityError,
    Schemas.PublicFacilityDTO,
    {},
    {},
    PutPublicFacilityPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'put', ...variables, signal });

export const usePutPublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PublicFacilityDTO,
      PutPublicFacilityError,
      PutPublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.PublicFacilityDTO,
    PutPublicFacilityError,
    PutPublicFacilityVariables
  >({
    mutationFn: (variables: PutPublicFacilityVariables) =>
      fetchPutPublicFacility({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface PostPublicFacilityPathParams {
  clientId: string;
}

export type PostPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type PostPublicFacilityVariables = {
  body?: Schemas.PublicFacilityDTO;
  pathParams: PostPublicFacilityPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPostPublicFacility = (
  variables: PostPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PublicFacilityDTO,
    PostPublicFacilityError,
    Schemas.PublicFacilityDTO,
    {},
    {},
    PostPublicFacilityPathParams
  >({ url: '/clients/{clientId}/public-facilities', method: 'post', ...variables, signal });

export const usePostPublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PublicFacilityDTO,
      PostPublicFacilityError,
      PostPublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.PublicFacilityDTO,
    PostPublicFacilityError,
    PostPublicFacilityVariables
  >({
    mutationFn: (variables: PostPublicFacilityVariables) =>
      fetchPostPublicFacility({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetConfigurationsByYearCalculationPathParams {
  yearCalculationId: string;
}

export interface GetConfigurationsByYearCalculationHeaders {
  authorization?: string;
}

export type GetConfigurationsByYearCalculationError = Fetcher.ErrorWrapper<undefined>;

export type GetConfigurationsByYearCalculationResponse = Schemas.MasterConfiguration[];

export type GetConfigurationsByYearCalculationVariables = {
  headers?: GetConfigurationsByYearCalculationHeaders;
  pathParams: GetConfigurationsByYearCalculationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetConfigurationsByYearCalculation = (
  variables: GetConfigurationsByYearCalculationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetConfigurationsByYearCalculationResponse,
    GetConfigurationsByYearCalculationError,
    undefined,
    GetConfigurationsByYearCalculationHeaders,
    {},
    GetConfigurationsByYearCalculationPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/master-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetConfigurationsByYearCalculation = <
  TData = GetConfigurationsByYearCalculationResponse,
>(
  variables: GetConfigurationsByYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetConfigurationsByYearCalculationResponse,
      GetConfigurationsByYearCalculationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetConfigurationsByYearCalculationResponse,
    GetConfigurationsByYearCalculationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/year-calculations/{yearCalculationId}/master-configurations',
      operationId: 'getConfigurationsByYearCalculation',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetConfigurationsByYearCalculation({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PostMasterConfigurationPathParams {
  yearCalculationId: string;
}

export type PostMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type PostMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
  pathParams: PostMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPostMasterConfiguration = (
  variables: PostMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfiguration,
    PostMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    PostMasterConfigurationPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/master-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfiguration,
      PostMasterConfigurationError,
      PostMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfiguration,
    PostMasterConfigurationError,
    PostMasterConfigurationVariables
  >({
    mutationFn: (variables: PostMasterConfigurationVariables) =>
      fetchPostMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetClientsV2QueryParams {
  onlyArchived?: boolean;
}

export interface GetClientsV2Headers {
  authorization?: string;
}

export type GetClientsV2Error = Fetcher.ErrorWrapper<undefined>;

export type GetClientsV2Response = Schemas.ClientDocument[];

export type GetClientsV2Variables = {
  headers?: GetClientsV2Headers;
  queryParams?: GetClientsV2QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetClientsV2 = (variables: GetClientsV2Variables, signal?: AbortSignal) =>
  griddyFetch<
    GetClientsV2Response,
    GetClientsV2Error,
    undefined,
    GetClientsV2Headers,
    GetClientsV2QueryParams,
    {}
  >({ url: '/v2/clients', method: 'get', ...variables, signal });

export const useGetClientsV2 = <TData = GetClientsV2Response>(
  variables: GetClientsV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientsV2Response, GetClientsV2Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetClientsV2Response, GetClientsV2Error, TData>({
    queryKey: queryKeyFn({ path: '/v2/clients', operationId: 'getClientsV2', variables }),
    queryFn: ({ signal }) => fetchGetClientsV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface SaveClientsV2Headers {
  authorization?: string;
}

export type SaveClientsV2Error = Fetcher.ErrorWrapper<undefined>;

export type SaveClientsV2Response = Schemas.ClientDocument[];

export type SaveClientsV2RequestBody = Schemas.ClientDTO[];

export type SaveClientsV2Variables = {
  body?: SaveClientsV2RequestBody;
  headers?: SaveClientsV2Headers;
} & GriddyContext['fetcherOptions'];

export const fetchSaveClientsV2 = (variables: SaveClientsV2Variables, signal?: AbortSignal) =>
  griddyFetch<
    SaveClientsV2Response,
    SaveClientsV2Error,
    SaveClientsV2RequestBody,
    SaveClientsV2Headers,
    {},
    {}
  >({ url: '/v2/clients', method: 'post', ...variables, signal });

export const useSaveClientsV2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SaveClientsV2Response,
      SaveClientsV2Error,
      SaveClientsV2Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<SaveClientsV2Response, SaveClientsV2Error, SaveClientsV2Variables>({
    mutationFn: (variables: SaveClientsV2Variables) =>
      fetchSaveClientsV2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteClientQueryParams {
  /**
   * @format int32
   */
  clientId: number;
}

export interface DeleteClientHeaders {
  authorization?: string;
}

export type DeleteClientError = Fetcher.ErrorWrapper<undefined>;

export type DeleteClientVariables = {
  headers?: DeleteClientHeaders;
  queryParams: DeleteClientQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteClient = (variables: DeleteClientVariables, signal?: AbortSignal) =>
  griddyFetch<
    undefined,
    DeleteClientError,
    undefined,
    DeleteClientHeaders,
    DeleteClientQueryParams,
    {}
  >({ url: '/v2/clients', method: 'delete', ...variables, signal });

export const useDeleteClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteClientError, DeleteClientVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteClientError, DeleteClientVariables>({
    mutationFn: (variables: DeleteClientVariables) =>
      fetchDeleteClient({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface ShareClientQueryParams {
  /**
   * @format int32
   */
  clientId: number;
}

export interface ShareClientHeaders {
  authorization?: string;
}

export type ShareClientError = Fetcher.ErrorWrapper<undefined>;

export type ShareClientRequestBody = string[];

export type ShareClientVariables = {
  body?: ShareClientRequestBody;
  headers?: ShareClientHeaders;
  queryParams: ShareClientQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchShareClient = (variables: ShareClientVariables, signal?: AbortSignal) =>
  griddyFetch<
    undefined,
    ShareClientError,
    ShareClientRequestBody,
    ShareClientHeaders,
    ShareClientQueryParams,
    {}
  >({ url: '/v2/clients/share', method: 'post', ...variables, signal });

export const useShareClient = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ShareClientError, ShareClientVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, ShareClientError, ShareClientVariables>({
    mutationFn: (variables: ShareClientVariables) =>
      fetchShareClient({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetUsersHeaders {
  authorization?: string;
}

export type GetUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetUsersResponse = Schemas.User[];

export type GetUsersVariables = {
  headers?: GetUsersHeaders;
} & GriddyContext['fetcherOptions'];

export const fetchGetUsers = (variables: GetUsersVariables, signal?: AbortSignal) =>
  griddyFetch<GetUsersResponse, GetUsersError, undefined, GetUsersHeaders, {}, {}>({
    url: '/users',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUsers = <TData = GetUsersResponse>(
  variables: GetUsersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetUsersResponse, GetUsersError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetUsersResponse, GetUsersError, TData>({
    queryKey: queryKeyFn({ path: '/users', operationId: 'getUsers', variables }),
    queryFn: ({ signal }) => fetchGetUsers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface SaveUsersHeaders {
  authorization?: string;
}

export type SaveUsersError = Fetcher.ErrorWrapper<undefined>;

export type SaveUsersVariables = {
  body?: Schemas.User;
  headers?: SaveUsersHeaders;
} & GriddyContext['fetcherOptions'];

export const fetchSaveUsers = (variables: SaveUsersVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.User, SaveUsersError, Schemas.User, SaveUsersHeaders, {}, {}>({
    url: '/users',
    method: 'post',
    ...variables,
    signal,
  });

export const useSaveUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.User, SaveUsersError, SaveUsersVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<Schemas.User, SaveUsersError, SaveUsersVariables>({
    mutationFn: (variables: SaveUsersVariables) =>
      fetchSaveUsers({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SaveReportJsonNodesInGridFSPathParams {
  masterConfigurationId: string;
}

export interface SaveReportJsonNodesInGridFSHeaders {
  authorization?: string;
}

export type SaveReportJsonNodesInGridFSError = Fetcher.ErrorWrapper<undefined>;

export type SaveReportJsonNodesInGridFSVariables = {
  body?: Schemas.JsonNode;
  headers?: SaveReportJsonNodesInGridFSHeaders;
  pathParams: SaveReportJsonNodesInGridFSPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchSaveReportJsonNodesInGridFS = (
  variables: SaveReportJsonNodesInGridFSVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    string,
    SaveReportJsonNodesInGridFSError,
    Schemas.JsonNode,
    SaveReportJsonNodesInGridFSHeaders,
    {},
    SaveReportJsonNodesInGridFSPathParams
  >({ url: '/reports/{masterConfigurationId}', method: 'post', ...variables, signal });

export const useSaveReportJsonNodesInGridFS = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      SaveReportJsonNodesInGridFSError,
      SaveReportJsonNodesInGridFSVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    string,
    SaveReportJsonNodesInGridFSError,
    SaveReportJsonNodesInGridFSVariables
  >({
    mutationFn: (variables: SaveReportJsonNodesInGridFSVariables) =>
      fetchSaveReportJsonNodesInGridFS({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetYearsPathParams {
  publicFacilityId: string;
}

export type GetYearsError = Fetcher.ErrorWrapper<undefined>;

export type GetYearsResponse = Schemas.YearExtendedDTOV2[];

export type GetYearsVariables = {
  pathParams: GetYearsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetYears = (variables: GetYearsVariables, signal?: AbortSignal) =>
  griddyFetch<GetYearsResponse, GetYearsError, undefined, {}, {}, GetYearsPathParams>({
    url: '/public-facilities/{publicFacilityId}/years',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetYears = <TData = GetYearsResponse>(
  variables: GetYearsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetYearsResponse, GetYearsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetYearsResponse, GetYearsError, TData>({
    queryKey: queryKeyFn({
      path: '/public-facilities/{publicFacilityId}/years',
      operationId: 'getYears',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetYears({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PostYearPathParams {
  publicFacilityId: string;
}

export type PostYearError = Fetcher.ErrorWrapper<undefined>;

export type PostYearVariables = {
  body?: Schemas.YearDTOV2;
  pathParams: PostYearPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPostYear = (variables: PostYearVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.YearDTOV2, PostYearError, Schemas.YearDTOV2, {}, {}, PostYearPathParams>({
    url: '/public-facilities/{publicFacilityId}/years',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostYear = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.YearDTOV2, PostYearError, PostYearVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<Schemas.YearDTOV2, PostYearError, PostYearVariables>({
    mutationFn: (variables: PostYearVariables) =>
      fetchPostYear({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface AddYearToFavoritePathParams {
  publicFacilityId: string;
  yearId: string;
}

export interface AddYearToFavoriteHeaders {
  authorization?: string;
}

export type AddYearToFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type AddYearToFavoriteVariables = {
  headers?: AddYearToFavoriteHeaders;
  pathParams: AddYearToFavoritePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchAddYearToFavorite = (
  variables: AddYearToFavoriteVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    AddYearToFavoriteError,
    undefined,
    AddYearToFavoriteHeaders,
    {},
    AddYearToFavoritePathParams
  >({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}/favorites',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddYearToFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddYearToFavoriteError, AddYearToFavoriteVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, AddYearToFavoriteError, AddYearToFavoriteVariables>({
    mutationFn: (variables: AddYearToFavoriteVariables) =>
      fetchAddYearToFavorite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface RemoveYearFromFavoritePathParams {
  publicFacilityId: string;
  yearId: string;
}

export interface RemoveYearFromFavoriteHeaders {
  authorization?: string;
}

export type RemoveYearFromFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type RemoveYearFromFavoriteVariables = {
  headers?: RemoveYearFromFavoriteHeaders;
  pathParams: RemoveYearFromFavoritePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchRemoveYearFromFavorite = (
  variables: RemoveYearFromFavoriteVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    RemoveYearFromFavoriteError,
    undefined,
    RemoveYearFromFavoriteHeaders,
    {},
    RemoveYearFromFavoritePathParams
  >({
    url: '/public-facilities/{publicFacilityId}/years/{yearId}/favorites',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemoveYearFromFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveYearFromFavoriteError,
      RemoveYearFromFavoriteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    RemoveYearFromFavoriteError,
    RemoveYearFromFavoriteVariables
  >({
    mutationFn: (variables: RemoveYearFromFavoriteVariables) =>
      fetchRemoveYearFromFavorite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAccountTransactionsQueryParams {
  masterConfigurationId: string;
  accountId: string;
}

export type GetAccountTransactionsError = Fetcher.ErrorWrapper<undefined>;

export type GetAccountTransactionsResponse = Schemas.AccountTransaction[];

export type GetAccountTransactionsVariables = {
  queryParams: GetAccountTransactionsQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAccountTransactions = (
  variables: GetAccountTransactionsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAccountTransactionsResponse,
    GetAccountTransactionsError,
    undefined,
    {},
    GetAccountTransactionsQueryParams,
    {}
  >({ url: '/plannedCostRevenueTransactions', method: 'get', ...variables, signal });

export const useGetAccountTransactions = <TData = GetAccountTransactionsResponse>(
  variables: GetAccountTransactionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAccountTransactionsResponse, GetAccountTransactionsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAccountTransactionsResponse, GetAccountTransactionsError, TData>({
    queryKey: queryKeyFn({
      path: '/plannedCostRevenueTransactions',
      operationId: 'getAccountTransactions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAccountTransactions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAccountTransactionsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAccountTransactionsVariables = {
  body: Schemas.PlannedCostRevenueRequest;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateAccountTransactions = (
  variables: UpdateAccountTransactionsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    UpdateAccountTransactionsError,
    Schemas.PlannedCostRevenueRequest,
    {},
    {},
    {}
  >({ url: '/plannedCostRevenueTransactions', method: 'post', ...variables, signal });

export const useUpdateAccountTransactions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateAccountTransactionsError,
      UpdateAccountTransactionsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    UpdateAccountTransactionsError,
    UpdateAccountTransactionsVariables
  >({
    mutationFn: (variables: UpdateAccountTransactionsVariables) =>
      fetchUpdateAccountTransactions({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllByUserFromGridFSHeaders {
  authorization?: string;
}

export type GetAllByUserFromGridFSError = Fetcher.ErrorWrapper<undefined>;

export type GetAllByUserFromGridFSResponse = Schemas.JsonNode[];

export type GetAllByUserFromGridFSVariables = {
  headers?: GetAllByUserFromGridFSHeaders;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllByUserFromGridFS = (
  variables: GetAllByUserFromGridFSVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllByUserFromGridFSResponse,
    GetAllByUserFromGridFSError,
    undefined,
    GetAllByUserFromGridFSHeaders,
    {},
    {}
  >({ url: '/objects', method: 'get', ...variables, signal });

export const useGetAllByUserFromGridFS = <TData = GetAllByUserFromGridFSResponse>(
  variables: GetAllByUserFromGridFSVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllByUserFromGridFSResponse, GetAllByUserFromGridFSError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllByUserFromGridFSResponse, GetAllByUserFromGridFSError, TData>({
    queryKey: queryKeyFn({ path: '/objects', operationId: 'getAllByUserFromGridFS', variables }),
    queryFn: ({ signal }) =>
      fetchGetAllByUserFromGridFS({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface SaveJsonNodesInGridFSHeaders {
  authorization?: string;
}

export type SaveJsonNodesInGridFSError = Fetcher.ErrorWrapper<undefined>;

export type SaveJsonNodesInGridFSResponse = Schemas.JsonNode[];

export type SaveJsonNodesInGridFSRequestBody = Schemas.JsonNode[];

export type SaveJsonNodesInGridFSVariables = {
  body?: SaveJsonNodesInGridFSRequestBody;
  headers?: SaveJsonNodesInGridFSHeaders;
} & GriddyContext['fetcherOptions'];

export const fetchSaveJsonNodesInGridFS = (
  variables: SaveJsonNodesInGridFSVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    SaveJsonNodesInGridFSResponse,
    SaveJsonNodesInGridFSError,
    SaveJsonNodesInGridFSRequestBody,
    SaveJsonNodesInGridFSHeaders,
    {},
    {}
  >({ url: '/objects', method: 'post', ...variables, signal });

export const useSaveJsonNodesInGridFS = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SaveJsonNodesInGridFSResponse,
      SaveJsonNodesInGridFSError,
      SaveJsonNodesInGridFSVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    SaveJsonNodesInGridFSResponse,
    SaveJsonNodesInGridFSError,
    SaveJsonNodesInGridFSVariables
  >({
    mutationFn: (variables: SaveJsonNodesInGridFSVariables) =>
      fetchSaveJsonNodesInGridFS({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MigrateWiberaConfigIndexFactorIdsError = Fetcher.ErrorWrapper<undefined>;

export type MigrateWiberaConfigIndexFactorIdsVariables = GriddyContext['fetcherOptions'];

export const fetchMigrateWiberaConfigIndexFactorIds = (
  variables: MigrateWiberaConfigIndexFactorIdsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResult,
    MigrateWiberaConfigIndexFactorIdsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/wibera-config-index-factors', method: 'post', ...variables, signal });

export const useMigrateWiberaConfigIndexFactorIds = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      MigrateWiberaConfigIndexFactorIdsError,
      MigrateWiberaConfigIndexFactorIdsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    MigrateWiberaConfigIndexFactorIdsError,
    MigrateWiberaConfigIndexFactorIdsVariables
  >({
    mutationFn: (variables: MigrateWiberaConfigIndexFactorIdsVariables) =>
      fetchMigrateWiberaConfigIndexFactorIds({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MigrateUseAccountPlanAccountRangeConfigError = Fetcher.ErrorWrapper<undefined>;

export type MigrateUseAccountPlanAccountRangeConfigVariables = GriddyContext['fetcherOptions'];

export const fetchMigrateUseAccountPlanAccountRangeConfig = (
  variables: MigrateUseAccountPlanAccountRangeConfigVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<string, MigrateUseAccountPlanAccountRangeConfigError, undefined, {}, {}, {}>({
    url: '/migration/use-account-plan-range-config',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigrateUseAccountPlanAccountRangeConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      MigrateUseAccountPlanAccountRangeConfigError,
      MigrateUseAccountPlanAccountRangeConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    string,
    MigrateUseAccountPlanAccountRangeConfigError,
    MigrateUseAccountPlanAccountRangeConfigVariables
  >({
    mutationFn: (variables: MigrateUseAccountPlanAccountRangeConfigVariables) =>
      fetchMigrateUseAccountPlanAccountRangeConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MigrateReportIdsError = Fetcher.ErrorWrapper<undefined>;

export type MigrateReportIdsVariables = GriddyContext['fetcherOptions'];

export const fetchMigrateReportIds = (variables: MigrateReportIdsVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.MigrationResultWithErrorCount, MigrateReportIdsError, undefined, {}, {}, {}>({
    url: '/migration/report-id',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigrateReportIds = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResultWithErrorCount,
      MigrateReportIdsError,
      MigrateReportIdsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResultWithErrorCount,
    MigrateReportIdsError,
    MigrateReportIdsVariables
  >({
    mutationFn: (variables: MigrateReportIdsVariables) =>
      fetchMigrateReportIds({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MigratePlannedCostRevenueConfigsError = Fetcher.ErrorWrapper<undefined>;

export type MigratePlannedCostRevenueConfigsVariables = GriddyContext['fetcherOptions'];

export const fetchMigratePlannedCostRevenueConfigs = (
  variables: MigratePlannedCostRevenueConfigsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResult,
    MigratePlannedCostRevenueConfigsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/planned-cost-revenue-config', method: 'post', ...variables, signal });

export const useMigratePlannedCostRevenueConfigs = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      MigratePlannedCostRevenueConfigsError,
      MigratePlannedCostRevenueConfigsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    MigratePlannedCostRevenueConfigsError,
    MigratePlannedCostRevenueConfigsVariables
  >({
    mutationFn: (variables: MigratePlannedCostRevenueConfigsVariables) =>
      fetchMigratePlannedCostRevenueConfigs({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateAccountTransactions1Error = Fetcher.ErrorWrapper<undefined>;

export type UpdateAccountTransactions1Variables = GriddyContext['fetcherOptions'];

export const fetchUpdateAccountTransactions1 = (
  variables: UpdateAccountTransactions1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<string, UpdateAccountTransactions1Error, undefined, {}, {}, {}>({
    url: '/migration/migrateToEsraFileSourceInJournals',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateAccountTransactions1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      UpdateAccountTransactions1Error,
      UpdateAccountTransactions1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    string,
    UpdateAccountTransactions1Error,
    UpdateAccountTransactions1Variables
  >({
    mutationFn: (variables: UpdateAccountTransactions1Variables) =>
      fetchUpdateAccountTransactions1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateToggleValuesError = Fetcher.ErrorWrapper<undefined>;

export type UpdateToggleValuesVariables = GriddyContext['fetcherOptions'];

export const fetchUpdateToggleValues = (
  variables: UpdateToggleValuesVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, UpdateToggleValuesError, undefined, {}, {}, {}>({
    url: '/migration/index-factor',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateToggleValues = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdateToggleValuesError, UpdateToggleValuesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, UpdateToggleValuesError, UpdateToggleValuesVariables>({
    mutationFn: (variables: UpdateToggleValuesVariables) =>
      fetchUpdateToggleValues({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateToggleValues1Error = Fetcher.ErrorWrapper<undefined>;

export type UpdateToggleValues1Variables = GriddyContext['fetcherOptions'];

export const fetchUpdateToggleValues1 = (
  variables: UpdateToggleValues1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, UpdateToggleValues1Error, undefined, {}, {}, {}>({
    url: '/migration/file-metadata-create',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateToggleValues1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateToggleValues1Error,
      UpdateToggleValues1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, UpdateToggleValues1Error, UpdateToggleValues1Variables>({
    mutationFn: (variables: UpdateToggleValues1Variables) =>
      fetchUpdateToggleValues1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableCostUnitAllocationConfigsError = Fetcher.ErrorWrapper<undefined>;

export type EnableCostUnitAllocationConfigsVariables = GriddyContext['fetcherOptions'];

export const fetchEnableCostUnitAllocationConfigs = (
  variables: EnableCostUnitAllocationConfigsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResultWithErrorCount,
    EnableCostUnitAllocationConfigsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/enable-cost-unit-allocation-config', method: 'post', ...variables, signal });

export const useEnableCostUnitAllocationConfigs = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResultWithErrorCount,
      EnableCostUnitAllocationConfigsError,
      EnableCostUnitAllocationConfigsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResultWithErrorCount,
    EnableCostUnitAllocationConfigsError,
    EnableCostUnitAllocationConfigsVariables
  >({
    mutationFn: (variables: EnableCostUnitAllocationConfigsVariables) =>
      fetchEnableCostUnitAllocationConfigs({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePublicFacilitiesError = Fetcher.ErrorWrapper<undefined>;

export type DeletePublicFacilitiesVariables = GriddyContext['fetcherOptions'];

export const fetchDeletePublicFacilities = (
  variables: DeletePublicFacilitiesVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<string, DeletePublicFacilitiesError, undefined, {}, {}, {}>({
    url: '/migration/delete-public-facilities',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeletePublicFacilities = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      DeletePublicFacilitiesError,
      DeletePublicFacilitiesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    string,
    DeletePublicFacilitiesError,
    DeletePublicFacilitiesVariables
  >({
    mutationFn: (variables: DeletePublicFacilitiesVariables) =>
      fetchDeletePublicFacilities({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MigratePublicFacilitiesError = Fetcher.ErrorWrapper<undefined>;

export type MigratePublicFacilitiesVariables = GriddyContext['fetcherOptions'];

export const fetchMigratePublicFacilities = (
  variables: MigratePublicFacilitiesVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResultWithErrorCount,
    MigratePublicFacilitiesError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/create-public-facility', method: 'post', ...variables, signal });

export const useMigratePublicFacilities = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResultWithErrorCount,
      MigratePublicFacilitiesError,
      MigratePublicFacilitiesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResultWithErrorCount,
    MigratePublicFacilitiesError,
    MigratePublicFacilitiesVariables
  >({
    mutationFn: (variables: MigratePublicFacilitiesVariables) =>
      fetchMigratePublicFacilities({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FixMasterConfigurationWithIncorrectCostCenterRanksError =
  Fetcher.ErrorWrapper<undefined>;

export type FixMasterConfigurationWithIncorrectCostCenterRanksVariables =
  GriddyContext['fetcherOptions'];

export const fetchFixMasterConfigurationWithIncorrectCostCenterRanks = (
  variables: FixMasterConfigurationWithIncorrectCostCenterRanksVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResult,
    FixMasterConfigurationWithIncorrectCostCenterRanksError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/cost-center-rank/fix-ranks', method: 'post', ...variables, signal });

export const useFixMasterConfigurationWithIncorrectCostCenterRanks = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      FixMasterConfigurationWithIncorrectCostCenterRanksError,
      FixMasterConfigurationWithIncorrectCostCenterRanksVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    FixMasterConfigurationWithIncorrectCostCenterRanksError,
    FixMasterConfigurationWithIncorrectCostCenterRanksVariables
  >({
    mutationFn: (variables: FixMasterConfigurationWithIncorrectCostCenterRanksVariables) =>
      fetchFixMasterConfigurationWithIncorrectCostCenterRanks({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FixCostCenterDefaultGroupError = Fetcher.ErrorWrapper<undefined>;

export type FixCostCenterDefaultGroupVariables = GriddyContext['fetcherOptions'];

export const fetchFixCostCenterDefaultGroup = (
  variables: FixCostCenterDefaultGroupVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<Schemas.MigrationResult, FixCostCenterDefaultGroupError, undefined, {}, {}, {}>({
    url: '/migration/cost-center-default-group',
    method: 'post',
    ...variables,
    signal,
  });

export const useFixCostCenterDefaultGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResult,
      FixCostCenterDefaultGroupError,
      FixCostCenterDefaultGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResult,
    FixCostCenterDefaultGroupError,
    FixCostCenterDefaultGroupVariables
  >({
    mutationFn: (variables: FixCostCenterDefaultGroupVariables) =>
      fetchFixCostCenterDefaultGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MigrateBackupMasterConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type MigrateBackupMasterConfigurationsVariables = GriddyContext['fetcherOptions'];

export const fetchMigrateBackupMasterConfigurations = (
  variables: MigrateBackupMasterConfigurationsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResultWithErrorCount,
    MigrateBackupMasterConfigurationsError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/backup-master-configuration', method: 'post', ...variables, signal });

export const useMigrateBackupMasterConfigurations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MigrationResultWithErrorCount,
      MigrateBackupMasterConfigurationsError,
      MigrateBackupMasterConfigurationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MigrationResultWithErrorCount,
    MigrateBackupMasterConfigurationsError,
    MigrateBackupMasterConfigurationsVariables
  >({
    mutationFn: (variables: MigrateBackupMasterConfigurationsVariables) =>
      fetchMigrateBackupMasterConfigurations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddIndexesError = Fetcher.ErrorWrapper<undefined>;

export type AddIndexesVariables = GriddyContext['fetcherOptions'];

export const fetchAddIndexes = (variables: AddIndexesVariables, signal?: AbortSignal) =>
  griddyFetch<undefined, AddIndexesError, undefined, {}, {}, {}>({
    url: '/migration/add-indexes',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddIndexes = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddIndexesError, AddIndexesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, AddIndexesError, AddIndexesVariables>({
    mutationFn: (variables: AddIndexesVariables) =>
      fetchAddIndexes({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type UpsertMasterConfigurationVariables = {
  body: Schemas.MasterConfiguration;
} & GriddyContext['fetcherOptions'];

export const fetchUpsertMasterConfiguration = (
  variables: UpsertMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.EntityUpsertedResponse,
    UpsertMasterConfigurationError,
    Schemas.MasterConfiguration,
    {},
    {},
    {}
  >({ url: '/masterConfiguration', method: 'post', ...variables, signal });

export const useUpsertMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EntityUpsertedResponse,
      UpsertMasterConfigurationError,
      UpsertMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.EntityUpsertedResponse,
    UpsertMasterConfigurationError,
    UpsertMasterConfigurationVariables
  >({
    mutationFn: (variables: UpsertMasterConfigurationVariables) =>
      fetchUpsertMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CopyConfigurationPathParams {
  masterConfigurationId: string;
  targetMasterConfigId: string;
}

export type CopyConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type CopyConfigurationVariables = {
  pathParams: CopyConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCopyConfiguration = (
  variables: CopyConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, CopyConfigurationError, undefined, {}, {}, CopyConfigurationPathParams>({
    url: '/master-configurations/{masterConfigurationId}/technical-data/copy/master-config/{targetMasterConfigId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, CopyConfigurationError, CopyConfigurationVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, CopyConfigurationError, CopyConfigurationVariables>({
    mutationFn: (variables: CopyConfigurationVariables) =>
      fetchCopyConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface SyncMasterConfigurationCostUnitBasicFeePathParams {
  masterConfigurationId: string;
}

export type SyncMasterConfigurationCostUnitBasicFeeError = Fetcher.ErrorWrapper<undefined>;

export type SyncMasterConfigurationCostUnitBasicFeeVariables = {
  body?: Schemas.UpdateCostUnitBasicFee;
  pathParams: SyncMasterConfigurationCostUnitBasicFeePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchSyncMasterConfigurationCostUnitBasicFee = (
  variables: SyncMasterConfigurationCostUnitBasicFeeVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    SyncMasterConfigurationCostUnitBasicFeeError,
    Schemas.UpdateCostUnitBasicFee,
    {},
    {},
    SyncMasterConfigurationCostUnitBasicFeePathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/sync-cost-unit',
    method: 'post',
    ...variables,
    signal,
  });

export const useSyncMasterConfigurationCostUnitBasicFee = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SyncMasterConfigurationCostUnitBasicFeeError,
      SyncMasterConfigurationCostUnitBasicFeeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    SyncMasterConfigurationCostUnitBasicFeeError,
    SyncMasterConfigurationCostUnitBasicFeeVariables
  >({
    mutationFn: (variables: SyncMasterConfigurationCostUnitBasicFeeVariables) =>
      fetchSyncMasterConfigurationCostUnitBasicFee({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreatePlannedCostRevenueDirectEntryPathParams {
  masterConfigurationId: string;
  plannedCostTypeAccountEntryId: string;
  plannedAccountEntryId: string;
  directEntryName: string;
}

export type CreatePlannedCostRevenueDirectEntryError = Fetcher.ErrorWrapper<undefined>;

export type CreatePlannedCostRevenueDirectEntryVariables = {
  pathParams: CreatePlannedCostRevenueDirectEntryPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCreatePlannedCostRevenueDirectEntry = (
  variables: CreatePlannedCostRevenueDirectEntryVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedDirectEntryDTO,
    CreatePlannedCostRevenueDirectEntryError,
    undefined,
    {},
    {},
    CreatePlannedCostRevenueDirectEntryPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/{plannedCostTypeAccountEntryId}/account/{plannedAccountEntryId}/direct-entry/{directEntryName}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreatePlannedCostRevenueDirectEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PlannedDirectEntryDTO,
      CreatePlannedCostRevenueDirectEntryError,
      CreatePlannedCostRevenueDirectEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.PlannedDirectEntryDTO,
    CreatePlannedCostRevenueDirectEntryError,
    CreatePlannedCostRevenueDirectEntryVariables
  >({
    mutationFn: (variables: CreatePlannedCostRevenueDirectEntryVariables) =>
      fetchCreatePlannedCostRevenueDirectEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CopyConfiguration1PathParams {
  masterConfigurationId: string;
  targetMasterConfigId: string;
}

export type CopyConfiguration1Error = Fetcher.ErrorWrapper<undefined>;

export type CopyConfiguration1Variables = {
  pathParams: CopyConfiguration1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCopyConfiguration1 = (
  variables: CopyConfiguration1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, CopyConfiguration1Error, undefined, {}, {}, CopyConfiguration1PathParams>({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/copy/master-config/{targetMasterConfigId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfiguration1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, CopyConfiguration1Error, CopyConfiguration1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, CopyConfiguration1Error, CopyConfiguration1Variables>({
    mutationFn: (variables: CopyConfiguration1Variables) =>
      fetchCopyConfiguration1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateVariantOfMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type CreateVariantOfMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type CreateVariantOfMasterConfigurationVariables = {
  pathParams: CreateVariantOfMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCreateVariantOfMasterConfiguration = (
  variables: CreateVariantOfMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfigurationEntityDTO,
    CreateVariantOfMasterConfigurationError,
    undefined,
    {},
    {},
    CreateVariantOfMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/create-variant',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateVariantOfMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CreateVariantOfMasterConfigurationError,
      CreateVariantOfMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CreateVariantOfMasterConfigurationError,
    CreateVariantOfMasterConfigurationVariables
  >({
    mutationFn: (variables: CreateVariantOfMasterConfigurationVariables) =>
      fetchCreateVariantOfMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateBackupOfMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type CreateBackupOfMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type CreateBackupOfMasterConfigurationVariables = {
  pathParams: CreateBackupOfMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCreateBackupOfMasterConfiguration = (
  variables: CreateBackupOfMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfigurationEntityDTO,
    CreateBackupOfMasterConfigurationError,
    undefined,
    {},
    {},
    CreateBackupOfMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/create-backup',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBackupOfMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CreateBackupOfMasterConfigurationError,
      CreateBackupOfMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CreateBackupOfMasterConfigurationError,
    CreateBackupOfMasterConfigurationVariables
  >({
    mutationFn: (variables: CreateBackupOfMasterConfigurationVariables) =>
      fetchCreateBackupOfMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CopyConfigPathParams {
  masterConfigurationId: string;
  targetYearCalculationId: string;
}

export type CopyConfigError = Fetcher.ErrorWrapper<undefined>;

export type CopyConfigVariables = {
  pathParams: CopyConfigPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCopyConfig = (variables: CopyConfigVariables, signal?: AbortSignal) =>
  griddyFetch<
    Schemas.MasterConfigurationEntityDTO,
    CopyConfigError,
    undefined,
    {},
    {},
    CopyConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/copy/year-calculations/{targetYearCalculationId}/master-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MasterConfigurationEntityDTO,
      CopyConfigError,
      CopyConfigVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.MasterConfigurationEntityDTO,
    CopyConfigError,
    CopyConfigVariables
  >({
    mutationFn: (variables: CopyConfigVariables) =>
      fetchCopyConfig({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetRangeConfigForMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type GetRangeConfigForMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetRangeConfigForMasterConfigurationVariables = {
  pathParams: GetRangeConfigForMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetRangeConfigForMasterConfiguration = (
  variables: GetRangeConfigForMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    undefined,
    {},
    {},
    GetRangeConfigForMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetRangeConfigForMasterConfiguration = <TData = Schemas.RangeConfigDTO>(
  variables: GetRangeConfigForMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RangeConfigDTO,
      GetRangeConfigForMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.RangeConfigDTO,
    GetRangeConfigForMasterConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/account-ranges',
      operationId: 'getRangeConfigForMasterConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetRangeConfigForMasterConfiguration({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface PostRangePathParams {
  masterConfigurationId: string;
}

export type PostRangeError = Fetcher.ErrorWrapper<undefined>;

export type PostRangeVariables = {
  body?: Schemas.RangeDTO;
  pathParams: PostRangePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchPostRange = (variables: PostRangeVariables, signal?: AbortSignal) =>
  griddyFetch<
    Schemas.RangeConfigDTO,
    PostRangeError,
    Schemas.RangeDTO,
    {},
    {},
    PostRangePathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RangeConfigDTO, PostRangeError, PostRangeVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<Schemas.RangeConfigDTO, PostRangeError, PostRangeVariables>({
    mutationFn: (variables: PostRangeVariables) =>
      fetchPostRange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CopyConfiguration2PathParams {
  masterConfigurationId: string;
  targetMasterConfigId: string;
}

export type CopyConfiguration2Error = Fetcher.ErrorWrapper<undefined>;

export type CopyConfiguration2Variables = {
  pathParams: CopyConfiguration2PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCopyConfiguration2 = (
  variables: CopyConfiguration2Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, CopyConfiguration2Error, undefined, {}, {}, CopyConfiguration2PathParams>({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/copy/master-config/{targetMasterConfigId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCopyConfiguration2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, CopyConfiguration2Error, CopyConfiguration2Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, CopyConfiguration2Error, CopyConfiguration2Variables>({
    mutationFn: (variables: CopyConfiguration2Variables) =>
      fetchCopyConfiguration2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SyncMasterConfigurationCostUnitBasicFee1Error = Fetcher.ErrorWrapper<undefined>;

export type SyncMasterConfigurationCostUnitBasicFee1Variables = {
  body?: Schemas.UpdateCostUnitBasicFee;
} & GriddyContext['fetcherOptions'];

export const fetchSyncMasterConfigurationCostUnitBasicFee1 = (
  variables: SyncMasterConfigurationCostUnitBasicFee1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    SyncMasterConfigurationCostUnitBasicFee1Error,
    Schemas.UpdateCostUnitBasicFee,
    {},
    {},
    {}
  >({ url: '/master-configuration/cost-unit', method: 'post', ...variables, signal });

export const useSyncMasterConfigurationCostUnitBasicFee1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SyncMasterConfigurationCostUnitBasicFee1Error,
      SyncMasterConfigurationCostUnitBasicFee1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    SyncMasterConfigurationCostUnitBasicFee1Error,
    SyncMasterConfigurationCostUnitBasicFee1Variables
  >({
    mutationFn: (variables: SyncMasterConfigurationCostUnitBasicFee1Variables) =>
      fetchSyncMasterConfigurationCostUnitBasicFee1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllLedgerAccountQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllLedgerAccountError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLedgerAccountResponse = Schemas.LedgerAccount[];

export type GetAllLedgerAccountVariables = {
  queryParams: GetAllLedgerAccountQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllLedgerAccount = (
  variables: GetAllLedgerAccountVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllLedgerAccountResponse,
    GetAllLedgerAccountError,
    undefined,
    {},
    GetAllLedgerAccountQueryParams,
    {}
  >({ url: '/ledgeraccounts', method: 'get', ...variables, signal });

export const useGetAllLedgerAccount = <TData = GetAllLedgerAccountResponse>(
  variables: GetAllLedgerAccountVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllLedgerAccountResponse, GetAllLedgerAccountError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllLedgerAccountResponse, GetAllLedgerAccountError, TData>({
    queryKey: queryKeyFn({
      path: '/ledgeraccounts',
      operationId: 'getAllLedgerAccount',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetAllLedgerAccount({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateLedgerAccountError = Fetcher.ErrorWrapper<undefined>;

export type UpdateLedgerAccountVariables = {
  body: Schemas.LedgerAccountFilesRequest;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateLedgerAccount = (
  variables: UpdateLedgerAccountVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, UpdateLedgerAccountError, Schemas.LedgerAccountFilesRequest, {}, {}, {}>({
    url: '/ledgeraccounts',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateLedgerAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateLedgerAccountError,
      UpdateLedgerAccountVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, UpdateLedgerAccountError, UpdateLedgerAccountVariables>({
    mutationFn: (variables: UpdateLedgerAccountVariables) =>
      fetchUpdateLedgerAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteFileQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type DeleteFileError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileVariables = {
  queryParams: DeleteFileQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteFile = (variables: DeleteFileVariables, signal?: AbortSignal) =>
  griddyFetch<undefined, DeleteFileError, undefined, {}, DeleteFileQueryParams, {}>({
    url: '/ledgeraccounts',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFile = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFileError, DeleteFileVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteFileError, DeleteFileVariables>({
    mutationFn: (variables: DeleteFileVariables) =>
      fetchDeleteFile({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LedgeraccountsPostImportError = Fetcher.ErrorWrapper<undefined>;

export type LedgeraccountsPostImportVariables = {
  body: Schemas.CamsPostImportFileRequest;
} & GriddyContext['fetcherOptions'];

export const fetchLedgeraccountsPostImport = (
  variables: LedgeraccountsPostImportVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    LedgeraccountsPostImportError,
    Schemas.CamsPostImportFileRequest,
    {},
    {},
    {}
  >({ url: '/ledgeraccounts/post-import', method: 'post', ...variables, signal });

export const useLedgeraccountsPostImport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      LedgeraccountsPostImportError,
      LedgeraccountsPostImportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    LedgeraccountsPostImportError,
    LedgeraccountsPostImportVariables
  >({
    mutationFn: (variables: LedgeraccountsPostImportVariables) =>
      fetchLedgeraccountsPostImport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllLabelsQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllLabelsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLabelsResponse = Schemas.LabelRecord[];

export type GetAllLabelsVariables = {
  queryParams: GetAllLabelsQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllLabels = (variables: GetAllLabelsVariables, signal?: AbortSignal) =>
  griddyFetch<GetAllLabelsResponse, GetAllLabelsError, undefined, {}, GetAllLabelsQueryParams, {}>({
    url: '/labels',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllLabels = <TData = GetAllLabelsResponse>(
  variables: GetAllLabelsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllLabelsResponse, GetAllLabelsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllLabelsResponse, GetAllLabelsError, TData>({
    queryKey: queryKeyFn({ path: '/labels', operationId: 'getAllLabels', variables }),
    queryFn: ({ signal }) => fetchGetAllLabels({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateLabelsError = Fetcher.ErrorWrapper<undefined>;

export type UpdateLabelsVariables = {
  body: Schemas.LabelFileRequest;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateLabels = (variables: UpdateLabelsVariables, signal?: AbortSignal) =>
  griddyFetch<undefined, UpdateLabelsError, Schemas.LabelFileRequest, {}, {}, {}>({
    url: '/labels',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateLabels = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdateLabelsError, UpdateLabelsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, UpdateLabelsError, UpdateLabelsVariables>({
    mutationFn: (variables: UpdateLabelsVariables) =>
      fetchUpdateLabels({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteFile1QueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type DeleteFile1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteFile1Variables = {
  queryParams: DeleteFile1QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteFile1 = (variables: DeleteFile1Variables, signal?: AbortSignal) =>
  griddyFetch<undefined, DeleteFile1Error, undefined, {}, DeleteFile1QueryParams, {}>({
    url: '/labels',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFile1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFile1Error, DeleteFile1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteFile1Error, DeleteFile1Variables>({
    mutationFn: (variables: DeleteFile1Variables) =>
      fetchDeleteFile1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllTransactionForJournalQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllTransactionForJournalError = Fetcher.ErrorWrapper<undefined>;

export type GetAllTransactionForJournalResponse = Schemas.AccountTransaction[];

export type GetAllTransactionForJournalVariables = {
  queryParams: GetAllTransactionForJournalQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllTransactionForJournal = (
  variables: GetAllTransactionForJournalVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllTransactionForJournalResponse,
    GetAllTransactionForJournalError,
    undefined,
    {},
    GetAllTransactionForJournalQueryParams,
    {}
  >({ url: '/journal', method: 'get', ...variables, signal });

export const useGetAllTransactionForJournal = <TData = GetAllTransactionForJournalResponse>(
  variables: GetAllTransactionForJournalVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllTransactionForJournalResponse,
      GetAllTransactionForJournalError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllTransactionForJournalResponse,
    GetAllTransactionForJournalError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/journal',
      operationId: 'getAllTransactionForJournal',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllTransactionForJournal({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAccountTransactions2Error = Fetcher.ErrorWrapper<undefined>;

export type UpdateAccountTransactions2Variables = {
  body: Schemas.JournalFileRequest;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateAccountTransactions2 = (
  variables: UpdateAccountTransactions2Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, UpdateAccountTransactions2Error, Schemas.JournalFileRequest, {}, {}, {}>({
    url: '/journal',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateAccountTransactions2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateAccountTransactions2Error,
      UpdateAccountTransactions2Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    UpdateAccountTransactions2Error,
    UpdateAccountTransactions2Variables
  >({
    mutationFn: (variables: UpdateAccountTransactions2Variables) =>
      fetchUpdateAccountTransactions2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteFile2QueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type DeleteFile2Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteFile2Variables = {
  queryParams: DeleteFile2QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteFile2 = (variables: DeleteFile2Variables, signal?: AbortSignal) =>
  griddyFetch<undefined, DeleteFile2Error, undefined, {}, DeleteFile2QueryParams, {}>({
    url: '/journal',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFile2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFile2Error, DeleteFile2Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteFile2Error, DeleteFile2Variables>({
    mutationFn: (variables: DeleteFile2Variables) =>
      fetchDeleteFile2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type JournalPostImportError = Fetcher.ErrorWrapper<undefined>;

export type JournalPostImportVariables = {
  body: Schemas.CamsPostImportFileRequest;
} & GriddyContext['fetcherOptions'];

export const fetchJournalPostImport = (
  variables: JournalPostImportVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, JournalPostImportError, Schemas.CamsPostImportFileRequest, {}, {}, {}>({
    url: '/journal/post-import',
    method: 'post',
    ...variables,
    signal,
  });

export const useJournalPostImport = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, JournalPostImportError, JournalPostImportVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, JournalPostImportError, JournalPostImportVariables>({
    mutationFn: (variables: JournalPostImportVariables) =>
      fetchJournalPostImport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetClientsQueryParams {
  onlyArchived?: boolean;
}

export interface GetClientsHeaders {
  authorization?: string;
}

export type GetClientsError = Fetcher.ErrorWrapper<undefined>;

export type GetClientsResponse = Schemas.ClientDocument[];

export type GetClientsVariables = {
  headers?: GetClientsHeaders;
  queryParams?: GetClientsQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetClients = (variables: GetClientsVariables, signal?: AbortSignal) =>
  griddyFetch<
    GetClientsResponse,
    GetClientsError,
    undefined,
    GetClientsHeaders,
    GetClientsQueryParams,
    {}
  >({ url: '/clients', method: 'get', ...variables, signal });

export const useGetClients = <TData = GetClientsResponse>(
  variables: GetClientsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientsResponse, GetClientsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetClientsResponse, GetClientsError, TData>({
    queryKey: queryKeyFn({ path: '/clients', operationId: 'getClients', variables }),
    queryFn: ({ signal }) => fetchGetClients({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface SaveClientsHeaders {
  authorization?: string;
}

export type SaveClientsError = Fetcher.ErrorWrapper<undefined>;

export type SaveClientsResponse = Schemas.ClientDocument[];

export type SaveClientsRequestBody = Schemas.ClientDocument[];

export type SaveClientsVariables = {
  body?: SaveClientsRequestBody;
  headers?: SaveClientsHeaders;
} & GriddyContext['fetcherOptions'];

export const fetchSaveClients = (variables: SaveClientsVariables, signal?: AbortSignal) =>
  griddyFetch<
    SaveClientsResponse,
    SaveClientsError,
    SaveClientsRequestBody,
    SaveClientsHeaders,
    {},
    {}
  >({ url: '/clients', method: 'post', ...variables, signal });

export const useSaveClients = (
  options?: Omit<
    reactQuery.UseMutationOptions<SaveClientsResponse, SaveClientsError, SaveClientsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<SaveClientsResponse, SaveClientsError, SaveClientsVariables>({
    mutationFn: (variables: SaveClientsVariables) =>
      fetchSaveClients({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteClient1QueryParams {
  /**
   * @format int32
   */
  clientId: number;
}

export interface DeleteClient1Headers {
  authorization?: string;
}

export type DeleteClient1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteClient1Variables = {
  headers?: DeleteClient1Headers;
  queryParams: DeleteClient1QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteClient1 = (variables: DeleteClient1Variables, signal?: AbortSignal) =>
  griddyFetch<
    undefined,
    DeleteClient1Error,
    undefined,
    DeleteClient1Headers,
    DeleteClient1QueryParams,
    {}
  >({ url: '/clients', method: 'delete', ...variables, signal });

export const useDeleteClient1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteClient1Error, DeleteClient1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteClient1Error, DeleteClient1Variables>({
    mutationFn: (variables: DeleteClient1Variables) =>
      fetchDeleteClient1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface AddPublicFacilityFavoritePathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface AddPublicFacilityFavoriteHeaders {
  authorization?: string;
}

export type AddPublicFacilityFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type AddPublicFacilityFavoriteVariables = {
  headers?: AddPublicFacilityFavoriteHeaders;
  pathParams: AddPublicFacilityFavoritePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchAddPublicFacilityFavorite = (
  variables: AddPublicFacilityFavoriteVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    AddPublicFacilityFavoriteError,
    undefined,
    AddPublicFacilityFavoriteHeaders,
    {},
    AddPublicFacilityFavoritePathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/favorites',
    method: 'post',
    ...variables,
    signal,
  });

export const useAddPublicFacilityFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddPublicFacilityFavoriteError,
      AddPublicFacilityFavoriteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    AddPublicFacilityFavoriteError,
    AddPublicFacilityFavoriteVariables
  >({
    mutationFn: (variables: AddPublicFacilityFavoriteVariables) =>
      fetchAddPublicFacilityFavorite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface RemovePublicFacilityFavoritePathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface RemovePublicFacilityFavoriteHeaders {
  authorization?: string;
}

export type RemovePublicFacilityFavoriteError = Fetcher.ErrorWrapper<undefined>;

export type RemovePublicFacilityFavoriteVariables = {
  headers?: RemovePublicFacilityFavoriteHeaders;
  pathParams: RemovePublicFacilityFavoritePathParams;
} & GriddyContext['fetcherOptions'];

export const fetchRemovePublicFacilityFavorite = (
  variables: RemovePublicFacilityFavoriteVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    RemovePublicFacilityFavoriteError,
    undefined,
    RemovePublicFacilityFavoriteHeaders,
    {},
    RemovePublicFacilityFavoritePathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}/favorites',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemovePublicFacilityFavorite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemovePublicFacilityFavoriteError,
      RemovePublicFacilityFavoriteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    RemovePublicFacilityFavoriteError,
    RemovePublicFacilityFavoriteVariables
  >({
    mutationFn: (variables: RemovePublicFacilityFavoriteVariables) =>
      fetchRemovePublicFacilityFavorite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface ShareClient1QueryParams {
  /**
   * @format int32
   */
  clientId: number;
}

export interface ShareClient1Headers {
  authorization?: string;
}

export type ShareClient1Error = Fetcher.ErrorWrapper<undefined>;

export type ShareClient1RequestBody = string[];

export type ShareClient1Variables = {
  body?: ShareClient1RequestBody;
  headers?: ShareClient1Headers;
  queryParams: ShareClient1QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchShareClient1 = (variables: ShareClient1Variables, signal?: AbortSignal) =>
  griddyFetch<
    undefined,
    ShareClient1Error,
    ShareClient1RequestBody,
    ShareClient1Headers,
    ShareClient1QueryParams,
    {}
  >({ url: '/clients/share', method: 'post', ...variables, signal });

export const useShareClient1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ShareClient1Error, ShareClient1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, ShareClient1Error, ShareClient1Variables>({
    mutationFn: (variables: ShareClient1Variables) =>
      fetchShareClient1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertBabReportError = Fetcher.ErrorWrapper<undefined>;

export type UpsertBabReportVariables = {
  body?: Schemas.BabReport;
} & GriddyContext['fetcherOptions'];

export const fetchUpsertBabReport = (variables: UpsertBabReportVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.EntityUpsertedResponse, UpsertBabReportError, Schemas.BabReport, {}, {}, {}>({
    url: '/babreports',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpsertBabReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EntityUpsertedResponse,
      UpsertBabReportError,
      UpsertBabReportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.EntityUpsertedResponse,
    UpsertBabReportError,
    UpsertBabReportVariables
  >({
    mutationFn: (variables: UpsertBabReportVariables) =>
      fetchUpsertBabReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface CreateBabCostCenterPathParams {
  yearId: string;
  masterConfigId: string;
  creationType: 'PROFIT_CENTER' | 'COST_CENTER' | 'JOB_ID';
}

export type CreateBabCostCenterError = Fetcher.ErrorWrapper<undefined>;

export type CreateBabCostCenterVariables = {
  pathParams: CreateBabCostCenterPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchCreateBabCostCenter = (
  variables: CreateBabCostCenterVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.BabCostCenterCreationResponse,
    CreateBabCostCenterError,
    undefined,
    {},
    {},
    CreateBabCostCenterPathParams
  >({
    url: '/bab-cost-centers/{yearId}/{masterConfigId}/{creationType}',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateBabCostCenter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.BabCostCenterCreationResponse,
      CreateBabCostCenterError,
      CreateBabCostCenterVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    Schemas.BabCostCenterCreationResponse,
    CreateBabCostCenterError,
    CreateBabCostCenterVariables
  >({
    mutationFn: (variables: CreateBabCostCenterVariables) =>
      fetchCreateBabCostCenter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllAccountPlanRecordsQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllAccountPlanRecordsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAccountPlanRecordsResponse = Schemas.AccountPlan[];

export type GetAllAccountPlanRecordsVariables = {
  queryParams: GetAllAccountPlanRecordsQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllAccountPlanRecords = (
  variables: GetAllAccountPlanRecordsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllAccountPlanRecordsResponse,
    GetAllAccountPlanRecordsError,
    undefined,
    {},
    GetAllAccountPlanRecordsQueryParams,
    {}
  >({ url: '/accountsplan', method: 'get', ...variables, signal });

export const useGetAllAccountPlanRecords = <TData = GetAllAccountPlanRecordsResponse>(
  variables: GetAllAccountPlanRecordsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllAccountPlanRecordsResponse,
      GetAllAccountPlanRecordsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllAccountPlanRecordsResponse,
    GetAllAccountPlanRecordsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/accountsplan',
      operationId: 'getAllAccountPlanRecords',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllAccountPlanRecords({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAccountPlanError = Fetcher.ErrorWrapper<undefined>;

export type UpdateAccountPlanVariables = {
  body: Schemas.AccountPlanFileRequest;
} & GriddyContext['fetcherOptions'];

export const fetchUpdateAccountPlan = (
  variables: UpdateAccountPlanVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, UpdateAccountPlanError, Schemas.AccountPlanFileRequest, {}, {}, {}>({
    url: '/accountsplan',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateAccountPlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdateAccountPlanError, UpdateAccountPlanVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, UpdateAccountPlanError, UpdateAccountPlanVariables>({
    mutationFn: (variables: UpdateAccountPlanVariables) =>
      fetchUpdateAccountPlan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteAccountPlanQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type DeleteAccountPlanError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAccountPlanVariables = {
  queryParams: DeleteAccountPlanQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteAccountPlan = (
  variables: DeleteAccountPlanVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, DeleteAccountPlanError, undefined, {}, DeleteAccountPlanQueryParams, {}>({
    url: '/accountsplan',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAccountPlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteAccountPlanError, DeleteAccountPlanVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteAccountPlanError, DeleteAccountPlanVariables>({
    mutationFn: (variables: DeleteAccountPlanVariables) =>
      fetchDeleteAccountPlan({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllAcctIdToJobIdsMapQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllAcctIdToJobIdsMapError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAcctIdToJobIdsMapResponse = Record<string, string[]>;

export type GetAllAcctIdToJobIdsMapVariables = {
  queryParams: GetAllAcctIdToJobIdsMapQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllAcctIdToJobIdsMap = (
  variables: GetAllAcctIdToJobIdsMapVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllAcctIdToJobIdsMapResponse,
    GetAllAcctIdToJobIdsMapError,
    undefined,
    {},
    GetAllAcctIdToJobIdsMapQueryParams,
    {}
  >({ url: '/account_jobid_map', method: 'get', ...variables, signal });

export const useGetAllAcctIdToJobIdsMap = <TData = GetAllAcctIdToJobIdsMapResponse>(
  variables: GetAllAcctIdToJobIdsMapVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllAcctIdToJobIdsMapResponse,
      GetAllAcctIdToJobIdsMapError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllAcctIdToJobIdsMapResponse, GetAllAcctIdToJobIdsMapError, TData>({
    queryKey: queryKeyFn({
      path: '/account_jobid_map',
      operationId: 'getAllAcctIdToJobIdsMap',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllAcctIdToJobIdsMap({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAllAcctIdToJobIdsMapByAccountRangeError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAcctIdToJobIdsMapByAccountRangeResponse = Record<string, string[]>;

export type GetAllAcctIdToJobIdsMapByAccountRangeVariables = {
  body: Schemas.AccountRequest;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllAcctIdToJobIdsMapByAccountRange = (
  variables: GetAllAcctIdToJobIdsMapByAccountRangeVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllAcctIdToJobIdsMapByAccountRangeResponse,
    GetAllAcctIdToJobIdsMapByAccountRangeError,
    Schemas.AccountRequest,
    {},
    {},
    {}
  >({ url: '/account_jobid_map', method: 'post', ...variables, signal });

export const useGetAllAcctIdToJobIdsMapByAccountRange = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetAllAcctIdToJobIdsMapByAccountRangeResponse,
      GetAllAcctIdToJobIdsMapByAccountRangeError,
      GetAllAcctIdToJobIdsMapByAccountRangeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    GetAllAcctIdToJobIdsMapByAccountRangeResponse,
    GetAllAcctIdToJobIdsMapByAccountRangeError,
    GetAllAcctIdToJobIdsMapByAccountRangeVariables
  >({
    mutationFn: (variables: GetAllAcctIdToJobIdsMapByAccountRangeVariables) =>
      fetchGetAllAcctIdToJobIdsMapByAccountRange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllTransactionsForAccountsQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  accountId: string[];
  yearId: string;
}

export type GetAllTransactionsForAccountsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllTransactionsForAccountsResponse = Schemas.AccountTransaction[];

export type GetAllTransactionsForAccountsVariables = {
  queryParams: GetAllTransactionsForAccountsQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllTransactionsForAccounts = (
  variables: GetAllTransactionsForAccountsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllTransactionsForAccountsResponse,
    GetAllTransactionsForAccountsError,
    undefined,
    {},
    GetAllTransactionsForAccountsQueryParams,
    {}
  >({ url: '/account', method: 'get', ...variables, signal });

export const useGetAllTransactionsForAccounts = <TData = GetAllTransactionsForAccountsResponse>(
  variables: GetAllTransactionsForAccountsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllTransactionsForAccountsResponse,
      GetAllTransactionsForAccountsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllTransactionsForAccountsResponse,
    GetAllTransactionsForAccountsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/account',
      operationId: 'getAllTransactionsForAccounts',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllTransactionsForAccounts({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllTransactionsForAccountsWithBodyQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllTransactionsForAccountsWithBodyError = Fetcher.ErrorWrapper<undefined>;

export type GetAllTransactionsForAccountsWithBodyResponse = Schemas.AccountTransaction[];

export type GetAllTransactionsForAccountsWithBodyRequestBody = string[];

export type GetAllTransactionsForAccountsWithBodyVariables = {
  body?: GetAllTransactionsForAccountsWithBodyRequestBody;
  queryParams: GetAllTransactionsForAccountsWithBodyQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllTransactionsForAccountsWithBody = (
  variables: GetAllTransactionsForAccountsWithBodyVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllTransactionsForAccountsWithBodyResponse,
    GetAllTransactionsForAccountsWithBodyError,
    GetAllTransactionsForAccountsWithBodyRequestBody,
    {},
    GetAllTransactionsForAccountsWithBodyQueryParams,
    {}
  >({ url: '/account', method: 'post', ...variables, signal });

export const useGetAllTransactionsForAccountsWithBody = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetAllTransactionsForAccountsWithBodyResponse,
      GetAllTransactionsForAccountsWithBodyError,
      GetAllTransactionsForAccountsWithBodyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    GetAllTransactionsForAccountsWithBodyResponse,
    GetAllTransactionsForAccountsWithBodyError,
    GetAllTransactionsForAccountsWithBodyVariables
  >({
    mutationFn: (variables: GetAllTransactionsForAccountsWithBodyVariables) =>
      fetchGetAllTransactionsForAccountsWithBody({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AccountPlanPostImportError = Fetcher.ErrorWrapper<undefined>;

export type AccountPlanPostImportVariables = {
  body: Schemas.CamsPostImportFileRequest;
} & GriddyContext['fetcherOptions'];

export const fetchAccountPlanPostImport = (
  variables: AccountPlanPostImportVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, AccountPlanPostImportError, Schemas.CamsPostImportFileRequest, {}, {}, {}>(
    { url: '/account-plan/post-import', method: 'post', ...variables, signal },
  );

export const useAccountPlanPostImport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AccountPlanPostImportError,
      AccountPlanPostImportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    AccountPlanPostImportError,
    AccountPlanPostImportVariables
  >({
    mutationFn: (variables: AccountPlanPostImportVariables) =>
      fetchAccountPlanPostImport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetFlattenedProfitCenterTreesForCostUnitPathParams {
  yearId: string;
  masterConfigId: string;
}

export type GetFlattenedProfitCenterTreesForCostUnitError = Fetcher.ErrorWrapper<undefined>;

export type GetFlattenedProfitCenterTreesForCostUnitResponse = Record<
  string,
  Schemas.AcctTrxSelectCostUnitCriteriaDTO
>;

export type GetFlattenedProfitCenterTreesForCostUnitVariables = {
  pathParams: GetFlattenedProfitCenterTreesForCostUnitPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetFlattenedProfitCenterTreesForCostUnit = (
  variables: GetFlattenedProfitCenterTreesForCostUnitVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetFlattenedProfitCenterTreesForCostUnitResponse,
    GetFlattenedProfitCenterTreesForCostUnitError,
    undefined,
    {},
    {},
    GetFlattenedProfitCenterTreesForCostUnitPathParams
  >({
    url: '/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetFlattenedProfitCenterTreesForCostUnit = <
  TData = GetFlattenedProfitCenterTreesForCostUnitResponse,
>(
  variables: GetFlattenedProfitCenterTreesForCostUnitVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFlattenedProfitCenterTreesForCostUnitResponse,
      GetFlattenedProfitCenterTreesForCostUnitError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetFlattenedProfitCenterTreesForCostUnitResponse,
    GetFlattenedProfitCenterTreesForCostUnitError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees',
      operationId: 'getFlattenedProfitCenterTreesForCostUnit',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFlattenedProfitCenterTreesForCostUnit({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetDataContainerByIdPathParams {
  ids: string[];
}

export type GetDataContainerByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetDataContainerByIdVariables = {
  pathParams: GetDataContainerByIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetDataContainerById = (
  variables: GetDataContainerByIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Record<string, any>,
    GetDataContainerByIdError,
    undefined,
    {},
    {},
    GetDataContainerByIdPathParams
  >({ url: '/years/{ids}', method: 'get', ...variables, signal });

export const useGetDataContainerById = <TData = Record<string, any>>(
  variables: GetDataContainerByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Record<string, any>, GetDataContainerByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Record<string, any>, GetDataContainerByIdError, TData>({
    queryKey: queryKeyFn({ path: '/years/{ids}', operationId: 'getDataContainerById', variables }),
    queryFn: ({ signal }) => fetchGetDataContainerById({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetYearIdByMasterConfigIdPathParams {
  masterConfigId: string;
}

export type GetYearIdByMasterConfigIdError = Fetcher.ErrorWrapper<undefined>;

export type GetYearIdByMasterConfigIdVariables = {
  pathParams: GetYearIdByMasterConfigIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetYearIdByMasterConfigId = (
  variables: GetYearIdByMasterConfigIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    string,
    GetYearIdByMasterConfigIdError,
    undefined,
    {},
    {},
    GetYearIdByMasterConfigIdPathParams
  >({ url: '/years/masterConfigId/{masterConfigId}', method: 'get', ...variables, signal });

export const useGetYearIdByMasterConfigId = <TData = string>(
  variables: GetYearIdByMasterConfigIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetYearIdByMasterConfigIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<string, GetYearIdByMasterConfigIdError, TData>({
    queryKey: queryKeyFn({
      path: '/years/masterConfigId/{masterConfigId}',
      operationId: 'getYearIdByMasterConfigId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetYearIdByMasterConfigId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface FetchYearIdByMasterConfigurationIdPathParams {
  masterConfigurationId: string;
}

export type FetchYearIdByMasterConfigurationIdError = Fetcher.ErrorWrapper<undefined>;

export type FetchYearIdByMasterConfigurationIdVariables = {
  pathParams: FetchYearIdByMasterConfigurationIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchFetchYearIdByMasterConfigurationId = (
  variables: FetchYearIdByMasterConfigurationIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    string,
    FetchYearIdByMasterConfigurationIdError,
    undefined,
    {},
    {},
    FetchYearIdByMasterConfigurationIdPathParams
  >({
    url: '/years/master-configurations/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useFetchYearIdByMasterConfigurationId = <TData = string>(
  variables: FetchYearIdByMasterConfigurationIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, FetchYearIdByMasterConfigurationIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<string, FetchYearIdByMasterConfigurationIdError, TData>({
    queryKey: queryKeyFn({
      path: '/years/master-configurations/{masterConfigurationId}',
      operationId: 'fetchYearIdByMasterConfigurationId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFetchYearIdByMasterConfigurationId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetYearCalculationPathParams {
  yearCalculationId: string;
}

export type GetYearCalculationError = Fetcher.ErrorWrapper<undefined>;

export type GetYearCalculationVariables = {
  pathParams: GetYearCalculationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetYearCalculation = (
  variables: GetYearCalculationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.YearCalculationDTO,
    GetYearCalculationError,
    undefined,
    {},
    {},
    GetYearCalculationPathParams
  >({ url: '/year-calculations/{yearCalculationId}', method: 'get', ...variables, signal });

export const useGetYearCalculation = <TData = Schemas.YearCalculationDTO>(
  variables: GetYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearCalculationDTO, GetYearCalculationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.YearCalculationDTO, GetYearCalculationError, TData>({
    queryKey: queryKeyFn({
      path: '/year-calculations/{yearCalculationId}',
      operationId: 'getYearCalculation',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetYearCalculation({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationByYearCalculationPathParams {
  yearCalculationId: string;
}

export interface GetMasterConfigurationByYearCalculationHeaders {
  authorization?: string;
}

export type GetMasterConfigurationByYearCalculationError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationByYearCalculationResponse =
  Schemas.MasterConfigurationEntityDTO[];

export type GetMasterConfigurationByYearCalculationVariables = {
  headers?: GetMasterConfigurationByYearCalculationHeaders;
  pathParams: GetMasterConfigurationByYearCalculationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetMasterConfigurationByYearCalculation = (
  variables: GetMasterConfigurationByYearCalculationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    undefined,
    GetMasterConfigurationByYearCalculationHeaders,
    {},
    GetMasterConfigurationByYearCalculationPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/master-configurations/entities',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetMasterConfigurationByYearCalculation = <
  TData = GetMasterConfigurationByYearCalculationResponse,
>(
  variables: GetMasterConfigurationByYearCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationByYearCalculationResponse,
      GetMasterConfigurationByYearCalculationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetMasterConfigurationByYearCalculationResponse,
    GetMasterConfigurationByYearCalculationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/year-calculations/{yearCalculationId}/master-configurations/entities',
      operationId: 'getMasterConfigurationByYearCalculation',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMasterConfigurationByYearCalculation({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetFilesMetadataPathParams {
  yearCalculationId: string;
}

export type GetFilesMetadataError = Fetcher.ErrorWrapper<undefined>;

export type GetFilesMetadataResponse = Schemas.FileMetadataDTO[];

export type GetFilesMetadataVariables = {
  pathParams: GetFilesMetadataPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetFilesMetadata = (variables: GetFilesMetadataVariables, signal?: AbortSignal) =>
  griddyFetch<
    GetFilesMetadataResponse,
    GetFilesMetadataError,
    undefined,
    {},
    {},
    GetFilesMetadataPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/files-metadata',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetFilesMetadata = <TData = GetFilesMetadataResponse>(
  variables: GetFilesMetadataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFilesMetadataResponse, GetFilesMetadataError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetFilesMetadataResponse, GetFilesMetadataError, TData>({
    queryKey: queryKeyFn({
      path: '/year-calculations/{yearCalculationId}/files-metadata',
      operationId: 'getFilesMetadata',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetFilesMetadata({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteFileMetadataPathParams {
  yearCalculationId: string;
}

export type DeleteFileMetadataError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileMetadataVariables = {
  pathParams: DeleteFileMetadataPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteFileMetadata = (
  variables: DeleteFileMetadataVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<undefined, DeleteFileMetadataError, undefined, {}, {}, DeleteFileMetadataPathParams>({
    url: '/year-calculations/{yearCalculationId}/files-metadata',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFileMetadata = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteFileMetadataError, DeleteFileMetadataVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteFileMetadataError, DeleteFileMetadataVariables>({
    mutationFn: (variables: DeleteFileMetadataVariables) =>
      fetchDeleteFileMetadata({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetFilesMetadataStatusPathParams {
  yearCalculationId: string;
}

export type GetFilesMetadataStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetFilesMetadataStatusVariables = {
  pathParams: GetFilesMetadataStatusPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetFilesMetadataStatus = (
  variables: GetFilesMetadataStatusVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.InputDataStatusDTO,
    GetFilesMetadataStatusError,
    undefined,
    {},
    {},
    GetFilesMetadataStatusPathParams
  >({
    url: '/year-calculations/{yearCalculationId}/files-metadata/status',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetFilesMetadataStatus = <TData = Schemas.InputDataStatusDTO>(
  variables: GetFilesMetadataStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.InputDataStatusDTO, GetFilesMetadataStatusError, TData>({
    queryKey: queryKeyFn({
      path: '/year-calculations/{yearCalculationId}/files-metadata/status',
      operationId: 'getFilesMetadataStatus',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFilesMetadataStatus({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetYearPathParams {
  yearId: string;
}

export type GetYearError = Fetcher.ErrorWrapper<undefined>;

export type GetYearVariables = {
  pathParams: GetYearPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetYear = (variables: GetYearVariables, signal?: AbortSignal) =>
  griddyFetch<Schemas.YearDTOV2, GetYearError, undefined, {}, {}, GetYearPathParams>({
    url: '/v2/years/{yearId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetYear = <TData = Schemas.YearDTOV2>(
  variables: GetYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.YearDTOV2, GetYearError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.YearDTOV2, GetYearError, TData>({
    queryKey: queryKeyFn({ path: '/v2/years/{yearId}', operationId: 'getYear', variables }),
    queryFn: ({ signal }) => fetchGetYear({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllTransactionForJournalV2QueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  masterConfigId: string;
}

export type GetAllTransactionForJournalV2Error = Fetcher.ErrorWrapper<undefined>;

export type GetAllTransactionForJournalV2Response = Schemas.AccountTransaction[];

export type GetAllTransactionForJournalV2Variables = {
  queryParams: GetAllTransactionForJournalV2QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllTransactionForJournalV2 = (
  variables: GetAllTransactionForJournalV2Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllTransactionForJournalV2Response,
    GetAllTransactionForJournalV2Error,
    undefined,
    {},
    GetAllTransactionForJournalV2QueryParams,
    {}
  >({ url: '/v2/journal', method: 'get', ...variables, signal });

export const useGetAllTransactionForJournalV2 = <TData = GetAllTransactionForJournalV2Response>(
  variables: GetAllTransactionForJournalV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllTransactionForJournalV2Response,
      GetAllTransactionForJournalV2Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllTransactionForJournalV2Response,
    GetAllTransactionForJournalV2Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v2/journal',
      operationId: 'getAllTransactionForJournalV2',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllTransactionForJournalV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetClientV2PathParams {
  name: string;
}

export interface GetClientV2Headers {
  authorization?: string;
}

export type GetClientV2Error = Fetcher.ErrorWrapper<undefined>;

export type GetClientV2Variables = {
  headers?: GetClientV2Headers;
  pathParams: GetClientV2PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetClientV2 = (variables: GetClientV2Variables, signal?: AbortSignal) =>
  griddyFetch<
    Schemas.ClientDTO,
    GetClientV2Error,
    undefined,
    GetClientV2Headers,
    {},
    GetClientV2PathParams
  >({ url: '/v2/clients/{name}', method: 'get', ...variables, signal });

export const useGetClientV2 = <TData = Schemas.ClientDTO>(
  variables: GetClientV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientDTO, GetClientV2Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.ClientDTO, GetClientV2Error, TData>({
    queryKey: queryKeyFn({ path: '/v2/clients/{name}', operationId: 'getClientV2', variables }),
    queryFn: ({ signal }) => fetchGetClientV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetClientById2PathParams {
  /**
   * @format int32
   */
  id: number;
}

export interface GetClientById2Headers {
  authorization?: string;
}

export type GetClientById2Error = Fetcher.ErrorWrapper<undefined>;

export type GetClientById2Variables = {
  headers?: GetClientById2Headers;
  pathParams: GetClientById2PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetClientById2 = (variables: GetClientById2Variables, signal?: AbortSignal) =>
  griddyFetch<
    Schemas.ClientDTO,
    GetClientById2Error,
    undefined,
    GetClientById2Headers,
    {},
    GetClientById2PathParams
  >({ url: '/v2/clients/id/{id}', method: 'get', ...variables, signal });

export const useGetClientById2 = <TData = Schemas.ClientDTO>(
  variables: GetClientById2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientDTO, GetClientById2Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.ClientDTO, GetClientById2Error, TData>({
    queryKey: queryKeyFn({ path: '/v2/clients/id/{id}', operationId: 'getClientById2', variables }),
    queryFn: ({ signal }) => fetchGetClientById2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllTransactionsForOneAccountV2QueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  accountId: string;
  yearId: string;
  masterConfigId: string;
}

export type GetAllTransactionsForOneAccountV2Error = Fetcher.ErrorWrapper<undefined>;

export type GetAllTransactionsForOneAccountV2Response = Schemas.AccountTransaction[];

export type GetAllTransactionsForOneAccountV2Variables = {
  queryParams: GetAllTransactionsForOneAccountV2QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllTransactionsForOneAccountV2 = (
  variables: GetAllTransactionsForOneAccountV2Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllTransactionsForOneAccountV2Response,
    GetAllTransactionsForOneAccountV2Error,
    undefined,
    {},
    GetAllTransactionsForOneAccountV2QueryParams,
    {}
  >({ url: '/v2/account', method: 'get', ...variables, signal });

export const useGetAllTransactionsForOneAccountV2 = <
  TData = GetAllTransactionsForOneAccountV2Response,
>(
  variables: GetAllTransactionsForOneAccountV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllTransactionsForOneAccountV2Response,
      GetAllTransactionsForOneAccountV2Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllTransactionsForOneAccountV2Response,
    GetAllTransactionsForOneAccountV2Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v2/account',
      operationId: 'getAllTransactionsForOneAccountV2',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllTransactionsForOneAccountV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationsPathParams {
  publicFacilityId: string;
}

export type GetMasterConfigurationsError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationsResponse = Schemas.YearExtendedDTOV2[];

export type GetMasterConfigurationsVariables = {
  pathParams: GetMasterConfigurationsPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetMasterConfigurations = (
  variables: GetMasterConfigurationsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetMasterConfigurationsResponse,
    GetMasterConfigurationsError,
    undefined,
    {},
    {},
    GetMasterConfigurationsPathParams
  >({
    url: '/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetMasterConfigurations = <TData = GetMasterConfigurationsResponse>(
  variables: GetMasterConfigurationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMasterConfigurationsResponse,
      GetMasterConfigurationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetMasterConfigurationsResponse, GetMasterConfigurationsError, TData>({
    queryKey: queryKeyFn({
      path: '/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations',
      operationId: 'getMasterConfigurations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMasterConfigurations({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllDistinctProfitCentersQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllDistinctProfitCentersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctProfitCentersResponse = string[];

export type GetAllDistinctProfitCentersVariables = {
  queryParams: GetAllDistinctProfitCentersQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllDistinctProfitCenters = (
  variables: GetAllDistinctProfitCentersVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllDistinctProfitCentersResponse,
    GetAllDistinctProfitCentersError,
    undefined,
    {},
    GetAllDistinctProfitCentersQueryParams,
    {}
  >({ url: '/profitcenters', method: 'get', ...variables, signal });

export const useGetAllDistinctProfitCenters = <TData = GetAllDistinctProfitCentersResponse>(
  variables: GetAllDistinctProfitCentersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctProfitCentersResponse,
      GetAllDistinctProfitCentersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllDistinctProfitCentersResponse,
    GetAllDistinctProfitCentersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/profitcenters',
      operationId: 'getAllDistinctProfitCenters',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllDistinctProfitCenters({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetTransactionsByFileIdQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetTransactionsByFileIdError = Fetcher.ErrorWrapper<undefined>;

export type GetTransactionsByFileIdResponse = Schemas.ProfitCenterTree[];

export type GetTransactionsByFileIdVariables = {
  queryParams: GetTransactionsByFileIdQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetTransactionsByFileId = (
  variables: GetTransactionsByFileIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetTransactionsByFileIdResponse,
    GetTransactionsByFileIdError,
    undefined,
    {},
    GetTransactionsByFileIdQueryParams,
    {}
  >({ url: '/profitcenter_trees', method: 'get', ...variables, signal });

export const useGetTransactionsByFileId = <TData = GetTransactionsByFileIdResponse>(
  variables: GetTransactionsByFileIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTransactionsByFileIdResponse,
      GetTransactionsByFileIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetTransactionsByFileIdResponse, GetTransactionsByFileIdError, TData>({
    queryKey: queryKeyFn({
      path: '/profitcenter_trees',
      operationId: 'getTransactionsByFileId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTransactionsByFileId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetTransactionsByProfitCenterIdPathParams {
  profitCenterId: string;
}

export interface GetTransactionsByProfitCenterIdQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetTransactionsByProfitCenterIdError = Fetcher.ErrorWrapper<undefined>;

export type GetTransactionsByProfitCenterIdVariables = {
  pathParams: GetTransactionsByProfitCenterIdPathParams;
  queryParams: GetTransactionsByProfitCenterIdQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetTransactionsByProfitCenterId = (
  variables: GetTransactionsByProfitCenterIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.ProfitCenterTree,
    GetTransactionsByProfitCenterIdError,
    undefined,
    {},
    GetTransactionsByProfitCenterIdQueryParams,
    GetTransactionsByProfitCenterIdPathParams
  >({ url: '/profitcenter_trees/{profitCenterId}', method: 'get', ...variables, signal });

export const useGetTransactionsByProfitCenterId = <TData = Schemas.ProfitCenterTree>(
  variables: GetTransactionsByProfitCenterIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProfitCenterTree,
      GetTransactionsByProfitCenterIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.ProfitCenterTree, GetTransactionsByProfitCenterIdError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/profitcenter_trees/{profitCenterId}',
        operationId: 'getTransactionsByProfitCenterId',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetTransactionsByProfitCenterId({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    },
  );
};

export interface GetByFileIdsFromGridFSPathParams {
  /**
   * @pattern ^[0-9a-zA-Z]{25}$
   */
  ids: string[];
}

export interface GetByFileIdsFromGridFSHeaders {
  authorization?: string;
}

export type GetByFileIdsFromGridFSError = Fetcher.ErrorWrapper<undefined>;

export type GetByFileIdsFromGridFSResponse = Schemas.JsonNode[];

export type GetByFileIdsFromGridFSVariables = {
  headers?: GetByFileIdsFromGridFSHeaders;
  pathParams: GetByFileIdsFromGridFSPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetByFileIdsFromGridFS = (
  variables: GetByFileIdsFromGridFSVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetByFileIdsFromGridFSResponse,
    GetByFileIdsFromGridFSError,
    undefined,
    GetByFileIdsFromGridFSHeaders,
    {},
    GetByFileIdsFromGridFSPathParams
  >({ url: '/objects/{ids}', method: 'get', ...variables, signal });

export const useGetByFileIdsFromGridFS = <TData = GetByFileIdsFromGridFSResponse>(
  variables: GetByFileIdsFromGridFSVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetByFileIdsFromGridFSResponse, GetByFileIdsFromGridFSError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetByFileIdsFromGridFSResponse, GetByFileIdsFromGridFSError, TData>({
    queryKey: queryKeyFn({
      path: '/objects/{ids}',
      operationId: 'getByFileIdsFromGridFS',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetByFileIdsFromGridFS({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetByFileIdsFromGridFSNoAuthQueryParams {
  /**
   * @pattern ^[0-9a-zA-Z]{25}$
   */
  id: string;
}

export type GetByFileIdsFromGridFSNoAuthError = Fetcher.ErrorWrapper<undefined>;

export type GetByFileIdsFromGridFSNoAuthResponse = Schemas.JsonNode[];

export type GetByFileIdsFromGridFSNoAuthVariables = {
  queryParams: GetByFileIdsFromGridFSNoAuthQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetByFileIdsFromGridFSNoAuth = (
  variables: GetByFileIdsFromGridFSNoAuthVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetByFileIdsFromGridFSNoAuthResponse,
    GetByFileIdsFromGridFSNoAuthError,
    undefined,
    {},
    GetByFileIdsFromGridFSNoAuthQueryParams,
    {}
  >({ url: '/object', method: 'get', ...variables, signal });

export const useGetByFileIdsFromGridFSNoAuth = <TData = GetByFileIdsFromGridFSNoAuthResponse>(
  variables: GetByFileIdsFromGridFSNoAuthVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetByFileIdsFromGridFSNoAuthResponse,
      GetByFileIdsFromGridFSNoAuthError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetByFileIdsFromGridFSNoAuthResponse,
    GetByFileIdsFromGridFSNoAuthError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/object',
      operationId: 'getByFileIdsFromGridFSNoAuth',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetByFileIdsFromGridFSNoAuth({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MigrateProfitCenterTreeToFlatProfitCenterListError = Fetcher.ErrorWrapper<undefined>;

export type MigrateProfitCenterTreeToFlatProfitCenterListVariables =
  GriddyContext['fetcherOptions'];

export const fetchMigrateProfitCenterTreeToFlatProfitCenterList = (
  variables: MigrateProfitCenterTreeToFlatProfitCenterListVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<string, MigrateProfitCenterTreeToFlatProfitCenterListError, undefined, {}, {}, {}>({
    url: '/migration/profitCenterMapping/migrateProfitCenterTreeToFlatProfitCenterList',
    method: 'get',
    ...variables,
    signal,
  });

export const useMigrateProfitCenterTreeToFlatProfitCenterList = <TData = string>(
  variables: MigrateProfitCenterTreeToFlatProfitCenterListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, MigrateProfitCenterTreeToFlatProfitCenterListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<string, MigrateProfitCenterTreeToFlatProfitCenterListError, TData>({
    queryKey: queryKeyFn({
      path: '/migration/profitCenterMapping/migrateProfitCenterTreeToFlatProfitCenterList',
      operationId: 'migrateProfitCenterTreeToFlatProfitCenterList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMigrateProfitCenterTreeToFlatProfitCenterList(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type MigrateMasterConfigurationsFromGridFsToMongoError = Fetcher.ErrorWrapper<undefined>;

export type MigrateMasterConfigurationsFromGridFsToMongoVariables = GriddyContext['fetcherOptions'];

export const fetchMigrateMasterConfigurationsFromGridFsToMongo = (
  variables: MigrateMasterConfigurationsFromGridFsToMongoVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<string, MigrateMasterConfigurationsFromGridFsToMongoError, undefined, {}, {}, {}>({
    url: '/migration/migrateMasterConfigurationsFromGridFsToMongo',
    method: 'get',
    ...variables,
    signal,
  });

export const useMigrateMasterConfigurationsFromGridFsToMongo = <TData = string>(
  variables: MigrateMasterConfigurationsFromGridFsToMongoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, MigrateMasterConfigurationsFromGridFsToMongoError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<string, MigrateMasterConfigurationsFromGridFsToMongoError, TData>({
    queryKey: queryKeyFn({
      path: '/migration/migrateMasterConfigurationsFromGridFsToMongo',
      operationId: 'migrateMasterConfigurationsFromGridFsToMongo',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMigrateMasterConfigurationsFromGridFsToMongo(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export interface MigrateSingleMasterConfigurationFromGridFsToMongoPathParams {
  /**
   * @pattern ^[0-9a-zA-Z]{25}$
   */
  masterConfigurationId: string;
}

export type MigrateSingleMasterConfigurationFromGridFsToMongoError =
  Fetcher.ErrorWrapper<undefined>;

export type MigrateSingleMasterConfigurationFromGridFsToMongoVariables = {
  pathParams: MigrateSingleMasterConfigurationFromGridFsToMongoPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchMigrateSingleMasterConfigurationFromGridFsToMongo = (
  variables: MigrateSingleMasterConfigurationFromGridFsToMongoVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    MigrateSingleMasterConfigurationFromGridFsToMongoError,
    undefined,
    {},
    {},
    MigrateSingleMasterConfigurationFromGridFsToMongoPathParams
  >({
    url: '/migration/migrateMasterConfigurationsFromGridFsToMongo/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useMigrateSingleMasterConfigurationFromGridFsToMongo = <TData = undefined>(
  variables: MigrateSingleMasterConfigurationFromGridFsToMongoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      MigrateSingleMasterConfigurationFromGridFsToMongoError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    undefined,
    MigrateSingleMasterConfigurationFromGridFsToMongoError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/migration/migrateMasterConfigurationsFromGridFsToMongo/{masterConfigurationId}',
      operationId: 'migrateSingleMasterConfigurationFromGridFsToMongo',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMigrateSingleMasterConfigurationFromGridFsToMongo(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export interface MigrateCostUnitForMasterConfigurationPathParams {
  /**
   * @pattern ^[0-9a-zA-Z]{25}$
   */
  masterConfigurationId: string;
}

export type MigrateCostUnitForMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type MigrateCostUnitForMasterConfigurationVariables = {
  pathParams: MigrateCostUnitForMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchMigrateCostUnitForMasterConfiguration = (
  variables: MigrateCostUnitForMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    MigrateCostUnitForMasterConfigurationError,
    undefined,
    {},
    {},
    MigrateCostUnitForMasterConfigurationPathParams
  >({
    url: '/migration/migrateCostUnitForMasterConfiguration/{masterConfigurationId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useMigrateCostUnitForMasterConfiguration = <TData = undefined>(
  variables: MigrateCostUnitForMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MigrateCostUnitForMasterConfigurationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<undefined, MigrateCostUnitForMasterConfigurationError, TData>({
    queryKey: queryKeyFn({
      path: '/migration/migrateCostUnitForMasterConfiguration/{masterConfigurationId}',
      operationId: 'migrateCostUnitForMasterConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMigrateCostUnitForMasterConfiguration({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MigrateInterestTableReportError = Fetcher.ErrorWrapper<undefined>;

export type MigrateInterestTableReportVariables = GriddyContext['fetcherOptions'];

export const fetchMigrateInterestTableReport = (
  variables: MigrateInterestTableReportVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResultWithErrorCount,
    MigrateInterestTableReportError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/interest-table-report', method: 'get', ...variables, signal });

export const useMigrateInterestTableReport = <TData = Schemas.MigrationResultWithErrorCount>(
  variables: MigrateInterestTableReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MigrationResultWithErrorCount,
      MigrateInterestTableReportError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.MigrationResultWithErrorCount,
    MigrateInterestTableReportError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/migration/interest-table-report',
      operationId: 'migrateInterestTableReport',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMigrateInterestTableReport({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MigrateCostUnitReportError = Fetcher.ErrorWrapper<undefined>;

export type MigrateCostUnitReportVariables = GriddyContext['fetcherOptions'];

export const fetchMigrateCostUnitReport = (
  variables: MigrateCostUnitReportVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResultWithErrorCount,
    MigrateCostUnitReportError,
    undefined,
    {},
    {},
    {}
  >({ url: '/migration/cost-unit-report', method: 'get', ...variables, signal });

export const useMigrateCostUnitReport = <TData = Schemas.MigrationResultWithErrorCount>(
  variables: MigrateCostUnitReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MigrationResultWithErrorCount,
      MigrateCostUnitReportError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.MigrationResultWithErrorCount,
    MigrateCostUnitReportError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/migration/cost-unit-report',
      operationId: 'migrateCostUnitReport',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMigrateCostUnitReport({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DetectMasterConfigurationWithIncorrectCostCenterRanksError =
  Fetcher.ErrorWrapper<undefined>;

export type DetectMasterConfigurationWithIncorrectCostCenterRanksVariables =
  GriddyContext['fetcherOptions'];

export const fetchDetectMasterConfigurationWithIncorrectCostCenterRanks = (
  variables: DetectMasterConfigurationWithIncorrectCostCenterRanksVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MigrationResultWithErrorCount,
    DetectMasterConfigurationWithIncorrectCostCenterRanksError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/migration/cost-center-rank/detect-incorrect-ranks',
    method: 'get',
    ...variables,
    signal,
  });

export const useDetectMasterConfigurationWithIncorrectCostCenterRanks = <
  TData = Schemas.MigrationResultWithErrorCount,
>(
  variables: DetectMasterConfigurationWithIncorrectCostCenterRanksVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MigrationResultWithErrorCount,
      DetectMasterConfigurationWithIncorrectCostCenterRanksError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.MigrationResultWithErrorCount,
    DetectMasterConfigurationWithIncorrectCostCenterRanksError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/migration/cost-center-rank/detect-incorrect-ranks',
      operationId: 'detectMasterConfigurationWithIncorrectCostCenterRanks',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDetectMasterConfigurationWithIncorrectCostCenterRanks(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export interface GetMasterConfigurationByIdPathParams {
  id: string;
}

export type GetMasterConfigurationByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetMasterConfigurationByIdVariables = {
  pathParams: GetMasterConfigurationByIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetMasterConfigurationById = (
  variables: GetMasterConfigurationByIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.MasterConfiguration,
    GetMasterConfigurationByIdError,
    undefined,
    {},
    {},
    GetMasterConfigurationByIdPathParams
  >({ url: '/masterConfiguration/{id}', method: 'get', ...variables, signal });

export const useGetMasterConfigurationById = <TData = Schemas.MasterConfiguration>(
  variables: GetMasterConfigurationByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.MasterConfiguration, GetMasterConfigurationByIdError, TData>({
    queryKey: queryKeyFn({
      path: '/masterConfiguration/{id}',
      operationId: 'getMasterConfigurationById',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMasterConfigurationById({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteMasterConfigurationByIdPathParams {
  id: string;
}

export interface DeleteMasterConfigurationByIdHeaders {
  authorization?: string;
}

export type DeleteMasterConfigurationByIdError = Fetcher.ErrorWrapper<undefined>;

export type DeleteMasterConfigurationByIdVariables = {
  headers?: DeleteMasterConfigurationByIdHeaders;
  pathParams: DeleteMasterConfigurationByIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteMasterConfigurationById = (
  variables: DeleteMasterConfigurationByIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteMasterConfigurationByIdError,
    undefined,
    DeleteMasterConfigurationByIdHeaders,
    {},
    DeleteMasterConfigurationByIdPathParams
  >({ url: '/masterConfiguration/{id}', method: 'delete', ...variables, signal });

export const useDeleteMasterConfigurationById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteMasterConfigurationByIdError,
      DeleteMasterConfigurationByIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeleteMasterConfigurationByIdError,
    DeleteMasterConfigurationByIdVariables
  >({
    mutationFn: (variables: DeleteMasterConfigurationByIdVariables) =>
      fetchDeleteMasterConfigurationById({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetConfigStatusPathParams {
  masterConfigurationId: string;
}

export type GetConfigStatusError = Fetcher.ErrorWrapper<undefined>;

export type GetConfigStatusVariables = {
  pathParams: GetConfigStatusPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetConfigStatus = (variables: GetConfigStatusVariables, signal?: AbortSignal) =>
  griddyFetch<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatusError,
    undefined,
    {},
    {},
    GetConfigStatusPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data/status',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetConfigStatus = <
  TData = 'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
>(
  variables: GetConfigStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
      GetConfigStatusError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatusError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/technical-data/status',
      operationId: 'getConfigStatus',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetConfigStatus({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetTechnicalDataGroupsByMasterConfigurationIdPathParams {
  masterConfigurationId: string;
}

export type GetTechnicalDataGroupsByMasterConfigurationIdError = Fetcher.ErrorWrapper<undefined>;

export type GetTechnicalDataGroupsByMasterConfigurationIdResponse = Schemas.TechnicalDataGroupDTO[];

export type GetTechnicalDataGroupsByMasterConfigurationIdVariables = {
  pathParams: GetTechnicalDataGroupsByMasterConfigurationIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetTechnicalDataGroupsByMasterConfigurationId = (
  variables: GetTechnicalDataGroupsByMasterConfigurationIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetTechnicalDataGroupsByMasterConfigurationIdResponse,
    GetTechnicalDataGroupsByMasterConfigurationIdError,
    undefined,
    {},
    {},
    GetTechnicalDataGroupsByMasterConfigurationIdPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data/groups',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTechnicalDataGroupsByMasterConfigurationId = <
  TData = GetTechnicalDataGroupsByMasterConfigurationIdResponse,
>(
  variables: GetTechnicalDataGroupsByMasterConfigurationIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTechnicalDataGroupsByMasterConfigurationIdResponse,
      GetTechnicalDataGroupsByMasterConfigurationIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetTechnicalDataGroupsByMasterConfigurationIdResponse,
    GetTechnicalDataGroupsByMasterConfigurationIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/technical-data/groups',
      operationId: 'getTechnicalDataGroupsByMasterConfigurationId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTechnicalDataGroupsByMasterConfigurationId(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export interface GetTechnicalDataCharacteristicsByMasterConfigurationIdPathParams {
  masterConfigurationId: string;
}

export type GetTechnicalDataCharacteristicsByMasterConfigurationIdError =
  Fetcher.ErrorWrapper<undefined>;

export type GetTechnicalDataCharacteristicsByMasterConfigurationIdResponse =
  Schemas.TechnicalDataCharacteristicDTO[];

export type GetTechnicalDataCharacteristicsByMasterConfigurationIdVariables = {
  pathParams: GetTechnicalDataCharacteristicsByMasterConfigurationIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetTechnicalDataCharacteristicsByMasterConfigurationId = (
  variables: GetTechnicalDataCharacteristicsByMasterConfigurationIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetTechnicalDataCharacteristicsByMasterConfigurationIdResponse,
    GetTechnicalDataCharacteristicsByMasterConfigurationIdError,
    undefined,
    {},
    {},
    GetTechnicalDataCharacteristicsByMasterConfigurationIdPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data/characteristics',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTechnicalDataCharacteristicsByMasterConfigurationId = <
  TData = GetTechnicalDataCharacteristicsByMasterConfigurationIdResponse,
>(
  variables: GetTechnicalDataCharacteristicsByMasterConfigurationIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTechnicalDataCharacteristicsByMasterConfigurationIdResponse,
      GetTechnicalDataCharacteristicsByMasterConfigurationIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetTechnicalDataCharacteristicsByMasterConfigurationIdResponse,
    GetTechnicalDataCharacteristicsByMasterConfigurationIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/technical-data/characteristics',
      operationId: 'getTechnicalDataCharacteristicsByMasterConfigurationId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTechnicalDataCharacteristicsByMasterConfigurationId(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export interface IsMasterConfigurationForPreCalculationPathParams {
  masterConfigurationId: string;
}

export type IsMasterConfigurationForPreCalculationError = Fetcher.ErrorWrapper<undefined>;

export type IsMasterConfigurationForPreCalculationVariables = {
  pathParams: IsMasterConfigurationForPreCalculationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchIsMasterConfigurationForPreCalculation = (
  variables: IsMasterConfigurationForPreCalculationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    boolean,
    IsMasterConfigurationForPreCalculationError,
    undefined,
    {},
    {},
    IsMasterConfigurationForPreCalculationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/pre-calculation',
    method: 'get',
    ...variables,
    signal,
  });

export const useIsMasterConfigurationForPreCalculation = <TData = boolean>(
  variables: IsMasterConfigurationForPreCalculationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<boolean, IsMasterConfigurationForPreCalculationError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<boolean, IsMasterConfigurationForPreCalculationError, TData>({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/pre-calculation',
      operationId: 'isMasterConfigurationForPreCalculation',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchIsMasterConfigurationForPreCalculation({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetPlannedCostRevenueConfigPathParams {
  masterConfigurationId: string;
}

export type GetPlannedCostRevenueConfigError = Fetcher.ErrorWrapper<undefined>;

export type GetPlannedCostRevenueConfigVariables = {
  pathParams: GetPlannedCostRevenueConfigPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetPlannedCostRevenueConfig = (
  variables: GetPlannedCostRevenueConfigVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    undefined,
    {},
    {},
    GetPlannedCostRevenueConfigPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPlannedCostRevenueConfig = <TData = Schemas.PlannedCostRevenueConfigDTO>(
  variables: GetPlannedCostRevenueConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenueConfigDTO,
      GetPlannedCostRevenueConfigError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.PlannedCostRevenueConfigDTO,
    GetPlannedCostRevenueConfigError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue',
      operationId: 'getPlannedCostRevenueConfig',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlannedCostRevenueConfig({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface DeletePlannedCostRevenueForMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type DeletePlannedCostRevenueForMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type DeletePlannedCostRevenueForMasterConfigurationVariables = {
  pathParams: DeletePlannedCostRevenueForMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeletePlannedCostRevenueForMasterConfiguration = (
  variables: DeletePlannedCostRevenueForMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeletePlannedCostRevenueForMasterConfigurationError,
    undefined,
    {},
    {},
    DeletePlannedCostRevenueForMasterConfigurationPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePlannedCostRevenueForMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePlannedCostRevenueForMasterConfigurationError,
      DeletePlannedCostRevenueForMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeletePlannedCostRevenueForMasterConfigurationError,
    DeletePlannedCostRevenueForMasterConfigurationVariables
  >({
    mutationFn: (variables: DeletePlannedCostRevenueForMasterConfigurationVariables) =>
      fetchDeletePlannedCostRevenueForMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetConfigStatus1PathParams {
  masterConfigurationId: string;
}

export type GetConfigStatus1Error = Fetcher.ErrorWrapper<undefined>;

export type GetConfigStatus1Variables = {
  pathParams: GetConfigStatus1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetConfigStatus1 = (variables: GetConfigStatus1Variables, signal?: AbortSignal) =>
  griddyFetch<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatus1Error,
    undefined,
    {},
    {},
    GetConfigStatus1PathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/status',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetConfigStatus1 = <
  TData = 'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
>(
  variables: GetConfigStatus1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
      GetConfigStatus1Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatus1Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/status',
      operationId: 'getConfigStatus1',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetConfigStatus1({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetPlannedCostRevenuePreselectedValuesPathParams {
  masterConfigurationId: string;
}

export type GetPlannedCostRevenuePreselectedValuesError = Fetcher.ErrorWrapper<undefined>;

export type GetPlannedCostRevenuePreselectedValuesVariables = {
  pathParams: GetPlannedCostRevenuePreselectedValuesPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetPlannedCostRevenuePreselectedValues = (
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    undefined,
    {},
    {},
    GetPlannedCostRevenuePreselectedValuesPathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPlannedCostRevenuePreselectedValues = <
  TData = Schemas.PlannedCostRevenuePreselectedValuesDTO,
>(
  variables: GetPlannedCostRevenuePreselectedValuesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PlannedCostRevenuePreselectedValuesDTO,
      GetPlannedCostRevenuePreselectedValuesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.PlannedCostRevenuePreselectedValuesDTO,
    GetPlannedCostRevenuePreselectedValuesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values',
      operationId: 'getPlannedCostRevenuePreselectedValues',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPlannedCostRevenuePreselectedValues({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetConfigStatus2PathParams {
  masterConfigurationId: string;
}

export type GetConfigStatus2Error = Fetcher.ErrorWrapper<undefined>;

export type GetConfigStatus2Variables = {
  pathParams: GetConfigStatus2PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetConfigStatus2 = (variables: GetConfigStatus2Variables, signal?: AbortSignal) =>
  griddyFetch<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatus2Error,
    undefined,
    {},
    {},
    GetConfigStatus2PathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/cost-centers/status',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetConfigStatus2 = <
  TData = 'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
>(
  variables: GetConfigStatus2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
      GetConfigStatus2Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatus2Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/cost-centers/status',
      operationId: 'getConfigStatus2',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetConfigStatus2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetConfigStatus3PathParams {
  masterConfigurationId: string;
}

export type GetConfigStatus3Error = Fetcher.ErrorWrapper<undefined>;

export type GetConfigStatus3Variables = {
  pathParams: GetConfigStatus3PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetConfigStatus3 = (variables: GetConfigStatus3Variables, signal?: AbortSignal) =>
  griddyFetch<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatus3Error,
    undefined,
    {},
    {},
    GetConfigStatus3PathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/status',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetConfigStatus3 = <
  TData = 'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
>(
  variables: GetConfigStatus3Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
      GetConfigStatus3Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    'FULLY_CONFIGURED' | 'PARTIALLY_CONFIGURED' | 'NOTHING_CONFIGURED',
    GetConfigStatus3Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/{masterConfigurationId}/account-ranges/status',
      operationId: 'getConfigStatus3',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetConfigStatus3({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAllMasterConfigurationEntityByYearError = Fetcher.ErrorWrapper<undefined>;

export type GetAllMasterConfigurationEntityByYearResponse = Record<
  string,
  Schemas.MasterConfigurationEntityDTO[]
>;

export type GetAllMasterConfigurationEntityByYearVariables = GriddyContext['fetcherOptions'];

export const fetchGetAllMasterConfigurationEntityByYear = (
  variables: GetAllMasterConfigurationEntityByYearVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllMasterConfigurationEntityByYearResponse,
    GetAllMasterConfigurationEntityByYearError,
    undefined,
    {},
    {},
    {}
  >({ url: '/master-configurations/entities-by-year', method: 'get', ...variables, signal });

export const useGetAllMasterConfigurationEntityByYear = <
  TData = GetAllMasterConfigurationEntityByYearResponse,
>(
  variables: GetAllMasterConfigurationEntityByYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllMasterConfigurationEntityByYearResponse,
      GetAllMasterConfigurationEntityByYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllMasterConfigurationEntityByYearResponse,
    GetAllMasterConfigurationEntityByYearError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations/entities-by-year',
      operationId: 'getAllMasterConfigurationEntityByYear',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllMasterConfigurationEntityByYear({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAllMasterConfigurationsByYearError = Fetcher.ErrorWrapper<undefined>;

export type GetAllMasterConfigurationsByYearResponse = Record<
  string,
  Schemas.MasterConfiguration[]
>;

export type GetAllMasterConfigurationsByYearVariables = GriddyContext['fetcherOptions'];

export const fetchGetAllMasterConfigurationsByYear = (
  variables: GetAllMasterConfigurationsByYearVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    undefined,
    {},
    {},
    {}
  >({ url: '/master-configurations-by-year', method: 'get', ...variables, signal });

export const useGetAllMasterConfigurationsByYear = <
  TData = GetAllMasterConfigurationsByYearResponse,
>(
  variables: GetAllMasterConfigurationsByYearVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllMasterConfigurationsByYearResponse,
      GetAllMasterConfigurationsByYearError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllMasterConfigurationsByYearResponse,
    GetAllMasterConfigurationsByYearError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configurations-by-year',
      operationId: 'getAllMasterConfigurationsByYear',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllMasterConfigurationsByYear({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAccountsByMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export type GetAccountsByMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetAccountsByMasterConfigurationResponse = Schemas.AccountDTO[];

export type GetAccountsByMasterConfigurationVariables = {
  pathParams: GetAccountsByMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAccountsByMasterConfiguration = (
  variables: GetAccountsByMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    undefined,
    {},
    {},
    GetAccountsByMasterConfigurationPathParams
  >({
    url: '/master-configs/{masterConfigurationId}/accounts',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAccountsByMasterConfiguration = <
  TData = GetAccountsByMasterConfigurationResponse,
>(
  variables: GetAccountsByMasterConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAccountsByMasterConfigurationResponse,
      GetAccountsByMasterConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAccountsByMasterConfigurationResponse,
    GetAccountsByMasterConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/master-configs/{masterConfigurationId}/accounts',
      operationId: 'getAccountsByMasterConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAccountsByMasterConfiguration({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllLedgerAccountWithPagerQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  /**
   * @format int32
   */
  page: number;
  /**
   * @format int32
   */
  pageSize: number;
}

export type GetAllLedgerAccountWithPagerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLedgerAccountWithPagerVariables = {
  queryParams: GetAllLedgerAccountWithPagerQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllLedgerAccountWithPager = (
  variables: GetAllLedgerAccountWithPagerVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PageLedgerAccount,
    GetAllLedgerAccountWithPagerError,
    undefined,
    {},
    GetAllLedgerAccountWithPagerQueryParams,
    {}
  >({ url: '/ledgeraccounts/page', method: 'get', ...variables, signal });

export const useGetAllLedgerAccountWithPager = <TData = Schemas.PageLedgerAccount>(
  variables: GetAllLedgerAccountWithPagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageLedgerAccount, GetAllLedgerAccountWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.PageLedgerAccount, GetAllLedgerAccountWithPagerError, TData>({
    queryKey: queryKeyFn({
      path: '/ledgeraccounts/page',
      operationId: 'getAllLedgerAccountWithPager',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllLedgerAccountWithPager({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAccountsToCostCenterNamesQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  accountIds: string[];
}

export type GetAccountsToCostCenterNamesError = Fetcher.ErrorWrapper<undefined>;

export type GetAccountsToCostCenterNamesResponse = Record<string, string[]>;

export type GetAccountsToCostCenterNamesVariables = {
  queryParams: GetAccountsToCostCenterNamesQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAccountsToCostCenterNames = (
  variables: GetAccountsToCostCenterNamesVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAccountsToCostCenterNamesResponse,
    GetAccountsToCostCenterNamesError,
    undefined,
    {},
    GetAccountsToCostCenterNamesQueryParams,
    {}
  >({ url: '/ledgeraccounts/accounts_costcenter', method: 'get', ...variables, signal });

export const useGetAccountsToCostCenterNames = <TData = GetAccountsToCostCenterNamesResponse>(
  variables: GetAccountsToCostCenterNamesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAccountsToCostCenterNamesResponse,
      GetAccountsToCostCenterNamesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAccountsToCostCenterNamesResponse,
    GetAccountsToCostCenterNamesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ledgeraccounts/accounts_costcenter',
      operationId: 'getAccountsToCostCenterNames',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAccountsToCostCenterNames({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllDistinctAccountIdsQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllDistinctAccountIdsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctAccountIdsResponse = string[];

export type GetAllDistinctAccountIdsVariables = {
  queryParams: GetAllDistinctAccountIdsQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllDistinctAccountIds = (
  variables: GetAllDistinctAccountIdsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllDistinctAccountIdsResponse,
    GetAllDistinctAccountIdsError,
    undefined,
    {},
    GetAllDistinctAccountIdsQueryParams,
    {}
  >({ url: '/ledgeraccounts/accountids', method: 'get', ...variables, signal });

export const useGetAllDistinctAccountIds = <TData = GetAllDistinctAccountIdsResponse>(
  variables: GetAllDistinctAccountIdsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAccountIdsResponse,
      GetAllDistinctAccountIdsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllDistinctAccountIdsResponse,
    GetAllDistinctAccountIdsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ledgeraccounts/accountids',
      operationId: 'getAllDistinctAccountIds',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllDistinctAccountIds({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllAcctIdToCostCenterNameMapQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllAcctIdToCostCenterNameMapError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAcctIdToCostCenterNameMapResponse = Record<string, string[]>;

export type GetAllAcctIdToCostCenterNameMapVariables = {
  queryParams: GetAllAcctIdToCostCenterNameMapQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllAcctIdToCostCenterNameMap = (
  variables: GetAllAcctIdToCostCenterNameMapVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllAcctIdToCostCenterNameMapResponse,
    GetAllAcctIdToCostCenterNameMapError,
    undefined,
    {},
    GetAllAcctIdToCostCenterNameMapQueryParams,
    {}
  >({ url: '/ledgeraccounts/account_costcenter_map', method: 'get', ...variables, signal });

export const useGetAllAcctIdToCostCenterNameMap = <TData = GetAllAcctIdToCostCenterNameMapResponse>(
  variables: GetAllAcctIdToCostCenterNameMapVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllAcctIdToCostCenterNameMapResponse,
      GetAllAcctIdToCostCenterNameMapError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllAcctIdToCostCenterNameMapResponse,
    GetAllAcctIdToCostCenterNameMapError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ledgeraccounts/account_costcenter_map',
      operationId: 'getAllAcctIdToCostCenterNameMap',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllAcctIdToCostCenterNameMap({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAcctIdToCostCenterNameQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  accountId: string;
}

export type GetAcctIdToCostCenterNameError = Fetcher.ErrorWrapper<undefined>;

export type GetAcctIdToCostCenterNameResponse = string[];

export type GetAcctIdToCostCenterNameVariables = {
  queryParams: GetAcctIdToCostCenterNameQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAcctIdToCostCenterName = (
  variables: GetAcctIdToCostCenterNameVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAcctIdToCostCenterNameResponse,
    GetAcctIdToCostCenterNameError,
    undefined,
    {},
    GetAcctIdToCostCenterNameQueryParams,
    {}
  >({ url: '/ledgeraccounts/account_costcenter', method: 'get', ...variables, signal });

export const useGetAcctIdToCostCenterName = <TData = GetAcctIdToCostCenterNameResponse>(
  variables: GetAcctIdToCostCenterNameVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAcctIdToCostCenterNameResponse,
      GetAcctIdToCostCenterNameError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAcctIdToCostCenterNameResponse,
    GetAcctIdToCostCenterNameError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ledgeraccounts/account_costcenter',
      operationId: 'getAcctIdToCostCenterName',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAcctIdToCostCenterName({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllLedgerAccountsForOneAccountQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  accountId: string;
  yearId: string;
}

export type GetAllLedgerAccountsForOneAccountError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLedgerAccountsForOneAccountResponse = Schemas.LedgerAccount[];

export type GetAllLedgerAccountsForOneAccountVariables = {
  queryParams: GetAllLedgerAccountsForOneAccountQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllLedgerAccountsForOneAccount = (
  variables: GetAllLedgerAccountsForOneAccountVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllLedgerAccountsForOneAccountResponse,
    GetAllLedgerAccountsForOneAccountError,
    undefined,
    {},
    GetAllLedgerAccountsForOneAccountQueryParams,
    {}
  >({ url: '/ledgeraccounts/account', method: 'get', ...variables, signal });

export const useGetAllLedgerAccountsForOneAccount = <
  TData = GetAllLedgerAccountsForOneAccountResponse,
>(
  variables: GetAllLedgerAccountsForOneAccountVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllLedgerAccountsForOneAccountResponse,
      GetAllLedgerAccountsForOneAccountError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllLedgerAccountsForOneAccountResponse,
    GetAllLedgerAccountsForOneAccountError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ledgeraccounts/account',
      operationId: 'getAllLedgerAccountsForOneAccount',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllLedgerAccountsForOneAccount({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllLabelsByTypeQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  labelType:
    | 'PROFITCENTER_BEREICH'
    | 'PROFITCENTER'
    | 'KST_BEREICH'
    | 'KST'
    | 'AUFTRAG'
    | 'PROJEKT'
    | 'ANLAGEN_KLASSE'
    | 'BEWERTUNGSBEREICH';
}

export type GetAllLabelsByTypeError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLabelsByTypeResponse = Schemas.LabelRecord[];

export type GetAllLabelsByTypeVariables = {
  queryParams: GetAllLabelsByTypeQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllLabelsByType = (
  variables: GetAllLabelsByTypeVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllLabelsByTypeResponse,
    GetAllLabelsByTypeError,
    undefined,
    {},
    GetAllLabelsByTypeQueryParams,
    {}
  >({ url: '/labels/type', method: 'get', ...variables, signal });

export const useGetAllLabelsByType = <TData = GetAllLabelsByTypeResponse>(
  variables: GetAllLabelsByTypeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllLabelsByTypeResponse, GetAllLabelsByTypeError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllLabelsByTypeResponse, GetAllLabelsByTypeError, TData>({
    queryKey: queryKeyFn({ path: '/labels/type', operationId: 'getAllLabelsByType', variables }),
    queryFn: ({ signal }) => fetchGetAllLabelsByType({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllLabelsWithPagerQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  /**
   * @format int32
   */
  page: number;
  /**
   * @format int32
   */
  pageSize: number;
}

export type GetAllLabelsWithPagerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLabelsWithPagerVariables = {
  queryParams: GetAllLabelsWithPagerQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllLabelsWithPager = (
  variables: GetAllLabelsWithPagerVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PageLabelRecord,
    GetAllLabelsWithPagerError,
    undefined,
    {},
    GetAllLabelsWithPagerQueryParams,
    {}
  >({ url: '/labels/page', method: 'get', ...variables, signal });

export const useGetAllLabelsWithPager = <TData = Schemas.PageLabelRecord>(
  variables: GetAllLabelsWithPagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PageLabelRecord, GetAllLabelsWithPagerError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.PageLabelRecord, GetAllLabelsWithPagerError, TData>({
    queryKey: queryKeyFn({ path: '/labels/page', operationId: 'getAllLabelsWithPager', variables }),
    queryFn: ({ signal }) =>
      fetchGetAllLabelsWithPager({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllTransactionForJournalV21QueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  /**
   * @format int32
   */
  page: number;
  /**
   * @format int32
   */
  pageSize: number;
}

export type GetAllTransactionForJournalV21Error = Fetcher.ErrorWrapper<undefined>;

export type GetAllTransactionForJournalV21Variables = {
  queryParams: GetAllTransactionForJournalV21QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllTransactionForJournalV21 = (
  variables: GetAllTransactionForJournalV21Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PageAccountTransaction,
    GetAllTransactionForJournalV21Error,
    undefined,
    {},
    GetAllTransactionForJournalV21QueryParams,
    {}
  >({ url: '/journal/page', method: 'get', ...variables, signal });

export const useGetAllTransactionForJournalV21 = <TData = Schemas.PageAccountTransaction>(
  variables: GetAllTransactionForJournalV21Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PageAccountTransaction,
      GetAllTransactionForJournalV21Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.PageAccountTransaction,
    GetAllTransactionForJournalV21Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/journal/page',
      operationId: 'getAllTransactionForJournalV21',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllTransactionForJournalV21({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllDistinctJobIdsQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllDistinctJobIdsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctJobIdsResponse = string[];

export type GetAllDistinctJobIdsVariables = {
  queryParams: GetAllDistinctJobIdsQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllDistinctJobIds = (
  variables: GetAllDistinctJobIdsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllDistinctJobIdsResponse,
    GetAllDistinctJobIdsError,
    undefined,
    {},
    GetAllDistinctJobIdsQueryParams,
    {}
  >({ url: '/jobids', method: 'get', ...variables, signal });

export const useGetAllDistinctJobIds = <TData = GetAllDistinctJobIdsResponse>(
  variables: GetAllDistinctJobIdsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetAllDistinctJobIdsResponse, GetAllDistinctJobIdsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<GetAllDistinctJobIdsResponse, GetAllDistinctJobIdsError, TData>({
    queryKey: queryKeyFn({ path: '/jobids', operationId: 'getAllDistinctJobIds', variables }),
    queryFn: ({ signal }) => fetchGetAllDistinctJobIds({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type HealthcheckError = Fetcher.ErrorWrapper<undefined>;

export type HealthcheckVariables = GriddyContext['fetcherOptions'];

export const fetchHealthcheck = (variables: HealthcheckVariables, signal?: AbortSignal) =>
  griddyFetch<string, HealthcheckError, undefined, {}, {}, {}>({
    url: '/healthcheck',
    method: 'get',
    ...variables,
    signal,
  });

export const useHealthcheck = <TData = string>(
  variables: HealthcheckVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, HealthcheckError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<string, HealthcheckError, TData>({
    queryKey: queryKeyFn({ path: '/healthcheck', operationId: 'healthcheck', variables }),
    queryFn: ({ signal }) => fetchHealthcheck({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetFlattenedProfitCenterTreesQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetFlattenedProfitCenterTreesError = Fetcher.ErrorWrapper<undefined>;

export type GetFlattenedProfitCenterTreesResponse = Record<string, Schemas.AcctTrxSelectCriteria>;

export type GetFlattenedProfitCenterTreesVariables = {
  queryParams: GetFlattenedProfitCenterTreesQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetFlattenedProfitCenterTrees = (
  variables: GetFlattenedProfitCenterTreesVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetFlattenedProfitCenterTreesResponse,
    GetFlattenedProfitCenterTreesError,
    undefined,
    {},
    GetFlattenedProfitCenterTreesQueryParams,
    {}
  >({ url: '/flat_profit_center_tree', method: 'get', ...variables, signal });

export const useGetFlattenedProfitCenterTrees = <TData = GetFlattenedProfitCenterTreesResponse>(
  variables: GetFlattenedProfitCenterTreesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetFlattenedProfitCenterTreesResponse,
      GetFlattenedProfitCenterTreesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetFlattenedProfitCenterTreesResponse,
    GetFlattenedProfitCenterTreesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/flat_profit_center_tree',
      operationId: 'getFlattenedProfitCenterTrees',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetFlattenedProfitCenterTrees({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetDataContainerById1PathParams {
  dataContainerId: string;
}

export type GetDataContainerById1Error = Fetcher.ErrorWrapper<undefined>;

export type GetDataContainerById1Variables = {
  pathParams: GetDataContainerById1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetDataContainerById1 = (
  variables: GetDataContainerById1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.DataContainer,
    GetDataContainerById1Error,
    undefined,
    {},
    {},
    GetDataContainerById1PathParams
  >({ url: '/datacontainers/{dataContainerId}', method: 'get', ...variables, signal });

export const useGetDataContainerById1 = <TData = Schemas.DataContainer>(
  variables: GetDataContainerById1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DataContainer, GetDataContainerById1Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.DataContainer, GetDataContainerById1Error, TData>({
    queryKey: queryKeyFn({
      path: '/datacontainers/{dataContainerId}',
      operationId: 'getDataContainerById1',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDataContainerById1({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteDataContainer1PathParams {
  dataContainerId: string;
}

export type DeleteDataContainer1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteDataContainer1Variables = {
  pathParams: DeleteDataContainer1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteDataContainer1 = (
  variables: DeleteDataContainer1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteDataContainer1Error,
    undefined,
    {},
    {},
    DeleteDataContainer1PathParams
  >({ url: '/datacontainers/{dataContainerId}', method: 'delete', ...variables, signal });

export const useDeleteDataContainer1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDataContainer1Error,
      DeleteDataContainer1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeleteDataContainer1Error,
    DeleteDataContainer1Variables
  >({
    mutationFn: (variables: DeleteDataContainer1Variables) =>
      fetchDeleteDataContainer1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllDistinctCostCentersQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllDistinctCostCentersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctCostCentersResponse = string[];

export type GetAllDistinctCostCentersVariables = {
  queryParams: GetAllDistinctCostCentersQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllDistinctCostCenters = (
  variables: GetAllDistinctCostCentersVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllDistinctCostCentersResponse,
    GetAllDistinctCostCentersError,
    undefined,
    {},
    GetAllDistinctCostCentersQueryParams,
    {}
  >({ url: '/costcenters', method: 'get', ...variables, signal });

export const useGetAllDistinctCostCenters = <TData = GetAllDistinctCostCentersResponse>(
  variables: GetAllDistinctCostCentersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctCostCentersResponse,
      GetAllDistinctCostCentersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllDistinctCostCentersResponse,
    GetAllDistinctCostCentersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/costcenters',
      operationId: 'getAllDistinctCostCenters',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllDistinctCostCenters({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetClientPathParams {
  name: string;
}

export interface GetClientHeaders {
  authorization?: string;
}

export type GetClientError = Fetcher.ErrorWrapper<undefined>;

export type GetClientVariables = {
  headers?: GetClientHeaders;
  pathParams: GetClientPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetClient = (variables: GetClientVariables, signal?: AbortSignal) =>
  griddyFetch<
    Schemas.ClientDocument,
    GetClientError,
    undefined,
    GetClientHeaders,
    {},
    GetClientPathParams
  >({ url: '/clients/{name}', method: 'get', ...variables, signal });

export const useGetClient = <TData = Schemas.ClientDocument>(
  variables: GetClientVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientDocument, GetClientError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.ClientDocument, GetClientError, TData>({
    queryKey: queryKeyFn({ path: '/clients/{name}', operationId: 'getClient', variables }),
    queryFn: ({ signal }) => fetchGetClient({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetPublicFacilityPathParams {
  clientId: string;
  publicFacilityId: string;
}

export interface GetPublicFacilityHeaders {
  authorization?: string;
}

export type GetPublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicFacilityVariables = {
  headers?: GetPublicFacilityHeaders;
  pathParams: GetPublicFacilityPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetPublicFacility = (
  variables: GetPublicFacilityVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PublicFacilityDTO,
    GetPublicFacilityError,
    undefined,
    GetPublicFacilityHeaders,
    {},
    GetPublicFacilityPathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPublicFacility = <TData = Schemas.PublicFacilityDTO>(
  variables: GetPublicFacilityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.PublicFacilityDTO, GetPublicFacilityError, TData>({
    queryKey: queryKeyFn({
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}',
      operationId: 'getPublicFacility',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPublicFacility({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface DeletePublicFacilityPathParams {
  clientId: string;
  publicFacilityId: string;
}

export type DeletePublicFacilityError = Fetcher.ErrorWrapper<undefined>;

export type DeletePublicFacilityVariables = {
  pathParams: DeletePublicFacilityPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeletePublicFacility = (
  variables: DeletePublicFacilityVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeletePublicFacilityError,
    undefined,
    {},
    {},
    DeletePublicFacilityPathParams
  >({
    url: '/clients/{clientId}/public-facilities/{publicFacilityId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePublicFacility = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePublicFacilityError,
      DeletePublicFacilityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeletePublicFacilityError,
    DeletePublicFacilityVariables
  >({
    mutationFn: (variables: DeletePublicFacilityVariables) =>
      fetchDeletePublicFacility({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetBabReportByIdPathParams {
  id: string;
}

export type GetBabReportByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetBabReportByIdVariables = {
  pathParams: GetBabReportByIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetBabReportById = (variables: GetBabReportByIdVariables, signal?: AbortSignal) =>
  griddyFetch<
    Schemas.BabReport,
    GetBabReportByIdError,
    undefined,
    {},
    {},
    GetBabReportByIdPathParams
  >({ url: '/babreports/{id}', method: 'get', ...variables, signal });

export const useGetBabReportById = <TData = Schemas.BabReport>(
  variables: GetBabReportByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BabReport, GetBabReportByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<Schemas.BabReport, GetBabReportByIdError, TData>({
    queryKey: queryKeyFn({ path: '/babreports/{id}', operationId: 'getBabReportById', variables }),
    queryFn: ({ signal }) => fetchGetBabReportById({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteBabReportByIdPathParams {
  id: string;
}

export type DeleteBabReportByIdError = Fetcher.ErrorWrapper<undefined>;

export type DeleteBabReportByIdVariables = {
  pathParams: DeleteBabReportByIdPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteBabReportById = (
  variables: DeleteBabReportByIdVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteBabReportByIdError,
    undefined,
    {},
    {},
    DeleteBabReportByIdPathParams
  >({ url: '/babreports/{id}', method: 'delete', ...variables, signal });

export const useDeleteBabReportById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteBabReportByIdError,
      DeleteBabReportByIdVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteBabReportByIdError, DeleteBabReportByIdVariables>({
    mutationFn: (variables: DeleteBabReportByIdVariables) =>
      fetchDeleteBabReportById({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface GetAllAccountPlanRecordsWithPagerQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
  /**
   * @format int32
   */
  page: number;
  /**
   * @format int32
   */
  pageSize: number;
}

export type GetAllAccountPlanRecordsWithPagerError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAccountPlanRecordsWithPagerVariables = {
  queryParams: GetAllAccountPlanRecordsWithPagerQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllAccountPlanRecordsWithPager = (
  variables: GetAllAccountPlanRecordsWithPagerVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    Schemas.PageAccountPlan,
    GetAllAccountPlanRecordsWithPagerError,
    undefined,
    {},
    GetAllAccountPlanRecordsWithPagerQueryParams,
    {}
  >({ url: '/accountsplan/page', method: 'get', ...variables, signal });

export const useGetAllAccountPlanRecordsWithPager = <TData = Schemas.PageAccountPlan>(
  variables: GetAllAccountPlanRecordsWithPagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PageAccountPlan,
      GetAllAccountPlanRecordsWithPagerError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    Schemas.PageAccountPlan,
    GetAllAccountPlanRecordsWithPagerError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/accountsplan/page',
      operationId: 'getAllAccountPlanRecordsWithPager',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllAccountPlanRecordsWithPager({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllAccountIdToAccountDescriptionMapQueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllAccountIdToAccountDescriptionMapError = Fetcher.ErrorWrapper<undefined>;

export type GetAllAccountIdToAccountDescriptionMapResponse = Record<string, string>;

export type GetAllAccountIdToAccountDescriptionMapVariables = {
  queryParams: GetAllAccountIdToAccountDescriptionMapQueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllAccountIdToAccountDescriptionMap = (
  variables: GetAllAccountIdToAccountDescriptionMapVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllAccountIdToAccountDescriptionMapResponse,
    GetAllAccountIdToAccountDescriptionMapError,
    undefined,
    {},
    GetAllAccountIdToAccountDescriptionMapQueryParams,
    {}
  >({ url: '/accountsplan/account_description_map', method: 'get', ...variables, signal });

export const useGetAllAccountIdToAccountDescriptionMap = <
  TData = GetAllAccountIdToAccountDescriptionMapResponse,
>(
  variables: GetAllAccountIdToAccountDescriptionMapVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllAccountIdToAccountDescriptionMapResponse,
      GetAllAccountIdToAccountDescriptionMapError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllAccountIdToAccountDescriptionMapResponse,
    GetAllAccountIdToAccountDescriptionMapError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/accountsplan/account_description_map',
      operationId: 'getAllAccountIdToAccountDescriptionMap',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllAccountIdToAccountDescriptionMap({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface GetAllDistinctAccountIds1QueryParams {
  fileType: 'JOURNAL' | 'ASSETS' | 'LEDGER_ACCOUNT' | 'LABELS' | 'ACCOUNT_PLAN';
  yearId: string;
}

export type GetAllDistinctAccountIds1Error = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistinctAccountIds1Response = string[];

export type GetAllDistinctAccountIds1Variables = {
  queryParams: GetAllDistinctAccountIds1QueryParams;
} & GriddyContext['fetcherOptions'];

export const fetchGetAllDistinctAccountIds1 = (
  variables: GetAllDistinctAccountIds1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    GetAllDistinctAccountIds1Response,
    GetAllDistinctAccountIds1Error,
    undefined,
    {},
    GetAllDistinctAccountIds1QueryParams,
    {}
  >({ url: '/accountids', method: 'get', ...variables, signal });

export const useGetAllDistinctAccountIds1 = <TData = GetAllDistinctAccountIds1Response>(
  variables: GetAllDistinctAccountIds1Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistinctAccountIds1Response,
      GetAllDistinctAccountIds1Error,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<
    GetAllDistinctAccountIds1Response,
    GetAllDistinctAccountIds1Error,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/accountids',
      operationId: 'getAllDistinctAccountIds1',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllDistinctAccountIds1({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type BaseError = Fetcher.ErrorWrapper<undefined>;

export type BaseVariables = GriddyContext['fetcherOptions'];

export const fetchBase = (variables: BaseVariables, signal?: AbortSignal) =>
  griddyFetch<string, BaseError, undefined, {}, {}, {}>({
    url: '/',
    method: 'get',
    ...variables,
    signal,
  });

export const useBase = <TData = string>(
  variables: BaseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, BaseError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useGriddyContext(options);
  return reactQuery.useQuery<string, BaseError, TData>({
    queryKey: queryKeyFn({ path: '/', operationId: 'base', variables }),
    queryFn: ({ signal }) => fetchBase({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export interface DeleteDataContainerPathParams {
  id: string;
}

export type DeleteDataContainerError = Fetcher.ErrorWrapper<undefined>;

export type DeleteDataContainerVariables = {
  pathParams: DeleteDataContainerPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteDataContainer = (
  variables: DeleteDataContainerVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteDataContainerError,
    undefined,
    {},
    {},
    DeleteDataContainerPathParams
  >({ url: '/years/{id}', method: 'delete', ...variables, signal });

export const useDeleteDataContainer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteDataContainerError,
      DeleteDataContainerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteDataContainerError, DeleteDataContainerVariables>({
    mutationFn: (variables: DeleteDataContainerVariables) =>
      fetchDeleteDataContainer({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteFileMetadata1PathParams {
  yearCalculationId: string;
  fileType: 'ASSETS' | 'LABELS' | 'LEDGER_ACCOUNT' | 'JOURNAL' | 'ACCOUNT_PLAN';
}

export type DeleteFileMetadata1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteFileMetadata1Variables = {
  pathParams: DeleteFileMetadata1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteFileMetadata1 = (
  variables: DeleteFileMetadata1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteFileMetadata1Error,
    undefined,
    {},
    {},
    DeleteFileMetadata1PathParams
  >({
    url: '/year-calculations/{yearCalculationId}/files-metadata/type/{fileType}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteFileMetadata1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteFileMetadata1Error,
      DeleteFileMetadata1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteFileMetadata1Error, DeleteFileMetadata1Variables>({
    mutationFn: (variables: DeleteFileMetadata1Variables) =>
      fetchDeleteFileMetadata1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteByFileIdFromGridFSPathParams {
  /**
   * @pattern ^[0-9a-zA-Z]{25}$
   */
  id: string;
}

export interface DeleteByFileIdFromGridFSHeaders {
  authorization?: string;
}

export type DeleteByFileIdFromGridFSError = Fetcher.ErrorWrapper<undefined>;

export type DeleteByFileIdFromGridFSVariables = {
  headers?: DeleteByFileIdFromGridFSHeaders;
  pathParams: DeleteByFileIdFromGridFSPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteByFileIdFromGridFS = (
  variables: DeleteByFileIdFromGridFSVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteByFileIdFromGridFSError,
    undefined,
    DeleteByFileIdFromGridFSHeaders,
    {},
    DeleteByFileIdFromGridFSPathParams
  >({ url: '/objects/{id}', method: 'delete', ...variables, signal });

export const useDeleteByFileIdFromGridFS = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteByFileIdFromGridFSError,
      DeleteByFileIdFromGridFSVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeleteByFileIdFromGridFSError,
    DeleteByFileIdFromGridFSVariables
  >({
    mutationFn: (variables: DeleteByFileIdFromGridFSVariables) =>
      fetchDeleteByFileIdFromGridFS({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePlannedCostRevenueConfigsError = Fetcher.ErrorWrapper<undefined>;

export type DeletePlannedCostRevenueConfigsVariables = GriddyContext['fetcherOptions'];

export const fetchDeletePlannedCostRevenueConfigs = (
  variables: DeletePlannedCostRevenueConfigsVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<string, DeletePlannedCostRevenueConfigsError, undefined, {}, {}, {}>({
    url: '/migration/planned-cost-revenue-config/delete',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePlannedCostRevenueConfigs = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      string,
      DeletePlannedCostRevenueConfigsError,
      DeletePlannedCostRevenueConfigsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    string,
    DeletePlannedCostRevenueConfigsError,
    DeletePlannedCostRevenueConfigsVariables
  >({
    mutationFn: (variables: DeletePlannedCostRevenueConfigsVariables) =>
      fetchDeletePlannedCostRevenueConfigs({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteTechnicalDataEntry1PathParams {
  masterConfigurationId: string;
  ids: string[];
}

export type DeleteTechnicalDataEntry1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteTechnicalDataEntry1Variables = {
  pathParams: DeleteTechnicalDataEntry1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteTechnicalDataEntry1 = (
  variables: DeleteTechnicalDataEntry1Variables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteTechnicalDataEntry1Error,
    undefined,
    {},
    {},
    DeleteTechnicalDataEntry1PathParams
  >({
    url: '/master-configurations/{masterConfigurationId}/technical-data/{ids}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteTechnicalDataEntry1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteTechnicalDataEntry1Error,
      DeleteTechnicalDataEntry1Variables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeleteTechnicalDataEntry1Error,
    DeleteTechnicalDataEntry1Variables
  >({
    mutationFn: (variables: DeleteTechnicalDataEntry1Variables) =>
      fetchDeleteTechnicalDataEntry1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteRange1PathParams {
  masterConfigurationId: string;
}

export type DeleteRange1Error = Fetcher.ErrorWrapper<undefined>;

export type DeleteRange1Variables = {
  pathParams: DeleteRange1PathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteRange1 = (variables: DeleteRange1Variables, signal?: AbortSignal) =>
  griddyFetch<undefined, DeleteRange1Error, undefined, {}, {}, DeleteRange1PathParams>({
    url: '/master-configurations/{masterConfigurationId}/account-ranges/delete',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteRange1 = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteRange1Error, DeleteRange1Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<undefined, DeleteRange1Error, DeleteRange1Variables>({
    mutationFn: (variables: DeleteRange1Variables) =>
      fetchDeleteRange1({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export interface DeleteMasterConfigurationPathParams {
  masterConfigurationId: string;
}

export interface DeleteMasterConfigurationHeaders {
  authorization?: string;
}

export type DeleteMasterConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteMasterConfigurationVariables = {
  headers?: DeleteMasterConfigurationHeaders;
  pathParams: DeleteMasterConfigurationPathParams;
} & GriddyContext['fetcherOptions'];

export const fetchDeleteMasterConfiguration = (
  variables: DeleteMasterConfigurationVariables,
  signal?: AbortSignal,
) =>
  griddyFetch<
    undefined,
    DeleteMasterConfigurationError,
    undefined,
    DeleteMasterConfigurationHeaders,
    {},
    DeleteMasterConfigurationPathParams
  >({
    url: '/master-configurations/masterConfiguration/{masterConfigurationId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteMasterConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteMasterConfigurationError,
      DeleteMasterConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useGriddyContext();
  return reactQuery.useMutation<
    undefined,
    DeleteMasterConfigurationError,
    DeleteMasterConfigurationVariables
  >({
    mutationFn: (variables: DeleteMasterConfigurationVariables) =>
      fetchDeleteMasterConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/years';
      operationId: 'getAllDataContainer';
      variables: GetAllDataContainerVariables;
    }
  | {
      path: '/public-facilities/{publicFacilityId}/years/{yearId}';
      operationId: 'getYear1';
      variables: GetYear1Variables;
    }
  | {
      path: '/master-configurations';
      operationId: 'getMasterConfigurationsByYearId';
      variables: GetMasterConfigurationsByYearIdVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}';
      operationId: 'getMasterConfiguration';
      variables: GetMasterConfigurationVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/technical-data';
      operationId: 'getTechnicalDataByMasterConfigurationId';
      variables: GetTechnicalDataByMasterConfigurationIdVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/details';
      operationId: 'getPlannedCostRevenueConfigDetails';
      variables: GetPlannedCostRevenueConfigDetailsVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/entities';
      operationId: 'getMasterConfigurationEntity';
      variables: GetMasterConfigurationEntityVariables;
    }
  | {
      path: '/datacontainers';
      operationId: 'getAllDataContainer1';
      variables: GetAllDataContainer1Variables;
    }
  | {
      path: '/clients/{clientId}/public-facilities';
      operationId: 'getPublicFacilitiesByClient';
      variables: GetPublicFacilitiesByClientVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}/master-configurations';
      operationId: 'getConfigurationsByYearCalculation';
      variables: GetConfigurationsByYearCalculationVariables;
    }
  | {
      path: '/v2/clients';
      operationId: 'getClientsV2';
      variables: GetClientsV2Variables;
    }
  | {
      path: '/users';
      operationId: 'getUsers';
      variables: GetUsersVariables;
    }
  | {
      path: '/public-facilities/{publicFacilityId}/years';
      operationId: 'getYears';
      variables: GetYearsVariables;
    }
  | {
      path: '/plannedCostRevenueTransactions';
      operationId: 'getAccountTransactions';
      variables: GetAccountTransactionsVariables;
    }
  | {
      path: '/objects';
      operationId: 'getAllByUserFromGridFS';
      variables: GetAllByUserFromGridFSVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/account-ranges';
      operationId: 'getRangeConfigForMasterConfiguration';
      variables: GetRangeConfigForMasterConfigurationVariables;
    }
  | {
      path: '/ledgeraccounts';
      operationId: 'getAllLedgerAccount';
      variables: GetAllLedgerAccountVariables;
    }
  | {
      path: '/labels';
      operationId: 'getAllLabels';
      variables: GetAllLabelsVariables;
    }
  | {
      path: '/journal';
      operationId: 'getAllTransactionForJournal';
      variables: GetAllTransactionForJournalVariables;
    }
  | {
      path: '/clients';
      operationId: 'getClients';
      variables: GetClientsVariables;
    }
  | {
      path: '/accountsplan';
      operationId: 'getAllAccountPlanRecords';
      variables: GetAllAccountPlanRecordsVariables;
    }
  | {
      path: '/account_jobid_map';
      operationId: 'getAllAcctIdToJobIdsMap';
      variables: GetAllAcctIdToJobIdsMapVariables;
    }
  | {
      path: '/account';
      operationId: 'getAllTransactionsForAccounts';
      variables: GetAllTransactionsForAccountsVariables;
    }
  | {
      path: '/years/{yearId}/master-configs/{masterConfigId}/cost-units/profit-center-trees';
      operationId: 'getFlattenedProfitCenterTreesForCostUnit';
      variables: GetFlattenedProfitCenterTreesForCostUnitVariables;
    }
  | {
      path: '/years/{ids}';
      operationId: 'getDataContainerById';
      variables: GetDataContainerByIdVariables;
    }
  | {
      path: '/years/masterConfigId/{masterConfigId}';
      operationId: 'getYearIdByMasterConfigId';
      variables: GetYearIdByMasterConfigIdVariables;
    }
  | {
      path: '/years/master-configurations/{masterConfigurationId}';
      operationId: 'fetchYearIdByMasterConfigurationId';
      variables: FetchYearIdByMasterConfigurationIdVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}';
      operationId: 'getYearCalculation';
      variables: GetYearCalculationVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}/master-configurations/entities';
      operationId: 'getMasterConfigurationByYearCalculation';
      variables: GetMasterConfigurationByYearCalculationVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}/files-metadata';
      operationId: 'getFilesMetadata';
      variables: GetFilesMetadataVariables;
    }
  | {
      path: '/year-calculations/{yearCalculationId}/files-metadata/status';
      operationId: 'getFilesMetadataStatus';
      variables: GetFilesMetadataStatusVariables;
    }
  | {
      path: '/v2/years/{yearId}';
      operationId: 'getYear';
      variables: GetYearVariables;
    }
  | {
      path: '/v2/journal';
      operationId: 'getAllTransactionForJournalV2';
      variables: GetAllTransactionForJournalV2Variables;
    }
  | {
      path: '/v2/clients/{name}';
      operationId: 'getClientV2';
      variables: GetClientV2Variables;
    }
  | {
      path: '/v2/clients/id/{id}';
      operationId: 'getClientById2';
      variables: GetClientById2Variables;
    }
  | {
      path: '/v2/account';
      operationId: 'getAllTransactionsForOneAccountV2';
      variables: GetAllTransactionsForOneAccountV2Variables;
    }
  | {
      path: '/public-facilities/{publicFacilityId}/years/year-calculations/master-configurations';
      operationId: 'getMasterConfigurations';
      variables: GetMasterConfigurationsVariables;
    }
  | {
      path: '/profitcenters';
      operationId: 'getAllDistinctProfitCenters';
      variables: GetAllDistinctProfitCentersVariables;
    }
  | {
      path: '/profitcenter_trees';
      operationId: 'getTransactionsByFileId';
      variables: GetTransactionsByFileIdVariables;
    }
  | {
      path: '/profitcenter_trees/{profitCenterId}';
      operationId: 'getTransactionsByProfitCenterId';
      variables: GetTransactionsByProfitCenterIdVariables;
    }
  | {
      path: '/objects/{ids}';
      operationId: 'getByFileIdsFromGridFS';
      variables: GetByFileIdsFromGridFSVariables;
    }
  | {
      path: '/object';
      operationId: 'getByFileIdsFromGridFSNoAuth';
      variables: GetByFileIdsFromGridFSNoAuthVariables;
    }
  | {
      path: '/migration/profitCenterMapping/migrateProfitCenterTreeToFlatProfitCenterList';
      operationId: 'migrateProfitCenterTreeToFlatProfitCenterList';
      variables: MigrateProfitCenterTreeToFlatProfitCenterListVariables;
    }
  | {
      path: '/migration/migrateMasterConfigurationsFromGridFsToMongo';
      operationId: 'migrateMasterConfigurationsFromGridFsToMongo';
      variables: MigrateMasterConfigurationsFromGridFsToMongoVariables;
    }
  | {
      path: '/migration/migrateMasterConfigurationsFromGridFsToMongo/{masterConfigurationId}';
      operationId: 'migrateSingleMasterConfigurationFromGridFsToMongo';
      variables: MigrateSingleMasterConfigurationFromGridFsToMongoVariables;
    }
  | {
      path: '/migration/migrateCostUnitForMasterConfiguration/{masterConfigurationId}';
      operationId: 'migrateCostUnitForMasterConfiguration';
      variables: MigrateCostUnitForMasterConfigurationVariables;
    }
  | {
      path: '/migration/interest-table-report';
      operationId: 'migrateInterestTableReport';
      variables: MigrateInterestTableReportVariables;
    }
  | {
      path: '/migration/cost-unit-report';
      operationId: 'migrateCostUnitReport';
      variables: MigrateCostUnitReportVariables;
    }
  | {
      path: '/migration/cost-center-rank/detect-incorrect-ranks';
      operationId: 'detectMasterConfigurationWithIncorrectCostCenterRanks';
      variables: DetectMasterConfigurationWithIncorrectCostCenterRanksVariables;
    }
  | {
      path: '/masterConfiguration/{id}';
      operationId: 'getMasterConfigurationById';
      variables: GetMasterConfigurationByIdVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/technical-data/status';
      operationId: 'getConfigStatus';
      variables: GetConfigStatusVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/technical-data/groups';
      operationId: 'getTechnicalDataGroupsByMasterConfigurationId';
      variables: GetTechnicalDataGroupsByMasterConfigurationIdVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/technical-data/characteristics';
      operationId: 'getTechnicalDataCharacteristicsByMasterConfigurationId';
      variables: GetTechnicalDataCharacteristicsByMasterConfigurationIdVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/pre-calculation';
      operationId: 'isMasterConfigurationForPreCalculation';
      variables: IsMasterConfigurationForPreCalculationVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue';
      operationId: 'getPlannedCostRevenueConfig';
      variables: GetPlannedCostRevenueConfigVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/status';
      operationId: 'getConfigStatus1';
      variables: GetConfigStatus1Variables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/planned-cost-revenue/preselected-values';
      operationId: 'getPlannedCostRevenuePreselectedValues';
      variables: GetPlannedCostRevenuePreselectedValuesVariables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/cost-centers/status';
      operationId: 'getConfigStatus2';
      variables: GetConfigStatus2Variables;
    }
  | {
      path: '/master-configurations/{masterConfigurationId}/account-ranges/status';
      operationId: 'getConfigStatus3';
      variables: GetConfigStatus3Variables;
    }
  | {
      path: '/master-configurations/entities-by-year';
      operationId: 'getAllMasterConfigurationEntityByYear';
      variables: GetAllMasterConfigurationEntityByYearVariables;
    }
  | {
      path: '/master-configurations-by-year';
      operationId: 'getAllMasterConfigurationsByYear';
      variables: GetAllMasterConfigurationsByYearVariables;
    }
  | {
      path: '/master-configs/{masterConfigurationId}/accounts';
      operationId: 'getAccountsByMasterConfiguration';
      variables: GetAccountsByMasterConfigurationVariables;
    }
  | {
      path: '/ledgeraccounts/page';
      operationId: 'getAllLedgerAccountWithPager';
      variables: GetAllLedgerAccountWithPagerVariables;
    }
  | {
      path: '/ledgeraccounts/accounts_costcenter';
      operationId: 'getAccountsToCostCenterNames';
      variables: GetAccountsToCostCenterNamesVariables;
    }
  | {
      path: '/ledgeraccounts/accountids';
      operationId: 'getAllDistinctAccountIds';
      variables: GetAllDistinctAccountIdsVariables;
    }
  | {
      path: '/ledgeraccounts/account_costcenter_map';
      operationId: 'getAllAcctIdToCostCenterNameMap';
      variables: GetAllAcctIdToCostCenterNameMapVariables;
    }
  | {
      path: '/ledgeraccounts/account_costcenter';
      operationId: 'getAcctIdToCostCenterName';
      variables: GetAcctIdToCostCenterNameVariables;
    }
  | {
      path: '/ledgeraccounts/account';
      operationId: 'getAllLedgerAccountsForOneAccount';
      variables: GetAllLedgerAccountsForOneAccountVariables;
    }
  | {
      path: '/labels/type';
      operationId: 'getAllLabelsByType';
      variables: GetAllLabelsByTypeVariables;
    }
  | {
      path: '/labels/page';
      operationId: 'getAllLabelsWithPager';
      variables: GetAllLabelsWithPagerVariables;
    }
  | {
      path: '/journal/page';
      operationId: 'getAllTransactionForJournalV21';
      variables: GetAllTransactionForJournalV21Variables;
    }
  | {
      path: '/jobids';
      operationId: 'getAllDistinctJobIds';
      variables: GetAllDistinctJobIdsVariables;
    }
  | {
      path: '/healthcheck';
      operationId: 'healthcheck';
      variables: HealthcheckVariables;
    }
  | {
      path: '/flat_profit_center_tree';
      operationId: 'getFlattenedProfitCenterTrees';
      variables: GetFlattenedProfitCenterTreesVariables;
    }
  | {
      path: '/datacontainers/{dataContainerId}';
      operationId: 'getDataContainerById1';
      variables: GetDataContainerById1Variables;
    }
  | {
      path: '/costcenters';
      operationId: 'getAllDistinctCostCenters';
      variables: GetAllDistinctCostCentersVariables;
    }
  | {
      path: '/clients/{name}';
      operationId: 'getClient';
      variables: GetClientVariables;
    }
  | {
      path: '/clients/{clientId}/public-facilities/{publicFacilityId}';
      operationId: 'getPublicFacility';
      variables: GetPublicFacilityVariables;
    }
  | {
      path: '/babreports/{id}';
      operationId: 'getBabReportById';
      variables: GetBabReportByIdVariables;
    }
  | {
      path: '/accountsplan/page';
      operationId: 'getAllAccountPlanRecordsWithPager';
      variables: GetAllAccountPlanRecordsWithPagerVariables;
    }
  | {
      path: '/accountsplan/account_description_map';
      operationId: 'getAllAccountIdToAccountDescriptionMap';
      variables: GetAllAccountIdToAccountDescriptionMapVariables;
    }
  | {
      path: '/accountids';
      operationId: 'getAllDistinctAccountIds1';
      variables: GetAllDistinctAccountIds1Variables;
    }
  | {
      path: '/';
      operationId: 'base';
      variables: BaseVariables;
    };
