import type { IRowNode } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { ChangeEvent } from 'react';
import { useCallback, useRef, useState } from 'react';

import type { griddy } from '@org/query';
import { useDebounce, useEvent } from '@org/hooks';
import {
  getCopyOfTableData,
  setRowAttributeValue,
  setRowAttributeValueForFilteredNodes,
} from '@org/utils';

import type { BaseControllerType } from '../types';
import { useColumns } from './useColumns';
import { useImputedWorkingCapital } from './useImputedWorkingCapital';

type WorkingCapital = griddy.WorkingCapital;

export type ControllerProps = BaseControllerType;

export const useController = (apiParams: ControllerProps) => {
  const {
    imputedWorkingCapitalConfig,
    updateWorkingCapitals,
    updateDefaultWorkingCapitalRate,
    updateEnableWorkingCapital,
  } = useImputedWorkingCapital(apiParams);
  const tableRef = useRef<AgGridReact>(null);
  const gridApi = tableRef.current?.api;
  const { workingCapitalEnabled = false, workingCapitals = [] } = imputedWorkingCapitalConfig ?? {};

  const [enteredWorkingCapitalRate, setEnteredWorkingCapitalRate] = useState(
    imputedWorkingCapitalConfig?.defaultWorkingCapitalRate ?? 0,
  );

  const debouncedUpdateDefaultWorkingCapitalRate = useDebounce(async (value: number) => {
    await updateDefaultWorkingCapitalRate(value);
  }, 450);

  const handleWorkingCapitalsChange = useCallback(async () => {
    const newWorkingCapitals = getCopyOfTableData<WorkingCapital>(gridApi);
    await updateWorkingCapitals(newWorkingCapitals);
  }, [gridApi, updateWorkingCapitals]);

  const handleEnableWorkingCapitalChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const enabled = event.currentTarget.checked;
      await updateEnableWorkingCapital(enabled);
    },
    [updateEnableWorkingCapital],
  );

  const handleDefaultWorkingCapitalRateChange = useEvent((val: number = 0) => {
    setEnteredWorkingCapitalRate(val);
    debouncedUpdateDefaultWorkingCapitalRate(val);
  });

  const resetSingleWorkingCapitalRateToDefault = useCallback(
    async (rowNode: IRowNode) => {
      if (gridApi) {
        setRowAttributeValue<WorkingCapital>(
          rowNode,
          'workingCapitalRate',
          enteredWorkingCapitalRate,
        );
        await handleWorkingCapitalsChange();
      }
    },
    [enteredWorkingCapitalRate, gridApi, handleWorkingCapitalsChange],
  );

  const resetAllFilteredWorkingCapitalRatesToDefault = useCallback(async () => {
    if (gridApi) {
      setRowAttributeValueForFilteredNodes<WorkingCapital>(
        gridApi,
        'workingCapitalRate',
        enteredWorkingCapitalRate,
      );
      await handleWorkingCapitalsChange();
    }
  }, [enteredWorkingCapitalRate, gridApi, handleWorkingCapitalsChange]);

  const columns = useColumns({
    apiParams,
    resetWorkingCapitalRate: resetSingleWorkingCapitalRateToDefault,
  });

  return {
    ...apiParams,
    apiParams,
    columns,
    enteredWorkingCapitalRate,
    handleDefaultWorkingCapitalRateChange,
    handleEnableWorkingCapitalChange,
    resetAllFilteredWorkingCapitalRatesToDefault,
    tableRef,
    updateWorkingCapitals,
    workingCapitalEnabled,
    workingCapitals,
  };
};

export type ControllerType = ReturnType<typeof useController>;
