import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Button, Label, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface ImputedWithdrawalCapitalProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const ImputedWithdrawalCapital: FC<ImputedWithdrawalCapitalProps> = (props) => {
  const { t } = useTranslation();
  const {
    tableRef,
    gridApi,
    activateConfiguration,
    activeConfiguration,
    autoGroupColumnDef,
    clearSelected,
    columnDefs,
    defaultColDef,
    enableDeleteButton,
    onSelectionChanged,
    onTableDataChange,
    updateGroupName,
    modal,
    agGridProps,
  } = props;

  return (
    <>
      <h6 className="MuiTypography-root MuiTypography-h6">
        {t('main:manageMenu.manageImputedWithdrawalCapital.tableTitle')}
      </h6>
      <Label className="mb-3 flex cursor-pointer gap-2 text-xs">
        <ToggleInput
          checked={activeConfiguration}
          name={t('main:manageMenu.manageCostCenters.switchUseInCalculation')}
          onChange={activateConfiguration}
        />
        {t('main:manageMenu.manageCostCenters.switchUseInCalculation')}
      </Label>
      <Button
        className="mb-[16px] self-start"
        disabled={!activeConfiguration || !enableDeleteButton}
        onClick={clearSelected}
        size="md"
        variant="primary"
      >
        <span className="appkiticon icon-delete-fill" />
        {t('main:manageMenu.manageImputedWithdrawalCapital.removeSelected')}
      </Button>

      <BaseEditTable
        {...agGridProps}
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        enableGroupEdit={activeConfiguration}
        groupDefaultExpanded={1}
        groupSelectsChildren
        groupSelectsFiltered
        isDisabled={!activeConfiguration}
        onChange={onTableDataChange}
        onFilterChanged={() => gridApi?.refreshHeader()}
        onGroupCellChange={updateGroupName}
        onSelectionChanged={onSelectionChanged}
        ref={tableRef}
        rowSelection="multiple"
        showOpenedGroup
        useAutoGeneratedRowId
      />
      {modal}
    </>
  );
};

export const ImputedWithdrawalCapitalPage = bindControllers(ImputedWithdrawalCapital, [
  useController,
]);
ImputedWithdrawalCapitalPage.displayName = 'ImputedWithdrawalCapitalPage';
