import type { FC } from 'react';

import { useTranslation } from '@org/locales';
import { BaseTable, Button } from '@org/ui';
import { bindControllers, getRowClasses } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const View: FC<ViewProps> = ({
  defaultColDef,
  columnDefs,
  handleExport,
  gridRef,
  defaultExcelExportParams,
  agGridProps,
  doesExternalFilterPass,
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="float-right inline-flex items-center gap-4">
        <Button
          onClick={handleExport}
          size="sm"
          variant="primary"
        >
          <span className="appkiticon icon-download-outline" />
          EXPORT
        </Button>
      </div>
      <div className="flex h-[calc(100vh-180px)] w-full flex-auto items-start justify-center overflow-auto p-0 pt-3">
        <BaseTable
          // FIXME: Workaround for ag grid refresh issue with labels
          key={i18n.language}
          {...agGridProps}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          defaultExcelExportParams={defaultExcelExportParams}
          doesExternalFilterPass={doesExternalFilterPass}
          getRowClass={getRowClasses()}
          groupDefaultExpanded={2}
          groupDisplayType="custom"
          isExternalFilterPresent={() => true}
          ref={gridRef}
        />
      </div>
    </>
  );
};

export const CostUnitReport = bindControllers(View, [useController]);
CostUnitReport.displayName = 'CostUnitReport';
