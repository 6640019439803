import type { IRowNode } from 'ag-grid-community';

export const getFirstEntryOf = <T>(fieldName: keyof T, tableData: T[], subGroup: T) =>
  tableData.find((i) => i[fieldName] === subGroup[fieldName])!;

export const getLastEntryOf = <T>(fieldName: keyof T, tableData: T[], subGroup: T) =>
  tableData.findLast((i) => i[fieldName] === subGroup[fieldName])!;

export const getIndexOfEntry = <T>(fieldName: keyof T, tableData: T[], entry: T) =>
  tableData.findIndex((item) => item[fieldName] === entry[fieldName]);

const CHILDREN_AFTER_GROUP = 'childrenAfterGroup';
const ALL_LEAF_CHILDREN = 'allLeafChildren';

export const GetNodesTypes = [CHILDREN_AFTER_GROUP, ALL_LEAF_CHILDREN] as const;
export type GetNodesType = (typeof GetNodesTypes)[number];

export const getBasicVarsForMovingSubgroups = <T>(
  node: IRowNode,
  tableData: T[],
  identifierField: keyof T,
) => getBasicVars(node, tableData, identifierField, CHILDREN_AFTER_GROUP);

export const getBasicVarsForMovingGroupsAndSubgroups = <T>(
  node: IRowNode,
  tableData: T[],
  identifierField: keyof T,
) => getBasicVars(node, tableData, identifierField, ALL_LEAF_CHILDREN);

export const getBasicVars = <T>(
  node: IRowNode,
  tableData: T[],
  identifierField: keyof T,
  type: GetNodesType,
) => {
  const groupLastItem = node[type]?.at(-1);
  const groupFirstItem = node[type]?.at(0);

  const groupLastItemIndex = tableData.findIndex(
    (item) => item[identifierField] === groupLastItem?.data[identifierField],
  );

  const groupFirstItemIndex = tableData.findIndex(
    (item) => item[identifierField] === groupFirstItem?.data[identifierField],
  );

  const rowsToMove: T[] = node[type]?.map((IrowNode) => IrowNode.data) ?? [];

  const tableDataLen = tableData.length - 1;

  return {
    groupFirstItem,
    groupFirstItemIndex,
    groupLastItem,
    groupLastItemIndex,
    nodeIsFirstRow: groupFirstItemIndex === 0,
    nodeIsLastRow: tableDataLen === groupLastItemIndex,
    rowsToMove,
    tableDataLen,
  };
};
