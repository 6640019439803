import { useState } from 'react';

import { Input } from '@nextui-org/react';

import { useKeys } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { YearsOverviewModalContentProps } from '..';

const avoidNumericKeys = (event: React.KeyboardEvent<HTMLInputElement>) =>
  ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();

export const AddNewYearModalContent = ({
  disclosureState,
  createYear,
  years,
}: YearsOverviewModalContentProps) => {
  const { t } = useTranslation();

  const [newYear, setNewYear] = useState('');

  const { isOpen, onClose } = disclosureState;

  const isDuplicated = years?.some((year) => year.year === newYear);

  useKeys('Enter', () => {
    const isValid = isOpen && newYear && !isDuplicated;

    if (isValid) {
      addNewFacilityYear();
    }
  });

  const handleCloseModal = () => {
    onClose?.();
    setNewYear('');
  };

  const addNewFacilityYear = async () => {
    if (!newYear || Number(newYear) > 3000 || Number(newYear) < 1900) {
      return showNotification(
        'warning',
        t('main:clients.yearlyOverviewPanel.alerts.warning.notAYear'),
      );
    }

    if (isDuplicated) {
      const existMsg = t('main:clients.yearlyOverviewPanel.alerts.warning.yearAlreadyExistent');
      showNotification('warning', existMsg);
      return;
    }

    try {
      await createYear({ year: newYear });
      handleCloseModal();
    } catch {
      showNotification('warning', t('main:clients.clientsOverviewPanel.alerts.warning.general'));
    }
  };

  return (
    <>
      <ModalHeader>{t('main:clients.yearlyOverviewPanel.header')}</ModalHeader>
      <ModalBody>
        <Input
          label={t('common:year')}
          onChange={({ target: { value } }) => {
            if (value.length <= 4) {
              return setNewYear(value);
            }
          }}
          onKeyDown={avoidNumericKeys}
          type="number"
          value={newYear}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          color="danger"
          onClick={handleCloseModal}
          size="md"
          variant="secondary"
        >
          {t('common:close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!!isDuplicated || !newYear || !(newYear.length === 4)}
          onClick={addNewFacilityYear}
          size="md"
          variant="primary"
        >
          {t('common:create')}
        </Button>
      </ModalFooter>
    </>
  );
};
