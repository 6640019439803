import type { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useMemo } from 'react';

import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';

import type { CostTypeAccountRowDataAccount } from './types';
import {
  AccountTableCheckbox,
  AdjustedAccountTableCheckbox,
  SelectAllCheckbox,
} from './components';

interface UseColumnsProps {
  selectedAccountsMap?: Record<number, boolean>;
  adjustedAccountsMap?: Record<number, boolean>;
  costTypeAccountConfig?: dice.CostTypeAccountConfigDTO;
  costTypeAccountId: string;
  detailAccounts: CostTypeAccountRowDataAccount[];
  tableRef: RefObject<AgGridReact>;
  handleEditConfig: (config: dice.CostTypeAccountConfigDTO) => Promise<void>;
}

export const useColumns = ({
  selectedAccountsMap = {},
  adjustedAccountsMap = {},
  costTypeAccountConfig,
  costTypeAccountId,
  detailAccounts,
  handleEditConfig,
}: UseColumnsProps): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      [
        {
          cellRenderer: ({ data: rowData, ...other }: ICellRendererParams) => (
            <AccountTableCheckbox
              {...other}
              costTypeAccountConfig={costTypeAccountConfig}
              data={rowData}
              handleEditConfig={handleEditConfig}
              rowId={costTypeAccountId}
              selectedAccounts={selectedAccountsMap}
            />
          ),
          colId: 'accountId',
          field: 'accountId',
          flex: 2,
          headerComponent: () => (
            <div className="flex space-x-2">
              <SelectAllCheckbox
                costTypeAccountConfig={costTypeAccountConfig}
                detailAccounts={detailAccounts}
                handleEditConfig={handleEditConfig}
                rowId={costTypeAccountId}
                selectedAccounts={selectedAccountsMap}
              />
              <div>{t('main:manageMenu.costTypeAccountConfiguration.tableColumns.account')}</div>
            </div>
          ),
        },
        {
          colId: 'costType',
          field: 'costType',
          flex: 1,
          headerName: t('main:manageMenu.costTypeAccountConfiguration.costType'),
        },
        {
          cellRenderer: ({ data: rowData, ...other }: ICellRendererParams) => (
            <AdjustedAccountTableCheckbox
              {...other}
              adjustedAccounts={adjustedAccountsMap}
              costTypeAccountConfig={costTypeAccountConfig}
              data={rowData}
              handleEditConfig={handleEditConfig}
              rowId={costTypeAccountId}
              selectedAccounts={selectedAccountsMap}
            />
          ),
          colId: 'adjusted',
          field: 'adjusted',
          headerClass: 'ag-right-aligned-header',
          headerName: t('main:manageMenu.costTypeAccountConfiguration.tableColumns.adjusted'),
          maxWidth: 100,
        },
      ] satisfies (ColDef | ColGroupDef)[],
    [
      adjustedAccountsMap,
      costTypeAccountConfig,
      costTypeAccountId,
      detailAccounts,
      handleEditConfig,
      selectedAccountsMap,
      t,
    ],
  );
};
