import axios from 'axios';

import { _getAuthHeaders, _getAuthToken, _getIdToken } from './env-url';
import Logger from './Logger';

export const sendPost = async (url, payload, params) => {
  const authHeaders = _getAuthHeaders();
  const config = {
    data: payload,
    headers: authHeaders,
    method: 'post',
    params: params ?? {},
    url,
  };

  const { data } = await axios(config);
  return data;
};

export const sendPut = async (url, payload, params) => {
  const authHeaders = _getAuthHeaders();
  const config = {
    data: payload,
    headers: authHeaders,
    method: 'put',
    params: params ?? {},
    url,
  };

  const { data } = await axios(config);
  return data;
};

export const sendDelete = async (url, params) => {
  const authHeaders = _getAuthHeaders();
  const config = {
    headers: authHeaders,
    method: 'delete',
    params: params ?? {},
    url,
  };

  const { data } = await axios(config);
  return data;
};

export const sendGet = async (url, params) => {
  const authHeaders = _getAuthHeaders();
  const config = {
    headers: authHeaders,
    method: 'get',
    params: params ?? {},
    url,
  };

  const { data } = await axios(config);
  return data;
};

export const fetcher = (url, config) => {
  const authHeaders = _getAuthHeaders();
  const configuration = config ? config : {};
  return axios({
    headers: authHeaders,
    url,
    ...configuration,
  })
    .then((res) => res.data)
    .catch((error) => Logger.error(error));
};
