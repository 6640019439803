import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import type { aggregated } from '@org/query';
import { useTranslation } from '@org/locales';
import {
  formatNonZeroNumberToLocaleString,
  getAlignmentIndentClass,
  getCellClasses,
} from '@org/utils';

export interface PriceSheetReportDTO {
  name: string;
  groupName: string;
  subGroupName: string;
  amount: number;
  currency: string;
}

export const useColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          cellRenderer: 'agGroupCellRenderer',
          showRowGroup: true,
          cellClass: getCellClasses(getAlignmentIndentClass),
          colId: 'subGroupName',
          field: 'subGroupName',
          headerName: t('main:manageMenu.priceSheet.tableColumns.description'),
          flex: 1,
        },
        {
          colId: 'source',
          field: 'source',
          valueGetter: ({ data }) => t(`main:manageMenu.priceSheet.${data?.source}`),
          hide: true,
          rowGroup: true,
        },
        {
          colId: 'groupName',
          field: 'groupName',
          hide: true,
          rowGroup: true,
        },
        {
          colId: 'currency',
          field: 'currency',
          cellClass: getCellClasses(),
          headerName: t('main:reportPanels.priceSheet.tableRows.unit'),
          maxWidth: 100,
        },
        {
          colId: 'amount',
          field: 'amount',
          cellClass: getCellClasses(() => 'ag-cell-type-report-price accounting-number'),
          headerName: t('main:reportPanels.priceSheet.tableRows.amount'),
          maxWidth: 200,
          type: 'report-price',
          valueFormatter: ({ value }) =>
            typeof value === 'number'
              ? formatNonZeroNumberToLocaleString(value, { defaultValue: '-' })
              : value,
        },
      ] satisfies (
        | ColDef<ReturnType<typeof aggregated.flattenPriceSheetReportGroups>[number]>
        | ColGroupDef
      )[],
    [t],
  );
};
