import type { FC } from 'react';

import { Chip } from '@nextui-org/react';

import { Combobox, ComboboxItem, ComboboxSection } from '@org/design';
import { useTranslation } from '@org/locales';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const View: FC<ViewProps> = (props) => {
  const { configurations, value, onChange, className, ...singleComboboxProps } = props;
  const { t } = useTranslation();

  return (
    <Combobox
      {...singleComboboxProps}
      aria-label="Master Configurations Select"
      className={className}
      onSelectionChange={onChange}
      placeholder={t('common:configuration')}
      selectedKey={value}
    >
      {configurations.map(({ year, configs }) => (
        <ComboboxSection
          key={year}
          title={year}
        >
          {configs.map((config) => (
            <ComboboxItem
              key={config.id}
              textValue={`${year} | ${config?.type} | ${config.name}`}
            >
              <Chip
                radius="sm"
                size="sm"
                variant="dot"
              >
                {t(`main:reportGeneration.selectionSection.${config.configType}Calculation`)} |{' '}
                {config?.type} |
              </Chip>
              {config.name}
            </ComboboxItem>
          ))}
        </ComboboxSection>
      ))}
    </Combobox>
  );
};

export const MasterConfigurationsSelect = bindControllers(View, [useController]);
MasterConfigurationsSelect.displayName = 'MasterConfigurationsSelect';
