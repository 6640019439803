import type { ProfitCenterTree } from '@org/models';
import type { griddy } from '@org/query';

import { objGet, objSet } from '../../../../utils';
import { createAllocationStructureFromMapping } from '../WithdrawCapitalHelper';

export const resetAllAllocations = (
  withdrawCapitalConfig: griddy.WithdrawCapital[],
  costCenterConfig: griddy.CostCenterConfig,
  accountCostCentersMap: Record<number, string[]>,
  profitCenterTrees: ProfitCenterTree[],
) => {
  const defaultAllocationMap = createAllocationStructureFromMapping(
    accountCostCentersMap,
    costCenterConfig,
    profitCenterTrees,
  );

  return withdrawCapitalConfig?.map((row) => {
    if (row?.accountsAllocation) {
      Object.keys(row?.accountsAllocation).forEach((accountId) => {
        const newAutomaticallyCreatedMap = objGet(defaultAllocationMap, accountId) ?? {};
        objSet(row.accountsAllocation, accountId, newAutomaticallyCreatedMap);
      });
    }
    return row;
  });
};
