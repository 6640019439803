import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { sendPost } from '../utils/apiCalls';
import { _getClientID, _getLogoutUrl } from '../utils/env-url';

const Logout = () => {
  const navigate = useNavigate();
  const [revokeResult, setRevokeResult] = useState(null);

  const revokeToken = useCallback(
    async (token) => {
      try {
        const response = await sendPost(
          '/productIAM/revoke_token',
          {},
          {
            client_id: _getClientID(),
            token,
          },
        );
        setRevokeResult(response);
      } catch (error) {
        setRevokeResult({ error: true, message: error });
        sessionStorage.clear();
        sessionStorage.setItem('initCall', false);
        navigate('/');
      }
    },
    [navigate],
  );

  useEffect(() => {
    const pIAM_authorization_token = sessionStorage.getItem('pIAM_authorization_token');

    if (pIAM_authorization_token !== null) {
      revokeToken(pIAM_authorization_token);
    } else {
      navigate('/');
    }
  }, [navigate, revokeToken]);

  useEffect(() => {
    const logoutPath = _getLogoutUrl();
    if (!revokeResult?.error) {
      // Ask other tabs for session storage (this is ONLY to trigger event for ALL TAB)
      localStorage.setItem('setLogoutSession', 'dealsSsp');
      localStorage.removeItem('setLogoutSession', 'dealsSsp');
      localStorage.removeItem('refreshToken-nanoedmo:time');
      localStorage.removeItem('refreshToken-nanoedmo:running');
      localStorage.removeItem('sessionEndingAlert-nanoedmo:time');
      localStorage.removeItem('sessionEndingAlert-nanoedmo:running');
      localStorage.removeItem('sessionClosing-nanoedmo:time');
      localStorage.removeItem('sessionClosing-nanoedmo:running');

      sessionStorage.removeItem('pIAM_authorization_token');
      sessionStorage.removeItem('pIAM_refreshtoken_token');
      sessionStorage.removeItem('pIAM_id_token');
      sessionStorage.removeItem('pIAM');
      sessionStorage.clear();
      sessionStorage.setItem('initCall', true);
      window.location.href = logoutPath;
    }
    if (revokeResult?.error) {
      // Ask other tabs for session storage (this is ONLY to trigger event for ALL TAB)
      localStorage.setItem('setLogoutSession', 'dealsSsp');
      localStorage.removeItem('setLogoutSession', 'dealsSsp');
      localStorage.removeItem('refreshToken-nanoedmo:time');
      localStorage.removeItem('refreshToken-nanoedmo:running');
      localStorage.removeItem('sessionEndingAlert-nanoedmo:time');
      localStorage.removeItem('sessionEndingAlert-nanoedmo:running');
      localStorage.removeItem('sessionClosing-nanoedmo:time');
      localStorage.removeItem('sessionClosing-nanoedmo:running');

      sessionStorage.removeItem('pIAM_authorization_token');
      sessionStorage.removeItem('pIAM_refreshtoken_token');
      sessionStorage.removeItem('pIAM_id_token');
      sessionStorage.removeItem('pIAM');
      sessionStorage.clear();

      sessionStorage.setItem('initCall', true);
      window.location.href = logoutPath;
    }
  }, [revokeResult]);

  return <></>;
};

export default Logout;
