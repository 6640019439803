/**
 * This file is generated by @org/config/svgr-preset.js
 * Do not edit directly.
 * Instead, edit the @org/config/svgr-preset.js file and run
 */
import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgSpinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="spinner_svg__lds-wedges"
    height="1em"
    preserveAspectRatio="xMidYMid"
    style={{
      background: '0 0',
    }}
    viewBox="0 0 100 100"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="rotate(-168.18 45.518 44.482) scale(.8)">
      <animateTransform
        attributeName="transform"
        begin="0s"
        calcMode="linear"
        dur="0.75s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      />
      <path
        d="M50 50V0a50 50 0 0 1 50 50Z"
        fill="#d04a02"
        fillOpacity={0.8}
      />
    </g>
    <g transform="rotate(143.865 43.369 46.631) scale(.8)">
      <animateTransform
        attributeName="transform"
        begin="0s"
        calcMode="linear"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      />
      <path
        d="M50 50h50a50 50 0 0 1-50 50Z"
        fill="#eb8c00"
        fillOpacity={0.8}
      />
    </g>
    <g transform="rotate(95.91 40.49 49.51) scale(.8)">
      <animateTransform
        attributeName="transform"
        begin="0s"
        calcMode="linear"
        dur="1.5s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      />
      <path
        d="M50 50v50A50 50 0 0 1 0 50Z"
        fill="#d93954"
        fillOpacity={0.8}
      />
    </g>
    <g transform="rotate(47.955 33.758 56.242) scale(.8)">
      <animateTransform
        attributeName="transform"
        begin="0s"
        calcMode="linear"
        dur="3s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      />
      <path
        d="M50 50H0A50 50 0 0 1 50 0Z"
        fill="#ffb600"
        fillOpacity={0.8}
      />
    </g>
  </svg>
);
const Memo = memo(SvgSpinner);
export default Memo;
