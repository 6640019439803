import type { FC } from 'react';

import classNames from 'classnames';
import { Controller } from 'react-hook-form';

import type { BaseEditTableProps } from '@org/ui';
import { MasterConfigurationsSelect } from '@org/features';
import { useTranslation } from '@org/locales';
import { Button, Label, LoaderWrapper, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface AssetForecastProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const AssetForecastView: FC<AssetForecastProps> = (props) => {
  const { t } = useTranslation();

  const {
    handleActivateAssetForecast,
    isEnabled,
    isDisabled,
    createAssetForecast,
    isToggleDisabled,
    control,
    isLoading,
    publicFacilityId,
    handleChangeConfig,
  } = props;

  return (
    <LoaderWrapper loading={isLoading}>
      <div className="h-fit flex-1">
        <div
          className="flex flex-col"
          data-testid="AssetForecastPage"
        >
          <h6 className="my-3">{t('main:manageMenu.manageAssetForecast.title')}</h6>

          <Label
            className={classNames(
              'mb-3 flex cursor-pointer gap-2 text-xs',
              isToggleDisabled && 'pointer-events-none cursor-default',
            )}
          >
            <ToggleInput
              checked={isEnabled}
              disabled={isToggleDisabled}
              name={t('main:manageMenu.manageCostCenters.switchUseInCalculation')}
              onChange={handleActivateAssetForecast}
            />
            {t('main:manageMenu.manageCostCenters.switchUseInCalculation')}
          </Label>

          <div className="flex items-end justify-start gap-2">
            <div className="flex w-96 flex-col">
              <Label>{t('main:manageMenu.manageAssetForecast.lblConfiguration')}</Label>
              <Controller
                control={control}
                name="masterConfigurationId"
                render={({ field: { onChange, value, ref: _ref, ...rest } }) => (
                  <MasterConfigurationsSelect
                    aria-label={t('main:manageMenu.manageAssetForecast.lblConfiguration')}
                    className="mb-3"
                    isDisabled={isDisabled}
                    isFullWidth
                    onChange={async (key) => {
                      onChange(key);
                      await handleChangeConfig(key as string);
                    }}
                    publicFacilityId={publicFacilityId}
                    size="sm"
                    value={value}
                    {...rest}
                  />
                )}
              />

              <div className="inline-flex">
                <Button
                  className="w-100 mt-2"
                  disabled={isDisabled}
                  onClick={createAssetForecast}
                  size="md"
                  variant="primary"
                >
                  {t('main:manageMenu.manageAssetForecast.btnCreateAssets')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
};

export const AssetForecastPage = bindControllers(AssetForecastView, [useController]);
AssetForecastPage.displayName = 'AssetForecastPage';
