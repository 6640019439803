import type { CellClassParams } from 'ag-grid-community';

import { getCellClasses as baseGetCellClasses } from '@org/utils';

export const getCellClasses = (params: CellClassParams) => {
  if (params.data?.disableRowFormatting) {
    return [];
  }

  return baseGetCellClasses(({ node }) => (node.level === 1 ? 'font-bold' : ''))(params);
};
