import type { Collection, Node } from '@react-types/shared';

import { getChildNodes } from '@react-stately/collections';
import { ListCollection } from '@react-stately/list';

type FilterFn = (textValue: string, inputValue: string) => boolean;

function filterNodes<T>(
  collection: Collection<Node<T>>,
  nodes: Iterable<Node<T>>,
  inputValue: string,
  filter: FilterFn,
): Iterable<Node<T>> {
  const filteredNode = [];
  for (const rootNode of nodes) {
    if (rootNode.type === 'section' && rootNode.hasChildNodes) {
      const filtered = filterNodes(
        collection,
        getChildNodes(rootNode, collection),
        inputValue,
        filter,
      );
      if ([...filtered].some((node) => node.type === 'item')) {
        filteredNode.push({ ...rootNode, childNodes: filtered });
      }
    } else if (rootNode.type === 'item' && filter(rootNode.textValue, inputValue)) {
      filteredNode.push({ ...rootNode });
    } else if (rootNode.type !== 'item') {
      filteredNode.push({ ...rootNode });
    }
  }
  return filteredNode;
}

export function filterCollection<T extends object>(
  collection: Collection<Node<T>>,
  inputValue: string,
  filter: FilterFn,
): Collection<Node<T>> {
  return new ListCollection(filterNodes(collection, collection, inputValue, filter));
}
