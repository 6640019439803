import type { ColDef } from 'ag-grid-community';
import type { FC } from 'react';

import { useTranslation } from '@org/locales';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ViewProps } from './useController';
import { useController } from './useController';

const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
  resizable: true,
  sortable: false,
};

const View: FC<ViewProps> = (props) => {
  const { rowData, modal, colDef, gridRef, updateLabels } = props;
  const { t } = useTranslation();

  return (
    <>
      <h6 className="my-3">{t('main:manageMenu.manageCustomLabels.tableTitle')}</h6>
      <div className="mt-2.5 h-full flex-1">
        <BaseEditTable
          columnDefs={colDef}
          defaultColDef={defaultColDef}
          onChange={updateLabels}
          ref={gridRef}
          rowData={rowData}
          useAutoGeneratedRowId
        />
        {modal}
      </div>
    </>
  );
};

export const CustomLabels = bindControllers(View, [useController]);
