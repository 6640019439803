import { useCallback } from 'react';

import type { APIParams } from '../../..';
import type { MasterConfiguration, YearDTOV2 } from '../../dice';
import { useCalculateReports } from '../../rave';

export interface UseRecalculateReportProps
  extends APIParams<
    'publicFacilityId' | 'clientId' | 'yearId' | 'type' | 'masterConfigurationId'
  > {}

export const useRecalculateReport = (params: UseRecalculateReportProps) => {
  const { mutateAsync: recalculateReportMutation, isPending: isRecalculating } =
    useCalculateReports();

  const recalculateReport = useCallback(
    async ({
      year,
      masterConfiguration,
    }: {
      year?: YearDTOV2;
      masterConfiguration?: MasterConfiguration;
    }) => {
      if (!year?.id) {
        throw new Error('Year is required for recalculation');
      }

      await recalculateReportMutation({
        body: {
          masterConfiguration,
          yearId: params.yearId,
        },
      });
    },
    [params.yearId, recalculateReportMutation],
  );

  return {
    isRecalculating,
    recalculateReport,
  };
};
