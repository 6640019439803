import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useTranslation } from '@org/locales';

export const useColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        cellRenderer: 'agGroupCellRenderer',
        field: 'group',
        flex: 1,
        headerName: t('main:manageMenu.priceSheet.tableColumns.description'),
        hide: true,
        rowGroup: true,
      },
      {
        field: 'subGroup',
        flex: 1,
        headerName: t('main:manageMenu.priceSheet.tableColumns.description'),
        hide: true,
        rowGroup: true,
      },
    ],
    [t],
  ) satisfies (ColDef | ColGroupDef)[];
};
