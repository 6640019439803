export type ErrorWrapper<TError> =
  | TError
  | {
      status: number;
      statusText: 'unknown' | (string & {});
      payload: string;
    };

export interface FetcherOptions<TBody, THeaders, TQueryParams, TPathParams> {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {},
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) {
    query = `?${query}`;
  }
  return url.replaceAll(/{\w*}/g, (key) => pathParams[key.slice(1, -1)]!) + query;
};

/**
 * @internal
 */
export const buildFetcher =
  (baseUrl: string) =>
  async <
    TData,
    TError,
    TBody extends {} | undefined | null,
    THeaders extends {},
    TQueryParams extends {},
    TPathParams extends {},
  >({
    url,
    method,
    body,
    headers,
    pathParams,
    queryParams,
    signal,
  }: FetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> => {
    let finalBody: string | FormData | undefined;
    let contentType: string | undefined = 'application/json';
    if (body instanceof FormData) {
      finalBody = body;
      contentType = undefined;
    } else if (body) {
      finalBody = JSON.stringify(body);
    }
    const pIAM_authorization_token = sessionStorage.getItem('pIAM_authorization_token');
    const pIAM_id_token = sessionStorage.getItem('pIAM_id_token');
    const response = await globalThis.fetch(
      `${baseUrl}${resolveUrl(url, queryParams, pathParams)}`,
      {
        body: finalBody,
        headers: {
          ...(contentType && { 'Content-Type': contentType }),
          ...headers,
          Authorization: pIAM_authorization_token ?? '',
          param: pIAM_id_token ?? '',
        },
        method: method.toUpperCase(),
        signal,
      },
    );
    if (!response.ok) {
      let error: ErrorWrapper<TError>;
      try {
        if (response.headers.get('content-type')?.includes('json')) {
          error = await response.json();
        } else {
          error = {
            payload: await response.text(),
            status: response.status,
            statusText: response.statusText,
          };
        }
      } catch (error_) {
        error = {
          payload:
            error_ instanceof Error ? `Unexpected error (${error_.message})` : 'Unexpected error',
          status: response.status,
          statusText: 'unknown' as const,
        };
      }

      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw error;
    }

    if (response.headers.get('content-type')?.includes('json')) {
      return await response.json();
    } else {
      // if it is not a json response, assume it is a blob and cast it to TData
      return (await response.blob()) as unknown as TData;
    }
  };
