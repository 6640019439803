import { useCallback } from 'react';

import {
  addCostCenterToMasterConfiguration,
  deleteCostCenterFromMasterConfiguration,
  updateCostCenterInMasterConfiguration,
} from '@org/utils';

import type { APIParams } from '../..';
import type { CostCenter, MasterConfiguration } from '../dice';
import {
  useGetAllAcctIdToCostCenterNameMap,
  useGetFlattenedProfitCenterTrees,
  useGetTransactionsByFileId,
} from '../griddy';
import { useMasterConfiguration } from './useMasterConfiguration';

interface UseUpdateCostCentersParams extends APIParams<'yearId' | 'masterConfigurationId'> {}

export const useUpdateCostCenters = (params: UseUpdateCostCentersParams) => {
  const { yearId } = params;
  const { masterConfiguration } = useMasterConfiguration(params);
  const { data: accountCostCentersMap } = useGetAllAcctIdToCostCenterNameMap({
    queryParams: {
      fileType: 'JOURNAL',
      yearId,
    },
  });
  const { data: flatProfitCenterTree } = useGetFlattenedProfitCenterTrees({
    queryParams: {
      fileType: 'JOURNAL',
      yearId,
    },
  });

  const { data: profitCenterTrees } = useGetTransactionsByFileId({
    queryParams: {
      fileType: 'JOURNAL',
      yearId,
    },
  });

  const addCostCenter = useCallback(
    (newRow: CostCenter, customMasterConfiguration?: MasterConfiguration) =>
      addCostCenterToMasterConfiguration(
        newRow,
        (customMasterConfiguration ?? masterConfiguration ?? {}) as MasterConfiguration,
        accountCostCentersMap ?? {},
        flatProfitCenterTree ?? {},
      ),
    [masterConfiguration, accountCostCentersMap, flatProfitCenterTree],
  );

  const updateCostCenter = useCallback(
    (newRow: CostCenter | undefined, customMasterConfiguration?: MasterConfiguration) =>
      updateCostCenterInMasterConfiguration(
        newRow ?? {},
        (customMasterConfiguration ?? masterConfiguration ?? {}) as MasterConfiguration,
        accountCostCentersMap ?? {},
        flatProfitCenterTree ?? {},
      ),
    [accountCostCentersMap, flatProfitCenterTree, masterConfiguration],
  );

  const deleteCostCenter = useCallback(
    (rowToDelete: CostCenter, customMasterConfiguration?: MasterConfiguration) =>
      deleteCostCenterFromMasterConfiguration(
        rowToDelete,
        (customMasterConfiguration ?? masterConfiguration ?? {}) as MasterConfiguration,
        accountCostCentersMap ?? {},
        flatProfitCenterTree ?? {},
      ),
    [masterConfiguration, accountCostCentersMap, flatProfitCenterTree],
  );

  return {
    accountCostCentersMap,
    addCostCenter,
    deleteCostCenter,
    flatProfitCenterTree,
    profitCenterTrees,
    updateCostCenter,
  };
};
