import { useMemo } from 'react';

import { difference } from 'lodash';

import type { dice } from '@org/query';
import { CheckboxCellRenderer } from '@org/ui';

interface EntryHeaderCheckboxProps {
  preselectedAllocations?: dice.AllocationConfigPreselectedValuesDTO;
  otherMergedAllocations: dice.AllocationBasisConfigEntryDTO;
  selectedAllocationBasis?: dice.AllocationBasisConfigEntryDTO;
  handleUpdateEntry: (entry: dice.AllocationBasisConfigEntryDTO) => Promise<void>;
  basisType: dice.AllocationKeyConfigDTO['basisType'];
  rankOfCC?: number;
}

export const SelectAllCheckbox = ({
  preselectedAllocations,
  otherMergedAllocations,
  selectedAllocationBasis,
  handleUpdateEntry,
  basisType,
  rankOfCC,
}: EntryHeaderCheckboxProps) => {
  const differenceInEntries = useMemo(() => {
    const localPreselectedAllocations = getLocalPreselectedAllocations(
      basisType,
      preselectedAllocations ?? {},
      rankOfCC ?? 0,
    );

    const fullAllocationEntry = getFullAllocationEntry(localPreselectedAllocations);
    return findDifference(fullAllocationEntry, otherMergedAllocations);
  }, [basisType, otherMergedAllocations, preselectedAllocations, rankOfCC]);

  const handleOnEdit = async (isChecked: boolean) => {
    if (isChecked) {
      return await handleUpdateEntry({
        ...selectedAllocationBasis,
        ...differenceInEntries,
      });
    }

    const { costTypeEntry, revenueTypeEntry } = defaultEntries;

    return await handleUpdateEntry({
      ...selectedAllocationBasis,
      costTypeEntry,
      revenueTypeEntry,
    });
  };

  const { costTypeEntry, revenueTypeEntry } = selectedAllocationBasis ?? {};

  const isSomeSelected = useMemo(() => {
    let entries;
    if (basisType === 'ACCOUNT') {
      entries = [
        costTypeEntry?.allocationBasisCalculatedCost,
        costTypeEntry?.allocationBasisCostCenters,
        revenueTypeEntry?.allocationBasisCalculatedCost,
        revenueTypeEntry?.allocationBasisCostCenters,
        costTypeEntry?.allocationBasisAccountIds,
        revenueTypeEntry?.allocationBasisAccountIds,
      ];
    } else {
      entries = [
        costTypeEntry?.allocationBasisCalculatedCost,
        costTypeEntry?.allocationBasisCostCenters,
        revenueTypeEntry?.allocationBasisCalculatedCost,
        revenueTypeEntry?.allocationBasisCostCenters,
        costTypeEntry?.allocationBasisCostTypeNames,
        revenueTypeEntry?.allocationBasisCostTypeNames,
      ];
    }

    return entries.some((array) => Array.isArray(array) && array.length > 0);
  }, [costTypeEntry, revenueTypeEntry, basisType]);

  const typeEntryByBasisType =
    basisType === 'ACCOUNT' ? 'allocationBasisAccountIds' : 'allocationBasisCostTypeNames';

  const isAllRevenueSelected =
    Object.values(differenceInEntries.revenueTypeEntry?.[typeEntryByBasisType] ?? [])
      .sort()
      .toString() ===
      Object.values(selectedAllocationBasis?.revenueTypeEntry?.[typeEntryByBasisType] ?? [])
        .sort()
        .toString() &&
    Object.values(differenceInEntries.revenueTypeEntry?.allocationBasisCalculatedCost ?? [])
      .sort()
      .toString() ===
      Object.values(selectedAllocationBasis?.revenueTypeEntry?.allocationBasisCalculatedCost ?? [])
        .sort()
        .toString() &&
    Object.values(differenceInEntries.revenueTypeEntry?.allocationBasisCostCenters ?? [])
      .sort()
      .toString() ===
      Object.values(selectedAllocationBasis?.revenueTypeEntry?.allocationBasisCostCenters ?? [])
        .sort()
        .toString();

  const isAllCostSelected =
    Object.values(differenceInEntries.costTypeEntry?.[typeEntryByBasisType] ?? [])
      .sort()
      .toString() ===
      Object.values(selectedAllocationBasis?.costTypeEntry?.[typeEntryByBasisType] ?? [])
        .sort()
        .toString() &&
    Object.values(differenceInEntries.costTypeEntry?.allocationBasisCalculatedCost ?? [])
      .sort()
      .toString() ===
      Object.values(selectedAllocationBasis?.costTypeEntry?.allocationBasisCalculatedCost ?? [])
        .sort()
        .toString() &&
    Object.values(differenceInEntries.costTypeEntry?.allocationBasisCostCenters ?? [])
      .sort()
      .toString() ===
      Object.values(selectedAllocationBasis?.costTypeEntry?.allocationBasisCostCenters ?? [])
        .sort()
        .toString();

  return (
    <CheckboxCellRenderer
      indeterminate={isSomeSelected}
      onEdit={handleOnEdit}
      value={isAllRevenueSelected && isAllCostSelected}
    />
  );
};

const getFullAllocationEntry = (
  localPreselectedAllocations?: dice.AllocationConfigPreselectedValuesDTO,
): dice.AllocationBasisConfigEntryDTO => {
  const {
    expenseAccounts,
    calculatedCosts,
    calculatedRevenues,
    expenseCostTypeAccounts,
    revenueAccounts,
    costCenters,
    revenueCostTypeAccounts,
  } = localPreselectedAllocations ?? {};
  return {
    costTypeEntry: {
      allocationBasisAccountIds: expenseAccounts?.map((account) => account.accountId ?? ''),
      allocationBasisCalculatedCost: calculatedCosts,
      allocationBasisCostCenters: costCenters?.map((cc) => cc.longName ?? ''),
      allocationBasisCostTypeNames: expenseCostTypeAccounts?.map((acc) => acc.name ?? ''),
      allocationBasisConfigType: 'COST',
    },
    revenueTypeEntry: {
      allocationBasisAccountIds: revenueAccounts?.map((account) => account.accountId ?? ''),
      allocationBasisCalculatedCost: calculatedRevenues,
      allocationBasisCostCenters: costCenters?.map((cc) => cc.longName ?? ''),
      allocationBasisCostTypeNames: revenueCostTypeAccounts?.map((acc) => acc.name ?? ''),
      allocationBasisConfigType: 'REVENUES',
    },
  };
};

const defaultEntries: {
  costTypeEntry?: dice.AllocationBasisConfigTypeEntryDTO;
  revenueTypeEntry?: dice.AllocationBasisConfigTypeEntryDTO;
} = {
  costTypeEntry: {
    allocationBasisAccountIds: [],
    allocationBasisCalculatedCost: [],
    allocationBasisCostCenters: [],
    allocationBasisCostTypeNames: [],
    allocationBasisConfigType: 'COST',
  },
  revenueTypeEntry: {
    allocationBasisAccountIds: [],
    allocationBasisCalculatedCost: [],
    allocationBasisCostCenters: [],
    allocationBasisCostTypeNames: [],
    allocationBasisConfigType: 'REVENUES',
  },
};

function findDifference(
  full: dice.AllocationBasisConfigEntryDTO,
  partial: dice.AllocationBasisConfigEntryDTO,
): dice.AllocationBasisConfigEntryDTO {
  return {
    costTypeEntry: {
      allocationBasisAccountIds: difference(
        full?.costTypeEntry?.allocationBasisAccountIds,
        partial?.costTypeEntry?.allocationBasisAccountIds ?? [],
      ),

      allocationBasisCalculatedCost: difference(
        full?.costTypeEntry?.allocationBasisCalculatedCost,
        partial?.costTypeEntry?.allocationBasisCalculatedCost ?? [],
      ),
      allocationBasisCostCenters: difference(
        full?.costTypeEntry?.allocationBasisCostCenters,
        partial?.costTypeEntry?.allocationBasisCostCenters ?? [],
      ),

      allocationBasisCostTypeNames: difference(
        full?.costTypeEntry?.allocationBasisCostTypeNames,
        partial?.costTypeEntry?.allocationBasisCostTypeNames ?? [],
      ),
      allocationBasisConfigType: 'COST',
    },
    revenueTypeEntry: {
      allocationBasisAccountIds: difference(
        full?.revenueTypeEntry?.allocationBasisAccountIds,
        partial?.revenueTypeEntry?.allocationBasisAccountIds ?? [],
      ),

      allocationBasisCalculatedCost: difference(
        full?.revenueTypeEntry?.allocationBasisCalculatedCost,
        partial?.revenueTypeEntry?.allocationBasisCalculatedCost ?? [],
      ),
      allocationBasisCostCenters: difference(
        full?.revenueTypeEntry?.allocationBasisCostCenters,
        partial?.revenueTypeEntry?.allocationBasisCostCenters ?? [],
      ),

      allocationBasisCostTypeNames: difference(
        full?.revenueTypeEntry?.allocationBasisCostTypeNames,
        partial?.revenueTypeEntry?.allocationBasisCostTypeNames ?? [],
      ),
      allocationBasisConfigType: 'REVENUES',
    },
  };
}

const getLocalPreselectedAllocations = (
  basisType: dice.AllocationKeyConfigDTO['basisType'],
  preselectedAllocations: dice.AllocationConfigPreselectedValuesDTO,
  rankOfCC: number,
) => {
  if (basisType === 'ACCOUNT') {
    return {
      ...preselectedAllocations,
      costCenters: preselectedAllocations?.costCenters?.filter((cc) => (cc?.rank ?? 0) < rankOfCC),
      expenseCostTypeAccounts: [],
      revenueCostTypeAccounts: [],
    };
  }

  if (basisType === 'COST_TYPE_ACCOUNT') {
    return {
      ...preselectedAllocations,
      costCenters: preselectedAllocations?.costCenters?.filter((cc) => (cc?.rank ?? 0) < rankOfCC),
      expenseAccounts: [],
      revenueAccounts: [],
    };
  }
};
