import type { FC } from 'react';

import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const { gridRef, onGridReady, defaultColDef, columnDefs, getRowId } = props;

  return (
    <BaseEditTable
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      onGridReady={onGridReady}
      ref={gridRef}
      rowSelection="multiple"
    />
  );
};

export const ImputedCostCenterTable = bindControllers(View, [useController]);
ImputedCostCenterTable.displayName = 'ImputedCostCenterTable';
