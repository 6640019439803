import type { dice } from '@org/query';
import { useTranslation } from '@org/locales';
import { CheckboxCellRenderer } from '@org/ui';

interface AccountTableCheckboxProps {
  data: {
    key: string;
    accountType: string;
    label: string;
  };
  value: string | number;
  selectedAllocationBasis?: dice.AllocationBasisConfigEntryDTO;
  otherMergedAllocations?: dice.AllocationBasisConfigEntryDTO;
  handleUpdateEntry: (entry: dice.AllocationBasisConfigEntryDTO) => Promise<void>;
}

export const AccountTableCheckbox = ({
  data,
  value,
  selectedAllocationBasis,
  otherMergedAllocations,
  handleUpdateEntry,
}: AccountTableCheckboxProps) => {
  const { t } = useTranslation();
  if (!selectedAllocationBasis) {
    return null;
  }

  const { accountType, key, label } = data;

  const type = accountType === 'EXPENSE' ? 'costTypeEntry' : 'revenueTypeEntry';

  const entryPropName = getPropNameByKey(key);

  const collection = (selectedAllocationBasis[type]?.[entryPropName] ?? []) as string[];
  const isSelected = collection.includes(label as string);

  const otherCollection = (otherMergedAllocations?.[type]?.[entryPropName] ?? []) as string[];

  const isSelectedInOther = otherCollection.includes(label as string);

  const handleOnEdit = async (isChecked: boolean) => {
    const newValue = isChecked
      ? [...(collection || []), label]
      : (collection || []).filter((v: string) => v !== label);

    const copy: dice.AllocationBasisConfigEntryDTO = {
      ...selectedAllocationBasis,
      [type]: {
        ...(selectedAllocationBasis[type] ?? []),
        [entryPropName]: newValue,
      },
    };

    await handleUpdateEntry(copy);
  };

  const checkboxLabel =
    entryPropName === 'allocationBasisCalculatedCost'
      ? t(`main:reportPanels.babReport.tableRows.${value.toString()}`)
      : value.toString();

  return (
    <div className="ml-8">
      <CheckboxCellRenderer
        disabled={isSelectedInOther && !isSelected}
        label={checkboxLabel}
        onEdit={handleOnEdit}
        value={isSelectedInOther || isSelected}
      />
    </div>
  );
};

const getPropNameByKey = (key: string) => {
  if (key === 'calculatedCosts' || key === 'calculatedRevenues') {
    return 'allocationBasisCalculatedCost';
  }

  if (key === 'revenueAccounts' || key === 'expenseAccounts') {
    return 'allocationBasisAccountIds';
  }

  if (key === 'revenueCostTypeAccounts' || key === 'expenseCostTypeAccounts') {
    return 'allocationBasisCostTypeNames';
  }

  if (key === 'costCenters') {
    return 'allocationBasisCostCenters';
  }

  return 'allocationBasisCostCenters';
};
