import { formatNumberToLocaleString } from './formatNumberToLocaleString';

export interface FormatIntegerToLocaleStringOptions extends Intl.NumberFormatOptions {
  defaultValue?: string;
}

export const formatIntegerToLocaleString = (
  value?: number | string | null,
  options: FormatIntegerToLocaleStringOptions = {},
) =>
  formatNumberToLocaleString(value, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  });
