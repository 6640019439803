import { useState } from 'react';

import type { Key } from '@org/design';
import { MasterConfigurationsSelect } from '@org/features';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import type { ModalContentProps } from '..';

export const CopyConfigurationModalContent = ({
  disclosureState,
  publicFacilityId,
  yearId,
}: ModalContentProps) => {
  const { configurations } = aggregated.useGetAllConfigurations({
    publicFacilityId,
  });

  const [value, setValue] = useState<Key | null>(null);
  const { copyMasterConfig, copyConfigMutation } =
    aggregated.useMasterConfigurationByYearCalculation({
      yearCalculationId: yearId,
    });

  const { t } = useTranslation();

  const { isOpen, onClose } = disclosureState;
  const copyPreviousYear = async () => {
    if (!isOpen) {
      return;
    }

    const toMasterConfigId = value as string;

    let masterConfigurationYearCalculationId;
    configurations.forEach((year) => {
      const allConfigurationTypes = [...year.configs];
      const selectedConfig = allConfigurationTypes.find((c) => c.id === toMasterConfigId);
      if (selectedConfig) {
        masterConfigurationYearCalculationId = selectedConfig.yearCalculationId;
        return;
      }
    });

    if (!masterConfigurationYearCalculationId) {
      return;
    }

    try {
      await copyMasterConfig({
        id: toMasterConfigId,
        yearCalculationId: masterConfigurationYearCalculationId,
      });
      onClose();
    } catch {
      showNotification('warning', t('main:clients.clientsOverviewPanel.alerts.warning.general'));
    }
  };
  return (
    <>
      <ModalHeader>{t('main:manageMenu.configurationPage.copyDataFromPreviousYear')}</ModalHeader>
      <ModalBody>
        <MasterConfigurationsSelect
          className="w-full"
          onChange={setValue}
          publicFacilityId={publicFacilityId}
          value={value}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          color="danger"
          onClick={onClose}
          size="md"
          variant="secondary"
        >
          {t('common:close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={copyConfigMutation.isPending}
          onClick={copyPreviousYear}
          size="md"
          variant="primary"
        >
          {t('common:create')}
        </Button>
      </ModalFooter>
    </>
  );
};
