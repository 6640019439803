import type { TFunction } from '@org/locales';
import type { aggregated, dice } from '@org/query';

import { getErrorMessage } from '../getErrorMessage';
import { showNotification } from '../showNotification';

export function showErrorCamsNotification(t: TFunction, data: dice.ImportFileResponse) {
  try {
    // NOTE: BE returns HTTP STATUS OK - error is in the body
    if (data?.xInternalErrorCode !== 200 && data?.xInternalErrorCode) {
      const errMessage = data?.errorType
        ? t(`common:modals.camsImport.errors.${data?.errorType}`)
        : t('common:modals.camsImport.error');
      showNotification('error', errMessage, 5000);
    } else {
      showNotification('success', t('common:modals.camsImport.success'));
    }
  } catch (error_) {
    const error = error_ as unknown as aggregated.ImportErrorType;

    showNotification(
      'error',
      t('common:modals.camsImport.error', {
        msg: getErrorMessage(error, t),
      }),
      5000,
    );
  }
}
