import type { TFunction } from '@org/locales';
import type { dice } from '@org/query';
import { CostUnitAllocationRowType } from '@org/models';

interface Allocation {
  data?:
    | dice.AccountDTO
    | dice.AllocationConfigCostTypeAccountDTO
    | dice.AllocationConfigCostCenterDTO
    | string;
  label?: string | number;
  group?: string;
  subGroup?: string;
  accountType?: string;
  key?: string;
  description: string;
}

interface FlattenAllocationsParams {
  nestedAllocations?: dice.AllocationConfigPreselectedValuesDTO;
  rankOfCC?: number;
  basisType: dice.AllocationKeyConfigDTO['basisType'];
  t: TFunction;
}

export const flattenAllocations = ({
  nestedAllocations,
  rankOfCC,
  basisType,
  t,
}: FlattenAllocationsParams) => {
  const allocations: Allocation[] = [];

  if (!nestedAllocations || !rankOfCC) {
    return allocations;
  }

  Object.entries(nestedAllocations).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    if (basisType === 'ACCOUNT' && (key === 'revenueAccounts' || key === 'expenseAccounts')) {
      value.forEach((item: dice.AccountDTO) => {
        const { accountId, accountType, accountDescription } = item;

        allocations.push({
          accountType,
          data: item,
          description: `${accountId} - ${accountDescription}`,
          group:
            accountType === CostUnitAllocationRowType.EXPENSE
              ? t('main:manageMenu.manageAllocationKeys.cost')
              : t('main:manageMenu.manageAllocationKeys.revenue'),
          key,
          label: accountId ?? '',
          subGroup: t('main:manageMenu.manageAllocationKeys.accounts'),
        });
      });
    }

    if (
      basisType === 'COST_TYPE_ACCOUNT' &&
      (key === 'revenueCostTypeAccounts' || key === 'expenseCostTypeAccounts')
    ) {
      value.forEach((item: dice.AllocationConfigCostTypeAccountDTO) => {
        const { name, type } = item;

        allocations.push({
          accountType: type,
          data: item,
          description: name ?? '',
          group:
            type === CostUnitAllocationRowType.EXPENSE
              ? t('main:manageMenu.manageAllocationKeys.cost')
              : t('main:manageMenu.manageAllocationKeys.revenue'),
          key,
          label: name,
          subGroup: t('main:manageMenu.manageAllocationKeys.costTypeAccounts'),
        });
      });
    }

    if (key === 'costCenters') {
      value.forEach((item: dice.AllocationConfigCostCenterDTO) => {
        if ((item?.rank ?? 0) < rankOfCC) {
          const baseCostCenter = {
            data: item,
            description: item.longName ?? '',
            key,
            label: item.longName,
            subGroup: t('main:manageMenu.allocationKeysConfiguration.allocationTableHeader'),
          };

          allocations.push(
            {
              ...baseCostCenter,
              accountType: CostUnitAllocationRowType.EXPENSE,
              group: t('main:manageMenu.manageAllocationKeys.cost'),
            },
            {
              ...baseCostCenter,
              accountType: CostUnitAllocationRowType.REVENUE,
              group: t('main:manageMenu.manageAllocationKeys.revenue'),
            },
          );
        }
      });
    }

    if (key === 'calculatedCosts') {
      value.forEach((item: string) => {
        allocations.push({
          accountType: CostUnitAllocationRowType.EXPENSE,
          data: item,
          description: item || '',
          group: t('main:manageMenu.manageAllocationKeys.cost'),
          key,
          label: item,
          subGroup: t('main:manageMenu.manageAllocationKeys.lblImputedCosts'),
        });
      });
    }

    if (key === 'calculatedRevenues') {
      value.forEach((item: string) => {
        allocations.push({
          accountType: CostUnitAllocationRowType.REVENUE,
          data: item,
          description: item || '',
          group: t('main:manageMenu.manageAllocationKeys.revenue'),
          key,
          label: item,
          subGroup: t('main:manageMenu.manageAllocationKeys.lblImputedCosts'),
        });
      });
    }
  });

  return allocations;
};
