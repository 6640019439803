import type { CellValueChangedEvent, ColDef, RowDragEvent } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { dice, griddy } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useAgGridData } from '@org/hooks';
import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal, useMasterDetailState } from '@org/ui';
import {
  addToArrayAtIndexMut,
  getCopyOfTableData,
  isStringEmptyOrSpaces,
  removeFromArrayAtIndexMut,
} from '@org/utils';

import type { BaseControllerType } from '../types';
import { createRow } from './helpers';
import { useColumns } from './useColumns';

export type UseControllerProps = BaseControllerType;

const defaultColDef = {
  flex: 1,
  sortable: false,
} satisfies ColDef;

export const useController = (apiParams: UseControllerProps) => {
  const { masterConfigurationId: configId, yearId } = apiParams;
  const { t } = useTranslation();
  const tableRef = useRef<AgGridReact>(null);
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const { onGridReady } = useAgGridData({
    agGridRef: tableRef,
    data: masterConfiguration?.costUnitConfig,
  });

  const { setTableContext, tableContext } = useMasterDetailState();

  const onCellValueChange = useCallback(
    async (event: CellValueChangedEvent<griddy.CostUnit & { isNew?: boolean }>) => {
      const colName = event.colDef.field;

      if (!colName) {
        return;
      }

      const {
        data,
        newValue: { isValid = true, ...selectRevenueAccountsConfiguration },
      } = event;

      const { isNew, ...otherData } = data;

      if (isNew && masterConfiguration?.costUnitConfig) {
        if (isStringEmptyOrSpaces(data.longName)) {
          showNotification('error', t('common:alerts.error.notAllFieldsFilled'));
          return;
        }

        await updateMasterConfiguration({
          ...masterConfiguration,
          costUnitConfig: [...masterConfiguration?.costUnitConfig, otherData],
        });
      } else {
        const isCostCenterMapping = colName === 'selectRevenueAccountsConfiguration';
        let newRowData = otherData;
        if (isCostCenterMapping) {
          if (!isValid) {
            return;
          }
          newRowData = { ...otherData, selectRevenueAccountsConfiguration };
        }

        const updatedCostUnitConfig = masterConfiguration?.costUnitConfig?.map((config) =>
          config.shortName === data.shortName ? newRowData : config,
        );

        await updateMasterConfiguration({
          ...masterConfiguration,
          costUnitConfig: updatedCostUnitConfig,
        });
      }
    },
    [masterConfiguration, t, updateMasterConfiguration],
  );

  const deleteRow = useCallback(
    async ({ gridProps: { node, api: gridApi }, data }: ButtonCellRendererProps) => {
      const configAfterDeletion = masterConfiguration?.costUnitConfig.filter(
        (config) => config.shortName !== data.shortName,
      );

      gridApi.applyTransaction({
        remove: [node.data],
      });

      await updateMasterConfiguration({
        ...masterConfiguration,
        costUnitConfig: configAfterDeletion,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const handleDelete = useCallback(
    (btnProps: ButtonCellRendererProps) => {
      deleteRow(btnProps);
    },
    [deleteRow],
  );

  const addNewRow = useCallback(() => {
    const hasNewCreatedRow = tableRef?.current?.api
      .getRenderedNodes()
      .find((item) => item?.data?.isNew);

    if (hasNewCreatedRow) {
      return;
    }

    const newRow = createRow(masterConfiguration?.costUnitConfig);

    tableRef?.current?.api.applyTransaction({
      add: [newRow],
    });
  }, [masterConfiguration]);

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonCellRendererProps>(
    {
      onConfirm: handleDelete,
    },
  );

  const columnDefs = useColumns({ handleDeleteModal, setTableContext, tableContext, yearId });

  const onRowDragEnd = useCallback(
    async (event: RowDragEvent) => {
      const { api } = event;

      const tableData: dice.CostUnit[] = getCopyOfTableData(api);

      await updateMasterConfiguration({
        ...masterConfiguration,
        costUnitConfig: tableData,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const onRowDragMove = useCallback((event: RowDragEvent) => {
    const { api, nodes, overNode } = event;

    const movingNodes = nodes;

    if (!overNode) {
      return;
    }

    const overData = overNode?.data;
    const movingDataRows = movingNodes.map((node) => node.data);
    const rowsHaveSameShortName = movingDataRows.some((cc) => cc.shortName === overData?.shortName);

    // DO NOTHING - when dragging over same node
    if (rowsHaveSameShortName) {
      return;
    }

    const allNodesData: dice.CostUnit[] = getCopyOfTableData(api);

    const INVALID_INDEX = -1;

    let fromIndex = INVALID_INDEX;

    movingDataRows.forEach((movingDataRow) => {
      fromIndex = allNodesData.findIndex(
        (nodeData) => nodeData.shortName === movingDataRow.shortName,
      );
      removeFromArrayAtIndexMut(allNodesData, fromIndex);
    });

    const toIndex = allNodesData.findIndex((nodeData) => nodeData.shortName === overData.shortName);

    const areAddAndRemoveIndexesValid = toIndex > INVALID_INDEX && fromIndex > INVALID_INDEX;

    // DO NOTHING - when invalid indexes
    if (!areAddAndRemoveIndexesValid) {
      return;
    }

    if (fromIndex > toIndex) {
      addToArrayAtIndexMut(allNodesData, movingDataRows, toIndex);
    } else {
      /*
        orig rows: [0 -> to 2, 1, 2]
        from: 0, [1, 2] (indexes: [0, 1])
        to: 1 (using modified array) -> should be 2 ( -> toIndex + 1)
       */
      addToArrayAtIndexMut(allNodesData, movingDataRows, toIndex + 1);
    }

    const updatedRankCCs = allNodesData.map((node, index) => {
      node.rank = index + 1;
      return node;
    });
    tableRef.current?.api.setGridOption('rowData', updatedRankCCs);
    api?.clearFocusedCell();
  }, []);

  const detailCellRendererParams = useMemo(
    () => ({
      apiParams,
    }),
    [apiParams],
  );

  return {
    addNewRow,
    apiParams,
    columnDefs,
    configId,
    defaultColDef,
    detailCellRendererParams,
    handleDelete,
    handleDeleteModal,
    isLoading: false,
    modal,
    onCellValueChange,
    onGridReady,
    onRowDragEnd,
    onRowDragMove,
    tableContext,
    tableRef,
    yearId,
  };
};

export type ControllerType = ReturnType<typeof useController>;
