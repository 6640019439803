import { Link, useParams } from 'react-router-dom';

import { PublicFacilitiesOverview as Page } from '@org/pages';

export const PublicFacilitiesOverviewPage = () => {
  const { clientId } = useParams<{
    clientId: string;
  }>();

  return (
    <Page
      LinkComponent={Link}
      clientId={clientId!}
      getLinkProps={(facilityId) => ({ to: `/client/${clientId}/${facilityId}` })}
    />
  );
};
