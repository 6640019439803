import PropTypes, { oneOfType } from 'prop-types';

import { ListItemWrapper } from '../style/ListItemWrapper';
import { StyledDescription } from '../style/StyledDescription';

const TableItemsWithDescriptionList = ({ items = [], getDescription, descWidth }) =>
  items.sort().map((val, idx) => (
    <ListItemWrapper key={idx}>
      <span className="d-flex">
        <span>{val}</span>
        {val && getDescription && (
          <StyledDescription
            title={val}
            width={descWidth}
          >
            {getDescription(val)}
          </StyledDescription>
        )}
      </span>
    </ListItemWrapper>
  ));

TableItemsWithDescriptionList.propTypes = {
  getDescription: PropTypes.func,
  items: PropTypes.arrayOf(oneOfType([PropTypes.string, PropTypes.number])),
};

export default TableItemsWithDescriptionList;
