import { createContext, useState } from 'react';

import config from '../config';
import { sendGet, sendPost } from '../utils/apiCalls';
import Logger from '../utils/Logger';

const UserStateContext = createContext();

function UserStateProvider(props) {
  const [currentUser, setCurrentUser] = useState(null);
  const userDts = sessionStorage.getItem('UserDts');

  const fetchCurrentUser = async () => {
    try {
      const pIAMToken = sessionStorage.getItem('pIAM_authorization_token');
      if (pIAMToken !== null && pIAMToken !== undefined) {
        const data = await sendGet(`${config.PROXY_SAFE_PATH}${config.PROXY_CURRENT_USER_DETAILS}`);
        if (data) {
          setCurrentUser(data);
          sessionStorage.setItem('UserDts', JSON.stringify(data));
          if (!userDts) {
            await sendPost(`${config.PROXY_GRIDDY_PATH}${config.GRIDDY_USERS_PATH}`, {
              ...data,
              email: data.username,
            });
          }
        }
      }
    } catch (error) {
      Logger.error(error, 'UserState.js');
    }
  };

  if (!currentUser) {
    fetchCurrentUser();
  }

  return (
    <UserStateContext.Provider
      value={{
        currentUser,
      }}
      {...props}
    />
  );
}

export { UserStateContext, UserStateProvider };
