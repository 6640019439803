import { forwardRef } from 'react';

import classnames from 'classnames';

import styles from './TextInput.module.css';

type OuterProps = React.JSX.IntrinsicElements['input'];

export type TextInputProps = {
  inputSize?: 'xs' | 'sm' | 'md' | 'xl';
} & OuterProps;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { className, inputSize = 'sm', disabled = false, ...otherProps } = props;

  return (
    <input
      className={classnames(styles.TextInput, className, 'input', {
        'cursor-not-allowed': disabled,
        'input-md': inputSize === 'md',
        'input-sm': inputSize === 'sm',
        'input-xl': inputSize === 'xl',
        'input-xs': inputSize === 'xs',
      })}
      data-testid="TextInput"
      disabled={disabled}
      ref={ref}
      {...otherProps}
    />
  );
});
