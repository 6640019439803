import { type ChangeEvent } from 'react';

import { useForm } from 'react-hook-form';

import type { griddy } from '@org/query';
import { useDebounce, useEvent } from '@org/hooks';
import { aggregated } from '@org/query';

import type { BaseControllerType } from '../types';

export const DEFAULT_IMPUTED_TRADE_TAX_DATA = {
  accountIds: [],
  rateOfTradeTax: 0,
  tradeTaxIndexValue: 3.5,
};

export type UseControllerProperties = BaseControllerType;

export const useController = (props: UseControllerProperties) => {
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration<griddy.MasterConfiguration>(props);
  const { accountIdSelectOptions, accountIdSelectOptionsByRange } = aggregated.useAccountIds(props);

  const { handleSubmit, control, getValues } = useForm<griddy.ImputedTradeTaxConfig>({
    values: masterConfiguration?.imputedTradeTaxConfig,
  });

  const handleEnableConfigChange = useEvent(
    async ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
      const data = checked ? DEFAULT_IMPUTED_TRADE_TAX_DATA : undefined;
      await saveWithDependencies(data);
    },
  );

  const saveWithDependencies = useDebounce(
    async (updatedTradeTaxConfig: griddy.ImputedTradeTaxConfig | undefined) => {
      await updateMasterConfiguration({
        ...masterConfiguration,
        imputedTradeTaxConfig: updatedTradeTaxConfig,
      });
    },
    1000,
  );

  return {
    accountIdSelectOptions,
    accountIdSelectOptionsByRange,
    control,
    getValues,
    handleDataChange: saveWithDependencies,
    handleEnableConfigChange,
    handleSubmit,
    isEnabled: !!masterConfiguration?.imputedTradeTaxConfig,
  };
};

export type ControllerType = ReturnType<typeof useController>;
