import type { AgGridReact } from 'ag-grid-react';
import type { RefObject } from 'react';
import { useCallback } from 'react';

import { useForm } from 'react-hook-form';

import type { APIParams, dice } from '@org/query';
import { aggregated } from '@org/query';

export interface ControllerProps {
  apiParams: APIParams<'masterConfigurationId'>;
  selectedPlannedDirectEntryId?: string;
  gridRef: RefObject<AgGridReact>;
  selectedPlannedAccountEntry?: dice.PlannedAccountEntryDTO;
  updateAndReplacePlannedCostRevenueDirectEntry: (
    updatedEntry: dice.CostUnitAllocationBasisConfigEntryDTO,
  ) => Promise<void>;
  currentMethodType: dice.PlannedDirectEntryDistributionConfigDTO['allocationMethod'];
}

export type ViewProps = ReturnType<typeof useController>;

export const useController = (props: ControllerProps) => {
  const {
    apiParams,
    updateAndReplacePlannedCostRevenueDirectEntry,
    selectedPlannedAccountEntry,
    selectedPlannedDirectEntryId,
  } = props;

  const { getPlannedDirectEntry, preselectedTechnicalDataOptions } =
    aggregated.usePlannedCostRevenue(apiParams);

  const selectedPlannedDirectEntry = getPlannedDirectEntry({
    plannedAccountEntryId: selectedPlannedAccountEntry?.id ?? '',
    plannedCostTypeAccountEntryId: selectedPlannedDirectEntryId ?? '',
  });

  const technicalData = selectedPlannedDirectEntry?.distributionConfig?.selectedTechnicalData ?? '';

  const { control } = useForm({
    defaultValues: {
      technicalData,
    },
    values: {
      technicalData,
    },
  });

  const handleTechnicalDataSelection = useCallback(
    async (selectedTechnicalData: string | number | null) => {
      const updatedDirectEntry = {
        ...selectedPlannedDirectEntry,
        distributionConfig: {
          ...selectedPlannedDirectEntry?.distributionConfig,
          selectedTechnicalData: selectedTechnicalData as string,
        },
      };

      await updateAndReplacePlannedCostRevenueDirectEntry(updatedDirectEntry);
    },
    [selectedPlannedDirectEntry, updateAndReplacePlannedCostRevenueDirectEntry],
  );

  return {
    control,
    handleTechnicalDataSelection,
    options: preselectedTechnicalDataOptions,
  };
};
