import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { useTranslation } from '@org/locales';
import { BaseEditTable, Label, ToggleInput } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface FutureAssetsRiskProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const FutureAssetsView: FC<FutureAssetsRiskProps> = (props) => {
  const { t } = useTranslation();

  const {
    columnDefs,
    onCellValueChange,
    modal,
    handleActivateFutureAssets,
    activeFutureAssets,
    autoGroupColumnDef,
    tableRef,
    defaultColDef,
    getRowId,
    onGridReady,
  } = props;

  return (
    <div
      className="flex h-full flex-1 flex-col gap-3"
      data-testid="CorporateRisk"
    >
      <Label className="flex cursor-pointer items-stretch gap-2 text-xs">
        <ToggleInput
          checked={activeFutureAssets}
          name={t('main:manageMenu.manageFutureAssets.activate')}
          onChange={handleActivateFutureAssets}
        />
        {t('main:manageMenu.manageFutureAssets.activate')}
      </Label>
      <div className="flex-1">
        <BaseEditTable
          animateRows
          autoGroupColumnDef={autoGroupColumnDef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableGroupEdit
          getRowId={getRowId}
          groupDisplayType="singleColumn"
          isDisabled={!activeFutureAssets}
          isGroupOpenByDefault={() => true}
          onCellValueChanged={onCellValueChange}
          onGridReady={onGridReady}
          ref={tableRef}
          singleClickEdit
        />
      </div>
      {modal}
    </div>
  );
};

export const FutureAssetsPage = bindControllers(FutureAssetsView, [useController]);
FutureAssetsPage.displayName = 'FutureAssetsPage';
