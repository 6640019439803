/**
 * ### Appkit *Select* wrapper ###
 * #### Needed to ensure consistent behavior like 'selectAll' and 'search' would always be present ####
 * 'select all' is always present by default, when 'multiple' selection is possible.
 */
import type { SelectProps } from 'appkit-react';
import type { FC } from 'react';
import React from 'react';

import { Select } from 'appkit-react';

import { useTranslation } from '@org/locales';

export const BaseSelect: FC<SelectProps> = (props) => {
  const { t } = useTranslation();

  const {
    showSelectAll = true,
    showSearchOnToggle = true,
    customSelectAllStringOnList = t('common:selectAll'),
    customSelectAllStringOnToggle = t('common:allSelected'),
    multiple,
  } = props;
  return (
    <Select
      customSelectAllStringOnList={customSelectAllStringOnList}
      customSelectAllStringOnToggle={customSelectAllStringOnToggle}
      showSearchOnToggle={showSearchOnToggle}
      showSelectAll={multiple && showSelectAll}
      {...props}
    />
  );
};
