import { useEffect, useState } from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import { Select, SelectOption } from 'appkit-react';
import styled from 'styled-components';

import { useTranslation } from '@org/locales';
import { NumberInput } from '@org/ui';

import { handleShowAlert } from '../../../utils/Alerts/alertUtils';
import { roundNumber } from '../../../utils/formatUtils';
import { parseToFloat } from '../../../utils/parseUtils';

const StyledInputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  column-gap: 10px;
  align-items: center;

  > :nth-child(1) {
    flex-grow: 1;
  }

  > :nth-child(2) {
    width: 80px;
    flex-shrink: 0;
  }

  > :nth-child(3) {
    width: 24px;
    flex-shrink: 0;
  }
`;

export const WithdrawAllocationInputGroup = ({ value, costCenters, onAdd: onAddParent }) => {
  const { t } = useTranslation();
  const [selectedCostCenter, setSelectedCostCenter] = useState(null); // set default selection from list

  const [allocation, setAllocation] = useState(0);

  const [availableCostCenters, setAvailableCostCenters] = useState([]);

  const onAdd = () => {
    if (parseToFloat(allocation) === null) {
      return;
    }
    if (!selectedCostCenter) {
      return;
    }

    if (allocation <= 0) {
      handleShowAlert(
        'warning',
        t('main:manageMenu.manageWithdrawCapital.alerts.warning.zeroAllocation'),
      );
      return;
    }

    const newAllocation = { ...value, [selectedCostCenter]: allocation };

    onAddParent(newAllocation);
    // Set allocation to the remaining percentage
    setAllocation(
      Math.max(
        0,
        roundNumber(
          100 - Object.values(newAllocation).reduce((c, p) => parseToFloat(c) + parseToFloat(p), 0),
        ),
      ),
    );
  };

  useEffect(() => {
    const updated = costCenters.filter((center) => (value ? !(center in value) : true));
    setAvailableCostCenters(updated);
    if (!updated.includes(selectedCostCenter)) {
      setSelectedCostCenter(updated[0]);
    }
  }, [costCenters, selectedCostCenter, value]);

  return (
    <StyledInputGroup>
      <Select
        onSelect={(val) => setSelectedCostCenter(val)}
        required
        value={selectedCostCenter}
      >
        {availableCostCenters.map((costCenter) => (
          <SelectOption
            key={costCenter}
            value={costCenter}
          >
            {costCenter}
          </SelectOption>
        ))}
      </Select>
      <NumberInput
        max={100}
        min={0}
        onChange={(e) => setAllocation(e)}
        placeholder="100%"
        type="number"
        value={allocation}
      />
      <AddBoxIcon
        onClick={onAdd}
        style={{
          cursor: 'pointer',
          filter: selectedCostCenter ? '' : 'contrast(0%)',
        }}
      />
    </StyledInputGroup>
  );
};
