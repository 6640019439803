import { useCallback, useRef } from 'react';

import { useEnhancedEffect } from './useEnhancedEffect';

export function useEvent<Args extends unknown[], Return>(
  fn: (...args: Args) => Return,
): (...args: Args) => Return {
  const ref = useRef(fn);
  useEnhancedEffect(() => {
    ref.current = fn;
  });
  return useCallback(
    (...args: Args) =>
      // @ts-expect-error hide `this`
      (0, ref.current!)(...args),
    [],
  );
}
