import { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const useLayoutContext = () => useContext(StateContext);

const LAYOUTS = {
  LEFT: 'left',
  RIGHT: 'right',
};

function LayoutStateProvider(props) {
  const currentLayout = localStorage.getItem('layout') || LAYOUTS.LEFT;
  const [layout, setLayout] = useState(currentLayout);

  const changeLayout = () => {
    if (layout === LAYOUTS.LEFT) {
      setLayout(LAYOUTS.RIGHT);
      localStorage.setItem('layout', LAYOUTS.RIGHT);
    }

    if (layout === LAYOUTS.RIGHT) {
      setLayout(LAYOUTS.LEFT);
      localStorage.setItem('layout', LAYOUTS.LEFT);
    }
  };

  return (
    <StateContext.Provider
      value={{
        changeLayout,
        layout,
      }}
      {...props}
    />
  );
}

export { useLayoutContext, LayoutStateProvider, LAYOUTS };
