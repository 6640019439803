// @ts-check
import { useEffect, useMemo, useState } from 'react';

import { Tab, Tabs } from 'appkit-react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from '@org/locales';
import { aggregated } from '@org/query';

import { FILE_TYPE } from '../../utils/data_configuration';
import ErrorBoundary from '../../utils/ErrorHandling/ErrorBoundary';
import { BabReportPage } from '../BabReportPage';
import { CostUnitReportPage } from '../CostUnitReport';
import { InterestReportPage } from '../InterestReportPage';
import { PriceSheetReportPage } from '../PriceSheetReport';
import { StyledWrapper } from '../styled/StyledComponents';
import { TradeTaxReportPage } from '../TradeTaxReportPage';

const REPORT_TAB_VALUES = {
  BAB: 'BAB',
  COST_UNIT: 'COST_UNIT',
  COST_UNIT_OLD: 'COST_UNIT_OLD',
  INTEREST: 'INTEREST',
  PRICE_SHEET: 'PRICE_SHEET',
  TRADE_TAX: 'TRADE_TAX',
};

const ReportsPage = () => {
  const navigate = useNavigate();
  const {
    yearId: yearCalculationId = '',
    publicFacilityId = '',
    configId,
    type = '',
    clientId,
  } = useParams();
  const { year: selectedYear } = aggregated.useYear({
    publicFacilityId,
    type,
    yearId: yearCalculationId,
  });

  const { filesMetadata } = aggregated.useFileMetadata({ yearCalculationId });
  const { masterConfiguration: selectedConfiguration = {} } = aggregated.useMasterConfiguration({
    masterConfigurationId: configId,
  });

  const [activeReportTab, setActiveReportTab] = useState(REPORT_TAB_VALUES.BAB);

  const { t } = useTranslation();

  const ledgerInputId = useMemo(
    () => filesMetadata?.find((item) => item.type === FILE_TYPE.LEDGER_ACCOUNT.apiType)?.fileId,
    [filesMetadata],
  );

  const { assets } = aggregated.useAssets({
    masterConfigurationId: configId,
    yearId: yearCalculationId,
  });

  const { report: selectedReport } = aggregated.useReport({
    clientId,
    masterConfigurationId: configId,
    publicFacilityId,
    type,
    yearId: yearCalculationId,
  });

  useEffect(() => {
    if (selectedYear && !selectedConfiguration) {
      navigate(-1);
    }
  }, [navigate, selectedConfiguration, selectedYear]);

  if (!selectedReport) {
    return <StyledWrapper />;
  }
  return (
    <StyledWrapper>
      <ErrorBoundary>
        <Tabs
          className="a-tab-item-padding-10"
          onChange={(e, val) => setActiveReportTab(val)}
          size="md"
          style={{ padding: '0 30px', width: '100%' }}
          value={activeReportTab}
        >
          <Tab
            key={REPORT_TAB_VALUES.BAB}
            label={REPORT_TAB_VALUES.BAB}
            value={REPORT_TAB_VALUES.BAB}
          >
            {activeReportTab === REPORT_TAB_VALUES.BAB && <BabReportPage />}
          </Tab>
          {(assets || ledgerInputId) && (
            <Tab
              key={REPORT_TAB_VALUES.INTEREST}
              label={t('common:tabs.interest')}
              value={REPORT_TAB_VALUES.INTEREST}
            >
              {activeReportTab === REPORT_TAB_VALUES.INTEREST && <InterestReportPage />}
            </Tab>
          )}
          <Tab
            key={REPORT_TAB_VALUES.COST_UNIT}
            label={t('common:tabs.costUnit')}
            value={REPORT_TAB_VALUES.COST_UNIT}
          >
            {activeReportTab === REPORT_TAB_VALUES.COST_UNIT && <CostUnitReportPage />}
          </Tab>
          {selectedConfiguration?.imputedTradeTaxConfig && (
            <Tab
              key={REPORT_TAB_VALUES.TRADE_TAX}
              label={t('main:reportPanels.tradeTax.tabName')}
              value={REPORT_TAB_VALUES.TRADE_TAX}
            >
              <TradeTaxReportPage />
            </Tab>
          )}
          <Tab
            key={REPORT_TAB_VALUES.PRICE_SHEET}
            label={t('main:reportPanels.priceSheet.tabName')}
            value={REPORT_TAB_VALUES.PRICE_SHEET}
          >
            {activeReportTab === REPORT_TAB_VALUES.PRICE_SHEET && <PriceSheetReportPage />}
          </Tab>
        </Tabs>
      </ErrorBoundary>
    </StyledWrapper>
  );
};

export default ReportsPage;
