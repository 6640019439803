import type { FC } from 'react';
import { memo } from 'react';

import { useParams } from 'react-router-dom';

import { PriceSheetReport as Page } from '@org/pages';

export const PriceSheetReportPage: FC = memo(() => {
  const {
    configId: masterConfigurationId,
    yearId,
    type,
    publicFacilityId,
    clientId,
  } = useParams<{
    yearId: string;
    configId: string;
    type: string;
    publicFacilityId: string;
    clientId: string;
  }>();

  return (
    <Page
      apiParams={{
        type: type!,
        publicFacilityId: publicFacilityId!,
        clientId: clientId!,
        masterConfigurationId: masterConfigurationId!,
        yearId: yearId!,
      }}
    />
  );
});
