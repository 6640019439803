import type { dice, griddy } from '../../../../query/src';
import { rebuildRanks } from '../../array';
import { updateImputedConcessionFeeByCostCenters } from './updateImputedConcessionFee';
import { updateImputedWorkingCapitalByCostCenterDelete as updateImputedWorkingCapital } from './updateImputedWorkingCapital';
import { updateInterestRatesByCostCenters } from './updateInterestRates';
import { resetAutomaticAllocations } from './updateWithdrawCapital';
import { isPrimaryCostCenter } from './utils';

export type DeleteCostCenterFromMasterConfiguration = (
  costCenter: dice.CostCenter,
  masterConfiguration: dice.MasterConfiguration,
  accountCostCentersMap: Record<number, string[]>,
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse,
) => dice.MasterConfiguration;

export const deleteCostCenterFromMasterConfiguration: DeleteCostCenterFromMasterConfiguration = (
  costCenter,
  masterConfiguration,
  accountCostCentersMap,
  flatProfitCenterTree,
) => {
  const { costCenterMapping: _costCenterMapping = [] } =
    masterConfiguration?.costCenterConfig ?? {};
  const filteredCostCenterMapping =
    _costCenterMapping.filter((row) => row.shortName !== costCenter.shortName) || [];
  const costCenterMapping = rebuildRanks(filteredCostCenterMapping);

  const newMasterConfiguration: dice.MasterConfiguration = {
    ...masterConfiguration,
    costCenterConfig: {
      ...masterConfiguration?.costCenterConfig,
      costCenterMapping,
    },
  };
  return updateOtherConfigurationSteps({
    accountCostCentersMap,
    costCenter,
    flatProfitCenterTree,
    masterConfiguration: newMasterConfiguration,
  });
};

const updateOtherConfigurationSteps = (props: {
  costCenter: dice.CostCenter;
  masterConfiguration: dice.MasterConfiguration;
  flatProfitCenterTree: griddy.GetFlattenedProfitCenterTreesResponse;
  accountCostCentersMap: Record<number, string[]>;
}): dice.MasterConfiguration => {
  const { costCenter, masterConfiguration, accountCostCentersMap, flatProfitCenterTree } = props;
  /* TODO: update only needed parts for CostUnitAllocationConfig, InterestRates
      and do ot mutate the object */
  let updatedConfiguration = updateCostUnitAllocationConfig({
    costCenter,
    newMasterConfiguration: masterConfiguration,
  });
  updatedConfiguration = updateInterestRatesByCostCenters(updatedConfiguration);
  const imputedWorkingCapitalConfig = updateImputedWorkingCapital({
    costCenter,
    imputedWorkingCapitalConfig: masterConfiguration?.imputedWorkingCapitalConfig,
  });
  const concessionFeeConfig = updateImputedConcessionFeeByCostCenters(updatedConfiguration);
  const withdrawCapitalConfig = resetAutomaticAllocations(
    updatedConfiguration.withdrawCapitalConfig ?? [],
    updatedConfiguration.costCenterConfig,
    accountCostCentersMap,
    flatProfitCenterTree,
  );
  return {
    ...updatedConfiguration,
    concessionFeeConfig,
    imputedWorkingCapitalConfig,
    withdrawCapitalConfig,
  };
};

const updateCostUnitAllocationConfig = (props: {
  costCenter: dice.CostCenter;
  newMasterConfiguration: dice.MasterConfiguration;
}): dice.MasterConfiguration => {
  const { costCenter, newMasterConfiguration } = props;
  if (isPrimaryCostCenter(costCenter)) {
    const { costCenterToCostUnitDistributions = [] } =
      newMasterConfiguration?.costUnitAllocationConfig ?? {};
    const updatedCostUnitAllocations = costCenterToCostUnitDistributions.filter(
      (allocation) => allocation.primaryCostCenterShortName !== costCenter.shortName,
    );

    return {
      ...newMasterConfiguration,
      costUnitAllocationConfig: {
        ...newMasterConfiguration?.costUnitAllocationConfig,
        costCenterToCostUnitDistributions: updatedCostUnitAllocations,
      },
    };
  }
  return newMasterConfiguration;
};
